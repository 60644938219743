import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import FeaturePagesActions, * as actionCreator from "./actions";
import FeaturePagesAPI from "./http";

const FeaturePagesSaga = [
    takeLatest(FeaturePagesActions.REQ_FEATURE_PAGES_LIST, reqFeaturePages),
    takeLatest(FeaturePagesActions.REQ_FEATURE_PAGE_BY_ID, reqFeaturePageById),
    takeLatest(FeaturePagesActions.REQ_ADD_FEATURE_PAGE, reqAddFeaturePage),
    takeLatest(FeaturePagesActions.REQ_UPDATE_FEATURE_PAGE, reqUpdateFeaturePage),
    takeLatest(FeaturePagesActions.REQ_DELETE_FEATURE_PAGE, reqDeleteFeaturePage),
];

function* reqFeaturePages() {
    try {
        const response =  yield call(FeaturePagesAPI.reqFeaturePagesList);
        yield put(actionCreator.successFeaturePagesList(response.data));
    } catch (e) {
        yield put(actionCreator.errorFeaturePagesList(e.data));
    }
}

function* reqFeaturePageById(action) {
    try {
        const response =  yield call(FeaturePagesAPI.reqFeaturePageById, action.payload);
        yield put(actionCreator.successFeaturePageById(response.data));
    } catch (e) {
        yield put(actionCreator.errorFeaturePageById(e.data));
    }
}

function* reqAddFeaturePage(action) {
    try {
        const response =  yield call(FeaturePagesAPI.reqAddFeaturePage, action.payload);
        yield put(actionCreator.successAddFeaturePage(response.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.errorAddFeaturePage(e.data));
        yield call(action.payload.cb);
    }
}

function* reqUpdateFeaturePage(action) {
    try {
        const response =  yield call(FeaturePagesAPI.reqUpdateFeaturePage, action.payload);
        yield put(actionCreator.successUpdateFeaturePage(response.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.errorUpdateFeaturePage(e.data));
        yield call(action.payload.cb);
    }
}

function* reqDeleteFeaturePage(action) {
    try {
        const response =  yield call(FeaturePagesAPI.reqDeleteFeaturePage, action.payload.data);
        yield put(actionCreator.successDeleteFeaturePage(response.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.errorDeleteFeaturePage(e.data));
        yield call(action.payload.cb);
    }
}

export default FeaturePagesSaga;
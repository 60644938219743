import React, { Component } from "react";
import { Form, Input, Button, message, Spin, Icon, Upload, Typography, Select } from "antd";
const { Option } = Select;
import { formItemLayout, formButtonLayout } from "../../constants";
import { uploadUtility, getSlug } from "../../utils";
import constant from "./signature.json";
import _ from "lodash";
import "./signature.scss";

const { Text } = Typography;

class SignatureBannerForm extends Component {

    constructor(props) {
        super();
        this.state = { countryWebsite: 'https://birdeye.com/' };
        this.onChangeCountryValue = this.onChangeCountryValue.bind(this);
    }

    handleFormSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {console.log(values, error);
            if (error) {
                return;
            }
            const dataValues =  _.omit(values, ['country']);
            dataValues.url = this.state.countryWebsite.concat(dataValues.url);
            dataValues.sfdc_campaign = dataValues.sfdc_campaign && dataValues.sfdc_campaign.join(";");
            this.props.createSignatureAssetRequest({ data: { template: this.props.template, type: "banner", country: values.country, data: {...dataValues, image: uploadUtility.singleFile.submittedValue(dataValues.image)} }, cb: this.handleSubmitResponse });
        });
    }

    handleSubmitResponse = (response) => {
        if (response.type === "success") this.props.form.resetFields();
        message[response.type](response.message);
    }

    onChangeCountryValue(value) {
        constant.value.countriesOptions.map((country) => {
            if(value === country.value){
                this.setState({countryWebsite: country.website});
            }
        })
    }

    render() {
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const formValues = getFieldsValue();


        return (
            <Spin tip="Please wait..." spinning={this.props.isLoading} size={"large"} indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}>
                <Form onSubmit={this.handleFormSubmit}>
                    <Form.Item {...formItemLayout} label={"Title"}>
                        {
                            getFieldDecorator("name", {
                                rules: [{
                                    required: true,
                                    message: "Please provide a proper title"
                                }]
                            })(
                                <Input placeholder={"Banner title"} />
                            )
                        }
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={"Country"} >
                        {
                            getFieldDecorator("country", {
                                rules: [{
                                    required: true,
                                    message: "Please provide a country name"
                                }]
                            })(
                                <Select
                                style={{ width: 200 }}
                                placeholder="Select a country"
                                onChange={this.onChangeCountryValue}
                                >
                                { constant.value.countriesOptions.map((country) =>  <Option key={country.text} value={country.value}>{country.text}</Option>)}
                              </Select>,
                            )
                        }
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={"URL"} extra={"We don't support URL with query string."}>
                        {
                            getFieldDecorator("url", {
                                rules: [{
                                    required: true,
                                    message: "Please provide a URL to which banner will redirect"
                                }],
                                getValueFromEvent: (e) => {return e.target.value ? e.target.value.split("?")[0] : ""},
                            })(
                                <Input placeholder={"Banner will redirct to URL"} addonBefore={`${this.state.countryWebsite}`} />
                            )
                        }
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={"Image"} extra={<>Supported format: <Text code>jpg</Text>, <Text code>jpeg</Text>, <Text code>png</Text>, <Text code>gif</Text>, <Text code>svg</Text><br/>Preferred image aspect ratio 4:1 (max width 600px)</>}>
                        {
                            getFieldDecorator("image", {
                                rules: [{
                                    required: true,
                                    message: "Please provide a proper to which banner will be redirect"
                                }, {
                                    validator: uploadUtility.singleFile.validator
                                }],
                                getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                valuePropName: "fileList",
                                initialValue: null
                            })(
                                <Upload
                                    name={"files"}
                                    accept={"image/*"}
                                    action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=email-signature-banner-image-${formValues.name ? getSlug(formValues.name) : Date.now()}`}
                                    withCredentials
                                    listType="picture"
                                    >
                                    <Button icon={"upload"} disabled={!formValues.name}>Banner image</Button>
                                </Upload>
                            )
                        }
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={"SFDC Campaign"} extra={"We can add multiple sfdc campaigns 1111, 2222."}>
                        {
                            getFieldDecorator("sfdc_campaign")(
                                <Select mode="tags" 
                                    tokenSeparators={[',']} 
                                    placeholder={"Add multiple SFDC campaigns"}>
                                </Select>
                            )
                        }
                    </Form.Item>
                    <Form.Item {...formButtonLayout}>
                        <Button type="primary" htmlType="submit">
                            Upload
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        );
    }
}

const SignatureBanner = Form.create()(SignatureBannerForm);
export default SignatureBanner;
import React, {Component} from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Switch, Select, Icon, Upload } from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";
import { beAPIResource } from "../../utils/apiHelper";
import lookupResource from "./../../pages/Resources/resources.json";
import { getSlug, encodeResourceClassification, decodeResourceClassification, uploadUtility } from "../../utils";
import lookup from "./constants";

const 
    {valueList = {industries: [], topics: []}} = lookupResource,
    {industries, topics} = valueList;

const 
    { Item: FormItem } = Form,
    { Option: SelectOption } = Select;

class CardForm extends Component {

    constructor(props) {
        super(props);

        this.linkSuffix = lookup.card.linkSuffix[props.type] || "";
        this.imageResolutions = lookup.card.imageResolutions || [];
        this.timestamp = `${Math.floor(0x100000000 * Math.random())}_${Date.now().toString()}`;

        this.state = {
            descriptionLength: 0,
            slugOccurence: props.additional.isUpdate ? 0 : -1
        }

        this.slugUniquenessTO = null;
    }

    componentDidMount() {
        const description = this.props.form.getFieldValue('cardDesc');
        if(description) {
            this.setState({descriptionLength : description.length});
        }

        if (!this.props.additional.isUpdate) {
            const link = this.props.form.getFieldValue('link');
            this.handleLinkValidation(link);
        }  
    }

    getLink = (value) => {
        const 
            slug = getSlug(value),
            link = slug ? `${slug}/` : "";

        return link;
    };

    handleLinkValidation = async (link) => {
        this.props.form.setFields({ link: { value: link } });
        let occurence = -1;
        try {
            const { data } = await beAPIResource.get("support/resources/url/occurence", { slug: `/${this.linkSuffix}${link}` });
            if (data.hasOwnProperty("occurence")) occurence = data.occurence;
        } catch (e) {}
        if (occurence !== 0) this.props.form.setFields({ link: { value: link, errors: [new Error("Another resource with same URL already exists")] } });
        this.setState({ slugOccurence: occurence });
    }

    handleCardHeadingChange = (e) => {
        e.preventDefault();

        if (this.props.additional.isUpdate) return;

        if (this.slugUniquenessTO) {
            clearTimeout(this.slugUniquenessTO);
        }

        const link = this.getLink(e.target.value || "");
        this.slugUniquenessTO = setTimeout(this.handleLinkValidation, 1000, link);
    };

    handleFormSubmission = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            const descLength = this.state.descriptionLength;
            if (error) {
                return;
            } else if(descLength > 175) {
                return;
            }

            const { resourceIndustries, resourceTopics, link, image={}, featuredImage={} } = values;

            let imagesWithUrls = {};

            for (const resolution in image) {

                if (image.hasOwnProperty(resolution)) {
                    imagesWithUrls[resolution] = uploadUtility.singleFile.submittedValue(image[resolution]);
                }
            }

            let featuredImagesWithUrls = {};
            for (const resolution in featuredImage) {
                if (featuredImage.hasOwnProperty(resolution)) {
                    featuredImagesWithUrls[resolution] = uploadUtility.singleFile.submittedValue(featuredImage[resolution]);
                }
            }

            values.classification = encodeResourceClassification(
                this.props.type,
                resourceIndustries,
                resourceTopics,
                lookupResource
            );

            values.link = `/${this.linkSuffix}${link}`;

            delete(values.resourceIndustries);
            delete(values.resourceTopics);
            delete(values.image);

            this.props.handleStepFormSubmission({ card: {...values, image: imagesWithUrls["1x"], featuredImage: featuredImagesWithUrls["1x"]} });
        });
    }

    onDescriptionChange = (e) => {
        this.setState({descriptionLength: e.target.value.length});
    }

    render() {
        const { type } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { eventTitle="", eventDescription="" } = type === "webinar" && this.props.initialValues.webinar ? this.props.initialValues.webinar : (type === "meeting" && this.props.initialValues.meeting ? this.props.initialValues.meeting : {});
        const { cardTitle=eventTitle, cardDesc=eventDescription, link=(this.getLink(cardTitle || eventTitle || "")), showInList = true, classification = "", image, featuredImage } = this.props.initialValues.card || {};
        const { topics: resourceTopics, industries: resourceIndustries } = (classification && decodeResourceClassification(classification, lookupResource)) || {};
        const { descriptionLength, slugOccurence } = this.state;

        return (
            <>
                <Form style={{}} onSubmit={this.handleFormSubmission}>
                    <FormItem {...formItemLayout} label={"Heading"}>
                        {
                            getFieldDecorator("cardTitle", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable resource card heading"
                                }],
                                initialValue: cardTitle
                            }) (
                                <Input placeholder={"Resource card heading"} onChange={this.handleCardHeadingChange} autocomplete="off" />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Description"} extra={<div style={{'textAlign': 'right'}}> {descriptionLength > 175 ? <span style={{color: "red"}} >{descriptionLength-175} characters overloaded (Limit 175)</span> : <span>{175-descriptionLength} characters left.</span> }</div>}>
                        {
                            getFieldDecorator("cardDesc", {
                                rules: [{
                                    required: true,
                                    message: "Provide some description related to this resource"
                                }],
                                initialValue: cardDesc
                            }) (
                                <Input.TextArea placeholder={"Resource card description"} autoSize={{minRows: 2, maxRows: 8}} maxLength={175} onChange={this.onDescriptionChange} allowClear />
                            )
                         }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Link"}  extra={<p>Resource link is dynamically generated through the input provided at <strong>Heading</strong>.</p>}>
                        {
                            getFieldDecorator("link", {
                                initialValue: link && link.replace(`/${this.linkSuffix}`, '')
                            }) (
                                <Input placeholder={"Resource page link (dynamically generated)"} readOnly={true} addonBefore={`${process.env.REACT_APP_WEBSITE_URL}${this.linkSuffix}`} />
                            )
                         }
                    </FormItem>
                    {
                        this.imageResolutions.map((resolution, index) => {
                            const layout = index === 0 ? formItemLayout : formButtonLayout;
                            const label = index === 0 ? "Resource Card Image" : "";
                            const { key, pd } = resolution;
                            const imageUrl = image;
                            const extensionIndex = imageUrl && imageUrl.lastIndexOf(".");
                            const finalImageURL = imageUrl && imageUrl.substr(0, extensionIndex) + ( pd ? ("@" + pd) : "" ) + imageUrl.substr(extensionIndex);

                            return (
                                <FormItem key={index} {...layout} label={label}>
                                    {
                                        getFieldDecorator(`image[${key}]`, {
                                            getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                            rules: [{
                                                required: type !== "meeting",
                                                message: `Upload a ${key} image`
                                            }, {
                                                validator: uploadUtility.singleFile.validator
                                            }],
                                            valuePropName: "fileList",
                                            initialValue: imageUrl && [{
                                                uid: "-1",
                                                name: `${cardTitle}@${key}`,
                                                status: "done",
                                                url: finalImageURL
                                            }],
                                        })(
                                            <Upload
                                                name={"files"}
                                                accept={"image/*"}
                                                action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=card-image-${this.timestamp}&pd=${pd}&webp=true&excludeTimestamp=true`}
                                                withCredentials
                                                listType="picture"
                                                >
                                                <Button icon={"upload"}>Resource Card Image@{key}</Button>
                                            </Upload>
                                        )
                                    }
                                </FormItem>
                            )
                        })
                    }
                    {
                        this.imageResolutions.map((resolution, index) => {
                            const layout = index === 0 ? formItemLayout : formButtonLayout;
                            const label = index === 0 ? "Featured Card Image" : "";
                            const { key, pd } = resolution;
                            const imageUrl = featuredImage;
                            const extensionIndex = imageUrl && imageUrl.lastIndexOf(".");
                            const finalImageURL = imageUrl && imageUrl.substr(0, extensionIndex) + ( pd ? ("@" + pd) : "" ) + imageUrl.substr(extensionIndex);

                            return (
                                <FormItem key={index} {...layout} label={label}>
                                    {
                                        getFieldDecorator(`featuredImage[${key}]`, {
                                            getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                            rules: [{
                                                required: type !== "meeting",
                                                message: `Upload a ${key} featured image`
                                            }, {
                                                validator: uploadUtility.singleFile.validator
                                            }],
                                            valuePropName: "fileList",
                                            initialValue: imageUrl && [{
                                                uid: "-1",
                                                name: `featured-${cardTitle}@${key}`,
                                                status: "done",
                                                url: finalImageURL
                                            }],
                                        })(
                                            <Upload
                                                name={"files"}
                                                accept={"image/*"}
                                                action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=featured-card-image-${this.timestamp}&pd=${pd}&webp=true&excludeTimestamp=true`}
                                                withCredentials
                                                listType="picture"
                                                >
                                                <Button icon={"upload"}>Feataured Image@{key}</Button>
                                            </Upload>
                                        )
                                    }
                                </FormItem>
                            )
                        })
                    }
                    <FormItem {...formItemLayout} label={"Related Industries"}>
                        {
                            getFieldDecorator("resourceIndustries", {
                                rules: [{
                                    "required": true,
                                    "message": `Select some industries which are related to this ${type}`
                                }],
                                initialValue: resourceIndustries
                            })(
                                <Select placeholder={`Related industries of this ${type}`} showSearch={true} mode={"multiple"}>
                                {
                                    industries && industries.length && industries.map((industry, index) => (
                                        <SelectOption key={index} value={industry.value}>{industry.label}</SelectOption>
                                    ))
                                }
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Related Topics"}>
                        {
                            getFieldDecorator("resourceTopics", {
                                rules: [{
                                    "required": true,
                                    "message": `Select some topics which are relatable to this ${type}`
                                }],
                                initialValue: resourceTopics
                            })(
                                <Select placeholder={`Related topics of this ${type}`} showSearch={true} mode={"multiple"} >
                                {
                                    topics && topics.length && topics.map((topic, index) => (
                                        <SelectOption key={index} value={topic.value}>{topic.label}</SelectOption>
                                    ))
                                }
                                </Select>
                            )
                        }
                    </FormItem>
                    {
                        (this.props.type === "webinar" || this.props.type === "guide") && (
                            <FormItem {...formItemLayout} label={"Show In Resource List"} extra={<p>This input is to distinguish between <b>Public</b> and <b>Private</b> webinar events.<br/>In order to make it Private, toggle it to <b>No</b> otherwise toggle it to <b>Yes</b>.</p>}>
                                {
                                    getFieldDecorator("showInList", {
                                        rules: [{
                                            "required": false
                                        }],
                                        valuePropName: "checked",
                                        initialValue: showInList
                                    })(
                                        <Switch checkedChildren={"Yes"} unCheckedChildren={"No"} style={{width: "60px"}}/>
                                    )
                                }
                            </FormItem>
                        )
                    }
                    <FormItem {...formButtonLayout}>
                        <Button type={"primary"} htmlType={"submit"} disabled={slugOccurence !== 0}>
                            Next<Icon type="right" />
                        </Button>
                    </FormItem>
                </Form>
            </>
        );
    }
}

const Card = Form.create()(CardForm);

Card.propTypes = {
    userData: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    additional: PropTypes.object,
    handleStepFormSubmission: PropTypes.func.isRequired
};

export default Card;
import { connect } from "react-redux";

import {
    fetchReviewBySource,
    fetchReviewBySourceSuccess,
    fetchReviewBySourceError,
    deleteReviewByReviewId,
    deleteReviewByReviewIdSuccess,
    deleteReviewByReviewIdError,
    fetchSourcesFromDatabase,
    fetchSourcesFromDatabaseSuccess,
    fetchSourcesFromDatabaseError,
    postReviewResponse,
    postReviewResponseSuccess,
    postReviewResponseError
} from "./actions";

import GetReview from "./GetReviews";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Reviews
});

export const GetReviewPage = connect(
    mapStateToProps,
    {
      fetchReviewBySource,
      fetchReviewBySourceSuccess,
      fetchReviewBySourceError,
      deleteReviewByReviewId,
      deleteReviewByReviewIdSuccess,
      deleteReviewByReviewIdError,
      fetchSourcesFromDatabase,
      fetchSourcesFromDatabaseSuccess,
      fetchSourcesFromDatabaseError,
      postReviewResponse,
      postReviewResponseSuccess,
      postReviewResponseError,
    }
)(GetReview);

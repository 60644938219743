import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tag, Spin } from "antd";
import { isEmpty } from "lodash";
import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";
import { getIsPageActionAccessible } from "../../utils";
import lookup from "./userRoles.json";
import { getPermissionToDisplay } from "./utils";

class UserRoles extends Component {
    pageKey = "userRoleList";

    componentDidMount() {
        this.props.fetchUserRolesListRequest();
        this.props.fetchPermissionsList();
    }

    formatData = (data) => {
        const { permissionsList } = this.props;

        return data.map((record) => {console.log(record);
            const permissions = record.privileges ? record.privileges.split(",") : [];
            const permissionForDisplay = getPermissionToDisplay(permissions, permissionsList);

            return {
                ...record,
                key: record.id,
                permissionsForDisplay: !isEmpty(permissionForDisplay) && permissionForDisplay.map((p, i) => <Tag key={i} color="green">{p}</Tag>)
            };
        });
    };

    render() {
        const { rolesList, loadingRole, loadingPermission, userError, deleteUserRolesRequest, userData } = this.props;

        const unauthorized = userError === "unauthorized";
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        const isActionAccessible = getIsPageActionAccessible(this.pageKey, userData.privileges);

        return (
            <div className="users-roles-root">
                <PageHeaderContainer pageKey={"userRoleList"} />
                <TableSkeleton
                    columns={lookup.columns}
                    pathForEdit={isActionAccessible ? "/user-role/" : ""}
                    dataSource={this.formatData(rolesList)}
                    handleDelete={isActionAccessible && ((data) => (data && data.id && deleteUserRolesRequest({id: data.id})))}
                    loading={loadingRole || loadingPermission}
                />
            </div>
        );
    }
}

UserRoles.propTypes = {
    rolesList: PropTypes.array.isRequired,
    userError: PropTypes.string.isRequired,
    fetchUserRolesListRequest: PropTypes.func.isRequired,
    deleteUserRolesRequest: PropTypes.func.isRequired,
};

export default UserRoles;
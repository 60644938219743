import { numToMonth } from "../constants";
import { formatNum, formatNumber } from "../../../utils";
import googleLogo from "../../../../assets/images/google-logo.png";
import salesforceLogo from "../../../../assets/images/salesforce-logo.png";
import { salesforceBaseUrl } from "../../../constants";
import moment from "moment-timezone";

export const getZoomAndCenter = (mapConfig, mapElment, markers, targetMarker) => {
  const {
    mapConstants: { defaultZoom, usCenterCoords }
  } = mapConfig;
  let bounds = null;
  if (markers.length) {
    bounds = new Bound();
    markers.forEach(({ latitude, longitude }) => bounds.extend(latitude, longitude));
    if (targetMarker) {
      bounds.addCenteredLatLng(targetMarker.latitude, targetMarker.longitude);
    }
  }

  const mapDimension = {
    height: mapElment.clientHeight,
    width: mapElment.clientWidth
  };
  const zoom = bounds ? getBoundsZoomLevel(bounds, mapDimension) || defaultZoom : defaultZoom;
  const centerCoords = bounds ? bounds.getCenter() : usCenterCoords;

  return [zoom, new window.google.maps.LatLng(...centerCoords)];

  function getBoundsZoomLevel(bounds, mapDim) {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 21;

    function latRad(lat) {
      let sin = Math.sin((lat * Math.PI) / 180);
      let radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();
    let latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

    let lngDiff = ne.lng - sw.lng;
    let lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;
    let latZoom = zoom(mapDim.height || 1, WORLD_DIM.height, latFraction);
    let lngZoom = zoom(mapDim.width || 1, WORLD_DIM.width, lngFraction);
    return Math.min(latZoom, lngZoom, ZOOM_MAX) - 1;
  }
};

class Bound {
  constructor() {
    this.maxLat = -Infinity;
    this.maxLng = -Infinity;
    this.minLat = Infinity;
    this.minLng = Infinity;
  }
  extend(lat, lng) {
    this.maxLat = Math.max(this.maxLat, lat);
    this.maxLng = Math.max(this.maxLng, lng);
    this.minLat = Math.min(this.minLat, lat);
    this.minLng = Math.min(this.minLng, lng);
  }
  addCenteredLatLng(lat, lng) {
    this.extend(lat, lng);
    this._centerCoord(lat, "Lat");
    this._centerCoord(lng, "Lng");
  }
  getNorthEast() {
    return { lat: this.maxLat, lng: this.maxLng };
  }
  getSouthWest() {
    return { lat: this.minLat, lng: this.minLng };
  }
  getCenter() {
    return [(this.maxLat + this.minLat) / 2, (this.maxLng + this.minLng) / 2];
  }
  _centerCoord(coord, type) {
    const distToMin = Math.abs(this[`min${type}`] - coord);
    const distToMax = Math.abs(this[`max${type}`] - coord);
    if (distToMax < distToMin) this[`max${type}`] = 2 * coord - this[`min${type}`];
    else this[`min${type}`] = 2 * coord - this[`max${type}`];
  }
}

export function getFormattedTime(timeStr) {
    if (!timeStr) {
        return '';
    }

    if (typeof timeStr === "string" ) {
        let [year, month] = timeStr.split("-");
        month = numToMonth[month - 1];
        return `${month} ${year}`;
    } else {
        return moment(timeStr).format('MMM YYYY');
    }
}

export const formatContent = (marker, industryToSubs) => {
  // TODO: clean up default values once API is ready
  const {
      businessName = "",
      type,
      industry,
      subIndustry,
      segmentC,
      street,
      city = "",
      state,
      zip = "",
      name,
      emailId,
      businessPhone,
      id: salesforceId,
      enabledProducts,
      paidSinceC,
      leadScore = 90,
      leadSource = "Outbound Lead",
      leadStatus = "New",
      oppStage = "5. Pilot",
      annualRevenue,
      reviewsSinceJoining,
      beforeBirdeyeReviewCount,
      businessLocation,
      googleRating, googleReviews,
      healthScore
    } = marker,
    formattedAddress = formatAddress({ street, city, state, zip }),
    subindustryName = getSubindustryName(industry, subIndustry, industryToSubs);
  let typeString = "";

  switch (type) {
    case "lead":
      typeString = "Lead";
      break;
    case "customer":
      typeString = "Customer";
      break;
    case "opportunity":
      typeString = "Opportunity";
      break;
    default:
      return;
  }

  const renderGoogleLink = () =>
    businessName && formattedAddress
      ? `<div class="searchlink"><a href="https://www.google.com/search?q=${encodeURI(businessName)},+${encodeURI(
          formattedAddress
        )}" target="_blank"><img src=${googleLogo} /></a></div>`
      : "";
  const renderSfLink = () =>
    salesforceId
      ? `<div class="sflink"><a href="${salesforceBaseUrl}${salesforceId}" target="_blank"><img src=${salesforceLogo} /></a></div>`
      : "";

  const lineConstructors = [
    () => `<h3 class="business-name">${businessName} <span class=${typeString.toLowerCase()}>${typeString}</span></h3>`,
    () => (type === "customer" && paidSinceC ? `<p class="paid-since"><b>Customer since:</b> ${getFormattedTime(paidSinceC)}</p>` : ""),
    () => (type === "lead" && leadScore ? `<p class="lead-score"><b>Lead score:</b> ${leadScore}</p>` : ""),
    () => (type === "lead" && leadSource ? `<p class="lead-source"><b>Lead source:</b> ${leadSource}</p>` : ""),
    () => (type === "lead" && leadStatus ? `<p class="lead-status"><b>Lead status:</b> ${leadStatus}</p>` : ""),
    () => (type === "opportunity" && oppStage ? `<p class="opp-stage"><b>Opp stage:</b> ${oppStage}</p>` : ""),
    () => (healthScore ? `<p class="health-score"><b>Health Score:</b> ${healthScore}</p>` : ""),
    () => (formattedAddress ? `<p class="address"><b>Address: </b>${formattedAddress}</p>` : ""),
    () => (businessLocation ? `<p class="loction"><b>Business locations: </b>${businessLocation}</p>` : ""),

    () => (name || businessPhone || emailId ? `<p class="contact"><span><b>Contact:</b></span>` : ""),
    () => (name ? `<span>${name}</span>` : ""),
    () => (businessPhone ? `<span>${businessPhone}</span>` : ""),
    () => (emailId ? `<span class="email"><a href="mailto:${emailId}" target="_blank">${emailId}</a></p></span>` : ""),
    () => (name || businessPhone || emailId ? "</p>" : ""),

    () => (segmentC ? `<p class="locations"><b>Segment:</b> ${segmentC}</p>` : ""),
    () => (industry ? `<p class="industry"><b>Industry:</b> ${industry}</p>` : ""),
    () => (subindustryName ? `<p class="industry"><b>Subindustry:</b> ${subindustryName}</p>` : ""),
    () => (type === "customer" && beforeBirdeyeReviewCount ? `<p class="before-review-count"><b>Reviews before joining:</b> ${formatNumber(beforeBirdeyeReviewCount)}</p>` : ""),
    () => (type === "customer" && reviewsSinceJoining ? `<p class="after-review-count"><b>Reviews after joining:</b> ${formatNumber(reviewsSinceJoining)}</p>` : ""),
    () => (type !== "customer" && googleRating) ? `<p class="google-rating"><b>Google rating:</b> ${googleRating.toFixed(1)}` : "",
    () => (type !== "customer" && googleReviews) ? `<p class="google-reviews"><b>Google reviews:</b> ${formatNum(googleReviews)}` : "",
    () => (annualRevenue ? `<p class="annual-revenue"><b>Annual revenue:</b> $${formatNum(annualRevenue)}</p>` : ""),
    () => (type === "customer" && !!enabledProducts ? `<p class="product"><b>Products:</b> ${enabledProducts}</p>` : ""),

    () =>
      (businessName && formattedAddress) || salesforceId
        ? `<div class="link-block"><b>Find on:</b> ${renderGoogleLink()} ${renderSfLink()}</div>`
        : ""
  ];

  return `<div class="info-window">${lineConstructors.reduce((str, lineConstructor) => (str += lineConstructor()), "")}</div>`;

  // helpers
  function formatAddress(addrObj) {
    return Object.values(addrObj)
      .filter((val) => !!val)
      .join(", ");
  }

  function getSubindustryName(industry, subId, industryToSubs) {
    if (!industry || !subId || !industryToSubs) return "";
    let subindustries = industryToSubs[industry.toLowerCase()] ? industryToSubs[industry.toLowerCase()].children : [];
    subindustries = subindustries.filter(({ key }) => key === subId);
    return subindustries.length ? subindustries[0].title : "";
  }
};

export const getMarker = (googleMap, mapConfig, industryToSubs, record, isTarget) => {
  const {
    imagePath: {
      marker: { customer, lead, opportunity },
      selectedMarker: { selectedCustomer, selectedLead, selectedOpportunity }
    }
  } = mapConfig;

  let position = new window.google.maps.LatLng(record.latitude, record.longitude);

  let markerObj = {
    position,
    map: googleMap,
    ...record
  };

  if (record.type === "lead") {
    markerObj.icon = isTarget ? selectedLead : lead;
  } else if (record.type === "opportunity") {
    markerObj.icon = isTarget ? selectedOpportunity : opportunity;
  } else {
    markerObj.icon = isTarget ? selectedCustomer : customer;
  }

  if (isTarget) markerObj.zIndex = mapConfig.mapConstants.targetZIndex;

  let marker = new window.google.maps.Marker(markerObj);
  let infowindow = new window.google.maps.InfoWindow({});
  mountMarkerEvents(googleMap, marker, infowindow, industryToSubs);

  return marker;

  function mountMarkerEvents(googleMap, marker, infowindow, industryToSubs) {
    marker.addListener("mouseover", () => {
      if (!infowindow.content) infowindow.setContent(formatContent(marker, industryToSubs));
      infowindow.open(googleMap, marker);
    });
    marker.addListener("mouseout", () => {
      if (infowindow.shouldPersist) return;
      infowindow.close(googleMap, marker);
    });
    marker.addListener("click", () => {
      if (!infowindow.content) infowindow.setContent(formatContent(marker, industryToSubs));
      infowindow.open(googleMap, marker);
      infowindow.shouldPersist = true;
    });
    infowindow.addListener("closeclick", () => {
      infowindow.shouldPersist = false;
    });
  }
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Form, Input, InputNumber, Button, Icon, Typography } from "antd";
import _ from "lodash";
import { formItemLayout, formButtonLayout } from "../../constants";
import { TextEditor } from "../../components";

const FormItem =  Form.Item;

class PodcastForm extends Component {
    constructor(props) {
        super(props);

    };

    componentDidMount() {
        const initialValue = this.props.initialValues;
        const formValues = !_.isEmpty(initialValue.podcast) ? { ...initialValue.podcast } : {};

        if (!(formValues.title && formValues.briefDescription) && !_.isEmpty(initialValue.card)) {
            if (initialValue.card.cardTitle) formValues.title = initialValue.card.cardTitle;
            if (initialValue.card.cardDesc) formValues.briefDescription = initialValue.card.cardDesc;
        }

        this.props.form.setFieldsValue(formValues);
    };

    handleFormSubmission = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (error) return;

            this.props.handleStepFormSubmission({ podcast: values });
        });
    };

    handleTextEditorChange = (content, name) => {
        this.props.form.setFieldsValue({ detailedDescription: content.replace("<p><br></p>", "") });
    };

    renderEpisodeIDExtra = () => {
        return (
            <React.Fragment>
                <Typography.Paragraph>Episode ID can be fetched using any of the following ways:</Typography.Paragraph>
                <ul>
                    <li><Typography.Text strong>From Episode URL</Typography.Text>: <Typography.Text>https://www.buzzsprout.com/1728881/episodes/<Typography.Text mark>episodeID</Typography.Text></Typography.Text></li>
                    <li><Typography.Text strong>From Email Link to Episode</Typography.Text>: <Typography.Text>https://www.buzzsprout.com/1728881/<Typography.Text mark>episodeID</Typography.Text></Typography.Text></li>
                    <li><Typography.Text strong>From Embed this ONE Episode</Typography.Text>: <div style={{ padding: "10px", borderRadius: "5px", border: "1px dotted rgb(217 217 217)", fontSize: "12px", margin: "10px 0 0" }}><code>&lt;div id=&quot;buzzsprout-player-<Typography.Text mark>episodeID</Typography.Text>&quot;&gt;&lt;/div&gt;<br/>&lt;script src=&quot;https://www.buzzsprout.com/1728881/<Typography.Text mark>episodeID</Typography.Text>-podcast-episode-title.js?container_id=buzzsprout-player-<Typography.Text mark>episodeID</Typography.Text>&amp;player=small&quot; type=&quot;text/javascript&quot; charset=&quot;utf-8&quot;&gt;&lt;/script&gt;</code></div></li>
                </ul>
            </React.Fragment>
        );
    };

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <Fragment>
                <Form onSubmit={this.handleFormSubmission}>
                    <FormItem { ...formItemLayout } label="Title">
                        {
                            getFieldDecorator("title", {
                                rules: [{
                                    required: true,
                                    message: "Provide a title of this Podcast"
                                }]
                            }) (
                                <Input placeholder="Title"/>
                            )
                        }
                    </FormItem>
                    <FormItem { ...formItemLayout } label="Brief Description">
                        {
                            getFieldDecorator("briefDescription", {
                                rules: [{
                                    required: true,
                                    message: "Provide brief description of this Podcast"
                                }]
                            }) (
                                <Input.TextArea placeholder="Brief description" allowClear autoSize={{ minRows: 2 }} />
                            )
                        }
                    </FormItem>
                    <FormItem { ...formItemLayout } label="Detailed Description">
                        {
                            getFieldDecorator("detailedDescription", {
                                rules: [{
                                    required: true,
                                    message: "Provide detailed description of this Podcast"
                                }],
                            })(
                                <TextEditor name="detailedDescription" handleEditorChange={this.handleTextEditorChange} />
                            )
                        }
                    </FormItem>
                    <FormItem { ...formItemLayout } label="Episode ID" extra={this.renderEpisodeIDExtra()}>
                        {
                            getFieldDecorator("episodeId", {
                                rules: [{
                                    required: true,
                                    message: "Provide episode ID"
                                }, {
                                    pattern: "^[0-9]+$",
                                    message: "Episode ID should be numeric"
                                }]
                            }) (
                                <Input placeholder="Episode ID" style={{ width: "50%" }} />
                            )
                        }
                    </FormItem>
                    <FormItem { ...formItemLayout } label="Duration" extra={<span>Duration should be in <strong>minute</strong>.</span>}>
                        {
                            getFieldDecorator("duration", {
                                rules: [{
                                    required: true,
                                    message: "Provide duration of this episode"
                                }]
                            }) (
                                <InputNumber placeholder="Episode duration" min={0} style={{ width: "50%" }} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formButtonLayout}>
                        <Button type={"primary"} htmlType={"submit"}>
                            Next <Icon type="right" />
                        </Button>
                    </FormItem>
                </Form>
            </Fragment>
        );
    }
}

const Podcast = Form.create()(PodcastForm);

Podcast.propTypes = {
    type: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    additional: PropTypes.object,
    handleStepFormSubmission: PropTypes.func.isRequired
};

export default Podcast;
import React, { Component } from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import {Spin, Form, Modal} from "antd";
import {find, isEmpty, isEqual} from "lodash";
import moment from 'moment-timezone';

import { Presenter, ResourceSteps } from "../../components";
import lookup from "../../components/ResourceSteps/constants";

import "./webinar.scss";
import { createSFDCampaign } from "../../utils";

class ScheduleForm extends Component  {

    constructor(props) {
        super(props);

        this.eventId = this.props.match && this.props.match.params && this.props.match.params && parseInt(this.props.match.params.eventId);
        this.isFutureDatedWebinar = true;

        this.state = {
            isWebinarPage: true
        };
    }

    componentWillMount() {
        const
            {reqWebinarData, form, reqWebinarPresenters, /*getWebinarRecordingAssets,*/ getWebinarUpcomingSession} = this.props;

        if (this.eventId) {
            reqWebinarData({eventId: this.eventId}, form.setFieldsValue);
        }

        if (this.props.location.pathname.includes("webinar")) {
            // getWebinarRecordingAssets();
            reqWebinarPresenters({isWebinarPresenter: true});
            getWebinarUpcomingSession({type: "Webinar"});
        } else {
            reqWebinarPresenters({isMeetingHost: true});
            getWebinarUpcomingSession({type: "Meeting"});
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isWebinarPage: nextProps.location.pathname.includes("webinar")
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const 
            {notification: nextNotification, event: nextWebinarEvent, history} = this.props,
            {notification: prevNotification, event: prevWebinarEvent} = prevProps;

        if (prevNotification && nextNotification && nextNotification.timestamp > prevNotification.timestamp) {
            const {isWebinarPage} = this.state;
            const eventText = isWebinarPage ? "webinar" : "meeting";
            const redirectURL = isWebinarPage ? "/webinar/events" : "/meeting/events";
            Modal[nextNotification.type]({
                title: nextNotification.type === 'success' ? (`You have successfully ${this.eventId ? "edited" : "added"} a ${eventText}`) : (`Something went wrong`),
                content: nextNotification.message,
                okText: `Go to ${eventText} list page`,
                maskClosable: true,
                onOk() {
                    history.push(redirectURL)
                }
            });
        }

        this.needToSetFormValues = (nextWebinarEvent.eventId !== prevWebinarEvent.eventId);
    }

    disabledDate = (current) => {
        return current && current < moment().endOf('day');
    };

    getConvertedDate = (date, currentTimezone, newTimezone) => {
        return moment.tz(date, currentTimezone).tz(newTimezone)
    };

    handleFormSubmission = async (data) => {
        const{ reqWebinarSchedule, reqMeetingSchedule, userData: {name} } = this.props;
        const {isWebinarPage} = this.state;

        const payload = {
            action: this.eventId ? "update" : "new"
        };

        let webinarKey = null;
        if (this.eventId) webinarKey = this.props.event.webinarKey;
        if (data.webinar.eventExperience === "recurring") {
            webinarKey = data.webinar.webinarKey;
            delete(data.webinar.audienceUrl);
        }
        if (data.webinar.eventExperience === "simulive") {
            if (data.webinar.webinarKey) webinarKey = data.webinar.webinarKey;
            delete(data.webinar.audienceUrl);
            data.webinar.registrants_processed_at = null;
            data.webinar.attendees_processed_at = null;
            data.webinar.attendance_file_url = null;
        }

        if (isWebinarPage) {
        let leadSfdcCampaignId = "";
        if(data.banner && !data.banner.leadSfdcCampaign){
            leadSfdcCampaignId = await createSFDCampaign('webinar', data, this.props.userData.name);
        } else {
            leadSfdcCampaignId = (data.banner && data.banner.leadSfdcCampaign) ? data.banner.leadSfdcCampaign : null;
        }
            payload.data = {
                name, isFutureDatedEvent: this.isFutureDatedWebinar, 
                eventId: this.eventId,
                ...data.webinar,
                ...data.card,
                cardImage: (data.card && data.card.image) ? data.card.image : null,
                featuredCardImage: (data.card && data.card.featuredImage) ? data.card.featuredImage : null,
                createSfdcLead: data.banner && data.banner.createSfdcLead,
                leadSfdcCampaign:  leadSfdcCampaignId,
                banner: delete(data.banner.createSfdcLead) && data.banner,
                meta: data.meta,
                highlights: data.highlights,
                result: data.result,
                cta: data.cta,
                sneakPeak: data.sneakPeak,
                webinarKey
            };

            reqWebinarSchedule(payload);
        } else {
            const eventData = data.meeting;
            eventData.showInList = 0;

            const organiserEmailId = !isEmpty(eventData.eventPresenter) ? eventData.eventPresenter[0].email : null;
            let meetingPayload = {
                topic: eventData.eventTitle,
                type: 2,
                eventDate: this.getConvertedDate(eventData.startDate, "UTC", eventData.eventTimezone).format("YYYY-MM-DD"),
                startTime: this.getConvertedDate(eventData.startDate, "UTC", eventData.eventTimezone).format("HH:mm"),
                endTime: this.getConvertedDate(eventData.endDate, "UTC", eventData.eventTimezone).format("HH:mm"),
                duration: (eventData.eventDuration === 'custom') ? moment.duration((moment(eventData.endDate)).diff(moment(eventData.startDate))).asMinutes() : eventData.eventDuration,
                timezone: eventData.eventTimezone,
                organiserName: !isEmpty(eventData.eventPresenter) ? eventData.eventPresenter[0].name : null,
                organiserEmailId
            };

            if (this.eventId) {
                //Do not hit RC meetings API on no change in meeting details to avoid multiple emails sent
                const {eventTitle, times, eventTimezone} = this.props.event;
                const origEvent = {
                    eventTitle,
                    eventTimezone,
                    startTime: times ? times[0].startTime : null,
                    endTime: times ? times[0].endTime: null
                };
                const updatedEvent = {
                    eventTitle: eventData.eventTitle,
                    eventTimezone: eventData.eventTimezone,
                    startTime: eventData.times ? eventData.times[0].startTime : null,
                    endTime: eventData.times ? eventData.times[0].endTime : null
                };

                if (isEqual(origEvent, updatedEvent)) {
                    meetingPayload = null
                }
            }

            delete(eventData.eventDuration);
            payload.data = {
                name, isFutureDatedEvent: this.isFutureDatedWebinar, eventId: this.eventId,
                ...eventData,
                ...data.card,
                createSfdcLead: data.banner && data.banner.createSfdcLead,
                banner: delete(data.banner.createSfdcLead) && data.banner,
                meta: data.meta,
                highlights: data.highlights,
                result: data.result,
                cta: data.cta,
                sneakPeak: data.sneakPeak,
                meetingId: this.eventId ? this.props.event.meetingId : null,
                calendarId: this.eventId ? this.props.event.calendarId : null,
                type: "Meeting",
                meetingPayload,
                organiserEmailId
            };

            reqMeetingSchedule(payload);
        }
    };

    getForFutureDate = (dateStr) => {
        return (moment.utc().diff(dateStr) < 0);
    }

    render() {
        const {isWebinarPage} = this.state;
        const pageType = isWebinarPage ? "webinar" : "meeting";
        const 
            { error, userData, isLoading, isLoadingPresenter, event, match, match: {params}, presenters = [], isPresenterModalVisible, reqWebinarPresenterModalClose, reqWebinarPresenterAdd, reqWebinarPresenterUpdate, reqWebinarPresenterDelete, isLoadingPresenterModal, notificationPresenterModal, isLoadingRecordingAssets, recordingAssets, isLoadingUpcomingSession, upcomingSession } = this.props,
            unauthorized = error === "unauthorized" || !userData.name,
            {eventTitle, eventDescription, recordingAssetKey, eventExperience="CLASSIC", isOnDemand=false, eventOccurrence = "single_session", eventTimezone = "America/Los_Angeles", eventPresenter = [], cardTitle, cardDesc, link, classification = "", startDate, endDate, times, showInList=true, createSfdcLead=true, leadSfdcCampaign="", meta = {}, banner = {}, highlights = {}, sneakPeak = {}, result = {}, cta = {}, webinarKey="", cardImage, featuredCardImage, audienceKey="", hostPlatform, gcFormId, gcEventId, country} = params.eventId ? event : {};

        let initialValues = {};
        if (params.eventId) {
            initialValues = {
                [pageType]: { eventTitle, eventDescription, recordingAssetKey, eventExperience, isOnDemand, eventOccurrence, eventTimezone, eventPresenter, startDate, endDate, times, webinarKey, featuredCardImage, audienceKey, hostPlatform: hostPlatform ? hostPlatform : lookup.webinar.hostPlatforms[1].value, gcFormId, gcEventId, country },
                card: { cardTitle, cardDesc, link, classification, showInList, image: cardImage, featuredImage: featuredCardImage },
                meta,
                banner: { ...banner, createSfdcLead, leadSfdcCampaign}, highlights, sneakPeak, result, cta
            };
        }

        let filteredUpcomingSession = this.eventId ? upcomingSession.filter((s) => (s.eventId !== this.eventId)) : upcomingSession;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        if((isLoading || isLoadingPresenter) && !isPresenterModalVisible) {
            return <Spin />;
        }

        this.isFutureDatedWebinar = startDate ? this.getForFutureDate(startDate) : true;

        return (
            <>
                <ResourceSteps type={pageType} title={isWebinarPage ? "Webinar" : "Meeting"} userData={ userData } initialValues={ initialValues } additional={{ error, isLoading, presenters, match, isFutureDatedEvent: this.isFutureDatedWebinar, reqWebinarPresenterModalOpen: this.props.reqWebinarPresenterModalOpen, recordingAssets, isLoadingRecordingAssets, isWebinarUpdate: !!this.eventId, isLoadingUpcomingSession, upcomingSession: filteredUpcomingSession, isUpdate: !!this.eventId, eventExperience}} handleStepsSubmission={this.handleFormSubmission}/>
                {
                    isPresenterModalVisible && (
                        <Modal className={"presenterModal"} centered footer={null} destroyOnClose={true} title="Presenter" visible={isPresenterModalVisible} onCancel={reqWebinarPresenterModalClose}>
                            <Presenter type={pageType} isLoadingPresenterModal={isLoadingPresenterModal} presenterList={presenters} addPresenter={reqWebinarPresenterAdd} updatePresenter={reqWebinarPresenterUpdate} deletePresenter={reqWebinarPresenterDelete} notificationPresenterModal={notificationPresenterModal}/>
                        </Modal>
                    )
                }
            </>
        );
    }
}

ScheduleForm.propTypes = {
    userData: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    reqWebinarSchedule: PropTypes.func.isRequired,
    reqMeetingSchedule: PropTypes.func.isRequired,
    reqWebinarData: PropTypes.func.isRequired,
    reqWebinarPresenters: PropTypes.func.isRequired,
    reqWebinarPresenterModalOpen: PropTypes.func.isRequired, 
    reqWebinarPresenterModalClose: PropTypes.func.isRequired,
    reqWebinarPresenterAdd: PropTypes.func.isRequired,
    reqWebinarPresenterUpdate: PropTypes.func.isRequired,
    reqWebinarPresenterDelete: PropTypes.func.isRequired,
    getWebinarRecordingAssets: PropTypes.func.isRequired,
    getWebinarUpcomingSession: PropTypes.func.isRequired
};

const Schedule = Form.create()(ScheduleForm);

export default Schedule;
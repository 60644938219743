import React, { Component } from "react";
import { Form, Input, Button, Spin, Select, Upload, Checkbox, Icon } from "antd";
import _, { isEmpty } from "lodash";
import { formItemLayout, formButtonLayout } from "../../constants";
import { uploadUtility } from "../../utils";
import { RedirectWrapper } from "../../components";
const { TextArea } = Input;
import lookup from "./lookup.json";

class PartnerForm extends Component {
    constructor(props) {
        super(props);

        const documentId = props.match.params.documentId;

        this.state = {
            documentId: documentId,
            isEdit: documentId !== "new"
        };
        switch (props.userData.account_type) {
            case 2:
                this.type = "coBranded";
                break;
            case 3:
                this.type = "whitelabeled";
                break;
            default:
                this.type = "both";
        }
    }

    componentDidMount() {
        this.state.isEdit && (this.props.fetchPartnerDocumentByIdRequest({ documentId: this.state.documentId }));
        this.timestamp = Date.now().toString();
    }
    
    componentDidUpdate() {
        if(!isEmpty(this.props.document) && isEmpty(this.state.documentData)) {
            this.setState({ documentData: this.props.document })
        }
    }


    handleDriveOnClick = async (meta) => {
        this.props.downloadPartnerDocumentRequest({ fileType: meta.file_type, fileId: meta.file_id, id: meta.id }, ()=>{});
    }

    handleOnSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((e,v) => {
            if (e) return;

            let data = {...v, ...this.decodeSharedURL(v.file_url)};
            data.preview_image = v.preview_image && !_.isEmpty(v.preview_image[0]) ? v.preview_image[0].url : null;
            
            if (this.state.isEdit) this.props.updatePartnerDocumentRequest({ documentId: this.state.documentId, data, cb: this.handleAfterSubmission });
            else this.props.createPartnerDocumentRequest({ data, cb: this.handleAfterSubmission });
        });
    };

    handleAfterSubmission = (data) => {
        if(data.data) this.handleDriveOnClick(data.data);
        const category = this.props.form.getFieldValue("category");
        this.props.history.push(`/partner/document/${category}`);
    }

    getFileType = (value) => {
        switch(value) {
            case "document": return "docx";
            case "presentation": return "pptx";
            case "spreadsheets": return "xlsx";
            default: return "file";
        }
    }

    decodeSharedURL = (url) => {
        let decodedURL = { file_id: null, file_type: null };
        if (!url) return decodedURL;

        const matches = url.match(/^https\:\/\/(drive|docs)\.google\.com\/(file|document|presentation|spreadsheets)\/d\/([a-zA-Z0-9-_]+)/i);
        if (!matches || matches.length !== 4) return decodedURL;

        decodedURL.file_id = matches[3];
        decodedURL.file_type = this.getFileType(matches[2]);
        return decodedURL;
    }

    encodeSharedURL = (tuples) => {
        switch(tuples.file_type) {
            case "docx": return `https://docs.google.com/document/d/${tuples.file_id}`;
            case "pptx": return `https://docs.google.com/presentation/d/${tuples.file_id}`;
            case "xlsx": return `https://docs.google.com/spreadsheets/d/${tuples.file_id}`;
            default: return `https://drive.google.com/file/d/${tuples.file_id}`;
        }
    }

    render() {
        const { error, userData, document } = this.props;
        const { isEdit } = this.state;
        const unauthorized = error === "unauthorized" || !userData.name;
        
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }
        
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const formValues = getFieldsValue();

        return (
            <div>
                <h2>{isEdit ? "Edit" : "New"} Partner Document</h2>
                <Spin spinning={!!this.props.isLoading}>
                    <Form onSubmit={this.handleOnSubmit}>
                        <Form.Item {...formItemLayout} label={"Partner type"}>
                            {
                                getFieldDecorator("partner_type", {
                                    rules: [{
                                        required: true,
                                        message: "Please select a partner type"
                                    }],
                                    initialValue: document.partner_type ? document.partner_type : (this.type !== "both" ? this.type : undefined)
                                })(
                                    <Select disabled={this.type !== "both"}>
                                        {
                                            lookup.selectOption.type.map((v, i) => (
                                                <Select.Option key={i} value={v[0]}>{v[1]}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                )
                            }
                        </Form.Item>
                        <Form.Item {...formItemLayout} label={"Category"}>
                            {
                                getFieldDecorator("category", {
                                    rules: [{
                                        required: true,
                                        message: "Please select a category"
                                    }],
                                    initialValue: document.category
                                })(
                                    <Select>
                                        {
                                            lookup.selectOption.category.map((v, i) => (
                                                <Select.Option key={i} value={v[0]}>{v[1]}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                )
                            }
                        </Form.Item>
                        <Form.Item {...formItemLayout} label={"Title"}>
                            {
                                getFieldDecorator("title", {
                                    rules: [{
                                        required: true,
                                        message: "Please provide a title"
                                    }],
                                    initialValue: document.title
                                })(
                                    <Input />
                                )
                            }
                        </Form.Item>
                        <Form.Item {...formItemLayout} label={"Description"}>
                            {
                                getFieldDecorator("description", {
                                    rules: [{
                                        required: true,
                                        message: "Please provide a description"
                                    }],
                                    initialValue: document.description
                                })(
                                    <TextArea />
                                )
                            }
                        </Form.Item>
                        <Form.Item {...formItemLayout} label="Preview image" extra={<p>Supported file formats <b>jpg</b>, <b>jpeg</b>, <b>png</b>, and <b>gif</b>. Supported resolution: 750px X 500px</p>}>
                            {
                                getFieldDecorator("preview_image", {
                                    getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                    rules: [{
                                        required: false,
                                        message: "Please upload an image"
                                    }, {
                                        validator: uploadUtility.singleFile.validator
                                    }],
                                    valuePropName: "fileList",
                                    initialValue: document.preview_image ? [{
                                        uid: -1,
                                        name: `${document.title} | Preview image`,
                                        status: "done",
                                        url: document.preview_image
                                    }] : undefined
                                })(
                                    <Upload
                                        name={"files"}
                                        accept={"image/*"}
                                        action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=partner-document-whats-new-image&timestamp=${this.timestamp}`}
                                        withCredentials
                                        listType="picture"
                                        >
                                        <Button icon={"upload"}>Upload preview image</Button>
                                    </Upload>
                                )
                            }
                        </Form.Item>
                        <Form.Item {...formItemLayout} label={"Document Shared URL"} extra={<p>Supported file formats <b>Document</b>, <b>Presentation</b>, <b>Spreadsheets</b>, <b>Image</b>, and <b>PDF</b></p>}>
                            {
                                getFieldDecorator("file_url", {

                                    rules: [{
                                        required: true,
                                        message: "Please provide document URL"
                                    }, {
                                        pattern: "^https\\:\\/\\/(drive|docs)\\.google\\.com\\/(file|document|presentation|spreadsheets)\\/d\\/([a-zA-Z0-9-_]+)/?",
                                        message: "Please provide a valid document URL"
                                    }],
                                    initialValue: this.props.document.file_id ? this.encodeSharedURL(this.props.document) : undefined
                                })(
                                    <Input placeholder={"Document Shared URL"} />
                                )
                            }
                        </Form.Item>
                        <Form.Item {...formItemLayout} label={"Is New?"}>
                            {
                                getFieldDecorator("is_new", {
                                    initialValue: document.is_new,
                                    valuePropName: "checked"
                                })(
                                    <Checkbox />
                                )
                            }
                        </Form.Item>
                        
                        <Form.Item {...formButtonLayout}>
                            <Button type="primary" htmlType="submit">Save</Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </div>
        );
    }
}

export default Form.create()(PartnerForm);

import { BusinessActions } from "./actions";

let Business = {
    isLoading: false,
    fetchReviewSuccess: false,
    beReviews: [],
    deleteReviewResp: "",
    deleteRequestSuccess: false,
    isProcessing: false
};

const businessActionsReducer = (state = Business, action) => {
    switch (action.type) {
        case  BusinessActions.FETCH_BIRDEYE_REVIEWS:
            return {
                ...state,
                isLoading: true,
            };
        case BusinessActions.FETCH_BIRDEYE_REVIEWS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                fetchReviewSuccess: true,
                beReviews: action.payload
            };
        case BusinessActions.FETCH_BIRDEYE_REVIEWS_ERROR:
            return {
                ...state,
                isLoading: false,
                fetchReviewSuccess: false,
            };
        case  BusinessActions.DELETE_REVIEW_REQ:
            return {
                ...state,
                isProcessing: true,
                deleteRequestSuccess: false
            };
        case  BusinessActions.DELETE_REVIEW_REQ_SUCCESS:
            return {
                ...state,
                isProcessing: false,
                deleteRequestSuccess: true,
                deleteReviewResp: action.payload
            };
        case  BusinessActions.DELETE_REVIEW_REQ_ERROR:
            return {
                ...state,
                isProcessing: false,
                deleteReviewResp: action.payload,
                deleteRequestSuccess: false
            };
        default:
            return state;
    }
};

export default businessActionsReducer;
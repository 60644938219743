import React, {Component} from 'react';
import {Form, Input, Button, Spin, Modal, Switch, Select} from 'antd';
import {formButtonLayout, formItemLayout} from '../../constants';
import {RedirectWrapper} from '../../components';
import { pick, find, isEmpty } from 'lodash';
import {addDynamicScripts} from "../../utils";
import lookup  from './users.json';

const Option = Select.Option;

const googlePlacesFields = [
    "address_components",
    "formatted_address",
    "formatted_phone_number",
    "international_phone_number",
    "name",
    "opening_hours",
    "permanently_closed",
    "place_id",
    "url",
    "vicinity",
    "types",
    "geometry",
    "website",
    "formatted_phone_number"
];

const menuItem = {
    display: "block",
    background: "aliceblue",
    margin: "10px",
    lineHeight: "30px",
    borderRadius: "5px"
}

const selectedItem = {
    border: "solid 1px #2196f3",
    userSelect: "none",
    cursor: "pointer",
    display: "block",
    background: "aliceblue",
    margin: "10px",
    lineHeight: "30px",
    borderRadius: "5px"
}

class SignupForm extends Component {
    state = {
        formError: '',
        isLoading: false,
        error: "",
        sessionToken: "",
        suggestions: [],
        businessName: "",
        activeSuggestionIndex: 0,
        isSelected: false,
        street: "",
        city: "",
        state: "",
        zip: "",
        businessPhone: "",
        countryCode: "",
        findOnGoogle: false,
        countrySelected: "US",
        industry: ""
    };

    handleSubmit = e => {
        e.preventDefault();
        const {websiteUrl,latitude, longitude} = this.state;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({isLoading: true});
                const payload = {
                    'businessName' : !isEmpty(this.state.businessName) ? this.state.businessName : values.businessName,
                    'address' : values.address,
                    'city' : values.city,
                    'state' : values.state,
                    'businessEmailId' : values.businessEmailId,
                    'phone' : values.phone,
                    'zip' : values.zip,
                    'countryCode' : values.countryCode,
                    'status' : values.status,
                    'type' : values.type,
                    'userEmailId' : values.userEmailId,
                    'salesRep' : values.salesRep,
                    'industryName' : this.props.form.getFieldValue("findOnGoogle") ? this.state.industry || "Other" : values.industryWithoutGoogle,
                    websiteUrl,
                    latitude,
                    longitude
                }
                this.props.businessSignupRequest({payload, cb: this.callbackFunction});
            }
            else {
                return;
            }
        });
    };

    callbackFunction = (response) => {
        this.setState({isLoading: false});
        if (response.status === 'success') {
            this.setState({isSelected: false, suggestions:[], sessionToken: "", businessName: null, street: null, city: null, state: null, zip: null, countryCode: null, businessPhone: null});
            window.open('https://reviews.birdeye.com/a-' + response.businessNumber + '?update=1', "_blank");
            return Modal.success({
                title: "SuccessFully Created",
                maskClosable: true,
                okText: "Close",
                content: "Business Successfully Created."
            });
        } else {
            return Modal.error({
                title: `Something went wrong.`,
                content: response.message
            });
        }
    };

    addPlacesAPI = () => {
        addDynamicScripts(`${process.env.REACT_APP_GOOGLE_URL}?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&language=en&region=US`, "google-places-api");
    }

    componentDidMount() {
        document.addEventListener("mouseup", this.closeDropdown);
        this.addPlacesAPI();
        this.props.fetchStates( { country: 'US' } );
        this.props.fetchCountries();
    }

    componentWillUnmount() {
        document.removeEventListener("mouseup", this.closeDropdown);
        document.removeEventListener("keydown", this.updateActiveSuggestion);
    }

    handleAutocomplete = (value) => {
        const google = window.google;
        const sessionToken = this.state.sessionToken || new google.maps.places.AutocompleteSessionToken();
        this.autocomplete = new google.maps.places.AutocompleteService();
        this.autocomplete.getPlacePredictions({
            input: value,
            sessionToken
        }, this.updatePlaceSuggestions);
        if (!this.state.sessionToken) {
            this.setState({ sessionToken });
        }
    };

    updatePlaceSuggestions = (predictions, status) => {
        const google = window.google
        let suggestions = [];
        if (status == google.maps.places.PlacesServiceStatus.OK) {
            suggestions = predictions.map(suggestion => pick(suggestion, ["place_id", "structured_formatting"]));
        }
        else {
            const manualInputOption = {
                "place_id": "",
                "structured_formatting": {
                    "main_text": "Can't find your business?",
                    "secondary_text": "Add your business manually"
                }
            };
            suggestions.push(manualInputOption);
        }
        this.setState({ suggestions });
        document.addEventListener("keydown", this.updateActiveSuggestion);
    };

    updateActiveSuggestion = (e) => {
        const { suggestions } = this.state;
        let { activeSuggestionIndex } = this.state;
        const listLength = suggestions.length;
        let activeSuggestion;

        if (e.keyCode === 38) {
            if (activeSuggestionIndex - 1 < 0) {
                activeSuggestionIndex = listLength - 1;
            } else {
                activeSuggestionIndex = activeSuggestionIndex - 1;
            }
        } else if (e.keyCode === 40) {
            if (activeSuggestionIndex + 1 >= listLength) {
                activeSuggestionIndex = 0;
            } else {
                activeSuggestionIndex = activeSuggestionIndex + 1;
            }
        } else if (e.keyCode !== 13 && e.keyCode !== 9) {
            activeSuggestionIndex = 0;
            this.setState({ activeSuggestionIndex });
            return;
        }

        activeSuggestion = suggestions[activeSuggestionIndex];
        this.setState({ activeSuggestionIndex, businessName: this.getUpdatedBusinessName(activeSuggestion) });

        if (e.keyCode === 13 || e.keyCode === 9) {
            if (activeSuggestion) {
                this.updateWithAutoCompResult(activeSuggestion.place_id);
            } else {
                this.closeDropdown(e);
            }
        }
    };

    updateActiveSuggestionOnHover = (index) => {
        this.setState({ activeSuggestionIndex: index });
    };

    updatePlaceDetails = (place, status) => {
        if(this.state.findOnGoogle) {
            const google = window.google
            if (status == google.maps.places.PlacesServiceStatus.OK) {
                const zipObj = place.address_components ? find(place.address_components, {"types": ["postal_code"]}) || {} : {};
                const zip = zipObj.short_name || "";
                const stateObj = place.address_components ? find(place.address_components, {"types": ["administrative_area_level_1", "political"]}) || {} : {};
                const state = stateObj.long_name || "";
                const countryObj = place.address_components ? find(place.address_components, {"types": ["country", "political"]}) || {} : {};
                const countryCode = countryObj.short_name || "";
                let cityObj = place.address_components ? find(place.address_components, {"types": ["locality", "political"]}) || {} : {};
                const city = cityObj.long_name || "";
                const street = place.vicinity && place.vicinity.split(",").slice(0, -1).join(",");
                let businessPhoneNumber = place.formatted_phone_number || "";
                const businessName = place.name;
                const industry = (place.types[0] !== "point_of_interest" && place.types[0] !== "establishment" ) ? place.types[0] : "Other";
                const websiteUrl = place.website || "";
                let businessSugObj = find(this.state.suggestions, { "place_id": place.place_id });
                if (isEmpty(businessSugObj)) {
                    businessSugObj = {
                        "structured_formatting": {
                            "secondary_text": `${place["vicinity"]}, ${state}, ${countryCode}`
                        }
                    };
                }
                let latitude = place.geometry && place.geometry.location && place.geometry.location.lat();
                latitude = latitude.toString().replace(".", "");
                latitude = parseInt(latitude);

                let longitude = place.geometry && place.geometry.location && place.geometry.location.lng();
                longitude = longitude.toString().replace(".", "");
                longitude = parseInt(longitude);
                
                this.setState({
                    businessName,
                    street,
                    city,
                    state,
                    zip,
                    countryCode,
                    businessPhone: businessPhoneNumber ? this.formatPhone(businessPhoneNumber) : "",
                    industry,
                    websiteUrl,
                    latitude,
                    longitude
                });
            }
            document.removeEventListener("keydown", this.updateActiveSuggestion);
        }
    }

    fetchBusinessInfoByPlaceID = (placeId, saveToCookie = false) => {
        const google = window.google
        try {
            const placeDetailRequest = {
                placeId,
                fields: googlePlacesFields,
                sessionToken: this.state.sessionToken || new google.maps.places.AutocompleteSessionToken()
            };
            const service = new google.maps.places.PlacesService(document.getElementById("businessInputField"));
            service.getDetails(placeDetailRequest, (place, status) => this.updatePlaceDetails(place, status, saveToCookie));
        } catch (e) {
            // todo: log error
        }
    }

    formatPhone = (phone) => {
        const numbers = phone.match(/\d+/g, '').join('');
        const phoneNumber = numbers.length === 10 ? numbers : (numbers.length > 10 ? numbers.slice(numbers.length-10,numbers.length) : (numbers.length < 10 ? null : numbers));
        if(phoneNumber === null){
            return null;
        }
        else {
            const reformat = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
            return ('(' + reformat[1] + ') ' + reformat[2] + '-' + reformat[3]); // (123) 456-7890
        }
    }

    handleChange = (e) => {
        const value = e.target.value;
        if(value === ""){
            this.setState({street: null, city: null, state: null, zip: null, countryCode: null});
        }
        this.setState({ businessName: value, isSelected: (value === "" ? true : false) });
        this.handleAutocomplete(value);
    }

    updateWithAutoCompResult = (placeId) => {
        this.setState({isSelected: true})
        if (placeId) {
            this.fetchBusinessInfoByPlaceID(placeId);
        }
        else {
            this.setState({street: null, city: null, state: null, zip: null, countryCode: null});
            const { suggestions, activeSuggestionIndex } = this.state
            const activeSuggestion = suggestions[activeSuggestionIndex];
            this.getUpdatedBusinessName(activeSuggestion);
        }
        document.removeEventListener("keydown", this.updateActiveSuggestion);
    };

    closeDropdown = (e) => {
        const { suggestions, activeSuggestionIndex } = this.state;
        if (suggestions.length && (e.keyCode === 13 || e.keyCode === 9 || !e.target.className.includes("menuItem"))) {
            this.updateWithAutoCompResult(suggestions[activeSuggestionIndex] && suggestions[activeSuggestionIndex].place_id);
        }
    };

    getUpdatedBusinessName = (activeSuggestion) => {
        const { suggestions, businessName } = this.state;
        let updatedBusinessName = businessName;

        if (activeSuggestion) {
            if (activeSuggestion.place_id) {
                updatedBusinessName = activeSuggestion.structured_formatting.main_text;
            } else {
                if (find(suggestions, {"structured_formatting": {"main_text": businessName}})) {
                    updatedBusinessName = "";
                }
            }
        }

        return updatedBusinessName;
    };

    onSwitchChange = (checked) => {
        this.setState({
            formError: '',
            isLoading: false,
            error: "",
            sessionToken: "",
            suggestions: [],
            businessName: "",
            activeSuggestionIndex: 0,
            isSelected: false,
            street: "",
            city: "",
            state: "",
            zip: "",
            businessPhone: "",
            countryCode: "US",
            countrySelected: "US",
            findOnGoogle: checked,
            industry: ""
        });
        this.props.fetchStates( { country: 'US' } );
        this.props.fetchCountries();
    }

    handleCountryChange = (countryCode) => {
        this.props.fetchStates( { country: countryCode } );
    };

    handleStateChange = (stateCode) => {
        this.props.fetchCities( { state: stateCode, country: this.props.form.getFieldValue('countryCode') } );
    }

    render() {
        const { error, userData, countries, states, isLoading, cities, isStateChange, loading } = this.props;

        const {getFieldDecorator} = this.props.form;

        const unauthorized = error === "unauthorized" || !userData.name;

        const { suggestions, businessName, isSelected, street, city, state, countryCode, zip, businessPhone, activeSuggestionIndex, findOnGoogle } = this.state;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location}/>;
        }

        if (this.state.isLoading) {
            return <Spin/>;
        }

        return (
            <div>
                <h2> Business Sign Up</h2>
                <Form {...formItemLayout} style={{width: '65%', float: 'left', display: 'inline-block'}} onKeyPress={event => { if (event.which === 13 /* Enter */) { event.preventDefault();} }}>
                    <Form.Item label="Find on Google">
                        {getFieldDecorator('findOnGoogle', {
                            rules: [
                                {
                                    required: true,
                                    message: "please provide Business Name"
                                }
                            ],
                            valuePropNmae: 'checked',
                            initialValue: false

                        })(
                            <Switch onChange={this.onSwitchChange} />
                        )}
                    </Form.Item>
                    { findOnGoogle ? (
                        <Form.Item label="Business Name">
                            {getFieldDecorator('businessName', {
                                rules: [
                                    {
                                        required: true,
                                        message: "please provide Business Name"
                                    },
                                ],
                                initialValue: businessName
                            })(
                                <div style={{position: "relative"}}>
                                    <Input
                                        id={'businessInputField'}
                                        style={{width: "100%"}}
                                        name={"businessName"}
                                        placeholder={"Enter Business Name"}
                                        onChange={this.handleChange}
                                        value={businessName}
                                    />
                                    {
                                        !!suggestions.length && !isSelected &&
                                        <ul style={{ padding: "1px", boxShadow: "2px 2px 5px", margin: "3px", position: "absolute", zIndex: "1", width: "100%", background: "white" }}>
                                            {
                                                suggestions.map((suggest, index) => (
                                                    <li key={suggest.place_id}
                                                        style={activeSuggestionIndex === index ? selectedItem : menuItem}
                                                        onClick={() => this.updateWithAutoCompResult(suggest.place_id)}
                                                        onMouseEnter={() => this.updateActiveSuggestionOnHover(index)}>
                                                        <div style={{lineHeight: "26px"}}>
                                                            <span style={{fontSize: "14px", marginLeft: "10px", display: "inline-table"}}><b>{suggest.structured_formatting.main_text}</b></span><br/>
                                                            <span style={{color: "#999999", marginLeft: "10px", display: "block", width: "397px", whiteSpace: "noWrap", overflow: "hidden", textOverflow: "ellipsis"}}>{suggest.structured_formatting.secondary_text}</span>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    }
                                </div>
                            )}
                        </Form.Item>
                    ) : (
                        <Form.Item label="Business Name">
                            {getFieldDecorator('businessName', {
                                rules: [
                                    {
                                        required: true,
                                        message: "please provide Business Name"
                                    },
                                ],
                            })(
                                <Input placeholder={"Enter Business Name"} />
                            )}
                        </Form.Item>
                    ) }

                    { !this.props.form.getFieldValue("findOnGoogle") &&
                        <Form.Item label="Industry">
                            {getFieldDecorator('industryWithoutGoogle', {
                                rules: [
                                    {
                                        required: true,
                                        message: "please select industry"
                                    }
                                ],
                            })
                            (
                                <Select placeholder="Select Industry">
                                    {lookup.industry.map((ele) => (
                                        <Option key={ele.value} value={ele.value}>
                                            {ele.label}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    }

                    <Form.Item label="Address">
                        {getFieldDecorator('address', {
                            initialValue: street
                        })(<Input placeholder="address" />)}
                    </Form.Item>

                    { loading ? (
                        <Form.Item {...formItemLayout} label="Country">
                            <Spin />
                        </Form.Item>
                    ) : (
                        <Form.Item {...formItemLayout} label="Country">
                            {getFieldDecorator('countryCode', {
                                rules: [{ required: true, message: 'Select Country' }],
                                initialValue: countryCode ? countryCode : "US"
                            })(
                                <Select onChange={this.handleCountryChange} placeholder="Country">
                                    {!isEmpty(countries) && countries.map((country) => (
                                        <Option key={country.code} value={country.code}>
                                            {country.name}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    )}

                    {isLoading || isEmpty(states) ? (
                        <Form.Item {...formItemLayout} label="State">
                            <Spin />
                        </Form.Item>
                    ) : (
                        <Form.Item {...formItemLayout} label="State">
                            {getFieldDecorator('state', {
                                rules: [{ required: true, message: 'Select State' }],
                                initialValue: state
                            })(
                                <Select onChange={this.handleStateChange} placeholder="State">
                                    {!isEmpty(states) && states.map((state) => (
                                        <Option key={state.code} value={state.code}>
                                            {state.name}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    )}

                    {
                        isStateChange ? (
                            <Form.Item {...formItemLayout} label="Cities">
                                <Spin />
                            </Form.Item>
                        ) : (
                            <Form.Item label="City">
                                {getFieldDecorator('city', {
                                    initialValue: city
                                })(
                                    <Select placeholder="City">
                                        {!isEmpty(cities) && cities.map((city, index) => (
                                            <Option key={index} value={city.name}>
                                                {city.name}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        )
                    }

                    <Form.Item label="Business E-mail">
                        {getFieldDecorator('businessEmailId', {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!'
                                }
                            ],
                        })(<Input placeholder="Enter Business Email"/>)}
                    </Form.Item>

                    <Form.Item label="Business Phone" extra={<p><b>(123) 456-7890</b></p>}>
                        {getFieldDecorator('phone', {
                            initialValue: businessPhone,
                            rules: [
                                {
                                    pattern: "^\\(?\\d{3}\\)? ?\\d{3}(\\-)?\\d{4}$",
                                    message: "Please provide valid phone number"
                                },
                                {
                                    required: true,
                                    message: "Please provide business phone"
                                },
                            ],
                        })(
                            <Input placeholder="Enter Business Phone"/>
                        )}
                    </Form.Item>

                    <Form.Item label="Business Zip" extra={<p><b>A1A 1A1, 1234, 12345</b></p>}>
                        {getFieldDecorator('zip', {
                            initialValue: zip,
                            rules: [
                                {
                                    pattern: "^(\\d{5}|\\d{4}|(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9])$",
                                    message: "Provide a valid zip code"
                                },
                                {
                                    required: true,
                                    message: "Please provide a business zip code"
                                },
                            ],
                        })(
                            <Input placeholder="Enter Business Location Zip"/>
                        )}
                    </Form.Item>

                    <Form.Item label="Status" style={{display: 'none'}}>
                        {getFieldDecorator('status', {
                            initialValue: "free"
                        })(<Input placeholder="Enter Business Status"/>)}
                    </Form.Item>

                    <Form.Item label="Type" style={{display: 'none'}}>
                        {getFieldDecorator('type', {
                            initialValue: "Business"
                        })(<Input placeholder="Enter Business Type"/>)}
                    </Form.Item>

                    <Form.Item label="User Email Id">
                        {getFieldDecorator('userEmailId', {
                            initialValue: this.props.form.getFieldValue("businessEmailId"),
                            rules: [
                                {
                                    type: "email",
                                    message: "Provided input is not valid email"
                                },
                                {
                                    required: true,
                                    message: "Please provide a user email"
                                }
                            ],
                        })(<Input placeholder="Enter User Email Id"/>)}
                    </Form.Item>
                    <Form.Item label="SalesRep">
                        {getFieldDecorator('salesRep', {
                            initialValue: "22",
                            rules: [
                                {
                                    required: true,
                                    message: "Please provide Sales Rep Id"
                                },
                            ],
                        })(<Input placeholder="Enter salesRep"/>)}
                    </Form.Item>

                    <Form.Item {...formButtonLayout}>
                        <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const Signup = Form.create()(SignupForm);

export default Signup;


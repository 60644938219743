import { connect } from "react-redux";

import { fetchPartnerDocumentRequest, fetchPartnerDocumentByIdRequest, createPartnerDocumentRequest,
    updatePartnerDocumentRequest, downloadPartnerDocumentRequest, fetchPartnerDocumentBySectionRequest } from "./actions";

import PartnerDocumentLibrary from "./PartnerDocumentLibrary";
import PartnerForm from "./PartnerForm";
import ProductBasedLibrary from "./ProductBasedLibrary";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Partner
});

export const PartnerDocumentLibraryPage = connect( 
    mapStateToProps, 
    {fetchPartnerDocumentRequest, downloadPartnerDocumentRequest}
)(PartnerDocumentLibrary);

export const PartnerFormPage = connect( 
    mapStateToProps, 
    { fetchPartnerDocumentByIdRequest, createPartnerDocumentRequest, updatePartnerDocumentRequest, downloadPartnerDocumentRequest } 
)(PartnerForm);

export const ProductBasedLibraryPage = connect(
    mapStateToProps,
    { fetchPartnerDocumentBySectionRequest, downloadPartnerDocumentRequest }
)(ProductBasedLibrary);

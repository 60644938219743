import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import { message, Button, Tag, Icon, Modal, Typography } from "antd";
import _ from "lodash";

import { TableSkeleton, RedirectWrapper } from "../../../../components";

import { dateFormat } from "../../../../constants";
import lookup from "./caseStudy.json";

class CaseStudy extends Component {
  state = { visible: false };

  componentDidMount() {
    const { match, fetchResourceRequest, fetchCaseStudyRequest } = this.props;
    fetchResourceRequest({ id: match.params.id });
    fetchCaseStudyRequest({ id: match.params.id });
  }

  formatData = (data) => {
    return data.map((record, i) => {
      return {
        ...record,
        key: record.id,
        typeTag: <Tag color="#2db7f5">{ record.type.toUpperCase() }</Tag>,
        snippet: record.content && record.content.replace( /(<([^>]+)>)/ig, "\n").replace(/\s{2,}|^\s|\s$/, ""),
        lastUpdated: moment(record.updatedAt).format(dateFormat)
      };
    });
  };

  handleDelete = (data) => {
    const { id } = data;
    this.props.deleteRCaseStudyBlockRequest({ id, cb: this.renderFeedbackMsg });
  };

  handlePublish = () => {
    this.props.updateResourceRequest({ data: this.props.curData, cb: this.renderFeedback });
  }

  renderFeedback = (errorType) => {
    if (!errorType) {
      return Modal.success({
        title: `You have successfully published this case study`,
        content: `Check the case study in https://birdeye.com${this.props.curData.link}`,
        maskClosable: true
      });
    } else {
      return Modal.warning({
        title: `The case study was not published successfully...`,
        content: "Please check your input or try again later..."
      });
    }
  };

  renderFeedbackMsg = (errorMsg) => {
    if (!errorMsg) {
      return message.success(
        "You have successfully deleted this block of content."
      );
    } else {
      return message.error("Something went wrong... Please try again later.");
    }
  };

  renderExpandedRow = (record) => (
    <div>
      {Object.keys(record).map((field, i) => {console.log(field);
        if ( 
          ![ "key", "id", "caseStudyId", "snippet", "typeTag", "type", "lastUpdated" ].includes(field) 
          && !_.isEmpty(record[field])
        ) {
          return (
            <div className="mb1" key={i}>
              <b>{field}: </b>
              {field === "content" ? (
                <span dangerouslySetInnerHTML={{ __html: record[field] }} />
              ) : (
                field === 'product' ? (
                  record[field].map((r) => (<Typography.Text code>{r}</Typography.Text>)).concat()
                ) : (
                  field === 'result' ? (
                    <span>{record[field].title}</span>
                  ) : (
                    field === 'highlight' ? (
                      record[field].map((r) => (<Typography.Text code><strong>{r.title}</strong>: {r.value}</Typography.Text>)).concat()
                    ) : (
                      <span>{record[field]}</span>
                    )
                  )
                )
              )}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );

  render() {
    const { caseStudyData, isLoading, error, userData, curData } = this.props;
    const unauthorized = error === "unauthorized" || !userData.name;
    const additionalHandler = [{ check: () => true , render: record => ({path: `/case-studies/${caseStudyId}/blocks/insert/${record.order + 1}`, label: "Insert after this"})}];
    const caseStudyId = this.props.match.params.id;

    if (unauthorized) {
      return <RedirectWrapper location={this.props.location} />;
    }

    return (
      <div className="resources-root">
        <h2>Case Study: {curData.heading}</h2>
          <TableSkeleton
            disablePagination
            columns={lookup.columns}
            pathForEdit={`/case-studies/${caseStudyId}/blocks/`}
            handleDelete={this.handleDelete}
            additionalHandler={additionalHandler}
            expandedRowRender={this.renderExpandedRow}
            dataSource={this.formatData(caseStudyData)}
            loading={isLoading}
          />
          <Link to={`/case-studies/${caseStudyId}/blocks`}>
            <Button type="primary">
              <Icon type="plus" /> Add a new block
            </Button>
          </Link>
          <span className="ml1">
            <Button type="primary" onClick={this.handlePublish}>
              <Icon type="cloud-upload" />Publish
            </Button>
          </span>
      </div>
    );
  }
}

CaseStudy.propTypes = {
  curData: PropTypes.object.isRequired,
  caseStudyData: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired,
  fetchResourceRequest: PropTypes.func.isRequired,
  fetchCaseStudyRequest: PropTypes.func.isRequired,
  deleteRCaseStudyBlockRequest: PropTypes.func.isRequired
};

export default CaseStudy;

import { beAPIResource } from "../../utils/apiHelper";

const integrationDomainApi = {
    getAll: () => beAPIResource.get("support/integration-domains"),
    getById: (id) => beAPIResource.get(`support/integration-domains/${id}`),
    add: (data) => beAPIResource.post("support/integration-domains/", data),
    update: (data) =>
        beAPIResource.put(`support/integration-domains/${data.id}`, data),
    delete: (id) => beAPIResource.delete(`support/integration-domains/${id}`)
};

export default integrationDomainApi;

import { PartnerActions } from "./actions";

const DEFAULT_STATE = {
    isLoading: false,
    list: [],
    document: {},
    section: {}
};

const partner = (state = DEFAULT_STATE,  action) => {
    switch (action.type) {
        case PartnerActions.FETCH_PARTNER_DOCUMENT_REQUEST:
        case PartnerActions.CREATE_PARTNER_DOCUMENT_REQUEST:
        case PartnerActions.UPDATE_PARTNER_DOCUMENT_REQUEST:
        case PartnerActions.FETCH_PARTNER_DOCUMENT_BYID_REQUEST:
        case PartnerActions.DOWNLOAD_PARTNER_DOCUMENT_REQUEST:
        case PartnerActions.FETCH_PARTNER_DOCUMENT_BY_SECTION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case PartnerActions.FETCH_PARTNER_DOCUMENT_SUCCESS:
        case PartnerActions.FETCH_PARTNER_DOCUMENT_ERROR:
            return {
                ...state,
                isLoading: false, list: action.payload, document: {}
            };
        case PartnerActions.CREATE_PARTNER_DOCUMENT_SUCCESS:
        case PartnerActions.CREATE_PARTNER_DOCUMENT_ERROR:
        case PartnerActions.UPDATE_PARTNER_DOCUMENT_SUCCESS:
        case PartnerActions.UPDATE_PARTNER_DOCUMENT_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case PartnerActions.FETCH_PARTNER_DOCUMENT_BYID_SUCCESS:
        case PartnerActions.FETCH_PARTNER_DOCUMENT_BYID_ERROR:
            return {
                ...state,
                isLoading: false,
                document: action.payload
            };
        case PartnerActions.DOWNLOAD_PARTNER_DOCUMENT_SUCCESS: 
        case PartnerActions.DOWNLOAD_PARTNER_DOCUMENT_ERROR:
            return {
                ...state,
                isLoading: false
            }
        case PartnerActions.FETCH_PARTNER_DOCUMENT_BY_SECTION_SUCCESS:
        case PartnerActions.FETCH_PARTNER_DOCUMENT_BY_SECTION_ERROR:
            let newSection = { ...state.section };
            const { section, category, product } = action.payload;

            if (newSection.hasOwnProperty(section)) {
                if (newSection[section].hasOwnProperty(category)) {
                    newSection[section][product || "all"] = action.payload.documents;
                } else {
                    newSection[section] = { [product || "all"]: action.payload.documents };
                }
            } else {
                newSection[section] = { [product || "all"]: action.payload.documents } 
            }
            
            return {
                ...state,
                isLoading: false,
                section: newSection
            }
        default:
            return state;
    }
};

export default partner;
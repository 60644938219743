import { findIndex } from "lodash";
import { UserActions } from "./actions";

export let User = {
    userData: {},
    curData: {},
    userListData: [],
    userError: "",
    loadingUser: false,
    rolesList: [],
    permissionsList: [],
    roleData : {},
    loadingRole: false,
    loadingPermission: false
};

const userReducer = (state = User, action) => {
    let index;
    switch (action.type) {
        case UserActions.LOGIN_REQUEST:
        case UserActions.BIRDEYE_LOGIN_REQUEST:
        case UserActions.LOGOUT_REQUEST:
        case UserActions.FETCH_USERS_LIST_REQUEST:
        case UserActions.FETCH_USER_REQUEST:
        case UserActions.ADD_USER_REQUEST:
        case UserActions.UPDATE_USER_REQUEST:
        case UserActions.DELETE_USER_REQUEST:
            return {
                ...state,
                curData: {},
                userError: "",
                loadingUser: true
            };
        case UserActions.LOGIN_SUCCESS:
            localStorage.beCmsUserData = JSON.stringify({userData: action.payload});
            return {
                ...state,
                userData: action.payload,
                loadingUser: false
            };
        case UserActions.LOGOUT_SUCCESS:
            localStorage.beCmsUserData = JSON.stringify({userData: {}});
            return {
                ...state,
                userData: {},
                loadingUser: false
            };
        case UserActions.USER_REQUEST_ERROR:
            return {
                ...state,
                userError: action.payload.error,
                loadingUser: false
            };
        case UserActions.FETCH_USERS_LIST_SUCCESS:
            return {
                ...state,
                userListData: action.payload,
                loadingUser: false
            };
        case UserActions.FETCH_USER_ROLES_SUCCESS:
            return {
                ...state,
                rolesList: action.payload
            };
        case UserActions.FETCH_PERMISSIONS:
        case UserActions.FETCH_PERMISSION:
        case UserActions.ADD_PERMISSION:
        case UserActions.UPDATE_PERMISSION:
        case UserActions.DELETE_PERMISSION_REQUEST:
            return {
                ...state,
                loadingPermission: true
            };
        case UserActions.FETCH_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissionsList: action.payload,
                loadingPermission: false
            };
        case UserActions.FETCH_PERMISSION_SUCCESS:
            return {
                ...state,
                permissionData: action.payload,
                loadingPermission: false
            };
        case UserActions.ADD_PERMISSION_SUCCESS:
            return {
                ...state,
                loadingPermission: false
            };
        case UserActions.UPDATE_PERMISSION_SUCCESS:
            return {
                ...state,
                loadingPermission: false
            };
        case UserActions.FETCH_ROLE:
        case UserActions.DELETE_USER_ROLES_REQUEST:
            return {
                ...state,
                roleData: {},
                loadingRole: true
            };
        case UserActions.FETCH_ROLE_SUCCESS:
            return {
                ...state,
                roleData: action.payload,
                loadingRole: false
            };
        case UserActions.FETCH_USER_SUCCESS:
            return {
                ...state,
                curData: action.payload,
                loadingUser: false
            };
        case UserActions.ADD_USER_SUCCESS:
            return {
                ...state,
                userListData: [...state.userListData, action.payload],
                curData: action.payload,
                loadingUser: false
            };
        case UserActions.UPDATE_USER_SUCCESS:
            index = findIndex(state.userListData, ["id", action.payload.id]);
            return {
                ...state,
                userListData: [
                    ...state.userListData.slice(0, index),
                    action.payload,
                    ...state.userListData.slice(index + 1)
                ],
                curData: action.payload,
                loadingUser: false
            };
        case UserActions.DELETE_USER_SUCCESS:
            return {
                ...state,
                userListData: [
                    ...state.userListData.filter(user => (user && user.id !== action.payload.id))
                ],
                curData: action.payload,
                loadingUser: false
            };
        case UserActions.DELETE_USER_ERROR:
            return {
                ...state,
                loadingUser: false,
                curData: action.payload,
            };
        case UserActions.DELETE_USER_ROLES_SUCCESS:
            return {
                ...state,
                rolesList: [
                    ...state.rolesList.filter(role => (role && role.id !== action.payload.id))
                ],
                curData: action.payload,
                loadingRole: false
            };
        case UserActions.DELETE_USER_ROLES_ERROR:
            return {
                ...state,
                loadingRole: false,
                curData: action.payload,
            };
        case UserActions.DELETE_PERMISSION_SUCCESS:
            return {
                ...state,
                permissionsList: [
                    ...state.permissionsList.filter(permission => (permission && permission.id !== action.payload.id))
                ],
                curData: action.payload,
                loadingPermission: false
            };
        case UserActions.DELETE_PERMISSION_ERROR:
            return {
                ...state,
                curData: action.payload,
                loadingPermission: false
            };
        default:
            return state;
    }
};

export default userReducer;
export const UPPER_LIMIT = 5000;

export const validTypes = {
  lead: "lead_leads",
  customer: "customer_customers",
  opportunity: "opportunity_opportunities"
};

export const productLookup = {
  "listings": "listingsEnabled",
  "reviews": "reviewsEnabled",
  "social": "socialEnabled",
  "socialListening": "socialListeningEnabled",
  "surveys": "surveyEnabled",
  "ticketing": "socialTicketingEnabled",
  "insights": "insightsEnabled",
  "benchmarking": "competitiveBenchmarkingEnabled",
  "inbox": "inboxEnabled",
  "webchat": "webChatEnabled",
  "google seller ratings": "googleSellerRatingsEnabled",
  "interactions": "interactionsEnabled",
  "referrals" : "referralsEnabled"
};

export const numToMonth = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const stateLookup = {
  al: "alabama",
  ak: "alaska",
  az: "arizona",
  ar: "arkansas",
  ca: "california",
  co: "colorado",
  ct: "connecticut",
  de: "delaware",
  dc: "district of columbia",
  fl: "florida",
  ga: "georgia",
  hi: "hawaii",
  id: "idaho",
  il: "illinois",
  in: "indiana",
  ia: "iowa",
  ks: "kansas",
  ky: "kentucky",
  la: "louisiana",
  me: "maine",
  md: "maryland",
  ma: "massachusetts",
  mi: "michigan",
  mn: "minnesota",
  ms: "mississippi",
  mo: "missouri",
  mt: "montana",
  ne: "nebraska",
  nv: "nevada",
  nh: "new hampshire",
  nj: "new jersey",
  nm: "new mexico",
  ny: "new york",
  nc: "north carolina",
  nd: "north dakota",
  oh: "ohio",
  ok: "oklahoma",
  or: "oregon",
  pa: "pennsylvania",
  ri: "rhode island",
  sc: "south carolina",
  sd: "south dakota",
  tn: "tennessee",
  tx: "texas",
  ut: "utah",
  vt: "vermont",
  va: "virginia",
  wa: "washington",
  wv: "west virginia",
  wi: "wisconsin",
  wy: "wyoming"
};

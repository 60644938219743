export const getUpdatedCityFilter = (states, filterObj) => {
  if (states === "all-selected") return;
  let stateCodes = states.map((state) => state.split("_")[0]);
  return filterObj.city.filter((c) => stateCodes.includes(c.toLowerCase().split(", ")[1]));
};

export const getUpdatedSubindustryFilter = (industries, filterObj, industryList) => {
  let newSubindustryFilter = [];
  let curtIndToSubind = {};
  filterObj.subindustry.forEach((value) => {
    if (value.slice(0, 8) !== "industry") {
      const [, industryName] = value.split("_");
      if (!curtIndToSubind[industryName]) curtIndToSubind[industryName] = [value];
      else curtIndToSubind[industryName].push(value);
    }
  });
  if (industries[0] === "all-selected") industries = industryList.map(({ name }) => name.toLowerCase());
  industries.forEach((industryName) => {
    if (!curtIndToSubind[industryName]) newSubindustryFilter.push(`industry_${industryName}`);
    else newSubindustryFilter.push(...curtIndToSubind[industryName]);
  });
  return newSubindustryFilter;
};

export const getIndustryOptions = (industryToSubs, filterObj) => {
  let { industry } = filterObj;
  let indOptions = [];
  if (industry && industry[0] === "all-selected") {
    for (let keyVal in industryToSubs) {
      indOptions.push(industryToSubs[keyVal]);
    }
  } else {
    industry.sort().forEach((keyVal) => industryToSubs[keyVal] && indOptions.push(industryToSubs[keyVal]));
  }
  return indOptions;
};

// Following we are importing all steps (in alphabetical order)
import Banner from "./Banner";
import Card from "./Card";
import ClickToActions from "./ClickToActions";
import Highlights from "./Highlights";
import Meta from "./Meta";
import Result from "./Result";
import SneakPeak from "./SneakPeak";
import Webinar from "./Webinar";
import Meeting from "./Meeting";
import Overview from "./Overview";
import Podcast from "./Podcast";

const constants = {
    steps: {
        webinar: [
            {
                title: "Webinar",
                content: Webinar,
                dataKey: "webinar"
            },
            {
                title: "Card",
                content: Card,
                dataKey: "card"
            },
            {
                title: "Meta",
                content: Meta,
                dataKey: "meta"
            },
            {
                title: "Banner",
                content: Banner,
                dataKey: "banner"
            },
            {
                title: "Highlights",
                content: Highlights,
                dataKey: "highlights",
                isOptional: true,
                description: "(optional)"
            },
            {
                title: "Sneak Peek",
                content: SneakPeak,
                dataKey: "sneakPeak",
                isOptional: true,
            },
            {
                title: "Stats",
                content: Result,
                dataKey: "result",
                isOptional: true,
            },
            {
                title: "CTA",
                content: ClickToActions,
                dataKey: "cta"
            },
            {
                title: "Publish",
                content: Overview,
                dataKey: "overview",
                isIgnored: true
            }
        ],
        meeting: [
            {
                title: "Meeting",
                content: Meeting,
                dataKey: "meeting"
            },
            {
                title: "Card",
                content: Card,
                dataKey: "card"
            },
            {
                title: "Meta",
                content: Meta,
                dataKey: "meta"
            },
            {
                title: "Banner",
                content: Banner,
                dataKey: "banner"
            },
            {
                title: "Highlights",
                content: Highlights,
                dataKey: "highlights",
                isOptional: true,
                description: "(optional)"
            },
            {
                title: "Sneak Peek",
                content: SneakPeak,
                dataKey: "sneakPeak",
                isOptional: true,
            },
            {
                title: "Stats",
                content: Result,
                dataKey: "result",
                isOptional: true,
            },
            {
                title: "CTA",
                content: ClickToActions,
                dataKey: "cta"
            },
            {
                title: "Publish",
                content: Overview,
                dataKey: "overview",
                isIgnored: true
            }
        ],
        ebook: [
            {
                title: "Card",
                content: Card,
                dataKey: "card"
            },
            {
                title: "Meta",
                content: Meta,
                dataKey: "meta"
            },
            {
                title: "Banner",
                content: Banner,
                dataKey: "banner"
            },
            {
                title: "Highlights",
                content: Highlights,
                dataKey: "highlights",
                isOptional: true,
            },
            {
                title: "Sneak Peek",
                content: SneakPeak,
                dataKey: "sneakPeak",
                isOptional: true,
            },
            {
                title: "Stats",
                content: Result,
                dataKey: "result",
                isOptional: true,
            },
            {
                title: "CTA",
                content: ClickToActions,
                dataKey: "cta"
            },
            {
                title: "Publish",
                content: Overview,
                dataKey: "overview",
                isIgnored: true
            }
        ],
        whitepaper: [
            {
                title: "Card",
                content: Card,
                dataKey: "card"
            },
            {
                title: "Meta",
                content: Meta,
                dataKey: "meta"
            },
            {
                title: "Banner",
                content: Banner,
                dataKey: "banner"
            },
            {
                title: "Highlights",
                content: Highlights,
                dataKey: "highlights",
                isOptional: true,
            },
            {
                title: "Sneak Peek",
                content: SneakPeak,
                dataKey: "sneakPeak",
                isOptional: true,
            },
            {
                title: "Stats",
                content: Result,
                dataKey: "result",
                isOptional: true,
            },
            {
                title: "CTA",
                content: ClickToActions,
                dataKey: "cta"
            },
            {
                title: "Publish",
                content: Overview,
                dataKey: "overview",
                isIgnored: true
            }
        ],
        guide: [
            {
                title: "Card",
                content: Card,
                dataKey: "card"
            },
            {
                title: "Meta",
                content: Meta,
                dataKey: "meta"
            },
            {
                title: "Banner",
                content: Banner,
                dataKey: "banner"
            },
            {
                title: "Highlights",
                content: Highlights,
                dataKey: "highlights",
                isOptional: true,
            },
            {
                title: "Sneak Peek",
                content: SneakPeak,
                dataKey: "sneakPeak",
                isOptional: true,
            },
            {
                title: "Stats",
                content: Result,
                dataKey: "result",
                isOptional: true,
            },
            {
                title: "CTA",
                content: ClickToActions,
                dataKey: "cta"
            },
            {
                title: "Publish",
                content: Overview,
                dataKey: "overview",
                isIgnored: true
            }
        ],
        podcast: [
            {
                title: "Card",
                content: Card,
                dataKey: "card"
            },
            {
                title: "Meta",
                content: Meta,
                dataKey: "meta"
            },
            {
                title: "Podcast",
                content: Podcast,
                dataKey: "podcast"
            },
            {
                title: "Publish",
                content: Overview,
                dataKey: "overview",
                isIgnored: true
            }
        ]
    },
    webinar: {
        timezoneOptions: {
            au: [
                {value: "Australia/Sydney", label: "GMT+10:00 | Australia/Sydney"},
                {value: "Australia/Brisbane", label: "GMT+10:00 | Australia/Brisbane"},
                {value: "Australia/Darwin", label: "GMT+09:30 | Australia/Darwin"},
                {value: "Australia/Adelaide", label: "GMT+09:30 | Australia/Adelaide"},
                {value: "Australia/Hobart", label: "GMT+10:00 | Australia/Hobart"},
                {value: "Australia/Perth", label: "GMT+08:00 | Australia/Perth"}
            ],
            us: [
                { value: "America/New_York", label: "GMT-05:00 | Eastern Standard Time" },
                { value: "America/Chicago", label: "GMT-06:00 | Central Standard Time" },
                { value: "America/Denver", label: "GMT-07:00 | Mountain Standard Time" },
                { value: "America/Los_Angeles", label: "GMT-08:00 | Pacific Standard Time" },
                { value: "America/Anchorage", label: "GMT-09:00 | Alaska Standard Time" },
                { value: "Pacific/Honolulu", label: "GMT-10:00 | Hawaii-Aleutian Standard Time" },
                { value: "America/Phoenix", label: "GMT-07:00 | Mountain Standard Time (no DST)" },
                { value: "America/Boise", label: "GMT-07:00 | Mountain Standard Time (no DST)" },
                { value: "America/Indiana/Indianapolis", label: "GMT-05:00 | Eastern Standard Time" },
                { value: "America/Kentucky/Louisville", label: "GMT-05:00 | Eastern Standard Time" },
                { value: "America/Detroit", label: "GMT-05:00 | Eastern Standard Time" }
            ],
            ca: [
                { value: "America/Toronto", label: "GMT-05:00 | Eastern Standard Time (EST)" },
                { value: "America/Montreal", label: "GMT-05:00 | Eastern Standard Time (EST)" },
                { value: "America/Halifax", label: "GMT-04:00 | Atlantic Standard Time (AST)" },
                { value: "America/St_Johns", label: "GMT-03:30 | Newfoundland Standard Time (NST)" },
                { value: "America/Winnipeg", label: "GMT-06:00 | Central Standard Time (CST)" },
                { value: "America/Regina", label: "GMT-06:00 | Central Standard Time (CST)" },
                { value: "America/Edmonton", label: "GMT-07:00 | Mountain Standard Time (MST)" },
                { value: "America/Vancouver", label: "GMT-08:00 | Pacific Standard Time (PST)" },
                { value: "America/Yellowknife", label: "GMT-07:00 | Mountain Standard Time (MST)" },
                { value: "America/Iqaluit", label: "GMT-05:00 | Eastern Standard Time (EST)" }
            ],
            nz: [
                { value: "Pacific/Auckland", label: "GMT+12:00 | New Zealand Standard Time (NZST)" },
                { value: "Pacific/Chatham", label: "GMT+12:45 | Chatham Standard Time (CHAST)" }
            ],
            uk: [
                { value: "Europe/London", label: "GMT+00:00 | Greenwich Mean Time (GMT)" }
            ]              
        },
        intervals: [
            {label: "15 minutes", value: 15},
            {label: "30 minutes", value: 30},
            {label: "45 minutes", value: 45},
            {label: "1 hour", value: 60},
            {label: "1 and half hour", value: 90},
            {label: "2 hours", value: 120},
            {label: "Custom", value: "custom"}
        ],
        hostPlatforms: [
            {label: "Goldcast", value: "goldcast"},
            {label: "On 24", value: "on24"}
        ],
        countries: [
            {
                "value": "au",
                "label": "Australia"
            },
            {
                "value": "ca",
                "label": "Canada"
            },
            {
                "value": "nz",
                "label": "New Zealand"
            },
            {
                "value": "uk",
                "label": "United Kingdom"
            },
            {
                "value": "us",
                "label": "United States"
            }
        ]
    },
    meeting: {
        timezoneOptions: [
            {value: "Pacific/Tongatapu", label: "GMT+13:00 | Pacific/Tongatapu", id: "87"},
            {value: "Pacific/Fiji", label: "GMT+12:00 | Pacific/Fiji", id: "42"},
            {value: "Pacific/Auckland", label: "GMT+12:00 | Pacific/Auckland", id: "41"},
            {value: "Asia/Magadan", label: "GMT+11:00 | Asia/Magadan", id: "76"},
            {value: "Asia/Vladivostok", label: "GMT+10:00 | Asia/Vladivostok", id: "39"},
            {value: "Australia/Hobart", label: "GMT+10:00 | Australia/Hobart", id: "38"},
            {value: "Pacific/Guam", label: "GMT+10:00 | Pacific/Guam", id: "37"},
            {value: "Australia/Sydney", label: "GMT+10:00 | Australia/Sydney", id: "36"},
            {value: "Australia/Brisbane", label: "GMT+10:00 | Australia/Brisbane", id: "35"},
            {value: "Australia/Darwin", label: "GMT+09:30 | Australia/Darwin", id: "34"},
            {value: "Australia/Adelaide", label: "GMT+09:30 | Australia/Adelaide", id: "33"},
            {value: "Asia/Yakutsk", label: "GMT+09:00 | Asia/Yakutsk", id: "32"},
            {value: "Asia/Seoul", label: "GMT+09:00 | Asia/Seoul", id: "31"},
            {value: "Asia/Tokyo", label: "GMT+09:00 | Asia/Tokyo", id: "30"},
            {value: "Asia/Taipei", label: "GMT+08:00 | Asia/Taipei", id: "29"},
            {value: "Australia/Perth", label: "GMT+08:00 | Australia/Perth", id: "27"},
            {value: "Asia/Irkutsk", label: "GMT+08:00 | Asia/Irkutsk", id: "86"},
            {value: "Asia/Shanghai", label: "GMT+08:00 | Asia/Shanghai", id: "26"},
            {value: "Asia/Krasnoyarsk", label: "GMT+07:00 | Asia/Krasnoyarsk", id: "85"},
            {value: "Asia/Bangkok", label: "GMT+07:00 | Asia/Bangkok", id: "25"},
            {value: "Asia/Jakarta", label: "GMT+07:00 | Asia/Jakarta", id: "25"},
            {value: "Asia/Rangoon", label: "GMT+06:30 | Asia/Rangoon", id: "84"},
            {value: "Asia/Colombo", label: "GMT+06:00 | Asia/Colombo", id: "24"},
            {value: "Asia/Dhaka", label: "GMT+06:00 | Asia/Dhaka", id: "23"},
            {value: "Asia/Novosibirsk", label: "GMT+06:00 | Asia/Novosibirsk", id: "85"},
            {value: "Asia/Calcutta", label: "GMT+05:30 | Asia/Calcutta", id: "22"},
            {value: "Asia/Karachi", label: "GMT+05:00 | Asia/Karachi", id: "21"},
            {value: "Asia/Yekaterinburg", label: "GMT+05:00 | Asia/Yekaterinburg", id: "20"},
            {value: "Asia/Kabul", label: "GMT+04:30 | Asia/Kabul", id: "19"},
            {value: "Asia/Tbilisi", label: "GMT+04:00 | Asia/Tbilisi", id: "80"},
            {value: "Asia/Muscat", label: "GMT+04:00 | Asia/Muscat", id: "17"},
            {value: "Asia/Tehran", label: "GMT+03:30 | Asia/Tehran", id: "16"},
            {value: "Africa/Nairobi", label: "GMT+03:00 | Africa/Nairobi", id: "15"},
            {value: "Europe/Moscow", label: "GMT+03:00 | Europe/Moscow", id: "14"},
            {value: "Asia/Kuwait", label: "GMT+03:00 | Asia/Kuwait", id: "13"},
            {value: "Asia/Baghdad", label: "GMT+03:00 | Asia/Baghdad", id: "79"},
            {value: "Asia/Jerusalem", label: "GMT+02:00 | Asia/Jerusalem", id: "12"},
            {value: "Europe/Helsinki", label: "GMT+02:00 | Europe/Helsinki", id: "11"},
            {value: "Africa/Harare", label: "GMT+02:00 | Africa/Harare", id: "10"},
            {value: "Africa/Cairo", label: "GMT+02:00 | Africa/Cairo", id: "9"},
            {value: "Europe/Bucharest", label: "GMT+02:00 | Europe/Bucharest", id: "7"},
            {value: "Europe/Athens", label: "GMT+02:00 | Europe/Athens", id: "7"},
            {value: "Europe/Warsaw", label: "GMT+01:00 | Europe/Warsaw", id: "4"},
            {value: "Europe/Brussels", label: "GMT+01:00 | Europe/Brussels", id: "3"},
            {value: "Europe/London", label: "GMT+01:00 | Europe/London", id: "2"},
            {value: "Africa/Casablanca", label: "GMT+01:00 | Africa/Casablanca", id: "1"},
            {value: "GMT", label: "GMT | GMT", id: "1"},
            {value: "Atlantic/Cape_Verde", label: "GMT-01:00 | Atlantic/Cape_Verde", id: "72"},
            {value: "Atlantic/Azores", label: "GMT-01:00 | Atlantic/Azores", id: "43"},
            {value: "America/Buenos_Aires", label: "GMT-03:00 | America/Buenos_Aires", id: "69"},
            {value: "America/Sao_Paulo", label: "GMT-03:00 | America/Sao_Paulo", id: "45"},
            {value: "America/St_Johns", label: "GMT-03:30 | America/St_Johns", id: "47"},
            {value: "America/Santiago", label: "GMT-04:00 | America/Santiago", id: "68"},
            {value: "America/Caracas", label: "GMT-04:00 | America/Caracas", id: "66"},
            {value: "America/Indianapolis", label: "GMT-05:00 | America/Indianapolis", id: "52"},
            {value: "America/Bogota", label: "GMT-05:00 | America/Bogota", id: "50"},
            {value: "America/Mexico_City", label: "GMT-06:00 | America/Mexico_City", id: "54"},
            {value: "America/Phoenix", label: "GMT-07:00 | America/Phoenix", id: "56"},
            {value: "America/Los_Angeles", label: "GMT-08:00 | America/Los_Angeles", id: "58"},
            {value: "America/Anchorage", label: "GMT-09:00 | America/Anchorage", id: "59"},
            {value: "Pacific/Honolulu", label: "GMT-10:00 | Pacific/Honolulu", id: "60"}
        ],
        intervals: [
            {label: "15 minutes", value: 15},
            {label: "30 minutes", value: 30},
            {label: "45 minutes", value: 45},
            {label: "1 hour", value: 60},
            {label: "1 and half hour", value: 90},
            {label: "2 hours", value: 120},
            {label: "3 hours", value: 180},
            {label: "Custom", value: "custom"}
        ]
    },
    banner: {
        imageResolutions: [
            { key: "1x", pd: "" },
            { key: "2x", pd: "2x" },
            { key: "3x", pd: "3x" },
        ],
        defaultTag: {
            webinar: "Free Webinar",
            ebook: "Free ebook",
            whitepaper: "Free Whitepaper",
            guide: "Free Guide",
            guide_episode: "The Break Room"
        }
    },
    card: {
        linkSuffix: {
            webinar: "resources/webinars/",
            meeting: "meetings/",
            ebook: "resources/ebooks/",
            whitepaper: "resources/white-papers/",
            guide: "resources/guides/",
            podcast: "resources/podcast/"
        },
        imageResolutions: [
            { key: "1x", pd: "" },
            { key: "2x", pd: "2x" },
            { key: "3x", pd: "3x" },
        ]
    },
    highlights: {
        imageResolutions: [
            { key: "1x", pd: "" },
            { key: "2x", pd: "2x" },
            { key: "3x", pd: "3x" },
        ],
        alignmentOptions: [
            {
                label: "One Column",
                value: "oneCol"
            },
            {
                label: "Two Column",
                value: "twoCol"
            },
        ],
        withHeading: [
            {
                label: "with heading",
                value: true
            },
            {
                label: "without heading",
                value: false
            }
        ],
        defaultTitle: {
            webinar: "In this webinar, you’ll hear:",
            meetings: "In this event, you’ll hear:",
            ebook: "In this ebook, you’ll learn:",
            whitepaper: "In this whitepaper, you’ll learn:",
            guide: "In this guide, you’ll learn:"
        }
    },
    sneakPeak: {
        mediaOptions: [
            {
                label: "Video Clip",
                value: "video"
            },
            {
                label: "Carousal",
                value: "carousal"
            },
        ],
        defaultMediaOption: {
            webinar: "video",
            ebook: "carousal",
            whitepaper: "carousal",
            guide: "carousal",
        }
    }, 
    result: {
        resultOptions: [
            {
                title: "Birdeye gets you real results that help you be found",
                rates: [
                    {
                        heading: "+400%",
                        desc: "Calls from Google"
                    },
                    {
                        heading: "+440%",
                        desc: "Directions from Google"
                    },
                    {
                        heading: "+450%",
                        desc: "Site traffic from Google"
                    }
                ],
                information: "Data is from over 100,000 Birdeye business customers"
            },
            {
                title: "Birdeye gets you real results that help you be chosen",
                rates: [
                    {
                        heading: "+350%",
                        desc: "Increase in Google Reviews"
                    },
                    {
                        heading: "+0.8 Stars",
                        desc: "Increase in Google rating"
                    },
                    {
                        heading: "+10%",
                        desc: "Increase in revenue"
                    }
                ],
                information: "Data is from over 100,000 Birdeye business customers"
            },
            {
                title: "Birdeye gets you real results that help you be connected",
                rates: [
                    {
                        heading: "+20%",
                        desc: "Leads per month"
                    },
                    {
                        heading: "35%",
                        desc: "Decrease in response time"
                    },
                    {
                        heading: "95%",
                        desc: "Open rate for SMS"
                    }
                ],
                information: "Data is from over 100,000 Birdeye business customers"
            },
            {
                title: "Birdeye gets you real results that help you be the best",
                rates: [
                    {
                        heading: "20 million",
                        desc: "NPS surveys run on Birdeye"
                    },
                    {
                        heading: "1 billion +",
                        desc: "AI-driven insights generated"
                    },
                    {
                        heading: "100,000 +",
                        desc: "Customer issues resolved"
                    }
                ],
                information: "Data is from over 100,000 Birdeye business customers"
            }
        ]
    },
    cta: {
        defaultPrimaryCTA: {
            webinar: { label: "Register Now", action: "/register-now/" },
            meetings: { label: "Register Now", action: "/register-now/" },
            ebook: { label: "Download Now", action: "/download-now/" },
            whitepaper: { label: "Download Now", action: "/download-now/" },
            guide: { label: "Download Now", action: "/download-now/" },
        },
        primaryCTAOptions: [
            {
                label: "Yes",
                value: 1
            },
            {
                label: "No",
                value: 0
            },
        ],
        buttonOptions: [
            {
                label: "Full Filled",
                value: "full"
            },
            {
                label: "Half filled",
                value: "half"
            },
        ]
    },
    podcast: {}
};

export default constants;

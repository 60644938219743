import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tag, message, Tooltip, Icon, Modal, Button } from "antd";
import _ from "lodash";
import twowayIcon from '../../../assets/images/repeat.svg';

import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";

import { splitter, getIsPageActionAccessible } from "../../utils";
import lookup from "./integrations.json";
import "./Integrations.scss";

const order = [ ...lookup.value.typeOptions, "" ];

class Integrations extends Component {

    constructor(props) {
        super(props);
        this.pageKey = "integrationsList";
    }
    
    state = {
        filteredData: {}
    };

    componentDidMount() {
        this.props.fetchIntegrationsListRequest();
        this.props.fetchCategoriesListRequest();
    }

    reformatData = (record) => {
        if(typeof record === "object") {
            const orderedIntegrationType = this.customSortData({data: (record || []), order});
            return orderedIntegrationType.map((type, i) => (
                <Tag color="blue" key={i}>
                    {type}
                </Tag>
            ))
        } else if(typeof record === "string") {
            const types = record.split(', ');
            const orderedIntegrationType = this.customSortData({data: (types || []), order});
            return orderedIntegrationType.map((type, i) => (
                <Tag color="blue" key={i}>
                    {type}
                </Tag>
            ))
        }
    }
    separateByComma = (record) => {
        if(typeof record === "object") {
            const orderedIntegrationCountries = this.customSortData({data: (record || []), order});
            if(orderedIntegrationCountries.length > 2){
                return  <Tooltip title={orderedIntegrationCountries.join(', ')}>
                            <span>{orderedIntegrationCountries.slice(0,2).join(', ').concat(` & ${orderedIntegrationCountries.length - 2}  more`)}</span>
                        </Tooltip>
            }
            return orderedIntegrationCountries.join(', ');
        } else if(typeof record === "string") {
            const countries = record.split(', ');
            const orderedIntegrationCountries = this.customSortData({data: (countries || []), order});
            if(orderedIntegrationCountries.length > 2){
                return orderedIntegrationCountries.slice(0,2).join(', ').concat(` & ${orderedIntegrationCountries.length - 2}  more`);
            }
            return orderedIntegrationCountries;
        }
    }
    reformatProductsData = (record) => {
        if(typeof record === "object") {
            const orderedIntegrationType = this.customSortData({data: (record || []), order});
            return orderedIntegrationType.map((type, i) => (
                <Tag color="blue" key={i}>
                 { type.includes('2way') ? <> <Icon component={() => (<img src={twowayIcon} style={{marginTop:'-5px'}} />)}  /> {type.replace('2way','')} </> : <> {type} </> }
                </Tag>
            ))
        } else if(typeof record === "string") {
            const products = record.split(', ');
            const orderedIntegrationType = this.customSortData({data: (products || []), order});
            return orderedIntegrationType.map((type, i) => (
                <Tag color="blue" key={i}>
                    { type.includes('2way') ? <> <Icon component={() => (<img src={twowayIcon} style={{marginTop:'-5px'}} />)} /> {type.replace('2way','')} </> : <> {type} </>}
                </Tag>
            ))
        }
    }
    formatData = (data) => {
        return data.map((record) => {
            return {
                ...record,
                key: record.id,
                supported: record.enabled ? "Supported" : "Non-supported",
                modifiedName: record.enabled ?  <span> <Tooltip title="Supported"><Icon type="check-circle" theme="filled" style={{color:"#4CAF50", margin:"5px"}} /></Tooltip>{ record.isArchived ? `${record.name} [Archived]` : `${record.name}`}</span>  : <span> <Tooltip title="Not supported"><Icon type="exclamation-circle" theme="filled" style={{color:"#F57C00",margin:"5px"}} /></Tooltip>{ record.isArchived ? `${record.name} [Archived]` : `${record.name}`}</span>,
                premium: record.isPremium ? "Premium" : "Non-premium",
                type: (record.type && record.type.length > 0 ? (this.reformatData(record.type)) : "-"),
                productsForTable: (record.products && record.products.length > 0 ? (this.reformatProductsData(record.products)) : "-"),
                countriesForTable: (record.countries && record.countries.length > 0 ? this.separateByComma(record.countries)  : "-"),
                marketPlaceEnable: (record.isMarketPlaceEnable ? "Enabled" : "Disabled")
            };
        });
    };

    handleDelete = (data) => {
        const { id } = data;
        this.props.deleteIntegrationRequest({ id, cb: this.renderFeedbackMsg });
    };

    renderFeedbackMsg = (errorMsg) => {
        if (!errorMsg) {
            return message.success(
                "You have successfully deleted this record."
            );
        } else {
            return message.error(
                "Something went wrong... Please try again later."
            );
        }
    };

    customSortData = ({data, order}) => {
        const sortByObject = order.reduce((obj, item, index) => {
            return {
                ...obj,
                [item]: index
            }
        }, {});
        return data.sort((a, b) => sortByObject[a] - sortByObject[b]);
    };

    renderExpandedRow = (record) => {
        const orderedIntegrationType = this.customSortData({data: ( Array.isArray(record.type) ? record.type : []), order});

        let isZapier;
        if( orderedIntegrationType.length ) {
                isZapier = orderedIntegrationType.some((element) => {
                return element.props.children.includes('Zapier')
            });
        }

        // const isZapier = 

        return (
            <div>
                <div className="mb1">
                    <b>Version: </b>
                    <span>{record.version || "N/A"}</span>
                </div>
                <div className="mb1">
                    <b>Integration Type </b>
                    <Tooltip title="Integration types are displayed in the order of their priority" >
                        <Icon type="info-circle" theme="filled" /> <b> : </b>
                    </Tooltip>
                    {orderedIntegrationType.length ? orderedIntegrationType.map((type, i) => (
                        type
                    )) : "N/A"}
                </div>
                <div className="mb1">
                    <b>Availability: </b>
                    {record.availability.length ? record.availability.map((availability, i) => (
                        <Tag color="blue" key={i}>
                            {availability}
                        </Tag>
                    )) : "N/A"}
                </div>
                {
                    record.url && !isZapier &&
                            <div className="mb1">
                                <b>Default Page URL: </b>
                                <a href={`${process.env.REACT_APP_WEBSITE_URL}integration/${record.url}/`} target="_blank" rel="noopener noreferrer">
                                    {`${process.env.REACT_APP_WEBSITE_URL}integration/${record.url}/`}
                                </a>
                            </div>
                }
                <div className="mb1">
                    <b>Technical Document </b>
                    <Tooltip title="Confidential - Internal use only" >
                        <Icon type="info-circle" theme="filled" />
                    </Tooltip>
                    <b> : </b>
                    <span>{record.technicalDocument ? <a href={`${record.technicalDocument}`} target="_blank" rel="noopener noreferrer">{record.technicalDocument}</a> : "N/A"}</span>
                </div>
                <div className="mb1">
                    <b>Heading: </b>
                    <span>{record.heading ? splitter(record.heading) : "N/A"}</span>
                </div>
                <div className="mb1">
                    <b>Trigger Supported: </b>
                    <span>{record.triggerSupported ? splitter(record.triggerSupported) : "N/A"}</span>
                </div>
                <div className="mb1">
                    <b>Location: </b>
                    <span>{record.location ? splitter(record.location) : "N/A"}</span>
                </div>
                <div className="mb1">
                    <b>Special Instructions: </b>
                    <span>{record.specialInstructions ? splitter(record.specialInstructions) : "N/A"}</span>
                </div>
                <div className="mb1">
                    <b>Lessonly Link: </b>
                    <span>{record.lessonlyLink ? <a href={`${record.lessonlyLink}`} target="_blank" rel="noopener noreferrer">{record.lessonlyLink}</a> : "N/A"}</span>
                </div>
                <div className="mb1">
                    <b>Training Deck Link: </b>
                    <span>{record.trainingResourcesDoc ? <a href={`${record.trainingResourcesDoc}`} target="_blank" rel="noopener noreferrer">{record.trainingResourcesDoc}</a> : "N/A"}</span>
                </div>
                <div className="mb1">
                    <b>Training Recording Link: </b>
                    <span>{record.trainingResourcesLink ? <a href={`${record.trainingResourcesLink}`} target="_blank" rel="noopener noreferrer">{record.trainingResourcesLink}</a> : "N/A"}</span>
                </div>
                <div className="mb1">
                    <b>Technical Document Feedback Form: </b>
                    <span>{record.technicalDocumentFeedbackForm ? <a href={`${record.technicalDocumentFeedbackForm}`} target="_blank" rel="noopener noreferrer">{record.technicalDocumentFeedbackForm}</a> : "N/A"}</span>
                </div>
                <div className="mb1">
                    <b>Created By: </b>
                    <span>{record.createdBy ? splitter(record.createdBy) : "N/A"}</span>
                </div>
                <div className="mb1">
                    <b>Updated By: </b>
                    <span>{record.updatedBy ? splitter(record.updatedBy) : "N/A"}</span>
                </div>
                <div className="mb1">
                    <b>Partnership: </b>
                    <span>{record.partnership ? record.partnership : "N/A" }</span>
                </div>
                <div className="mb1">
                    <b>Show CRM on website: </b>
                    <span>{record.isPublished ? "Yes" : "No" }</span>
                </div>
                <div className="mb1">
                    <b>Show CRM on salesforce: </b>
                    <span>{record.isSalesforce ? "Yes" : "No" }</span>
                </div>
                <div className="mb1">
                    <b>Exclusions: </b>
                    <span>{record.isExclusions ? "Yes" : "No" }</span>
                </div>
            </div>
        );
    };

    searchAndFilter = (pagination, filters, sorter) => {
        if(!_.isEmpty(filters)) {
            this.setState({
                filteredData: {... filters},
            });
        } else {
            this.setState({
                filteredData: {}
            });
        }
    };

    columnFilter = (row, columnName, filterData) => { 
        if(filterData.length == 0){
            return true;
        }

        if (columnName === 'integrationTier') {
            const integrationTierRow = row.integrationTier || "";

            return filterData.includes(integrationTierRow);

        } else if (columnName === 'products') {
            const productsRow = row[columnName] || [];
            
            return productsRow.find((product) => filterData.includes(product.replace(' 2way','')) || filterData.includes(product) ) ? true : false;
        
        } else if (columnName === 'isMarketPlaceEnable') {
            const marketPlace = row[columnName];
            return filterData.includes(marketPlace);
        } else {
            const filterRow = row[columnName] || []; 

            return filterData.find((ele) => filterRow.includes(ele)) ? true : false;
        }
    }

    handleAllFilters = (allRows, filteredData) => {
        const countries = filteredData.countriesForTable || [];
        const products = filteredData.productsForTable || [];
        const integrationTier = filteredData.integrationTier || [];
        const integrationTypes = filteredData.type || [];
        const marketPlaceEnable = filteredData.marketPlaceEnable || [];


        return allRows.filter((row) => {
            const isRowAvail = this.columnFilter(row, "countries", countries) && this.columnFilter(row, "products", products) && this.columnFilter(row, "integrationTier", integrationTier) && this.columnFilter(row, "type", integrationTypes) && this.columnFilter(row, "isMarketPlaceEnable", marketPlaceEnable);
            if(isRowAvail){
                return row;
            } 
        });
    }

    archiveIntegration = (record) => {
        const rawData = { ...record };
        const types = [];
        const productsModules = [];
        delete rawData['countriesForTable'];
        delete rawData['modifiedName'];
        delete rawData['productsForTable'];
        if(Array.isArray(rawData.type))
        rawData.type.map(function(type) {
            type.props ? types.push(type.props.children) : types.push(type);
        });

        if(Array.isArray(rawData.products))
        rawData.products.map(function(product) {
            product.props ? productsModules.push(product.props.children) : productsModules.push(product);
        });

        let data = {
            ...rawData,
            type: types.join(", "),
            availability: Array.isArray(rawData.availability) ? rawData.availability.join(", "): rawData.availability,
            countries:  Array.isArray(rawData.countries) ? rawData.countries.join(", ") : rawData.countries,
            products:   productsModules.join(", "),
            isArchived: !rawData.isArchived
        };

        if (data.id) {
            this.props.updateIntegrationRequest({ data, cb: this.renderFeedback });
        }
    }

    renderFeedback = (errorType) => {
        this.props.fetchIntegrationsListRequest();
        if (!errorType) {
            return Modal.success({
                title: `You have successfully updated the record`,
                maskClosable: true
            });
        } else {
            return Modal.warning({
                title: 'The record was not updated',
                content: "Please check your entry or try again later..."
            });
        }
    };

    renderFeedback = (errorType) => {
        this.props.fetchIntegrationsListRequest();
        if (!errorType) {
            return Modal.success({
                title: `You have successfully updated the record`,
                maskClosable: true
            });
        } else {
            return Modal.warning({
                title: 'The record was not updated',
                content: "Please check your entry or try again later..."
            });
        }
    };

    render() {
        const { data, isLoading, error, userData } = this.props;
        const { filteredData } = this.state;
        let dataFinal = [];
        if(!_.isEmpty(filteredData)) {
            dataFinal = this.handleAllFilters(data, filteredData);
        } else {
            dataFinal = data;
        }

        const { name } = userData;
        const unauthorized = error === "unauthorized" || !name;
        const isActionAccessible = getIsPageActionAccessible(this.pageKey, userData.privileges);
        const handleArchiveCRM = [
            { 
                check: () => true ,
                render: (record) => (
                    {
                        cb: () => this.archiveIntegration(record, isActionAccessible),
                        label: record.isArchived ? "Restore" : "Archive",
                        path: ""
                    }
                )
            }
        ];

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location}/>;
        }

        const customColumns = [...lookup.columns];

        if(isActionAccessible){
            customColumns.push({
                "title": "Market Place",
                "key": "marketPlaceEnable",
                "filters" : [
                    { "text": "Enabled", "value": true },
                    { "text": "Disabled", "value": false }
                ]
            })
        }

        return (
            <div className="integrations-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <h4 style={{marginTop: "-1em", marginBottom: "1.5em"}}>Anything listed/not listed here, can always be integrated by clients by calling our APIs or sending a SFTP file which is a development effort for the client</h4>
                <TableSkeleton
                        columns={customColumns}
                        pathForEdit={isActionAccessible ? "/integration/" : ""}
                        handleDelete={isActionAccessible ? this.handleDelete : ""}
                        expandedRowRender={this.renderExpandedRow}
                        dataSource={this.formatData(dataFinal)}
                        onChange={this.searchAndFilter}
                        loading={isLoading}
                        rowClassName={({...dataFinal}) => dataFinal.isExclusions ? "custom-text-color" : "" }
                        additionalHandler={handleArchiveCRM}
                    />
            </div>
        );
    }
}

Integrations.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchIntegrationsListRequest: PropTypes.func.isRequired,
    deleteIntegrationRequest: PropTypes.func.isRequired,
    updateIntegrationRequest: PropTypes.func.isRequired
};

export default Integrations;

import { takeLatest, call, put } from "redux-saga/effects";
import ProspectorActions, * as actionCreator from "./actions";
import prospectorApi from "./http";

import { formatStateData, formatIndustryData, formatOwnerData } from "../utils";

const prospectorSagas = [
  takeLatest(ProspectorActions.FETCH_RECORDS_REQUEST, fetchRecords),
  takeLatest(ProspectorActions.FETCH_TARGET_PROFILE_REQUEST, fetchProfile),
  takeLatest(ProspectorActions.FETCH_STATE_LIST_REQUEST, fetchStates),
  takeLatest(ProspectorActions.FETCH_CITY_LIST_REQUEST, fetchCities),
  takeLatest(ProspectorActions.FETCH_INDUSTRY_LIST_REQUEST, fetchIndustries),
  takeLatest(ProspectorActions.FETCH_OWNER_LIST_REQUEST, fetchOwners)
];

function* fetchRecords(action) {
  try {
    const resp = yield call(prospectorApi.getRecord, action.payload);
    yield put(actionCreator.fetchRecordsSucess(resp.data));
    yield put(actionCreator.updateRecordCount());
  } catch (e) {
    yield put(actionCreator.fetchRecordsFailure(e.data.data || { error: "cannot load records" }));
  }
}

function* fetchProfile(action) {
  try {
    const resp = yield call(prospectorApi.getTargetProfile, action.payload);
    yield put(actionCreator.fetchTargetProfileSuccess(resp.data[0]));
    yield put(actionCreator.updateRecordCount());
  } catch (e) {
    yield put(actionCreator.fetchTargetProfileFailure(e.data.data || { error: "cannot load profile" }));
  }
}

function* fetchStates() {
  try {
    const resp = yield call(prospectorApi.getStates);
    yield put(actionCreator.fetchStateListSuccess(formatStateData(resp.data)));
  } catch (e) {
    yield put(actionCreator.fetchFormOptionFailure(e.data.data || { error: "cannot load state data" }));
  }
}

function* fetchCities() {
  try {
    const resp = yield call(prospectorApi.getCities);
    yield put(actionCreator.fetchCityListSuccess(resp.data));
  } catch (e) {
    yield put(actionCreator.fetchFormOptionFailure(e.data.data || { error: "cannot load city data" }));
  }
}

function* fetchIndustries() {
  try {
    const resp = yield call(prospectorApi.getIndustries);
    yield put(actionCreator.fetchIndustryListSuccess(formatIndustryData(resp.data)));
  } catch (e) {
    yield put(actionCreator.fetchFormOptionFailure(e.data.data || { error: "cannot load industry data" }));
  }
}

function* fetchOwners() {
  try {
    const resp = yield call(prospectorApi.getOwners);
    yield put(actionCreator.fetchOwnerListSuccess(formatOwnerData(resp.data)));
  } catch (e) {
    yield put(actionCreator.fetchFormOptionFailure(e.data.data || { error: "cannot load owner data" }));
  }
}

export default prospectorSagas;

import { takeLatest, call, put } from "redux-saga/effects";
import ScitorAction, * as actionCreator from "./action";
import scitorAPI from "./http";

const scitorSaga = [
    takeLatest(ScitorAction.FILE_UPLOAD_REQUEST, fileUploadRequest),
];

function* fileUploadRequest(action) {
    try {
        const resp = yield call(scitorAPI.fileUpload, action.payload.data, action.payload.user);
        yield put(actionCreator.fileUploadSuccess({msg: ""}));
        yield call(action.payload.cb, {type: "success", message: "This file has been successfully uploaded. We will update you shortly with an email."});
    } catch (e) {
        yield put(actionCreator.fileUploadError({msg: ""}));
        yield call(action.payload.cb, {type: "error", message: e.data.data.message || "Something went wrong while uploading. Please retry!"});
    }
}

export default scitorSaga;
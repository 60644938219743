import React, { Component } from "react";
import { Slider, Row, Col } from "antd";
import lodash from "lodash";

import sidebar from "../../containers/SideBar/sideBar.json";

class PermissionSliderInput extends Component {

    getEncodedSliderValue = (permission) => {
        const assignedPermission = this.props.permissions;
        if (assignedPermission.indexOf(permission) === -1) return 0;
        if (assignedPermission.indexOf(`${permission}+`) !== -1) return 2;
        return 1;
    }

    getDecodedSliderValue = (permission, sliderValue) => {
        if (sliderValue === 1) return [permission];
        if (sliderValue === 2) return [permission, `${permission}+`];
        return null;
    }

    getSliderMarks = (permissionTierLabel=[]) => {
        return { min: 0, max: permissionTierLabel.length, marks: ["Hide", ...permissionTierLabel] }
    }

    handleOnSliderChange = (permission, sliderValue) => {
        let permissions = this.props.permissions;

        const allDecodedPermission = this.getDecodedSliderValue(permission, 2);
        permissions = permissions.filter(v => (allDecodedPermission.indexOf(v) === -1));
        
        if (sliderValue !== 0) {
            permissions = permissions.concat(this.getDecodedSliderValue(permission, sliderValue));
        }

        this.props.handleChange("tabPermissions", lodash.uniqBy(permissions));
    }

    render() {
        const menuList = sidebar.menuList; 
        return (
            <div style={{height: "350px", padding: "15px 0", overflowX: "hidden", overflowY: "auto"}}>
                {
                    menuList.map((m,i) => {
                        const sliderMarks = this.getSliderMarks(m.permissionTierLabel);

                        return (
                            <Row gutter={50} key={i}>
                                <Col span={8} style={{textAlign: "right"}}>{m.label}</Col>
                                <Col span={12}>
                                    <Slider
                                        name={m.permission} 
                                        tipFormatter={null} min={sliderMarks.min} max={sliderMarks.max}
                                        dots 
                                        marks={sliderMarks.marks}
                                        value={this.getEncodedSliderValue(m.permission)}
                                        onAfterChange={(v) => this.handleOnSliderChange(m.permission, v)}
                                        />
                                </Col>
                            </Row>
                        )
                    })
                }
            </div>
        );
    }
}

export default PermissionSliderInput;
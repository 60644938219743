import { beAPIResource } from "../../utils/apiHelper";

const resourceApi = {
    getAll: () => beAPIResource.get(`support/effects/`),
    getByBusinessNumber: (businessNumber) => beAPIResource.get(`support/effects/${businessNumber}`),
    update: (data) => beAPIResource.put(`support/effects/${data.businessNumber}`, data),
    delete: (data) => beAPIResource.delete(`support/effects/${data.businessNumber}`, data),
    getIndustries: () => beAPIResource.get(`support/effects/industry/groups/count`),
    getByIndustryId: (industryId) => beAPIResource.get(`support/effects/industry/${industryId}`),
    updateIndustry: (data) =>  beAPIResource.put(`support/effects/industry/${data.id}`, data)
};

export default resourceApi;

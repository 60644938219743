import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import ResourcesActions, * as actionCreator from "./actions";
import resourceApi from "./http";

const resourceSagas = [
    takeLatest(
        ResourcesActions.FETCH_RESOURCES_LIST_REQUEST,
        fetchResourcesList
    ),
    takeLatest(ResourcesActions.FETCH_RESOURCE_REQUEST, fetchResource),
    takeLatest(ResourcesActions.ADD_RESOURCE_REQUEST, addResource),
    takeLatest(ResourcesActions.UPDATE_RESOURCE_REQUEST, updateResource),
    takeEvery(ResourcesActions.DELETE_RESOURCE_REQUEST, deleteResource),
    takeLatest(ResourcesActions.FETCH_INTEGRATION_LIST_REQUEST, fetchIntegrationList),
    takeLatest(ResourcesActions.FETCH_COMPETITOR_LIST_REQUEST, fetchCompetitorList),
    // for case study
    takeLatest(ResourcesActions.FETCH_CASE_STUDY_REQUEST, fetchCaseStudy),
    takeLatest(ResourcesActions.FETCH_CASE_STUDY_BLOCK_REQUEST, fetchCaseStudyBlock),
    takeLatest(ResourcesActions.ADD_CASE_STUDY_BLOCK_REQUEST, addCaseStudyBlock),
    takeLatest(ResourcesActions.UPDATE_CASE_STUDY_BLOCK_REQUEST, updateCaseStudyBlock),
    takeEvery(ResourcesActions.DELETE_CASE_STUDY_BLOCK_REQUEST, deleteCaseStudyBlock),
    takeEvery(ResourcesActions.INSERT_CASE_STUDY_BLOCK, insertCaseStudyBlock),

    //videos
    takeEvery(ResourcesActions.FETCH_VIDEO_LIST_REQUEST, fetchVideoList),
    takeEvery(ResourcesActions.FETCH_VIDEO_REQUEST, fetchVideo),
    takeEvery(ResourcesActions.ADD_VIDEO_REQUEST, addVideo),
    takeEvery(ResourcesActions.UPDATE_VIDEO_REQUEST, updateVideo),
    takeEvery(ResourcesActions.DELETE_VIDEO_REQUEST, deleteVideo),
    takeLatest(ResourcesActions.FETCH_SLUG_OCCURENCE_REQUEST, fetchSlugOccurence),
    
    //video tutorials
    takeEvery(ResourcesActions.FETCH_VIDEO_TUTORIALS_LIST_REQUEST, fetchVideoTutorialsList),
    takeEvery(ResourcesActions.FETCH_VIDEO_TUTORIAL_REQUEST, fetchVideoTutorial),
    takeEvery(ResourcesActions.ADD_VIDEO_TUTORIAL_REQUEST, addVideoTutorial),
    takeEvery(ResourcesActions.UPDATE_VIDEO_TUTORIAL_REQUEST, updateVideoTutorial),
    takeEvery(ResourcesActions.DELETE_VIDEO_TUTORIAL_REQUEST, deleteVideoTutorial)
];

// video tutorials
function* fetchVideoTutorialsList() {
    try {
        const resp = yield call(resourceApi.getAllVideoTutorials);
        yield put(actionCreator.fetchVideoTutorialsListSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.resourceError(e.data.data || { error: "resourcesListNotFound" })
        );
    }
}

function* fetchVideoTutorial(action) {
    try {
        const resp = yield call(resourceApi.getVideoTutorialById, action.payload.id);
        yield put(actionCreator.fetchVideoTutorialSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.resourceError((e.data && e.data.data)|| { error: "resourceNotFound" }));
    }
}

function* addVideoTutorial(action) {
    try {
        const resp = yield call(resourceApi.addVideoTutorial, action.payload.data);
        yield put(actionCreator.addVideoTutorialSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.resourceError(e.data.data || { error: "cannotAdd" }));
        yield call(action.payload.cb, "cannotAdd");
    }
}

function* updateVideoTutorial(action) {
    try {
        const resp = yield call(resourceApi.updateVideoTutorial, action.payload.data);
        yield put(actionCreator.updateVideoTutorialSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.resourceError(e.data.data || { error: "cannotUpdate" }));
        yield call(action.payload.cb, "cannotUpdate");
    }
}

function* deleteVideoTutorial(action) {
    const { id } = action.payload;
    try {
        yield call(resourceApi.deleteVideoTutorial, id);
        yield put(actionCreator.deleteVideoTutorialSuccess({ id }));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.resourceError(e.data.data || { error: "cannotDelete" }));
        yield call(action.payload.cb, "cannotDelete");
    }
}

// videos
function* fetchVideoList() {
    try {
        const resp = yield call(resourceApi.getAllVideos);
        yield put(actionCreator.fetchVideoListSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.resourceError(e.data.data || { error: "resourcesListNotFound" })
        );
    }
}

function* fetchVideo(action) {
    try {
        const resp = yield call(resourceApi.getVideoById, action.payload.id);
        yield put(actionCreator.fetchVideoSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.resourceError((e.data && e.data.data)|| { error: "resourceNotFound" }));
    }
}

function* addVideo(action) {
    try {
        const resp = yield call(resourceApi.addVideo, action.payload.data);
        yield put(actionCreator.addVideoSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.resourceError(e.data.data || { error: "cannotAdd" }));
        yield call(action.payload.cb, "cannotAdd");
    }
}

function* updateVideo(action) {
    try {
        const resp = yield call(resourceApi.updateVideo, action.payload.data);
        yield put(actionCreator.updateVideoSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.resourceError(e.data.data || { error: "cannotUpdate" }));
        yield call(action.payload.cb, "cannotUpdate");
    }
}

function* deleteVideo(action) {
    const { id } = action.payload;
    try {
        yield call(resourceApi.deleteVideo, id);
        yield put(actionCreator.deleteVideoSuccess({ id }));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.resourceError(e.data.data || { error: "cannotDelete" }));
        yield call(action.payload.cb, "cannotDelete");
    }
}

//resources

function* fetchResourcesList() {
    try {
        const resp = yield call(resourceApi.getAllCaseStudies);
        yield put(actionCreator.fetchResourcesListSucess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.resourceError(e.data.data || { error: "resourcesListNotFound" })
        );
    }
}

function* fetchResource(action) {
    try {
        const resp = yield call(resourceApi.getCaseStudyById, action.payload.id);
        yield put(actionCreator.fetchResourceSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.resourceError(e.data.data || { error: "resourceNotFound" }));
    }
}

function* addResource(action) {
    try {
        const resp = yield call(resourceApi.addCaseStudy, action.payload.data);
        yield put(actionCreator.addResourceSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.resourceError(e.data.data || { error: "cannotAdd" }));
        yield call(action.payload.cb, "cannotAdd");
    }
}

function* updateResource(action) {
    try {
        const resp = yield call(resourceApi.updateCaseStudy, action.payload.data);
        yield put(actionCreator.updateResourceSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.resourceError(e.data.data || { error: "cannotUpdate" }));
        yield call(action.payload.cb, "cannotUpdate");
    }
}

function* deleteResource(action) {
    const { id } = action.payload;
    try {
        yield call(resourceApi.deleteCaseStudy, id);
        yield put(actionCreator.deleteResourceSuccess({ id }));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.resourceError(e.data.data || { error: "cannotDelete" }));
        yield call(action.payload.cb, "cannotDelete");
    }
}


// for case study blocks

function* fetchCaseStudy(action) {
    try {
        const resp = yield call(resourceApi.getCaseStudy, action.payload.id);
        yield put(actionCreator.fetchCaseStudySuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.resourceError(e.data.data || { error: "caseStudyNotFound" }));
    }
}

function* fetchCaseStudyBlock(action) {
    try {
        const resp = yield call(resourceApi.getCaseStudyBlock, action.payload.id);
        const caseStudyResp = yield call(resourceApi.getCaseStudy, resp.data.caseStudyId);
        yield put(actionCreator.fetchCaseStudyBlockSuccess(resp.data));
        yield put(actionCreator.fetchCaseStudySuccess(caseStudyResp.data));
    } catch (e) {
        yield put(actionCreator.resourceError(e.data.data || { error: "caseStudyBlockNotFound" }));
    }
}

function* addCaseStudyBlock(action) {
    try {
        const resp = yield call(resourceApi.addCaseStudyBlock, action.payload.caseStudyId, action.payload.data);
        yield put(actionCreator.addCaseStudyBlockSuccess(resp.data));
        if (action.payload.cb) {
            yield call(action.payload.cb);
        }
    } catch (e) {
        yield put(actionCreator.resourceError((e.data && e.data.data) || { error: "cannotAddCaseStudy" }));
        if (action.payload.cb) {
            yield call(action.payload.cb, "cannotAddCaseStudy");
        }
    }
}

function* updateCaseStudyBlock(action) {
    try {
        const resp = yield call(resourceApi.updateCaseStudyBlock, action.payload.data);
        yield put(actionCreator.updateCaseStudyBlockSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.resourceError(e.data.data || { error: "cannotUpdateCaseStudy" }));
        yield call(action.payload.cb, "cannotUpdateCaseStudy");
    }
}

function* insertCaseStudyBlock(action) {
    try {
        const
            {caseStudyId, insertAtPosition, data} = action.payload,
            resp = yield call(resourceApi.insertCaseStudyBlock, {caseStudyId, insertAtPosition, data});

        yield put(actionCreator.addCaseStudyBlockSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.resourceError((e.data && e.data.data) || { error: "cannotAddCaseStudy" }));
        if (action.payload.cb) {
            yield call(action.payload.cb, "cannotAddCaseStudy");
        }
    }
}

function* deleteCaseStudyBlock(action) {
    const { id } = action.payload;
    try {
        yield call(resourceApi.deleteCaseStudyBlock, id);
        yield put(actionCreator.deleteCaseStudyBlockSuccess({ id }));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.resourceError(e.data.data || { error: "cannotDeleteCaseStudy" }));
        yield call(action.payload.cb, "cannotDeleteCaseStudy");
    }
}

function* fetchIntegrationList() {
    try {
        const resp = yield call(resourceApi.getIntegrationList);
        yield put(actionCreator.fetchIntegrationListSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.fetchIntegrationListError([]));
    }
}

function* fetchCompetitorList() {
    try {
        const resp = yield call(resourceApi.getCompetitorList);
        yield put(actionCreator.fetchCompetitorListSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.fetchCompetitorListError([]));
    }
}

/**
 * Common
 */

function* fetchSlugOccurence(action) {
    try {
        const { data } = yield call(resourceApi.getSlugOccurene, action.slug);
        yield put(actionCreator.fetchSlugOccurenceSuccess(data.occurence || 0));
    } catch (e) {
        yield put(actionCreator.fetchSlugOccurenceError(0));
    }
}


export default resourceSagas;

import { beAPIResource } from "../../utils/apiHelper";

const selfServePanelAPI = {
    reqSelfServeBusinessDetails: () => beAPIResource.get("/support/integration-self-serve"),
    requesedCRMDetails: () => beAPIResource.get("/support/integration-self-serve/requested/crm"),
    reqIntegrationSelfServeCRMDetails: ({crmName, businessNum}) => beAPIResource.get(`/support/integration-self-serve/${crmName}/${businessNum}`),
    saveIntegrationSelfServeCRMDetails: (payload) => beAPIResource.post("/support/integration-self-serve", payload)
};

export default selfServePanelAPI;                                                                                                                                          ;

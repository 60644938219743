import React, { Component } from "react";
import { Form, Input, Radio, Select, Switch, Row, Col, Divider, Button } from "antd";
import { isEmpty, forEach } from "lodash";
import googleLogo from '../../../../assets/images/google-logo-full.png';
import birdeyeLogo from '../../../../assets/images/logo-color.svg';
import moment from "moment";
const { Option } = Select;
const timeOption = [{"label":"00:00 AM","value":"00:00"},{"label":"00:30 AM","value":"00:30"},{"label":"01:00 AM","value":"01:00"},{"label":"01:30 AM","value":"01:30"},{"label":"02:00 AM","value":"02:00"},{"label":"02:30 AM","value":"02:30"},{"label":"03:00 AM","value":"03:00"},{"label":"03:30 AM","value":"03:30"},{"label":"04:00 AM","value":"04:00"},{"label":"04:30 AM","value":"04:30"},{"label":"05:00 AM","value":"05:00"},{"label":"05:30 AM","value":"05:30"},{"label":"06:00 AM","value":"06:00"},{"label":"06:30 AM","value":"06:30"},{"label":"07:00 AM","value":"07:00"},{"label":"07:30 AM","value":"07:30"},{"label":"08:00 AM","value":"08:00"},{"label":"08:30 AM","value":"08:30"},{"label":"09:00 AM","value":"09:00"},{"label":"09:30 AM","value":"09:30"},{"label":"10:00 AM","value":"10:00"},{"label":"10:30 AM","value":"10:30"},{"label":"11:00 AM","value":"11:00"},{"label":"11:30 AM","value":"11:30"},{"label":"12:00 Noon","value":"12:00"},{"label":"12:30 PM","value":"12:30"},{"label":"01:00 PM","value":"13:00"},{"label":"01:30 PM","value":"13:30"},{"label":"02:00 PM","value":"14:00"},{"label":"02:30 PM","value":"14:30"},{"label":"03:00 PM","value":"15:00"},{"label":"03:30 PM","value":"15:30"},{"label":"04:00 PM","value":"16:00"},{"label":"04:30 PM","value":"16:30"},{"label":"05:00 PM","value":"17:00"},{"label":"05:30 PM","value":"17:30"},{"label":"06:00 PM","value":"18:00"},{"label":"06:30 PM","value":"18:30"},{"label":"07:00 PM","value":"19:00"},{"label":"07:30 PM","value":"19:30"},{"label":"08:00 PM","value":"20:00"},{"label":"08:30 PM","value":"20:30"},{"label":"09:00 PM","value":"21:00"},{"label":"09:30 PM","value":"21:30"},{"label":"10:00 PM","value":"22:00"},{"label":"10:30 PM","value":"22:30"},{"label":"11:00 PM","value":"23:00"},{"label":"11:30 PM","value":"23:30"}];
const daysOption = [{label: "Monday", value: 0, shortName: "Mon"}, {label: "Tuesday", value: 1, shortName: "Tue"}, {label: "Wednesday",value: 2, shortName: "Wed"}, {label: "Thursday", value: 3, shortName: "Thurs"}, {label: "Friday", value: 4, shortName: "Fri"}, {label: "Saturday", value: 5, shortName: "Sat"}, {label: "Sunday", value: 6, shortName: "Sun"}];

class BusinessHours extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showHours: false,
            businessInfo: {},
            wholeWeekOperating: 0,
            googleTimes: []
        };
    }

    componentDidMount( ){
        const formattedHours = this.props.googleData !== undefined ? this.formatHours(this.props.googleData.business_hours) : {};
        this.setState({
            showHours: this.props.businessInfo.wholeWeekOperating ? false : true,
            businessInfo: this.props.businessInfo,
            googleTimes: formattedHours
        });
    }

    onChangeWorkingType = e => {
        e.target.value  ? this.setState({ showHours: false }) : this.setState({ showHours: true });
    };

    onHoursChange (index , value) {
        const formValue = this.props.form.getFieldsValue();
        formValue.hoursOfOperations[index].isOpen = value;
        this.setState({ businessInfo: { ...this.state.businessInfo, hoursOfOperations: formValue.hoursOfOperations}});
    };

    onSync = (e) => {
        e.preventDefault();
        const googleTimes = this.state.googleTimes;
        let exchangedTime = [];
        forEach(googleTimes, function(time){
            let timing = {};
            timing = { day: time.day, comment: "", isOpen: time.isOpen ? true : false, startHour: time.startHour, endHour: time.endHour };
            exchangedTime.push(timing);
        });
        this.setState({ businessInfo : { hoursOfOperations: exchangedTime } });
        this.props.form.setFieldsValue({
            'hoursOfOperations': exchangedTime
        })
    };

    formatHours = (businessHours) => {
        let details = [];
        if(!isEmpty(businessHours)) {
            const splitByTime = businessHours.split('.')[0];
            let splitByDays = [];
            if(businessHours.includes(";")){
                splitByDays = splitByTime.split(';');
            } else if(businessHours.includes(",")) {
                splitByDays = splitByTime.split(',');
            }

            forEach(splitByDays, function(daysTimings) {
                let splitDaysTiming = [];
                if(daysTimings.includes(",")) {
                    splitDaysTiming = daysTimings.split(',');
                } else {
                    splitDaysTiming = daysTimings.split(' ');
                }

                let day = 0;
                let name = splitDaysTiming[0].trim();
                let startHour = "";
                let endHour = "";

                if(daysTimings.includes("Open 24 hours")) {
                    startHour = "00:00";
                    endHour = "24:00";
                } else if(splitDaysTiming.length > 2){
                    if(daysTimings.includes("open")){
                        startHour = "00am";
                        endHour = "11:30pm";
                    } else if(splitDaysTiming[1].includes("to") && splitDaysTiming[2].includes("to")) {
                        startHour = splitDaysTiming[1].split('to')[0].trim();
                        endHour = splitDaysTiming[2].split('to')[1].trim();
                    } else if(splitDaysTiming[1].includes('to')) {
                        startHour = splitDaysTiming[1].split('to')[0].trim();
                        endHour = splitDaysTiming[1].split('to')[1].trim();
                    } else if(splitDaysTiming[1].includes('–')) {
                        startHour = splitDaysTiming[1].split('–')[0].trim();
                        endHour = splitDaysTiming[1].split('–')[1].trim();
                    } else {
                        startHour = splitDaysTiming[1].split('–')[0].trim();
                        endHour = splitDaysTiming[2].split('–')[1].trim();
                    }
                } else {
                    if(splitDaysTiming[1].includes("to")) {
                        startHour = splitDaysTiming[1].split('to')[0].trim();
                        endHour = splitDaysTiming[1].split('to')[1].trim();
                    } else {
                        startHour = splitDaysTiming[1].split('–')[0];
                        endHour = splitDaysTiming[1].split('–')[1];
                    }
                }

                const hours = splitDaysTiming[1].trim();
                const splitHours = hours.includes("to") ? hours.split('to') : hours.split('–');

                switch(name) {
                    case "Monday" :
                        day = 0;
                        break;
                    case "Tuesday" :
                        day = 1;
                        break;
                    case "Wednesday" :
                        day = 2;
                        break;
                    case "Thursday" :
                        day = 3;
                        break;
                    case "Friday" :
                        day = 4;
                        break;
                    case "Saturday" :
                        day = 5;
                        break;
                    case "Sunday" :
                        day = 6;
                        break;
                }

                const detail = splitHours.length === 2 || hours.includes("Open") ? { 'name': name, 'day': day, 'startHour' : this.convertHours(startHour), 'endHour' : this.convertHours(endHour), isOpen: 1 } : { 'name': name, 'day': day, 'startHour' : '09:00', 'endHour' : '18:00', isOpen: 0 };
                details[day] = detail;
            }.bind(this));
        } else {
            details = [
                { name: "Monday", day: 0, startHour: this.convertHours("09:00am"), endHour: this.convertHours("06:00pm"), isOpen: 1 },
                { name: "Tuesday", day: 1, startHour: this.convertHours("09:00am"), endHour: this.convertHours("06:00pm"), isOpen: 1 },
                { name: "Wednesday", day: 2, startHour: this.convertHours("09:00am"), endHour: this.convertHours("06:00pm"), isOpen: 1 },
                { name: "Thursday", day: 3, startHour: this.convertHours("09:00am"), endHour: this.convertHours("06:00pm"), isOpen: 1 },
                { name: "Friday", day: 4, startHour: this.convertHours("09:00am"), endHour: this.convertHours("06:00pm"), isOpen: 1 },
                { name: "Saturday", day: 5, startHour: "09:00am", endHour: "06:00pm", isOpen: 0 },
                { name: "Sunday", day: 6, startHour: "09:00am", endHour: "06:00pm", isOpen: 0 }
            ];
        }
        return details;
    }

    convertHours = (time) => {
        const convertTime = moment(time, ['h:mm A', 'hh:mm A']);
        return convertTime.format('HH:mm');
    }

    formatHoursOfOperations(hoursOperations = []) {
        return hoursOperations.map(({day, comment, isOpen, startHour, endHour}) => ({day, comment, isOpen, startHour: this.convertHours(startHour), endHour: this.convertHours(endHour)}));
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { businessInfo, googleTimes } = this.state;
        const { googleData, findOnGoogle } = this.props;
        const hours = googleTimes;
        const { wholeWeekOperating, hoursOfOperations } = businessInfo;
        const hoursOfOperationsFormat = this.formatHoursOfOperations(hoursOfOperations);
        const { showHours } = this.state;
        const businessData = this.props.businessInfo.wholeWeekOperating || 0;
        const wholeWeekOperatingValue = isEmpty(wholeWeekOperating) ? businessData : wholeWeekOperating

        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col span={12}>
                            <img src={birdeyeLogo} />
                            <Form.Item >
                                {getFieldDecorator('wholeWeekOperating', {
                                    rules: [{ required: true, message: 'Please provide business number' }],
                                    initialValue: wholeWeekOperatingValue
                                })(
                                    <Radio.Group onChange = {(value) => this.onChangeWorkingType(value)}>
                                        <Radio value={1}>Open 24/7</Radio>
                                        <Radio value={2}>By appointment only</Radio>
                                        <Radio value={0}>Custom</Radio>
                                    </Radio.Group>,
                                )}
                            </Form.Item>

                        </Col>
                        {
                            findOnGoogle ? (
                                <Col span={12}>
                                    <img src={googleLogo} style={{ width: "100px" }}/>
                                </Col>
                            ) : (
                                ""
                            )
                        }
                        {findOnGoogle ? <Divider /> : ""}
                    </Row>

                    { showHours ?
                        <div style={{display: "flex"}}>
                            <div style={{ width: "49%", float: "left" }}>
                                {hoursOfOperationsFormat.map( ( hour ) => (

                                    <Row style = {{marginBottom: 25}} key={hour.day}>
                                        <Col span={3}>
                                        </Col>
                                        <Col span={3}>
                                            {daysOption[hour.day].label}
                                            <Form.Item hidden={true}>
                                                {getFieldDecorator(`hoursOfOperations[${hour.day}].day`, {
                                                    initialValue: hour.day
                                                })(
                                                    <Input type="hidden" />
                                                )}
                                            </Form.Item>
                                            <Form.Item hidden={true}>
                                                {getFieldDecorator(`hoursOfOperations[${hour.day}].comment`, {
                                                    initialValue: hour.comment
                                                })(
                                                    <Input type="hidden" />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={3}>
                                            {getFieldDecorator(`hoursOfOperations[${hour.day}].isOpen`, {
                                                valuePropName: 'checked',
                                                initialValue: hour.isOpen ? true : false

                                            })(
                                                <Switch onChange = {(value) => this.onHoursChange(hour.day, value)} checkedChildren="ON" unCheckedChildren="OFF" />
                                            )}
                                        </Col>
                                        <div className={ (hoursOfOperations[hour.day].isOpen) ? "" : "hide"}>
                                            <Col span={1}></Col>
                                            <Col span={6} >
                                                {getFieldDecorator(`hoursOfOperations[${hour.day}].startHour`, {
                                                    rules: [{required: true, message: 'Please provide business number'}],
                                                    initialValue: (hour.startHour) ? hour.startHour : ""
                                                })(
                                                    <Select>
                                                        {timeOption.map((slot) => (
                                                            <Option key={slot.value} value={slot.value}>
                                                                {slot.label}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={6}>
                                                {getFieldDecorator(`hoursOfOperations[${hour.day}].endHour`, {
                                                    rules: [{ required: true, message: 'Please provide business number' }],
                                                    initialValue: (hour.endHour) ? hour.endHour : ""
                                                })(
                                                    <Select >
                                                        {timeOption.map((slot) => (
                                                            <Option key={slot.value} value={slot.value}>
                                                                {slot.label}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Col>
                                        </div>
                                    </Row>
                                ))}
                            </div>
                            {findOnGoogle ? <div style={{width: "1px", backgroundColor: "#E8E8E8", marginBottom: "23px", marginLeft: "13px"}}>
                            </div> : ""}
                            {
                                findOnGoogle && googleData !== undefined ? (
                                    <div style={{width: "49%", float: "left"}} >
                                        { hours.map( ( hour ) => (
                                            hour.isOpen ? (
                                                <Row style = {{marginBottom: 25}}  key={hour.day}>
                                                    <Col span={3}>
                                                    </Col>
                                                    <Col span={3}>
                                                        {daysOption[hour.day].label}
                                                        <Form.Item hidden={true}>
                                                            {getFieldDecorator(`hours[${hour.day}].day`, {
                                                                initialValue: hour.day
                                                            })(
                                                                <Input type="hidden" />
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item hidden={true}>
                                                            {getFieldDecorator(`hours[${hour.day}].comment`, {
                                                                initialValue: hour.comment
                                                            })(
                                                                <Input type="hidden" />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={3}>
                                                        {getFieldDecorator(`hours[${hour.day}].isOpen`, {
                                                            valuePropName: 'checked',
                                                            initialValue: hour.isOpen ? true : false

                                                        })(
                                                            <Switch disabled={true} checkedChildren="ON" unCheckedChildren="OFF" />
                                                        )}
                                                    </Col>
                                                    <div >
                                                        <Col span={1}></Col>
                                                        <Col span={6} >
                                                            {getFieldDecorator(`hours[${hour.day}].startHour`, {
                                                                rules: [{required: true, message: 'Please provide business number'}],
                                                                initialValue: (hour.startHour) ? hour.startHour : ""
                                                            })(
                                                                <Select disabled={true}>
                                                                    {timeOption.map((slot) => (
                                                                        <Option key={slot.value} value={slot.value}>
                                                                            {slot.label}
                                                                        </Option>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        </Col>
                                                        <Col span={1}></Col>
                                                        <Col span={6}>
                                                            {getFieldDecorator(`hours[${hour.day}].endHour`, {
                                                                rules: [{ required: true, message: 'Please provide business number' }],
                                                                initialValue: (hour.endHour) ? hour.endHour : ""
                                                            })(
                                                                <Select disabled={true}>
                                                                    {timeOption.map((slot) => (
                                                                        <Option key={slot.value} value={slot.value}>
                                                                            {slot.label}
                                                                        </Option>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        </Col>
                                                    </div>
                                                </Row>
                                            ) : (
                                                <Row style = {{marginBottom: 25}}  key={hour.day}>
                                                    <Col span={3}>
                                                    </Col>
                                                    <Col span={3}>
                                                        {daysOption[hour.day].label}
                                                        <Form.Item hidden={true}>
                                                            {getFieldDecorator(`hours[${hour.day}].day`, {
                                                                initialValue: hour.day
                                                            })(
                                                                <Input type="hidden" />
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item hidden={true}>
                                                            {getFieldDecorator(`hours[${hour.day}].comment`, {
                                                                initialValue: hour.comment
                                                            })(
                                                                <Input type="hidden" />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={3}>
                                                        {getFieldDecorator(`hours[${hour.day}].isOpen`, {
                                                            valuePropName: 'checked',
                                                            initialValue: hour.isOpen ? true : false

                                                        })(
                                                            <Switch disabled={true} checkedChildren="ON" unCheckedChildren="OFF" />
                                                        )}
                                                    </Col>
                                                </Row>
                                            )
                                        ))}
                                    </div>
                                ) : (
                                    findOnGoogle && googleData == undefined ? (
                                        <div style={{ width: "49%", float: "left", margin: "auto", color: "red", fontWeight: 500, fontSize: "20px", fontFamily: "initial"  }}>
                                            Google Profile Not Found
                                        </div>
                                    ) : (
                                        <></>
                                    )

                                )
                            }
                        </div>
                        : null }
                    {
                        findOnGoogle && googleData !== undefined ? (
                            <Row style={{marginBottom: "10px"}}>
                                <Button type="primary" onClick={this.onSync}>
                                    Sync With Google
                                </Button>
                            </Row>) : ""
                    }
                </Form>
            </div>
        );
    }
}


export default BusinessHours;
import { connect } from "react-redux";
import {
    fetchCategoriesListBySource,
    fetchBirdeyeCategoriesList,
    updateCategoryRequest,
    addBeSubCategoryRequest,
    updateSicNaicsRequest,
    addNewCategoryRequest,
    addIndustry
} from "./actions";

import Categories from "./Categories";
import UpdateCategory from "./UpdateCategory";
import AddBeSubCategory from "./AddBeSubCategory";
import UpdateSicNaics from "./UpdateSicNaicsCodes";
import AddNewIndustry from "./AddNewIndustry";

const mapStateToProps = (state) => ({
    ...state.Categories,
    userData: state.User.userData
});

export const CategoriesPage = connect(
    mapStateToProps,
    { fetchCategoriesListBySource }
)(Categories);

export const UpdateCategoriesPage = connect(
    mapStateToProps,
    {
        fetchBirdeyeCategoriesList,
        updateCategoryRequest,
        fetchCategoriesListBySource
    }
)(UpdateCategory);

export const AddBeSubCategoryPage = connect(
    mapStateToProps,
    { fetchBirdeyeCategoriesList, addBeSubCategoryRequest }
)(AddBeSubCategory);

export const UpdateSicNaicsPage = connect(
    mapStateToProps,
    { updateSicNaicsRequest, fetchBirdeyeCategoriesList }
)(UpdateSicNaics);

export const AddNewIndustryPage = connect(
    mapStateToProps,
    { addNewCategoryRequest, fetchBirdeyeCategoriesList, addIndustry }
)(AddNewIndustry);

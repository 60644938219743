import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isEqual, isEmpty } from "lodash";
import { Form, Button } from "antd";

import Filter from "./Filter";
import {
  fetchStateListRequest,
  fetchCityListRequest,
  fetchIndustryListRequest,
  fetchOwnerListRequest
} from "../../redux/actions";
import { getUpdatedCityFilter, getUpdatedSubindustryFilter, getIndustryOptions } from "../../utils";
import panelConfig from "./panelConfig.json";

class Panel extends React.Component {
  initialFilterObj = {
    type: [],
    state: ["all-selected"],
    city: [],
    industry: ["all-selected"],
    subindustry: ["all-selected"],
    segment: ["all-selected"],
    owner: ["all-selected"],
    revenueRange: ["all-selected"],
    products: ["all-selected"],
    healthScore: ["all-selected"]
  };

  constructor(props) {
    super(props);
    this.state = {
      filterObj: { ...this.initialFilterObj, ...this.props.initialFilter },
      hasFilter: !!Object.keys(this.props.initialFilter).length
    };
  }

  componentDidMount() {
    if (!this.props.stateList.length) this.props.fetchStateListRequest();
    if (!this.props.cityList.length) this.props.fetchCityListRequest();
    if (!this.props.industryList.length) this.props.fetchIndustryListRequest();
    if (!this.props.ownerList.length) this.props.fetchOwnerListRequest();
  }

  handleFieldChange = (name, value) => {
    let filterObj = { ...this.state.filterObj };
    filterObj[name] = value;
    if (name === "state") filterObj.city = getUpdatedCityFilter(value, filterObj);
    if (name === "industry")
      filterObj.subindustry = getUpdatedSubindustryFilter(value, filterObj, this.props.industryList);
    this.setState(() => ({ filterObj }), this.updateFilterStatus);
  };

  updateFilterStatus = () => {
    const { filterObj } = this.state;
    let numFilteredFields = 0;
    for (let filterName in filterObj) {
      if (filterObj[filterName].length) {
        numFilteredFields++;
      }
    }
    let hasFilter = numFilteredFields ? true : false;
    this.setState({ hasFilter });
  };

  renderFilters = () => {
    const { industryToSubs } = this.props;
    const { filterObj } = this.state;
    let filters = [];
    for (let name in panelConfig.filters) {
      let filterConfig = panelConfig.filters[name];
      let dynamicOptions = filterConfig.optionsProps ? this.props[filterConfig.optionsProps] || [] : [];
      if (name === "subindustry") dynamicOptions = getIndustryOptions(industryToSubs, filterObj);
      if (name === "city") dynamicOptions = this.props.cityList;
      let newFilter = (
        <Filter
          key={name}
          {...filterConfig}
          value={this.state.filterObj[name]}
          filterObj={this.state.filterObj}
          dynamicOptions={dynamicOptions}
          handleFieldChange={this.handleFieldChange}
        />
      );
      filters.push(newFilter);
    }

    return filters;
  };

  handleButtonClick = (btnType) => {
    let nextFilterObj;
    if (btnType === "apply") {
      nextFilterObj = this.getPreparedFilter();
    } else {
      nextFilterObj = this.resetFilters();
    }
    this.props.updateFilter(nextFilterObj);
  };

  getPreparedFilter = () => {
    let { filterObj } = this.state;
    let preparedFilter = {};
    for (let filterName in filterObj) {
      if (!isEqual(filterObj[filterName], this.initialFilterObj[filterName]) && filterObj[filterName].length)
        preparedFilter[filterName] = [...filterObj[filterName]];
    }
    let hasFilter = Object.keys(preparedFilter).length ? true : false;
    this.setState({ hasFilter });
    return preparedFilter;
  };

  resetFilters = () => {
    this.setState({ filterObj: this.initialFilterObj, hasFilter: false });
    return {};
  };

  render() {
    const { hasFilter } = this.state;
    return (
      <div className="panel-inner-wrapper">
        <Form>
          <div className="filter-wrapper">{this.renderFilters()}</div>
          <div className="btn-wrapper">
            <Button type="link" onClick={() => this.handleButtonClick("reset")} disabled={!hasFilter}>
              Reset
            </Button>
            <Button type="primary" onClick={() => this.handleButtonClick("apply")} disabled={!hasFilter}>
              Apply
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

Panel.propTypes = {
  initialFilter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
  stateList: PropTypes.array.isRequired,
  cityList: PropTypes.array.isRequired,
  industryList: PropTypes.array.isRequired,
  industryToSubs: PropTypes.object.isRequired,
  fetchStateListRequest: PropTypes.func.isRequired,
  fetchCityListRequest: PropTypes.func.isRequired,
  fetchIndustryListRequest: PropTypes.func.isRequired,
  fetchOwnerListRequest: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  ...state.Prospector
});

const MapPanel = connect(mapStateToProps, {
  fetchStateListRequest,
  fetchCityListRequest,
  fetchIndustryListRequest,
  fetchOwnerListRequest
})(Panel);

export default MapPanel;

import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";

const MapMask = ({ data, filterObj, isRecordLoading, isProfileLoading, upperLimit, zooming }) => {
  if (isRecordLoading || isProfileLoading || zooming) {
    return (
      <div className="map-mask">
        <span className="mask-msg spin">
          <Spin size="large" />
        </span>
      </div>
    );
  }
  
  const hasFilter = filterObj && Object.keys(filterObj).length;
  let maskMsg = "";
  if (!data.length)
    maskMsg = hasFilter
      ? "Selected filters returned no results. Please select different filters and try again"
      : "Please select filters to see the results";
  if (data.length > upperLimit) maskMsg = "Results exceeded rendering limit, please refine the filter.";

  if (!maskMsg) return null;
  return (
    <div className="map-mask">
      <span className="mask-msg">{maskMsg}</span>
    </div>
  );
};

MapMask.propTypes = {
  data: PropTypes.array.isRequired,
  filterObj: PropTypes.object.isRequired,
  isRecordLoading: PropTypes.bool.isRequired,
  isProfileLoading: PropTypes.bool.isRequired,
  upperLimit: PropTypes.number.isRequired,
  zooming: PropTypes.bool.isRequired
};

export default MapMask;

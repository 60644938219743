import { findIndex } from "lodash";
import { IntegrationDomainsActions } from "./actions";

let IntegrationDomains = {
    data: [],
    curData: {},
    error: "",
    isIntegrationLoading: false
};

const integrationDomainsReducer = (state = IntegrationDomains, action) => {
    let index;
    switch (action.type) {
        case IntegrationDomainsActions.FETCH_INTEGRATIONDOMAINS_LIST_REQUEST:
        case IntegrationDomainsActions.FETCH_INTEGRATIONDOMAIN_REQUEST:
        case IntegrationDomainsActions.ADD_INTEGRATIONDOMAIN_REQUEST:
        case IntegrationDomainsActions.UPDATE_INTEGRATIONDOMAIN_REQUEST:
            return {
                ...state,
                error: "",
                isIntegrationLoading: true
            };
        case IntegrationDomainsActions.FETCH_INTEGRATIONDOMAINS_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isIntegrationLoading: false
            };
        case IntegrationDomainsActions.FETCH_INTEGRATIONDOMAIN_SUCCESS:
            return {
                ...state,
                curData: action.payload,
                isIntegrationLoading: false
            };
        case IntegrationDomainsActions.ADD_INTEGRATIONDOMAIN_SUCCESS:
            return {
                ...state,
                data: [...state.data, action.payload],
                curData: action.payload,
                isIntegrationLoading: false
            };
        case IntegrationDomainsActions.UPDATE_INTEGRATIONDOMAIN_SUCCESS:
            index = findIndex(state.data, ["id", action.payload.id]);
            return {
                ...state,
                data: [
                    ...state.data.slice(0, index),
                    action.payload,
                    ...state.data.slice(index + 1)
                ],
                curData: action.payload,
                isIntegrationLoading: false
            };
        case IntegrationDomainsActions.DELETE_INTEGRATIONDOMAIN_SUCCESS:
            index = findIndex(state.data, ["id", action.payload.id]);
            return {
                ...state,
                data: [
                    ...state.data.slice(0, index),
                    ...state.data.slice(index + 1)
                ],
                error: ""
            };
        case IntegrationDomainsActions.INTEGRATIONDOMAIN_ERROR:
            return {
                ...state,
                data: [],
                curData: {},
                error: action.payload.error,
                isIntegrationLoading: false
            };
        default:
            return state;
    }
};

export default integrationDomainsReducer;

import React, {Component} from "react";
import {Form, Button, Input, Select, Icon, Radio} from 'antd';
import PropTypes from 'prop-types';
import { formItemLayout, formButtonLayout } from "../../constants";
import lookup from "./constants";

const FormItem = Form.Item;
class CustomizeForm extends Component {

    constructor(props){
        super(props);
    }

    handleCustomizeForm = (e) =>{
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((error, values) => {
            if(!error) {
                values.commentLabel = values.message ? values.commentLabel : "";
                this.props.handleCalendarStepsForm({customize: {... values}});
            }
        });
    }

    render(){
        const { getFieldDecorator } = this.props.form;
        const { customize, configure } = this.props.initialValues;
        const { heading, additionalAttendeesSupported, subHeading, message, commentLabel, calendarPageSequence } = customize || {};
        const { requestType } = configure;
        const calendarPageSequenceValue = requestType === 'CALENDAR_LEAD' ? "SHOW_LEAD_FORM" : calendarPageSequence;

        return (
            <Form onSubmit={this.handleCustomizeForm}>
                <FormItem {...formItemLayout} label="Heading">
                    {getFieldDecorator("heading", {
                        rules: [{ "required": true, "message": 'Please provide heading.' }],
                        initialValue: heading || "Schedule time with Birdeye",
                    })(
                        <Input
                            name="heading"
                            placeholder="Heading"
                        />
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Sub-heading">
                    {getFieldDecorator('subHeading', {
                        initialValue: subHeading || "Web conferencing details provided upon confirmation."
                    })(
                        <Input
                            name="subHeading"
                            placeholder="Sub heading"
                        />
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Calendar page sequence">
                    {getFieldDecorator('calendarPageSequence', {
                        initialValue: calendarPageSequenceValue
                    })(
                        <Radio.Group>
                            {
                                lookup.valueList.calendarPageSequence.map((ele) => 
                                    <Radio value={ele.value} key={ele.value} disabled={requestType === 'CALENDAR_LEAD' && ele.value === 'SHOW_TIME_SLOTS'} >{ele.label}</Radio>
                                )
                            }
                        </Radio.Group>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Additional attendees" extra={<p>Option for the contact to invite additional attendees to the meeting</p>}>
                    {getFieldDecorator('additionalAttendeesSupported', {
                        rules: [{"required": true, "message": 'Please select additional attendees.'}],
                        initialValue: additionalAttendeesSupported
                    })(
                        <Radio.Group options={lookup.valueList.additionalAttendees || []} />
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Show “Message” for additional notes">
                    {getFieldDecorator('message', {
                        initialValue: message
                    })(
                        <Radio.Group options={lookup.valueList.additionalAttendees || []} />
                    )}
                </FormItem>
                {
                    this.props.form.getFieldValue('message') && <FormItem {...formItemLayout} label="Label for message box">
                        {getFieldDecorator('commentLabel', {
                            rules: [{ "required": true, 
                                "message": "Please provide label for message box." }, {
                                "max": 100, 
                                "message": 'Message should be of maximum 100 characters.' 
                            }],
                            initialValue: commentLabel || "Message"
                        })(
                            <Input name="commentLabel" maxLength={101} />
                        )}
                    </FormItem>
                }
                <FormItem {...formButtonLayout}>
                    <Button type={"primary"} htmlType={"submit"}>
                        Continue<Icon type="right" />
                    </Button>
                </FormItem>
            </Form>
        )
    }
}

const Customize = Form.create()(CustomizeForm);

Customize.propTypes = {
    initialValues: PropTypes.object.isRequired,
    handleCalendarStepsForm: PropTypes.func.isRequired
};

export default Customize;
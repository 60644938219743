import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, InputNumber, Button, message, Spin } from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";

class ScitorUploadForm extends Component {

    handleOnSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (error) {
                return;
            }

            const payload = new FormData();
            payload.append('file', this.uploadedFile, this.uploadedFile.name);
            this.props.fileUploadRequest({data: payload, user: this.props.userData.name, cb: this.handleSubmitResponse});
        });
    }

    handleSubmitResponse = (response) => {
        message[response.type](response.message);
    }

    handleUploadFileChange = (e) => {
        this.uploadedFile = e.target.files[0];
    };

    render() {
        const unauthorized = !this.props.userData.name;
        const { getFieldDecorator } = this.props.form;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <h2>Optum File Upload</h2>
                <Spin spinning={this.props.isLoading}>
                    <Form onSubmit={this.handleOnSubmit}>
                        <Form.Item {...formItemLayout} label={"Upload File"} extra={"Format: xlsx or xls, Maximum 2 MB file size"} >
                            {
                                getFieldDecorator("file", {
                                    rules: [{
                                        "required": true,
                                        "message": "Please upload a file"
                                    }]
                                })(
                                    <Input style={{"padding" : "1.5px"}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type={"file"} placeholder={"Upload file for Bulk Upload"} onChange={this.handleUploadFileChange}/>
                                )
                            }
                        </Form.Item>
                        <Form.Item {...formButtonLayout}>
                            <Button type="primary" htmlType="submit">
                                Upload
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </div>
        );    
    }
}

const ScitorUpload = Form.create()(ScitorUploadForm);
export default ScitorUpload;
import { createActionTypes } from "../../utils";

export const WebinarActions = createActionTypes("webinarTool", [
    "REQ_WEBINAR_SCHEDULE",
    "RESP_WEBINAR_SCHEDULE_SUCCESS",
    "RESP_WEBINAR_SCHEDULE_ERROR",
    "REQ_WEBINAR_DATA",
    "RESP_WEBINAR_DATA_SUCCESS",
    "RESP_WEBINAR_DATA_ERROR",
    "REQ_WEBINAR_EVENTS",
    "RESP_WEBINAR_EVENTS_SUCCESS",
    "RESP_WEBINAR_EVENTS_ERROR",
    "REQ_WEBINARE_PRESENTERS",
    "RESP_WEBINARE_PRESENTERS_SUCCESS",
    "RESP_WEBINARE_PRESENTERS_ERROR",
    "REQ_WEBINAR_EVENT_DELETE",
    "RESP_WEBINAR_EVENT_DELETE_SUCCESS",
    "RESP_WEBINAR_EVENT_DELETE_ERROR",
    "REQ_WEBINAR_REGISTRANTS",
    "RESP_WEBINAR_REGISTRANTS_SUCCESS",
    "RESP_WEBINAR_REGISTRANTS_ERROR",
    "REQ_WEBINAR_ATTENDEES",
    "RESP_WEBINAR_ATTENDEES_SUCCESS",
    "RESP_WEBINAR_ATTENDEES_ERROR",
    "REQ_WEBINAR_PRESENTER_MODAL_OPEN",
    "REQ_WEBINAR_PRESENTER_MODAL_CLOSE",
    "REQ_WEBINAR_PRESENTER_ADD",
    "RESP_WEBINAR_PRESENTER_ADD_SUCCESS",
    "RESP_WEBINAR_PRESENTER_ADD_ERROR",
    "REQ_WEBINAR_PRESENTER_UPDATE",
    "RESP_WEBINAR_PRESENTER_UPDATE_SUCCESS",
    "RESP_WEBINAR_PRESENTER_UPDATE_ERROR",
    "REQ_WEBINAR_PRESENTER_DELETE",
    "RESP_WEBINAR_PRESENTER_DELETE_SUCCESS",
    "RESP_WEBINAR_PRESENTER_DELETE_ERROR",
    "GET_LANDING_PAGE_WEBINAR",
    "GET_LANDING_PAGE_WEBINAR_SUCCESS",
    "GET_LANDING_PAGE_WEBINAR_ERROR",
    "UPDATE_LANDING_PAGE_WEBINAR",
    "UPDATE_LANDING_PAGE_WEBINAR_SUCCESS",
    "UPDATE_LANDING_PAGE_WEBINAR_ERROR",
    "GET_WEBINAR_RECORDING_ASSETS",
    "GET_WEBINAR_RECORDING_ASSETS_SUCCESS",
    "GET_WEBINAR_RECORDING_ASSETS_ERROR",
    "GET_WEBINAR_UPCOMING_SESSION",
    "GET_WEBINAR_UPCOMING_SESSION_SUCCESS",
    "GET_WEBINAR_UPCOMING_SESSION_ERROR",
    "REFRESH_WEBINAR_ATTENDANCE",
    "REFRESH_WEBINAR_ATTENDANCE_SUCCESS",
    "REFRESH_WEBINAR_ATTENDANCE_ERROR",
    "REQ_MEETING_SCHEDULE",
    "RESP_MEETING_SCHEDULE_SUCCESS",
    "RESP_MEETING_SCHEDULE_ERROR",
    "REQ_MEETING_DELETE",
    "RESP_MEETING_DELETE_SUCCESS",
    "RESP_MEETING_DELETE_ERROR"
]);

export const reqWebinarSchedule = (obj) => ({
    type: WebinarActions.REQ_WEBINAR_SCHEDULE,
    payload: obj
});

export const respWebinarScheduleSuccess = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_SCHEDULE_SUCCESS,
    payload: obj
});

export const respWebinarScheduleError = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_SCHEDULE_ERROR,
    payload: obj
});

export const reqWebinarData = (obj) => ({
    type: WebinarActions.REQ_WEBINAR_DATA,
    payload: obj
});

export const respWebinarDataSuccess = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_DATA_SUCCESS,
    payload: obj
});

export const respWebinarDataError = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_DATA_ERROR,
    payload: obj
});

export const reqWebinarEvents = (obj) => ({
    type: WebinarActions.REQ_WEBINAR_EVENTS,
    payload: obj
});

export const respWebinarEventsSuccess = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_EVENTS_SUCCESS,
    payload: obj
});

export const respWebinarEventsError = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_EVENTS_ERROR,
    payload: obj
});

export const reqWebinarPresenters = (obj) => ({
    type: WebinarActions.REQ_WEBINARE_PRESENTERS,
    payload: obj
});

export const respWebinarPresentersSuccess = (obj) => ({
    type: WebinarActions.RESP_WEBINARE_PRESENTERS_SUCCESS,
    payload: obj
});

export const respWebinarPresentersError = (obj) => ({
    type: WebinarActions.RESP_WEBINARE_PRESENTERS_ERROR,
    payload: obj
});

export const reqWebinarEventDelete = (obj) => ({
    type: WebinarActions.REQ_WEBINAR_EVENT_DELETE,
    payload: obj
});

export const respWebinarEventDeleteSuccess = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_EVENT_DELETE_SUCCESS,
    payload: obj
});

export const respWebinarEventDeleteError = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_EVENT_DELETE_ERROR,
    payload: obj
});

export const reqWebinarRegistrants = (obj) => ({
    type: WebinarActions.REQ_WEBINAR_REGISTRANTS,
    payload: obj
});

export const respWebinarRegistrantsSuccess = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_REGISTRANTS_SUCCESS,
    payload: obj
});

export const respWebinarRegistrantsError = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_REGISTRANTS_ERROR,
    payload: obj
});

export const reqWebinarAttendees = (obj) => ({
    type: WebinarActions.REQ_WEBINAR_ATTENDEES,
    payload: obj
});

export const respWebinarAttendeesSuccess = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_ATTENDEES_SUCCESS,
    payload: obj
});

export const respWebinarAttendeesError = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_ATTENDEES_ERROR,
    payload: obj
});

export const reqWebinarPresenterModalOpen = (obj) => ({
    type: WebinarActions.REQ_WEBINAR_PRESENTER_MODAL_OPEN,
    payload: obj
});

export const reqWebinarPresenterModalClose = (obj) => ({
    type: WebinarActions.REQ_WEBINAR_PRESENTER_MODAL_CLOSE,
    payload: obj
});

export const reqWebinarPresenterAdd = (obj, pageType) => ({
    type: WebinarActions.REQ_WEBINAR_PRESENTER_ADD,
    payload: obj,
    pageType
});

export const respWebinarPresenterAddSuccess = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_PRESENTER_ADD_SUCCESS,
    payload: obj
});

export const respWebinarPresenterAddError = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_PRESENTER_ADD_ERROR,
    payload: obj
});

export const reqWebinarPresenterUpdate = (obj, pageType) => ({
    type: WebinarActions.REQ_WEBINAR_PRESENTER_UPDATE,
    payload: obj,
    pageType
});

export const respWebinarPresenterUpdateSuccess = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_PRESENTER_UPDATE_SUCCESS,
    payload: obj
});

export const respWebinarPresenterUpdateError = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_PRESENTER_UPDATE_ERROR,
    payload: obj
});

export const reqWebinarPresenterDelete = (obj, pageType) => ({
    type: WebinarActions.REQ_WEBINAR_PRESENTER_DELETE,
    payload: obj,
    pageType
});

export const respWebinarPresenterDeleteSuccess = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_PRESENTER_DELETE_SUCCESS,
    payload: obj
});

export const respWebinarPresenterDeleteError = (obj) => ({
    type: WebinarActions.RESP_WEBINAR_PRESENTER_DELETE_ERROR,
    payload: obj
});

export const getLandingPageWebinar = (obj) => ({
    type: WebinarActions.GET_LANDING_PAGE_WEBINAR,
    payload: obj
});

export const getLandingPageWebinarSuccess = (obj) => ({
    type: WebinarActions.GET_LANDING_PAGE_WEBINAR_SUCCESS,
    payload: obj
});

export const getLandingPageWebinarError = (obj) => ({
    type: WebinarActions.GET_LANDING_PAGE_WEBINAR_ERROR,
    payload: obj
});

export const updateLandingPageWebinar = (obj) => ({
    type: WebinarActions.UPDATE_LANDING_PAGE_WEBINAR,
    payload: obj
});

export const updateLandingPageWebinarSuccess = (obj) => ({
    type: WebinarActions.UPDATE_LANDING_PAGE_WEBINAR_SUCCESS,
    payload: obj
});

export const updateLandingPageWebinarError = (obj) => ({
    type: WebinarActions.UPDATE_LANDING_PAGE_WEBINAR_ERROR,
    payload: obj
});

export const getWebinarRecordingAssets = (obj) => ({
    type: WebinarActions.GET_WEBINAR_RECORDING_ASSETS,
    payload: obj
});

export const getWebinarRecordingAssetsSuccess = (obj) => ({
    type: WebinarActions.GET_WEBINAR_RECORDING_ASSETS_SUCCESS,
    payload: obj
});

export const getWebinarRecordingAssetsError = (obj) => ({
    type: WebinarActions.GET_WEBINAR_RECORDING_ASSETS_ERROR,
    payload: obj
});

export const getWebinarUpcomingSession = (obj) => ({
    type: WebinarActions.GET_WEBINAR_UPCOMING_SESSION,
    payload: obj
});

export const getWebinarUpcomingSessionSuccess = (obj) => ({
    type: WebinarActions.GET_WEBINAR_UPCOMING_SESSION_SUCCESS,
    payload: obj
});

export const getWebinarUpcomingSessionError = (obj) => ({
    type: WebinarActions.GET_WEBINAR_UPCOMING_SESSION_ERROR,
    payload: obj
});

export const refreshWebinarAttendance = (obj) => ({
    type: WebinarActions.REFRESH_WEBINAR_ATTENDANCE,
    payload: obj
});

export const refreshWebinarAttendanceSuccess = (obj) => ({
    type: WebinarActions.REFRESH_WEBINAR_ATTENDANCE_SUCCESS,
    payload: obj
});

export const refreshWebinarAttendanceError = (obj) => ({
    type: WebinarActions.REFRESH_WEBINAR_ATTENDANCE_ERROR,
    payload: obj
});

export const reqMeetingSchedule = (obj) => ({
    type: WebinarActions.REQ_MEETING_SCHEDULE,
    payload: obj
});

export const respMeetingScheduleSuccess = (obj) => ({
    type: WebinarActions.RESP_MEETING_SCHEDULE_SUCCESS,
    payload: obj
});

export const respMeetingScheduleError = (obj) => ({
    type: WebinarActions.RESP_MEETING_SCHEDULE_ERROR,
    payload: obj
});

export const reqMeetingDelete = (obj) => ({
    type: WebinarActions.REQ_MEETING_DELETE,
    payload: obj
});

export const respMeetingDeleteSuccess = (obj) => ({
    type: WebinarActions.RESP_MEETING_DELETE_SUCCESS,
    payload: obj
});

export const respMeetingDeleteError = (obj) => ({
    type: WebinarActions.RESP_MEETING_DELETE_ERROR,
    payload: obj
});

export default WebinarActions;
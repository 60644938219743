import React, { Component } from "react";
import PropTypes from "prop-types";
import {TableSkeleton, RedirectWrapper} from "../../components";
import lookup from "./activity";
import {Spin} from 'antd';
import {fromActivityData, mapFilterOptions, getActivityType} from './activityAPIHelper';
import './activity.scss';
import ActivitySearchFilter from "./ActivitySearchFilter";
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
class ViewActivities extends Component {
    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.props.fetchActivitiesListFromRoute();
    }

    getRequestDetail = (activity) => (
        <div>
            <div className="mb1">
                <b>URL: </b>
                <b>{activity.url}</b>
            </div>
            <div className="mb1">
                <b>Request: </b>
                <Editor
                    value={activity.request}
                    mode={'view'}
                />
            </div>
        </div>
    );

    formatTableRows = (data) => (
        data.map(fromActivityData).map(record=> ({
            ...record,
            key: record.id,
        }))
    );

    searchAndFilter = (pagination, filters, sorter) => {
        const
            searchParams = this.props.searchParams,
            currentOptions = {
                user : searchParams.user,
                api  : searchParams.api,
                type : searchParams.type
            },
            pageOptions = {
                pageSize: pagination.pageSize,
                pageIndex: pagination.current
            },
            filtersOptions = {};

        // Needed because filter options from table come as {api: ['case study']}
        Object.entries(filters).forEach(entry => {
            filtersOptions[entry[0].toLowerCase()] = entry[1][0];
        });

        this.props.fetchActivitiesList({
            ...currentOptions,
            ...pageOptions,
            ...mapFilterOptions(filtersOptions)
        });
    };

    resetSearchParam = key => {
        const newSearchParams = {
            ...this.props.searchParams
        };

        delete newSearchParams[key];
        this.props.fetchActivitiesList(newSearchParams);
    };

    render(){
        const
            {isLoading, data, searchParams, totalData, userData, error} = this.props,
            {resetSearchParam, getRequestDetail, formatTableRows, searchAndFilter} = this,
            {pageSize, pageIndex} = searchParams;

        const unauthorized = error === "unauthorized" || !userData.name;
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="resources-root">
                <h2>Activities</h2>
                <ActivitySearchFilter resetSearchParam={resetSearchParam} searchParams={searchParams}/>
                {isLoading ?
                    <Spin /> :
                    <TableSkeleton
                        pageSize={pageSize}
                        totalData={totalData}
                        currentPage={pageIndex}
                        columns={lookup.columns}
                        expandedRowRender={getRequestDetail}
                        dataSource={formatTableRows(data)}
                        onChange={searchAndFilter}
                    />
                }
            </div>
        );
    }
}

ViewActivities.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    searchParams: PropTypes.object.isRequired,
    fetchActivitiesList: PropTypes.func.isRequired,
};

export default ViewActivities;

import ProspectorActions from "./actions";
import { getRecordCount } from "../utils";

let Prospector = {
  records: [],
  recordError: {},
  isRecordLoading: true,
  recordCount: { lead: 0, customer: 0, opportunity: 0 },
  targetProfile: {},
  profileError: {},
  isProfileLoading: false,
  stateList: [],
  cityList: [],
  industryList: [],
  ownerList: [],
  industryToSubs: {},
  pageError: ""
};

const prospectorReducer = (state = Prospector, action) => {
  switch (action.type) {
    /* records */
    case ProspectorActions.FETCH_RECORDS_REQUEST:
      return {
        ...state,
        recordError: {},
        isRecordLoading: true
      };
    case ProspectorActions.FETCH_RECORDS_SUCCESS:
      return {
        ...state,
        records: action.payload,
        isRecordLoading: false
      };
    case ProspectorActions.FETCH_RECORDS_FAILURE:
      return {
        ...state,
        isRecordLoading: false,
        recordError: action.payload
      };
    case ProspectorActions.UPDATE_RECORD_COUNT:
      return {
        ...state,
        recordCount: getRecordCount(state.records, state.targetProfile)
      };
    /* target profile */
    case ProspectorActions.FETCH_TARGET_PROFILE_REQUEST:
      return {
        ...state,
        profileError: {},
        isProfileLoading: true
      };
    case ProspectorActions.FETCH_TARGET_PROFILE_SUCCESS:
      return {
        ...state,
        targetProfile: action.payload || {},
        isProfileLoading: false
      };
    case ProspectorActions.FETCH_TARGET_PROFILE_FAILURE:
      return {
        ...state,
        isProfileLoading: false,
        profileError: action.payload
      };
    /* state, city, industry, and owner list */
    case ProspectorActions.FETCH_STATE_LIST_SUCCESS:
      return {
        ...state,
        stateList: action.payload
      };
    case ProspectorActions.FETCH_CITY_LIST_SUCCESS:
      return {
        ...state,
        cityList: action.payload
      };
    case ProspectorActions.FETCH_INDUSTRY_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case ProspectorActions.FETCH_OWNER_LIST_SUCCESS:
      return {
        ...state,
        ownerList: action.payload
      };
    case ProspectorActions.FETCH_FORM_OPTION_FAILURE:
      return {
        ...state,
        pageError: action.payload
      };
    default:
      return state;
  }
};

export default prospectorReducer;

import { connect } from "react-redux";

import { fetchAllProductMessageRequest, updateProductMessageRequest, deleteProductMessageRequest, updateProductMessageIndustryImageRequest } from "./actions";

import ProductMessageForm from "./ProductMessageForm";
import ProductMessageList from "./ProductMessageList";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Upsell
});

export const ProductMessageFormPage = connect(
    mapStateToProps,
    { fetchAllProductMessageRequest, updateProductMessageRequest }
)( ProductMessageForm );

export const ProductMessageListPage = connect(
    mapStateToProps,
    { fetchAllProductMessageRequest, deleteProductMessageRequest, updateProductMessageIndustryImageRequest }
)( ProductMessageList );

import React, { useEffect, useState } from 'react'
import { PageHeaderContainer } from '../../components'
import { formButtonLayout, formItemLayout } from '../../constants';
import { Button, DatePicker, Form, Input, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import { beAPIResource } from '../../utils/apiHelper';
import moment from 'moment';
import { connect } from 'react-redux';
const FormItem = Form.Item;
const { TextArea } = Input;

const ProductReleaseUpdateForm = (props) => {
    const {userData} = props || {};
    const isUpdate = props.match.params.id !== 'new';
    const { getFieldDecorator } = props.form;
    const pageKey = 'productReleaseUpdate';

    useEffect(() => {
        (async()=>{
            if(isUpdate) {
                const {data} = await beAPIResource.get(`/support/partners/product-release?id=${props.match.params.id}`);
                if (data[0]) {
                    const pageData = JSON.parse(data[0].data);
                    props.form.setFieldsValue({ ...pageData, date: moment(pageData.date)})
                }
            }
        })()
    }, [])
    

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields(async(err, values) => {
        if (!err) {
            try {
                let payload = {
                    data: JSON.stringify({
                        ...values,
                        fileId: values.fileId.includes('https') ? extractFileId(values.fileId) : values.fileId,
                        date: moment(values.date._d || values.date).format('MM/DD/YY')
                    }),
                    ...(isUpdate ? {updatedBy: userData.name} : {createdBy: userData.name})
                }
                if (isUpdate) payload.id = props.match.params.id;
                await beAPIResource.put(`/support/partners/product-release`, payload);
                return Modal.success({
                    title: "Success",
                    okText: "See the list",
                    content: 'Record saved successfully.',
                    onOk() {
                        props.history.push('/product-release')
                    }
                });
            } catch (error) {
                return Modal.error({
                    title: `Something went wrong.`,
                    content: 'Record was not saved.'
                });
            }
            
        }
        });
    }

    const extractFileId = (url) => {
        const regex = /\/d\/([a-zA-Z0-9-_]+)\//;
        const match = url.match(regex);
        if (match && match[1]) {
          return match[1];
        } else {
          return null;
        }
      }

    return (
        <>
            <PageHeaderContainer pageKey={pageKey} title={"Product & Feature"} />
            <Form onSubmit={handleSubmit}>
                <FormItem {...formItemLayout} label="Title">
                    {getFieldDecorator('title', {
                        rules: [{ required: true, message: 'Please input the title!' }],
                    })(
                        <Input />,
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Description">
                    {getFieldDecorator('description', {
                        rules: [{ required: true, message: 'Please input the description!' }],
                    })(
                        <TextArea rows={4} />,
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Date">
                    {getFieldDecorator('date', {
                        rules: [{ required: true, message: 'Please select the date!' }],
                    })(
                        <DatePicker format={'MM/DD/YY'} />,
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label={"Document Shared ID/URL"} extra={<p>Make sure the file is shared. Supported file formats <b>Document</b>, <b>Presentation</b>, <b>Spreadsheets</b>, <b>Image</b>, and <b>PDF</b></p>}>
                    {
                        getFieldDecorator("fileId", {
                            rules: [{
                                required: true,
                                message: "Please provide document URL"
                            }]
                        })(
                            <Input />
                        )
                    }
                </FormItem>
                <FormItem {...formButtonLayout}>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </FormItem>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({
    ...state.User
});

const ProductReleaseUpdate = Form.create({ name: 'normal_login' })(ProductReleaseUpdateForm);

export default withRouter(connect(mapStateToProps)(ProductReleaseUpdate));

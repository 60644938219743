import { WebinarActions } from "./actions";

const DEFAULT_STATE = {
    isLoading: false,
    notification: {
        timestamp: 0
    },
    notificationPresenterModal: {
        timestamp: 0
    },
    events: {
        upcoming: [],
        past: []
    },
    event: {},
    presenters: [],
    isLoadingUsers: false,
    fetchUsersFailed: false,
    registrants: [],
    attendance: [],
    isPresenterModalVisible: false,
    isLoadingPresenterModal: false,
    currentLandingPageWebinar: {},
    isLoadingPresenter: false,
    isLoadingRecordingAssets: false,
    isLoadingUpcomingSession: false,
    recordingAssets: [],
    upcomingSession: []
};

const webinar = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case WebinarActions.REQ_WEBINAR_SCHEDULE:
            return {
                ...state,
                isLoading: true
            };
        case WebinarActions.RESP_WEBINAR_SCHEDULE_SUCCESS:
        return {
            ...state,
            isLoading: false,
            events: {
                upcoming: [],
                past: []
            },
            notification: {
                type: "success",
                message: "Your event is successfully scheduled.",
                timestamp: (new Date()).getTime()
            }
        };
        case WebinarActions.RESP_WEBINAR_SCHEDULE_ERROR:
            return {
                ...state,
                isLoading: false,
                notification: {
                    type: "error",
                    message: action.payload,
                    timestamp: (new Date()).getTime()
                }
            };
        case WebinarActions.REQ_WEBINAR_DATA:
        return {
            ...state,
            isLoading: true,
            event: {}
        };
        case WebinarActions.RESP_WEBINAR_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                event: action.payload
            };
        case WebinarActions.RESP_WEBINAR_DATA_ERROR:
        return {
            ...state,
            isLoading: false
        };
        case WebinarActions.REQ_WEBINAR_EVENTS:
            return {
                ...state,
                isLoading: true,
                event: {}
            };
        case WebinarActions.RESP_WEBINAR_EVENTS_SUCCESS:
            const 
                events = {...state.events},
                {eventsType, data} = action.payload;
            
            events[eventsType] = data;

            return {
                ...state,
                isLoading: false,
                events
            };
        case WebinarActions.RESP_WEBINAR_EVENTS_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case WebinarActions.REQ_WEBINARE_PRESENTERS:
            return {
                ...state,
                isLoadingPresenter: true,
                isLoadingPresenterModal: true,
            };
        case WebinarActions.RESP_WEBINARE_PRESENTERS_SUCCESS:
        case WebinarActions.RESP_WEBINARE_PRESENTERS_ERROR:
            return {
                ...state,
                isLoadingPresenter: false,
                presenters: action.payload,
                isLoadingPresenterModal: false,
            };
        case WebinarActions.REQ_WEBINAR_EVENT_DELETE:
            return {
                ...state,
                isLoading: true,
                notification: {
                    timestamp: 0
                }
            };
        case WebinarActions.RESP_WEBINAR_EVENT_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                events: {
                    upcoming: [],
                    past: []
                },
                notification: {
                    type: "success",
                    message: "You have successfully deleted this event.",
                    timestamp: (new Date()).getTime()
                }
            };
        case WebinarActions.RESP_WEBINAR_EVENT_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                notification: {
                    type: "error",
                    message: "Oops! Something went wrong while deleting this event. Kindly try after some moment.",
                    timestamp: (new Date()).getTime()
                }
            };
        case WebinarActions.REQ_WEBINAR_REGISTRANTS:
            return {
                ...state,
                isLoadingUsers: true,
                registrants: []
            };
        case WebinarActions.RESP_WEBINAR_REGISTRANTS_SUCCESS:
            return {
                ...state,
                isLoadingUsers: false,
                registrants: action.payload,
                fetchUsersFailed: false
            };
        case WebinarActions.RESP_WEBINAR_REGISTRANTS_ERROR:
            return {
                ...state,
                isLoadingUsers: false,
                fetchUsersFailed: true
            };
        case WebinarActions.REQ_WEBINAR_ATTENDEES:
            return {
                ...state,
                isLoadingUsers: true,
                attendance: []
            };
        case WebinarActions.RESP_WEBINAR_ATTENDEES_SUCCESS:
            return {
                ...state,
                isLoadingUsers: false,
                attendance: action.payload,
                fetchUsersFailed: false
            };
        case WebinarActions.RESP_WEBINAR_ATTENDEES_ERROR:
            return {
                ...state,
                isLoadingUsers: false,
                fetchUsersFailed: true
            };
        case WebinarActions.REQ_WEBINAR_PRESENTER_MODAL_OPEN:
            return {
                ...state,
                isPresenterModalVisible: true
            }
        case WebinarActions.REQ_WEBINAR_PRESENTER_MODAL_CLOSE:
            return {
                ...state,
                isPresenterModalVisible: false
            }
        case WebinarActions.REQ_WEBINAR_PRESENTER_ADD:
        case WebinarActions.REQ_WEBINAR_PRESENTER_UPDATE:
        case WebinarActions.REQ_WEBINAR_PRESENTER_DELETE:
            return {
                ...state,
                isLoadingPresenterModal: true,
            }
        case WebinarActions.RESP_WEBINAR_PRESENTER_ADD_SUCCESS:
        case WebinarActions.RESP_WEBINAR_PRESENTER_UPDATE_SUCCESS:
        case WebinarActions.RESP_WEBINAR_PRESENTER_DELETE_SUCCESS:
            return {
                ...state,
                isLoadingPresenterModal: true,
                isPresenterModalVisible: true,
                presenters: [],
                notificationPresenterModal: {
                    type: "success",
                    message: action.payload || "Successfully operation perfomed for this presenter",
                    timestamp: (new Date()).getTime()
                }
            }
        case WebinarActions.RESP_WEBINAR_PRESENTER_ADD_ERROR:
        case WebinarActions.RESP_WEBINAR_PRESENTER_UPDATE_ERROR:
        case WebinarActions.RESP_WEBINAR_PRESENTER_DELETE_ERROR:
            return {
                ...state,
                isLoadingPresenterModal: false,
                isPresenterModalVisible: true,
                notificationPresenterModal: {
                    type: "error",
                    message: action.payload || "Oops! Something went wrong while operating this presenter. Kindly try after some moment.",
                    timestamp: (new Date()).getTime()
                }
            }
        case WebinarActions.GET_LANDING_PAGE_WEBINAR:
            return {
                ...state,
                isLoadingCurrentWebinar: true,
            }
        case WebinarActions.GET_LANDING_PAGE_WEBINAR_SUCCESS:
            return {
                ...state,
                currentLandingPageWebinar: action.payload,
                isLoadingCurrentWebinar: false,
            }
        case WebinarActions.GET_LANDING_PAGE_WEBINAR_ERROR:
            return {
                ...state,
                currentLandingPageWebinar: {},
                isLoadingCurrentWebinar: false,
            }
        case WebinarActions.UPDATE_LANDING_PAGE_WEBINAR:
            return {
                ...state,
                isLoadingCurrentWebinar: true
            }
        case WebinarActions.UPDATE_LANDING_PAGE_WEBINAR_SUCCESS:
            return {
                ...state,
                isLoadingCurrentWebinar: false
            }
        case WebinarActions.UPDATE_LANDING_PAGE_WEBINAR_ERROR:
            return {
                ...state,
                isLoadingCurrentWebinar: false
            }
        case WebinarActions.GET_WEBINAR_RECORDING_ASSETS:
            return {
                ...state,
                isLoadingRecordingAssets: true
            }
        case WebinarActions.GET_WEBINAR_RECORDING_ASSETS_SUCCESS:
        case WebinarActions.GET_WEBINAR_RECORDING_ASSETS_ERROR:
            return {
                ...state,
                isLoadingRecordingAssets: false,
                recordingAssets: action.payload
            }
        case WebinarActions.GET_WEBINAR_UPCOMING_SESSION:
            return {
                ...state,
                isLoadingUpcomingSession: true
            }
        case WebinarActions.GET_WEBINAR_UPCOMING_SESSION_SUCCESS:
        case WebinarActions.GET_WEBINAR_UPCOMING_SESSION_ERROR:
            return {
                ...state,
                isLoadingUpcomingSession: false,
                upcomingSession: action.payload
            }
        case WebinarActions.REFRESH_WEBINAR_ATTENDANCE:
            return {
                ...state,
                isLoading: true,
            };
        case WebinarActions.REFRESH_WEBINAR_ATTENDANCE_SUCCESS:
        case WebinarActions.REFRESH_WEBINAR_ATTENDANCE_ERROR:
            return {
                ...state,
                isLoading: false,
                notification: {
                    ...action.payload,
                    timestamp: (new Date()).getTime()
                }
            };
        case WebinarActions.REQ_MEETING_SCHEDULE:
            return {
                ...state,
                isLoading: true
            };
        case WebinarActions.RESP_MEETING_SCHEDULE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                events: {
                    upcoming: [],
                    past: []
                },
                notification: {
                    type: "success",
                    message: "Your meeting is successfully scheduled.",
                    timestamp: (new Date()).getTime()
                }
            };
        case WebinarActions.RESP_MEETING_SCHEDULE_ERROR:
            return {
                ...state,
                isLoading: false,
                notification: {
                    type: "error",
                    message: action.payload,
                    timestamp: (new Date()).getTime()
                }
            };
        case WebinarActions.REQ_MEETING_DELETE:
            return {
                ...state,
                isLoading: true,
                notification: {
                    timestamp: 0
                }
            };
        case WebinarActions.RESP_MEETING_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                events: {
                    upcoming: [],
                    past: []
                },
                notification: {
                    type: "success",
                    message: "You have successfully deleted this meeting.",
                    timestamp: (new Date()).getTime()
                }
            };
        case WebinarActions.RESP_MEETING_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                notification: {
                    type: "error",
                    message: "Oops! Something went wrong while deleting this meeting. Kindly try after some moment.",
                    timestamp: (new Date()).getTime()
                }
            };
        default:
            return state;
    }
};

export default webinar;
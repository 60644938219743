import React, {Component} from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Radio, Icon } from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";
import { getSlug } from "../../utils";

import lookup from "./constants";

const { Item: FormItem } = Form;

class ClickToActionsForm extends Component {

    constructor(props) {
        super(props);

        this.defaultPrimaryCTA = lookup.cta.defaultPrimaryCTA[props.type];
    }
    
    handleFormSubmission = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (error) {
                return;
            }

            let updatedCtaButtons = [];

            if (values.primaryCTA) {
                const { ctaButtons } = values;

                for (let ctaButton of ctaButtons) {

                    const buttonLabel = ctaButton.label && ctaButton.label.toLowerCase().replace(/\s/gi, '');

                    updatedCtaButtons.push({
                        ...ctaButton,
                        dataClickTag: `bottom_${buttonLabel}_lead_button`
                    });
                }
            }

            delete(values.primaryCTA);

            this.props.handleStepFormSubmission({ cta: {...values, ctaButtons: updatedCtaButtons} });
        });
    };
    
    render() {
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { title="Start growing with Birdeye today!", ctaButtons = [] } = this.props.initialValues.cta || {};
        const formValues = getFieldsValue();
        const primaryCTA = formValues.hasOwnProperty('primaryCTA') ? formValues.primaryCTA : (ctaButtons.length > 0 ? 1 : 0); // this is to handle multiple array if in case left

        return (
            <>
                <Form style={{}} onSubmit={this.handleFormSubmission}>
                    <FormItem {...formItemLayout} label={"Title"}>
                        {
                            getFieldDecorator("title", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable title"
                                }],
                                initialValue: title
                            }) (
                                <Input placeholder={"Title"}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Primary CTA"}>
                        {
                            getFieldDecorator("primaryCTA", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable tag related to resource"
                                }],
                                initialValue: primaryCTA
                            }) (
                                <Radio.Group options={lookup.cta.primaryCTAOptions || []} />
                            )
                         }
                    </FormItem>
                    {
                        !!primaryCTA && (
                            <>
                                <FormItem {...formItemLayout} label="Primary CTA">
                                    {
                                        getFieldDecorator(`ctaButtons[0][label]`, {
                                            rules: [{
                                                required: true,
                                                message: "Provide a short label describing this CTA"
                                            }],
                                            initialValue: ctaButtons[0] && ctaButtons[0].label
                                        }) (
                                            <Input placeholder={"Label for CTA"} />
                                        )
                                    }
                                </FormItem>
                                <FormItem {...formButtonLayout}>
                                    {
                                        getFieldDecorator(`ctaButtons[0][action]`, {
                                            rules: [{
                                                required: true,
                                                message: "Provide an action for this CTA"
                                            }],
                                            initialValue: ctaButtons[0] && ctaButtons[0].action
                                        }) (
                                            <Input placeholder={"Action for CTA"} />
                                        )
                                    }
                                </FormItem>
                            </>
                        )
                    }
                    <FormItem {...formButtonLayout}>
                        <Button type={"primary"} htmlType={"submit"}>
                            Next<Icon type="right" />
                        </Button>
                    </FormItem>
                </Form>
            </>
        );
    }
}

const ClickToActions = Form.create()(ClickToActionsForm);

ClickToActions.propTypes = {
    userData: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    additional: PropTypes.object,
    handleStepFormSubmission: PropTypes.func.isRequired
};

export default ClickToActions;
import { createActionTypes } from "../../utils";

export const UserActions = createActionTypes("user", [
    "LOGIN_REQUEST",
    "LOGOUT_REQUEST",
    "LOGIN_SUCCESS",
    "LOGOUT_SUCCESS",
    "FETCH_USERS_LIST_REQUEST",
    "FETCH_USERS_LIST_SUCCESS",
    "FETCH_USER_REQUEST",
    "FETCH_USER_SUCCESS",
    "ADD_USER_REQUEST",
    "ADD_USER_SUCCESS",
    "UPDATE_USER_REQUEST",
    "UPDATE_USER_SUCCESS",
    "USER_REQUEST_ERROR",
    "FETCH_USER_ROLES",
    "FETCH_USER_ROLES_SUCCESS",
    "FETCH_USER_ROLES_ERROR",
    "REDIRECT_TO_LOGIN_IF_NO_SESSION",
    "FETCH_PERMISSIONS",
    "FETCH_PERMISSIONS_SUCCESS",
    "FETCH_PERMISSION",
    "FETCH_PERMISSION_SUCCESS",
    "UPDATE_PERMISSION",
    "UPDATE_PERMISSION_SUCCESS",
    "FETCH_ROLE",
    "FETCH_ROLE_SUCCESS",
    "UPDATE_ROLE",
    "ADD_ROLE",
    "ADD_PERMISSION",
    "ADD_PERMISSION_SUCCESS",
    "DELETE_USER_REQUEST",
    "DELETE_USER_SUCCESS",
    "DELETE_USER_ERROR",
    "DELETE_USER_ROLES_REQUEST",
    "DELETE_USER_ROLES_SUCCESS",
    "DELETE_USER_ROLES_ERROR",
    "DELETE_PERMISSION_REQUEST",
    "DELETE_PERMISSION_SUCCESS",
    "DELETE_PERMISSION_ERROR",
    "BIRDEYE_LOGIN_REQUEST",
    "BIRDEYE_LOGIN_SUCCESS",
    "BIRDEYE_LOGIN_ERROR"
]);

export const loginRequest = (obj) => {
    return {
        type: UserActions.LOGIN_REQUEST,
        payload: obj
    };
};

export const loginSucess = (obj) => {
    return {
        type: UserActions.LOGIN_SUCCESS,
        payload: obj
    };
};

export const birdeyeLoginRequest = (obj) => {
    return {
        type: UserActions.BIRDEYE_LOGIN_REQUEST,
        payload: obj
    };
};

export const birdeyeLoginSucess = (obj) => {
    return {
        type: UserActions.BIRDEYE_LOGIN_SUCCESS,
        payload: obj
    };
};

export const logoutRequest = () => {
    return {
        type: UserActions.LOGOUT_REQUEST
    };
};

export const logoutSuccess = () => {
    return {
        type: UserActions.LOGOUT_SUCCESS
    };
};

export const fetchUsersListRequest = () => {
    return {
        type: UserActions.FETCH_USERS_LIST_REQUEST
    };
};

export const fetchUserRolesListRequest = () => {
    return {
        type: UserActions.FETCH_USER_ROLES
    };
};

export const fetchUsersListSucess = (obj) => {
    return {
        type: UserActions.FETCH_USERS_LIST_SUCCESS,
        payload: obj
    };
};

export const fetchUserRolesSuccess = (obj) => {
    return {
        type: UserActions.FETCH_USER_ROLES_SUCCESS,
        payload: obj
    };
};

export const fetchUserRequest = (obj) => {
    return {
        type: UserActions.FETCH_USER_REQUEST,
        payload: obj
    };
};

export const fetchUserSuccess = (obj) => {
    return {
        type: UserActions.FETCH_USER_SUCCESS,
        payload: obj
    };
};

export const addUserRequest = (obj) => {
    return {
        type: UserActions.ADD_USER_REQUEST,
        payload: obj
    };
};

export const addUserSuccess = (obj) => {
    return {
        type: UserActions.ADD_USER_SUCCESS,
        payload: obj
    };
};

export const updateUserRequest = (obj) => {
    return {
        type: UserActions.UPDATE_USER_REQUEST,
        payload: obj
    };
};

export const updateUserSuccess = (obj) => {
    return {
        type: UserActions.UPDATE_USER_SUCCESS,
        payload: obj
    };
};

export const userError = (obj) => {
    return {
        type: UserActions.USER_REQUEST_ERROR,
        payload: obj
    };
};

export const userRolesError = (obj) => {
    return {
        type: UserActions.FETCH_USER_ROLES_ERROR,
        payload: obj
    };
};

export const redirectToLoginIfNoSession = (obj) => ({
    type: UserActions.REDIRECT_TO_LOGIN_IF_NO_SESSION,
    payload: obj
});

export const fetchPermissionsList = () => {
    return {
        type: UserActions.FETCH_PERMISSIONS
    };
};

export const fetchPermissionsSuccess = (obj) => {
    return {
        type: UserActions.FETCH_PERMISSIONS_SUCCESS,
        payload: obj
    };
};

export const fetchPermission = (id) => {
    return {
        type: UserActions.FETCH_PERMISSION,
        payload: id
    };
};

export const updatePermission = (obj) => {
    return {
        type: UserActions.UPDATE_PERMISSION,
        payload: obj
    };
};

export const addPermission = (obj) => {
    return {
        type: UserActions.ADD_PERMISSION,
        payload: obj
    };
};

export const fetchPermissionSuccess = (obj) => {
    return {
        type: UserActions.FETCH_PERMISSION_SUCCESS,
        payload: obj
    };
};

export const updatePermissionSuccess = () => {
    return {
        type: UserActions.UPDATE_PERMISSION_SUCCESS
    };
};

export const addPermissionSuccess = () => {
    return {
        type: UserActions.ADD_PERMISSION_SUCCESS
    };
};

export const fetchRoleRequest = payload => ({
    type: UserActions.FETCH_ROLE,
    payload
});

export const fetchRoleRequestSuccess = payload => ({
    type: UserActions.FETCH_ROLE_SUCCESS,
    payload
});

export const updateRoleRequest = payload => ({
    type: UserActions.UPDATE_ROLE,
    payload
});

export const addRoleRequest = payload => ({
    type: UserActions.ADD_ROLE,
    payload
});

export const deleteUserRequest = payload => ({
    type: UserActions.DELETE_USER_REQUEST,
    payload
});

export const deleteUserRequestSuccess = payload => ({
    type: UserActions.DELETE_USER_SUCCESS,
    payload
});

export const deleteUserRequestError = payload => ({
    type: UserActions.DELETE_USER_ERROR,
    payload
});

export const deleteUserRolesRequest = payload => ({
    type: UserActions.DELETE_USER_ROLES_REQUEST,
    payload
});

export const deleteUserRolesRequestSuccess = payload => ({
    type: UserActions.DELETE_USER_ROLES_SUCCESS,
    payload
});

export const deleteUserRolesRequestError = payload => ({
    type: UserActions.DELETE_USER_ROLES_ERROR,
    payload
});

export const deletePermissionRequest = payload => ({
    type: UserActions.DELETE_PERMISSION_REQUEST,
    payload
});

export const deletePermissionRequestSuccess = payload => ({
    type: UserActions.DELETE_PERMISSION_SUCCESS,
    payload
});

export const deletePermissionRequestError = payload => ({
    type: UserActions.DELETE_PERMISSION_ERROR,
    payload
});

export default UserActions;
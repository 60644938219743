import { connect } from "react-redux";
import { reqBusinessDetails, reqSelfServeApprove, reqSelfServeReject, reqSelfServeCRMSave, requestedCRMIntegrationList } from './actions';
import SelfServeTab from "./SelfServeTab";
import ShowRequestedCRM from "./ShowRequestedCRM";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.SelfServe
});

export const IntegrationSelfServeTabPage = connect(
    mapStateToProps, { reqBusinessDetails, reqSelfServeApprove, reqSelfServeReject, reqSelfServeCRMSave }
)(SelfServeTab);

export const IntegrationRequestedCRMPage = connect(
    mapStateToProps, { requestedCRMIntegrationList }
)(ShowRequestedCRM);

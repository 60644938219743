import React, { Component } from "react";
import PropTypes from "prop-types";
import { pick } from "lodash";
import moment from "moment";
import { Form, Input, Button, Alert, Spin, Modal, Upload, Icon, DatePicker, Checkbox, Row, Col, Radio } from "antd";
import { TextEditor, RedirectWrapper } from "../../components";
import { getSlug, uploadUtility } from "../../utils";
import { formItemLayout, formButtonLayout, dateFormat, newsLetterPublishAccessList } from "../../constants";
import lookup from "./featureReleases.json";

const FormItem = Form.Item;

const { TextArea } = Input;

class FeatureForm extends Component {
    initialData = {
        id: 0,
        title: "",
        imageUrl: "",
        cardImageUrl: "",
        shortSummary: "",
        longDescription: "",
        businessTypeIds: [],
        applicableModuleIds: [],
        releaseType: [],
        detailsUrl: "",
        productionLiveDate: moment().format(dateFormat),
        showCalloutInDashboard: false,
        applicableModule:[],
        businessType:[],
        includedInNewsletter: false,
        featureInNewsletterByBusinessType: [],
        archived: false
    };

    state = {
        data: {...this.initialData},
        formError: "",
        imageType: "",
        imageError: false,
        isEditPage: !!this.props.match.params.id,
        uploadingImage: false
    };

    componentDidMount() {
        const { location, match, fetchFeatureRequest } = this.props;

        this.props.fetchBusinessTypeRequest();
        this.props.fetchApplicableModuleRequest();
        this.props.fetchReleaseTypesRequest();

        if (match.params.id) {
            fetchFeatureRequest({ id: match.params.id });
        }
        this.timestamp = Date.now().toString();
    }

    componentDidUpdate(prevProps) {
        if (this.props.curData.releaseId && this.props.curData.releaseId !== prevProps.curData.releaseId) {
            let newCurrentData = this.mapDataToForm(this.props.curData);
            this.setState({
                data: {...this.state.data, ...newCurrentData}
            });
        }
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                isEditPage: !!this.props.match.params.id,
                data: this.initialData
             });
            this.props.fetchBusinessTypeRequest();
            this.props.fetchApplicableModuleRequest();
            this.props.fetchReleaseTypesRequest();
        }
    }

    mapDataToForm = (rawData) => {
        const
            aggData = pick(rawData, ["id", ...lookup.valueLookup.aggFields]),
            {businessTypes} = rawData,
            businessTypeIds = [], featureInNewsletterByBusinessType= [];

        let includedInNewsletter = false;

        if (businessTypes && businessTypes.length) {
            for (const businessType of businessTypes) {
                businessTypeIds.push(businessType.id);
                includedInNewsletter = includedInNewsletter || businessType.includedInNewsletter;
                businessType.includedInNewsletter && businessType.featureInNewsletter && featureInNewsletterByBusinessType.push(businessType.id);
            }
        }

        return {
            ...aggData,
            applicableModuleIds: rawData.applicableModules ? rawData.applicableModules.map(module => module.id) : [],
            businessTypeIds,
            releaseType: rawData.releaseType ? rawData.releaseType.id : "",
            includedInNewsletter,
            featureInNewsletterByBusinessType,
            id: rawData.releaseId ? rawData.releaseId : 0
        };
    };

    beforeUpload = (file) => {

        const isValid = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif' || file.type === 'video/mp4';
        if (!isValid) {
            this.setState({ formError: "Invalid File", imageError: true });
            return false;
        }
        this.setState({ imageType: file.type });

        const isFile5M = file.size / 1024 / 1024 < 5;
        if (!isFile5M) {
            this.setState({ formError: "File size must be smaller than 5MB", imageError: true });
            return false;
        }
        this.setState({ formError: "", imageError: false });
        return isValid && isFile5M;
    }

    mediaRemove = (file) => {
        this.setState({uploadingImage: false, formError: "", imageError: false});
    }

    getImageUploadUrl = (name, pd) => {
        const pdQuery = pd ? `&pd=${pd}` : "";
        if(!this.state.imageError){
            if(this.state.imageType === 'video/mp4'){
                return `${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=${name}&timestamp=${this.timestamp}${pdQuery}`;
            } else {
                return `${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=${name}&timestamp=${this.timestamp}${pdQuery}&webp=true`;
            }
        } else {
            return '';
        }
    }

    handleInputChange = (e, imageFieldName) => {
        if (e.target) {
            const { name, value } = e.target;
            this.handleFieldChange(name, value);
        } else if (e.file) {
            if(e.file.status) {
                if (e.file.status === "uploading") {
                    this.setState(() => ({uploadingImage: true}));
                } else if (e.file.status === "done") {
                    this.setState(() => ({uploadingImage: false}));
                }
                if (e.file.response) {
                    if (e.file.response.imageUrl) {
                        const {imageUrl} = e.file.response;
                        this.handleFieldChange(imageFieldName, imageUrl);
                    }
                }
            } else {
                this.setState(() => ({uploadingImage: true}));
            }
        }
    };

    handleDateChange = (date, fieldName) => {
        this.handleFieldChange(fieldName, date.format(dateFormat));
    };

    handleEditorChange = (html, fieldName) => {
        this.handleFieldChange(fieldName, html);
    }

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        if (name === "title") {
            data.detailsUrl = getSlug(value);
        }
        this.setState({ data, formError: "" });
    };

    handleCheck = (e) => {
        let data = { ...this.state.data };
        data[e.target.name] = e.target.checked;

        if (e.target.name === "includedInNewsletter" && !e.target.checked) {
            data.featureInNewsletterByBusinessType = [];
        }

        this.setState({ data, formError: "" });
    };

    handleCheckGroup = (name, checkedList) => {
        this.props.form.setFieldsValue({name : checkedList ? checkedList : []});
        let data = { ...this.state.data };
        data[name] = checkedList;

        if (name === "businessTypeIds") {
            data.featureInNewsletterByBusinessType =  data.featureInNewsletterByBusinessType.filter((element) => (checkedList.indexOf(element) !== -1));
        }

        if (name === "featureInNewsletterByBusinessType" && checkedList.length > 0) {
            data.includedInNewsletter = true;
        }

        this.setState({ data, formError: "" });
    };

    shortSummaryValidate = (rule, value, callback) => {
        let letterCount = value.trim().split("").length;
        if (value && letterCount > 500) {
            callback('Short description should not more then 500 characters (current character count:'+ letterCount + ")" );
        } else {
            callback();
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const rawData = { ...this.state.data };
                let data = {
                    ...pick(rawData, lookup.valueLookup.aggFields)
                };
                if(data.longDescription === undefined || data.longDescription === "<p><br></p>" || data.longDescription == null) {
                    data.longDescription = "";
                }
                if (this.state.isEditPage) {
                    data.releaseId = this.state.data.id;
                    this.props.updateFeatureRequest({ data, cb: this.renderFeedback });
                } else {
                    this.props.addFeatureRequest({ data, cb: this.renderFeedback });
                }
            }
        });
    };

    renderFeedback = (errorType) => {
        const goToFeatureReleasesPage = () => this.props.history.push("/feature-releases");
        if (!errorType) {
            return Modal.success({
                title: `You have successfully ${
                    this.state.isEditPage ? "edited" : "added"
                } a record`,
                content: `Check the ${
                    this.state.isEditPage ? "updated" : "new"
                } record in feature release list page`,
                okText: "Go to feature releases page",
                maskClosable: false,
                onOk() {
                    goToFeatureReleasesPage();
                }
            });
        } else {
            return Modal.warning({
                title: `The record was not ${
                    this.state.isEditPage ? "edited" : "added"
                } successfully...`,
                content: errorType || "Please check your input or try again later!"
            });
        }
    };

    renderFormBlock = () => {
        const { getFieldDecorator } = this.props.form;
        const {
            title,
            imageUrl,
            cardImageUrl,
            shortSummary,
            longDescription,
            applicableModuleIds,
            businessTypeIds,
            releaseType,
            detailsUrl,
            productionLiveDate,
            showCalloutInDashboard,
            includedInNewsletter,
            featureInNewsletterByBusinessType,
            archived
        } = this.state.data;
        const { businessTypes, applicableModules, releaseTypes, userData } = this.props;
        const { uploadingImage } = this.state;
        const websiteUrl = process.env.REACT_APP_WEBSITE_URL;
        const hasNewsLetterAccess = (userData.privileges && userData.privileges.indexOf("featureReleaseNewsletter") !== -1) || newsLetterPublishAccessList.indexOf(userData.name) !== -1;

        if ((this.state.isEditPage && this.props.error === "releaseNotFound")) {
            return <Alert message="Release not found" type="error" showIcon />;
        } else {
            return (
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Title">
                        {getFieldDecorator('title', {
                            initialValue: title,
                            rules: [{ required: true, message: 'Title is required' }],
                        })(
                            <Input
                                name="title"
                                placeholder="Feature release title"
                                onChange={(e) => this.handleInputChange(e, 'title')}
                            />
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="URL">
                        <Input
                                addonBefore= {websiteUrl + 'updates/'}
                                value={detailsUrl}
                                name="detailsUrl"
                                placeholder="URL slug"
                                onChange={this.handleInputChange}
                            />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Short summary:"  extra="*Summary of this feature, up to 500 characters">
                        {getFieldDecorator('shortSummary', {
                            initialValue: shortSummary,
                            rules: [{required: true, message: 'Short summary is required' }, {validator: this.shortSummaryValidate} ],
                        })(
                            <TextArea
                                name="shortSummary"
                                value={shortSummary || ""}
                                placeholder=""
                                autosize={{ minRows: 2, maxRows: 12 }}
                                onChange={this.handleInputChange}
                            />
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Long description:" extra="*Disclaimer: Please keep the total images added size less than 2 MB">
                        <TextEditor
                            name="longDescription"
                            value={longDescription}
                            handleEditorChange={(html) => this.handleEditorChange(html, "longDescription")}
                        />
                    </FormItem>

                    <FormItem {...formItemLayout} label="Media File" extra="jpg, png, gif, mp4 (Upto 5mb)">
                        {
                            getFieldDecorator(`imageUrl`, {
                                getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                rules: [{
                                    required: true ,
                                    message: `Media File is required.`
                                },{
                                    validator: uploadUtility.singleFile.validator
                                }],
                                initialValue: imageUrl && [{
                                    uid: "-1",
                                    name: `media file: ${title}`,
                                    status: "done",
                                    url: imageUrl
                                }],
                                valuePropName: "fileList",
                            })(
                            <Upload
                                name="files"
                                action={this.getImageUploadUrl(`media-file-${detailsUrl}`, this.timestamp)}
                                withCredentials
                                listType="picture"
                                imagePath={imageUrl}
                                beforeUpload={this.beforeUpload}
                                onRemove={this.mediaRemove}
                                onChange={(e) => this.handleInputChange(e, 'imageUrl')}>
                                <Button disabled={!detailsUrl}>
                                    <Icon type="upload"/> Upload Media
                                </Button>
                            </Upload>
                            )
                        }
                    </FormItem>

                    <FormItem {...formItemLayout} label="Card Image URL" extra="jpg, png, gif, mp4 (Upto 5mb)">
                        {
                            getFieldDecorator(`cardImageUrl`, {
                                getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                rules: [{
                                    required: true ,
                                    message: `Card Image is required.`
                                },{
                                    validator: uploadUtility.singleFile.validator
                                }],
                                initialValue: cardImageUrl && [{
                                    uid: "-1",
                                    name: `card image url: ${title}`,
                                    status: "done",
                                    url: cardImageUrl
                                }],
                                valuePropName: "fileList",
                            })(

                                <Upload
                                    name={"files"}
                                    action={this.getImageUploadUrl(`card-image-${detailsUrl}`, this.timestamp)}
                                    withCredentials
                                    listType="picture"
                                    imagePath={cardImageUrl}
                                    beforeUpload={this.beforeUpload}
                                    onRemove={this.mediaRemove}
                                    onChange={(e) => this.handleInputChange(e, 'cardImageUrl')}>
                                    <Button>
                                        <Icon type="upload"/> Upload Media
                                    </Button>
                                </Upload>
                            )
                        }
                    </FormItem>

                    <FormItem {...formItemLayout} label="Business Type">
                        {getFieldDecorator('businessTypeIds', {
                            initialValue: businessTypeIds,
                            rules: [{ required: true, message: 'Business Type is required' }],
                        })(
                            <Checkbox.Group name="businessTypeIds"  style={{ width: '100%', marginTop: "10px" }} onChange={(checkedValues) => this.handleCheckGroup('businessTypeIds', checkedValues)}>
                                <Row>
                                    {
                                        businessTypes && businessTypes.map((item) => {
                                            return (
                                                <Col key={item.name} span={8}>
                                                    <Checkbox value={item.id}>{item.name}</Checkbox>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </Checkbox.Group>
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Applicable Module">
                        {getFieldDecorator('applicableModuleIds', {
                            initialValue: applicableModuleIds,
                            rules: [{ required: true, message: 'Applicable Module is required' }],
                        })(
                            <Checkbox.Group style={{ width: '100%', marginTop: "10px" }} onChange={(checkedValues) => this.handleCheckGroup('applicableModuleIds', checkedValues)}>
                                <Row>
                                    {
                                        applicableModules && applicableModules.map((item) => {
                                            return (
                                                <Col key={item.name} span={8}>
                                                    <Checkbox value={item.id}>{item.name}</Checkbox>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </Checkbox.Group>
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Type of Release">
                        {getFieldDecorator('releaseType', {
                            initialValue: releaseType,
                            rules: [{ required: true, message: 'Type of Release is required' }],
                        })(
                            <Radio.Group style={{ width: '100%', marginTop: "10px" }} name="releaseType"  onChange={this.handleInputChange}>
                                <Row>
                                    {
                                        releaseTypes && releaseTypes.map((item) => {
                                            return (
                                                <Col key={item.name} span={8}>
                                                    <Radio value={item.id}>{item.name}</Radio>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Production Live Date">
                        <DatePicker
                            placeholder=""
                            allowClear={false}
                            value={moment(productionLiveDate, dateFormat)}
                            format={dateFormat}
                            onChange={(date) => this.handleDateChange(date, "productionLiveDate")} />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Show callout in dashboard">
                        <Checkbox checked={showCalloutInDashboard} name="showCalloutInDashboard" onChange={this.handleCheck}/>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Archive">
                        <Checkbox checked={archived} name="archived" onChange={this.handleCheck}/>
                    </FormItem>
                    {
                        hasNewsLetterAccess && (
                            <FormItem {...formItemLayout} label="Include in monthly newsletter">
                                <Checkbox checked={includedInNewsletter} name="includedInNewsletter" onChange={this.handleCheck}/>
                            </FormItem>
                        )
                    }
                    {
                        hasNewsLetterAccess && (
                            <FormItem {...formItemLayout} label="Feature in newsletter">
                                <Checkbox.Group name="featureInNewsletterByBusinessType" value={featureInNewsletterByBusinessType} style={{width: "100%", marginTop: "10px"}} onChange={(values) => this.handleCheckGroup('featureInNewsletterByBusinessType', values)}>
                                    <Row>
                                        {
                                            businessTypes && businessTypes.map((item) => {
                                                return (
                                                    <Col key={`featureInNewsletterByBusinessType-${item.name}`} span={8}>
                                                        <Checkbox value={item.id} checked={businessTypeIds.indexOf(item.id) === -1} disabled={businessTypeIds.indexOf(item.id) === -1}>{item.name}</Checkbox>
                                                    </Col>
                                                );
                                            })
                                        }
                                    </Row>
                                </Checkbox.Group>
                            </FormItem>
                        )
                    }
                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit" disabled={uploadingImage ? true : false} block>
                            {this.state.isEditPage ? "Save" : "Create"}
                        </Button>
                    </FormItem>
                </Form>
            );
        }
    };

    render() {
        const { isLoading, error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="presses-root">
                <h2>{this.state.isEditPage ? "Edit" : "Add New"} Feature Release Form</h2>
                {(this.state.isEditPage && isLoading) || (this.state.isEditPage && (this.state.data && this.state.data.length < 1)) ? (
                    <Spin />
                ) : (
                    this.renderFormBlock()
                )}
            </div>
        );
    }
}

const UpdateFeatureRelease = Form.create()(FeatureForm);

UpdateFeatureRelease.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    curData: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchFeatureRequest: PropTypes.func.isRequired,
    updateFeatureRequest: PropTypes.func.isRequired,
    addFeatureRequest: PropTypes.func.isRequired,
    fetchBusinessTypeRequest: PropTypes.func.isRequired,
    fetchApplicableModuleRequest: PropTypes.func.isRequired,
    fetchReleaseTypesRequest: PropTypes.func.isRequired,
};

export default UpdateFeatureRelease;


import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { message, Tag, Divider, Popconfirm } from "antd";

import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";
import { getIsPageActionAccessible } from "../../utils";
import { dateFormat } from "../../constants";
import lookup from "./presses.json";

class Presses extends Component {

    pageKey = "pressList";
    tableCols = lookup.columns.map(col => col);
    isReadonly = !getIsPageActionAccessible(this.pageKey, ((this.props.userData && this.props.userData.privileges) || []));

    componentDidMount() {
        this.props.fetchPressesListRequest();
    }

    mapListToDataSource = (data) => {

        return data.map((press, index) => {
            const {id, internalPath, linkTitle, pressLink, releasedDate, type} = press;

            const linkUrl = (internalPath) ? process.env.REACT_APP_WEBSITE_URL + internalPath.replace(/^\/+/g, '')  : pressLink ;
            const linkLabel = (internalPath) ? 'Website Url' : 'Press Link' ;
            return {
                key: index,
                linkTitle: linkTitle,
                typeTag: <Tag color="#2db7f5" style={{textTransform: "uppercase", width: "90%"}}>{type}</Tag>,
                releasedDate: releasedDate,
                actions: (
                    <>
                        {
                            <>
                                <a target={"_blank"} href={`${linkUrl}`}>{`${linkLabel}`}</a>

                            </>
                        }
                        {
                            !this.isReadonly && (
                                <>
                                    <Divider type="vertical" />
                                    <Link to={`/press/${id}`} style={{width: "100%"}}>Edit</Link>
                                    <Divider type="vertical" />
                                    <Popconfirm title={"Are you sure to delete this record?"} onConfirm={() => this.handleDelete(press)} okText="Yes" cancelText="No" placement={"top"}>
                                        <a href="#">Delete</a>
                                    </Popconfirm>
                                </>
                            )
                        }
                    </>
                )
            }
        });
    };

    formatData = (data) => {
        if(this.props.location.pathname.includes('news')) {
            return data.filter(records => records.type === "news").map(record =>{
                return {
                    ...record,
                    key: record.id,
                    releasedDate: moment(record.releasedDate).format(dateFormat),
                    typeTag: <Tag color="#2db7f5" style={{textTransform: "uppercase", width: "90%"}}>{record.type}</Tag>
                };
            });
        } else {
            return data.filter(records => records.type === "announcement").map(record =>{
                return {
                    ...record,
                    key: record.id,
                    releasedDate: moment(record.releasedDate).format(dateFormat),
                    typeTag: <Tag color="#2db7f5" style={{textTransform: "uppercase", width: "90%"}}>{record.type}</Tag>
                };
            });
        }
    };

    handleDelete = (data) => {
        const { id } = data;
        this.props.deletePressRequest({ id, cb: this.renderFeedbackMsg });
    };

    renderFeedbackMsg = (errorMsg) => {
        if (!errorMsg) {
            return message.success(
                "You have successfully deleted this record."
            );
        } else {
            return message.error(
                "Something went wrong... Please try again later."
            );
        }
    };

    render() {
        const { data, isLoading, error, userData, location } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={location} />;
        }

        const isActionAccessible = getIsPageActionAccessible(this.pageKey, userData.privileges);

        return (
            <div className="Press-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    columns={this.tableCols}
                    dataSource={this.mapListToDataSource(this.formatData(data))}
                    loading={isLoading}
                />
            </div>
        );
    }
}

Presses.propTypes = {
    data: PropTypes.array.isRequired, 
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchPressesListRequest: PropTypes.func.isRequired,
    deletePressRequest: PropTypes.func.isRequired
};

export default Presses;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu, Dropdown, Avatar, Icon } from "antd";
import { isEmpty } from "lodash";
import { getCookie } from "../../utils";

class UserWidget extends Component {
    constructor(props) {
        super(props);
        const userId = getCookie("user_id") || "";
        if (userId && props.sessionExists) {
            this.login(userId);
        }
    }

    login = (id) => {
        this.props.loginRequest({ id });
    }

    logout = () => {
        this.props.logoutRequest();
    }

    componentDidUpdate = () => {
        const redirectUrl = localStorage.getItem("return_url");
        if (!isEmpty(this.props.userData) && redirectUrl) {
            localStorage.removeItem("return_url");
            window.location.hash = redirectUrl;
        }
    }

    render() {
        const { userData } = this.props;
        const isLoggedIn = !isEmpty(userData);
        const authLink = process.env.REACT_APP_AUTH_BASE_URL;
        const menu = (
            <Menu>
              <Menu.Item>
                <span className="welcome-msg">{userData.name}</span>
              </Menu.Item>
              <Menu.Item onClick={this.logout}>
                <span className="text-link">Logout</span>
              </Menu.Item>
            </Menu>
        );

        return (
            isLoggedIn ?
                <span className="pull-right">
                    <Dropdown overlay={menu} trigger={['click']}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{userData.name.split(" ").map(c => c.charAt(0).toUpperCase())}</Avatar> <Icon type="down" />
                        </a>
                    </Dropdown>
                </span>
                :
                <></>
        );
    }
}

UserWidget.propTypes = {
    history: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    loginRequest: PropTypes.func.isRequired,
    logoutRequest: PropTypes.func.isRequired,
    sessionExists: PropTypes.bool.isRequired
};

export default UserWidget;

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import { Form, Input, Select, Button, Spin, Modal } from "antd";
import { beAPIResource } from "../../utils/apiHelper";

import { RedirectWrapper } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";

const Option = Select.Option;
const FormItem = Form.Item;

const envOptions = [
    {label: "FreeProd", value: "local"}
];

class DeleteBusinessForm extends Component {
    state = {
        data: {
            businessNumber: null,
            env: null
        },
        formError: "",
        isLoading: false
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { businessNumber, env } = this.state.data;
        if ( isEmpty(businessNumber) || isEmpty(env) ) {
            this.setState({formError: "Fill in all the relevant fields"});
            return;
        };
        this.setState({
            isLoading: true
        }, () => {
            beAPIResource.delete("/support/freeprod/business",  "", {data: this.state.data}).then(() => {
                this.setState({
                    isLoading: false,
                    formError: ""
                }, () => {
                    return Modal.success({
                        title: `The business was deleted successfully`,
                        content: ""
                    });
                });
            }).catch((err) => {
                this.setState({
                    isLoading: false,
                    formError: ""
                }, () => {
                    return Modal.warning({
                        title: `The business could not be deleted`,
                        content: (err.data && err.data.data && err.data.data.message) || ""
                    });
                });
            });
        });
    }

    handleInputChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            this.handleFieldChange(name, value);
        }
    }

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        this.setState({data, formError: ""});
    }

    render() {
        const { businessNumber, env } = this.state.data;
        const { error, userData } = this.props;

        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        if(this.state.isLoading) {
            return <Spin />;
        }

        return (
            <div>
                <h2>Delete Business</h2>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Business Number">
                        <Input
                            name="businessNumber"
                            value={businessNumber}
                            placeholder="Enter Business Number"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Environment">
                        <Select
                            value={env || []}
                            placeholder="Select environment"
                            onChange={(value) =>
                                this.handleFieldChange("env", value)
                            }>
                            {envOptions.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit">
                            Delete
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

const DeleteBusiness = Form.create()(DeleteBusinessForm);

export default DeleteBusiness;

import React, { Component } from "react";
import { pick, includes, isEmpty, findIndex } from "lodash";
import { Form, Input, Select, Spin, Modal, Divider, Button, Empty, Icon, Typography } from "antd";

import { getSlug, getImageName } from "../../../../utils";
import { formItemLayout, formButtonLayout } from "../../../../constants";
import lookup from "./caseStudy.json";
import { MultiResolutionImage, TextEditor, RedirectWrapper } from "../../../../components";

import "./CaseStudyBlock.scss";

const { TextArea } = Input;
const Option = Select.Option;
const FormItem = Form.Item;

const isInsertPage = path => path.includes("/insert/");

class CSForm extends Component {
  state = {
    data: {
      id: 0,
      caseStudyId: this.props.match.params.case_study_id,
      order: 0,
      type: "",
      name: "",
      bannerHeading: "",
      clientSince: "",
      imageSrc: "",
      heading: "",
      blueHeading: "",
      content: "",
      imageClass: "",
      contentBg: "",
      avatarName: "",
      avatarDesignation: "",
      avatarImageSrc: "",
      logo: "",
      logoPosition: "",
      imageTempName: "",
      locations: "",
      foundedIn: "",
      employeeCount: "",
      result: {},
      product: [],
      highlight: []
    },
    formError: "",
    isEditPage: typeof this.props.match.params.id !== "undefined",
    isAppendPage: isInsertPage(this.props.match.path),
    uploading: false,
    highlightIds: [0]
  };

  timestamp = Date.now().toString();
  highlightId = 0;

  componentDidMount() {
    const { location, match, fetchCaseStudyBlockRequest } = this.props;
    if (location.state && location.state.data) {
      // This is to handle Snapshot's dynnamic fields
      const highlight = !isEmpty(location.state.data.highlight) ? location.state.data.highlight : [{}];
      let highlightIds = highlight.map((d, i) => (i));
      this.highlightId = (highlightIds.length - 1);

      this.setState({ data: this.mapDataToForm(location.state.data), highlightIds });
    } else if (match.params.id) {
      fetchCaseStudyBlockRequest({ id: match.params.id });
    } else if (match.params.at_position) {
      fetchCaseStudyBlockRequest({ id: match.params.at_position });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.caseStudyCurBlock.id !== prevProps.caseStudyCurBlock.id && !this.props.match.params.at_position) {
      // This is to handle Snapshot's dynnamic fields
      const highlight = !isEmpty(this.props.caseStudyCurBlock.highlight) ? this.props.caseStudyCurBlock.highlight : [{}];
      let highlightIds = highlight.map((d, i) => (i));
      this.highlightId = (highlightIds.length - 1);

      this.setState({ data: this.mapDataToForm(this.props.caseStudyCurBlock), highlightIds });
    }
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({ isEditPage: !!this.props.match.params.id });
    }
    if (this.props.match.params.at_position !== prevProps.match.params.at_position) {
      this.setState({ isAppendPage: !!this.props.match.params.at_position });
    }
  }

  mapDataToForm = (rawData) => {
    let aggData = pick(rawData, [
      "id",
      "caseStudyId",
      "order",
      "type",
      ...lookup.blockType[rawData.type]
    ]);
    return {
      ...aggData,
      imageTempName: aggData.imageSrc ? getImageName(aggData.imageSrc) : ""
    };
  };

  getImageUploadUrl = (name, pd) => {
    const pdQuery = pd ? `&pd=${pd}` : "";
    return `${
      process.env.REACT_APP_API_BASE_URL
    }support/image-upload?imgname=${name}&timestamp=${
      this.timestamp
    }${pdQuery}&webp=true`;
  };

  getSiblingImagePath = (imagePath, pdSuffix) => {
    let imageUrlParts = imagePath.split(".");
    const ext = imageUrlParts.pop();
    return `${imageUrlParts.join(".")}@${pdSuffix}.${ext}`;
  };

  handleInputChange = (e, fieldName) => {
    if (e.target) {
      const { name, value } = e.target;
      this.handleFieldChange(name, value);
    } else if (e.file) {
      if (e.file.status === "uploading") {
        this.setState(() => ({ uploading: true }));
      } else if (e.file.status === "done") {
        this.setState(() => ({ uploading: false }));
      }
      if (e.file.response) {
        const { imageUrl } = e.file.response;
        this.handleFieldChange(fieldName, imageUrl);
      }
    }
  };

  handleEditorChange = (html, fieldName) => {
    this.handleFieldChange(fieldName, html);
  };

  handleFieldChange = (name, value) => {
    let data = { ...this.state.data };
    data[name] = value;
    this.setState({ data, formError: "" });
  };

  handleCustomHighlightFieldAdd = () => {
		this.setState({highlightIds: this.state.highlightIds.concat(++ this.highlightId)});
	};

	handleCustomHighlightFieldRemove = (removedFieldId) => {
		this.setState({
			highlightIds: this.state.highlightIds.filter(fieldId => fieldId !== removedFieldId),
        });
	};

	handleHighlightFieldChange = (e, fieldId, key) => {
		e.preventDefault();

		const object = {[key]: e.target.value};
		let highlight = this.state.data.highlight || [];

		if (highlight && highlight[fieldId]) {
			let highlightFieldData = highlight[fieldId];

			highlight[fieldId] = {...highlightFieldData,  ...object};
		} else {
			highlight[fieldId] = {...object};
		}

		this.handleFieldChange("highlight", highlight);
	};

  handleSubmit = (e) => {
    e.preventDefault();
    let rawData = { ...this.state.data };
    const {isEditPage, isAppendPage, highlightIds=[] } = this.state;
    const { type, heading, content, caseStudyId, highlight: rawHighlight=[] } = this.state.data;
    const { caseStudyData, addCaseStudyBlockRequest, updateCaseStudyBlockRequest, insertCaseStudyBlockRequest } = this.props;
    const {at_position: insertAtPosition} = this.props.match.params;
    const highlight = rawHighlight.filter((data, index) => (highlightIds.indexOf(index) !== -1)).filter((data) => ((!!data.title || !!data.value)));

    // check required fields
    if (type === "content" && !heading && !content) {
      this.setState({ formError: "Please fill in at lease one field!" });
      return;
    } else if (includes(pick(rawData, lookup.requiredFields[type]), "")) {
      this.setState({ formError: "Please fill in all relevant fields!" });
      return;
    }

	  rawData.highlight = highlight;

    // data submission
    let data = {
      ...pick(rawData, [
        "caseStudyId",
        "order",
        "type",
        ...lookup.blockType[rawData.type]
      ])
    };
	
    if (isEditPage) {
      data.id = this.state.data.id;
      updateCaseStudyBlockRequest({ data, cb: this.renderFeedback });
    } else if (!isAppendPage) {
      data.order = caseStudyData.length ? caseStudyData.slice(-1)[0].order + 1 : 1;
      addCaseStudyBlockRequest({ data, cb: this.renderFeedback, caseStudyId });
    } else {
      insertCaseStudyBlockRequest({ data, cb: this.renderFeedback, caseStudyId, insertAtPosition })
    }
  };

  renderFeedback = (errorType) => {
    const goToCaseStudyPage = () =>
      this.props.history.push(`/case-studies/${this.state.data.caseStudyId}`);
    if (!errorType) {
      return Modal.success({
        title: `You have successfully ${
          this.state.isEditPage ? "edited" : "added"
        } a record`,
        content: `Check the ${
          this.state.isEditPage ? "updated" : "new"
        } record in case study page`,
        okText: "Go to case study page",
        maskClosable: true,
        onOk() {
          goToCaseStudyPage();
        }
      });
    } else {
      return Modal.warning({
        title: `The record was not ${
          this.state.isEditPage ? "edited" : "added"
        } successfully...`,
        content: "Please check your input or try again later..."
      });
    }
  };

  bannerRenderer = () => {
    const {
      name,
      bannerHeading,
      clientSince,
    } = this.state.data;

    return (
      <>
        <FormItem {...formItemLayout} label="Page Header Tag" required>
          <Input
            name="name"
            value={name}
            placeholder="e.g. Birdeye for ABC Company"
            onChange={this.handleInputChange}
          />
        </FormItem>
        <FormItem {...formItemLayout} label="Page Heading" required>
          <TextArea
            name="bannerHeading"
            value={bannerHeading}
            placeholder="Heading of the case study"
            autosize={{ minRows: 2, maxRows: 6 }}
            onChange={this.handleInputChange}
          />
        </FormItem>
        <FormItem {...formItemLayout} label="Client Since" required>
          <Input
            name="clientSince"
            value={clientSince}
            placeholder="e.g. Client since: September 2014"
            onChange={this.handleInputChange}
          />
        </FormItem>
      </>
    );
  };

  contentRenderer = () => {
    const { heading, content, blueHeading } = this.state.data;

    return (
      <>
        <FormItem {...formItemLayout} label="Headline">
          <Input
              name="blueHeading"
              value={blueHeading}
              placeholder="Headline (optional)"
              onChange={this.handleInputChange}
          />
        </FormItem>
        <FormItem {...formItemLayout} label="Block Heading">
          <TextArea
            name="heading"
            value={heading}
            placeholder="Heading of the content block"
            autosize={{ minRows: 2, maxRows: 6 }}
            onChange={this.handleInputChange}
          />
        </FormItem>
        <FormItem {...formItemLayout} label="Block Content">
          <TextEditor
            name="content"
            value={content}
            handleEditorChange={(html) =>
              this.handleEditorChange(html, "content")
            }
          />
        </FormItem>
      </>
    );
  };

  imageRenderer = () => {
    const { imageSrc, imageClass, imageTempName } = this.state.data;

    return (
      <>
        <FormItem {...formItemLayout} label="Image Name" required>
          <Input
            name="imageTempName"
            value={imageTempName}
            placeholder="Give SEO friendly name to the image"
            onChange={this.handleInputChange}
          />
        </FormItem>
        {imageTempName && (
          <MultiResolutionImage
            required
            formItemLayout={formItemLayout}
            handleInputChange={this.handleInputChange}
            imageFieldName="imageSrc"
            uploadName={getSlug(imageTempName)}
            imagePath={imageSrc}
            timestamp={this.timestamp}
          />
        )}
        <FormItem {...formItemLayout} label="Image Type" required>
          <Select
            value={imageClass || []}
            placeholder="Select Image Type"
            onChange={(value) => this.handleFieldChange("imageClass", value)}>
            {lookup.imageClasses.map((type) => (
              <Option key={type.value} value={type.value}>
                {type.label}
              </Option>
            ))}
          </Select>
        </FormItem>
      </>
    );
  };

  quoteRenderer = () => {
    const {
      content,
      avatarImageSrc,
      avatarName,
      avatarDesignation,
    } = this.state.data;

    return (
      <>
        <FormItem {...formItemLayout} label="Quote Content" required>
          <TextEditor
            name="content"
            value={content}
            handleEditorChange={(html) =>
              this.handleEditorChange(html, "content")
            }
          />
        </FormItem>
        <Divider>Contact person of the client company</Divider>
        <FormItem {...formItemLayout} label="Full Name" required>
          <Input
            name="avatarName"
            value={avatarName}
            placeholder="Name of the contact person of the client company"
            onChange={this.handleInputChange}
          />
        </FormItem>
        <FormItem {...formItemLayout} label="Designation" required>
          <Input
            name="avatarDesignation"
            value={avatarDesignation}
            placeholder="Designation of the contact person of the client company"
            onChange={this.handleInputChange}
          />
        </FormItem>
        {avatarName && (
          <MultiResolutionImage
            formItemLayout={formItemLayout}
            handleInputChange={this.handleInputChange}
            imageFieldName="avatarImageSrc"
            uploadName={getSlug(avatarName)}
            imagePath={avatarImageSrc}
            timestamp={this.timestamp}
          />
        )}
      </>
    );
  };

  heroRenderer = () => {
    const { name, bannerHeading, content, imageSrc } = this.state.data;

    return (
      <>
        <FormItem {...formItemLayout} label="Header tag" required>
          <Input
            name="name"
            value={name}
            placeholder="Page header tag"
            onChange={this.handleInputChange}
          />  
        </FormItem>
        <FormItem {...formItemLayout} label="Heading" required>
          <TextArea
              name="bannerHeading"
              value={bannerHeading}
              placeholder="Heading of the case study"
              autosize={{ minRows: 2, maxRows: 6 }}
              onChange={this.handleInputChange}
            />  
        </FormItem>
        <FormItem {...formItemLayout} label="Description">
          <TextArea
              name="content"
              value={content}
              placeholder="Description of the case study"
              autosize={{ minRows: 2, maxRows: 6 }}
              onChange={this.handleInputChange}
            />  
        </FormItem>
        {
          name && (
            <MultiResolutionImage
              formItemLayout={formItemLayout}
              handleInputChange={this.handleInputChange}
              imageFieldName="imageSrc"
              uploadName={getSlug(name)}
              imagePath={imageSrc}
              timestamp={this.timestamp}
              required
            />
          )
        }
      </>
    );
  };

  snapshotRenderer = () => {
	const { logo, content, product, highlight =[] } = this.state.data;
	const { highlightIds = [] } = this.state;

    return (
      <>
        {
          <div>
            {
              highlightIds && highlightIds.map((customFieldId, index) => {
                const {title = "", value = ""} = highlight[customFieldId] || {};

                return (
                  <FormItem {...(index === 0 ? formItemLayout : formButtonLayout)} label={index === 0 ? "Highlight" : ""} key={`custom-parameter-${customFieldId}`}>
                    {
                      <Input className={"inputCustomFieldKey"} placeholder={"Heading"} value={title} onChange={(e) => (this.handleHighlightFieldChange(e, customFieldId, "title"))}/>
                    }
                    {
                      <Input className={"inputCustomFieldValue"} placeholder={"Value"} value={value} onChange={(e) => (this.handleHighlightFieldChange(e, customFieldId, "value"))}/>
                    }
                    {
                      highlightIds.length > 1 ? (<Icon className={"btnRemoveCustomField"} type="minus-circle-o" onClick={() => this.handleCustomHighlightFieldRemove(customFieldId)}/>) : null
                    }
                  </FormItem>
                )
              })
            }
          </div>
        }
        <FormItem {...formButtonLayout} style={{marginBottom: 0}}>
          <p>Frequently used highlights:
            <Typography.Text code>Birdeye Customer Since</Typography.Text> <Typography.Text code>Founded In</Typography.Text> <Typography.Text code>Locations</Typography.Text> <Typography.Text code>Employees</Typography.Text> 
          </p>
        </FormItem>
        {
          (highlightIds.length < 3) && (
            <FormItem {...formButtonLayout}>
              <Button type="dashed" onClick={this.handleCustomHighlightFieldAdd} style={{ width: '44%' }}>
                <Icon type="plus" /> Add Highlight
              </Button>
            </FormItem>
          )
        }
        <FormItem {...formItemLayout} label="Company description">
          <TextEditor
              name="content"
              value={content}
              placeholder="Description of the company"
              handleEditorChange={(html) =>
                  this.handleEditorChange(html, "content")
              }
          />
        </FormItem>
        <FormItem {...formItemLayout} label="Product">
          <Select placeholder={"Select a suitable result template"} mode={"multiple"} value={product} onChange={(value) => this.handleFieldChange("product", value)}>
            {
              lookup.productOptions.map((product, index) => (
                <Option key={index} value={product}>{product}</Option>
              ))
            }
          </Select>
        </FormItem>
        <MultiResolutionImage
          formItemLayout={formItemLayout}
          handleInputChange={this.handleInputChange}
          imageFieldName="logo"
          uploadName="client-logo"
          imagePath={logo}
          timestamp={this.timestamp}
          label={<span>Logo</span>}
        />
      </>
    );
  };

  resultRenderer = () => {
    const { result } = this.state.data;

    return (
      <>
        <FormItem {...formItemLayout} label={"Templates"}>
          <Select placeholder={"Select a suitable result template"} onChange={(value) => this.handleFieldChange("result", lookup.resultOptions.filter(r => r.title === value)[0])} value={result.title}>
            {
              lookup.resultOptions.map((template, index) => (
                <Option key={index} value={template.title}>{template.title}</Option>
              ))
            }
          </Select> 
        </FormItem>
        <FormItem {...formItemLayout} label={"Preview"}>
          <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: "4px", backgroundColor: "#eef8fe" }}>
              {
                isEmpty(result) 
                  ? <Empty description={<><strong>No preview is available.</strong><br/><small>Select any of the template to get preview.</small></>} /> 
                  : (
                    <div style={{ textAlign: "center" }}>
                      <h2 style={{ margin: 0 }}>{result.title}</h2>
                      <>
                        {
                            result.rates && result.rates.map(({heading, desc}, index) => {
                                return (<li key={index} style={{ margin: 0 }}><strong>{heading}</strong>  <small>{desc}</small></li>)
                            })
                        }
                      </>
                      <h6 style={{ margin: 0 }}>{result.information}</h6>
                    </div>
                  )
              }
          </div>
        </FormItem>
      </>
    );
  }; 

  render() {
    const { isLoading, error, userData } = this.props;
    const unauthorized = error === "unauthorized" || !userData.name;
    const { type } = this.state.data;
    const { isEditPage, isAppendPage, uploading } = this.state;
    const formRenderer = type ? this[`${type}Renderer`] : () => null;

    if (unauthorized) {
      return <RedirectWrapper location={this.props.location} />;
    }

    return (
      <div className="case-study-block-root csBlock">
        <h2>
          {isEditPage ? "Edit" : "Add New"} Case Study Content Block
        </h2>
        {isEditPage && isLoading ? (
          <Spin />
        ) : (
          <Form onSubmit={this.handleSubmit}>
            <FormItem {...formItemLayout} label="Block Type" required>
            {
              isEditPage ?
              <span>{this.state.data.type}</span>
              :
              <Select
                value={this.state.data.type || []}
                placeholder="Select content block type"
                onChange={(value) => this.handleFieldChange("type", value)}>
                {Object.keys(lookup.blockType).map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            }
            </FormItem>
            {formRenderer()}
            {this.state.formError && (
              <FormItem {...formButtonLayout}>
                <span className="text-error">{this.state.formError}</span>
              </FormItem>
            )}
            <FormItem {...formButtonLayout}>
              <Button
                type="primary"
                htmlType="submit"
                block
                disabled={uploading ? true : false}>
                {this.state.isEditPage ? "Save" : "Create"}
              </Button>
            </FormItem>
          </Form>
        )}
      </div>
    );
  }
}

const UpdateCaseStudyBlock = Form.create()(CSForm);

export default UpdateCaseStudyBlock;

import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
    return (
        <div>
            <h1>Oops!</h1>
            <h3>We can't seem to find the page you're looking for.</h3>
            <p><Link to='/'>Go back to home page</Link></p>
        </div>
    );
};

export default PageNotFound;

import { createActionTypes } from "../../utils";

export const UpsellActions = createActionTypes("upsell", [
    "FETCH_ALL_PRODUCT_MESSAGE_REQUEST",
    "FETCH_ALL_PRODUCT_MESSAGE_REQUEST_SUCCESS",
    "FETCH_ALL_PRODUCT_MESSAGE_REQUEST_ERROR",

    "UPDATE_PRODUCT_MESSAGE_REQUEST",
    "UPDATE_PRODUCT_MESSAGE_SUCCESS",
    "UPDATE_PRODUCT_MESSAGE_ERROR",

    "DELETE_PRODUCT_MESSAGE_REQUEST",
    "DELETE_PRODUCT_MESSAGE_SUCCESS",
    "DELETE_PRODUCT_MESSAGE_ERROR",

    "UPDATE_PRODUCT_MESSAGE_INDUSTRY_IMAGE_REQUEST",
    "UPDATE_PRODUCT_MESSAGE_INDUSTRY_IMAGE_SUCCESS",
    "UPDATE_PRODUCT_MESSAGE_INDUSTRY_IMAGE_ERROR",
]);

export default UpsellActions;

export const fetchAllProductMessageRequest = (data) => {
    return {
        type: UpsellActions.FETCH_ALL_PRODUCT_MESSAGE_REQUEST,
        payload: data
    };
};

export const fetchAllProductMessageSuccess = (data, isCache) => {
    return {
        type: UpsellActions.FETCH_ALL_PRODUCT_MESSAGE_REQUEST_SUCCESS,
        payload: data,
        isCache
    };
};

export const fetchAllProductMessageError = (data, isCache) => {
    return {
        type: UpsellActions.FETCH_ALL_PRODUCT_MESSAGE_REQUEST_ERROR,
        payload: data,
        isCache
    };
};

export const updateProductMessageRequest = (data, cb) => {
    return {
        type: UpsellActions.UPDATE_PRODUCT_MESSAGE_REQUEST,
        payload: data,
        callback: cb
    };
}

export const updateProductMessageSuccess = (data) => {
    return {
        type: UpsellActions.UPDATE_PRODUCT_MESSAGE_SUCCESS,
        payload: data
    };
}

export const updateProductMessageError = (data) => {
    return {
        type: UpsellActions.UPDATE_PRODUCT_MESSAGE_ERROR,
        payload: data
    };
}

export const updateProductMessageIndustryImageRequest = (data, cb) => {
    return {
        type: UpsellActions.UPDATE_PRODUCT_MESSAGE_INDUSTRY_IMAGE_REQUEST,
        payload: data,
        callback: cb
    };
}

export const updateProductMessageIndustryImageSuccess = (data) => {
    return {
        type: UpsellActions.UPDATE_PRODUCT_MESSAGE_INDUSTRY_IMAGE_SUCCESS,
        payload: data
    };
}

export const updateProductMessageIndustryImageError = (data) => {
    return {
        type: UpsellActions.UPDATE_PRODUCT_MESSAGE_INDUSTRY_IMAGE_ERROR,
        payload: data
    };
}

export const deleteProductMessageRequest = (data, cb) => {
    return {
        type: UpsellActions.DELETE_PRODUCT_MESSAGE_REQUEST,
        payload: data,
        callback: cb
    };
}

export const deleteProductMessageSuccess = (data) => {
    return {
        type: UpsellActions.DELETE_PRODUCT_MESSAGE_SUCCESS,
        payload: data
    };
}

export const deleteProductMessageError = (data) => {
    return {
        type: UpsellActions.DELETE_PRODUCT_MESSAGE_ERROR,
        payload: data
    };
}

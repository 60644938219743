import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Button, Spin, Select, Divider, Descriptions } from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";

import "./TwilioLookup.scss";

const countryCodeOptions = [
    {label: "US +1", value: "US"},
    {label: "CA +1", value: "CA"},
    {label: "PR +1", value: "PR"},
    {label: "GB +44", value: "GB"},
    {label: "AU +61", value: "AU"},
    {label: "MX +52", value: "MX"}
];

class TwilioLookupForm extends Component {
    constructor(props){
        super(props);

        this.state= {
            contactNumberPayload : {
                rawPhoneNumber : null,
                countryCode : null
            },
            receivedRawPhoneNumber : null,
            receivedCarrierType : null,
            receivedFailureReason : null,
            receivedCountryCode : null,
            receivedCarrierName : null
        }
        this.countryCodeDisplay = "+1";
    }

    handleOnSubmit = (e) => {
        e.preventDefault();
        const {contactNumberPayload} = this.state;
        let finalPhoneNumber = "";
        switch(contactNumberPayload.countryCode) {
            case "US":
            case "CA":
            case "PR":
                finalPhoneNumber ="+1" + this.state.contactNumberPayload.rawPhoneNumber;
                break;
            case "GB":   
                finalPhoneNumber ="+44" + this.state.contactNumberPayload.rawPhoneNumber;
                break; 
            case "AU":
                finalPhoneNumber ="+61" + this.state.contactNumberPayload.rawPhoneNumber;
                break; 
            case "MX":                
                finalPhoneNumber ="+52" + this.state.contactNumberPayload.rawPhoneNumber;
                break; 
            default :
                finalPhoneNumber ="+1" + this.state.contactNumberPayload.rawPhoneNumber;

        }
        const sendNumberPayload = {
            rawPhoneNumber : finalPhoneNumber,
            countryCode : contactNumberPayload.countryCode
        }

        this.props.form.validateFieldsAndScroll((error) => {
            if (error) {
                return;
            }
            this.props.phoneNumberUploadRequest({data: sendNumberPayload, cb: this.handleSubmitResponse});
        });
    }

    handleSubmitResponse = (response) => {
        let failureReason = response.data.failureReason ? response.data.failureReason.replace("%2B", "+") : "";
        this.setState({
            receivedRawPhoneNumber : response.data.rawPhoneNumber,
            receivedCarrierType : response.data.carrierType,
            receivedFailureReason : failureReason,
            receivedCountryCode : response.data.countryCode,
            receivedCarrierName : response.data.carrierName
        });
    }

    handleInputChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            this.handleFieldChange(name, value);
        }
    }

    handleFieldChange = (name, value) => {
        let contactNumberPayload = { ...this.state.contactNumberPayload };
        contactNumberPayload[name] = value;
        this.setState({contactNumberPayload});
        if (name === "countryCode") {
            switch(value) {
                case "US":
            case "CA":
            case "PR":
                this.countryCodeDisplay = "+1";
                break;
            case "GB":   
                this.countryCodeDisplay = "+44";
                break; 
            case "AU":
                this.countryCodeDisplay = "+61";
                break; 
            case "MX":                
                this.countryCodeDisplay = "+52";
                break; 
            default :
                this.countryCodeDisplay = "+1";
            }
        }
    }
    

    render() {
        const unauthorized = !this.props.userData.name;
        const { contactNumberPayload, receivedCountryCode, receivedRawPhoneNumber, receivedCarrierType, receivedFailureReason, receivedCarrierName } = this.state;

        if (unauthorized) {
            return <Redirect to="/" />;
        } 

        const defaultOption = (
            <div className={"flagContainer"}>
                <div className={`flag US`}></div>
                <span>{"US +1"}</span> 
            </div>
        );
        const selectBefore = (
            <Select
                name="countryCode"
                value={ contactNumberPayload.countryCode ? contactNumberPayload.countryCode : defaultOption }
                style={{ width: 110 }}
                onChange={(value) =>
                    this.handleFieldChange("countryCode", value)
                }>
                {countryCodeOptions.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                        <div className={"flagContainer"}>
                            <div className={`flag ${option.value}`}></div>
                            <span>{option.label}</span>    
                        </div>
                    </Select.Option>
                ))}
            </Select>
          );

        return (
            <div class="twilioLookup">
                <h2>Phone Lookup</h2>
                <Spin spinning={this.props.isLoading}>
                    <Form onSubmit={this.handleOnSubmit}>                   
                        <Form.Item {...formItemLayout} label={"Contact number"} extra={<p>Please enter contact number without country code e.g. 4082215866</p>}>
                            <Input name="rawPhoneNumber" autoComplete="off" addonBefore={selectBefore} maxLength={"10"} style={{ width: 280 }} placeholder={"Enter contact number"} onChange={this.handleInputChange}/>
                        </Form.Item>
                        <Form.Item {...formButtonLayout}>
                            <Button type="primary" htmlType="submit">
                                Search
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
                {receivedRawPhoneNumber || receivedCountryCode || receivedCarrierType || receivedCarrierName || receivedFailureReason ?
                    <div>
                        <Divider orientation="left">Search results</Divider>
                            <Descriptions
                                bordered
                                column={1}
                            >
                                <Descriptions.Item label="Phone Number">{receivedRawPhoneNumber}</Descriptions.Item>
                                <Descriptions.Item label="Country Code">{receivedCountryCode}</Descriptions.Item>
                                <Descriptions.Item label="Carrier Name">{receivedCarrierName}</Descriptions.Item>
                                <Descriptions.Item label="Carrier Type">{receivedCarrierType}</Descriptions.Item>
                                <Descriptions.Item label="Failure Reason">{receivedFailureReason}</Descriptions.Item>
                            </Descriptions>
                    </div>
                : null}
            </div>
        );    
    }
}

const TwilioLookup = Form.create()(TwilioLookupForm);
export default TwilioLookup;
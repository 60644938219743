import { connect } from "react-redux";

import {
    reqFeaturePagesList,
    successFeaturePagesList,
    errorFeaturePagesList,
    
    reqFeaturePageById,
    successFeaturePageById,
    errorFeaturePageById,
    
    reqAddFeaturePage,
    successAddFeaturePage,
    errorAddFeaturePage,
    
    reqUpdateFeaturePage,
    successUpdateFeaturePage,
    errorUpdateFeaturePage,
    
    reqDeleteFeaturePage,
    successDeleteFeaturePage,
    errorDeleteFeaturePage,
    
} from "./actions";

import FeaturedPageManagement from "./FeaturedPageManagement";
import FeaturePage from "./FeaturePage";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.FeaturePages
});

export const FeaturedPageManagementPage = connect(
    mapStateToProps,
    {
        reqFeaturePagesList,
        successFeaturePagesList,
        errorFeaturePagesList,
        
        reqDeleteFeaturePage,
        successDeleteFeaturePage,
        errorDeleteFeaturePage
    }
)(FeaturedPageManagement);

export const FeaturePagePage = connect(
    mapStateToProps,
    {
        reqFeaturePagesList,
        successFeaturePagesList,
        errorFeaturePagesList,
        
        reqFeaturePageById,
        successFeaturePageById,
        errorFeaturePageById,
        
        reqUpdateFeaturePage,
        successUpdateFeaturePage,
        errorUpdateFeaturePage,
        
        reqAddFeaturePage,
        successAddFeaturePage,
        errorAddFeaturePage
    }
)(FeaturePage);

import { beAPIResource } from "../../utils/apiHelper";

const productEmailApi = {
    getByMonthData: (data) => beAPIResource.post("support/product-email/", data),
    getByTemplateData: (data) => beAPIResource.post("support/product-email/email", data),
    getTemplateStats: (data) => beAPIResource.post("support/product-email/template", data),
    getEmailVersions: (data) => beAPIResource.post("support/product-email/email-versions", data)
};

export default productEmailApi;

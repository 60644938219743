import React from "react";
import PropTypes from "prop-types";
import { Empty } from "antd";

const SignatureTemplate = (props) =>  {
    const { template, params } = props;
    const name = params.name ? params.name.trim().split(' ').join('_') : '';
    const utm = {
        utm_source: 'widgets',
        utm_medium: 'free_tools',
        utm_content: `${template}_signature${name ? '_'+name : ''}`
    };
    
    const utmQuery = Object.keys(utm).map(k => `${k}=${utm[k]}`).join('&');

    const renderNoPreview = (description="Please wait...") => {
        return (
            <Empty description={description} />
        );
    }

    const styleObj = {
        bottomIconAnchor: { display: "block" },
        bottomIconImage: { display: "block", width: "24px", height: "24px" },
        blueLinkAnchor: { fontSize: "14px", fontFamily: "Roboto,RobotoDraft,Helvetica,Arial,sans-serif", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: 1.43, color: "#1976d2", margin: 0, textDecoration: "none !important" },
        blankSpacer: { padding: 0, margin: 0, "mso-line-height-rule": "exactly" },
        profileImage: { height: "100%", borderRadius: "10px" },
    };

    const renderTemplate = (params) => {

        return (
            <table cellPadding="0" cellSpacing="0" border="0" style={{ margin: 0 }}>
                <tbody>
                    <tr>
                        <td valign="middle" height="40" style={{ ...styleObj.blankSpacer, fontSize: "40px", lineHeight: "40px" }}>
                            &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td align="left" valign="middle">
                            <a href={`https://${params.website}/?${utmQuery}&utm_campaign=website&utm_term=birdeye_icon`} target="_blank" rel="noopener noreferrer" style={{ margin: 0, display: "block" }}><img height="27" width="110" style={{ width: "110px", height: "27px", display: "block" }} src="https://d3cnqzq0ivprch.cloudfront.net/support-api/production/email-signature/asset/full-birdeye-logo-1602590586606.png" alt="birdeye-icon" /></a>
                        </td>
                    </tr>
                    <tr>
                        <td valign="middle" height="20" style={{ ...styleObj.blankSpacer, fontSize: "20px", lineHeight: "20px" }}>
                        &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table cellPadding="0" cellSpacing="0" border="0" style={{ margin: 0 }}>
                                <tr>
                                    {
                                        params.profileImage && (<>
                                            <td valign="middle" height="100px">
                                                <img style={styleObj.profileImage} src={params.profileImage} />
                                            </td>
                                            <td width="15" style={{ width: "15px" }}>&nbsp;</td>
                                        </>)
                                    }
                                    <td valign="middle">
                                        <table cellPadding="0" cellSpacing="0" border="0" style={{ margin: 0 }}>
                                            <tbody>
                                                {
                                                    params.name && (
                                                        <>
                                                            <tr>
                                                                <td align="left" valign="middle">
                                                                    <p style={{ fontSize: "18px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", lineHeight: 1.33, color: "#212121", margin: 0, padding: 0, fontFamily: "Roboto,RobotoDraft,Helvetica,Arial,sans-serif" }}>{params.name}</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="middle" height="2" style={{ ...styleObj.blankSpacer, fontSize: "2px", lineHeight: "2px" }}>
                                                                    &nbsp;
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                                {
                                                    params.designation && (
                                                        <>
                                                            <tr>
                                                                <td align="left" valign="middle">
                                                                    <p style={{ fontSize: "14px", fontFamily: 'Roboto,RobotoDraft,Helvetica,Arial,sans-serif', fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: 1.43, color: "#555555", margin: 0, padding: 0 }}>{params.designation}</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="middle" height="2" style={{ ...styleObj.blankSpacer, fontSize: "2px", lineHeight: "2px" }}>
                                                                    &nbsp;
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                                {
                                                    (params.isCalendar && params.calendarLabel && params.calendarUrl) && (
                                                        <>
                                                            <tr>
                                                                <td align="left" valign="middle">
                                                                    <a href={`${params.calendarUrl}?${utmQuery}&utm_campaign=website&utm_term=${params.calendarLabel.toLowerCase().replace(/\s/gi, '_')}`} target="_blank" rel="noopener noreferrer" style={{ ...styleObj.blueLinkAnchor }}><span style={{ textDecoration: "none" }}>{params.calendarLabel}</span></a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="middle" height="2" style={{ ...styleObj.blankSpacer, fontSize: "2px", lineHeight: "2px" }}>
                                                                    &nbsp;
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                                {
                                                    params.oPhone && (
                                                        <>
                                                            <tr>
                                                                <td align="left" valign="middle">
                                                                    <a href={`tel:${params.oPhone.split(" ext. ").join(",")}`} style={{ ...styleObj.blueLinkAnchor }}><span style={{ textDecoration: "none" }}>{params.oPhone}</span></a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="middle" height="2" style={{ ...styleObj.blankSpacer, fontSize: "2px", lineHeight: "2px" }}>
                                                                    &nbsp;
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                                {
                                                    params.cPhone && (
                                                        <>
                                                            <tr>
                                                                <td align="left" valign="middle">
                                                                    <a href={`tel:${params.cPhone}`} style={{ ...styleObj.blueLinkAnchor }}><span style={{ textDecoration: "none" }}>{params.cPhone}</span></a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="middle" height="2" style={{ ...styleObj.blankSpacer, fontSize: "2px", lineHeight: "2px" }}>
                                                                    &nbsp;
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                                <tr>
                                                    <td align="left" valign="middle">
                                                        <a href={`https://${params.website}/?${utmQuery}&utm_campaign=website&utm_term=birdeye_dot_com`} target="_blank" rel="noopener noreferrer" style={{ ...styleObj.blueLinkAnchor }}><span style={{ textDecoration: "none" }}>{params.website}</span></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    
                    <tr>
                        <td valign="middle" height="15" style={{ ...styleObj.blankSpacer, fontSize: "15px", lineHeight: "15px" }}>
                            &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td align="left" valign="middle">
                            <table cellPadding="0" cellSpacing="0" border="0" style={{ margin: 0, padding: 0 }}>
                                <tr>
                                    <td>
                                        <a href={`https://birdeye.com/blog/?${utmQuery}&utm_campaign=Content&utm_term=birdeye_small_icon`} target="_blank" rel="noopener noreferrer" style={ styleObj.bottomIconAnchor }><img height="24" width="24" alt="" style={{ ...styleObj.bottomIconImage }} src="https://d3cnqzq0ivprch.cloudfront.net/support-api/production/email-signature/asset/v2/birdeye-1650350748459.png"/></a>
                                    </td>
                                    <td width="5" style={{ width: "5px" }}>&nbsp;</td>
                                    <td>
                                        <a href="https://www.linkedin.com/company/birdeye" target="_blank" rel="noopener noreferrer" style={ styleObj.bottomIconAnchor }><img height="24" width="24" alt="" style={{ ...styleObj.bottomIconImage }} src="https://d3cnqzq0ivprch.cloudfront.net/support-api/production/email-signature/asset/v2/linkedin-1650350795721.png"/></a>
                                    </td>
                                    <td width="5" style={{ width: "5px" }}>&nbsp;</td>
                                    <td>
                                        <a href="https://twitter.com/birdeye_" target="_blank" rel="noopener noreferrer" style={ styleObj.bottomIconAnchor }><img height="24" width="24" alt="" style={{ ...styleObj.bottomIconImage }} src="https://d3cnqzq0ivprch.cloudfront.net/support-api/production/email-signature/asset/v2/twitter-1650350826880.png"/></a>
                                    </td>
                                    <td width="5" style={{ width: "5px" }}>&nbsp;</td>
                                    <td>
                                        <a href="https://www.g2crowd.com/products/birdeye/reviews" target="_blank" rel="noopener noreferrer" style={ styleObj.bottomIconAnchor }><img height="24" width="24" alt="" style={{ ...styleObj.bottomIconImage }} src="https://d3cnqzq0ivprch.cloudfront.net/support-api/production/email-signature/asset/v2/g2crowd-1650350865075.png"/></a>
                                    </td>
                                    <td width="5" style={{ width: "5px" }}>&nbsp;</td>
                                    <td>
                                        <a href="https://play.google.com/store/apps/details?id=com.birdeye.blip&hl=en" target="_blank" rel="noopener noreferrer" style={ styleObj.bottomIconAnchor }><img height="24" width="81" alt="" style={{ ...styleObj.bottomIconImage, width: "81px", height: "24px" }} src="https://d3cnqzq0ivprch.cloudfront.net/support-api/production/email-signature/asset/v2/google-play-1650350894928.png"/></a>
                                    </td>
                                    <td width="5" style={{ width: "5px" }}>&nbsp;</td>
                                    <td>
                                        <a href="https://itunes.apple.com/us/app/birdeye-reviews/id1000915473?mt=8" target="_blank" rel="noopener noreferrer" style={ styleObj.bottomIconAnchor }><img height="24" width="72" alt="" style={{ ...styleObj.bottomIconImage, width: "72px", height: "24px" }} src="https://d3cnqzq0ivprch.cloudfront.net/support-api/production/email-signature/asset/v2/app-store-1650350924033.png"/></a>
                                    </td>
                                    { params.country !== "uk" &&
                                    <>
                                    <td width="5" style={{ width: "5px" }}>&nbsp;</td>
                                    <td>
                                        <a href={`https://${params.website}/customer-advocacy/?${utmQuery}&utm_campaign=website&utm_term=customer_advocacy`} target="_blank" rel="noopener noreferrer" style={ styleObj.bottomIconAnchor }><img height="24" width="72" alt="" style={{ ...styleObj.bottomIconImage, width: "72px", height: "24px" }} src="https://d3cnqzq0ivprch.cloudfront.net/support-api/production/email-signature/asset/v2/birdeye-advocacy-1679663005712.png"/></a>
                                    </td>
                                    </>
                                    }
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {
                        params.banner && params.banner.image && (
                            <>
                                <tr>
                                    <td valign="middle" height="30" style={{ ...styleObj.blankSpacer, fontSize: "30px", lineHeight: "30px" }}>
                                        &nbsp;
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left" valign="middle">
                                        <a href={`${params.banner.url}?${utmQuery}&utm_campaign=${params.banner.url.indexOf("/blog") !== -1? 'content': 'website'}&utm_term=${params.banner.image.split('/').pop().split('.')[0]}${params.banner.sfdc_campaign ? `&utm_sfcamp=${params.banner.sfdc_campaign}` : '' }`} target="_blank" rel="noopener noreferrer" style={{ display: "block", maxWidth: "600px" }}>
                                            <img src={`${params.banner.image}`} alt="" width="600" border="0" style={{ display: "block", maxWidth: "100%" }} />
                                        </a>
                                    </td>
                                </tr>
                            </>
                        )
                    }
                    <tr>
                        <td valign="middle" height="40" style={{ ...styleObj.blankSpacer, fontSize: "40px", lineHeight: "40px" }}>
                            &nbsp;
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    switch (template) {
        case "default":
            delete params.banner;
        case "marketing":
        case "sales":
        case "support":
        case "channel_reseller":
            return renderTemplate(params);
        default:
            return renderNoPreview();
    }
}

SignatureTemplate.propTypes = {
    template: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired
};

SignatureTemplate.defaultProps = {
    template: "default",
    params: {}
};

export default SignatureTemplate;
import { connect } from "react-redux";

import {
    fetchEffectsListRequest,
    fetchEffectRequest,
    updateEffectRequest,
    fetchCategoriesListRequest,
    fetchCategoryRequest,
    updateCategoryRequest
} from "./actions";

import Effects from "./Effects";
import UpdateEffect from "./UpdateEffect";
import EffectsCategories from "./EffectsCategories";
import UpdateEffectsCategories from "./UpdateEffectsCategories";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Effects
});

export const EffectsPage = connect(
    mapStateToProps,
    { fetchEffectsListRequest }
)(Effects);

export const UpdateEffectPage = connect(
    mapStateToProps,
    { fetchEffectRequest, updateEffectRequest }
)(UpdateEffect);

export const EffectsCategoriesPage = connect(
    mapStateToProps,
    { fetchCategoriesListRequest }
)(EffectsCategories);

export const UpdateEffectsCategoriesPage = connect(
    mapStateToProps,
    { fetchCategoryRequest, updateCategoryRequest }
)(UpdateEffectsCategories);

import React, { Component } from "react";
import {Redirect} from "react-router-dom";
import {Spin, Table, Tag, Select} from "antd";

class Overview extends Component  {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        this.props.reqOverviewPageData();
    }

    getValueForFilter = (records, index) => {
        let filters = [];

        for (const record of records) {
            filters.push({text: record[index], value: record[index]});
        }

        return filters;
    };

    render() {

        const { error, userData, isLoading, pageData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        if(isLoading) {
            return <Spin />;
        }

        const {overview: thisPageData} = pageData;
        const isTableLoading = thisPageData.isTableLoading;
        const records = thisPageData.tableContent || [];
        const formatter = new Intl.NumberFormat('en-US');

        const columns = [
            {
                title: 'Industry',
                dataIndex: 'categoryName',
                key: 'categoryName',
                width: '25%',
                filters: this.getValueForFilter(records, 'categoryName'),
                onFilter: (value, record) => record.categoryName.indexOf(value) === 0,
                render: (value, row) => (<a href={`/#/free-prod-dashboard/detail/${row.categoryId}`}>{value}</a>)
            },
            {
                title: 'Business Count',
                dataIndex: 'totalBusinesses',
                key: 'totalBusinesses',
                width: '15%',
                sorter: (a, b) => a.totalBusinesses - b.totalBusinesses,
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                render: (value, row) => (formatter.format(value))
            }, {
                title: 'Total Email Count',
                dataIndex: 'totalEmails',
                key: 'totalEmails',
                width: '15%',
                sorter: (a, b) => a.totalEmails - b.totalEmails,
                sortDirections: ['descend', 'ascend'],
                render: (value, row) => (formatter.format(value))
            }, {
                title: 'Subscribed Email Count',
                dataIndex: 'totalSubscribedEmails',
                key: 'totalSubscribedEmails',
                width: '15%',
                sorter: (a, b) => a.totalSubscribedEmails - b.totalSubscribedEmails,
                sortDirections: ['descend', 'ascend'],
                render: (value, row) => (formatter.format(value))
            },
            {
                title: '% Subscribed Email',
                dataIndex: 'totalCreatedBusinessesYesterday',
                key: 'totalCreatedBusinessesYesterday',
                width: '15%',
                sorter: (a, b) => a.totalCreatedBusinessesYesterday - b.totalCreatedBusinessesYesterday,
                sortDirections: ['descend', 'ascend'],
                render: (value, row) => {
                    const {totalEmails, totalSubscribedEmails} = row;
                    if (totalSubscribedEmails === 0) {
                        return '0';
                    }

                    if (!parseInt(totalSubscribedEmails)) {
                        return '-';
                    }

                    return `${((totalSubscribedEmails/totalEmails) * 100).toFixed(2)}%`;
                }
            }
        ];

        return (
            <div>
                <h2>FreeProd Overview</h2>
                <Table className={"ant-table-bordered"} style={{ marginTop: 20, overflowY: "auto" }} size={"medium"} showHeader={true} columns={columns} dataSource={records} loading={isTableLoading} pagination={false} scroll={{y: 450}}/>
            </div>
        );
    }
}

export default Overview;
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Upload, Button } from "antd";
import { uploadUtility } from "../../utils";
import { formItemLayout, formButtonLayout } from "../../constants";

class MultiResolutionImageForm extends Component {
    constructor(props) {
        super(props);

        this.imageResolutions = [
            { key: "1x", pd: "" },
            { key: "2x", pd: "2x" },
            { key: "3x", pd: "3x" }
        ];

        this.timestamp = `${Math.floor(0x100000000 * Math.random())}_${Date.now().toString()}`;
    }

    handleOnSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((e,v) => {
            const { industry } = this.props;
            if (e) return;
            let imageUrl = v[`image_${industry}`][this.imageResolutions[0]["key"]] && v[`image_${industry}`][this.imageResolutions[0]["key"]][0] ? v[`image_${industry}`][this.imageResolutions[0]["key"]][0].url : null;
            this.props.handleSubmission({ [this.props.industry]: imageUrl });
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { imageUrl, industry } = this.props;

        return (
            <Form onSubmit={this.handleOnSubmit}>
                {
                    this.imageResolutions.map(({ key, pd }, index) => {
                        const layout = (index === 0) ? formItemLayout : formButtonLayout;

                        return (
                            <Form.Item key={index} {...layout} label={(!index) ? "Image" : ""} extra={<><b>Supported image type:</b> jpg, jpeg, png, gif, svg</>}>
                                {
                                    getFieldDecorator(`image_${industry}[${key}]`, {
                                        getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                        rules: [{
                                            required: true,
                                            message: `Upload image@${key}`
                                        }, {
                                            validator: uploadUtility.singleFile.validator
                                        }],
                                        valuePropName: "fileList",
                                        initialValue: imageUrl && [{
                                            uid: "-1",
                                            name: `image@${key}`,
                                            status: "done",
                                            url: imageUrl.substr(0, imageUrl.lastIndexOf(".")) + ( pd ? ("@" + pd) : "" ) + imageUrl.substr(imageUrl.lastIndexOf("."))
                                        }],
                                    })(
                                        <Upload
                                            name={"files"}
                                            accept={"image/*"}
                                            action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=product-upsell-industry-image-${this.timestamp}&pd=${pd}&webp=true&excludeTimestamp=true`}
                                            withCredentials
                                            listType="picture"
                                            >
                                            <Button icon={"upload"}>image @{key}</Button>
                                        </Upload>
                                    )
                                }
                            </Form.Item>
                        )
                    })
                }
                <Form.Item {...formButtonLayout}>
                    <Button type="primary" htmlType="submit">Save</Button>
                </Form.Item>
            </Form>
        )
    }
}

MultiResolutionImageForm.propTypes = {
    handleSubmission: PropTypes.func,
    imageUrl: PropTypes.string,
    industry: PropTypes.string
};

export default Form.create()(MultiResolutionImageForm);
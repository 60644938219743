import { SelfServePanelActions } from "./actions";

let SelfServe = {
    businessDetails: [],
    requestedCRMDetails: [],
    selfServeCRMDetails: {},
    errorMessage: {},
    isLoading: false
};

const selfServeReducer = (state = SelfServe, action) => {
    switch (action.type) {
        case SelfServePanelActions.FETCH_SELF_SERVE_LIST_REQUEST:
            return {
                businessDetails: [],
                errorMessage: {},
                isLoading: true
            };
        case SelfServePanelActions.FETCH_SELF_SERVE_LIST_SUCCESS:
            return {
                businessDetails: [
                    ...action.payload
                ],
                isLoading: false,
                errorMessage: {}
            };
        case SelfServePanelActions.FETCH_SELF_SERVE_LIST_ERROR:
            return {
                businessDetails: [],
                errorMessage: {... action.payload} || {},
                isLoading: false
            };
        case SelfServePanelActions.FETCH_SELF_SERVE_APPROVE:
            return {
                ...state,
                selfServeCRMDetails: {},
                isLoading: true
            };
        case SelfServePanelActions.FETCH_SELF_SERVE_APPROVE_SUCCESS:
            return {
                ...state,
                selfServeCRMDetails: {
                    ...action.payload
                },
                errorMessage: {... action.payload} || {},
                isLoading: false,
            };
        case SelfServePanelActions.FETCH_SELF_SERVE_APPROVE_ERROR:
            return {
                ...state,
                selfServeCRMDetails: {},
                errorMessage: {... action.payload} || {},
                isLoading: false
            };
        case SelfServePanelActions.SELF_SERVE_CRM_SAVE_REQUEST:
            return {
                ...state,
                errorMessage: {},
                isLoading: true
            };
        case SelfServePanelActions.SELF_SERVE_CRM_SAVE_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                errorMessage: {}
            };
        case SelfServePanelActions.SELF_SERVE_CRM_SAVE_REQUEST_ERROR:
            return {
                ...state,
                errorMessage: {... action.payload} || {},
                isLoading: false
            };
        case SelfServePanelActions.FETCH_REQUESTED_CRM_LIST_REQUEST:
            return {
                ...state,
                requestedCRMDetails: [],
                errorMessage: {},
                isLoading: true
            };
        case SelfServePanelActions.FETCH_REQUESTED_CRM_LIST_SUCCESS:
            return {
                    ...state,
                    requestedCRMDetails: [
                        ...action.payload
                    ],
                    isLoading: false,
                    errorMessage: {}
            };
        case SelfServePanelActions.FETCH_REQUESTED_CRM_LIST_ERROR:
            return {
                ...state,
                requestedCRMDetails: [],
                errorMessage: {... action.payload} || {},
                isLoading: false
            };
        default:
            return state;
    }
};

export default selfServeReducer;

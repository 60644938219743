import { findIndex } from "lodash";
import { PressesActions } from "./actions";

let Presses = {
    data: [],
    curData: {},
    error: "",
    isLoading: false
};

const pressesReducer = (state = Presses, action) => {
    let index;
    switch (action.type) {
        case PressesActions.FETCH_PRESSES_LIST_REQUEST:
        case PressesActions.FETCH_PRESS_REQUEST:
        case PressesActions.ADD_PRESS_REQUEST:
        case PressesActions.UPDATE_PRESS_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case PressesActions.FETCH_PRESSES_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case PressesActions.FETCH_PRESS_SUCCESS:
            return {
                ...state,
                curData: action.payload,
                isLoading: false
            };
        case PressesActions.ADD_PRESS_SUCCESS:
            return {
                ...state,
                data: [...state.data, action.payload],
                curData: action.payload,
                isLoading: false
            };
        case PressesActions.UPDATE_PRESS_SUCCESS:
            index = findIndex(state.data, ["id", action.payload.id]);
            return {
                ...state,
                data: [
                    ...state.data.slice(0, index),
                    action.payload,
                    ...state.data.slice(index + 1)
                ],
                curData: action.payload,
                isLoading: false
            };
        case PressesActions.DELETE_PRESS_SUCCESS:
            index = findIndex(state.data, ["id", action.payload.id]);
            return {
                ...state,
                data: [
                    ...state.data.slice(0, index),
                    ...state.data.slice(index + 1)
                ],
                error: ""
            };
        case PressesActions.PRESS_ERROR:
            return {
                ...state,
                data: [],
                curData: {},
                error: action.payload.error,
                isLoading: false
            };
        default:
            return state;
    }
};

export default pressesReducer;

import React, { Component } from "react";
import { Form, Input, Button, notification, Spin, Select, Upload, Divider } from "antd";
import _ from "lodash";
import { formItemLayout, formButtonLayout } from "../../constants";
import { uploadUtility, getSlug } from "../../utils";
import { RedirectWrapper } from "../../components";
import lookup from "./lookup.json";


class ProductMessageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading : false,
            isEdit: !_.isEmpty(props.location.state),
            existingLayoutsOfProduct: []
        };

        this.timestamp = `${Math.floor(0x100000000 * Math.random())}_${Date.now().toString()}`;
    }

    componentDidMount() {
        this.props.fetchAllProductMessageRequest({ isCache: 1 });
        if (this.state.isEdit) {
            const productMessage = this.props.location.state || {};
            this.setFormInitialValues({ product: productMessage.product, layout: productMessage.layout });
        }
    }

    handleFormOnSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((e,v) => {
            if (e) return;

            this.props.updateProductMessageRequest(this.valuesForm2API(v), this.handleAfterSubmit);
        });
    }

    handleAfterSubmit = (response) => {
        notification[response.status]({
            message: response.message,
            description: response.description,
            placement: "bottomRight",
            duration: 30
        });
        if (response.status == "success") {
            this.props.history.push("/upsell/product-message");
        }
    };

    setFormInitialValues = (filter) => {

        if (!filter.product || !filter.layout) return;

        this.props.form.resetFields();
        this.props.form.setFieldsValue(filter);

        this.setState({ isLoading: true }, () => {
            setTimeout(() => {
                let initialValues = {};

                if (this.state.isEdit) {
                    const productMessage = this.props.location.state || {};
                    initialValues = { ...this.valuesAPI2Form({ ...productMessage.component, ...filter }) };
                } else {
                    initialValues = { ...this.valuesAPI2Form({ ...(this.props.productMessageLookup[filter.product] || {})[filter.layout], layout: filter.layout }) };
                }
                this.props.form.setFieldsValue(initialValues);
                this.setState({ isLoading: false });
            }, 200);
        });
    };

    setFormInitialValuesOnProductChange = (product) => {
        this.setState({
            existingLayoutsOfProduct: Object.keys(this.props.productMessageLookup[product] || {})
        });
        this.props.form.resetFields();
        this.props.form.setFieldsValue({ product });
    };

    setFormInitialValuesOnLayoutChange = (layout) => {
        const product = this.props.form.getFieldValue("product");
    };

    valuesAPI2Form = (raw) => {
        let values = {};

        if (!raw) return values;

        const createFileValue = (url, name="") => {
            return url ? [{
                uid: -1,
                name,
                status: "done",
                url
            }] : undefined;
        };

        const createMultiResolutionImageValue = (imageField, imageUrl, name="") => {
            if (!imageField || !imageUrl) return {};
            let multiResolutionImageValue = {};

            const extensionIndex = imageUrl && imageUrl.lastIndexOf(".");
            const urlPrefix = imageUrl.substr(0, extensionIndex);
            const urlSuffix = imageUrl.substr(extensionIndex);

            lookup.imageResolutions.forEach(({ key, pd }) => {
                const url = urlPrefix + ( pd ? ("@" + pd) : "" ) + urlSuffix;
                multiResolutionImageValue[`${imageField}[${key}]`] = [{
                    uid: -1,
                    name: `${name}@${key}`,
                    status: "done",
                    url
                }];
            });

            return multiResolutionImageValue;
        };

        const createCTAValue = (cta, { label, target, link, attachment="" }) => {
            let value = {};

            if (_.isEmpty(cta)) return {};

            value[label] = cta.label ? cta.label : undefined;
            value[target] = cta.target ? cta.target : undefined;
            if (cta.target === "attachment" && cta.link) {
                value[attachment] = createFileValue(cta.link, cta.label);
            } else {
                value[link] = cta.link ? cta.link : undefined;
            }
            
            return value;
        };

        if (raw.layout === "info_container") {
            values.infoscreen_heading = raw.heading ? raw.heading : undefined;
            values.infoscreen_description = raw.description ? raw.description : undefined;
            values.infoscreen_icon = createFileValue(raw.icon, `${values.infoscreen_heading} | Icon`);
            values.infoscreen_image = createFileValue(raw.image, `${values.infoscreen_heading} | Image`);
            values = { ...values,  ...createCTAValue(raw.cta, { label: "infoscreen_cta_label", target: "infoscreen_cta_target", link: "infoscreen_cta_link", attachment: "infoscreen_cta_attachment" })};
        } else {

            if (raw.banner) {
                values.banner_tag = raw.banner.tag ? raw.banner.tag : null;
                values.banner_heading = raw.banner.heading ? raw.banner.heading : null;
                values.banner_description = raw.banner.description ? raw.banner.description : null;
                const multiResolutionImage = createMultiResolutionImageValue("banner_image", raw.banner.image, "Image");console.log(multiResolutionImage);
                values = { ...values,  ...createCTAValue(raw.banner.cta, { label: "banner_cta_label", target: "banner_cta_target", link: "banner_cta_link", attachment: "banner_cta_attachment" }), ...multiResolutionImage };
            }

            if (raw.addOn) {
                values.addon_heading = raw.addOn.heading ? raw.addOn.heading : null;
                values.addon_description = raw.addOn.description ? raw.addOn.description : null;
                values = { ...values,  ...createCTAValue(raw.addOn.cta, { label: "addon_cta_label", target: "addon_cta_target", link: "addon_cta_link", attachment: "addon_cta_attachment" }) };
            }
        }

        return values;
    };

    valuesForm2API = (raw) => {
        let values = {};
        if (raw.product) values.product = raw.product;

        if (raw.layout) { values.layout = raw.layout; }
        else { return values; }

        const fetchFileURL = (field) => {
            return raw[field] && !_.isEmpty(raw[field][0]) ? raw[field][0].url : null;
        };

        const fetchMultiResolutionImage = (field) => {
            return raw[field] && !_.isElement(raw[field]) && !_.isElement(raw[field][lookup.imageResolutions[0]["key"]]) && !_.isElement(raw[field][lookup.imageResolutions[0]["key"]][0]) ? raw[field][lookup.imageResolutions[0]["key"]][0].url : null;
        };

        const fetchCTAObject = ({ label, target, link, attachment }) => {
            let cta = {};

            if (raw[label]) {
                cta.label = raw[label];
                cta.data_click_tag = "productupsell-" + getSlug(raw[label]);
            }

            cta.target = raw[target] ? raw[target] : null;
            if (raw[target] === "attachment" && raw[attachment]) {
                cta.link = fetchFileURL(attachment);
            } else {
                cta.link = raw[link] ? raw[link] : null;
            }

            if (cta.target === "calendar") delete cta.link;

            return cta;
        };

        let component = {};

        if (raw.layout === "info_container") {

            component.heading = raw.infoscreen_heading ? raw.infoscreen_heading : null;
            component.description = raw.infoscreen_description ? raw.infoscreen_description : null;
            component.icon = fetchFileURL("infoscreen_icon");
            component.image = fetchFileURL("infoscreen_image");
            component.cta = fetchCTAObject({ label: "infoscreen_cta_label", target: "infoscreen_cta_target", link: "infoscreen_cta_link", attachment: "infoscreen_cta_attachment" });
        } else {
            let banner = {};
            banner.tag = raw.banner_tag ? raw.banner_tag : null;
            banner.heading = raw.banner_heading ? raw.banner_heading : null;
            banner.description = raw.banner_description ? raw.banner_description : null;
            banner.image = fetchMultiResolutionImage("banner_image");
            banner.cta = fetchCTAObject({ label: "banner_cta_label", target: "banner_cta_target", link: "banner_cta_link", attachment: "banner_cta_attachment" });

            component.banner = banner;

            let addOn = {};
            addOn = {};
            addOn.heading = raw.addon_heading ? raw.addon_heading : null;
            addOn.description = raw.addon_description ? raw.addon_description : null;
            addOn.cta = fetchCTAObject({ label: "addon_cta_label", target: "addon_cta_target", link: "addon_cta_link", attachment: "addon_cta_attachment" });

            component.addOn = addOn;
        }

        values.component = component;

        if (raw.layout !== "info_container" && !this.state.isEdit) {
            let industryWisePopulate = {};
            lookup.industry.forEach((row) => {
                industryWisePopulate[row.categorySlug] = null;
            });

            values.industry_image = industryWisePopulate;
            if (raw.addon_cta_target === "attachment") values.industry_addon = industryWisePopulate;
        }

        return values;
    };

    renderFileField = ({ label, fileFieldName, required=true, additionalStyle={}, extraMessage, type="image" }) => {
        const { getFieldDecorator } = this.props.form;
        const layout = label ? formItemLayout : formButtonLayout;

        return (
            <Form.Item {...layout} label={label} style={additionalStyle} extra={ extraMessage || (<p>Preferred image format: <b>svg</b></p>)}>
                {
                    getFieldDecorator(fileFieldName, {
                        getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                        rules: [{
                            required: required,
                            message: `Please upload ${type}`
                        }, {
                            validator: uploadUtility.singleFile.validator
                        }],
                        valuePropName: "fileList"
                    })(
                        <Upload
                            name={"files"}
                            accept={type === "image" ? "image/svg+xml" : "application/pdf"}
                            action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=upsell-product-message-${type}__${Date.now()}`}
                            withCredentials
                            listType="picture"
                            >
                            <Button icon={"upload"}>Click to upload</Button>
                        </Upload>
                    )
                }
            </Form.Item>
        );
    }

    renderMultipleFileField = ({ label, fileFieldName }) => {
        const { getFieldDecorator } = this.props.form;
        return lookup.imageResolutions.map(({ key, pd }, index) => {
            const layout = (index === 0) ? formItemLayout : formButtonLayout;
            return (
                <Form.Item key={index} {...layout} label={(index === 0) ? label : ""} extra={<><b>Supported image type:</b> jpg, jpeg, png, gif, svg</>}>
                    {
                        getFieldDecorator(`${fileFieldName}[${key}]`, {
                            getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                            rules: [{
                                required: true,
                                message: `Upload image@${key}`
                            }, {
                                validator: uploadUtility.singleFile.validator
                            }],
                            valuePropName: "fileList"
                        })(
                            <Upload
                                name={"files"}
                                accept={"image/*"}
                                action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=product-upsell-${this.timestamp}&pd=${pd}&webp=true&excludeTimestamp=true`}
                                withCredentials
                                listType="picture"
                                >
                                <Button icon={"upload"}>{label} @{key}</Button>
                            </Upload>
                        )
                    }
                </Form.Item>
            )
        });
    }

    renderInfoScreenFields = () => {
        const { getFieldDecorator } = this.props.form;

        return (
            <>
                <Divider orientation="left">Info Screen</Divider>
                {
                    this.renderFileField({ label: "Icon", fileFieldName: "infoscreen_icon", required: false })
                }
                <Form.Item {...formItemLayout} label="Heading">
                    {
                        getFieldDecorator("infoscreen_heading", {
                            rules: [{
                                required: true,
                                message: "Please provide heading"
                            }]
                        })(
                            <Input placeholder="Enter Heading" />
                        )
                    }
                </Form.Item>
                <Form.Item {...formItemLayout} label="Description">
                    {
                        getFieldDecorator("infoscreen_description", {
                            rules: [{
                                required: true,
                                message: "Please provide description"
                            }]
                        })(
                            <Input.TextArea placeholder="Enter Description" autoSize={{ minRows: 4 }} />
                        )
                    }
                </Form.Item>
                {
                    this.renderFileField({ label: "Image", fileFieldName: "infoscreen_image", required: false })
                }
                {
                    this.renderCTAFields({ 
                        label: "CTA", ctaLabelName: "infoscreen_cta_label", 
                        ctaLinkName: "infoscreen_cta_link", ctaTargetName: "infoscreen_cta_target",
                        ctaAttachmentName: "infoscreen_cta_attachment",
                        source: "inforscreen"
                    })
                }
            </>
        );
    };

    renderKnowMoreFields = () => {
        const { getFieldDecorator } = this.props.form;

        return (
            <>
                <Divider orientation="left">Add-on</Divider>
                <Form.Item {...formItemLayout} label="Heading">
                    {
                        getFieldDecorator("addon_heading", {
                            rules: [{
                                required: true,
                                message: "Please provide heading"
                            }]
                        })(
                            <Input placeholder="Enter Heading" />
                        )
                    }
                </Form.Item>
                <Form.Item {...formItemLayout} label="Description">
                    {
                        getFieldDecorator("addon_description", {
                            rules: [{
                                required: true,
                                message: "Please provide description"
                            }]
                        })(
                            <Input.TextArea placeholder="Enter Description" autoSize={{ minRows: 4 }} />
                        )
                    }
                </Form.Item>
                {
                    this.renderCTAFields({ 
                        label: "CTA", ctaLabelName: "addon_cta_label", 
                        ctaLinkName: "addon_cta_link", ctaTargetName: "addon_cta_target",
                        ctaAttachmentName: "addon_cta_attachment",
                        source: "addon"
                    })
                }
            </>
        );
    };

    renderCTAFields = ({ source, label, ctaLabelName, ctaLinkName, ctaTargetName, ctaAttachmentName }) => {
        if (!ctaLabelName || !ctaTargetName || !ctaLinkName || !source || !ctaAttachmentName) return (<></>);

        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const formValues = getFieldsValue(); 

        return (
            <>
                <Form.Item {...formItemLayout} label={label}>
                    {
                        getFieldDecorator(ctaLabelName, {
                            rules: [{
                                required: true,
                                message: "Please provide a label for this CTA"
                            }]
                        })(
                            <Input placeholder="Enter CTA's Label" />
                        )
                    }
                </Form.Item>
                <Form.Item {...formButtonLayout}>
                    {
                        getFieldDecorator(ctaTargetName, {
                            rules: [{
                                required: true,
                                message: "Please provide an action for this CTA"
                            }]
                        })(
                            <Select placeholder="Select CTA's Target">
                                {
                                    lookup.ctaTargets.filter((v) => (source != "addon" ? v[0] === "calendar" : v[0] !== "calendar")).map((v, i) => (
                                        <Select.Option key={i} value={v[0]}>{v[1]}</Select.Option>
                                    ))
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Form.Item {...formButtonLayout} style={{ display: formValues[ctaTargetName] !== "_blank" ? "none" : "" }}>
                    {
                        getFieldDecorator(ctaLinkName, {
                            rules: [{
                                required: formValues[ctaTargetName] === "_blank",
                                message: "Please provide an action for this CTA"
                            }]
                        })(
                            <Input placeholder="Enter CTA's Action"/>
                        )
                    }
                </Form.Item>
                {
                    this.renderFileField({ label: "", fileFieldName: ctaAttachmentName, 
                        layout: formButtonLayout,
                        required: formValues[ctaTargetName] === "attachment",
                        additionalStyle: { display: formValues[ctaTargetName] !== "attachment" ? "none" : "" },
                        extraMessage: (<p>Preferred file format: <b>pdf</b></p>), type: "file"
                    })
                }
            </>
        );
    };

    renderBannerFields = () => {
        const { getFieldDecorator } = this.props.form;

        return (
            <>
                <Divider orientation="left">Banner</Divider>
                <Form.Item {...formItemLayout} label="Tag">
                    {
                        getFieldDecorator("banner_tag", {
                            rules: [{
                                required: true,
                                message: "Please provide a tag"
                            }]
                        })(
                            <Input placeholder="Enter Tag" />
                        )
                    }
                </Form.Item>
                <Form.Item {...formItemLayout} label="Heading">
                    {
                        getFieldDecorator("banner_heading", {
                            rules: [{
                                required: true,
                                message: "Please provide heading"
                            }]
                        })(
                            <Input placeholder="Enter Heading" />
                        )
                    }
                </Form.Item>
                <Form.Item {...formItemLayout} label="Description">
                    {
                        getFieldDecorator("banner_description", {
                            rules: [{
                                required: true,
                                message: "Please provide description"
                            }]
                        })(
                            <Input.TextArea placeholder="Enter Description" autoSize={{ minRows: 4 }} />
                        )
                    }
                </Form.Item>
                {
                    this.renderMultipleFileField({ label: "Image", fileFieldName: "banner_image" })
                }
                {
                    this.renderCTAFields({ 
                        label: "CTA", ctaLabelName: "banner_cta_label", 
                        ctaLinkName: "banner_cta_link", ctaTargetName: "banner_cta_target",
                        ctaAttachmentName: "banner_cta_attachment",
                        source: "banner"
                    })
                }
            </>
        );
    };

    render() {
        const { error, userData } = this.props;
        const { isEdit, existingLayoutsOfProduct } = this.state;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const formValues = getFieldsValue();

        return (
            <div>
                <h2>{ isEdit ? "Update" : "Add" } Product Message</h2>
                <Spin spinning={!!this.props.isLoading || this.state.isLoading}>
                    <Form onSubmit={this.handleFormOnSubmit}>
                        <Divider orientation="left">Product & Message Layout</Divider>
                        <Form.Item {...formItemLayout} label="Product">
                            {
                                getFieldDecorator("product", {
                                    rules: [{
                                        required: true,
                                        message: "Please select a product"
                                    }],
                                })(
                                    <Select placeholder="Select a Product" onChange={this.setFormInitialValuesOnProductChange} disabled={isEdit}>
                                        {
                                            lookup.products.map((v, i) => (
                                                <Select.Option key={i} value={v[0]}>{v[1]}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                )
                            }
                        </Form.Item>
                        <Form.Item {...formItemLayout} label="Message Layout">
                            {
                                getFieldDecorator("layout", {
                                    rules: [{
                                        required: true,
                                        message: "Please select a layout"
                                    }],
                                })(
                                    <Select placeholder="Select a layout" onChange={this.setFormInitialValuesOnLayoutChange} disabled={isEdit}>
                                        {
                                            lookup.layouts.map((v, i) => {
                                                const isDisabled = existingLayoutsOfProduct.indexOf(v[0]) !== -1;
                                                return (
                                                    <Select.Option key={i} value={v[0]} disabled={isDisabled}>{v[1]} {isDisabled ? <small>( already created )</small> : ""}</Select.Option>
                                                );
                                            })
                                        }
                                    </Select>
                                )
                            }
                        </Form.Item>
                        {
                            formValues.layout === "info_container" ? this.renderInfoScreenFields() : (
                                <>
                                {
                                    this.renderBannerFields()
                                }
                                {
                                    this.renderKnowMoreFields()
                                }
                            </> 
                            )
                        }
                        <Form.Item {...formButtonLayout}>
                            <Button type="primary" htmlType="submit">Save</Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </div>
        );
    }
};

export default Form.create()(ProductMessageForm);

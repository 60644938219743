import React from "react";
import { each, escapeRegExp, isEmpty } from "lodash";
import URL from "url-parse";
import he from "he";
import ReactHtmlParser from "react-html-parser";

import { monthNames, richResources } from "../constants";
import permission from "../constants/permission.json";
import { beAPIResourceLeadGen } from "./apiHelper";

/* resources */

export const decodeResourceClassification = (
    classification = "",
    lookup,
    translate
) => {
    const classifications = classification.split(" ");
    let topics = [];
    let industries = [];
    classifications.forEach((tag) => {
        if (tag.includes("product-")) {
            topics.push(translate ? lookup.topicLookup[tag] || "" : tag);
        } else if (tag.includes("industry-")) {
            industries.push(translate ? lookup.industryLookup[tag] || "" : tag);
        }
    });
    return { topics, industries };
};

export const encodeResourceClassification = (
    type,
    industries,
    topics,
    lookup
) => {
    const typeCls = lookup.typeLookup[type];

    return `${typeCls} ${industries.join(" ")} ${topics.join(" ")}`;
};

/* general */

export const createActionTypes = (prefix, actionTypeList) => {
    const actionTypesObject = {};

    each(actionTypeList, (item) => {
        actionTypesObject[item] = `${prefix}/${item}`;
    });

    return actionTypesObject;
};

export const splitter = (val, delimiter = "\n") => {
    return val.split(delimiter).map((item, key) => {
        return (
            <span key={key}>
                {item}
                <br />
            </span>
        );
    });
};

export const strtr = (str, pairs) => {
    const substrs = Object.keys(pairs).map(escapeRegExp);
    return str
        .split(RegExp(`(${substrs.join("|")})`))
        .map((part) => pairs[part] || part)
        .join("");
};

export const getSlug = (name = "") => {
    name = he.decode(name);
    const pairs = {
        ˆ: "-",
        "‡": "-",
        Š: "-",
        "‰": "-",
        "": "-",
        Ž: "-",
        "‘": "-",
        "": "-",
        "“": "-",
        "’": "-",
        "•": "-",
        "”": "-",
        "˜": "-",
        "—": "-",
        š: "-",
        "™": "-",
        "": "-",
        œ: "-",
        Ÿ: "-",
        ž: "-",
        "–": "-",
        "": "-",
        á: "-",
        "/": "-",
        _: "-",
        ",": "-",
        ":": "-",
        ";": "-"
    };
    name = strtr(name, pairs);
    name = name.trim().toLowerCase();
    name = name.replace(/[^a-z0-9- ]/g, "");
    name = name.replace(/\s+/g, "-");
    name = name.replace(/-+/g, "-");
    name = name.replace(/^-/, "");

    if (name.substr(name.length - 1, name.length) === "-") {
        name = name.substr(0, name.length - 1);
    }
    return name;
};

export const isWebsiteLink = (url) => {
    return (url && url.match(/^(\/)|((http|https):\/\/(www\.)?birdeye.com)/gi)) ? true : false;
};

export const formatNum = (x) => {
    if (isNaN(x)) return x;

    if (x < 999) {
        return x;
    }

    if (x < 1000000) {
        return (x / 1000).toFixed(1) + "K";
    }
    if (x < 10000000) {
        return (x / 1000000).toFixed(1) + "M";
    }

    if (x < 1000000000) {
        return (x / 1000000).toFixed(1) + "M";
    }

    if (x < 1000000000000) {
        return (x / 1000000000).toFixed(1) + "B";
    }

    return "1T+";
};

export const toPercentage = (portion, total) => {
    let percentageStr = total ? ((portion / total) * 100).toFixed(2) + "%" : "-";
    if (percentageStr.slice(-3) === "00%") {
        return percentageStr.slice(0, -4) + "%";
    } else {
        return percentageStr;
    }
};

export const transformNumCol = (column, originalRecord, targetObj, aggObj, forCsv = false) => {
    const { sortAs, key, percentageCalc, notAgg } = column;
    let transformedRecord = targetObj || {};

    if (percentageCalc) {
        const portion = originalRecord[percentageCalc[0]];
        const base = originalRecord[percentageCalc[1]];
        transformedRecord[`${key}Original`] = base ? portion / base : 0;
        transformedRecord[key] = toPercentage(portion, base);

        if (aggObj && !notAgg) {
            aggObj[percentageCalc[0]] = aggObj[percentageCalc[0]] ? aggObj[percentageCalc[0]] + portion : portion;
        }

    } else if (sortAs === "numberString") {
        transformedRecord[`${key}Original`] = originalRecord[key];
        transformedRecord[key] = forCsv ? (new Intl.NumberFormat('en-US')).format(originalRecord[key]) : formatNum(originalRecord[key]);

        if (aggObj) {
            aggObj[key] = aggObj[key] ? aggObj[key] + originalRecord[key] : originalRecord[key];
        }
    } else if (sortAs === "number") {
        transformedRecord[`${key}Original`] = originalRecord[key];
        transformedRecord[`${key}DataIndex`] = (new Intl.NumberFormat('en-US')).format(originalRecord[key]);
        transformedRecord[key] = originalRecord[key];

        if (aggObj) {
            aggObj[key] = aggObj[key] ? aggObj[key] + originalRecord[key] : originalRecord[key];
        }
    }

    return transformedRecord;
};

export const getImageName = (imageName) => {
    const nameArr = imageName.split(".")[0].split("-");
    if (parseInt(nameArr.slice(-1)[0])) {
        nameArr.pop();
    }
    return nameArr.join(" ")
};

export const isStringEmpty = (str) => {
    return str == "" || str == undefined || str == null;
};

export const createSlugFromString = (cat) => {
    if (!isStringEmpty(cat) && typeof (cat) !== "undefined") {
        let str = cat.replace(/^\s+|\s+$/g, ""); // trim
        str = str.toLowerCase();
        // remove accents, swap ñ for n, etc
        // const from = "åàáãäâèéëêìíïîòóöôùúüûñç·,:;&";
        // const to = "aaaaaaeeeeiiiioooouuuunc------";

        // for (let i = 0, l = from.length; i < l; i++) {
        //     str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
        // }
        /* eslint-disable */
        str = str
            .replace(/\//g, "__")
            .replace(new RegExp(" & ", "g"), "_")
            .replace(/\s+/g, "-");

        return str;
        /* eslint-enable */
    }
};

export const getQueryStringParams = () => decodeURI(
    window.location.hash.split("?").pop())
    .replace('?', '')
    .split('&')
    .map(param => param.split('='))
    .reduce((values, [ key, value ]) => {
        values[ key ] = value
        return values
    }, {});

export const setQueryParamString = queryString => {
    window.location.hash = window.location.hash.split("?")[0] + queryString
};

export const getCookie = (name) => {
    const arg = name + "=";
    const alen = arg.length;
    const clen = document.cookie.length;
    let i = 0;
    while (i < clen) {
        const j = i + alen;
        if (document.cookie.substring(i, j) === arg) {
            return getCookieVal(j);
        }
        i = document.cookie.indexOf(" ", i) + 1;
        if (i === 0)
            break;
    }
    return null;
};

export const getCookieVal = (offset) => {
    let endstr = document.cookie.indexOf(";", offset);
    if (endstr === -1) {
        endstr = document.cookie.length;
    }
    return unescape(document.cookie.substring(offset, endstr));
};

export const addDynamicScripts = (src, id, cb, force) => {
    let script = document.getElementById(id);
    if (script && force) {
        if (typeof script.remove !== "function") { // fix for IE
            script.parentNode.removeChild(script);
        } else {
            script.remove();
        }
        appendScipt(id, src);
    } else if (!script) {
        appendScipt(id, src);
    } else {
        cb && script.addEventListener("load", cb);
        return;
    }

    function appendScipt(id, src) {
        let script = document.createElement("script");
        script.id = id;
        script.src = src;
        script.type = "text/javascript";
        script.dataset.timestamp = Date.now() + "";
        cb && script.addEventListener("load", cb);
        document.getElementsByTagName("head")[0].appendChild(script);
    }
};

export const addScripts = (scripts, cb) => {
    scripts.forEach(({ src, id }) => addDynamicScripts(src, id, cb));
}

export const capitalize = (str) => {
    if (!str) return str;
    str = str.toLowerCase().replace(/([^a-z]|^)([a-z])(?=[a-z]{2})/g, function(_, g1, g2) {
        return g1 + g2.toUpperCase(); } );
    return str;
}

export const isRichResource = (type) => richResources.includes(type);

export const startsWith = (str1, str2) => str1.slice(0, str2.length) === str2;

export const formatNumber = (number) => ( number && (new Intl.NumberFormat('en-US').format(number)) )

export const parseAsHtml = (content) => {
    return ReactHtmlParser(content);
};

export const downloadFile = ({
    response = {},
    name = '',
    type = "application/pdf",
    extension = "pdf",
    cb
}) => {
    const newBlob = new Blob([response.data], { type });
    
    // for IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }

    let link = document.createElement("a");
    const data = window.URL.createObjectURL(newBlob);
    const seconds = new Date().getTime().toString();
    
    link.style = "display: none";
    link.target = "_top";
    link.href = data;
    link.download = `${name ? name : seconds}.${extension}`;
    
    document.body.appendChild(link);
    cb && cb()
    link.click();

    // delay revoking the ObjectURL for browser compatibility
    setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(data);
    }, 100);
}

export const uploadUtility = {
    singleFile: {
        getValueFromEvent: (info) => {

            if (Array.isArray(info)) {
                return;
            }

            let [file, ] = info && info.fileList.slice(-1);

            if (!file) {
                return;
            }

            if (file.status === "done" && file.response && file.response.imageUrl) {
                file.url = file.response.imageUrl;
            }
    
            return [ file ];
        },
        validator: (rule, files, callback) => {

            if (!Array.isArray(files)) {
                return callback();
            }

            const [file, ] = files;

            if (file && file.status === "error") {
                return callback(file.response.message || "Unable to upload this file");
            }
            
            return callback();
        },
        submittedValue: (files) => {

            if (!Array.isArray(files)) {
                return;
            }

            const [file, ] = files;

            if (file && file.url) {
                return file.url;
            }

            return;
        }
    },
    multipleFile: {
        getValueFromEvent: () => {
            //TODO
        },
        validator: (rule, values, callback) => {
            //TODO
            return callback();
        }
    }
};

export const getPagePermission = (pageKey) => {
    const pageMeta = permission[pageKey];
    return !isEmpty(pageMeta) ? (pageMeta.permission) : null;
};

export const getSubTabPermission = (pageKey) => {
    const pageMeta = permission[pageKey];
    return !isEmpty(pageMeta) ? (pageMeta.subTabPermission || null) : null;
};

export const getIsPageActionAccessible = (pageKey, userPermission) => {
    if (!pageKey || isEmpty(userPermission)) return false;

    const requiredPermissions = getPagePermission(pageKey);
    const subTabPermission = getSubTabPermission(pageKey);

    const userHasCompletePermission = requiredPermissions && userPermission.indexOf(`${requiredPermissions}+`) !== -1;
    const userHasSubTabPermission = subTabPermission && userPermission.indexOf(`${subTabPermission}+`) !== -1;

    return (userHasCompletePermission || userHasSubTabPermission);
};

export const getIsPageAdmin = (pageKey, userPermission) => {
    if (!pageKey || isEmpty(userPermission)) return false;

    const requiredPermissions = getPagePermission(pageKey);
    return (userPermission.indexOf(`${requiredPermissions}Admin`) !== -1)
};

/**
 * @function
 * @async
 * @param {string} text - Text that needs to be copied
 * @returns {Promise}
 */

export const copyToClipboard = (text) => {
    return new Promise((resolve, reject) => {
        
        // To support all latest browsers
        if (navigator && navigator.clipboard) {
            const clipboardItem = new ClipboardItem({
                "text/html": new Blob(
                    [text],
                    { type: "text/html" }
                ),
            });
            navigator.clipboard.write([clipboardItem]);
            resolve();
            return;
        }

        // To support Internet Explorer 11 and older browsers
        if (window && window.clipboardData) {
            window.clipboardData.setData("Text", text);
            resolve();
            return;
        }

        reject({ message: "Couldn't able to copy text on clipboard." });
    });
};

export const getBusinessSlug = (name) => getSlug(name);

export const setCookie = (name, value, path, isSess, days, isTLD) => {
    const domain = ".birdeye.com" ;
    let d = "";
    const date = new Date();
    if (isSess === 1) {
        d = "";
    } else {
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        d = date.toGMTString();
    }

    document.cookie = name + "=" + escape(value) + ((d) ? "; expires=" + d : "")
        + ((path) ? "; path=" + path : "") + ((isTLD) ? `; domain=${domain}` : "") + "; secure";
};

const countriesCurrency = [
    {
        "value": "AUD",
        "label": "au"
    },
    {
        "value": "CAD",
        "label": "ca"
    },
    {
        "value": "NZD",
        "label": "nz"
    },
    {
        "value": "GBP",
        "label": "uk"
    },
    {
        "value": "USD",
        "label": "us"
    }
];

export async function createSFDCampaign(type, data, name) {
    let ownerID = null;
    let currencyIsoCode = 'USD';

    if(type === 'case-study' || type === 'webinar'){
        const country = type === 'case-study' ? data.country : data.webinar.country;
        currencyIsoCode = countriesCurrency.find(item => item.label === country).value || 'USD';
    }
    try {
        const getAllSFDCUsersRes = await beAPIResourceLeadGen.get(`leadgen/getAllSFDCUsers`);
        const user = (!!getAllSFDCUsersRes.data && !!name) ? getAllSFDCUsersRes.data.filter(item => item.emailId === name) : null;
        ownerID = !!user.length ? user[0].sfdcId : null;
    } catch {
        ownerID = null;
    }

    const sfdcCampaignPayload = {
        name: data.heading || (data.card && data.card.cardTitle) || null,
        active: true,
        parentCampaignId: process.env.REACT_APP_PARENT_CAMPAIGN_ID,
        ownerId: ownerID,
        description: data.description || (data.card && data.card.cardDesc) || null,
        type: "Demo Signup / Trial",
        currencyIsoCode: currencyIsoCode,
        status: "Planned"
    };

    try {
        const sfdcCampaignRes = await beAPIResourceLeadGen.post(`leadgen/sfdcCampaign`, sfdcCampaignPayload);
        return sfdcCampaignRes.data ? sfdcCampaignRes.data.id : null;
    } catch {
        return null;
    }
}

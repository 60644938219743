import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import PagesActions, * as actionCreator from "./actions";
import pageApi from "./http";

const pageSagas = [
    takeLatest(
        PagesActions.FETCH_PAGES_LIST_REQUEST,
        fetchPagesList
    ),
    takeLatest(PagesActions.FETCH_PAGE_REQUEST, fetchPage),
    takeLatest(PagesActions.ADD_PAGE_REQUEST, addPage),
    takeLatest(PagesActions.UPDATE_PAGE_REQUEST, updatePage),
    takeEvery(PagesActions.DELETE_PAGE_REQUEST, deletePage),
    takeEvery(PagesActions.REQ_FALLBACK_CAL, reqFallBackCal),
    takeEvery(PagesActions.ADD_INACTIVE_EMP, addInactiveEmp),
    takeEvery(PagesActions.REQ_INACTIVE_EMP_LIST, reqInactiveEmpList)
];

function* fetchPagesList() {
    try {
        const resp = yield call(pageApi.getAll);
        yield put(actionCreator.fetchPagesListSucess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.pageError(e.data.data || { error: "pagesListNotFound" })
        );
    }
}

function* fetchPage(action) {
    try {
        const resp = yield call(pageApi.getById, action.payload.id);
        yield put(actionCreator.fetchPageSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.pageError(e.data.data || { error: "pageNotFound" }));
    }
}

function* addPage(action) {
    try {
        const resp = yield call(pageApi.add, action.payload.data);
        const successResponse = yield put(actionCreator.addPageSuccess(resp));
        yield call(action.payload.cb, successResponse.payload);
    } catch (e) {
        const errorResponse =  yield put(actionCreator.pageError(e));
        yield call(action.payload.cb, errorResponse.payload.data.data || { error: "something went wrong" });
    }
}

function* updatePage(action) {
    try {
        const resp = yield call(pageApi.update, action.payload.data);
        const successResponse = yield put(actionCreator.updatePageSuccess(resp.data));
        yield call(action.payload.cb, successResponse.payload);
    } catch (e) {
        const errorResponse =  yield put(actionCreator.pageError(e));
        yield call(action.payload.cb, errorResponse.payload.data.data || { error: "cannotDelete" });
    }
}

function* deletePage(action) {
    const { id } = action.payload;
    try {
        yield call(pageApi.delete, id);
        yield put(actionCreator.deletePageSuccess({ id }));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.pageError(e.data.data || { error: "cannotDelete" }));
        yield call(action.payload.cb, e.data.data || { error: "cannotDelete" });
    }
}

function* reqFallBackCal() {
    try{
        const resp = yield call(pageApi.getFallBackCal);
        yield put(actionCreator.reqFallBackCalSuccess(resp.data));
    } catch (e) {
        console.log(e,"data")
        yield put(
            actionCreator.reqFallBackCalError(e.data.data || { message : "FallBackCal API not working" })
        )
    }
}

function* addInactiveEmp(action) {
    try{
        const resp = yield call(pageApi.addInactiveEmp, action.payload.data);
        const successResponse = yield put(actionCreator.addInactiveEmpSuccess(resp.data));
        yield call(action.payload.cb, successResponse.payload || "Done");
    } catch (e) {
        console.log(e,"data");
        const errorResponse = yield put( actionCreator.addInactiveEmpError(e.data.data || { message : "Inactive employee API not working" }));
        yield call(action.payload.cb, errorResponse.payload.data || { error: e.data.data.error || "something went wrong" });
    }
}

function* reqInactiveEmpList({ payload }) {
    try{
        const resp = yield call(pageApi.getInactiveEmp, payload);
        yield put(actionCreator.getInactiveEmpSuccess(resp.data));
    } catch (e) {
        console.log(e,"data")
        yield put(
            actionCreator.getInactiveEmpError(e.data.data || { message : "Inactive employee history API not working" })
        )
    }
}
export default pageSagas;

import { beAPIResource } from "../../utils/apiHelper";

export const FreeProdOverviewAPI = {
    reqOverviewData: payload => beAPIResource.get("/support/free-prod-dashboard/overview", payload),
    reqDetailCategories: (payload) => beAPIResource.get("/support/free-prod-dashboard/category", payload),
    reqDetailTableContent: (payload) => beAPIResource.get("/support/free-prod-dashboard/detail", payload),
    reqSummaryData: (payload) => beAPIResource.get("/support/free-prod-dashboard/summary", payload),
    reqEmailsData: (payload) => beAPIResource.get("/support/free-prod-dashboard/emails", payload),
};

export default FreeProdOverviewAPI;

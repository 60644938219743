import { SignatureAction } from "./action";

export let SignatureState = {
    isLoading: false,
    isModalLoading: false,
    banner: {},
    calendar: null,
    user: {}
};

const signatureReducer = (state = SignatureState, action) => {
    switch(action.type) {
        case SignatureAction.UPDATE_USER_REQUEST:
        case SignatureAction.UPDATE_USERS_REQUEST:
        case SignatureAction.GET_SIGNATURE_ASSET_REQUEST:
        case SignatureAction.DELETE_SIGNATURE_ASSET_REQUEST:
        case SignatureAction.GET_USER_CALENDAR_REQUEST:
        case SignatureAction.GET_USER_BY_ID_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case SignatureAction.UPDATE_USER_SUCCESS:
        case SignatureAction.UPDATE_USER_ERROR:
        case SignatureAction.UPDATE_USERS_SUCCESS:
        case SignatureAction.UPDATE_USERS_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case SignatureAction.GET_SIGNATURE_ASSET_SUCCESS:
        case SignatureAction.GET_SIGNATURE_ASSET_ERROR:
            return {
                ...state,
                isLoading: false,
                [action.payload.type]: {...state[action.payload.type], [action.payload.template]: action.payload.data}
            };
        case SignatureAction.CREATE_SIGNATURE_ASSET_REQUEST:
            return {
                ...state,
                isModalLoading: true
            };
        case SignatureAction.CREATE_SIGNATURE_ASSET_SUCCESS:
        case SignatureAction.CREATE_SIGNATURE_ASSET_ERROR:
        case SignatureAction.DELETE_SIGNATURE_ASSET_SUCCESS:
        case SignatureAction.DELETE_SIGNATURE_ASSET_ERROR:
            return {
                ...state,
                isModalLoading: false,
            };
        case SignatureAction.GET_USER_CALENDAR_SUCCESS:
        case SignatureAction.GET_USER_CALENDAR_ERROR:
            return {
                ...state,
                isLoading: false,
                calendar: action.payload.calendar
            };
        case SignatureAction.GET_USER_BY_ID_SUCCESS:
        case SignatureAction.GET_USER_BY_ID_ERROR:
            return {
                ...state,
                isLoading: false,
                user: action.payload
            };
        default:
            return state;
    }
};

export default signatureReducer;
import { createActionTypes } from "../../utils";

export const SignatureAction = createActionTypes("signature", [
    "UPDATE_USER_REQUEST",
    "UPDATE_USER_SUCCESS",
    "UPDATE_USER_ERROR",
    "UPDATE_USERS_REQUEST",
    "UPDATE_USERS_SUCCESS",
    "UPDATE_USERS_ERROR",
    "GET_SIGNATURE_ASSET_REQUEST",
    "GET_SIGNATURE_ASSET_SUCCESS",
    "GET_SIGNATURE_ASSET_ERROR",
    "CREATE_SIGNATURE_ASSET_REQUEST",
    "CREATE_SIGNATURE_ASSET_SUCCESS",
    "CREATE_SIGNATURE_ASSET_ERROR",
    "DELETE_SIGNATURE_ASSET_REQUEST",
    "DELETE_SIGNATURE_ASSET_SUCCESS",
    "DELETE_SIGNATURE_ASSET_ERROR",
    "GET_USER_CALENDAR_REQUEST",
    "GET_USER_CALENDAR_SUCCESS",
    "GET_USER_CALENDAR_ERROR",
    "GET_USER_BY_ID_REQUEST",
    "GET_USER_BY_ID_SUCCESS",
    "GET_USER_BY_ID_ERROR",
]);

export const updateUserRequest = (obj) => {
    return {
        type: SignatureAction.UPDATE_USER_REQUEST,
        payload: obj
    };
};

export const updateUserSuccess = (obj) => {
    return {
        type: SignatureAction.UPDATE_USER_SUCCESS
    };
}

export const updateUserError = (obj) => {
    return {
        type: SignatureAction.UPDATE_USER_ERROR
    };
}

export const updateUsersRequest = (obj) => {
    return {
        type: SignatureAction.UPDATE_USERS_REQUEST,
        payload: obj
    };
};

export const updateUsersSuccess = (obj) => {
    return {
        type: SignatureAction.UPDATE_USERS_SUCCESS
    };
}

export const updateUsersError = (obj) => {
    return {
        type: SignatureAction.UPDATE_USERS_ERROR
    };
}

export const getSignatureAssetRequest = (obj) => {
    return {
        type: SignatureAction.GET_SIGNATURE_ASSET_REQUEST,
        payload: obj
    };
};

export const getSignatureAssetSuccess = (obj) => {
    return {
        type: SignatureAction.GET_SIGNATURE_ASSET_SUCCESS,
        payload: obj
    };
}

export const getSignatureAssetError = (obj) => {
    return {
        type: SignatureAction.GET_SIGNATURE_ASSET_ERROR,
        payload: obj
    };
}

export const createSignatureAssetRequest = (obj) => {
    return {
        type: SignatureAction.CREATE_SIGNATURE_ASSET_REQUEST,
        payload: obj
    };
};

export const createSignatureAssetSuccess = (obj) => {
    return {
        type: SignatureAction.CREATE_SIGNATURE_ASSET_SUCCESS,
        payload: obj
    };
}

export const createSignatureAssetError = (obj) => {
    return {
        type: SignatureAction.CREATE_SIGNATURE_ASSET_ERROR,
        payload: obj
    };
}

export const deleteSignatureAssetRequest = (obj) => {
    return {
        type: SignatureAction.DELETE_SIGNATURE_ASSET_REQUEST,
        payload: obj
    };
};

export const deleteSignatureAssetSuccess = (obj) => {
    return {
        type: SignatureAction.DELETE_SIGNATURE_ASSET_SUCCESS,
        payload: obj
    };
}

export const deleteSignatureAssetError = (obj) => {
    return {
        type: SignatureAction.DELETE_SIGNATURE_ASSET_ERROR,
        payload: obj
    };
}

export const getUserCalendarRequest = (obj) => {
    return {
        type: SignatureAction.GET_USER_CALENDAR_REQUEST,
        payload: obj
    };
};

export const getUserCalendarSuccess = (obj) => {
    return {
        type: SignatureAction.GET_USER_CALENDAR_SUCCESS,
        payload: obj
    };
}

export const getUserCalendarError = (obj) => {
    return {
        type: SignatureAction.GET_USER_CALENDAR_ERROR,
        payload: obj
    };
}

export const getUserByIdRequest = (obj) => {
    return {
        type: SignatureAction.GET_USER_BY_ID_REQUEST,
        payload: obj
    };
};

export const getUserByIdSuccess = (obj) => {
    return {
        type: SignatureAction.GET_USER_BY_ID_SUCCESS,
        payload: obj
    };
};

export const getUserByIdError = (obj) => {
    return {
        type: SignatureAction.GET_USER_BY_ID_ERROR,
        payload: obj
    };
};

export default SignatureAction;

import React from "react";
import PropTypes from "prop-types";
import { Button, Popconfirm, Icon, message } from "antd";
import moment from "moment";
import { PageHeaderContainer, TableSkeleton } from "../../components";
import { getIsPageActionAccessible } from "../../utils";
import { isEmpty } from "lodash";
import lookup from "./lookup";

class ListByPageType extends React.Component {
    constructor(props) {
        super(props);
        this.pageKey = "featureResourceByPage";
        this.resourceTypeMapping = {};
        this.isReadonly = !getIsPageActionAccessible(this.pageKey, ((props.userData && props.userData.privileges) || []));
        this.lookupProduct = {};
    }

    componentDidMount() {
        this.props.reqFeaturePagesList();
        this.props.reqFeatureResourceByPage();
    }

    handleActionResponse = (response) => {
        message[response.type](response.message);
    }

    handleEditOnClick = (e, data) => {
        e.preventDefault();
        this.props.history.push({
            pathname: `/feature-resource/by/${this.props.match.params.pageType}/manage`,
            state: data,
        })
    }

    handleRemoveOnClick = (e, data) => {
        e.preventDefault();
        this.props.reqDeleteFeatureResourceByPage({ page: this.props.match.params.pageType, id: data.id }, this.handleActionResponse);
    };

    mapData2Table = (data) => {
        if (!data) return [];
        return data.map((row, i) => {
            return {
                key: i,
                ...row,
                formattedSlug: this.lookupProduct[row.slug],
                action: (
                    <React.Fragment>
                        <Button size="small" icon="edit" type="link" onClick={(e) => {this.handleEditOnClick(e, row)}} />
                        <Popconfirm title="Do you want to remove this?" icon={<Icon type="delete"/>} okText="Yes" cancelText="No" placement="left" onConfirm={(e) => {this.handleRemoveOnClick(e, row)}}>
                            <Button size="small" icon="delete" type="link" />
                        </Popconfirm>
                    </React.Fragment>
                ),
            };
        });
    }

    render () {
        
        if(!isEmpty(this.props.featurePagesList)) {
            this.props.featurePagesList.forEach(product => {
                this.lookupProduct[product.slug] = product.name;
            });   
        }
        
        return (
            <React.Fragment>
                <PageHeaderContainer pageKey={this.pageKey} title="Featured Resource By Page" />
                <TableSkeleton
                    dataSource={this.mapData2Table(this.props.featureResourceByPage)}
                    columns = {lookup.byPageTypeTableCols.filter(col => (!(this.isReadonly && col.key === "action")))}
                    loading={this.props.isLoading}
                    expandedRowRender={this.renderExpandedRow}
                />
            </React.Fragment>
        );
    };
}

ListByPageType.propTypes = {
    reqFeatureResourceByPage: PropTypes.func.isRequired,
    reqDeleteFeatureResourceByPage: PropTypes.func.isRequired
};

export default ListByPageType;
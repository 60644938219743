import React, { useState, useEffect } from 'react'
import {Form} from "antd";
import { getForm } from './getForm';
import { withRouter } from 'react-router-dom';
import { beAPIResource } from '../../utils/apiHelper';
import { RedirectWrapper } from '../../components';

function UpdateComponent({ match, userData, error, location }) {
    const unauthorized = error === "unauthorized" || (!userData || !userData.name);
    if (unauthorized) {
        return <RedirectWrapper location={location} />;
    }
    const params = match.params;
    const [selectedComponent, setSelectedComponent] = useState({});

    useEffect(() => {
        (async()=>{
            const {data} = await beAPIResource.get(`support/website/content/page/component/${params.componentId}`);
            setSelectedComponent(data);
        })()
    }, []);

    return (
        <Form>
            {
                getForm({name: selectedComponent.name, data: selectedComponent.data ? JSON.parse(selectedComponent.data) : {}, pageId: selectedComponent.page_id})
            }
        </Form>
  )
}

const UpdateComponentPage = withRouter(UpdateComponent);
export default UpdateComponentPage;
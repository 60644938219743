import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import IntegrationDomainsActions, * as actionCreator from "./actions";
import integrationDomainApi from "./http";

const integrationDomainSagas = [
    takeLatest(
        IntegrationDomainsActions.FETCH_INTEGRATIONDOMAINS_LIST_REQUEST,
        fetchIntegrationDomainsList
    ),
    takeLatest(IntegrationDomainsActions.FETCH_INTEGRATIONDOMAIN_REQUEST, fetchIntegrationDomain),
    takeLatest(IntegrationDomainsActions.ADD_INTEGRATIONDOMAIN_REQUEST, addIntegrationDomain),
    takeLatest(IntegrationDomainsActions.UPDATE_INTEGRATIONDOMAIN_REQUEST, updateIntegrationDomain),
    takeEvery(IntegrationDomainsActions.DELETE_INTEGRATIONDOMAIN_REQUEST, deleteIntegrationDomain)
];

function* fetchIntegrationDomainsList() {
    try {
        const resp = yield call(integrationDomainApi.getAll);
        yield put(actionCreator.fetchIntegrationDomainsListSucess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.integrationDomainError(e.data.data || { error: "integrationDomainsListNotFound" })
        );
    }
}

function* fetchIntegrationDomain(action) {
    try {
        const resp = yield call(integrationDomainApi.getById, action.payload.id);
        yield put(actionCreator.fetchIntegrationDomainSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.integrationDomainError(e.data.data || { error: "integrationDomainNotFound" }));
    }
}

function* addIntegrationDomain(action) {
    try {
        const resp = yield call(integrationDomainApi.add, action.payload.data);
        yield put(actionCreator.addIntegrationDomainSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.integrationDomainError(e.data.data || { error: "cannotAdd" }));
        yield call(action.payload.cb, "cannotAdd");
    }
}

function* updateIntegrationDomain(action) {
    try {
        const resp = yield call(integrationDomainApi.update, action.payload.data);
        yield put(actionCreator.updateIntegrationDomainSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.integrationDomainError(e.data.data || { error: "cannotUpdate" }));
        yield call(action.payload.cb, "cannotUpdate");
    }
}

function* deleteIntegrationDomain(action) {
    const { id } = action.payload;
    try {
        yield call(integrationDomainApi.delete, id);
        yield put(actionCreator.deleteIntegrationDomainSuccess({ id }));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.integrationDomainError(e.data.data || { error: "cannotDelete" }));
        yield call(action.payload.cb, "cannotDelete");
    }
}

export default integrationDomainSagas;

import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { FlyWheel, DocumentSection } from "../../components";
import { fetchPartnerDocumentBySectionRequest, downloadPartnerDocumentRequest } from "./../Partner/actions";

class PartnerLibraryHome extends Component {
    constructor(props) {
        super();
        this.type = (props.userData.account_type === 2) 
            ? "coBranded" : (
                (props.userData.account_type === 3) ? "whitelabeled" : 
                    "both"
            );
    }

    componentDidMount() {
        if (!this.props.section.hasOwnProperty("featured") || _.isEmpty(this.props.section.featured.all)) this.props.fetchPartnerDocumentBySectionRequest({ section: "featured", category: "all" });
        if (!this.props.section.hasOwnProperty("latest") || _.isEmpty(this.props.section.latest.all)) this.props.fetchPartnerDocumentBySectionRequest({ section: "latest", category: "all" });
    }

    render() {

        const { isLoading } = this.props;

        const latestDocuments = this.props.section.hasOwnProperty("latest") && !_.isEmpty(this.props.section.latest.all) ? this.props.section.latest.all.filter(d => (this.type !== "both" ? (d.type === this.type) : true)) : [];
        const featuredDocuments = this.props.section.hasOwnProperty("featured") && !_.isEmpty(this.props.section.featured.all) ? this.props.section.featured.all.filter(d => (this.type !== "both" ? (d.type === this.type) : true)) : [];

        return (
            <div className="productBasedLibrary" style={{ margin: "0 0 50px" }}>
                <h3 className="heading">Welcome to the Birdeye Partner Content Library</h3>
                <p className="subHeading">This is your one-stop shop for externally-facing materials that can be sent to and shared with prospects and customers.<br />
                    It contains partner sales decks, marketing materials, case studies, how to guides and more.</p>
                <div className="information"><span>Click on any product of the flywheel to learn more</span></div>
                <FlyWheel />
                <DocumentSection
                    isLoading={isLoading}
                    cols={[
                        {
                            type: "latest",
                            headingIcon: "sketch",
                            heading: "What's New",
                            isLoading: isLoading,
                            list: latestDocuments,
                        },
                        {
                            type: "featured",
                            headingIcon: "star",
                            heading: "Featured Content",
                            isLoading: isLoading,
                            list: featuredDocuments,
                        }
                    ]}
                    downloadPartnerDocumentRequest={this.props.downloadPartnerDocumentRequest}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Partner,
});

export default connect( 
    mapStateToProps , 
    { fetchPartnerDocumentBySectionRequest, downloadPartnerDocumentRequest }
)(PartnerLibraryHome);
import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { message } from "antd";

import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";
import { getSelectedCategoryLabel } from "./";
import { getIsPageActionAccessible } from "../../utils";

import lookup from "./integrationDomains.json";

class IntegrationDomains extends Component {

    pageKey = "integrationDomainsList";

    componentDidMount() {
        const { categories, fetchIntegrationDomainsListRequest, fetchBirdeyeCategoriesList} = this.props;
        fetchIntegrationDomainsListRequest();
        if (isEmpty(categories)) {
            fetchBirdeyeCategoriesList();
        }
    }

    formatData = (data) => {
        const { categories } = this.props;

        return data.map((record) => {
            return {
                ...record,
                key: record.id,
                domain: record.domain,
                categoryLabel: getSelectedCategoryLabel(categories, record.category)
            };
        });
    };

    handleDelete = (data) => {
        const { id } = data;
        this.props.deleteIntegrationDomainRequest({ id, cb: this.renderFeedbackMsg });
    };

    renderFeedbackMsg = (errorMsg) => {
        if (!errorMsg) {
            return message.success(
                "You have successfully deleted this record."
            );
        } else {
            return message.error(
                "Something went wrong... Please try again later."
            );
        }
    };

    render() {
        const { data, isLoading, isIntegrationLoading, error, userData } = this.props;
        const { name } = userData;
        const unauthorized = error === "unauthorized" || !name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        const isActionAccessible = getIsPageActionAccessible(this.pageKey, userData.privileges);

        return (
            <div className="integration-domains-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    columns={lookup.columns}
                    pathForEdit={isActionAccessible && "/integration-domain/"}
                    handleDelete={isActionAccessible && this.handleDelete}
                    dataSource={this.formatData(data)}
                    loading={(isLoading || isIntegrationLoading)}
                />
            </div>
        );
    }
}

IntegrationDomains.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isIntegrationLoading: PropTypes.bool,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchIntegrationDomainsListRequest: PropTypes.func.isRequired,
    deleteIntegrationDomainRequest: PropTypes.func.isRequired
};

export default IntegrationDomains;

import { connect } from "react-redux";

import {
    analyticsReport
} from "./actions";

import Analytics from "./Analytics";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Analytics
});

export const AnalyticsPage = connect(
    mapStateToProps,
    {
      analyticsReport
    }
)(Analytics);

import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";

import Info from "./Info";

const InfoGroup = ({ isRecordLoading, recordCount }) => {
  let totalCount = 0;
  let resultCount = { ...recordCount };
  Object.keys(resultCount).forEach((type) => (totalCount += resultCount[type]));

  return (
    <div className="info-wrapper">
      {!!totalCount && (
        <>
          <h3>Filtered results: </h3>
          {isRecordLoading ? <Spin /> : Object.keys(resultCount).map((type) => <Info type={type} key={type} count={resultCount[type]} />)}
        </>
      )}
    </div>
  );
};

InfoGroup.propTypes = {
  isRecordLoading: PropTypes.bool.isRequired,
  recordCount: PropTypes.object.isRequired
};

export default InfoGroup;

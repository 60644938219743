import React, {Component} from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Upload, Icon, Switch, Radio, InputNumber, Typography } from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";
import { TextEditor } from "../../components";
import lookup from "./constants.js";
import { uploadUtility } from "../../utils";

const { Item: FormItem } = Form;

class BannerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false
        }
        this.imageResolutions = lookup.banner.imageResolutions || [];
        this.defaultTagValue = lookup.banner.defaultTag[props.type] || "";
        this.timestamp = `${Math.floor(0x100000000 * Math.random())}_${Date.now().toString()}`;
    }

    componentDidMount() {
        const banner = this.props.initialValues.banner || {};

        if (this.props.type === "guide") {
            const values = {};
            if (banner.episode) {
                values.assetType = "episode";
            } else {
                values.assetType = "pdf";
            }

            this.props.form.setFieldsValue(values);
        }
    }

    handleAssetTypeChange = (e) => {
        e.preventDefault();
        this.props.form.setFieldsValue({ tag: lookup.banner.defaultTag[ (e.target.value === "episode" ? "guide_episode" : "guide") ] });
    }

    handleFormSubmission = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (error) {
                return;
            }

            const { type, initialValues={} } = this.props;
            const banner = initialValues.banner || {};
            const { tag, title, desc, image={}, createSfdcLead, pdf, video, assetType, episodeId, duration } = values;
            let bannerObj = { tag, title, desc, leadSfdcCampaign:banner.leadSfdcCampaign };
            let imagesWithUrls = {};

            for (const resolution in image) {

                if (image.hasOwnProperty(resolution)) {
                    imagesWithUrls[resolution] = uploadUtility.singleFile.submittedValue(image[resolution]);
                }
            }

            if (type === "webinar" || type === "meeting") {
                bannerObj = { ...bannerObj, createSfdcLead};

                if ( !this.props.additional.isFutureDatedEvent ) {
                    bannerObj = { ...bannerObj, video };
                }

            } else if ( type === "guide" && assetType === "episode" ) {
                const episode = { id: episodeId, duration };
                bannerObj.episode = episode;
            } else {
                bannerObj = { ...bannerObj, pdf: uploadUtility.singleFile.submittedValue(pdf) };
            }

            this.props.handleStepFormSubmission({ banner: { ...bannerObj } });
        })
    }

    handleDescriptionChange = (html, name) => {
        this.props.form.setFieldsValue({ desc: html });
    };

    renderEpisodeIDExtra = () => {
        return (
            <React.Fragment>
                <Typography.Paragraph>Episode ID can be fetched using any of the following ways:</Typography.Paragraph>
                <ul>
                    <li><Typography.Text strong>From Episode URL</Typography.Text>: <Typography.Text>https://www.buzzsprout.com/1728881/episodes/<Typography.Text mark>episodeID</Typography.Text></Typography.Text></li>
                    <li><Typography.Text strong>From Email Link to Episode</Typography.Text>: <Typography.Text>https://www.buzzsprout.com/1728881/<Typography.Text mark>episodeID</Typography.Text></Typography.Text></li>
                    <li><Typography.Text strong>From Embed this ONE Episode</Typography.Text>: <div style={{ padding: "10px", borderRadius: "5px", border: "1px dotted rgb(217 217 217)", fontSize: "12px", margin: "10px 0 0" }}><code>&lt;div id=&quot;buzzsprout-player-<Typography.Text mark>episodeID</Typography.Text>&quot;&gt;&lt;/div&gt;<br/>&lt;script src=&quot;https://www.buzzsprout.com/1728881/<Typography.Text mark>episodeID</Typography.Text>-podcast-episode-title.js?container_id=buzzsprout-player-<Typography.Text mark>episodeID</Typography.Text>&amp;player=small&quot; type=&quot;text/javascript&quot; charset=&quot;utf-8&quot;&gt;&lt;/script&gt;</code></div></li>
                </ul>
            </React.Fragment>
        );
    };

    handleUpload = (e) => {
        if (e.file.status === "uploading") {
            this.setState(() => ({uploading: true}));
        } else if (["error", "done"].includes(e.file.status)) {
            this.setState(() => ({uploading: false}));
        }
    }
    
    render() {
        const { initialValues, type } = this.props;
        const { webinar={}, meeting={}, card={}, banner = {} } = initialValues;
        const { eventTitle="", eventDescription="" } = webinar;
        const { cardTitle=eventTitle, cardDesc=eventDescription } = card;
        const { tag = this.defaultTagValue, title=cardTitle, desc=cardDesc, createSfdcLead =  true, leadSfdcCampaign="", image = {}, pdf, video={}, episode={} } =  banner;
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const formValues = getFieldsValue();
        const isEpisodeAsset = formValues.assetType === "episode";
        const isPDFAsset = (formValues.assetType === "pdf" || ["ebook", "whitepaper"].indexOf(type) !== -1);

        return (
            <>
                <Form style={{}} onSubmit={this.handleFormSubmission}>
                    <FormItem {...formItemLayout} label={"Tag"} extra={<p>Tag related to resources which help to highlight them. Ex: <strong>Free Webinar</strong>, <strong>Free Guide</strong></p>}>
                        {
                            getFieldDecorator("tag", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable tag related to resource"
                                }],
                                initialValue: tag
                            }) (
                                <Input placeholder={"Resource tag"}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Title"}>
                        {
                            getFieldDecorator("title", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable title to banner"
                                }],
                                initialValue: title
                            }) (
                                <Input placeholder={"Banner title"}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Description"}>
                        {
                            getFieldDecorator("desc", {
                                rules: [{
                                    required: true,
                                    message: "Provide some description related to banner"
                                }],
                                initialValue: desc
                            }) (
                                <TextEditor placeholder="" name="desc" handleEditorChange={this.handleDescriptionChange} />
                            )
                        }
                    </FormItem>
                    {
                        type === "webinar" && (
                            <>
                                <FormItem {...formItemLayout} label={"Create Salesforce Lead"} extra={<p>On the <b>lead form</b> submission, if lead on salesforce needs to be created then toggle it to <b>Yes</b>, otherwise toggle it to <b>No</b>.</p>} style={{display: "none"}}>
                                    {
                                        getFieldDecorator("createSfdcLead", {
                                            rules: [{
                                                "required": false,
                                            }],
                                            valuePropName: "checked",
                                            initialValue: createSfdcLead
                                        })(
                                            <Switch checkedChildren={"Yes"} unCheckedChildren={"No"} style={{width: "60px"}}/>
                                        )
                                    }
                                </FormItem>
                                {
                                    !this.props.additional.isFutureDatedEvent && (
                                        <>
                                            <FormItem {...formItemLayout} label={"Video Thumbnail URL"}>
                                                {
                                                    getFieldDecorator("video[imgUrl]", {
                                                        rules: [{
                                                            required: true,
                                                            message: "Provide a intro video thmbnail image URL"
                                                        }, {
                                                            type: "url",
                                                            message: "Provide a valid URL"
                                                        }],
                                                        initialValue: video.imgUrl
                                                    }) (
                                                        <Input placeholder={"Intro video thumbnail image url"} />
                                                    )
                                                }
                                            </FormItem>
                                            <FormItem {...formItemLayout} label={"Webinar Video Source"} hidden={true}>
                                                {
                                                    getFieldDecorator("video[videoSource]", {
                                                        initialValue: video.videoSource || "youtube"
                                                    }) (
                                                        <Input type="hidden" />
                                                    )
                                                }
                                            </FormItem>
                                            <FormItem {...formItemLayout} label={"Video Embed Link"}>
                                                {
                                                    getFieldDecorator("video[embedLink]", {
                                                        rules: [{
                                                            required: true,
                                                            message: "Provide a valid embed code"
                                                        }, {
                                                            pattern: "^[a-zA-Z0-9\\-\\_\\?\\=]+$",
                                                            message: "Please provide a valid embed code"
                                                        }],
                                                        initialValue: video.embedLink
                                                    }) (
                                                        <Input placeholder={"Intro video embed code"} addonBefore={ "https://www.youtube.com/embed/" }/>
                                                    )
                                                }
                                            </FormItem>
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                    {
                        type === "guide" && (
                            <FormItem {...formItemLayout} label="Asset Type">
                                {
                                    getFieldDecorator("assetType", {
                                        rules: [{
                                            required: true,
                                            message: "Provide a specific asset type"
                                        }],
                                        initialValue: formValues.assetType
                                    }) (
                                        <Radio.Group onChange={this.handleAssetTypeChange}>
                                            <Radio value="episode">Episode</Radio>
                                            <Radio value="pdf">PDF</Radio>
                                        </Radio.Group>
                                    )
                                }
                            </FormItem>
                        )
                    }
                    {
                        isPDFAsset && (
                            <FormItem {...formItemLayout} label={"PDF"}>
                                {
                                    getFieldDecorator(`pdf`, {
                                        getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                        rules: [{
                                            required: true,
                                            message: `Upload a PDF related to this ${type}`
                                        }, {
                                            validator: uploadUtility.singleFile.validator
                                        }],
                                        valuePropName: "fileList",
                                        initialValue: pdf && [{
                                            uid: "-1",
                                            name: title,
                                            status: "done",
                                            url: pdf
                                        }]
                                    })(
                                        <Upload
                                            name={"files"}
                                            accept={"application/pdf"}
                                            action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=pdf&timestamp=${Date.now().toString()}`}
                                            withCredentials
                                            listType="picture"
                                            onChange={this.handleUpload}
                                            >
                                            <Button>
                                                <Icon type="upload"/> Upload PDF
                                            </Button>
                                        </Upload>
                                    ) 
                                }
                            </FormItem>
                        )
                    }
                    {
                        isEpisodeAsset && (
                            <>
                                <FormItem { ...formItemLayout } label="Episode ID" extra={this.renderEpisodeIDExtra()}>
                                    {
                                        getFieldDecorator("episodeId", {
                                            rules: [{
                                                required: true,
                                                message: "Provide episode ID"
                                            }, {
                                                pattern: "^[0-9]+$",
                                                message: "Episode ID should be numeric"
                                            }],
                                            initialValue: episode.id
                                        }) (
                                            <Input placeholder="Episode ID" style={{ width: "50%" }} />
                                        )
                                    }
                                </FormItem>
                                <FormItem { ...formItemLayout } label="Duration" extra={<span>Duration should be in <strong>minute</strong>.</span>}>
                                    {
                                        getFieldDecorator("duration", {
                                            rules: [{
                                                required: true,
                                                message: "Provide duration of this episode"
                                            }],
                                            initialValue: episode.duration
                                        }) (
                                            <InputNumber placeholder="Episode duration" min={0} style={{ width: "50%" }} />
                                        )
                                    }
                                </FormItem>
                            </>
                        )
                    }
                    <FormItem {...formButtonLayout}>
                        <Button type={"primary"} htmlType={"submit"} disabled={this.state.uploading}>
                            Next<Icon type="right" />
                        </Button>
                    </FormItem>
                </Form>
            </>
        );
    }
}

const Banner = Form.create()(BannerForm);

Banner.propTypes = {
    userData: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    additional: PropTypes.object,
    handleStepFormSubmission: PropTypes.func.isRequired
};

export default Banner;
import { connect } from "react-redux";

import {
    fetchIntegrationsListRequest,
    fetchIntegrationRequest,
    addIntegrationRequest,
    updateIntegrationRequest,
    deleteIntegrationRequest,
    downloadIntegrationCsvRequest
} from "./actions";

import { fetchIntegrationDomainsListRequest } from "../IntegrationDomains/actions";
import { fetchCategoriesListRequest } from "../Effects/actions"

import Integrations from "./Integrations";
import UpdateIntegration from "./UpdateIntegration";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Integrations,
    categoryData: state.Effects.categoryData,
    domains: state.IntegrationDomains.data
});

export const IntegrationsPage = connect(
    mapStateToProps,
    { fetchIntegrationsListRequest, deleteIntegrationRequest, fetchCategoriesListRequest, downloadIntegrationCsvRequest, updateIntegrationRequest }
)(Integrations);

export const UpdateIntegrationPage = connect(
    mapStateToProps,
    {
        fetchIntegrationRequest,
        addIntegrationRequest,
        updateIntegrationRequest,
        fetchIntegrationDomainsListRequest
    }
)(UpdateIntegration);

import { connect } from "react-redux";
import { updateUserRequest, updateUsersRequest, getSignatureAssetRequest, createSignatureAssetRequest, deleteSignatureAssetRequest, getUserCalendarRequest, getUserByIdRequest } from "./action";
import UserSignature from "./UserSignature";
import MultipleUserSignature from "./MultipleUserSignature";
import ManageBanner from "./ManageBanner";

const mapStateToProps = (state) => {
    const { id, name, privileges, role } = state.User.userData || {};
    return {
        userData: { id, name, privileges, role },
        ...state.Signature
    }
};

export const UserSignaturePage = connect(
    mapStateToProps,
    { updateUserRequest, getSignatureAssetRequest, getUserCalendarRequest, getUserByIdRequest }
)( UserSignature );

export const MultipleUserSignaturePage = connect(
    mapStateToProps,
    { updateUsersRequest, getSignatureAssetRequest, createSignatureAssetRequest, deleteSignatureAssetRequest }
)( MultipleUserSignature );

export const ManageBannerPage = connect(
    mapStateToProps,
    { getSignatureAssetRequest, createSignatureAssetRequest, deleteSignatureAssetRequest }
)( ManageBanner );
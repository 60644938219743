import React, { Component } from "react";
import PropTypes from "prop-types";

import GoogleMap from "./components/googleMap/GoogleMap";
import Panel from "./components/panel/Panel";
import LegendGroup from "./components/legend/LegendGroup";
import InfoGroup from "./components/info/InfoGroup";
import Table from "./components/table/Table";
import { RedirectWrapper } from "../../components";
import { getApiParamsFromFilterObj, getProfileParams, extractParamsFromUrl } from "./utils";
import { UPPER_LIMIT } from "./constants";
import "./ProspectorWrapper.scss";

class ProspectorWrapper extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const [filterObj, targetProfileId, targetProfileType] = extractParamsFromUrl(location.search);
    this.state = {
      filterObj,
      targetProfileId,
      targetProfileType
    };
  }

  componentDidMount() {
    const { industryList, cityList } = this.props;
    const { targetProfileId } = this.state;

    if (targetProfileId !== -1) this.fetchTargetProfile();
    if (industryList.length && cityList.length) this.fetchRecords();
  }

  componentDidUpdate(prevProps) {
    const { industryList, cityList } = this.props;
    if (!industryList.length || !cityList.length) return;
    if (prevProps.industryList.length !== industryList.length || prevProps.cityList.length !== cityList.length)
      this.fetchRecords();
  }

  fetchTargetProfile = () => {
    const { targetProfileId, targetProfileType } = this.state;
    this.props.fetchTargetProfileRequest(getProfileParams(targetProfileId, targetProfileType));
  };

  fetchRecords = () => {
    const { filterObj } = this.state;
    let filterParams = {};
    if (!filterObj.type) filterParams = { type: ["prospector"] };
    else filterParams = getApiParamsFromFilterObj(filterObj, this.props);
    this.props.fetchRecordsRequest(filterParams);
  };

  updateFilter = (filterObj) => {
    this.setState({ filterObj }, this.fetchRecords);
  };

  renderInfoSection = () => {
    const { isRecordLoading, recordCount } = this.props;
    return <InfoGroup isRecordLoading={isRecordLoading} recordCount={recordCount} />;
  };

  renderMap = () => {
    const { records, targetProfile, isRecordLoading, isProfileLoading, industryToSubs } = this.props;
    const { filterObj, targetProfileId } = this.state;
    return (
      <GoogleMap
        data={records}
        hasTarget={!!targetProfileId}
        targetProfile={targetProfile}
        filterObj={filterObj}
        isRecordLoading={isRecordLoading}
        isProfileLoading={isProfileLoading}
        industryToSubs={industryToSubs}
        upperLimit={UPPER_LIMIT}
      />
    );
  };

  renderLegend = () => {
    const { recordCount, targetProfile, isRecordLoading } = this.props;
    const { targetProfileId } = this.state;
    return (
      <LegendGroup
        recordCount={recordCount}
        targetProfile={targetProfile}
        isRecordLoading={isRecordLoading}
        targetProfileId={targetProfileId}
      />
    );
  };

  renderTable = () => {
    const { records, targetProfile, recordCount, isRecordLoading, industryToSubs } = this.props;
    if (!recordCount.customer) return null;

    const tableData = records.filter((record) => record.type === "customer");
    const profileData = targetProfile && targetProfile.type === "customer" ? targetProfile : null;
    return <Table isLoading={isRecordLoading} profileData={profileData} data={tableData} industryToSubs={industryToSubs} />;
  };

  renderPanel = () => {
    const { filterObj } = this.state;
    return <Panel initialFilter={filterObj} updateFilter={this.updateFilter} />;
  }

  render() {
    const { recordError, userData } = this.props;
    const unauthorized = recordError.error === "unauthorized" || !userData.name;
    if (unauthorized) {
      return (
        <RedirectWrapper
          location={this.props.location}
          state={{
            type: recordError.error === "unauthorized" ? "unauthorized" : "login"
          }}
        />
      );
    }

    return (
      <div className="prospector-root">
        <div className="col-wrapper">
          <div className="map-wrapper">
            {this.renderInfoSection()}
            {this.renderMap()}
            {this.renderLegend()}
            {this.renderTable()}
          </div>
          <div className="panel-wrapper">
            {this.renderPanel()}
          </div>
        </div>
      </div>
    );
  }
}

ProspectorWrapper.propTypes = {
  records: PropTypes.array.isRequired,
  recordError: PropTypes.object.isRequired,
  isRecordLoading: PropTypes.bool.isRequired,
  targetProfile: PropTypes.object.isRequired,
  profileError: PropTypes.object.isRequired,
  industryToSubs: PropTypes.object.isRequired,
  isProfileLoading: PropTypes.bool.isRequired,
  fetchRecordsRequest: PropTypes.func.isRequired,
  fetchTargetProfileRequest: PropTypes.func.isRequired,
  recordCount: PropTypes.object.isRequired
};

export default ProspectorWrapper;

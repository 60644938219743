import { connect } from "react-redux";
import { isEmpty } from "lodash";

import {
    fetchIntegrationDomainsListRequest,
    fetchIntegrationDomainRequest,
    addIntegrationDomainRequest,
    updateIntegrationDomainRequest,
    deleteIntegrationDomainRequest
} from "./actions";

import {
    fetchBirdeyeCategoriesList
} from "../Categories/actions";

import IntegrationDomains from "./IntegrationDomains";
import UpdateIntegrationDomain from "./UpdateIntegrationDomain";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Categories,
    ...state.IntegrationDomains
});

export const getSelectedCategoryLabel = (categories, category) => {
    if (category === "All") return "All";
    if (isEmpty(categories) || !category) return "";
    const selectedCategory = categories.find((categoryObj) => {
        return Number(category) === categoryObj.parentCategory.id;
    });
    return selectedCategory && selectedCategory.parentCategory.name;
};

export const IntegrationDomainsPage = connect(
    mapStateToProps,
    { fetchIntegrationDomainsListRequest, deleteIntegrationDomainRequest, fetchBirdeyeCategoriesList }
)(IntegrationDomains);

export const UpdateIntegrationDomainPage = connect(
    mapStateToProps,
    { fetchIntegrationDomainRequest, addIntegrationDomainRequest, updateIntegrationDomainRequest, fetchBirdeyeCategoriesList }
)(UpdateIntegrationDomain);

import { ScitorAction } from "./action";

export let ScitorState = {
    isLoading: false
};

const scitorReducer = (state = ScitorState, action) => {
    switch(action.type) {
        case ScitorAction.FILE_UPLOAD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ScitorAction.FILE_UPLOAD_SUCCESS:
        case ScitorAction.FILE_UPLOAD_ERROR:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default scitorReducer;
import { takeLatest, call, put } from "redux-saga/effects";
import CategoriesActions, * as actionCreator from "./actions";
import categoriesApi from "./http";

const categoriesSagas = [
    takeLatest( CategoriesActions.FETCH_BIRDEYE_CATEGORY_LIST, fetchBirdeyeCategoriesList),
    takeLatest( CategoriesActions.FETCH_CATEGORIES_LIST_BY_SOURCE, fetchCategoriesListBySource),
    takeLatest(CategoriesActions.UPDATE_CATEGORY_REQUEST, updateCategory),
    takeLatest(CategoriesActions.ADD_BE_SUBCATEGORY, addBeSubCategory),
    takeLatest(CategoriesActions.UPDATE_SIC_NAICS, updateSicNaicsRequest),
    takeLatest(CategoriesActions.ADD_INDUSTRY, addNewIndustry)
];

function* fetchBirdeyeCategoriesList() {
    try {
        const resp = yield call(categoriesApi.getAll);
        yield put(actionCreator.fetchBirdeyeCategoriesListSucess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.categoriesError(e.data.data || { error: "categoriesListNotFound" })
        );
    }
}

function* fetchCategoriesListBySource(action) {
    try {
        const resp = yield call(categoriesApi.getById, action.payload.sourceId);
        yield put(actionCreator.fetchCategoriesListBySourceSucess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.categoriesError(e.data.data || { error: "categoriesListNotFound" })
        );
    }
}

function* updateCategory(action) {
    try {
        const resp = yield call(categoriesApi.update, action.payload.data);
        yield put(actionCreator.updateCategorySuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.categoriesError(e.data.data || { error: "cannotUpdate" }));
        yield call(action.payload.cb, "cannotUpdate");
    }
}

function* addBeSubCategory(action) {
    try {
        const resp = yield call(categoriesApi.addBeSubCategory, action.payload.data);
        yield put(actionCreator.updateCategorySuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.categoriesError(e.data.data || { error: "cannotAdd" }));
        yield call(action.payload.cb, "cannotAdd");
    }
}

function* updateSicNaicsRequest(action) {
    try {
        const resp = yield call(categoriesApi.updateSicNaicsRequest, action.payload.data);
        yield put(actionCreator.updateSicNaicsSuccess(resp));
        if(action.payload.cb){
            yield call(action.payload.cb, {...resp.data});
        }
    } catch (e) {
        yield put(actionCreator.updateSicNaicsError(e.data.data || {error: "cannotAdd"}));
        yield call(action.payload.cb, e);
    }
}

function* addNewIndustry(action) {
    try {
        const resp = yield call(categoriesApi.addNewIndustry, action.payload.data);
        yield put(actionCreator.addIndustrySuccess(resp));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.addIndustryError(e || { error: "cannotAdd" }));
        yield call(action.payload.cb, "cannotAddIndustry");
    }
}

export default categoriesSagas;

export default {
    "column": [
        {
            "title": "Status",
            "key": "status",
            "filters": [{ text: "Upcoming", value: true }, { text: "Past", value: false }],
            "onFilter": (value, record) => record.status === value,
            "dataIndex": "statusLabel",
            "align": "center",
            "width": "15%"
        }, {
            "title": "Organizer",
            "key": "organizerEmailId",
            "searchable": true,
            "width": "25%"
        }, {
            "title": "Attendee",
            "key": "attendeeEmailId",
            "searchable": true,
        }, {
            "title": "Overlook",
            "key": "overlook",
            "align": "center",
            "width": "15%"
        }, {
            "title": "Reschedule link",
            "key": "rescheduleLink",
            "align": "center",
            "width": "15%"
        }, {
            "title": "Schedule Time",
            "key": "startTime",
            "dataIndex": "schedule",
            "align": "center",
            "sortAs": "string",
            "defaultSortOrder": "descend",
            "width": "23%"
        }
    ]
}
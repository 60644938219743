import React, { Component } from "react";
import PropTypes from "prop-types";
import { TableSkeleton, RedirectWrapper } from "../../components";
import { Icon, Tag, Button, Descriptions } from "antd";
import momentTz from "moment-timezone";
import {salesforceBaseUrl} from "../../constants";

import lookup from "./invitation.js";

class CalendarInvitation extends Component {

    componentDidMount() {
        this.props.fetchSalesRepInvitationRequest({pageId: this.props.match.params.pageId});
    }

    formatScheduleTime = (time, timezone) => {
        const 
            {startTimestamp, endTimestamp} = time,
            startDate = momentTz.tz(startTimestamp, timezone).format('MMMM Do YYYY'),
            startTime = momentTz.tz(startTimestamp, timezone).format('h:mm a'),
            endTime = momentTz.tz(endTimestamp, timezone).format('h:mm a');

        return (
            <div className={"session"}>
                <p style={{ fontSize: "small", margin: "0 0 5px" }}><Icon type="calendar"/> {startDate}</p>
                <p style={{ fontSize: "small", margin: "0 0 5px" }}><Icon type="dashboard" /> {startTime} - {endTime}<br/><small style={{ fontSize: "x-small" }}>{momentTz.tz(timezone).format('z')}</small></p>
            </div>
        );
    }

    formattedData = (data) => {
        const momentNow = momentTz.utc(new Date());

        return data.map((v) => {
            const isFuture = (momentTz.utc(v.startTime).diff(momentNow) > 0);
            const isReschedule = v.status === "Rescheduled";

            return {
                ...v,
                status: isFuture,
                statusLabel: (
                    <div>
                        { isReschedule ? <Tag color={"blue"}>Rescheduled</Tag> : isFuture ? <Tag color={"green"}>Upcoming</Tag> : <Tag color={"gold"}>Past</Tag> }
                    </div>
                ),
                rescheduleLink: ( 
                    <div>
                        { v.rescheduleMeetingUrl ? <a style={{width: "100%", display: "inline-block", margin: "5px 0"}} disabled={isReschedule} target={"_blank"} href={v.rescheduleMeetingUrl}>{isReschedule ? "Link expired" : "Link"}</a> : "-"}
                    </div>
                ),
                overlook: (
                    <div>
                        { v.googleInviteLink && <a style={{width: "100%", display: "inline-block", margin: "5px 0"}} target={"_blank"} href={v.googleInviteLink}>Open Invitation</a> }
                    </div>
                ),
                schedule: this.formatScheduleTime({startTimestamp: v.startTime, endTimestamp: v.endTime}, v.timezone)
            }
        });
    }

    renderExpandedRow = (record) => {
        const { leadCampaign, leadSubCampaign, leadCampaignKW, leadSfdcCampaign, additionalAttendees } = record;
        const additionalAttendeesEmails =  additionalAttendees && additionalAttendees.length > 0 ? additionalAttendees.split(',') : [];

        const checkForEmpty = (value) => ( value || "N/A" );

        return (
            <>
                <Descriptions size="small" column={4} layout="vertical" colon={false}>
                    <Descriptions.Item label="Campaign">{ checkForEmpty(leadCampaign) }</Descriptions.Item>
                    <Descriptions.Item label="Sub campaign">{ checkForEmpty(leadSubCampaign) }</Descriptions.Item>
                    <Descriptions.Item label="Campaign keyword">{ checkForEmpty(leadCampaignKW) }</Descriptions.Item>
                    <Descriptions.Item label="Campaign SFDC">{ leadSfdcCampaign ? <a href={`${salesforceBaseUrl}${leadSfdcCampaign}`} target="_blank" rel="noopener noreferrer">{leadSfdcCampaign}</a> : "N/A" }</Descriptions.Item>
                </Descriptions>
                {
                    additionalAttendeesEmails && additionalAttendeesEmails.length > 0 && (
                        <Descriptions size="small" colon={false}>
                            <Descriptions.Item label="Additonal Attendee(s)">{ additionalAttendeesEmails.map((email, i) => <><a key={i} href={`mailto:${ email }`}>{ email }</a>&nbsp;</>) }</Descriptions.Item>
                        </Descriptions>
                    )
                }
                
            </>
        );
    };

    render() {
        const { salesRepInvitation=[], isSalesRepInvitationLoading, userError, userData, match } = this.props;
        const { name } = userData;

        const unauthorized = userError === "unauthorized" || !name;
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div>
                <h2>
                    Invitations{ salesRepInvitation[0] && ` | ${salesRepInvitation[0].pageName}` }
                    <Button style={{ float: "right" }} type={"primary"} onClick={(e) => {e.preventDefault(); window.open(`${process.env.REACT_APP_API_BASE_URL}support/salesRep/invite/download/${match.params.pageId}`)}} disabled={!salesRepInvitation.length}>Download in CSV</Button>
                </h2>
                <TableSkeleton
                    columns = {lookup.column}
                    dataSource={this.formattedData(salesRepInvitation)}
                    loading={isSalesRepInvitationLoading}
                    expandedRowRender={this.renderExpandedRow}
                />
            </div>
        )
    }
}

CalendarInvitation.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    userError: PropTypes.string.isRequired,
    isSalesRepInvitationLoading: PropTypes.bool.isRequired,
    fetchSalesRepInvitationRequest: PropTypes.func.isRequired
};

export default CalendarInvitation;
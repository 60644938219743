import { takeLatest, call, put } from "redux-saga/effects";
import SelfServePanelActions, * as actionCreator from "./actions";
import SelfServePanelAPI from "./http";

const selfServePanelSaga = [
    takeLatest(SelfServePanelActions.FETCH_SELF_SERVE_LIST_REQUEST, reqBusinessDetails),
    takeLatest(SelfServePanelActions.FETCH_SELF_SERVE_APPROVE, reqSelfServeApprove),
    takeLatest(SelfServePanelActions.FETCH_SELF_SERVE_REJECT, reqSelfServeReject),
    takeLatest(SelfServePanelActions.SELF_SERVE_CRM_SAVE_REQUEST, saveSelfServeCRMDetails),
    takeLatest(SelfServePanelActions.FETCH_REQUESTED_CRM_LIST_REQUEST, requestedCRMIntegrationDetails)
];

function* reqBusinessDetails() {
    try {
        const {data} = yield call(SelfServePanelAPI.reqSelfServeBusinessDetails);
        yield put(actionCreator.reqBusinessDetailsSuccess(data));
    } catch (err) {
        console.log(err.data,"errorBusinessDetails")
        yield put(actionCreator.reqBusinessDetailsError(err.data.data || {
            "code": 9000,
            "message": "INTERNAL SERVER ERROR"
        }));
    }
}

function* reqSelfServeApprove(action) {
    try {

        const crmSelfServeInfo = {crmName: action.payload.data.crmName, businessNum: action.payload.data.businessNum};
        const {data} = yield call(SelfServePanelAPI.reqIntegrationSelfServeCRMDetails, crmSelfServeInfo);
        yield call(action.payload.cb, data || "Done");
        yield put(actionCreator.reqSelfServeApproveSuccess(data));
    } catch (err) {
        console.log(err,"errorBusinessDetails")
        yield put(actionCreator.reqSelfServeApproveError(err || {
            "code": 9000,
            "message": "INTERNAL SERVER ERROR"
        }));
    }
}

function* reqSelfServeReject(action) {
    try {
        const crmSelfServeInfo = {crmName: action.payload.data.crmName, businessNum: action.payload.data.businessNum};
        const {data} = yield call(SelfServePanelAPI.reqIntegrationSelfServeCRMDetails, crmSelfServeInfo);
        yield put(actionCreator.reqSelfServeRejectSuccess(data));
        yield call(action.payload.cb, data || "Done");
    } catch (err) {
        console.log(err, "errorBusinessDetails")
        yield put(actionCreator.reqSelfServeRejectError(err || {
            "code": 9000,
            "message": "INTERNAL SERVER ERROR"
        }));
        yield call(action.payload.cb, err.data || {});
    }
}

function* saveSelfServeCRMDetails(action) {
    try {
        const {data} = yield call(SelfServePanelAPI.saveIntegrationSelfServeCRMDetails, action.payload.data);
        yield put(actionCreator.reqSelfServeCRMSaveSuccess(data));
        yield call(action.payload.cb, data || {});
    } catch (err) {
        console.log(err.data,"errorBusinessDetails");
        yield put(actionCreator.reqSelfServeCRMSaveError(err.data || {
            "code": 9000,
            "message": "INTERNAL SERVER ERROR"
        }));
        yield call(action.payload.cb, err.data || {});
    }
}

function* requestedCRMIntegrationDetails() {
    try {
        const resp = yield call(SelfServePanelAPI.requesedCRMDetails);
        yield put(actionCreator.requestedCRMIntegrationSuccess(resp.data));
    } catch (err) {
        console.log(err, "error requestedCRMIntegrationDetails");
        yield put(actionCreator.requestedCRMIntegrationError(err.data.data || {
            "code": 9000,
            "message": "INTERNAL SERVER ERROR"
        }));
    }
}

export default selfServePanelSaga;
import { connect } from "react-redux";

import {
    loginRequest,
    logoutRequest
} from "../../pages/Users/actions";

import UserWidget from "./UserWidget";

const mapStateToProps = (state) => ({
    ...state.User
});

export default connect(
    mapStateToProps,
    { loginRequest, logoutRequest }
)(UserWidget);

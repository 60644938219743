import React, { Component } from "react";
import { Button, Modal, Form, Input, Row, Col, Popconfirm, message, Spin, Select, Icon } from "antd";
import { TableSkeleton } from "../../../components";
import { findIndex, isEmpty } from "lodash";
import lookup from "../aggregation.json";

const { Option } = Select;

class AggregationSource extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            isLoading:false,
            aggregations: []
        }
    }

    componentDidMount(){
        const { aggregations } = this.props;
        this.setState({aggregations: aggregations});
    }

    formatData = (data) => {
        if(data){
            return data.map((record, i) => {
                return {
                    ...record,
                    key: `${record.sourceName}-${i}` ,
                    id: record.id,
                    sourceUrlLink : (<a href={record.sourceUrl} target="_blank">{record.sourceUrl}</a>),
                    customAction: (
                        <div>
                            <Popconfirm title="Are you sure delete this?" onConfirm={() => this.handleDelete(record)} okText="Yes" cancelText="No"><a href="#">Delete</a></Popconfirm><br/>
                            <Popconfirm title="Are you sure to reaggregate this?" onConfirm={() => this.handleReaggregate(record)} okText="Yes" cancelText="No"><a href="#">Reaggregate</a></Popconfirm>
                        </div>
                    )
                };
            });
        } else {
            return;
        }
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleAdd = () => {
        this.props.form.validateFieldsAndScroll(["sourceId", "url"], (err, values) => {
            if (!err) {
                const { businessInfo, sources } = this.props;
                const { businessId } = businessInfo;
                const formData = this.props.form.getFieldsValue();
                const valueIndex = findIndex(sources, function(src) {
                    return src.id == formData.sourceId;
                });
                formData.sourceAlias = sources[valueIndex].alias;
                this.props.addAggregationRequest( { businessId: businessId, data: formData, cb: this.addAggregationCallBack } );
            }
        });
    };

    addAggregationCallBack = (error) => {
        if(!error){
            this.props.form.resetFields();
            this.setState({ visible: false });
            message.success('Source added successfully. Refreshing list... please wait for 10 secs!', 5);
            this.refreshAggregation();
        } else {
            message.error('Error occur, Try again or contact growth@birdeye.com');
        }
    };

    handleDelete = (data) => {
        const { businessInfo } = this.props;
        const { businessId } = businessInfo;
        this.setState({ isLoading: true });
        this.props.deleteAggregationRequest({ businessId: businessId, aggregationId: data.id, cb: this.deleteCallBack });
    };

    handleReaggregate = (data) => {
        const aggregationData = data.id;
        const aggregationId = parseInt(aggregationData.slice(0, -4));
        const { businessInfo } = this.props;
        const { businessId } = businessInfo;
        this.setState({ isLoading: true });
        this.props.reaggregationRequest({ businessAggregationId: aggregationId, businessId: businessId, requestType: "BUSINESS_AGGREGATION_LEVEL", syncType:"SYNC", cb: this.reaggregationCallBack });
    };

    deleteCallBack = (error) => {
        if(!error){
            this.refreshAggregation();
        } else {
            this.setState({ isLoading: false });
            message.error('Error occur, Try again or contact growth@birdeye.com');
        }
    }

    reaggregationCallBack = (response) => {
        this.setState({ isLoading: false });
        if(response.status && response.status === 'success'){
            this.setState({ isLoading: false });
            return Modal.success({
                title: response.details,
                maskClosable: false,
                okText: this.refreshAggregation()
            });
        } else {
            this.setState({ isLoading: false });
            if(isEmpty(response.data)){
                return Modal.error({
                    title: `Something went wrong.`,
                    content: "Please try again",
                    maskClosable: true,
                    okText: "Close"
                });
            } else {
                return Modal.error({
                    title: `Something went wrong.`,
                    content: response.data,
                    maskClosable: true,
                    okText: "Close"
                });
            }
        }
    }

    refreshAggregation = () => {
        this.setState({ isLoading: true });
        const { businessInfo } = this.props;
        const { businessId } = businessInfo;
        setTimeout(() => {
            this.props.fetchAggregationRequest( { businessId: businessId ,  cb: this.aggregationCallBack });
        }, 2000);
    }

    aggregationCallBack = (response) => {
        if(!response){
            const { aggregations } = this.props;
            this.setState({ isLoading: false, aggregations: aggregations});
        } else {
            message.error({ content: 'Error Occurred'});
        }
    };


    showAddForm = () => {
        this.setState({ visible: true });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { isLoading, aggregations } = this.state;
        const { sources } = this.props;

        return (
            <div>
                { isLoading &&
                <div className="spin-overlay">
                    <Spin size="large" />
                </div>
                }
                <div className="">
                    <Row>
                        <Col span={4} offset={20}>
                            <Button type="primary" onClick={this.showAddForm}>
                                Add Source
                            </Button>
                            <Button style={{ marginTop: 8 }} type="primary" onClick={this.refreshAggregation}>
                                <Icon type="sync" /> Refresh
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <TableSkeleton
                                columns={lookup.columns}
                                dataSource={this.formatData(aggregations)}
                            />
                        </Col>
                    </Row>
                </div>
                <Modal
                    visible={this.state.visible}
                    title="Add User"
                    okText="Add"
                    onCancel={this.handleCancel}
                    onOk={this.handleAdd}
                >
                    <Form layout="vertical">
                        <Form.Item label="Source">
                        {getFieldDecorator('sourceId', {
                            rules: [{ required: true, message: 'Select source' }],
                            initialValue: null
                        })(
                            <Select showSearch optionFilterProp="label">
                                {sources.map((source) => (
                                    <Option key={source.id} label={source.name} value={source.id}>{source.name}</Option>
                                ))}
                            </Select>
                        )}
                        </Form.Item>
                        <Form.Item label="Url">
                            {getFieldDecorator('url', {
                                rules: [{ required: true, message: 'Provide source url' }],
                                initialValue: null
                            })(<Input />)}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default AggregationSource;
import { Modal } from "antd";
import { beAPIResource } from "../../utils/apiHelper";

export const addNewComponent = async (payload) => {
    try {
        await beAPIResource.post(`support/website/content/page`, payload);
        Modal.success({
            title: "Component has been added!",
            okText: "Go to Components table",
            maskClosable: true,
            onOk() {
                window.location.href =  `/#/website-content/update/${payload.page_id}`;
            }
        });
    } catch (error) {
        Modal.error({
            title: "Error",
            okText: "Return",
            content: "Please verify the data and try again..."
        });
    }
}

export const updateComponent = async(componentId, data, pageId) => {
    try {
        await beAPIResource.put(`support/website/content/page/${componentId}`, {data});
        Modal.success({
            title: "Component has been updated!",
            okText: "Go to Components table",
            maskClosable: true,
            onOk() {
                window.location.href =  `/#/website-content/update/${pageId}`;
            }
        });
    } catch (error) {
        Modal.error({
            title: "Error",
            okText: "Return",
            content: "Please verify the data and try again..."
        });
    }
}
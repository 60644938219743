import React, { Component } from "react";
import PropTypes from "prop-types";
import { pick, includes, isEmpty } from "lodash";
import { Form, Input, Select, Button, Alert, Spin, Modal } from "antd";

// import PermissionTree, { getPermissionMappedValues, getTabPermissionNames, getOtherPermissionNames, getAllTabPermissionNames, getOtherPermissionSelectList } from "./PermissionTree";
import { RedirectWrapper } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";

import PermissionSliderInput from "./PermissionSliderInput";
import { getAllTabPermission, getTabPermission, getOtherPermission, getOtherPermissionSelectList, getPermissions } from "./utils";

const Option = Select.Option;
const FormItem = Form.Item;

class UserRoleForm extends Component {
    initialData = {
        name: "",
        tabPermissions: [],
        otherPermissions: [],
    };

    state = {
        data: this.initialData,
        formError: "",
        isEditPage: !!this.props.match.params.id
    };

    componentDidMount() {
        const { match, fetchRoleRequest, fetchPermissionsList } = this.props;

        if (match.params.id) fetchRoleRequest({ id: match.params.id });
        fetchPermissionsList();
    }

    componentDidUpdate(prevProps) {
        if (this.props.roleData.id !== prevProps.roleData.id) {
            this.setState({ data: this.mapDataToForm(this.props.roleData) });
        }
    }

    mapDataToForm = (rawData) => {
        const allocatedPermission = rawData.privileges ? rawData.privileges.split(",").map(p => p.trim()) : [];
        const data = {};
        data.id = rawData.id; data.name = rawData.name;
        data.tabPermissions = getTabPermission(allocatedPermission);
        data.otherPermissions = getOtherPermission(allocatedPermission);
        return data;
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.handleFieldChange(name, value);
    };

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        this.setState({ data, formError: "" });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const fieldsToBeValidated = pick(this.state.data, [
            "name",
            "tabPermissions"
        ]);

        if (includes(fieldsToBeValidated, "") || (!this.state.data.tabPermissions.length)) {
            this.setState({ formError: "Role name and Tab Permissions are manadatory fields" });
            return;
        }

        let data = {
            name: this.state.data.name,
            permissions: this.state.data.tabPermissions
        };

        if (!isEmpty(this.state.data.otherPermissions)) data.permissions = data.permissions.concat(this.state.data.otherPermissions);

        data.permissions = getPermissions(data.permissions, this.props.permissionsList);

        if (this.state.isEditPage) {
            data.id = this.state.data.id;
            this.props.updateRoleRequest({ data, cb: this.renderFeedback });
        } else {
            this.props.addRoleRequest({ data, cb: this.renderFeedback });
        }
    };

    renderFeedback = (userErrorType) => {
        const gotToRolesPage = () => this.props.history.push("/user-roles");
        if (!userErrorType) {
            return Modal.success({
                title: `Successfully ${ this.state.isEditPage ? "updated" : "added" } this role`,
                content: `Check the ${ this.state.isEditPage ? "updated" : "new" } record in role list page`,
                okText: "Go to role list page",
                maskClosable: true,
                onOk() {
                    gotToRolesPage();
                }
            });
        } else {
            return Modal.error({
                title: `Failed to ${ this.state.isEditPage ? "update" : "add" } this role`,
                content: `Something went wrong while ${ this.state.isEditPage ? "updating" : "adding" } this role.`
            });
        }
    };

    renderFormBlock = () => {
        const {
            name,
            tabPermissions,
            otherPermissions,
        } = this.state.data;

        const { permissionsList } = this.props;
        const otherPermission = getOtherPermissionSelectList(permissionsList);
        if (this.state.isEditPage && this.props.userError === "userNotFound") {
            return <Alert message="User not found" type="userError" showIcon />;
        } else {
            return (
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Role Name">
                        <Input
                            name="name"
                            value={name}
                            placeholder="e.g Admin"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Tab Permissions">
                        <PermissionSliderInput handleChange={this.handleFieldChange} permissions={tabPermissions}/>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Other Permissions" extra="These are set of permissions which are independent of tabs.">
                        <Select
                            value={otherPermissions}
                            mode="multiple"
                            placeholder="Other Permissions"
                            onChange={(v) => this.handleFieldChange("otherPermissions", v)}
                            >
                            {otherPermission.map((permission) => (
                                <Option
                                    key={permission.name}
                                    value={permission.name}>
                                    {permission.label}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    

                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}

                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit">
                            {this.state.isEditPage ? "Save" : "Create"}
                        </Button>
                    </FormItem>
                </Form>
            );
        }
    };

    render() {
        const { userError, loadingRole, loadingPermission } = this.props;
        const unauthorized = userError === "unauthorized";

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="edit-roles-root">
                <h2>{this.state.isEditPage ? "Update" : "Add"} Role</h2>
                {this.state.isEditPage && (loadingRole || loadingPermission) ? (
                    <Spin />
                ) : (
                    this.renderFormBlock()
                )}
            </div>
        );
    }
}

const UpdateUserRole = Form.create()(UserRoleForm);

UpdateUserRole.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    roleData: PropTypes.object.isRequired,
    userError: PropTypes.string.isRequired,
    fetchPermissionsList: PropTypes.func.isRequired,
    fetchRoleRequest: PropTypes.func.isRequired,
    updateRoleRequest: PropTypes.func.isRequired,
    addRoleRequest: PropTypes.func.isRequired
};

export default UpdateUserRole;
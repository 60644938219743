import { takeLatest, call, put } from "redux-saga/effects";
import SignatureAction, * as actionCreator from "./action";
import signatureAPI from "./http";

const signatureSaga = [
    takeLatest(SignatureAction.UPDATE_USER_REQUEST, updateUser),
    takeLatest(SignatureAction.UPDATE_USERS_REQUEST, updateUsers),
    takeLatest(SignatureAction.GET_SIGNATURE_ASSET_REQUEST, getAsset),
    takeLatest(SignatureAction.CREATE_SIGNATURE_ASSET_REQUEST, createAsset),
    takeLatest(SignatureAction.DELETE_SIGNATURE_ASSET_REQUEST, deleteAsset),
    takeLatest(SignatureAction.GET_USER_CALENDAR_REQUEST, getCalendar),
    takeLatest(SignatureAction.GET_USER_BY_ID_REQUEST, getUserById),
];

function* updateUser(action) {
    try {
        const resp = yield call(signatureAPI.userUpdate, action.payload.data);
        yield put(actionCreator.updateUserSuccess());
        yield put(actionCreator.getUserByIdRequest( { id: action.payload.data.userId } ));
        yield call(action.payload.cb, {type: "success", message: "Your signature has been successfully updated. Please refresh your Gmail and verify it."});
    } catch (e) {
        yield put(actionCreator.updateUserError());
        yield call(action.payload.cb, {type: "error", message: "Oops! Something went wrong while updating your signature."});
    }
}

function* updateUsers(action) {
    try {
        const resp = yield call(signatureAPI.usersUpdate, action.payload.data);
        yield put(actionCreator.updateUsersSuccess());
        yield call(action.payload.cb, {type: "success", message: "This banner has been successfully updated in member's signatures."});
    } catch (e) {
        yield put(actionCreator.updateUsersError());
        yield call(action.payload.cb, {type: "error", message: "Oops! Something went wrong while updating signature with this banner."});
    }
}

function* getAsset(action) {
    try {
        const resp = yield call(signatureAPI.getAsset, action.payload.data);
        yield put(actionCreator.getSignatureAssetSuccess({...action.payload.data, data: resp.data}));
    } catch (e) {
        yield put(actionCreator.getSignatureAssetError({...action.payload.data, data: []}));
    }
}

function* createAsset(action) {
    try {
        const resp = yield call(signatureAPI.createAsset, action.payload.data);
        yield put(actionCreator.getSignatureAssetRequest({ data: action.payload.data }));
        yield put(actionCreator.createSignatureAssetSuccess());
        yield call(action.payload.cb, {type: "success", message: "This banner has been successfully uploaded."});
    } catch (e) {
        yield put(actionCreator.createSignatureAssetError());
        yield call(action.payload.cb, {type: "error", message: "Oops! Something went wrong while uploading this banner."});
    }
}

function* deleteAsset(action) {
    try {
        const resp = yield call(signatureAPI.deleteAsset, action.payload.data);
        yield put(actionCreator.getSignatureAssetRequest({ data: action.payload.data }));
        yield put(actionCreator.deleteSignatureAssetSuccess());
        yield call(action.payload.cb, {type: "success", message: "This banner has been successfully deleted."});
    } catch (e) {
        yield put(actionCreator.deleteSignatureAssetError());
        yield call(action.payload.cb, {type: "error", message: "Oops! Something went wrong while deleting this banner."});
    }
}

function* getCalendar(action) {
    try {
        const resp = yield call(signatureAPI.getCalendar, action.payload);
        const calendar = resp.data && resp.data.length ? resp.data[0] : null;
        yield put(actionCreator.getUserCalendarSuccess({ calendar }));
    } catch (e) {
        yield put(actionCreator.getUserCalendarError({ calendar: null }));
    }
}

function* getUserById(action) {
    try {
        let { data } = yield call(signatureAPI.getUserById, action.payload);
        delete data.account_type; delete data.end_time; delete data.fax; delete data.is_active; 
        delete data.privileges; delete data.role; delete data.sfdc_id; delete data.start_time; 
        delete data.timezone;
        yield put(actionCreator.getUserByIdSuccess(data));
    } catch (e) {
        yield put(actionCreator.getUserByIdError({}));
    }
}

export default signatureSaga;
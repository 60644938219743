import { createActionTypes } from "../../utils";

export const FeaturesActions = createActionTypes("feature", [
    "FETCH_FEATURES_LIST_REQUEST",
    "FETCH_FEATURES_LIST_SUCCESS",
    "FETCH_FEATURE_REQUEST",
    "FETCH_FEATURE_SUCCESS",
    "ADD_FEATURE_REQUEST",
    "ADD_FEATURE_SUCCESS",
    "UPDATE_FEATURE_REQUEST",
    "UPDATE_FEATURE_SUCCESS",
    "DELETE_FEATURE_REQUEST",
    "DELETE_FEATURE_SUCCESS",
    "FETCH_BUSINESS_TYPE_REQUEST",
    "FETCH_BUSINESS_TYPE_SUCCESS",
    "FETCH_APPLICABLE_MODULE_REQUEST",
    "FETCH_APPLICABLE_MODULE_SUCCESS",
    "FETCH_RELEASE_TYPE_REQUEST",
    "FETCH_RELEASE_TYPE_SUCCESS",
    "FEATURE_ERROR",
    "NEWSLETTER_LISTING",
    "NEWSLETTER_LISTING_SUCCESS",
    "NEWSLETTER_LISTING_ERROR",
    "NEWSLETTER_PREVIEW_OPEN",
    "NEWSLETTER_PREVIEW_CLOSE",
]);

export const fetchFeaturesListRequest = (obj) => {
    return {
        type: FeaturesActions.FETCH_FEATURES_LIST_REQUEST,
        payload: obj
    };
};

export const fetchFeaturesListSuccess = (obj) => {
    return {
        type: FeaturesActions.FETCH_FEATURES_LIST_SUCCESS,
        payload: obj
    };
};

export const fetchFeatureRequest = (obj) => {
    return {
        type: FeaturesActions.FETCH_FEATURE_REQUEST,
        payload: obj
    };
};

export const fetchFeatureSuccess = (obj) => {
    return {
        type: FeaturesActions.FETCH_FEATURE_SUCCESS,
        payload: obj
    };
};

export const addFeatureRequest = (obj) => {
    return {
        type: FeaturesActions.ADD_FEATURE_REQUEST,
        payload: obj
    };
};

export const addFeatureSuccess = (obj) => {
    return {
        type: FeaturesActions.ADD_FEATURE_SUCCESS,
        payload: obj
    };
};

export const updateFeatureRequest = (obj) => {
    return {
        type: FeaturesActions.UPDATE_FEATURE_REQUEST,
        payload: obj
    };
};

export const updateFeatureSuccess = (obj) => {
    return {
        type: FeaturesActions.UPDATE_FEATURE_SUCCESS,
        payload: obj
    };
};

export const deleteFeatureRequest = (obj) => {
    return {
        type: FeaturesActions.DELETE_FEATURE_REQUEST,
        payload: obj
    };
};

export const deleteFeatureSuccess = (obj) => {
    return {
        type: FeaturesActions.DELETE_FEATURE_SUCCESS,
        payload: obj
    };
};


export const fetchBusinessTypeRequest = (obj) => ({
    type: FeaturesActions.FETCH_BUSINESS_TYPE_REQUEST,
    payload: obj
});

export const fetchBusinessTypeSuccess = (obj) => ({
    type: FeaturesActions.FETCH_BUSINESS_TYPE_SUCCESS,
    payload: obj
});

export const fetchApplicableModuleRequest = (obj) => ({
    type: FeaturesActions.FETCH_APPLICABLE_MODULE_REQUEST,
    payload: obj
});

export const fetchApplicableModuleSuccess = (obj) => ({
    type: FeaturesActions.FETCH_APPLICABLE_MODULE_SUCCESS,
    payload: obj
});

export const fetchReleaseTypesRequest = (obj) => ({
    type: FeaturesActions.FETCH_RELEASE_TYPE_REQUEST,
    payload: obj
});

export const fetchReleaseTypesSuccess = (obj) => ({
    type: FeaturesActions.FETCH_RELEASE_TYPE_SUCCESS,
    payload: obj
});

export const featureError = (obj) => {
    return {
        type: FeaturesActions.FEATURE_ERROR,
        payload: obj
    };
};

export const fetchNewsletterListing = () => ({
    type: FeaturesActions.NEWSLETTER_LISTING
});

export const fetchNewsletterListingSuccess = (obj) => ({
    type: FeaturesActions.NEWSLETTER_LISTING_SUCCESS,
    payload: obj
});

export const fetchNewsletterListingError = () => ({
    type: FeaturesActions.NEWSLETTER_LISTING_ERROR
});

export const openNewsLetterPreview = (obj) => ({
    type: FeaturesActions.NEWSLETTER_PREVIEW_OPEN,
    payload: obj
});

export const closeNewsLetterPreview = (obj) => ({
    type: FeaturesActions.NEWSLETTER_PREVIEW_CLOSE,
});

export default FeaturesActions;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { Icon } from "antd";
import { RedirectWrapper } from "../../components";
class AccessRequestHistory extends Component {

    pageKey = "businessAccessList";

    componentDidMount() {
        const { userData } = this.props;
        this.props.fetchRequestHistoryList(userData.name);
    }

    formatData = (data) => {
        if (isEmpty(data)) return [];
        return data.map((record) => {
            return {
                ...record,
                key: record.business,
                user: record.user,
                business: record.business,
                role: record.role
            };
        });
    };

    render() {
        const { error, userData} = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;
        
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div style={{"position": "absolute", "top": "30%", "left": "40%", "textAlign":"center", "width": "400px", "backgroundColor": "#e6f7ff", "borderRadius":"2rem"}}>
               <h2 style={{"font-size": "1.5rem"}}><Icon type="warning" theme="twoTone" /> Please use Birdeye Assist </h2>
               <p style={{"font-size": "1rem"}}><a href="https://assist.birdeye.com/">Click to visit Birdeye Assist</a></p>
            </div>
        );
    }
}

AccessRequestHistory.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    requestList: PropTypes.object,
    fetchRequestHistoryList: PropTypes.func.isRequired
};

export default AccessRequestHistory;
import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {formButtonLayout, formItemLayout} from "../../constants";
import { RedirectWrapper } from "../../components";
import { Form, Input, Select, Button, Modal, Skeleton, Spin } from "antd";
import lookup from './sources.json';
const Option = Select.Option;
const FormItem = Form.Item;

class AddNewIndustries extends Component {

    state = {
        source: null,
        industry: null,
        beParentCategories: [],
        beParentCategory: "",
        beChildCategories: [],
        beSubCategory: "",
        formError: ""
    };

    componentDidMount() {
        this.props.fetchBirdeyeCategoriesList();
    }

    handleInputChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            this.handleFieldChange(name, value);
        }
    }

    handleFieldChange = (name, value) => {
        let data = { ...this.state };
        data[name] = value;
        this.setState({...data, formError: ""});
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (!error) {
                const selectedSource = lookup.sources.filter(item => {
                    return item.title === values.source;
                })

                this.props.addIndustry({data: { sourceId: selectedSource[0].value, name: values.industry, beParentCategory: values.beParentCategory, beSubCategory: values.beSubCategory }, cb: this.renderFeedback});
            }
        })
    }

    renderFeedback = () => {
        if(this.props.isCreated === true){
            return Modal.success({
                title: `Success`,
                content: "Industry added Successfully",
                maskClosable: true,
                okText: "Close"
            });
        }
        else if(this.props.isDeleted === false){
            return Modal.error({
                title: `Something went wrong.`,
                content: "Industry adding failed",
            });
        }
    };

    handleParentChange = (value, initial = false) => {
        const { categories } = this.props;
        let childCategories = [];

        for (let i = 0; i < categories.length; i++) {
            if (categories[i]['parentCategory']['name'] === value){
                const child = categories[i]['childCategory']
                if(child) {
                    childCategories = child;
                } else {
                    childCategories = [];
                }

            }
        }

        if(childCategories){
            this.setState({ beChildCategories: childCategories, beParentCategory: value});
        }

        if(!initial){
            this.props.form.setFieldsValue({beChildCategories: []});
        }
    }

    handleChildChange = (value) => {
        this.setState({ beSubCategory: value });
    }

    render() {
        const { getFieldDecorator }  = this.props.form;
        const { isLoading, location, error, userData, categories, loading } = this.props;
        const { beChildCategories } = this.state;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={location} />;
        }

        return (
            <div>
                <h2>Add New Industry</h2>
                {
                    loading || isLoading || isEmpty(categories) ? (
                        <Spin />
                    ) : (
                        <Form onSubmit={(e) => this.handleSubmit(e)} >
                            <FormItem {...formItemLayout} label="Source" required={true}>
                                {
                                    getFieldDecorator('source', {
                                        rules: [{ required: true, message: 'Please select source' }]
                                    })(
                                        <Select
                                            showSearch
                                            placeholder="Select Source"
                                            onChange={(value) =>
                                                this.handleFieldChange("source", value)
                                            }>
                                            {lookup.sources && lookup.sources.map((option) => (
                                                <Option key={option.key} value={option.title}>
                                                    {option.title}
                                                </Option>
                                            ))}
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem {...formItemLayout} label="Industry">
                                {
                                    getFieldDecorator('industry', {
                                        rules: [{ required: true, message: 'Please input industry name' }]
                                    })(
                                        <Input
                                            name="industry"
                                            placeholder="Enter Industry Name"
                                            onChange={this.handleInputChange}
                                        />
                                    )
                                }
                            </FormItem>
                            <FormItem {...formItemLayout} label="BE Parent Category">
                                {
                                    getFieldDecorator('beParentCategory', {
                                        rules: [{ required: true, message: 'Please select birdeye Parentcategory' }]
                                    })(
                                        <Select
                                            showSearch
                                            placeholder="Select Parent Category"
                                            onChange={(value) => this.handleParentChange(value)}
                                        >
                                            {categories && categories.map((category, index) =>  (
                                                <Option key={index} value={category.parentCategory.name}>{category.parentCategory.name}</Option>
                                            ))}
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem {...formItemLayout} label="BE Sub Category">
                                {
                                    getFieldDecorator('beSubCategory', {
                                        rules: [{ required: true, message: 'Please select birdeye Subcategory' }]
                                    })(
                                        <Select
                                            showSearch
                                            placeholder="Select Subcategory"
                                            onChange={(value) => this.handleChildChange(value)}
                                        >
                                            {beChildCategories.map((category, index) => (
                                                <Option key={index} value={category.name}>{category.name}</Option>
                                            ))}
                                        </Select>
                                    )
                                }
                            </FormItem>
                            {this.state.formError && (
                                <FormItem {...formButtonLayout}>
                                    <span className="text-error">
                                        {this.state.formError}
                                    </span>
                                </FormItem>
                            )}
                            <FormItem {...formButtonLayout}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </FormItem>

                        </Form>
                    )
                }
            </div>
        );
    }

}

AddNewIndustries.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    userData: PropTypes.object.isRequired,
    fetchBirdeyeCategoriesList: PropTypes.func.isRequired,
};

const AddNewIndustry = Form.create()(AddNewIndustries);

export default AddNewIndustry;
import { takeLatest, call, put } from "redux-saga/effects";
import EffectsActions, * as actionCreator from "./actions";
import effectApi from "./http";

const effectSagas = [
    takeLatest(EffectsActions.FETCH_EFFECTS_LIST_REQUEST, fetchEffectsList),
    takeLatest(EffectsActions.FETCH_EFFECT_REQUEST, fetchEffect),
    takeLatest(EffectsActions.UPDATE_EFFECT_REQUEST, updateEffect),
    takeLatest(EffectsActions.FETCH_CATEGORIES_LIST_REQUEST, fetchCategoriesList),
    takeLatest(EffectsActions.FETCH_CATEGORY_REQUEST, fetchCategory),
    takeLatest(EffectsActions.UPDATE_CATEGORY_REQUEST, updateCategory),
];

function* fetchEffectsList() {
    try {
        const resp = yield call(effectApi.getAll);
        yield put(actionCreator.fetchEffectsListSucess(resp.data.data));
    } catch (e) {
        yield put(
            actionCreator.effectError({ error: "effectsListNotFound" })
        );
    }
}

function* fetchEffect(action) {
    try {
        const resp = yield call(effectApi.getByBusinessNumber, action.payload.businessNumber);
        yield put(actionCreator.fetchEffectSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.effectError({ error: "effectNotFound" }));
    }
}

function* updateEffect(action) {
    try {
        const { data } = action.payload;
        const isDelete = data.isDelete;
        delete data.isDelete;
        let resp;
        if (isDelete) {
            // trying to delete image
            resp = yield call(effectApi.delete, data);
        } else {
            resp = yield call(effectApi.update, data);
        }
        yield put(actionCreator.updateEffectSuccess(resp.data || {}));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.effectError({ error: "cannotUpdate" }));
        yield call(action.payload.cb, "cannotUpdate");
    }
}

function* fetchCategoriesList() {
    try {
        const resp = yield call(effectApi.getIndustries);
        yield put(actionCreator.fetchCategoriesListSucess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.effectError({ error: "effectsCategoryListNotFound" })
        );
    }
}

function* fetchCategory(action) {
    try {
        const resp = yield call(effectApi.getByIndustryId, action.payload.id);
        yield put(actionCreator.fetchCategorySucess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.effectError({ error: "effectsCategoryNotFound" })
        );
    }
}

function* updateCategory(action) {
    try {
        let resp = yield call(effectApi.updateIndustry, action.payload.data);
        yield put(actionCreator.updateEffectSuccess(resp.data || {}));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.effectError({ error: "cannotUpdate" }));
        yield call(action.payload.cb, "cannotUpdate");
    }
}

export default effectSagas;

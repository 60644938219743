import React, { Component } from "react";
import {Redirect} from "react-router-dom";
import {Spin, Table, Tag, Select} from "antd";

class Summary extends Component  {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentWillMount() {
        this.props.reqSummaryPageData();
    }

    getMappedRecord = records => {
        const {all, icp} = records,
            mappedRow = [];

        if (all === undefined || icp === undefined) return [];

        mappedRow.push({label: 'Total Businesses', all: all.totalBusinesses || '-', icp: icp.totalBusinesses || '-'});
        mappedRow.push({label: 'Total Emails', all: all.totalEmails || '-', icp: icp.totalEmails || '-'});
        mappedRow.push({label: 'Total Subscribed Emails', all: all.totalSubscribedEmails || '-', icp: icp.totalSubscribedEmails || '-'});
        mappedRow.push({label: 'Yesterday Added Businesses', all: all.totalBusinessesYesterday || '-', icp: icp.totalBusinessesYesterday || '-'});
        mappedRow.push({label: 'Yesterday Added Emails', all: all.totalEmailsYesterday || '-', icp: icp.totalEmailsYesterday || '-'});

        return mappedRow;
    };

    render() {

        const { error, userData, isLoading, pageData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        if(isLoading) {
            return <Spin />;
        }

        const {summary: thisPageData} = pageData;

        const isTableLoading = thisPageData.isTableLoading;
        const records = this.getMappedRecord(thisPageData.tableContent) || [];
        const formatter = new Intl.NumberFormat('en-US');

        const columns = [
            {
                title: 'Metrics',
                dataIndex: 'label',
                key: 'label',
                width: '50%'
            }, {
                title: 'Overall',
                dataIndex: 'all',
                key: 'value',
                width: '25%',
                render: (value, row) => (row.label === 'Yesterday Added Emails' ? (<a href={"/#/free-prod-dashboard/emails"} title={"Click to view all new created emails"}>{formatter.format(value)}</a>) : formatter.format(value))
            }, {
                title: 'Only ICP',
                dataIndex: 'icp',
                key: 'value',
                width: '25%',
                render: value => (formatter.format(value))
            }
        ];

        return (
            <div>
                <h2>FreeProd Summary</h2>
                <Table className={"ant-table-bordered"} style={{ marginTop: 20 }} size={"medium"} showHeader={true} columns={columns} dataSource={records} loading={isTableLoading} pagination={false} scroll={{y: 500}}/>
            </div>
        );
    }
}

export default Summary;
import { beAPIResource } from "../../utils/apiHelper";

export const FeaturePagesAPI = {
    reqFeaturePagesList: () => beAPIResource.get(`/support/feature-pages/list`),
    reqFeaturePageById: (payload) => beAPIResource.get(`/support/feature-pages/list/${payload.id}`),
    reqAddFeaturePage: (payload) => beAPIResource.post(`/support/feature-pages/new`, payload),
    reqUpdateFeaturePage: (payload) => beAPIResource.post(`/support/feature-pages/update/${payload.id}`, payload),
    reqDeleteFeaturePage: (payload) => beAPIResource.delete(`/support/feature-pages/list`, payload),
};

export default FeaturePagesAPI;
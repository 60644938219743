import { connect } from "react-redux";

import {
    fetchEmailByMonthRequest,
    fetchEmailByTemplateRequest,
    fetchTemplateStatsRequest,
    eraseTemplateStatsRequest,
    fetchEmailVersionsRequest,
    fetchEmailVersionsSuccess,
    fetchEmailVersionsError,
    eraseEmailVersionsRequest
} from "./actions";

import ProductEmails from "./ProductEmails";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.ProductEmails
});

export const ProductEmailsPage = connect(
    mapStateToProps,
    { fetchEmailByMonthRequest, fetchEmailByTemplateRequest, fetchTemplateStatsRequest, eraseTemplateStatsRequest, fetchEmailVersionsRequest, fetchEmailVersionsSuccess, fetchEmailVersionsError, eraseEmailVersionsRequest }
)(ProductEmails);
import React, {Component} from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Upload, Icon } from "antd";
import { isEmpty } from "lodash";
import { formItemLayout, formButtonLayout, genericWebinarImagePath, genericWebinarOGImagePath } from "../../constants";
import { uploadUtility } from "../../utils";

const { Item: FormItem } = Form;

class MetaForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    handleFormSubmission = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (error) {
                return;
            }

            let { title, desc, image, ogTitle, ogDesc, ogImage } = values;
            const { type } = this.props;

            // add defaults in case of webinar and meeting
            if (type === "webinar" || type === "meeting") {
                if (isEmpty(image) || isEmpty(image[0] || !image[0].url)) {
                    image = [{
                        uid: "-1",
                        name: `google-event-image ${title}`,
                        status: "done",
                        url: genericWebinarImagePath
                    }];
                }
                if (isEmpty(ogImage) || isEmpty(ogImage[0] || !ogImage[0].url)) {
                    ogImage = [{
                        uid: "-1",
                        name: `og:image ${title}`,
                        status: "done",
                        url: genericWebinarOGImagePath
                    }];
                }
            }

            this.props.handleStepFormSubmission({meta: { title, description: desc, image: uploadUtility.singleFile.submittedValue(image), og: { title: ogTitle, description: ogDesc, imageUrl: uploadUtility.singleFile.submittedValue(ogImage) } }});
        });
    };

    handleMetaTitleChange = (e) => {
        e.preventDefault();
        this.props.form.setFieldsValue({ ogTitle: e.target.value });
    }

    handleMetaDescChange = (e) => {
        e.preventDefault();
        this.props.form.setFieldsValue({ ogDesc: e.target.value });
    }

    render() {
        const { type } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { eventTitle="", eventDescription="" } = type === "webinar" && this.props.initialValues.webinar ? this.props.initialValues.webinar : (type === "meeting" && this.props.initialValues.meeting ? this.props.initialValues.meeting : {});
        const { cardTitle=eventTitle, cardDesc=eventDescription } = this.props.initialValues.card || {};
        const { title=cardTitle, description: description=cardDesc, image, og = {} } = this.props.initialValues.meta || {};
        const { title: ogTitle=cardTitle, description: ogDesc=cardDesc, imageUrl: ogImage="" } = og;
        const isValidType = type === "webinar" || type === "meeting" || type === "ebook" || type === "guide";

        return (
            <>
                <Form style={{}} onSubmit={this.handleFormSubmission}>
                    <FormItem {...formItemLayout} label={"Title"} extra={<p>Any change will automatically populate field <label htmlFor="ogTitle"><b>og:title</b></label>.</p>}>
                        {
                            getFieldDecorator("title", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable meta title"
                                }],
                                initialValue: title
                            }) (
                                <Input placeholder={"Meta Title"} onChange={this.handleMetaTitleChange}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Descrition"} extra={<p>Any change will automatically populate fields <label htmlFor="ogDesc"><b>og:description</b></label>.</p>}>
                        {
                            getFieldDecorator("desc", {
                                rules: [{
                                    required: true,
                                    message: "Provide some meta description related to this resource"
                                }],
                                initialValue: description
                            }) (
                                <Input.TextArea placeholder={"Meta description"} autoSize={{minRows: 2, maxRows: 8}} onChange={this.handleMetaDescChange} />
                            )
                         }
                    </FormItem>
                    {
                        ( type === "webinar" || type === "meeting" ) && (
                            <FormItem {...formItemLayout} label={"Google Image"} extra={<p>We recommend that images are <strong>1920px wide</strong> (the minimum width is <strong>720px</strong>).</p>}>
                                {
                                    getFieldDecorator("image", {
                                        getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                        rules: [{
                                            required: isValidType ? false : true,
                                            message: "Upload a google event image"
                                        }, {
                                            validator: uploadUtility.singleFile.validator
                                        }],
                                        initialValue: image ? [{
                                            uid: "-1",
                                            name: `google-event-image ${title}`,
                                            status: "done",
                                            url: image
                                        }] : (isValidType ? [{
                                            uid: "-1",
                                            name: `google-event-image ${title}`,
                                            status: "done",
                                            url: genericWebinarImagePath
                                        }] : null),
                                        valuePropName: "fileList"
                                    })(
                                        <Upload
                                            name={"files"}
                                            accept={"image/*"}
                                            action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=meta-google-event-image&timestamp=${Date.now().toString()}&webp=true`}
                                            withCredentials
                                            listType="picture"
                                            >
                                            <Button icon={"upload"}>Google event image</Button>
                                        </Upload>
                                    )
                                }
                            </FormItem>
                        )
                    }
                    <FormItem {...formItemLayout} label={"og:title"}>
                        {
                            getFieldDecorator("ogTitle", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable og:title"
                                }],
                                initialValue: ogTitle
                            }) (
                                <Input placeholder={"og:title"}/>
                            )
                         }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"og:description"}>
                        {
                            getFieldDecorator("ogDesc", {
                                rules: [{
                                    required: true,
                                    message: "Provide some og:description related to this resource"
                                }],
                                initialValue: ogDesc
                            }) (
                                <Input.TextArea placeholder={"og:description"} autoSize={{minRows: 2, maxRows: 8}} />
                            )
                         }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"og:image"} extra={<p>Recommended resolution <strong>1200 x 630 pixels</strong>, which is an approximate aspect ratio of <strong>1.91:1</strong>.</p>}>
                        {
                            getFieldDecorator("ogImage", {
                                getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                rules: [{
                                    required: isValidType ? false : true,
                                    message: "Upload an og:image"
                                }, {
                                    validator: uploadUtility.singleFile.validator
                                }],
                                initialValue: ogImage ? [{
                                    uid: "-1",
                                    name: `og:image ${title}`,
                                    status: "done",
                                    url: ogImage
                                }] : (isValidType ? [{
                                    uid: "-1",
                                    name: `og:image ${title}`,
                                    status: "done",
                                    url: genericWebinarOGImagePath
                                }] : null),
                                valuePropName: "fileList"
                            })(
                                <Upload
                                    name={"files"}
                                    accept={"image/*"}
                                    action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=meta-og-image&timestamp=${Date.now().toString()}&webp=true`}
                                    withCredentials
                                    listType="picture"
                                    >
                                    <Button icon={"upload"}>og:image</Button>
                                </Upload>
                            )
                        }
                    </FormItem>
                    <FormItem {...formButtonLayout}>
                        <Button type={"primary"} htmlType={"submit"}>
                            Next<Icon type="right" />
                        </Button>
                    </FormItem>
                </Form>
            </>
        );
    }
}

const Meta = Form.create()(MetaForm);

Meta.propTypes = {
    userData: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    additional: PropTypes.object,
    handleStepFormSubmission: PropTypes.func.isRequired
};

export default Meta;
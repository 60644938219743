import React, { Component } from "react";
import PropTypes from "prop-types";
import {formButtonLayout, formItemLayout} from "../../constants";
import { RedirectWrapper } from "../../components";
import { Form, Input, Select, Button, Modal, Skeleton } from "antd";
import { getIsPageActionAccessible } from "../../utils";
const Option = Select.Option;
const FormItem = Form.Item;

class AddBeSubCategoryForm extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
        };

        this.beParentCategories = [];
        this.beSubCategories = [];
    }
    pageKey = "categoriesMapping";
    componentDidMount() {
        this.props.fetchBirdeyeCategoriesList();
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, value) => {
            
            if (error) {
                return;
            }

            let payload = {
                parentCategory: value.parentCategory,
                subCategory: value.subCategory,
                naicsCode: value.naicsCode,
                naicsSubIndustry: value.naicsSubIndustry,
                sicCode: value.sicCode,
                sicSubIndustry: value.sicSubIndustry,
            };

            this.props.addBeSubCategoryRequest({data: payload, cb: this.renderFeedback});
        })
    }

    renderFeedback = (errorType) => {
        const goToCategoryPage = () => this.props.history.push("/categories/2");
        if (!errorType) {
            return Modal.success({
                title: `You have successfully added a sub-industry`,
                okText: "Go to category page",
                maskClosable: true,
                onOk() {
                    goToCategoryPage();
                }
            });
        } else {
            return Modal.warning({
                title: `Unable to add this sub-industry`,
                content: "Please check your input or try again later..."
            });
        }
    };

    validateBeSubCategory = async (rule, beSubCategory, cb) => {
        const { categories=[] } = this.props;
        
        try {
            if (!this.beSubCategories.length) {
                for (const category of categories) {
                    if (category && category.childCategory) {
                        this.beSubCategories = [ ...this.beSubCategories, ...category.childCategory.map(({name}) => (name.toLowerCase()))];
                    }
                }
            }
        } catch (e) {
            console.error(e);
            cb("Unable to validate existence of this sub category");
            return;
        }

        if (!beSubCategory) {
            cb();
            return;
        }

        if (this.beSubCategories.indexOf(beSubCategory.trim().toLowerCase()) !== -1) {
            cb("This sub-category is already present");
            return;
        }

        cb();
    }

    render() {
        const { isLoading, error, userData, location, categories=[] } = this.props;
        const { getFieldDecorator } = this.props.form;

        const { name } = userData;
        const isActionAccessible = getIsPageActionAccessible(this.pageKey, userData.privileges);
        const unauthorized = error === "unauthorized" || !name || !isActionAccessible;
        if (unauthorized) {
            return <RedirectWrapper location={location} />;
        }

        return (
            <div>
                <h2>Add Sub Category</h2>
                {
                    isLoading 
                        ? (<Skeleton active />)
                        : (
                            <Form onSubmit={this.handleSubmit}>
                                <FormItem {...formItemLayout} label={"BE Parent Category"}>
                                    {
                                        getFieldDecorator("parentCategory", {
                                            rules: [{
                                                required: true,
                                                message: "Select a suitable BE parent category"
                                            }],
                                        })(
                                            <Select placeholder={"Select a suitable BE parent category"} showSearch loading={!categories}>
                                                {
                                                    categories.map((category, index) => {
                                                        return (
                                                            <Option key={index} value={category.parentCategory.name}>{category.parentCategory.name}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        )
                                    }
                                </FormItem>
                                <FormItem {...formItemLayout} label={"BE Sub Category"}>
                                    {
                                        getFieldDecorator("subCategory", {
                                            rules: [{
                                                required: true,
                                                message: "Provide a BE sub category"
                                            }, {
                                                validator: this.validateBeSubCategory
                                            }],
                                        })(
                                            <Input placeholder={"Enter a new BE sub category"}/>
                                        )
                                    }
                                </FormItem>
                                <FormItem {...formItemLayout} label={"NAICS Sub Industry"} required={true}>
                                    {
                                        getFieldDecorator("naicsSubIndustry", {
                                            initialValue: null,
                                            rules: [{
                                                required: true,
                                                message: "Please Provide a NAICS Sub industry"
                                            }],
                                        })(
                                            <Input placeholder={"Enter a NAICS Sub Industry"}/>
                                        )
                                    }
                                </FormItem>
                                <FormItem {...formItemLayout} label={"NAICS Code"} required={true}>
                                    {
                                        getFieldDecorator("naicsCode", {
                                            initialValue: null,
                                            rules: [{
                                                required: true,
                                                message: "Please Provide a NAICS Code"
                                            }, {
                                                pattern: '^[0-9]*$',
                                                message: "Please provide valid NAICS Code"
                                            }],
                                        })(
                                            <Input placeholder={"Enter a NAICS Code"}/>
                                        )
                                    }
                                </FormItem>
                                <FormItem {...formItemLayout} label={"SIC Sub Industry"} required={true}>
                                    {
                                        getFieldDecorator("sicSubIndustry", {
                                            initialValue: null,
                                            rules: [{
                                                required: true,
                                                message: "Please Provide a SIC Sub industry"
                                            }],
                                        })(
                                            <Input placeholder={"Enter a SIC Sub Industry"}/>
                                        )
                                    }
                                </FormItem>
                                <FormItem {...formItemLayout} label={"SIC Code"} required={true}>
                                    {
                                        getFieldDecorator("sicCode", {
                                            initialValue: null,
                                            rules: [{
                                                required: true,
                                                message: "Please Provide a SIC Code"
                                            }, {
                                                pattern: '^[0-9]*$',
                                                message: "Please provide valid NAICS Code"
                                            }],
                                        })(
                                            <Input placeholder={"Enter a SIC Code"}/>
                                        )
                                    }
                                </FormItem>
                                <FormItem {...formButtonLayout}>
                                    <Button type={"primary"} htmlType={"submit"} icon={"plus"} loading={isLoading}>
                                        Add this category
                                    </Button>
                                </FormItem>
                            </Form>
                        )
                }
            </div>
        );
    }

}

const AddBeSubCategory = Form.create()(AddBeSubCategoryForm);

AddBeSubCategory.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    userData: PropTypes.object.isRequired,
    fetchBirdeyeCategoriesList: PropTypes.func.isRequired,
    addBeSubCategoryRequest: PropTypes.func.isRequired
};

export default AddBeSubCategory;
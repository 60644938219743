export default {
    listingTableColumns: [
        {
            title: "Title",
            key: "title",
            dataIndex: "title",
            searchable: true,
            ellipsis: true,
            width: "35%",
        },
        {
            title: "Last Updated",
            key: "lastUpdated",
            dataIndex: "lastUpdated",
            width: "20%",
            align: "center",
            sortAs: "date"
        },
        {
            title: "Sorting Order",
            key: "sortingOrder",
            dataIndex: "sortingOrderField",
            sortAs: "number",
            width: "15%",
            align: "center"
        },
        {
            title: "Actions",
            key: "actions",
            dataIndex: "actions",
            width: "30%",
            align: "center"
        },
    ]
};
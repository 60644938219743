import React, {Component} from "react";
import PropTypes from "prop-types";
import { Form, Select, Button, Empty, Icon } from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";
import lookup from "./constants";

const { Item: FormItem } = Form;
const { Option: SelectOpt } = Select;


class ResultForm extends Component {
    constructor( props ) {
        super(props);

        this.rTemplates = (lookup.result && lookup.result.resultOptions) || [];
    }

    handleFormSubmission = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (error) {
                return;
            }

            const selectedTemplate = this.rTemplates && this.rTemplates.filter(({title}) => (title === values.rTemplate))[0];

            this.props.handleStepFormSubmission({ result: {...selectedTemplate} });
        });
    }

    handleFormSkip = (e) => {
        e.preventDefault();

        this.props.handleStepFormSubmission({ result: {} });
    }

    render() {
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { result } = this.props.initialValues;
        const { rTemplate: templateTitle = (result && result.title) || this.rTemplates[0].title } = getFieldsValue();
        const [selectedTemplate, ] = this.rTemplates && this.rTemplates.filter(({title}) => (title === templateTitle));

        return (
            <>
                <Form onSubmit={this.handleFormSubmission}>
                    <FormItem {...formItemLayout} label={"Templates"}>
                        {
                            getFieldDecorator("rTemplate", {
                                rules: [{
                                    required: true,
                                    message: "Select a suitable result template"
                                }],
                                initialValue: (selectedTemplate && selectedTemplate.title)
                            }) (
                                <Select placeholder={"Select a suitable result template"}>
                                    {
                                        this.rTemplates.map((template, index) => (
                                            <SelectOpt key={index} value={template.title}>{template.title}</SelectOpt>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Preview"}>
                        <div style={{ border: "1px solid #d9d9d9", padding: "10px", borderRadius: "4px", backgroundColor: "#eef8fe" }}>
                            {
                                !selectedTemplate 
                                    ? <Empty description={<><strong>No preview is available.</strong><br/><small>Select any of the template to get preview.</small></>} /> 
                                    : (
                                        <div style={{ textAlign: "center" }}>
                                            <h2 style={{ margin: 0 }}>{selectedTemplate.title}</h2>
                                            <>
                                                {
                                                    selectedTemplate.rates && selectedTemplate.rates.map(({heading, desc}, index) => {
                                                        return (<li key={index} style={{ margin: 0 }}><strong>{heading}</strong>  <small>{desc}</small></li>)
                                                    })
                                                }   
                                            </>
                                            <h6 style={{ margin: 0 }}>{selectedTemplate.information}</h6>
                                        </div>
                                    )
                            }
                        </div>
                    </FormItem>
                    <FormItem {...formButtonLayout}>
                        <Button type={"primary"} htmlType={"submit"}>
                            Next<Icon type="right" />
                        </Button>
                        <Button htmlType={"button"} type={"default"} style={{ marginLeft: "20px" }} onClick={this.handleFormSkip}>
                            Skip <Icon type="stop" />
                        </Button>
                    </FormItem>
                </Form>
            </>
        );
    }
}

const Result = Form.create()(ResultForm);

Result.propTypes = {
    userData: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    additional: PropTypes.object,
    handleStepFormSubmission: PropTypes.func.isRequired
};

export default Result;
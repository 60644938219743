import { findIndex } from "lodash";
import { EffectsActions } from "./actions";

let Effects = {
    data: [],
    curData: {},
    categoryData: [],
    curCategoryData: {},
    error: "",
    isLoading: false
};

const effectsReducer = (state = Effects, action) => {
    let index;
    switch (action.type) {
        case EffectsActions.FETCH_EFFECTS_LIST_REQUEST:
        case EffectsActions.FETCH_EFFECT_REQUEST:
        case EffectsActions.UPDATE_EFFECT_REQUEST:
        case EffectsActions.FETCH_CATEGORIES_LIST_REQUEST:
        case EffectsActions.FETCH_CATEGORY_REQUEST:
        case EffectsActions.UPDATE_CATEGORY_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case EffectsActions.FETCH_EFFECTS_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case EffectsActions.FETCH_EFFECT_SUCCESS:
            return {
                ...state,
                curData: action.payload,
                isLoading: false
            };
        case EffectsActions.UPDATE_EFFECT_SUCCESS:
            index = findIndex(state.data, ["businessNumber", action.payload.businessNumber]);
            return {
                ...state,
                data: [
                    ...state.data.slice(0, index),
                    action.payload,
                    ...state.data.slice(index + 1)
                ],
                curData: action.payload,
                isLoading: false
            };
        case EffectsActions.EFFECT_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case EffectsActions.FETCH_CATEGORIES_LIST_SUCCESS:
            return {
                ...state,
                categoryData: action.payload,
                isLoading: false
            };
        case EffectsActions.FETCH_CATEGORY_SUCCESS:
        case EffectsActions.UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                curCategoryData: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};

export default effectsReducer;

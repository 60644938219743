import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import { Popconfirm, Button, message, Select, Icon, Drawer } from "antd";
import SignatureBanner from "./SignatureBanner";


import { TableSkeleton } from "../../components";
import constant from "./signature.json";
import "./signature.scss";

class ManageBanner extends Component {

    constructor(props) {
        super();

        this.templateBanner = [];
        this.state = {
            isNewBannerModalVisible: false,
            template: null
        };
    }

    getTemplateBanner = (template) => {
        this.templateBanner = this.props.banner.hasOwnProperty(template) ? this.props.banner[template] : [];
    }

    getBannerMeta = (index) => {
        return this.templateBanner.hasOwnProperty(index) ? this.templateBanner[index] : null;
    }

    getTemplateMeta = (template) => {
        const templateMatch = constant.template.filter(v => (v[1] === template));
        return !_.isEmpty(templateMatch) ? templateMatch[0] : [];
    }

    handleClickNewBannerButton = () => {
        this.setState({isNewBannerModalVisible: true})
    }

    handleTemplateChange = (template) => {
        this.props.getSignatureAssetRequest({ data: {type: "banner", template} });
        this.setState({template});
    }

    handleBannerDeletion = (data) => {
        this.props.deleteSignatureAssetRequest({data: {...data}, cb: this.handleSubmitResponse})
    }

    handleSubmitResponse = (response) => {
        message[response.type](response.message);
    }

    getTableData = (data) => {

        if (_.isEmpty(data)) return [];

        return data.map((v) => {
            return {
                ...v,
                key: v.id,
                isSelectedFormatted: v.isSelected ? <Icon type="check-square" style={{color: "green", fontSize: "16px"}} /> : <Icon type="border" style={{ fontSize: "15px" }} />,
                banner: <a target={"_blank"} href={v.url}><img src={v.image} alt={v.name} title={v.name} style={{width: "400px"}} /></a>,
                country: <span>{v.country.toUpperCase()}</span>,
                action: <>
                    <Popconfirm
                        title={<><p>Do you want to delete this banner?</p></>}
                        okText={"Yes"}
                        cancelText={"No"}
                        placement={"right"}
                        onConfirm={() => {this.handleBannerDeletion({type: "banner", template: v.template, id: v.id})}}
                    >
                        <a href="javascript:void(0);"><Icon type="delete" style={{ fontSize: "16px" }} /></a>
                    </Popconfirm>
                </>
            }
        });
    }

    render() {
        const unauthorized = !this.props.userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        const selectedTemplate = this.state.template;
        const templateMeta = this.getTemplateMeta(selectedTemplate);

        this.getTemplateBanner(selectedTemplate);

        return (
            <div>
                <h2>Manage Banner</h2>
                <div style={{paddingBottom: "20px"}}>
                    <Select placeholder={"Employee group"} onChange={this.handleTemplateChange} style={{width: "200px"}}>
                        {
                            constant.template && constant.template.filter(t => (t[1] !== "default")).map(t => (
                                <Select.Option key={t[1]} value={t[1]}>{t[0]}</Select.Option>
                            ))
                        }
                    </Select>
                    <Button style={{float: "right"}} type="primary" disabled={!(selectedTemplate)} onClick={this.handleClickNewBannerButton}>Add new banner</Button>
                </div>
                <TableSkeleton
                    dataSource={this.getTableData(this.templateBanner)}
                    columns={constant.bannerTable.column}
                    loadinng={this.props.isLoading}
                />
                <Drawer
                    title={ !_.isEmpty(templateMeta) ? `New Banner | ${templateMeta[0]}` : "Banner"}
                    placement="right"
                    closable={true}
                    destroyOnClose={true}
                    visible={this.state.isNewBannerModalVisible}
                    onClose={() => (this.setState({isNewBannerModalVisible: false}))}
                    width="50%"
                >
                    <div style={{marginTop: "20%"}}>
                        <SignatureBanner isLoading={this.props.isModalLoading} template={selectedTemplate} createSignatureAssetRequest={this.props.createSignatureAssetRequest} />
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default ManageBanner;
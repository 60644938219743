import React, { Component } from "react";
import PropTypes from "prop-types";
import { includes, isEmpty } from "lodash";
import { Form, Select, Button, Spin, Modal, Popconfirm } from "antd";

import { RedirectWrapper } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";

const Option = Select.Option;
const FormItem = Form.Item;

class Configurator extends Component {
    initialData = {
        webinarId: (!isEmpty(this.props.currentLandingPageWebinar) && this.props.currentLandingPageWebinar.webinarId) || ""
    }

    state = {
        data: this.initialData,
        formError: ""
    };

    componentDidMount() {
        const 
            {reqWebinarEvents, getLandingPageWebinar, events } = this.props;

        if (isEmpty(events) || isEmpty(events.upcoming)) reqWebinarEvents({eventsType: "upcoming"});
        getLandingPageWebinar();
    }

    componentDidUpdate(prevProps, prevState) {
        if (isEmpty(this.props.currentLandingPageWebinar)) return;
        const previousWebinarId = (!isEmpty(prevProps.currentLandingPageWebinar) && prevProps.currentLandingPageWebinar.webinarId) || "";
        if (previousWebinarId !== this.props.currentLandingPageWebinar.webinarId) {
            this.setState({
                data: {
                    webinarId: this.props.currentLandingPageWebinar.webinarId
                }
            });
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.handleFieldChange(name, value);
    };

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        this.setState({ data, formError: "" });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const rawData = { ...this.state.data };
        if (
            includes(rawData, "")
        ) {
            this.setState({ formError: "Please select a webinar" });
            return;
        }
        this.props.updateLandingPageWebinar({ data: rawData, cb: this.renderFeedback });
    };

    renderFeedback = (userErrorType) => {
        const goToSessionsPage = () => this.props.history.push("/webinar/events");
        if (!userErrorType) {
            return Modal.success({
                title: `Configuration successful`,
                content: `You have successfully updated the webinar for landing page`,
                okText: "Go to Sessions page",
                maskClosable: true,
                onOk() {
                    goToSessionsPage();
                }
            });
        } else {
            return Modal.warning({
                title: `The configuration was not updated successfully`,
                content: "Please try again later..."
            });
        }
    };

    getListOfUpcomingEvents = ({upcoming = []}) => {
        return upcoming.map(({eventId, webinarId, eventTitle}) => ({
            id: eventId,
            value: eventId,
            name: eventTitle
        }));
    }

    render() {
        const { isLoading, isLoadingCurrentWebinar, error, userData, events } = this.props;
        const upcomingEvents = this.getListOfUpcomingEvents(events);
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div>
                <h2>Configure Webinar for Landing Page</h2>
                {isLoading || isLoadingCurrentWebinar ? (
                    <Spin />
                ) : (
                    <Form onSubmit={this.handleSubmit}>
                        <FormItem {...formItemLayout} label="Upcoming Webinars">
                            <Select
                                value={this.state.data.webinarId || []}
                                placeholder="Select a webinar"
                                onChange={(value) =>
                                    this.handleFieldChange("webinarId", value)
                                }>
                                {upcomingEvents.map((event) => (
                                    <Option
                                        key={event.id}
                                        value={event.value}>
                                        {event.name}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                        {this.state.formError && (
                            <FormItem {...formButtonLayout}>
                                <span className="text-error">
                                    {this.state.formError}
                                </span>
                            </FormItem>
                        )}
                        <FormItem {...formButtonLayout}>
                            <Popconfirm 
                                title="The impact of this change on website is immediate. Do you want to continue?"
                                onConfirm={this.handleSubmit}
                                okText="Yes, update"
                                cancelText="No, don't update"
                            >
                                <Button type="primary" htmlType="submit">
                                    Update
                                </Button>
                            </Popconfirm>
                        </FormItem>
                    </Form>
                )}
            </div>
        );
    }
}

const LandingConfigurator = Form.create()(Configurator);

LandingConfigurator.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    events: PropTypes.object.isRequired,
    currentLandingPageWebinar: PropTypes.object.isRequired,
    isLoading:  PropTypes.bool.isRequired
};

export default LandingConfigurator;
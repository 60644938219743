import { connect } from "react-redux";
import { fileUploadRequest } from "./action";
import ScitorUpload from "./ScitorUpload";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Scitor
});

export const ScitorUploadPage = connect(
    mapStateToProps,
    { fileUploadRequest }
)( ScitorUpload );

import React, { Component } from "react";
import {Form, Input, Switch} from "antd";
import {formItemLayout} from "../../../constants";

class ProvideBusinessNumber extends Component {

    state = {
        findOnGoogle: false,
        findOnEmail: false,
    }

    onGoogleChange = (e) => {
        this.setState({findOnGoogle: e});
    }

    findByEmail = (e) => {
        this.setState({findByEmail: e});
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { findByEmail } = this.state;
        return (
            <div>
                <Form>
                    <Form.Item {...formItemLayout} label="Find on Google" style={{ textAlign: "left" }}>
                        {getFieldDecorator('findOnGoogle', {
                            initialValue: false
                        })(
                            <Switch onChange={this.onGoogleChange} />
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="Find by Email" style={{ textAlign: "left" }}>
                        {getFieldDecorator('findByEmail', {
                            initialValue: false
                        })(
                            <Switch onChange={this.findByEmail} />
                        )}
                    </Form.Item>
                    {
                        findByEmail ? (
                            <Form.Item {...formItemLayout} label="Email Id">
                                {getFieldDecorator('emailId', {
                                    rules: [{
                                        required: true, message: 'Please provide Email Id'
                                    }, {
                                        type: "email", message: "Please Input a valid Email Address"
                                    }],
                                })(
                                    <Input
                                        placeholder="Enter Email Id"
                                    />,
                                )}
                            </Form.Item>
                        ) : (
                            <Form.Item {...formItemLayout} label="Business Number">
                                {getFieldDecorator('business_number', {
                                    rules: [{ required: true, message: 'Please provide business number' }, { pattern: new RegExp(/^[0-9]+$/),message: "Only numberic value allowed" }],
                                })(
                                    <Input
                                        placeholder="Enter Business Number"
                                    />,
                                )}
                            </Form.Item>
                        )
                    }
                </Form>
            </div>
        );
    }
}

export default ProvideBusinessNumber;
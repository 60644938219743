import React, { Component } from "react";
import PropTypes from "prop-types";

import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";
import { getIsPageActionAccessible } from "../../utils";

import lookup from "./effectsCategories.json";

class EffectsCategories extends Component {
    pageKey = "effectCategoriesList";

    componentDidMount() {
        this.props.fetchCategoriesListRequest();
    }

    formatData = (data) => {
        return data
            .filter(record => record.label)
            .map((record) => {
                return {
                    ...record,
                    key: record.id,
                    shownInWebsite: !!record.count ? "Yes" : "No",
                    withDesc: (record.headerDesc && record.footerDesc) ? "Yes" : "No"
                };
            });
    };

    renderExpandedRow = (record) => (
        <div>
            <div className="mb1">
                <b>Header desc: </b>
                <span>{record.headerDesc}</span>
            </div>
            <div className="mb1">
                <b>Footer desc: </b>
                <span>{record.footerDesc}</span>
            </div>
        </div>
    );

    render() {
        const { categoryData, isLoading, error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        const isActionAccessible = getIsPageActionAccessible(this.pageKey, userData.privileges);

        return (
            <div className="effects-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    columns={lookup.columns}
                    pathForEdit={ isActionAccessible && "/effects-category/"}
                    expandedRowRender={this.renderExpandedRow}
                    dataSource={this.formatData(categoryData)}
                    loading={isLoading}
                />
            </div>
        );
    }
}

EffectsCategories.propTypes = {
    categoryData: PropTypes.array.isRequired, 
    isLoading: PropTypes.bool.isRequired,
    userData: PropTypes.object.isRequired,
    error: PropTypes.string.isRequired,
    fetchCategoriesListRequest: PropTypes.func.isRequired
};

export default EffectsCategories;

import { EmailServiceActions } from "./actions";

let EmailService = {
    isLoading: false,
    data: [],
    emails: [],
    domain: '',
    curData: {},
};

const emailServiceReducer = (state = EmailService, action) => {
    switch (action.type) {
        case EmailServiceActions.SEARCH_DOMAIN_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case EmailServiceActions.SUCCESS_SEARCH_DOMAIN_RESPONSE:
            return {
                ...state,
                isLoading: false
            };
        case EmailServiceActions.ERROR_SEARCH_DOMAIN_RESPONSE:
            return {
                ...state,
                isLoading: false,
            };
        case EmailServiceActions.FETCH_EMAILS_REQUEST:
            return {
                ...state,
                domain: action.payload,
                error: "",
                isLoading: true
            };
        case EmailServiceActions.FETCH_EMAILS_RESPONSE:
            return {
                ...state,
                emails: action.payload,
                isLoading: false
            };
        case EmailServiceActions.ERROR_FETCH_EMAILS_RESPONSE:
            return {
                ...state,
                isLoading: false,
            };
        case EmailServiceActions.BLANK_EMAILS_RESPONSE:
            return {
                ...state,
                domain: "",
                emails: [],
                isLoading: false
            };
        default:
            return state;
    }
};

export default emailServiceReducer;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {TableSkeleton, NewsletterPreview, Preview, RedirectWrapper} from "../../components";
import staticData from "./newsletter.json";
import UpdateFeatureRelease from "./UpdateFeatureRelease";

class Newsletter extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.fetchNewsletterListing();
    }

    getTitleAgainstDate = (date) => {
        const
            dateObj = date.split("-"), // YYYY-MM-DD
            allMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        return `Your Birdeye updates for ${allMonths[(dateObj[1]-1)]}`;

    };

    getSentDateString = (date) => {
        return (new Date(date)).toJSON().split('T')[0];
    };

    getDataSource = (data) => {
        const formattedData = [];

        for (const date in data) {

            if (data.hasOwnProperty(date)) {
                const
                    businessTypes = data[date],
                    title = this.getTitleAgainstDate(date);

                for (const businessType in businessTypes) {

                    if (businessTypes.hasOwnProperty(businessType)) {

                        const
                            mappedObj = {title, date, businessType},
                            businessTypeObj = businessTypes[businessType];

                        mappedObj.key = `${date}-${businessType}`;
                        mappedObj.sentDate = (businessTypeObj.sentDate && this.getSentDateString(businessTypeObj.sentDate.join('-'))) || '-';
                        mappedObj.action = (<a onClick={() => (this.props.openNewsLetterPreview({index: mappedObj.key, title, features: businessTypeObj.features, emailHtml: businessTypeObj.emailHtml}))}>Preview</a>);
                        formattedData.push(mappedObj);
                    }
                }
            }
        }

        return formattedData;
    };

    render() {
        const
            {isLoading, error, userData, newsletter, closeNewsLetterPreview} = this.props,
            {pageData, preview} = newsletter,
            {data: previewData} = preview,
            unauthorized = error === "unauthorized" || !userData.name,
            dataSource = !isEmpty(pageData) ? this.getDataSource(pageData) : [];

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div>
                <h2>All Newsletters</h2>
                <TableSkeleton columns={staticData.table.columns} loading={isLoading} dataSource={dataSource} />
                {
                    preview.visible && (
                        <Preview
                            title={previewData.title}
                            body={
                                <NewsletterPreview
                                    title={previewData.title}
                                    features={previewData.features}
                                    emailHtml={previewData.emailHtml}
                                />
                            }
                            onCancel={closeNewsLetterPreview}
                        />
                    )
                }
            </div>
        );
    }
}

UpdateFeatureRelease.propTypes = {
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    newsletter: PropTypes.object.isRequired,
    fetchNewsletterListing: PropTypes.func.isRequired,
    openNewsLetterPreview: PropTypes.func.isRequired,
    closeNewsLetterPreview: PropTypes.func.isRequired
};

export default Newsletter;
import { createActionTypes } from "../../utils";

export const IntegrationDomainsActions = createActionTypes("integrationDomains", [
    "FETCH_INTEGRATIONDOMAINS_LIST_REQUEST",
    "FETCH_INTEGRATIONDOMAINS_LIST_SUCCESS",
    "FETCH_INTEGRATIONDOMAIN_REQUEST",
    "FETCH_INTEGRATIONDOMAIN_SUCCESS",
    "ADD_INTEGRATIONDOMAIN_REQUEST",
    "ADD_INTEGRATIONDOMAIN_SUCCESS",
    "UPDATE_INTEGRATIONDOMAIN_REQUEST",
    "UPDATE_INTEGRATIONDOMAIN_SUCCESS",
    "DELETE_INTEGRATIONDOMAIN_REQUEST",
    "DELETE_INTEGRATIONDOMAIN_SUCCESS",
    "INTEGRATIONDOMAIN_ERROR"
]);

export const fetchIntegrationDomainsListRequest = () => {
    return {
        type: IntegrationDomainsActions.FETCH_INTEGRATIONDOMAINS_LIST_REQUEST
    };
};

export const fetchIntegrationDomainsListSucess = (obj) => {
    return {
        type: IntegrationDomainsActions.FETCH_INTEGRATIONDOMAINS_LIST_SUCCESS,
        payload: obj
    };
};

export const fetchIntegrationDomainRequest = (obj) => {
    return {
        type: IntegrationDomainsActions.FETCH_INTEGRATIONDOMAIN_REQUEST,
        payload: obj
    };
};

export const fetchIntegrationDomainSuccess = (obj) => {
    return {
        type: IntegrationDomainsActions.FETCH_INTEGRATIONDOMAIN_SUCCESS,
        payload: obj
    };
};

export const addIntegrationDomainRequest = (obj) => {
    return {
        type: IntegrationDomainsActions.ADD_INTEGRATIONDOMAIN_REQUEST,
        payload: obj
    };
};

export const addIntegrationDomainSuccess = (obj) => {
    return {
        type: IntegrationDomainsActions.ADD_INTEGRATIONDOMAIN_SUCCESS,
        payload: obj
    };
};

export const updateIntegrationDomainRequest = (obj) => {
    return {
        type: IntegrationDomainsActions.UPDATE_INTEGRATIONDOMAIN_REQUEST,
        payload: obj
    };
};

export const updateIntegrationDomainSuccess = (obj) => {
    return {
        type: IntegrationDomainsActions.UPDATE_INTEGRATIONDOMAIN_SUCCESS,
        payload: obj
    };
};

export const deleteIntegrationDomainRequest = (obj) => {
    return {
        type: IntegrationDomainsActions.DELETE_INTEGRATIONDOMAIN_REQUEST,
        payload: obj
    };
};

export const deleteIntegrationDomainSuccess = (obj) => {
    return {
        type: IntegrationDomainsActions.DELETE_INTEGRATIONDOMAIN_SUCCESS,
        payload: obj
    };
};

export const integrationDomainError = (obj) => {
    return {
        type: IntegrationDomainsActions.INTEGRATIONDOMAIN_ERROR,
        payload: obj
    };
};

export default IntegrationDomainsActions;

import React from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { Select, Spin } from "antd";

import { startsWith } from "../../../../utils";

const { Option } = Select;

class SearchSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      filteringOptions: false,
      searchValue: ""
    };
    this.getFilteredOptions = debounce(this.getFilteredOptions, 800);
  }

  handleFieldChange = (name, nextValue = []) => {
    const { handleFieldChange } = this.props;
    handleFieldChange(name, nextValue);
    this.setState({
      options: [],
      filteringOptions: false
    });
  };

  getFilteredOptions = (searchValue) => {
    searchValue = searchValue.trim();
    if (searchValue.length < 3) {
      this.setState({ options: [], filteringOptions: false, searchValue });
      return;
    }
    this.setState(
      () => ({ options: [], filteringOptions: true, searchValue }),
      () => this.filterData(this.props.dynamicOptions, searchValue)
    );
  };

  filterData = async (data, searchValue) => {
    let { filterObj } = this.props;
    let stateFilter = filterObj.state
      ? filterObj.state.filter((code) => code !== "all-selected").map((stateStr) => stateStr.split("_")[0])
      : [];
    let options = data.filter((record) => {
      if (!stateFilter.length) return startsWith(record.value.toLowerCase(), searchValue.toLowerCase());

      let [city, state] = record.value.toLowerCase().split(", ");
      if (!stateFilter.includes(state)) return false;
      return startsWith(city.toLowerCase(), searchValue.toLowerCase());
    });
    this.setState({ options, filteringOptions: false, searchValue: options.length ? "" : "result found" });
  };

  getNoFoundContent = () => {
    const { options, filteringOptions, searchValue } = this.state;
    if (filteringOptions) return <Spin size="small" />;
    if (searchValue.length < 3) return "Please type at least 3 letters.";
    if (!options.length) return "Not Found";
  };

  render() {
    const { name } = this.props;

    return (
      <Select
        {...this.props}
        notFoundContent={this.getNoFoundContent()}
        onSearch={this.getFilteredOptions}
        onChange={(nextValue) => this.handleFieldChange(name, nextValue)}>
        {this.state.options.map((d) => (
          <Option key={d.key} value={d.value}>
            {d.title}
          </Option>
        ))}
      </Select>
    );
  }
}

SearchSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  filterOption: PropTypes.bool.isRequired,
  filterObj: PropTypes.object.isRequired,
  dynamicOptions: PropTypes.array.isRequired,
  getPopupContainer: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired
};

export default SearchSelect;

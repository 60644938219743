import React, { Component } from "react";
import PropTypes from "prop-types";
import { Radio, Select, Form, DatePicker, Modal, Icon, Button } from "antd";
import moment from "moment";
import { isEmpty } from "lodash";
import axios from "axios";  

import { TableSkeleton, RedirectWrapper } from "../../components";
import { dateFormat} from "../../constants";

import lookup from "./productEmails.json";
import { transformNumCol } from "../../utils";
import { downloadFile } from "../../utils";

const RadioGroup = Radio.Group;
const Option = Select.Option;

class ProductEmails extends Component {
    initOffsetIndex = 3;
    initDate = {
        startDate: moment().subtract(...lookup.offsetOptions[this.initOffsetIndex].value).format(dateFormat),
        endDate: moment().format(dateFormat)
    };
    pageType = this.props.match.path === "/email-overall" ? "byMonth" : "byTemplate"
    state = {
        dateOffset: this.initOffsetIndex,
        displayFormat: "Absolute",
        ...this.initDate,
        tableHeight: "S"
    }

    componentDidMount() {
        this.pageInit();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.path !== this.props.match.path) {
            this.pageInit();
        }
        if (prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) {
            this.fetchData(this.state);
        }
    }

    fetchData = (dateObj) => {
        if (this.pageType === "byMonth") {
            this.props.fetchEmailByMonthRequest(dateObj);
        } else {
            this.props.fetchEmailByTemplateRequest(dateObj);
        }
    };

    fetchOtherCampaigns = (campaigns) => {
        return (
            <ul>
                {
                    campaigns.map((value, key) => {
                        return (
                            <li>{value}</li>
                        );
                    })
                }
            </ul>
        );
    };

    pageInit = () => {
        this.pageType = this.props.match.path === "/email-overall" ? "byMonth" : "byTemplate";
        this.fetchData(this.state);
    };

    formatData = (data, forCsv = false) => {
        const { displayFormat, startDate, endDate } = this.state;
        const { fetchTemplateStatsRequest, fetchEmailVersionsRequest } = this.props;
        let total = {};
        let formattedData = data.map((record, i) => {
            const emailTemplateURL = `${process.env.REACT_APP_API_BASE_URL}support/product-email/email-html`;
            if (record.email) {
                if (["Sendgrid", "Promotional", "Transactional", "Other"].indexOf(record.email)!== -1) {
                    record.formattedEmail = <span>{record.email} <Icon type="info-circle" theme="twoTone" onClick={(e) => {e.preventDefault(); fetchTemplateStatsRequest({ startDate, endDate, template: record.email});}} /></span>
                } else {
                    record.formattedEmail = <a target="_blank" rel="noopener noreferrer" href={`${emailTemplateURL}?email=${record.email}`}>{record.email} <Icon type="up-square" theme="twoTone" onClick={(e) => {e.preventDefault(); fetchEmailVersionsRequest({ startDate, endDate, emailType: record.email});}} /></a>
                }
            } else if(record.campaign) {
                const campaign = (record.campaign).toLowerCase();
                record.formattedCampaign = <a target="_blank" rel="noopener noreferrer" href={`${emailTemplateURL}?campaign=${campaign}${record.version ? "&version=" + record.version : ""}`}>{record.campaign}</a>
            }

            return {
                ...this.formatRecord(record, i, displayFormat, total, forCsv),
                sequenceCode: Math.floor(Number(record.sequenceCode) / 1000)
            };
        });
        total = this.formatRecord(total, "total", displayFormat, null, forCsv);
        total.dateStr = "Total";
        total.sequenceCode = "Total";
        return {formattedData, total};
    };

    formatRecord = (record, i, displayFormat, total, forCsv = false) => {
        let transformedRecord = {};
        lookup.columns[`${this.pageType}${displayFormat}`].forEach(c => {
            transformNumCol(c, record, transformedRecord, total, forCsv);
        });
        
        return {
            key: i,
            ...record,
            ...transformedRecord,
            dateStr: moment.utc(record.dateStr).format("MMMM YYYY")
        };
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.handleFieldChange(name, value);
    };

    handleDateChange = (date, fieldName) => {
        this.handleFieldChange(fieldName, date.format(dateFormat));
    };

    handleFieldChange = (name, value) => {
        let nextState = { ...this.state };
        nextState[name] = value;
        if (name === "dateOffset" && value) { 
            nextState.startDate = moment().subtract(...lookup.offsetOptions[value].value).format(dateFormat);
            nextState.endDate = this.initDate.endDate;
        } 
        this.setState(nextState);
    };

    handleModalClose = (e) => {
        this.props.loadOtherProductEmails(false);
    };

    downloadHandler = () => {
        const { byMonthData, byTemplateData } = this.props;
        const { displayFormat, startDate, endDate,  } = this.state;
        const data = this.pageType === "byMonth" ? byMonthData : byTemplateData;
        const columns = lookup.columns[`${this.pageType}${displayFormat}`]
        const columnHeaders = columns.map((c, index) => ({header: c.title, key: index.toString()}));
        const columnsKeys = columns.map((c) => c.dataIndex && c.sortAs === "number" ? c.dataIndex : c.key)
        const { formattedData, total} = this.formatData(data, true);
        formattedData.push(total);

        const url = `${process.env.REACT_APP_API_BASE_URL}support/convert-csv`;
            axios({ url, responseType: "blob", method: 'post', data: { data: formattedData, columns: columnHeaders, keys: columnsKeys}, withCredentials: true  }).then((response) => {
                downloadFile({
                    response,
                    cb: () => {},
                    type: "text/csv",
                    extension: "csv",
                    name: `${this.pageType}${displayFormat}-${startDate}-to-${endDate}`
                });
            });
    }

    singleTemplateReportDownloadHandler = (statsFor) => {
        const { templateStats, emailVersions } = this.props;
        const { displayFormat, startDate, endDate,  } = this.state;
        let data;
        let columns;
        if (statsFor === "email") {
            const { formattedData: emailVersionsFormattedData, total: emailVersionsTotal } = this.formatData(emailVersions, true);
            emailVersionsFormattedData.push(emailVersionsTotal);
            data = emailVersionsFormattedData;
            columns=lookup.columns[`emailVersionsStats${displayFormat}`]
        } else {
            const { formattedData: templateStatsFormattedData, total: templateStatsTotal } = this.formatData(templateStats, true);
            templateStatsFormattedData.push(templateStatsTotal)
            data = templateStatsFormattedData;
            columns = lookup.columns[`templateStats${displayFormat}`];
        }
        const columnHeaders = columns.map((c, index) => ({header: c.title, key: index.toString()}));
        const columnsKeys = columns.map((c) => c.dataIndex && c.sortAs === "number" ? c.dataIndex : c.key)
        const uniqueFileName = data[0].campaign || 'template';

        const url = `${process.env.REACT_APP_API_BASE_URL}support/convert-csv`;
            axios({ url, responseType: "blob", method: 'post', data: { data, columns: columnHeaders, keys: columnsKeys}, withCredentials: true  }).then((response) => {
                downloadFile({
                    response,
                    cb: () => {},
                    type: "text/csv",
                    extension: "csv",
                    name: `${uniqueFileName}_campaign_stats_${displayFormat}-${startDate}-to-${endDate}`
                });
            });
    }

    render() {
        const { byMonthData, byTemplateData, templateStats, isLoading, error, userData, eraseTemplateStatsRequest, emailVersions, eraseEmailVersionsRequest } = this.props;
        const { displayFormat, dateOffset, startDate, endDate, tableHeight } = this.state;
        const unauthorized = error === "unauthorized" || !userData.name;

        const data = this.pageType === "byMonth" ? byMonthData : byTemplateData;
        const columns = lookup.columns[`${this.pageType}${displayFormat}`];

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        const { formattedData, total } = this.formatData(data);
        const { formattedData: templateStatsFormattedData, total: templateStatsTotal } = this.formatData(templateStats);
        const { formattedData: emailVersionsFormattedData, total: emailVersionsTotal } = this.formatData(emailVersions);
        const maxHeight = lookup.tableMaxHeight[tableHeight];
        const maxWidth = 1550;

        return (
            <div className="product-email-root">
                <div className="table-widget mb1" style={{maxWidth}}>
                    <div style={{ maxWidth: '75vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div>
                            <Form layout="inline">
                                <Form.Item label="Data View">
                                    <RadioGroup name="displayFormat" onChange={this.handleInputChange} value={displayFormat} buttonStyle="outline" style={{ width: "100%" }}>
                                        <Radio.Button value="Absolute" style={{ width: 180, textAlign: "center" }}>Absolute Performance</Radio.Button>
                                        <Radio.Button value="Conversion" style={{ width: 180, textAlign: "center" }}>Conversion Rate</Radio.Button>
                                    </RadioGroup>
                                </Form.Item>
                            </Form>
                            <Form layout="inline">
                                <Form.Item label="Time Period">
                                    <Select
                                        value={lookup.offsetOptions[dateOffset].label || []}
                                        onChange={(value) => this.handleFieldChange("dateOffset", value)}
                                        style={{ width: 168 }}>
                                        {
                                            lookup.offsetOptions.map((d, i) => <Option key={i} value={i}>{d.label}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="From Date:">
                                    {
                                        dateOffset ?
                                            <span>{startDate}</span>
                                            :
                                            <DatePicker 
                                                allowClear={false}
                                                value={moment(startDate, dateFormat)} 
                                                format={dateFormat}
                                                onChange={(date) => this.handleDateChange(date, "startDate")} />
                                    }
                                </Form.Item>
                                <Form.Item label="To Date:">
                                    {
                                        dateOffset ?
                                            <span>{endDate}</span>
                                            :
                                            <DatePicker 
                                                allowClear={false}
                                                value={moment(endDate, dateFormat)} 
                                                format={dateFormat}
                                                onChange={(date) => this.handleDateChange(date, "endDate")} />
                                    }
                                </Form.Item>
                            </Form>
                        </div>
                        <div>
                            <Button type="primary" ghost onClick={this.downloadHandler}>Download {displayFormat} Data</Button>
                        </div>
                    </div>
                </div>
                <div className="mb5">
                    <TableSkeleton
                        bordered
                        size="small"
                        scroll={this.pageType === "byMonth" ? {x: 100, y: maxHeight} : {x: maxWidth, y: maxHeight}}
                        columns={columns}
                        dataSource={formattedData}
                        footerData={total}
                        loading={isLoading}
                    />
                    {
                        this.pageType === "byTemplate" && !isEmpty(templateStats) && (
                            <Modal
                                visible={true}
                                closable={true}
                                width={"90%"}
                                footer={null}
                                onCancel={eraseTemplateStatsRequest}
                            >
                                <div style={{marginTop: '12px'}}>
                                    <TableSkeleton
                                        bordered
                                        size="small"
                                        scroll={{x: (maxWidth - 350), y: (maxHeight-110)}}
                                        columns={lookup.columns[`templateStats${displayFormat}`]}
                                        dataSource={templateStatsFormattedData}
                                        footerData={templateStatsTotal}
                                        loading={isLoading}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
                                    <Button type="primary" onClick={() => this.singleTemplateReportDownloadHandler('template')}>Download</Button>
                                </div>
                            </Modal>
                        )
                    }
                    {
                        this.pageType === "byTemplate" && !isEmpty(emailVersions) && (
                            <Modal
                                visible={true}
                                closable={true}
                                width={"90%"}
                                footer={null}
                                onCancel={eraseEmailVersionsRequest}
                            >
                                <div style={{marginTop: '12px'}}>
                                    <TableSkeleton
                                        bordered
                                        size="small"
                                        scroll={{x: (maxWidth - 350), y: (maxHeight-110)}}
                                        columns={lookup.columns[`emailVersionsStats${displayFormat}`]}
                                        dataSource={emailVersionsFormattedData}
                                        footerData={emailVersionsTotal}
                                        loading={isLoading}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
                                    <Button type="primary" onClick={() => this.singleTemplateReportDownloadHandler('email')}>Download</Button>
                                </div>
                            </Modal>
                        )
                    }
                </div>
            </div>
        );
    }
}

ProductEmails.propTypes = {
    match: PropTypes.object.isRequired,
    byMonthData: PropTypes.array.isRequired, 
    byTemplateData: PropTypes.array.isRequired, 
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchEmailByMonthRequest: PropTypes.func.isRequired,
    fetchEmailByTemplateRequest: PropTypes.func.isRequired,
    fetchTemplateStatsRequest: PropTypes.func.isRequired
};

export default ProductEmails;

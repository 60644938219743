import { createActionTypes } from "../../utils";

export const PagesActions = createActionTypes("presses", [
    "FETCH_PAGES_LIST_REQUEST",
    "FETCH_PAGES_LIST_SUCCESS",
    "FETCH_PAGE_REQUEST",
    "FETCH_PAGE_SUCCESS",
    "ADD_PAGE_REQUEST",
    "ADD_PAGE_SUCCESS",
    "UPDATE_PAGE_REQUEST",
    "UPDATE_PAGE_SUCCESS",
    "DELETE_PAGE_REQUEST",
    "DELETE_PAGE_SUCCESS",
    "PAGE_ERROR",
    "REQ_FALLBACK_CAL",
    "REQ_FALLBACK_CAL_SUCCESS",
    "REQ_FALLBACK_CAL_ERROR",
    "ADD_INACTIVE_EMP",
    "ADD_INACTIVE_EMP_SUCCESS",
    "ADD_INACTIVE_EMP_ERROR",
    "REQ_INACTIVE_EMP_LIST",
    "REQ_INACTIVE_EMP_LIST_SUCCESS",
    "REQ_INACTIVE_EMP_LIST_ERROR"
]);

export const fetchPagesListRequest = () => {
    return {
        type: PagesActions.FETCH_PAGES_LIST_REQUEST
    };
};

export const fetchPagesListSucess = (obj) => {
    return {
        type: PagesActions.FETCH_PAGES_LIST_SUCCESS,
        payload: obj
    };
};

export const fetchPageRequest = (obj) => {
    return {
        type: PagesActions.FETCH_PAGE_REQUEST,
        payload: obj
    };
};

export const fetchPageSuccess = (obj) => {
    return {
        type: PagesActions.FETCH_PAGE_SUCCESS,
        payload: obj
    };
};

export const addPageRequest = (obj) => {
    return {
        type: PagesActions.ADD_PAGE_REQUEST,
        payload: obj
    };
};

export const addPageSuccess = (obj) => {
    return {
        type: PagesActions.ADD_PAGE_SUCCESS,
        payload: obj
    };
};

export const updatePageRequest = (obj) => {
    return {
        type: PagesActions.UPDATE_PAGE_REQUEST,
        payload: obj
    };
};

export const updatePageSuccess = (obj) => {
    return {
        type: PagesActions.UPDATE_PAGE_SUCCESS,
        payload: obj
    };
};

export const deletePageRequest = (obj) => {
    return {
        type: PagesActions.DELETE_PAGE_REQUEST,
        payload: obj
    };
};

export const deletePageSuccess = (obj) => {
    return {
        type: PagesActions.DELETE_PAGE_SUCCESS,
        payload: obj
    };
};

export const pageError = (obj) => {
    return {
        type: PagesActions.PAGE_ERROR,
        payload: obj
    };
};

export const reqFallBackCal = () => {
    return {
        type: PagesActions.REQ_FALLBACK_CAL
    }
}

export const reqFallBackCalSuccess = (obj) => {
    return {
        type: PagesActions.REQ_FALLBACK_CAL_SUCCESS,
        payload: [... obj]
    }
}

export const reqFallBackCalError = (obj) => {
    return {
        type: PagesActions.REQ_FALLBACK_CAL_ERROR,
        payload: obj
    }
}

export const addInactiveEmp = (obj) => {
    return {
        type: PagesActions.ADD_INACTIVE_EMP,
        payload: obj
    }
}

export const addInactiveEmpSuccess = (obj) => {
    return {
        type: PagesActions.ADD_INACTIVE_EMP_SUCCESS,
        payload: obj
    }
}

export const addInactiveEmpError = (obj) => {
    return {
        type: PagesActions.ADD_INACTIVE_EMP_ERROR,
        payload: obj
    }
}

export const getInactiveEmp = (obj) => {
    return {
        type: PagesActions.REQ_INACTIVE_EMP_LIST,
        payload: obj
    }
}

export const getInactiveEmpSuccess = (obj) => {
    return {
        type: PagesActions.REQ_INACTIVE_EMP_LIST_SUCCESS,
        payload: [...obj]
    }
}

export const getInactiveEmpError = (obj) => {
    return {
        type: PagesActions.REQ_INACTIVE_EMP_LIST_ERROR,
        payload: obj
    }
}

export default PagesActions;

import { connect } from "react-redux";
import {
    getGmbRequest
} from "./actions";

import Gmb from "./Gmb"

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Gmb
});

export const GetGmbPage = connect(
    mapStateToProps, { getGmbRequest }
)(Gmb);

import { createActionTypes } from "../../utils";

export const PartnerActions = createActionTypes("partner", [
    "FETCH_PARTNER_DOCUMENT_REQUEST",
    "FETCH_PARTNER_DOCUMENT_SUCCESS",
    "FETCH_PARTNER_DOCUMENT_ERROR",

    "FETCH_PARTNER_DOCUMENT_BYID_REQUEST",
    "FETCH_PARTNER_DOCUMENT_BYID_SUCCESS",
    "FETCH_PARTNER_DOCUMENT_BYID_ERROR",

    "CREATE_PARTNER_DOCUMENT_REQUEST",
    "CREATE_PARTNER_DOCUMENT_SUCCESS",
    "CREATE_PARTNER_DOCUMENT_ERROR",

    "UPDATE_PARTNER_DOCUMENT_REQUEST",
    "UPDATE_PARTNER_DOCUMENT_SUCCESS",
    "UPDATE_PARTNER_DOCUMENT_ERROR",

    "DOWNLOAD_PARTNER_DOCUMENT_REQUEST",
    "DOWNLOAD_PARTNER_DOCUMENT_SUCCESS",
    "DOWNLOAD_PARTNER_DOCUMENT_ERROR",

    "FETCH_PARTNER_DOCUMENT_BY_SECTION_REQUEST",
    "FETCH_PARTNER_DOCUMENT_BY_SECTION_SUCCESS",
    "FETCH_PARTNER_DOCUMENT_BY_SECTION_ERROR"
]);

export const fetchPartnerDocumentRequest = (data) => {
    return {
        type: PartnerActions.FETCH_PARTNER_DOCUMENT_REQUEST,
        payload: data
    };
};

export const fetchPartnerDocumentSuccess = (data) => {
    return {
        type: PartnerActions.FETCH_PARTNER_DOCUMENT_SUCCESS,
        payload: data
    };
};

export const fetchPartnerDocumentError = (data) => {
    return {
        type: PartnerActions.FETCH_PARTNER_DOCUMENT_ERROR,
        payload: data
    };
};

export const fetchPartnerDocumentByIdRequest = (data) => {
    return {
        type: PartnerActions.FETCH_PARTNER_DOCUMENT_BYID_REQUEST,
        payload: data
    };
};

export const fetchPartnerDocumentByIdSuccess = (data) => {
    return {
        type: PartnerActions.FETCH_PARTNER_DOCUMENT_BYID_SUCCESS,
        payload: data
    };
};

export const fetchPartnerDocumentByIdError = (data) => {
    return {
        type: PartnerActions.FETCH_PARTNER_DOCUMENT_BYID_ERROR,
        payload: data
    };
};

export const createPartnerDocumentRequest = (data) => {
    return {
        type: PartnerActions.CREATE_PARTNER_DOCUMENT_REQUEST,
        payload: data
    };
};

export const createPartnerDocumentSuccess = (data) => {
    return {
        type: PartnerActions.CREATE_PARTNER_DOCUMENT_SUCCESS,
        payload: data
    };
};

export const createPartnerDocumentError = (data) => {
    return {
        type: PartnerActions.CREATE_PARTNER_DOCUMENT_ERROR,
        payload: data
    };
};

export const updatePartnerDocumentRequest = (data) => {
    return {
        type: PartnerActions.UPDATE_PARTNER_DOCUMENT_REQUEST,
        payload: data
    };
};

export const updatePartnerDocumentSuccess = (data) => {
    return {
        type: PartnerActions.UPDATE_PARTNER_DOCUMENT_SUCCESS,
        payload: data
    };
};

export const updatePartnerDocumentError = (data) => {
    return {
        type: PartnerActions.UPDATE_PARTNER_DOCUMENT_ERROR,
        payload: data
    };
};

export const downloadPartnerDocumentRequest = (data, cb) => {
    return {
        type: PartnerActions.DOWNLOAD_PARTNER_DOCUMENT_REQUEST,
        payload: data,
        cb
    };
};

export const downloadPartnerDocumentSuccess = () => {
    return {
        type: PartnerActions.DOWNLOAD_PARTNER_DOCUMENT_SUCCESS
    };
};

export const downloadPartnerDocumentError = () => {
    return {
        type: PartnerActions.DOWNLOAD_PARTNER_DOCUMENT_ERROR
    };
};

export const fetchPartnerDocumentBySectionRequest = (data) => {
    return {
        type: PartnerActions.FETCH_PARTNER_DOCUMENT_BY_SECTION_REQUEST,
        payload: data
    };
};

export const fetchPartnerDocumentBySectionSuccess = (data) => {
    return {
        type: PartnerActions.FETCH_PARTNER_DOCUMENT_BY_SECTION_SUCCESS,
        payload: data
    };
};

export const fetchPartnerDocumentBySectionError = (data) => {
    return {
        type: PartnerActions.FETCH_PARTNER_DOCUMENT_BY_SECTION_ERROR,
        payload: data
    };
};

export default PartnerActions;
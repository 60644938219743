const constants = {
    "recurrenceType": {
        "single_session": "Single Session",
        "series": "Series",
        "sequence": "Sequence"
    },
    "experienceType": {
        "classic": "Classic",
        "broadcast": "Broadcast",
        "simulive": "Simulive"
    },
    "approvalType": {
        "AUTOMATIC": "Automatic",
        "MANUAL": "Manual"
    },
    "status": {
        "NEW": "New",
        "UPDATED": "Updated"
    },
    "tableColumns": [{
            "title": "Title",
            "key": "eventTitle",
            "dataIndex": "eventTitle",
            "sortAs": "string",
            "searchable": true,
            "width": "30%"
        },
        {
            "title": "Overlook",
            "key": "information",
            "dataIndex": "information",
            "width": "20%",
            "align": "center"
        },
        {
            "title": "Webinar Date",
            "key": "time",
            "dataIndex": "times",
            "sortAs": "string",
            "width": "20%",
            "align": "center"
        },
        {
            "title": "Last Updated",
            "key": "lastUpdated",
            "dataIndex": "lastUpdated",
            "width": "10%",
            "align": "center",
            "sortAs": "date",
            "defaultSortOrder": "descend"
        },
        {
            "title": "Action",
            "key": "action",
            "width": "20%",
            "align": "center"
        }
    ],
    "meetingsTableColumns": [{
        "title": "Title",
        "key": "eventTitle",
        "dataIndex": "eventTitle",
        "sortAs": "string",
        "searchable": true,
        "width": "45%"
    },
        {
            "title": "Meeting Date",
            "key": "time",
            "dataIndex": "times",
            "sortAs": "string",
            "width": "20%",
            "align": "center"
        },
        {
            "title": "Last Updated",
            "key": "lastUpdated",
            "dataIndex": "lastUpdated",
            "width": "15%",
            "align": "center",
            "sortAs": "date",
            "defaultSortOrder": "descend"
        },
        {
            "title": "Action",
            "key": "action",
            "width": "20%",
            "align": "center"
        }
    ],
    "registrantsTableColumns": [
        {
            "title": "Name",
            "key": "name",
            "dataIndex": "name",
            "sortAs": "string",
            "searchable": true,
            "width": "40%",
        },
        {
            "title": "Email",
            "key": "email",
            "dataIndex": "email",
            "sortAs": "string",
            "searchable": true,
            "align": "center",
            "width": "30%",
        },
        {
            "title": "Registration Date",
            "key": "registrationDate",
            "align": "center",
            "width": "30%",
        }
    ],
    "attendanceTableColumns": [
        {
            "title": "Status",
            "key": "isAttendee",
            "dataIndex": "formattedIsAttendee",
            "filters": [{ text: "Present", value: true }, { text: "Absent", value: false }],
            "onFilter": (value, record) => record.isAttendee === value,
            "width": "10%",
            "align": "center",
        },
        {
            "title": "Name",
            "key": "name",
            "dataIndex": "name",
            "sortAs": "string",
            "searchable": true,
            "width": "15%",
            "ellipsis": true,
            "align": "center",
        },
        {
            "title": "Email",
            "key": "email",
            "dataIndex": "email", 
            "searchable": true,
            "width": "17%",
            "ellipsis": true,
            "align": "center",
        },
        {
            "title": "Phone",
            "key": "phone",
            "dataIndex": "phone", 
            "width": "14%",
            "ellipsis": true,
            "align": "center",
        },
        {
            "title": "Company",
            "key": "company",
            "width": "16%",
            "ellipsis": true,
            "align": "center",
        },
        {
            "title": "Type",
            "key": "keyObjectType",
            "dataIndex": "formattedObjectType",
            "filters": [{ text: "Lead", value: "lead" }, { text: "Customer", value: "customer" }, { text: "Prospect", value: "prospect" }, { text: "N/A", value: "-" }],
            "onFilter": (value, record) => (record.keyObjectType === value),
            "width": "10%",
            "ellipsis": true,
            "align": "center",
        },
        {
            "title": "Salesforce",
            "key": "salesforceInfo",
            "width": "18%",
            "ellipsis": true,
            "align": "center",
        }
    ]
};

export default constants;
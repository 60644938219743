import { connect } from "react-redux";


import {
    fetchEbookListRequest, fetchEbookByIdRequest, addEbookRequest, updateEbookRequest,
    fetchWPListRequest, fetchWPByIdRequest, addWPRequest, updateWPRequest,
    fetchGuideListRequest, fetchGuideByIdRequest, addGuideRequest, updateGuideRequest,
    fetchArticleListRequest, fetchArticleByIdRequest, addArticleRequest, updateArticleRequest,
    fetchPodcastListRequest, fetchPodcastByIdRequest, addPodcastRequest, updatePodcastRequest, 
    deletePodcastRequest
} from "./actions";


import Ebook from "./Ebook/Ebook";
import Whitepaper from "./Whitepaper/Whitepaper";
import Guide from "./Guide/Guide";
import ArticleList from "./Article/ArticleList";
import ArticleForm from "./Article/ArticleForm";
import Podcast from "./Podcast/Podcast";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.ResourcesV2
});

export const EbookPage = connect(
    mapStateToProps,
    { fetchEbookListRequest, fetchEbookByIdRequest, addEbookRequest, updateEbookRequest }
)( Ebook );

export const WhitepaperPage = connect(
    mapStateToProps,
    { fetchWPListRequest, fetchWPByIdRequest, addWPRequest, updateWPRequest }
)( Whitepaper );

export const GuidePage = connect(
    mapStateToProps,
    { fetchGuideListRequest, fetchGuideByIdRequest, addGuideRequest, updateGuideRequest }
)( Guide );

export const ArticleListPage = connect(
    mapStateToProps,
    { fetchArticleListRequest, updateArticleRequest }
)(ArticleList);

export const ArticleFormPage = connect(
    mapStateToProps,
    { fetchArticleListRequest, fetchArticleByIdRequest, addArticleRequest, updateArticleRequest }
)(ArticleForm);

export const PodcastPage = connect(
    mapStateToProps,
    { fetchPodcastListRequest, fetchPodcastByIdRequest, addPodcastRequest, updatePodcastRequest, deletePodcastRequest }
) ( Podcast );

const resource = [
    {label: "Case Study", value: "case-study"},
    {label: "Guide", value: "guide"},
    {label: "Video", value: "video"},
    {label: "Webinar", value: "webinar"},
    {label: "Podcast", value: "podcast"},
    {label: "Press Release", value: "press"}
];

export default {
    resource,
    tableCols: [
        {
            title: "Target",
            key: "rawTarget",
            filters: [
                {text: "Main", value: "main"},
                ...resource.map(({ label, value }) => ({ text: label, value }))
            ],
            onFilter: (value, record) => (record.rawTarget === value),
            dataIndex: "target",
            align: "center",
            width: "15%"
        },
        {
            title: "Feature",
            key: "rawFeature",
            filters: resource.map(({ label, value }) => ({ text: label, value })),
            onFilter: (value, record) => (record.rawFeature === value),
            dataIndex: "feature",
            align: "center",
            width: "15%"
        },
        {
            title: "Heading",
            key: "heading",
            searchable: true
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            width: "15%"
        }
    ],
    imageResolutions: [
        { key: "1x", pd: "" },
        { key: "2x", pd: "2x" },
        { key: "3x", pd: "3x" },
    ],
    productPage: [
        { text: "Listings", value: "listings" },
        { text: "Review Generation", value: "review-generation" },
        { text: "Review Monitoring", value: "review-monitoring" },
        { text: "Review Management", value: "review-management" },
        { text: "Review Marketing", value: "review-marketing" },
        { text: "Pages", value: "pages" },
        { text: "Referrals", value: "referrals" },
        { text: "Interactions", value: "interactions" },
        { text: "Webchat", value: "webchat" },
        { text: "Unified Inbox", value: "inbox" },
        { text: "Video Chat", value: "video-chat" },
        { text: "Receptionist", value: "receptionist" },
        { text: "Mass Texting", value: "mass-texting" },
        { text: "Surveys", value: "surveys" },
        { text: "Insights", value: "insights" },
        { text: "Benchmarking", value: "benchmarking" },
        { text: "Ticketing", value: "ticketing" },
        { text: "Partners - Resellers", value: "resellers" }
    ],
    byPageTypeTableCols: [
        {
            title: "Page",
            key: "formattedSlug",
            width: "80%"
        }, 
        {
            title: "Action",
            key: "action",
            align: "center",
            width: "20%"
        }, 
    ]
};

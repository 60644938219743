import React, { Component } from "react";
import { Form, Button, Input, Select, Spin, Icon, Tooltip } from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";
import { addDynamicScripts } from "../../utils";
import { pick, find, isEmpty, pickBy } from 'lodash';
import lookup from "./constants";
const FormItem = Form.Item;
const { Option } = Select;

const googlePlacesFields = [
    "address_components",
    "formatted_address",
    "formatted_phone_number",
    "name",
    "place_id",
    "url",
    "vicinity",
    "types",
    "geometry",
    "website"
];

const initialState = {
    businessName: "",
    businessNumber: null,
    zip: "",
    industry: "",
    businessAddress: "",
    countryCode: "",
    country: "",
    businessPhone: "",
    sessionToken: "",
    suggestions: [],
    activeSuggestionIndex: 0,
    isSelected: false,
    businessNotFound: false,
    city: "",
    street: "",
    state: "",
    website: "",
    placeId: "",
    latitude: "",
    longitude: "",
    keyword: "",
    customKeyword: "",
    industryKeywords: []
}

const menuItem = {
    display: "block",
    background: "#F8F8F8",
    margin: "10px",
    lineHeight: "30px",
    borderRadius: "5px"
}

const selectedItem = {
    border: "solid 1px #2196f3",
    userSelect: "none",
    cursor: "pointer",
    display: "block",
    background: "#F8F8F8",
    margin: "10px",
    lineHeight: "30px",
    borderRadius: "5px"
}

class Scan extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
        this.removedIndustries = ["point_of_interest"];
    }    

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields(["businessName", ,"keyword", "customKeyword","industry","businessAddress","businessPhone","countryCode","zip"], (error, values) => {
            
            if(!error) {
                const { businessNotFound } = this.state;
                let data = pick(this.state,["businessName", "businessPhone", "zip", "phone", "city", "street", "businessAddress", "state", "emailId", "countryCode", "industry", "website", "placeId", "latitude", "longitude", "customKeyword", "keyword"]);
                const customKeywordValue = values && values.customKeyword ? values.customKeyword : "";
                const keywordValue = values && values.keyword ? values.keyword : "";
                const businessPhone = values && values.businessPhone ? values.businessPhone : "";
                const businessAddress = values && values.businessAddress ? values.businessAddress : "";
                const zip = values && values.zip ? values.zip : "";
                const countryCode = values && values.countryCode ? values.countryCode : "";
                const industry = values && values.industry ? values.industry : "";

                let signupData = {
                    businessName : data.businessName,
                    phone : businessNotFound ? businessPhone : data.businessPhone,
                    zip : businessNotFound ? zip : (data.zip || ""),
                    type : "Business",
                    city: data.city,
                    address: businessNotFound ? businessAddress : (data.street || this.state.businessAddress),
                    state: data.state,
                    userEmailId: data.emailId,
                    countryCode: businessNotFound ? countryCode : (data.countryCode),
                    industry: businessNotFound ? industry : (data.industry || null),
                    websiteUrl: data.website || null,
                    placeId: businessNotFound ? "" : (data.placeId || ""),
                    latitude: data.latitude || null,
                    longitude: data.longitude || null,
                    industryKeyword: businessNotFound ? industry : (customKeywordValue || keywordValue || "")
                };

                signupData = pickBy(signupData, (val) => {
                    return val !== "" && val !== null && val !== undefined;
                });

                this.setState({ industry: signupData.industry, keyword: signupData.industryKeyword, customKeyword: signupData.industryKeyword, countryCode: signupData.countryCode, zip: signupData.zip, businessAddress: signupData.address, businessPhone: signupData.phone, placeId: signupData.placeId }, () => {
                    this.props.reqBusinessDetails(signupData);
                });
            }
        })
        
    }

    handleAutocomplete = (value) => {
        const google = window.google;
        const sessionToken = this.state.sessionToken || new google.maps.places.AutocompleteSessionToken();
        this.autocomplete = new google.maps.places.AutocompleteService();
        this.autocomplete.getPlacePredictions({
            input: value,
            sessionToken
        }, this.updatePlaceSuggestions);
        if (!this.state.sessionToken) {
            this.setState({ sessionToken });
        }
    };

    componentDidMount() {
        this.setState({emailId: this.props.userData.name})
        document.addEventListener("mouseup", this.closeDropdown);
        this.addPlacesAPI();
    }

    componentWillUnmount() {
        document.removeEventListener("mouseup", this.closeDropdown);
        document.removeEventListener("keydown", this.updateActiveSuggestion);
        this.addPlacesAPI();
    }

    componentDidUpdate(prevProps) {

        if (this.props.businessDetails && prevProps.businessDetails !== this.props.businessDetails) {
            const { onlineListingBusinessUrl } = this.props.businessDetails;
            
            if(onlineListingBusinessUrl) {
                window.open(onlineListingBusinessUrl, "_blank");
                this.setState(initialState);
                this.props.form.resetFields();
            }
        }
    }

    addPlacesAPI = () => {
        addDynamicScripts(`${process.env.REACT_APP_GOOGLE_URL}?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&language=en&region=US`, "google-places-api");
    }

    handleBusinessSearch = (e) => {
        const value = e.target.value;
        if(value === ""){
            this.setState({street: null, city: null, state: null, zip: null, countryCode: null, industryKeywords: [], keyword: "", customKeyword: "" });
        }
        this.setState({ businessName: value, isSelected: (value === "" ? true : false) });
        this.handleAutocomplete(value);
    }

    updatePlaceSuggestions = (predictions, status) => {
        const google = window.google
        let suggestions = [];
        const manualInputOption = {
            "place_id": "",
            "structured_formatting": {
                "main_text": "Can't find your business?",
                "secondary_text": "Add your business manually"
            }
        };

        if (status == google.maps.places.PlacesServiceStatus.OK) {
            suggestions = predictions.map(suggestion => pick(suggestion, ["place_id", "structured_formatting"]));
        }
        
        suggestions.push(manualInputOption);
        this.setState({ suggestions });
        document.addEventListener("keydown", this.updateActiveSuggestion);
    };

    updateActiveSuggestion = (e) => {
        const { suggestions } = this.state;
        let { activeSuggestionIndex } = this.state;
        const listLength = suggestions.length;
        let activeSuggestion;

        if (e.keyCode === 38) {
            if (activeSuggestionIndex - 1 < 0) {
                activeSuggestionIndex = listLength - 1;
            } else {
                activeSuggestionIndex = activeSuggestionIndex - 1;
            }
        } else if (e.keyCode === 40) {
            if (activeSuggestionIndex + 1 >= listLength) {
                activeSuggestionIndex = 0;
            } else {
                activeSuggestionIndex = activeSuggestionIndex + 1;
            }
        } else if (e.keyCode !== 13 && e.keyCode !== 9) {
            activeSuggestionIndex = 0;
            this.setState({ activeSuggestionIndex });
            return;
        }

        activeSuggestion = suggestions[activeSuggestionIndex];
        this.setState({ activeSuggestionIndex, businessName: this.getUpdatedBusinessName(activeSuggestion) });

        if (e.keyCode === 13 || e.keyCode === 9) {
            if (activeSuggestion) {
                this.updateWithAutoCompResult(activeSuggestion.place_id);
            } else {
                this.closeDropdown(e);
            }
        }
    };

    updateActiveSuggestionOnHover = (index) => {
        this.setState({ activeSuggestionIndex: index });
    };

    getKeywordSuggestions=(types)=>{
        let keywords = types
            .filter(type => ["point_of_interest", "establishment"].indexOf(type) === -1)
            .map(keyword => keyword.replace(/_/g, " ").trim())
            .map(keyword => (keyword.charAt(0).toUpperCase() + keyword.slice(1)) );
        
        return keywords;
    }

    updatePlaceDetails = (place, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
            const types = this.getKeywordSuggestions(place.types);
            const zipObj = place.address_components ? find(place.address_components, {"types": ["postal_code"]}) || {} : {};
            const zip = zipObj.short_name || "";
            const countryObj = place.address_components ? find(place.address_components, {"types": ["country", "political"]}) || {} : {};
            const countryCode = countryObj.short_name || "";
            const country = countryObj.long_name || "";
            const stateObj = place.address_components ? find(place.address_components, {"types": ["administrative_area_level_1", "political"]}) || {} : {};
            const state = stateObj.short_name || stateObj.long_name || "";
            const website = place.website || "";
            let cityObj = {};
            if (countryCode === "GB") {
                cityObj = place.address_components ? find(place.address_components, {"types": ["postal_town"]}) || {} : {};
            } else {
                cityObj = place.address_components ? find(place.address_components, {"types": ["locality", "political"]}) || {} : {};
            }
            const city = cityObj.long_name || "";
            const street = place.vicinity && place.vicinity.split(",").slice(0, -1).join(",");
            let industry = place.types.length ? place.types[0] : "Other";
            let businessSugObj = find(this.state.suggestions, { "place_id": place.place_id });
            if (isEmpty(businessSugObj)) {
                businessSugObj = {
                    "structured_formatting": {
                        "secondary_text": `${place["vicinity"]}, ${state}, ${countryCode}`
                    }
                };
            }
            const placeId = place.place_id || "";
            let latitude = place.geometry && place.geometry.location && place.geometry.location.lat();
            latitude = parseFloat(latitude.toFixed(7));
            let longitude = place.geometry && place.geometry.location && place.geometry.location.lng();
            longitude = parseFloat(longitude.toFixed(7));
            let businessPhoneNumber = place.formatted_phone_number || "";
            let businessAddress = businessSugObj && businessSugObj.structured_formatting.secondary_text || "";

            this.setState({
                sessionToken: "",
                suggestions: [],
                activeSuggestionIndex: 0,
                businessName: place.name,
                businessPhone: businessPhoneNumber,
                businessAddress,
                zip,
                industry: industry && this.removedIndustries.indexOf(industry) > -1 ? "" : industry,
                industryKeywords: types,
                keyword: types.length > 0 ? types[0]: "",
                customKeyword: "",
                countryCode,
                country,
                city,
                street,
                state,
                website,
                placeId,
                latitude,
                longitude
            });
            document.removeEventListener("keydown", this.updateActiveSuggestion);
        }
    }

    fetchBusinessInfoByPlaceID = (placeId, saveToCookie = false) => {
        const google = window.google
        try {
            const placeDetailRequest = {
                placeId,
                fields: googlePlacesFields,
                sessionToken: this.state.sessionToken || new google.maps.places.AutocompleteSessionToken()
            };
            const service = new google.maps.places.PlacesService(document.getElementById("businessInputField"));
            service.getDetails(placeDetailRequest, (place, status) => this.updatePlaceDetails(place, status, saveToCookie));
        } catch (e) {
            console.log(e,'error')
            // todo: log error
        }
    }

    updateWithAutoCompResult = (placeId) => {
        this.setState({isSelected: true})
        if (placeId) {
            this.fetchBusinessInfoByPlaceID(placeId);
            this.setState({businessNotFound: false})
        }
        else {
            this.setState({street: null, city: null, state: null, zip: null, countryCode: null});
            const { suggestions, activeSuggestionIndex } = this.state
            const activeSuggestion = suggestions[activeSuggestionIndex];
            this.getUpdatedBusinessName(activeSuggestion);
            this.setState({businessNotFound: true})
        }
        document.removeEventListener("keydown", this.updateActiveSuggestion);
    };

    closeDropdown = (e) => {
        const { suggestions, activeSuggestionIndex } = this.state;
        if (suggestions.length && (e.keyCode === 13 || e.keyCode === 9 || !e.target.className.includes("menuItem"))) {
            this.updateWithAutoCompResult(suggestions[activeSuggestionIndex] && suggestions[activeSuggestionIndex].place_id);
        }
    };

    getUpdatedBusinessName = (activeSuggestion) => {
        const { suggestions, businessName } = this.state;
        let updatedBusinessName = businessName;

        if (activeSuggestion) {
            if (activeSuggestion.place_id) {
                updatedBusinessName = activeSuggestion.structured_formatting.main_text;
            } else {
                if (find(suggestions, {"structured_formatting": {"main_text": businessName}})) {
                    updatedBusinessName = "";
                }
            }
        }

        return updatedBusinessName;
    };

    render() { 
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { errorMessage } = this.props;
        const { suggestions, businessName, isSelected,  activeSuggestionIndex, industryKeywords, keyword } = this.state;
        const otherKeyword = getFieldValue('keyword');
        const suggestedKeywords = isEmpty(industryKeywords) && isEmpty(businessName) ? industryKeywords : [ ...industryKeywords, "Other" ];
        const {industry, country} = lookup;
        const errorResponse =  (errorMessage.code && errorMessage.code === 9000) ? "Something went wrong, please try again later.": errorMessage.message;
        return (
            <div>
                <h2 style={{marginLeft: '25px'}}>Scan how a business shows up online</h2>
                <Spin spinning={this.props.isLoading}>
                    <Form {...formItemLayout} style={{width: '62%', float: 'left', height: '100vh', display: 'inline-block'}} onKeyPress={event => { if (event.which === 13 /* Enter */) { event.preventDefault(); }}} onSubmit={this.handleSubmit}>
                        <Form.Item  label="Business Name">
                            {   getFieldDecorator('businessName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please provide business name"
                                        },
                                    ]
                                })(
                                    <div>
                                        <Input
                                            autoComplete="off"
                                            id={'businessInputField'}
                                            style={{width: "100%"}}
                                            name={"businessName"}
                                            placeholder={"Enter Business Name"}
                                            onChange={this.handleBusinessSearch}
                                            value={businessName}
                                        />
                                        {
                                            !!suggestions.length && !isSelected &&
                                            <ul  style={{ padding: "1px", boxShadow: "2px 2px 5px", margin: "3px", position: "absolute", zIndex: "1", width: "100%", background: "white" }}>
                                                {
                                                    suggestions.map((suggest, index) => (
                                                        <li key={suggest.place_id}
                                                            style={activeSuggestionIndex === index ? selectedItem : menuItem}
                                                            onClick={() => this.updateWithAutoCompResult(suggest.place_id)}
                                                            onMouseEnter={() => this.updateActiveSuggestionOnHover(index)}>
                                                            <div style={{lineHeight: "26px"}}>
                                                                <span style={{fontSize: "14px", marginLeft: "10px", display: "inline-table"}}><b>{suggest.structured_formatting.main_text}</b></span><br/>
                                                                <span style={{color: "#999999", marginLeft: "10px", display: "block", width: "397px", whiteSpace: "noWrap", overflow: "hidden", textOverflow: "ellipsis"}}>{suggest.structured_formatting.secondary_text}</span>
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        }
                                    </div>
                                )
                            }
                        </Form.Item>
                        {
                            !this.state.businessNotFound && !isEmpty(businessName) && (
                                    <Form.Item {...formItemLayout} label="Business Keyword" >
                                        {   getFieldDecorator('keyword', {
                                                initialValue: keyword
                                            })(
                                                <Select onChange={this.handleFieldUpdate} style={{width: "94%"}} placeholder="Select Business Category">
                                                    {!isEmpty(suggestedKeywords) && suggestedKeywords.map((industry) => (
                                                        <Option key={industry} value={industry} name="keyword">
                                                            {industry}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            )
                                        }
                                        <Tooltip title="Select the keyword that best describes this business" >
                                            <Icon type="info-circle" theme="filled" style={{ fontSize: "17px", color: "#0049B0", marginLeft: "7px" }} />
                                        </Tooltip>
                                    </Form.Item>
                            )
                        }
                        {
                            otherKeyword === 'Other' && !this.state.businessNotFound && !isEmpty(businessName) && (
                                <Form.Item {...formItemLayout} label="Add New Keyword">
                                    {   getFieldDecorator('customKeyword', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Please provide business keyword"
                                                },
                                            ]
                                        })(
                                            <Input placeholder="Enter Business Keyword" name="customKeyword" />
                                        )
                                    }
                                </Form.Item>
                            )
                        }
                        {
                            this.state.businessNotFound && !isEmpty(businessName) && <>
                                <Form.Item {...formItemLayout} label="Industry">
                                    {getFieldDecorator('industry', {
                                        rules: [{ required: true, message: 'Please select industry' }]
                                    })(
                                        <Select placeholder="Select Industry">
                                            {!isEmpty(industry) && industry.map((ele) => (
                                                <Option key={ele.value} value={ele.value}>
                                                    {ele.label}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Business Phone">
                                    {getFieldDecorator('businessPhone', {
                                        rules: [
                                            {
                                                pattern: "^\\+?(1|61|44|63)? ?\\(?\\d{3}\\)? ?\\d{3}( |\\-)?\\d{3,4}$",
                                                message: "Please provide valid phone number"
                                            },
                                            {
                                                required: true,
                                                message: "Please provide business phone"
                                            },
                                        ],
                                    })(
                                        <Input placeholder="Enter Business Phone"/>
                                    )}
                                </Form.Item>
                                <Form.Item  {...formItemLayout} label="Business Address">
                                    {getFieldDecorator('businessAddress', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please provide business address"
                                            },
                                        ],
                                    })(<Input placeholder="Enter Business Address" />)}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Business Zip">
                                    {getFieldDecorator('zip', {
                                        rules: [
                                            {
                                                pattern: "^(\\d{5}|\\d{4}|(?!.*[IOU])[A-ZXY][A-Z0-9][0-9A-Z] ?[0-9][0-9A-Z][0-9A-Z])$",
                                                message: "Please provide valid zip code"
                                            },
                                            {
                                                required: true,
                                                message: "Please provide business zip code"
                                            },
                                        ],
                                    })(
                                        <Input placeholder="Enter Business Zip Code"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Country">
                                    {getFieldDecorator('countryCode', {
                                        rules: [{ required: true, message: 'Please select country' }]
                                    })(
                                        <Select placeholder="Select Country">
                                            {!isEmpty(country) && country.map((ele) => (
                                                <Option key={ele.value} value={ele.value}>
                                                    {ele.label}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </>
                        }
                        <FormItem {...formButtonLayout}>
                            <Button type="primary" htmlType="submit">
                                Scan this business
                            </Button>
                        </FormItem>
                        {
                            <FormItem {...formButtonLayout}>
                                <p style={{color: "#ff4d4f", marginTop: "-22px" }}>{errorResponse}</p>
                            </FormItem>
                        }
                    </Form>
                </Spin>
            </div> 
        )
    }
}

export default Scan; 
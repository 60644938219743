import React, { Component } from 'react';
import { Tabs } from 'antd';
import PropTypes from "prop-types";
import InactiveEmp from './InactiveEmp';
import History  from './History';
import { RedirectWrapper, PageHeaderContainer } from "../../components";
import { Form } from "antd";
const { TabPane } = Tabs;


class InactiveEmployeesTab extends Component {
    
    pageKey="inactiveEmployeesList";

    handleInactiveEmpHistory = (key) => { 
      if(key === "historyTab") {
        const email = this.props.userData.name;
        this.props.getInactiveEmp({ email });
      }
    }

    render() {
      const { userData, errorMessage, form, addInactiveEmp, inactiveEmpList, isLoading} = this.props;
      const unauthorized = errorMessage === "unauthorized" || !userData.name;
      if (unauthorized) {
            return <RedirectWrapper location={this.props.location}/>;
      }

      return (
        <>
          <PageHeaderContainer pageKey={this.pageKey} />
          <Tabs defaultActiveKey="Inactive employees" onTabClick={(key) => this.handleInactiveEmpHistory(key)}>
              <TabPane tab="Inactive employees" key="inactiveTab">
                  <InactiveEmp form={form} addInactiveEmp={addInactiveEmp} userData = {userData} isLoading={isLoading} />
              </TabPane>
              <TabPane tab="History" key="historyTab">
                <History  inactiveEmpList={inactiveEmpList} isLoading={isLoading}/>
              </TabPane>
          </Tabs>
        </>
      )
    }
}


const InactiveEmployees = Form.create()(InactiveEmployeesTab);

InactiveEmployees.propTypes = {
  addInactiveEmp: PropTypes.func.isRequired,
  getInactiveEmp: PropTypes.func.isRequired
};

export default InactiveEmployees; 
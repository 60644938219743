import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import IntegrationsActions, * as actionCreator from "./actions";
import integrationApi from "./http";

const integrationSagas = [
    takeLatest(
        IntegrationsActions.FETCH_INTEGRATIONS_LIST_REQUEST,
        fetchIntegrationsList
    ),
    takeLatest(IntegrationsActions.FETCH_INTEGRATION_REQUEST, fetchIntegration),
    takeLatest(IntegrationsActions.ADD_INTEGRATION_REQUEST, addIntegration),
    takeLatest(IntegrationsActions.UPDATE_INTEGRATION_REQUEST, updateIntegration),
    takeEvery(IntegrationsActions.DELETE_INTEGRATION_REQUEST, deleteIntegration),
    takeEvery(IntegrationsActions.DOWNLOAD_INTEGRATION_CSV_REQ, downloadCsv)
];

function* fetchIntegrationsList() {
    try {
        const resp = yield call(integrationApi.getAll);
        yield put(actionCreator.fetchIntegrationsListSucess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.integrationError(e.data.data || { error: "integrationsListNotFound" })
        );
    }
}

function* fetchIntegration(action) {
    try {
        const resp = yield call(integrationApi.getById, action.payload.id);
        yield put(actionCreator.fetchIntegrationSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.integrationError(e.data.data || { error: "integrationNotFound" }));
    }
}

function* addIntegration(action) {
    try {
        const resp = yield call(integrationApi.add, action.payload.data);
        yield put(actionCreator.addIntegrationSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.integrationError(e.data.data || { error: "cannotAdd" }));
        yield call(action.payload.cb, "cannotAdd");
    }
}

function* updateIntegration(action) {
    try {
        const resp = yield call(integrationApi.update, action.payload.data);
        yield put(actionCreator.updateIntegrationSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.integrationError(e.data.data || { error: "cannotUpdate" }));
        yield call(action.payload.cb, "cannotUpdate");
    }
}

function* deleteIntegration(action) {
    const { id } = action.payload;
    try {
        yield call(integrationApi.delete, id);
        yield put(actionCreator.deleteIntegrationSuccess({ id }));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.integrationError(e.data.data || { error: "cannotDelete" }));
        yield call(action.payload.cb, "cannotDelete");
    }
}

function* downloadCsv() {
    try {
        const resp = yield call(integrationApi.downloadCsvIntegration);
        yield put(actionCreator.downloadIntegrationCsvSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.integrationError([]));
    }
}

export default integrationSagas;

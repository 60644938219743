import { beAPIResource } from "../../utils/apiHelper";

const resourceApi = {
    getAllVideos: () => beAPIResource.get("support/resource/videos"),
    getVideoById: (id) => beAPIResource.get(`support/resource/videos/${id}`),
    addVideo: (data) => beAPIResource.post("support/resource/videos", data),
    updateVideo: (data) => beAPIResource.put(`support/resource/videos/${data.id}`, data),
    deleteVideo: (id) => beAPIResource.delete(`support/resource/videos/${id}`),
    getAllVideoTutorials: () => beAPIResource.get("support/resource/video-tutorials"),
    getVideoTutorialById: (id) => beAPIResource.get(`support/resource/video-tutorials/${id}`),
    addVideoTutorial: (data) => beAPIResource.post("support/resource/video-tutorials", data),
    updateVideoTutorial: (data) => beAPIResource.put(`support/resource/video-tutorials/${data.id}`, data),
    deleteVideoTutorial: (id) => beAPIResource.delete(`support/resource/video-tutorials/${id}`),
    getAllCaseStudies: () => beAPIResource.get("support/resource/case-studies"),
    getCaseStudyById: (id) => beAPIResource.get(`support/resource/case-studies/${id}`),
    addCaseStudy: (data) => beAPIResource.post("support/resource/case-studies/", data),
    updateCaseStudy: (data) => beAPIResource.put(`support/resource/case-studies/${data.id}`, data),
    deleteCaseStudy: (id) => beAPIResource.delete(`support/resource/case-studies/${id}`),
    getIntegrationList: () => beAPIResource.get('support/resource/case-studies/list/integration'),
    getCompetitorList: () => beAPIResource.get('support/resource/case-studies/list/competitor'),
    getAll: () => beAPIResource.get("support/resources/"),
    getById: (id) => beAPIResource.get(`support/resources/${id}`),
    add: (data) => beAPIResource.post("support/resources/", data),
    update: (data) => beAPIResource.put(`support/resources/${data.id}`, data),
    delete: (id) => beAPIResource.delete(`support/resources/${id}`),
    getCaseStudy: (id) => beAPIResource.get(`support/case-study/${id}/blocks`),
    getCaseStudyBlock: (id) => beAPIResource.get(`support/case-study/block/${id}`),
    addCaseStudyBlock: (caseStudyId, data) =>  beAPIResource.post(`support/case-study/${caseStudyId}/blocks`, data),
    updateCaseStudyBlock: (data) =>  beAPIResource.put(`support/case-study/blocks/${data.id}`, data),
    insertCaseStudyBlock: ({caseStudyId, insertAtPosition, data}) =>  beAPIResource.post(`support/case-study/${caseStudyId}/blocks/insert/${insertAtPosition}`, data),
    deleteCaseStudyBlock: (id) =>  beAPIResource.delete(`support/case-study/${id}`),
    getSlugOccurene: (slug) => beAPIResource.get("support/resources/url/occurence", { slug })
};

export default resourceApi;

import { connect } from "react-redux";

import { reqFeatureResource, reqAvailableFeatureResource, reqUpdateFeatureResource,
    reqDeleteFeatureResource, reqAddFeatureResource, reqFeatureResourceByPage, reqAddFeatureResourceByPage,
    reqUpdateFeatureResourceByPage, reqDeleteFeatureResourceByPage } from "./actions";

import { reqFeaturePagesList } from "../FeaturePages/actions";
import Feature from "./Feature";
import List from "./List";
import FeatureFormByPageType from "./FeatureFormByPageType";
import ListByPageType from "./ListByPageType";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.FeatureResource,
    ...state.FeaturePages
});

export const FeaturePage = connect(
    mapStateToProps,
    { reqFeatureResource, reqAvailableFeatureResource, reqUpdateFeatureResource, reqAddFeatureResource }
)(Feature);

export const FeatureListPage = connect(
    mapStateToProps,
    { reqFeatureResource, reqDeleteFeatureResource }
)(List);

export const FeatureFormByPageTypePage = connect(
    mapStateToProps,
    { reqAvailableFeatureResource, reqAddFeatureResourceByPage, reqUpdateFeatureResourceByPage, reqFeaturePagesList }
)(FeatureFormByPageType);

export const FeatureListByPageTypePage = connect(
    mapStateToProps,
    { reqFeatureResourceByPage, reqDeleteFeatureResourceByPage, reqFeaturePagesList }
)(ListByPageType);

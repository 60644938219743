import { takeLatest, call, put } from "redux-saga/effects";
import ACTIVITY_ACTIONS, * as actionCreator from "./actions";
import {fetchActivitiesList} from "./actions";
import activityAPI from "./http";
import { notification } from 'antd';
import {toSearchQueryString} from './activityAPIHelper';

import {
    getQueryStringParams,
    setQueryParamString
} from '../../utils';

function* fetchPagesList({payload: {searchParams}}) {
    try {
        const
            searchQueryString = toSearchQueryString(searchParams),
            resp = yield call(activityAPI.getAll, searchQueryString);

        yield put(actionCreator.activityListFetched({
            data : resp.data.data,
            total: resp.data.total,
            ...searchParams
        }));
        setQueryParamString(searchQueryString);
    } catch (e) {
        yield put(notification.error({message: 'Failed to fetch data', description: e.message}));
    }
}

function* fetchPagesListFromRoute() {
    const searchParams = getQueryStringParams();
    searchParams.pageSize  = parseInt(searchParams.pageSize  || '10');
    searchParams.pageIndex = parseInt(searchParams.pageIndex || '1');
    yield put(fetchActivitiesList(searchParams));
}

export default [
    takeLatest(ACTIVITY_ACTIONS.FETCH_ACTIVITY_LIST, fetchPagesList),
    takeLatest(ACTIVITY_ACTIONS.FETCH_ACTIVITY_LIST_FROM_ROUTE, fetchPagesListFromRoute)
];

import { createActionTypes } from "../../utils";

export const ResourcesActions = createActionTypes("resources", [
    "FETCH_RESOURCES_LIST_REQUEST",
    "FETCH_RESOURCES_LIST_SUCCESS",
    "FETCH_RESOURCE_REQUEST",
    "FETCH_RESOURCE_SUCCESS",
    "ADD_RESOURCE_REQUEST",
    "ADD_RESOURCE_SUCCESS",
    "UPDATE_RESOURCE_REQUEST",
    "UPDATE_RESOURCE_SUCCESS",
    "DELETE_RESOURCE_REQUEST",
    "DELETE_RESOURCE_SUCCESS",
    "RESOURCE_ERROR",
    "FETCH_CASE_STUDY_REQUEST",
    "FETCH_CASE_STUDY_SUCCESS",
    "FETCH_CASE_STUDY_BLOCK_REQUEST",
    "FETCH_CASE_STUDY_BLOCK_SUCCESS",
    "ADD_CASE_STUDY_BLOCK_REQUEST",
    "ADD_CASE_STUDY_BLOCK_SUCCESS",
    "UPDATE_CASE_STUDY_BLOCK_REQUEST",
    "UPDATE_CASE_STUDY_BLOCK_SUCCESS",
    "DELETE_CASE_STUDY_BLOCK_REQUEST",
    "DELETE_CASE_STUDY_BLOCK_SUCCESS",
    "INSERT_CASE_STUDY_BLOCK",
    "FETCH_INTEGRATION_LIST_REQUEST",
    "FETCH_INTEGRATION_LIST_SUCCESS",
    "FETCH_INTEGRATION_LIST_ERROR",
    "FETCH_COMPETITOR_LIST_REQUEST",
    "FETCH_COMPETITOR_LIST_SUCCESS",
    "FETCH_COMPETITOR_LIST_ERROR",
    "FETCH_VIDEO_REQUEST",
    "FETCH_VIDEO_SUCCESS",
    "FETCH_VIDEO_LIST_REQUEST",
    "FETCH_VIDEO_LIST_SUCCESS",
    "ADD_VIDEO_REQUEST",
    "ADD_VIDEO_SUCCESS",
    "UPDATE_VIDEO_REQUEST",
    "UPDATE_VIDEO_SUCCESS",
    "DELETE_VIDEO_REQUEST",
    "DELETE_VIDEO_SUCCESS",
    "FETCH_VIDEO_TUTORIALS_LIST_REQUEST",
    "FETCH_VIDEO_TUTORIALS_LIST_SUCCESS",
    "FETCH_VIDEO_TUTORIAL_REQUEST",
    "FETCH_VIDEO_TUTORIAL_SUCCESS",
    "ADD_VIDEO_TUTORIAL_REQUEST",
    "ADD_VIDEO_TUTORIAL_SUCCESS",
    "DELETE_VIDEO_TUTORIAL_REQUEST",
    "DELETE_VIDEO_TUTORIAL_SUCCESS",
    "UPDATE_VIDEO_TUTORIAL_REQUEST",
    "UPDATE_VIDEO_TUTORIAL_SUCCESS",

    // COMMON
    "FETCH_SLUG_OCCURENCE_REQUEST",
    "FETCH_SLUG_OCCURENCE_SUCCESS",
    "FETCH_SLUG_OCCURENCE_ERROR",
]);

export const fetchResourcesListRequest = () => {
    return {
        type: ResourcesActions.FETCH_RESOURCES_LIST_REQUEST
    };
};

export const fetchResourcesListSucess = (obj) => {
    return {
        type: ResourcesActions.FETCH_RESOURCES_LIST_SUCCESS,
        payload: obj
    };
};

export const fetchResourceRequest = (obj) => {
    return {
        type: ResourcesActions.FETCH_RESOURCE_REQUEST,
        payload: obj
    };
};

export const fetchResourceSuccess = (obj) => {
    return {
        type: ResourcesActions.FETCH_RESOURCE_SUCCESS,
        payload: obj
    };
};

export const addResourceRequest = (obj) => {
    return {
        type: ResourcesActions.ADD_RESOURCE_REQUEST,
        payload: obj
    };
};

export const addResourceSuccess = (obj) => {
    return {
        type: ResourcesActions.ADD_RESOURCE_SUCCESS,
        payload: obj
    };
};

export const updateResourceRequest = (obj) => {
    return {
        type: ResourcesActions.UPDATE_RESOURCE_REQUEST,
        payload: obj
    };
};

export const updateResourceSuccess = (obj) => {
    return {
        type: ResourcesActions.UPDATE_RESOURCE_SUCCESS,
        payload: obj
    };
};

export const deleteResourceRequest = (obj) => {
    return {
        type: ResourcesActions.DELETE_RESOURCE_REQUEST,
        payload: obj
    };
};

export const deleteResourceSuccess = (obj) => {
    return {
        type: ResourcesActions.DELETE_RESOURCE_SUCCESS,
        payload: obj
    };
};

export const resourceError = (obj) => {
    return {
        type: ResourcesActions.RESOURCE_ERROR,
        payload: obj
    };
};


// for case studies

export const fetchCaseStudyRequest = (obj) => {
    return {
        type: ResourcesActions.FETCH_CASE_STUDY_REQUEST,
        payload: obj
    };
};

export const fetchCaseStudySuccess = (obj) => {
    return {
        type: ResourcesActions.FETCH_CASE_STUDY_SUCCESS,
        payload: obj
    };
};

export const fetchCaseStudyBlockRequest = (obj) => {
    return {
        type: ResourcesActions.FETCH_CASE_STUDY_BLOCK_REQUEST,
        payload: obj
    };
};

export const fetchCaseStudyBlockSuccess = (obj) => {
    return {
        type: ResourcesActions.FETCH_CASE_STUDY_BLOCK_SUCCESS,
        payload: obj
    };
};

export const addCaseStudyBlockRequest = (obj) => {
    return {
        type: ResourcesActions.ADD_CASE_STUDY_BLOCK_REQUEST,
        payload: obj
    };
};

export const addCaseStudyBlockSuccess = (obj) => {
    return {
        type: ResourcesActions.ADD_CASE_STUDY_BLOCK_SUCCESS,
        payload: obj
    };
};

export const updateCaseStudyBlockRequest = (obj) => {
    return {
        type: ResourcesActions.UPDATE_CASE_STUDY_BLOCK_REQUEST,
        payload: obj
    };
};

export const updateCaseStudyBlockSuccess = (obj) => {
    return {
        type: ResourcesActions.UPDATE_CASE_STUDY_BLOCK_SUCCESS,
        payload: obj
    };
};

export const deleteRCaseStudyBlockRequest = (obj) => {
    return {
        type: ResourcesActions.DELETE_CASE_STUDY_BLOCK_REQUEST,
        payload: obj
    };
};

export const deleteCaseStudyBlockSuccess = (obj) => {
    return {
        type: ResourcesActions.DELETE_CASE_STUDY_BLOCK_SUCCESS,
        payload: obj
    };
};

export const insertCaseStudyBlockRequest = payload => ({
    type: ResourcesActions.INSERT_CASE_STUDY_BLOCK,
    payload
});

export const fetchIntegrationListRequest = () => {
    return {
        type: ResourcesActions.FETCH_INTEGRATION_LIST_REQUEST
    };
}

export const fetchIntegrationListSuccess = (payload) => {
    return {
        type: ResourcesActions.FETCH_INTEGRATION_LIST_SUCCESS,
        payload
    };
}

export const fetchIntegrationListError = (payload) => {
    return {
        type: ResourcesActions.FETCH_INTEGRATION_LIST_ERROR,
        payload
    };
}

export const fetchCompetitorListRequest = () => {
    return {
        type: ResourcesActions.FETCH_COMPETITOR_LIST_REQUEST
    };
}

export const fetchCompetitorListSuccess = (payload) => {
    return {
        type: ResourcesActions.FETCH_COMPETITOR_LIST_SUCCESS,
        payload
    };
}

export const fetchCompetitorListError = (payload) => {
    return {
        type: ResourcesActions.FETCH_COMPETITOR_LIST_ERROR,
        payload
    };
}

// video Actions
export const fetchVideo = (payload) => {
    return {
        type: ResourcesActions.FETCH_VIDEO_REQUEST,
        payload
    };
}

export const fetchVideoSuccess = (payload) => {
    return {
        type: ResourcesActions.FETCH_VIDEO_SUCCESS,
        payload
    };
}

export const fetchVideoList = () => {
    return {
        type: ResourcesActions.FETCH_VIDEO_LIST_REQUEST
    };
}

export const fetchVideoListSuccess = (payload) => {
    return {
        type: ResourcesActions.FETCH_VIDEO_LIST_SUCCESS,
        payload
    };
}

export const addVideo = (payload) => {
    return {
        type: ResourcesActions.ADD_VIDEO_REQUEST,
        payload
    };
}

export const addVideoSuccess = (payload) => {
    return {
        type: ResourcesActions.ADD_VIDEO_SUCCESS,
        payload
    };
}

export const updateVideo = (payload) => {
    return {
        type: ResourcesActions.UPDATE_VIDEO_REQUEST,
        payload
    };
}

export const updateVideoSuccess = (payload) => {
    return {
        type: ResourcesActions.UPDATE_VIDEO_SUCCESS,
        payload
    };
}

export const deleteVideo = (payload) => {
    return {
        type: ResourcesActions.DELETE_VIDEO_REQUEST,
        payload
    };
}

export const deleteVideoSuccess = (payload) => {
    return {
        type: ResourcesActions.DELETE_VIDEO_SUCCESS,
        payload
    };
}

// video tutorials

export const fetchVideoTutorialsList = () => {
    return {
        type: ResourcesActions.FETCH_VIDEO_TUTORIALS_LIST_REQUEST
    };
}

export const fetchVideoTutorialsListSuccess = (payload) => {
    return {
        type: ResourcesActions.FETCH_VIDEO_TUTORIALS_LIST_SUCCESS,
        payload
    };
}

export const fetchVideoTutorial = (payload) => {
    return {
        type: ResourcesActions.FETCH_VIDEO_TUTORIAL_REQUEST,
        payload
    };
}


export const fetchVideoTutorialSuccess = (payload) => {
    return {
        type: ResourcesActions.FETCH_VIDEO_TUTORIAL_SUCCESS,
        payload
    };
}

export const addVideoTutorial = (payload) => {
    return {
        type: ResourcesActions.ADD_VIDEO_TUTORIAL_REQUEST,
        payload
    };
}

export const addVideoTutorialSuccess = (payload) => {
    return {
        type: ResourcesActions.ADD_VIDEO_TUTORIAL_SUCCESS,
        payload
    };
}

export const deleteVideoTutorial = (payload) => {
    return {
        type: ResourcesActions.DELETE_VIDEO_TUTORIAL_REQUEST,
        payload
    };
}

export const deleteVideoTutorialSuccess = (payload) => {
    return {
        type: ResourcesActions.DELETE_VIDEO_TUTORIAL_SUCCESS,
        payload
    };
}

export const updateVideoTutorial = (payload) => {
    return {
        type: ResourcesActions.UPDATE_VIDEO_TUTORIAL_REQUEST,
        payload
    };
}

export const updateVideoTutorialSuccess = (payload) => {
    return {
        type: ResourcesActions.UPDATE_VIDEO_TUTORIAL_SUCCESS,
        payload
    };
}

/**
 * COMMON
 */

 export const fetchSlugOccurenceRequest = (slug) => ({
    type: ResourcesActions.FETCH_SLUG_OCCURENCE_REQUEST,
    slug
});

export const fetchSlugOccurenceSuccess = (occurence) => ({
    type: ResourcesActions.FETCH_SLUG_OCCURENCE_SUCCESS,
    occurence
});

export const fetchSlugOccurenceError = (occurence) => ({
    type: ResourcesActions.FETCH_SLUG_OCCURENCE_ERROR,
    occurence
});

export default ResourcesActions;

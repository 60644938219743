import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";
import { Tag, message, Icon, Button, Popconfirm, Divider } from "antd";

import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../../components";

import { dateFormat, websiteNodeAPIUrl } from "../../../constants";
import { decodeResourceClassification, downloadFile, getIsPageActionAccessible } from "../../../utils";
import lookup from "../resources.json";
import "./CaseStudyList.scss";

class Resources extends Component {

    pageKey="caseStudyList";
    isReadonly = !getIsPageActionAccessible(this.pageKey, this.props.userData.privileges);
    state = {
        downLoadingPDF: false,
        records: null,
        editSortingOrderKey: null,
        showEditSortingOrderSubmit: false
    };

    componentDidMount() {
        this.props.fetchResourcesListRequest();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            ...prevState,
            records: nextProps.data
        };
    }

    formatData = () => {
        const { records } =  this.state;
        return records.map((record, i) => {
            const dataId = record.id;
            const { topics, industries } = decodeResourceClassification( record.classification, lookup, true);
            return {
                ...record,
                topics, 
                industries,
                key: dataId,
                lastUpdated: moment(record.updatedAt).format(dateFormat),
                sortingOrder: record.sortingOrder,
                sortingOrderField: this.getSortingOrder(i, record.sortingOrder),
                actions: (
                    <>
                        {
                            record.pdfLink && (
                                <>
                                    <a onClick={() => this.downloadPDF(record.pdfLink)}>Download</a>
                                    <Divider type="vertical" />
                                </>
                            )
                        }
                        {
                            record.link && (
                                <>
                                    <a href={`${process.env.REACT_APP_WEBSITE_URL}.${record.link}`} target="_blank">Website URL</a>
                                    <Divider type="vertical" />
                                </>   
                            )
                        }
                        {
                            !this.isReadonly && (
                                <>
                                    <Link to={`/resource/case-study/${record.id}`}>Edit</Link>
                                    <Divider type="vertical" />
                                    <Popconfirm title={"Do you want to delete this case-study?"} onConfirm={() => this.handleDelete(record)} okText="Yes" cancelText="No" placement={"top"}>
                                        <a href="#">Delete</a>
                                    </Popconfirm>
                                </>
                            )
                        }
                    </>
                )
            };
        });
    };

    getSortingOrder = (i, value) => {
        const { editSortingOrderKey } = this.state;
        return (
            <div className="sortingOrderWrapper">
                {
                    editSortingOrderKey !== null && editSortingOrderKey === i ?
                        <div>
                            <input type="number" min="1" name={`sortingOrder_${i}`} value={value} onChange={(e) => this.inputChangeHandler(e, i)}/>
                            <Icon type="check-circle" theme="twoTone" onClick={(e) => this.updateSortingOrder(e, i)} />
                        </div> :
                        <div>
                            <Button type="dashed" disabled={ !this.isReadonly ? false : true } onClick={() => this.updateEditSortingOrder(i)}>{value}</Button>
                        </div>
                }
            </div>
        );
    };

    inputChangeHandler = (e, index) => {
        const data = {...this.state.records};
        data[index].sortingOrder = e.target.value;
        this.setState({
            records: data,
            showEditSortingOrderSubmit: true
        });
    };

    updateEditSortingOrder = (i) => {
        this.setState({
            editSortingOrderKey: i
        })
    };

    updateSortingOrder = (e, index) => {
        const { records } = this.state;
        const sortingOrder = records[index].sortingOrder;
        if (sortingOrder && parseInt(sortingOrder) > 0) {
            const payload = {
                ...records[index],
                sortingOrder: records[index].sortingOrder
            };
            this.props.updateResourceRequest({ data: payload, cb: ((errorMsg) => {
                    if (!errorMsg) {
                        this.setState({
                            editSortingOrderKey: null,
                            showEditSortingOrderSubmit: false
                        });
                        this.props.fetchResourcesListRequest();
                        return message.success(
                            "You have successfully updated this record."
                        );
                    } else {
                        return message.error(
                            "Something went wrong... Please try again later."
                        );
                    }
                })
            });
        }
    };

    handleDelete = (data) => {
        const { id } = data;
        this.props.deleteResourceRequest({ id, cb: this.renderFeedbackMsg });
    };

    renderFeedbackMsg = (errorMsg) => {
        if (!errorMsg) {
            return message.success(
                "You have successfully deleted this record."
            );
        } else {
            return message.error(
                "Something went wrong... Please try again later."
            );
        }
    };

    renderExpandedRow = (record) => {
        return (
            <div>
                <div className="mb1">
                    <b>Industries: </b>
                    {record.industries && record.industries.map((tag) => (
                        tag && <Tag color="blue" key={tag}>
                            {tag}
                        </Tag>
                    ))}
                </div>
                <div className="mb1">
                    <b>Topics: </b>
                    {record.topics && record.topics.map((tag) => (
                        tag && <Tag color="blue" key={tag}>
                            {tag}
                        </Tag>
                    ))}
                </div>
                <div className="mb1">
                    <b>SFDC Campaign ID: </b>
                    { record.leadSfdcCampaign &&
                        <span>{record.leadSfdcCampaign}</span>
                    }
                </div>
            </div>
        )
    };

    getDisplayLink = (record) => {
        const link = record.link;
        return ~link.indexOf("http") ? link : `${process.env.REACT_APP_WEBSITE_URL}${link[0] === "/" ? link.slice(1) : link}`;
    }

    downloadPDF = (path) => {
        this.setState({
            downLoadingPDF: true
        }, () => {
            const url = `${websiteNodeAPIUrl}getPDFDownloads?pdfPath=${path}`;
            axios.get(url, { responseType: "blob" }).then((response) => {
               downloadFile({
                   response,
                   cb: () => this.setState({downLoadingPDF: false}, () => this.hidePDFDownloadMessage && this.hidePDFDownloadMessage())
               });
            }).catch(() => {
                this.setState({downLoadingPDF: false})
            });
        });
    }

    renderPDFDownloadingMessage = () => {
        this.hidePDFDownloadMessage && this.hidePDFDownloadMessage();
        this.hidePDFDownloadMessage = message.loading("Downloading PDF...", 0);
        return this.hidePDFDownloadMessage;
    }

    componentWillUnmount() {
        this.hidePDFDownloadMessage && this.hidePDFDownloadMessage();
    }

    render() {
        const { isLoading, error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="resources-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                {
                    this.state.downLoadingPDF && this.renderPDFDownloadingMessage()
                }
                <TableSkeleton
                    columns={lookup.caseStudyColumns}
                    expandedRowRender={this.renderExpandedRow}
                    dataSource={this.formatData()}
                    loading={isLoading}
                />
            </div>
        );
    }
}

Resources.propTypes = {
    data: PropTypes.array.isRequired, 
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchResourcesListRequest: PropTypes.func.isRequired,
    deleteResourceRequest: PropTypes.func.isRequired
};

export default Resources;
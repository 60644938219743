import React, { useState, useEffect } from 'react'
import { PageHeaderContainer, RedirectWrapper } from '../../components'
import { connect } from 'react-redux';
import { Card, Col, Icon, Input, Popconfirm, Row, Tooltip } from 'antd'
import './styles.scss'
import { beAPIResource } from '../../utils/apiHelper'

const ProductRelease = (props) => {
    const { error, userData } = props;
    const unauthorized = error === "unauthorized" || !userData.name;
    
    if (unauthorized) {
        return <RedirectWrapper location={props.location} />;
    }

    const pageKey = "productRelease";
    const [fetchedList, setFetchedList] = useState([])
    const [displayList, setDisplayList] = useState([])
    const [searchText, setSearchText] = useState('')
    const showEdit = props.userData.privileges.includes('productReleases+');

    const fetchData = async () => {
        const {data} = await beAPIResource.get(`/support/partners/product-release`);
        if (Array.isArray(data)) {
            const conditionedData = data.map(item => ({...JSON.parse(item.data), id: item.id}));
            setDisplayList(conditionedData);
            setFetchedList(conditionedData);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])   

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        setDisplayList(fetchedList.filter(item => item.title.toLowerCase().includes(e.target.value.toLowerCase())));
    }
    const handleDelete = async (id) => {
        await beAPIResource.delete(`/support/partners/product-release/${id}`);
        fetchData();
    }

    return (
        <>
            <PageHeaderContainer pageKey={pageKey} title={"New Product & Feature Launches"} />
            <div className="tableWrapper">
                    <Input
                        placeholder="Search an item"
                        size="large"
                        value={searchText}
                        onChange={handleSearch}
                    />
                    <Row gutter={16} className="rowSection">
                        {displayList.map((row, i) => {
                            return (
                                <Col span={6} className="gutter-row" key={i}>
                                    <div className="card-wrapper">
                                        <Card bordered={false}>
                                            <div className="cardRowWrapper">
                                                <div className="textRegion">
                                                    <p className="title">{row.title}</p>
                                                    <p className="date">{`Launch Date (Est.${row.date})`}</p>
                                                    <p className="description">{row.description}</p>
                                                    <div className="link-wrapper">                                                        
                                                        {row.fileId &&
                                                            <>
                                                            <Tooltip placement="top" title={"Download"}>
                                                                <a href={`https://drive.google.com/uc?export=download&id=${row.fileId}`} className="link" download >
                                                                    <Icon type="download" style={{ color: '#ffa500' }} />
                                                                </a>
                                                            </Tooltip>
                                                            <Tooltip placement="top" title={"Asset preview"}>
                                                                <a href={`https://drive.google.com/file/d/${row.fileId}`} target="blank" className="link" title="link url" >
                                                                    <Icon type="eye" style={{ color: '#08c' }} />
                                                                </a>
                                                            </Tooltip>
                                                            </>
                                                        }
                                                        {showEdit &&
                                                        <>
                                                        <Tooltip placement="top" title={"Edit"}>
                                                            <a title="edit" href={`/#/product-release/${row.id}`}>
                                                                <Icon type="edit" style={{ color: 'dimgrey' }} />
                                                            </a>
                                                        </Tooltip>
                                                        <Tooltip placement="top" title={"Delete"}>
                                                        <Popconfirm
                                                            title="Are you sure you want to delete this?"
                                                            onConfirm={() => handleDelete(row.id)}
                                                            onCancel={()=>{}}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <a title="edit" onClick={ (e) => e.preventDefault() }>
                                                                <Icon type="delete" style={{ color: 'red' }} />
                                                            </a>
                                                        </Popconfirm>                                                            
                                                        </Tooltip>
                                                        </> 
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </Col>
                           )
                        })}
                        {!displayList.length && 
                            <>
                                <div className="no-data">
                                <p>No data available</p>
                                </div>
                            </>
                        }
                    </Row>
                </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    ...state.User
});

export default connect(mapStateToProps)(ProductRelease);
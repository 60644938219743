import React from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty, cloneDeep } from "lodash";
import { Layout, Menu, Icon } from "antd";

import lookup from "./sideBar.json";
import "./SideBar.scss";

const { SubMenu } = Menu;
const { Sider } = Layout;

const getInitialCollapseState = (url) => {
    const defaultToOpen = ["prospector"];
    return defaultToOpen.some(pagePath => url.indexOf(pagePath) !== -1);
}
class SideBar extends React.Component {
    state = {
        collapsed: getInitialCollapseState(window.location.href)
    };
    
    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.path !== this.props.path) this.setState({ collapsed: getInitialCollapseState(nextProps.path) });
    }

    renderMenuItem = (authorizedKeys, subMenu) => {
        const hasPermissionForSubTabs = subMenu.tabs && subMenu.tabs.some(tab => authorizedKeys.includes(tab.permission));
        return authorizedKeys.includes(subMenu.permission) || hasPermissionForSubTabs;
    }

    renderSubMenuItem = (authorizedKeys, subMenuPermission, menuPermission) => {
        return authorizedKeys.includes(menuPermission) || authorizedKeys.includes(subMenuPermission);
    }

    render() {
        const { userData } = this.props;
        const userLoggedIn = !isEmpty(userData);
        if (!userLoggedIn) {
            return null;
        }
        const data = cloneDeep(lookup)
        if (userData.role == 18) { // If partner
            const integrationsIndex = data.menuList.findIndex(tab => tab.key === "integration");
            if (integrationsIndex > -1) data.menuList[integrationsIndex].tabs.splice(1, 1); // Remove integration domain tab
        } else {
            const caseStudiesIndex = data.menuList.findIndex(tab => tab.label === "Case studies");
            if (caseStudiesIndex > -1) data.menuList.splice(caseStudiesIndex, 1); // Hide Case studies 
        }

        /* Lead gen tabs */
        const leadGenTabIndex = data.menuList.findIndex(tab => tab.key === "leadgen-management");
        if (leadGenTabIndex !== -1) {
            let leadGenTabs = [
                {
                    "label": "Lead Gen Form",
                    "link": "/leadgen-mngt/sfdc-lead"
                }
            ];
            if (userData.privileges.includes("leadSubscribe")) leadGenTabs.push({
                "label": "Subscribe",
                "link": "/leadgen-mngt/contact/subscribe"
            });
            if (userData.privileges.includes("leadUnsubscribe")) leadGenTabs.push({
                "label": "Unsubscribe",
                "link": "/leadgen-mngt/contact/unsubscribe"
            });
            if (userData.privileges.includes("leadBulkUpload")) leadGenTabs.push({
                "label": "Bulk Leads Upload",
                "link": "/leadgen-mngt/sfdc-lead-upload"
              });
            if (userData.privileges.includes("leadErrorReport")) leadGenTabs.push({
                "label": "Error Report",
                "link": "/leadgen-mngt/report"
             });
            if (userData.privileges.includes("leadInvalidDomain")) leadGenTabs.push({
                "label": "Invalid Email/Domain",
                "link": "/leadgen-mngt/invalid-email-domain"
            });
            data.menuList[leadGenTabIndex].tabs = leadGenTabs;
        }
        /* Lead gen tabs end */

        /* Integration tabs */
        const integrationsTabIndex = data.menuList.findIndex( tab => tab.key === 'integration');
        if(integrationsTabIndex !== -1 && userData.privileges.includes("integrationMarketplaceAdmin")) {      
            data.menuList[integrationsTabIndex].tabs.push(
                {
                    "label": "Self Serve Panel",
                    "link": "/integrations/self-serve",
                    "permission":"integrationMarketplaceAdmin"
                },
                {
                    "label": "Requested CRM",
                    "link": "/integrations/requested-crm",
                    "permission":"integrationMarketplaceAdmin"
                }
            )
        }
        /* Integration tabs end*/

        const {name, privileges } = userData;

        const renderMenuItem = (tab, i) => {
            if (tab.tabs) {
                let tabs = tab.tabs;
                return (
                    <SubMenu key={tab.key} title={tab.label}>
                        {
                            tabs.map((subTab, index) => { return renderMenuItem(subTab, index)})
                        }
                    </SubMenu>
                );
            } else {
                return (
                    <Menu.Item key={tab.link}>
                        <NavLink to={tab.link}>
                            <span>{tab.label}</span>
                        </NavLink>
                    </Menu.Item>
                )
            }
        }

        return (
            <div className="sidebar-wrapper">
                <Sider width={200} collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse} collapsedWidth={0}>
                    <Menu mode={"inline"} theme={"light"}>
                        {
                            data.menuList.map((subMenu) => {

                                if (!this.renderMenuItem(privileges, subMenu) || !subMenu.tabs.length) return  null;

                                const tabs = subMenu.tabs;
                                const menuLabel = <span><Icon type={subMenu.icon} theme='twoTone'/>{subMenu.label}</span>;

                                if (tabs.length === 1) {
                                    return (
                                        <Menu.Item key={subMenu.key}>
                                            <NavLink to={subMenu.tabs[0].link}>{menuLabel}</NavLink>
                                        </Menu.Item>
                                    )
                                }

                                return (
                                    <SubMenu key={subMenu.key} title={menuLabel} >
                                        {
                                            tabs.filter((tab) => (privileges.includes(subMenu.permission) || privileges.includes(tab.permission))).map((tab, i) => { return renderMenuItem(tab, i)})
                                        }
                                    </SubMenu>
                                );
                            })
                        }
                    </Menu>
                </Sider>
            </div>
        );
    }
};

SideBar.propTypes = {
    userData: PropTypes.object.isRequired,
    path: PropTypes.string
};

const mapStateToProps = (state) => ({
    ...state.User
});

export default withRouter(connect(mapStateToProps)(SideBar));
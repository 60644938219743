import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { fetchPartnerDocumentBySectionRequest, downloadPartnerDocumentRequest } from "./../Partner/actions";
import { Card, Col, Row, Icon, Tooltip, Tag } from 'antd';
import Announcement from "./Announcement";
const { Meta } = Card;

class NewPartnerLibraryHome extends Component {
    constructor(props) {
        super();
        switch (props.userData.account_type) {
            case 2:
                this.type = "coBranded";
                break;
            case 3:
                this.type = "whitelabeled";
                break;
            default:
                this.type = "both";
        }
    }

    componentDidMount() {
        if (!this.props.section.hasOwnProperty("latest") || _.isEmpty(this.props.section.latest.all)) this.props.fetchPartnerDocumentBySectionRequest({ section: "latest" });
    }

    getTag = (partnerType) => {
        switch (partnerType) {
            case "coBranded": return <Tag color="blue">Co-Branded</Tag>;
            case "whitelabeled": return <Tag color="green">Whitelabeled</Tag>
            default: return ""
        }
    }

    render() {

        const { userData } = this.props;
        const showEdit = userData.privileges.includes("partner+");

        const gridStyle = {
            width: '33.33%',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: 500
          };
        const cardStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }

        const latestDocuments = this.props.section.hasOwnProperty("latest") && !_.isEmpty(this.props.section.latest.all) ? this.props.section.latest.all.filter(d => (this.type !== "both" ? (d.partner_type === this.type) : true)).slice(0,2) : [];

        return (
            <Row gutter={16}>
                <Col span={24}>
                    <div className="partnerHomeWrapper" style={{ margin: "0 0 50px" }}>
                        <h3 className="heading">Welcome to the Partner Success Portal</h3>
                        <p className="subHeading">Your one-stop shop for all marketing and sales materials.</p>
                        {!!latestDocuments.length && <div className="newMaterials">
                            <h3 className="sectionHeading">New Marketing Materials</h3>
                            <div>
                                <Row gutter={16}>
                                    {
                                        latestDocuments.map(doc => {
                                            return (
                                                <Col span={12}>
                                                    <Card
                                                        hoverable
                                                        cover={<img alt="example" className="cover-img" src={doc.preview_image} />}
                                                    >
                                                            {this.type === 'both' && this.getTag(doc.partner_type)}
                                                            <p className="card-title">{doc.title}</p>
                                                            <p className="card-description">{doc.description}</p>
                                                            <div className="card-link-wrapper">                                                        
                                                                {doc.file_id &&
                                                                    <>
                                                                        <Tooltip placement="top" title={"Download"}>
                                                                            <a href={doc.document} className="link" title="download">
                                                                                <Icon type="download" style={{ color: '#ffa500' }} />
                                                                            </a>
                                                                        </Tooltip>
                                                                        <Tooltip placement="top" title={"Asset preview"}>
                                                                            <a className="link" title="link url" href={`https://drive.google.com/file/d/${doc.file_id}`} target="blank">
                                                                                <Icon type="eye" style={{ color: '#08c' }} />
                                                                            </a>
                                                                        </Tooltip>
                                                                    </>
                                                                }
                                                                {showEdit && <Tooltip placement="top" title={"Edit"}>
                                                                    <a title="edit" href={`/#/partner/document/manage/${doc.id}`}>
                                                                        <Icon type="edit" style={{ color: 'dimgrey' }} />
                                                                    </a>
                                                                </Tooltip>}
                                                            </div>
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>                    
                        </div>}
                        <div className="smallGrid">
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Card>
                                        <a href="/#/partner/document/sales-decks"><Card.Grid style={gridStyle}>Sales Decks</Card.Grid></a>
                                        <a href="/#/partner/document/product-brochures"><Card.Grid style={gridStyle}>Product Brochures</Card.Grid></a>
                                        <a href="#/partner/document/prospecting-kits"><Card.Grid style={gridStyle}>Prospecting Kits</Card.Grid></a>
                                        <a href="#/partner/document/competitive"><Card.Grid style={gridStyle}>Competitive Resources</Card.Grid></a>
                                        <a href="#/partner/document/email-templates"><Card.Grid style={gridStyle}>Email Templates</Card.Grid></a>
                                        <a href="#/partner/document/quick-start-guides"><Card.Grid style={gridStyle}>Quick Start Guides</Card.Grid></a>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <Announcement userData={userData}/>
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Partner
});

export default connect( 
    mapStateToProps, 
    { fetchPartnerDocumentBySectionRequest, downloadPartnerDocumentRequest }
)(NewPartnerLibraryHome);

const constants = {
    selfServe: [{
        "title": "Name",
        "key": "crmName",
        "sortAs": "string",
        "width": "15%",
        "align": "left",
        "searchable": true,
    },
    {
        "title": "SFDC ID",
        "key": "sfdcCaseId",
        "width": "15%",
        "searchable": true,
        "align": "left",
        "renderAsLink": {
            "key": "sfdcCaseURL",
            "base": ""
        }
    },
    {
        "title": "Business Number",
        "key": "businessNumber",
        "width": "15%",
        "align": "left",
        "searchable": true
    },
    {
        "title": "Assignee",
        "key": "assignee",
        "width": "15%",
        "align": "left",
        "searchable": true,
    },
    {
        "title": "Requested At",
        "width": "12%",
        "align": "left",
        "sortAs": "date",
        "key": "requestedAt"
    },
    {
        "title": "Updated At",
        "width": "12%",
        "align": "left",
        "sortAs": "date",
        "key": "updatedAt"
    }],

    showRequestedCRM: [{
        "title": "Business Name",
        "key": "businessName",
        "sortAs": "string",
        "width": "17%",
        "align": "left",
        "searchable": true
    },
    {
        "title": "Business Id",
        "key": "businessId",
        "width": "17%",
        "align": "left",
        "searchable": true
    },
    {
        "title": "Requested App Name",
        "key": "requestedAppName",
        "width": "17%",
        "align": "left",
        "searchable": true
    },
    {
        "title": "Requested App Details",
        "key": "requestedAppDetails",
        "width": "17%",
        "searchable": true
    },
    {
        "title": "Requested At",
        "sortAs": "date",
        "width": "17%",
        "key": "requestedAt"
    }]
}

export default constants;

import React from 'react';
import FeaturedResourcesForm from './componentForms/FeaturedResources';
import HTMLHeaderForm from './componentForms/HtmlHeader';

export const getForm = ({name, data, pageId}) => {
    switch(name) {
        case 'resourcesSection': return <FeaturedResourcesForm data={data} pageId={pageId}/>;
        case 'htmlBlock': return <HTMLHeaderForm data={data} pageId={pageId} />;
        default: return null;
    }
}

import React, { Component } from "react";
import { Tabs } from 'antd';
import History from "./History";
import { Form } from "antd";
import Scan from "./Scan";
import PropTypes from "prop-types";
import { RedirectWrapper } from "../../components";
const { TabPane } = Tabs;

class ScanToolTab extends Component {
    
    handleBusiness = (key) => {
        if(key === 'History'){
            const email = this.props.userData.name;
            this.props.getBusinessScanToolHistory({ email });
        }
    }

    render() {
        const { userData, errorMessage } = this.props;
        const unauthorized = errorMessage === "unauthorized" || !userData.name;
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location}/>;
        }

        return (
            <Tabs defaultActiveKey="Scan" onTabClick={(key) => this.handleBusiness(key)}>
                <TabPane tab="Scan" key="Scan">
                    <Scan form = {this.props.form} userData={this.props.userData} reqBusinessDetails={this.props.reqBusinessDetails} businessDetails={this.props.businessDetails} errorMessage={ this.props.errorMessage} isLoading={this.props.isLoading} />
                </TabPane>
                <TabPane tab="History" key="History">
                     <History scanToolHistory={this.props.scanToolHistory} isLoading={this.props.isLoading} />
                </TabPane>
            </Tabs>
        )
    }
}

ScanToolTab.propTypes = {
    userData: PropTypes.object.isRequired,
    reqBusinessDetails: PropTypes.func.isRequired,
    businessDetails: PropTypes.object.isRequired,
    getBusinessScanToolHistory: PropTypes.func.isRequired,
    errorMessage: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    scanToolHistory: PropTypes.array.isRequired
};

const ScanTool = Form.create()(ScanToolTab);

export default ScanTool; 
import { connect } from "react-redux";

import {
    fetchSalesRepsListRequest,
    fetchSalesRepRequest,
    addSalesRepRequest,
    updateSalesRepRequest,
    deleteSalesRepRequest,
    fetchSalesRepInvitationRequest,
    fetchSalesRepTimeZonesRequest
} from "./actions";

import {
    fetchPagesListRequest
} from "../Pages/actions";

import SalesReps from "./SalesReps";
import UpdateSalesRep from "./UpdateSalesRep";
import CalendarInvitation from "./CalendarInvitation";

const mapStateToProps = (state) => {
    return {
        ...state.SalesRep,
        ...state.User,
        ...state.Pages
    }
};

export const SalesRepsPage = connect(
    mapStateToProps,
    { fetchSalesRepsListRequest, deleteSalesRepRequest }
)(SalesReps);

export const UpdateSalesRepPage = connect(
    mapStateToProps,
    { fetchSalesRepRequest, fetchPagesListRequest, addSalesRepRequest, updateSalesRepRequest, fetchSalesRepTimeZonesRequest }
)(UpdateSalesRep);

export const CalendarInvitationPage = connect(
    mapStateToProps,
    { fetchSalesRepInvitationRequest }
)(CalendarInvitation);

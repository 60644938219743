import React, { Component } from "react";

class Complete extends Component {
    render() {
        const { findProcessed } = this.props;
        return (
            findProcessed ? (
                <div>
                    All steps has been completed.
                </div>
            ) : (
                <div style = {{color: "red"}}>
                    <b>NOTE : </b>Please Save Find (7th Step) to Proceed.
                </div>
            )
        )
    }
}

export default Complete;
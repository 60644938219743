import { createActionTypes } from "../../utils";

export const ScitorAction = createActionTypes("scitor", [
    "FILE_UPLOAD_REQUEST",
    "FILE_UPLOAD_SUCCESS",
    "FILE_UPLOAD_ERROR",
]);

export const fileUploadRequest = (obj) => {
    return {
        type: ScitorAction.FILE_UPLOAD_REQUEST,
        payload: obj
    };
};

export const fileUploadSuccess = (obj) => {
    return {
        type: ScitorAction.FILE_UPLOAD_SUCCESS,
        payload: obj
    };
}

export const fileUploadError = (obj) => {
    return {
        type: ScitorAction.FILE_UPLOAD_ERROR,
        payload: obj
    };
}

export default ScitorAction;
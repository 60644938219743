import { ProductEmailsActions } from "./actions";

let ProductEmails = {
    byMonthData: [],
    byTemplateData: [],
    templateStats: [],
    error: "",
    isLoading: false,
    modalOtherProductEmail: false,
    emailVersions: []
};

const productEmailsReducer = (state = ProductEmails, action) => {
    switch (action.type) {
        case ProductEmailsActions.FETCH_EMAIL_BY_MONTH_REQUEST:
        case ProductEmailsActions.FETCH_EMAIL_BY_TEMPLATE_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case ProductEmailsActions.FETCH_EMAIL_BY_MONTH_SUCCESS:
            return {
                ...state,
                byMonthData: action.payload,
                isLoading: false
            };
        case ProductEmailsActions.FETCH_EMAIL_BY_TEMPLATE_SUCCESS:
            return {
                ...state,
                byTemplateData: action.payload,
                isLoading: false
            };
        case ProductEmailsActions.FETCH_EMAIL_ERROR:
            return {
                ...state,
                byMonthData: [],
                byTemplateData: [],
                error: action.payload.error,
                isLoading: false
            };
        case ProductEmailsActions.FETCH_TEMPLATE_STATS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ProductEmailsActions.FETCH_TEMPLATE_STATS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                templateStats: action.payload
            };
        case ProductEmailsActions.FETCH_TEMPLATE_STATS_ERROR:
            return {
                ...state,
                isLoading: false,
                templateStats: [],
                error: action.payload.error,
            };
        case ProductEmailsActions.ERASE_TEMPLATE_STATS_REQUEST:
            return {
                ...state,
                templateStats: []
            };
        case ProductEmailsActions.FETCH_EMAIL_VERSIONS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ProductEmailsActions.FETCH_EMAIL_VERSIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                emailVersions: action.payload
            };
        case ProductEmailsActions.FETCH_EMAIL_VERSIONS_ERROR:
            return {
                ...state,
                isLoading: false,
                emailVersions: [],
                error: action.payload.error
            };
        case ProductEmailsActions.ERASE_EMAIL_VERSIONS_REQUEST:
            return {
                ...state,
                emailVersions: []
            };
        default:
            return state;
    }
};

export default productEmailsReducer;

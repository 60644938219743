import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Popconfirm, Button, message, Spin, Select, Icon } from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";
import _ from "lodash";

import constant from "./signature.json";
import "./signature.scss";

class MultipleUserSignatureForm extends Component {

    constructor(props) {
        super();

        this.templateBanner = [];
        this.state = {
            isNewBannerModalVisible: false,
            template: null,
            country: null
        };
    }

    componentDidUpdate(prevProps) {
        const template = this.props.form.getFieldValue("template");
        const country = this.props.form.getFieldValue("country");

        if (!prevProps.banner.hasOwnProperty(template) && this.props.banner.hasOwnProperty(template)) {
            this.templateBanner = this.props.banner[template].filter(v => v.country === country);

            const defaultSelectedBanner = this.templateBanner.findIndex(v => (v.isSelected));
            if (defaultSelectedBanner != -1) this.props.form.setFieldsValue({ banner: defaultSelectedBanner });
        }
    }

    getTemplateBanner = (template) => {
        this.templateBanner = this.props.banner.hasOwnProperty(template) ? this.props.banner[template] : [];
    }

    getBannerMeta = (index) => {
        return this.templateBanner.hasOwnProperty(index) ? this.templateBanner[index] : null;
    }

    getTemplateMeta = (template) => {
        const templateMatch = constant.template.filter(v => (v[1] === template));
        return !_.isEmpty(templateMatch) ? templateMatch[0] : [];
    }

    handleTemplateChange = async (template) => {console.log(template);
        await this.props.form.resetFields();
        this.setState({template});


    }

    handleCountryChange = (country) => {
        this.setState({country});

        if (template === "default") return;

        let defaultSelectedBanner = -1;
        const template = this.props.form.getFieldValue("template");

        if (_.isEmpty(this.props.banner[template])) {
            this.props.getSignatureAssetRequest({ data: {type: "banner", template} });
        } else {
            this.templateBanner = this.props.banner[template].filter(v => v.country === country);;
            defaultSelectedBanner = this.templateBanner.findIndex(v => (v.isSelected));
        }

        this.props.form.setFieldsValue({ banner: (defaultSelectedBanner != -1 ) ? defaultSelectedBanner : undefined });
    }

    handleOnSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (error) {
                return;
            }

            this.props.updateUsersRequest({ data: {...values, banner: this.getBannerMeta(values.banner)}, cb: this.handleSubmitResponse });
        });
    }

    handleSubmitResponse = (response) => {
        if (response.type === "success") this.props.form.resetFields();
        message[response.type](response.message);
    }

    handleBannerBulkSet = (data) => {
        this.props.updateUsersRequest({ data: {...data}, cb: this.handleSubmitResponse });
    }

    render() {
        const unauthorized = !this.props.userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        const selectedTemplate = this.state.template;
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const bannerMeta = this.getBannerMeta(getFieldsValue().banner);

        return (
            <div className={"multipleUserSignature"}>
                <h2>Manage Group Signature</h2>
                <div style={{paddingBottom: "20px"}}>
                    <Spin tip="Please wait..." spinning={this.props.isLoading} size={"large"} indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}>
                        <Form onSubmit={this.handleOnSubmit}>
                            <Form.Item {...formItemLayout} label={"Group"}>
                                {
                                    getFieldDecorator("template", {
                                        rules: [{
                                            required: true,
                                            message: "Please provide your group"
                                        }]
                                    })(
                                        <Select placeholder={"Group"} onChange={this.handleTemplateChange}>
                                            {
                                                constant.template && constant.template.map(t => (
                                                    <Select.Option key={t[1]} value={t[1]}>{t[0]}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                            <Form.Item {...formItemLayout} label={"Country"}>
                                {
                                    getFieldDecorator("country", {
                                        rules: [{
                                            required: true,
                                            message: "Please provide country"
                                        }]
                                    })(
                                        <Select placeholder={"Country"} onChange={this.handleCountryChange}>
                                            {
                                                constant.value.countriesOptions && constant.value.countriesOptions.map( country => (
                                                    <Select.Option key={country.text} value={country.value}>{country.text}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                            {
                                selectedTemplate !== "default" && (
                                    <>
                                        <Form.Item {...formItemLayout} label={"Banner"}>
                                            {
                                                getFieldDecorator("banner", {
                                                    rules: [{
                                                        required: true,
                                                        message: "Please chose an appropriate banner"
                                                    }]
                                                })(
                                                    <Select placeholder={"Chose an appropriate banner"} loading={_.isEmpty(this.templateBanner)} optionLabelProp={"label"}>
                                                        {
                                                            this.templateBanner.map((b,i) => {
                                                                return (
                                                                    <Select.Option key={i} value={i} label={b.name}><img src={b.image} style={{maxHeight: "80px"}} alt={b.name}/></Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </Form.Item>
                                        {
                                            !_.isEmpty(bannerMeta) && (
                                                <>
                                                    <Form.Item {...formItemLayout} label="Banner Url">
                                                        <a target="_blank" href={bannerMeta.url}>{bannerMeta.url}</a>
                                                    </Form.Item>
                                                    <Form.Item {...formItemLayout} label="Banner Image">
                                                        <img src={bannerMeta.image} style={{maxHeight: "80px"}} alt={bannerMeta.name}/>
                                                    </Form.Item>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                            <Form.Item  {...formButtonLayout}>
                            <Popconfirm
                                title={<><p>This will going to update group member's signature. Do you want to continue?</p></>}
                                okText={"Yes"}
                                cancelText={"No"}
                                placement={"right"}
                                onConfirm={this.handleOnSubmit}
                            >
                                <Button type="primary" htmlType="submit">
                                    Set this for group
                                </Button>
                            </Popconfirm>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </div>
        );
    }
}

const MultipleUserSignature = Form.create()(MultipleUserSignatureForm);
export default MultipleUserSignature;
import React, {Component} from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Select, DatePicker, TimePicker, Icon } from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";
import { isEmpty } from "lodash";
import moment from "moment-timezone";
import lookup from "./constants";

const { Item: FormItem } = Form;
const dateFormat = "dddd, MMMM DD, YYYY", timeFormat = "HH:mm:ss";

class MeetingForm extends Component {

    state = {
        descriptionLength: 0
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const description = this.props.form.getFieldValue('eventDescription');
        if(description) {
            this.setState({descriptionLength : description.length});
        }
    }

    getConvertedDate = (date, currentTimezone, newTimezone) => {
        return moment.tz(date, currentTimezone).tz(newTimezone)
    };

    handleFormSubmission = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            const descLength = this.state.descriptionLength;
            if (error) {
                return;
            } else if(descLength > 175) {
                return;
            }

            const { presenters=[], upcomingSession = [] } = this.props.additional;

            const 
                { startDate, startTime, endTime, eventTimezone, eventDuration, eventPresenter } = values,
                startDateString = startDate.format("YYYY-MM-DD"),
                startTimeString = startTime.format("HH:mm:ss"),
                startTimestampUTC = `${startDateString} ${startTimeString}`,
                startTimeStringInUTC = this.getConvertedDate(startTimestampUTC, eventTimezone, "GMT").format("YYYY-MM-DD[T]HH:mm:ss[Z]");

            let endTimestampUTC = null;
            if (eventDuration === "custom") {
                const endTimeString = endTime.format("HH:mm:ss");
                endTimestampUTC = `${startDateString} ${endTimeString}`
            } else {
                endTimestampUTC = moment.utc(startTimestampUTC).add(eventDuration, "minutes").format("YYYY-MM-DD HH:mm:ss");
            }
            const endTimeStringInUTC = this.getConvertedDate(endTimestampUTC, eventTimezone, "GMT").format("YYYY-MM-DD[T]HH:mm:ss[Z]");

            const mStartTime = moment.utc(startTimeStringInUTC);
            const mEndTime = moment.utc(endTimeStringInUTC);
            for (const session of upcomingSession) {
                const
                    mSessionStartDate = moment.utc(session.startDate),
                    mSessionEndDate = moment.utc(session.endDate);
                if ((!isEmpty(presenters) && session.organiserEmailId === eventPresenter[0]) && ((mSessionStartDate.diff(mStartTime) <= 0 && mSessionEndDate.diff(mStartTime) >= 0)
                    || (mSessionStartDate.diff(mEndTime) <= 0 && mSessionEndDate.diff(mEndTime) >= 0))) {
                        const message = `A session is already scheduled for the selected presenter on this date from ${this.getConvertedDate(session.startDate, "UTC", eventTimezone).format("HH:mm")} to ${this.getConvertedDate(session.endDate, "UTC", eventTimezone).format("HH:mm")}`;
                        this.props.form.setFields({ startDate: { value: startDate, errors: [{ message }] } });
                        return;
                    }
            }

                        
            values.times = [{
                startTime: startTimeStringInUTC,
                endTime: endTimeStringInUTC
            }];
            values.eventPresenter = presenters.filter(({email}) => (eventPresenter.indexOf(email) !== -1)).map(({email, name, photo, designation}) => ({email, name, photo, designation}));
            values.startDate = startTimeStringInUTC;
            values.endDate = endTimeStringInUTC;

            delete(values.startTime);

            this.props.handleStepFormSubmission({ meeting: {...values} });
        })
    }

    onDescriptionChange = (e) => {
        this.setState({descriptionLength: e.target.value.length});
    }

    render() {
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { eventTitle, eventDescription, eventTimezone = "America/Los_Angeles", startDate, times = [{startTime: null, endTime: null}], eventPresenter} = this.props.initialValues.meeting || {};
        const { presenters=[], isLoadingUpcomingSession } = this.props.additional;
        const startDateObj = startDate ? this.getConvertedDate(startDate, "GMT", eventTimezone) : moment().add("days", 1);
        const 
            [{startTime, endTime},] = times,
            startTimeObj = startTime ? this.getConvertedDate(startTime, "GMT", eventTimezone) : moment.utc("00:00:00", timeFormat),
            endTimeObj = endTime ? this.getConvertedDate(endTime, "GMT", eventTimezone) : moment.utc("00:00:00", timeFormat).add(1, "hours"),
            timeDifference = endTimeObj.diff(startTimeObj, "minutes"),
            initialEventDuration = lookup.meeting.intervals.map(({value}) => (value)).indexOf(timeDifference) === -1 ? "custom" :  timeDifference;

        const { eventDuration=initialEventDuration } = getFieldsValue();
        const { descriptionLength } = this.state;

        return (
            <>
                <Form onSubmit={this.handleFormSubmission}>
                    <FormItem {...formItemLayout} label={"Meeting Title"}>
                        {
                            getFieldDecorator("eventTitle", {
                                rules: [{   
                                    "required": true,
                                    "message": "Provide a title for this meeting"
                                }],
                                initialValue: eventTitle
                            })(
                                <Input name="eventTitle" placeholder={"Meeting Title"} onChange={this.handleInputChange} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Meeting Description"} extra={<div style={{'textAlign': 'right'}}> {descriptionLength > 175 ? <span style={{color: "red"}} >{descriptionLength-175} characters overloaded (Limit 175)</span> : <span>{175-descriptionLength} characters left.</span> }</div>}>
                        {
                            getFieldDecorator("eventDescription", {
                                rules: [{
                                    "required": true,
                                    "message": "Provide some description which will be helpful to elaborate this meeting"
                                }],
                                initialValue: eventDescription
                            })(
                                <Input.TextArea name="eventDescription" placeholder={"Meeting description"} autoSize={{minRows: 2, maxRows: 8}} maxLength={175} onChange={this.onDescriptionChange} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Timezone"}>
                        {
                            getFieldDecorator("eventTimezone", {
                                rules: [{
                                    "required": true,
                                    "message": "Select a timezone according to which this meeting is supposed to host"
                                }],
                                initialValue: eventTimezone
                            })(
                                <Select showSearch placeholder={"Select a suitable timezone"}>
                                    {
                                        lookup.meeting.timezoneOptions.map((timezone, index) => (
                                            <Select.Option key={`timezone-${index}`} value={timezone.value}>{timezone.label}</Select.Option>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Start Date"}>
                        {
                            this.props.form.getFieldDecorator("startDate", {
                                rules: [{
                                    "required": true,
                                    "message": "Select a start date for this meeting"
                                }],
                                initialValue: startDateObj
                            })(
                                <DatePicker placeholder={"Choose a start date"} className={"startDate"} format={dateFormat} disabledDate={(current) => (current && current < moment().endOf('day'))}  onChange={this.handleStartDateChange} loading={isLoadingUpcomingSession}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Start Time"}>
                        {
                            this.props.form.getFieldDecorator("startTime", {
                                rules: [{
                                    "required": true,
                                    "message": "Select start time for this meeting"
                                }, {
                                    validator: (rule, startTime, callback) => {
                                        const {endTime, eventDuration} = this.props.form.getFieldsValue(["endTime", "eventDuration"]);

                                        if (!endTime || !startTime) {
                                            return callback();
                                        }

                                        if(eventDuration === "custom") {
                                            this.props.form.setFields({
                                                startTime: {value: startTime, errors: null},
                                                endTime: {value: endTime, errors: null},
                                            })
        
                                            if (endTime.diff(startTime, "minutes") <= 0) {
                                                return callback(new Error("Start time should less than end time"));
                                            }
                                        }

                                        callback();
                                    }
                                }],
                                initialValue: startTimeObj
                            })(
                                <TimePicker style={{width: "30%"}} placeholder={"HH:MM:SS"} className={"startTime"} loading={isLoadingUpcomingSession}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Duration"}>
                        {
                            this.props.form.getFieldDecorator("eventDuration", {
                                rules: [{
                                    "required": true,
                                    "message": "Select duration of this meeting"
                                }],
                                initialValue: initialEventDuration
                            })(
                                <Select className={"eventDuration"} placeholder="Meeting duration" loading={isLoadingUpcomingSession}>
                                    {
                                        lookup.meeting.intervals.map(({label, value}, index) => (
                                                <Select.Option key={index} value={value}>{label}</Select.Option>
                                            )
                                        )
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                    {
                        (eventDuration === "custom") && (
                            <FormItem {...formItemLayout} label={"End Time"}>
                                {
                                    this.props.form.getFieldDecorator("endTime", {
                                        rules: [{
                                            "required": true,
                                            "message": "Select end time for this meeting"
                                        }, {
                                            validator: (rule, endTime, callback) => {
                                                const {startTime} = this.props.form.getFieldsValue(['startTime']);

                                                if (!endTime || !startTime) {
                                                    return callback();
                                                }

                                                this.props.form.setFields({
                                                    startTime: {value: startTime, errors: null},
                                                    endTime: {value: endTime, errors: null},
                                                })
            
                                                if (endTime.diff(startTime, "minutes") <= 0) {
                                                    return callback(new Error("End time should be greater than start time"));
                                                }

                                                callback();
                                            },
                                            "message": "End time should greater than start time"
                                        }],
                                        initialValue: endTimeObj
                                    })(
                                        <TimePicker style={{width: "30%"}} placeholder={"HH:MM:SS"} className={"endTime"}  loading={isLoadingUpcomingSession}/>
                                    )
                                }
                            </FormItem>
                        )
                    }
                    <FormItem {...formItemLayout} label={"Presenter"}>
                        {
                            getFieldDecorator("eventPresenter", {
                                rules: [{
                                    "required": true,
                                    "message": "Select event presenter(s)"
                                }], 
                                initialValue: eventPresenter && eventPresenter.map(({email}) => (email))
                            })(
                                <Select showSearch mode="single" placeholder={"Select meeting presenter(s)"}>
                                    {
                                        presenters && presenters.map(({email, name, designation, photo}, index) => (
                                            <Select.Option key={`presenter-${index}`} value={email}><span><img style={{height: "15px", width: "15px"}} src={photo}/> <b>{name}</b>, <small>{designation}</small></span></Select.Option>
                                        ))  
                                    }
                                </Select>
                            )
                        }
                        {/*<Button block type="dashed" onClick={this.props.additional.reqWebinarPresenterModalOpen}>Click for Presenter related operations</Button>*/}
                    </FormItem>
                    <FormItem {...formButtonLayout}>
                        <Button type={"primary"} htmlType={"submit"}>
                            Next<Icon type="right" />
                        </Button>
                    </FormItem>
                </Form>
            </>
        );
    }
}

const Meeting = Form.create()(MeetingForm);

Meeting.propTypes = {
    userData: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    additional: PropTypes.object,
    handleStepFormSubmission: PropTypes.func.isRequired,
};

export default Meeting;
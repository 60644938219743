import { createActionTypes } from "../../utils";

export const CategoriesActions = createActionTypes("categories", [
    "FETCH_CATEGORIES_LIST_BY_SOURCE",
    "FETCH_CATEGORIES_LIST_BY_SOURCE_SUCCESS",
    "FETCH_BIRDEYE_CATEGORY_LIST",
    "FETCH_BIRDEYE_CATEGORY_LIST_SUCCESS",
    "UPDATE_CATEGORY_REQUEST",
    "UPDATE_CATEGORY_SUCCESS",
    "CATEGORIES_ERROR",
    "ADD_BE_SUBCATEGORY",
    "ADD_BE_SUBCATEGORY_SUCCESS",
    "ADD_BE_SUBCATEGORY_ERROR",
    "UPDATE_SIC_NAICS",
    "UPDATE_SIC_NAICS_SUCCESS",
    "UPDATE_SIC_NAICS_ERROR",
    "ADD_NEW_CATEGORY",
    "ADD_NEW_CATEGORY_SUCCESS",
    "ADD_NEW_CATEGORY_ERROR",
    "ADD_INDUSTRY",
    "ADD_INDUSTRY_SUCCESS",
    "ADD_INDUSTRY_ERROR"
]);

export const fetchBirdeyeCategoriesList = (obj) => {
    return {
        type: CategoriesActions.FETCH_BIRDEYE_CATEGORY_LIST,
        payload: obj
    };
};

export const fetchBirdeyeCategoriesListSucess = (obj) => {
    return {
        type: CategoriesActions.FETCH_BIRDEYE_CATEGORY_LIST_SUCCESS,
        payload: obj
    };
};

export const fetchCategoriesListBySource = (obj) => {
    return {
        type: CategoriesActions.FETCH_CATEGORIES_LIST_BY_SOURCE,
        payload: obj
    };
};

export const fetchCategoriesListBySourceSucess = (obj) => {
    return {
        type: CategoriesActions.FETCH_CATEGORIES_LIST_BY_SOURCE_SUCCESS,
        payload: obj
    };
};

export const updateCategoryRequest = (obj) => {
    return {
        type: CategoriesActions.UPDATE_CATEGORY_REQUEST,
        payload: obj
    };
};

export const updateCategorySuccess = (obj) => {
    return {
        type: CategoriesActions.UPDATE_CATEGORY_SUCCESS,
        payload: obj
    };
};

export const categoriesError = (obj) => {
    return {
        type: CategoriesActions.CATEGORIES_ERROR,
        payload: obj
    };
};

export const addBeSubCategoryRequest = (obj) => {
    return {
        type: CategoriesActions.ADD_BE_SUBCATEGORY,
        payload: obj
    };
};

export const addBeSubCategorySuccess = (obj) => {
    return {
        type: CategoriesActions.ADD_BE_SUBCATEGORY_SUCCESS,
        payload: obj
    };
};

export const addBeSubCategoryErrror = (obj) => {
    return {
        type: CategoriesActions.ADD_BE_SUBCATEGORY_ERROR,
        payload: obj
    };
};

export const updateSicNaicsRequest = (obj) => {
    return {
        type: CategoriesActions.UPDATE_SIC_NAICS,
        payload: obj
    };
};

export const addNewCategoryRequest = (obj) => {
    return {
        type: CategoriesActions.ADD_NEW_CATEGORY,
        payload: obj
    };
};

export const updateSicNaicsSuccess = (obj) => {
    return {
        type: CategoriesActions.UPDATE_SIC_NAICS_SUCCESS,
    };
};

export const addNewCategorySuccess = (obj) => {
    return {
        type: CategoriesActions.ADD_NEW_CATEGORY_SUCCESS,
        payload: obj
    };
};

export const updateSicNaicsError = (obj) => {
    return {
        type: CategoriesActions.UPDATE_SIC_NAICS_ERROR,
    };
};

export const addNewCategoryError = (obj) => {
    return {
        type: CategoriesActions.ADD_NEW_CATEGORY_ERROR,
        payload: obj
    };
};

export const addIndustry = (obj) => {
    return {
        type: CategoriesActions.ADD_INDUSTRY,
        payload: obj
    };
};

export const addIndustrySuccess = (obj) => {
    return {
        type: CategoriesActions.ADD_INDUSTRY_SUCCESS,
        payload: obj
    };
};

export const addIndustryError = (obj) => {
    return {
        type: CategoriesActions.ADD_INDUSTRY_ERROR,
        payload: obj
    };
};

export default CategoriesActions;

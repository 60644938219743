import { GmbActions } from "./actions";

let Gmb = {
    isLoading: false,
    error: ""
};

const gmbReducer = (state = Gmb, action) => {

    switch (action.type) {
        case GmbActions.GET_GMB_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case GmbActions.GET_GMB_SUCCESS:
            return {
                ...state,
                error: "",
                isLoading: false
            };
        case GmbActions.GET_GMB_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};

export default gmbReducer;
import { beAPIResource } from "../../utils/apiHelper";

const pageApi = {
    getAll: () => beAPIResource.get("/support/page/"),
    getById: (id) => beAPIResource.get(`/support/page/${id}`),
    add: (data) => beAPIResource.post("/support/page/", data),
    update: (data) => beAPIResource.put(`/support/page/${data.id}`, data),
    delete: (id) => beAPIResource.delete(`/support/page/${id}`),
    getFallBackCal: () => beAPIResource.get("/support/page/fallback-calendar"),
    addInactiveEmp: (data) => beAPIResource.post("/support/page/inactive/employee", data),
    getInactiveEmp: (data) => beAPIResource.get("/support/page/inactive/all", data)
};

export default pageApi;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Popconfirm, Menu } from "antd";

const ListItemAction = ({ data, pathForEdit, handleDelete, additionalActions }) => {

    const getLinkType = (additionalAction) => {

        switch(true) {
            case !!additionalAction.cb: return <a onClick={() => additionalAction.cb(additionalAction.path)}>{additionalAction.label}</a>;

            case additionalAction.external: return <a href={additionalAction.path} target="_blank">{additionalAction.label}</a>;

            case additionalAction.downloadable: return <a onClick={(e) => {e.preventDefault(); additionalAction.actionHandler(additionalAction.record)}} target="_blank">{additionalAction.label}</a>;

            default: return <Link to={additionalAction.path}>{additionalAction.label}</Link>

        }
    }
    return <Menu className="list-action">
        { pathForEdit && pathForEdit.length > 0 &&
            <Menu.Item key={pathForEdit}>
                <Link to={{pathname: pathForEdit, state: {data}}}>Edit</Link>
            </Menu.Item>
        }
        {
            handleDelete &&
                <Menu.Item key="1">
                    <Popconfirm
                        title="Are you sure delete this record?"
                        placement="left"
                        onConfirm={() => {
                            handleDelete(data);
                        }}
                        okText="Yes"
                        cancelText="No">
                        Delete
                    </Popconfirm>
                </Menu.Item>
        }
        {
                additionalActions && !!additionalActions.length && 
                    additionalActions
                    .filter(additionalAction => additionalAction.path.length > 0 || additionalAction.cb)
                    .map((additionalAction) => (
                        <Menu.Item key={additionalAction.label}>
                            {
                                getLinkType(additionalAction)
                            }
                       </Menu.Item>
                    ))
        }
    </Menu>
};

ListItemAction.propTypes = {
    data: PropTypes.object.isRequired,
    pathForEdit: PropTypes.string.isRequired,
    handleDelete: PropTypes.func,
    additionalActions: PropTypes.array
};

export default ListItemAction;

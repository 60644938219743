import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, Input, Modal, Radio } from "antd";
import { beAPIResource } from "../../utils/apiHelper";
const { Item: FormItem } = Form;
const formItemLayout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 5 }
    },
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 14 }
    }
};

const formButtonLayout = {
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 14, offset: 5 }
    }
};

const AddInvalidEmailDomain = (props) => {

    const [ type, setType ] = useState('email');
    const leadAddress = ''; 
    const { getFieldDecorator } = props.form;
    const unauthorized = props.error === "unauthorized" || !props.userData.name;

    const handleSubmit = (e) => {
        e.preventDefault();

        const goToListingPage = () => props.history.push("/leadgen-mngt/invalid-email-domain");
        props.form.validateFieldsAndScroll(
            ["type", "leadAddress"],
            async (error, values) => {
                if (!error) {
                    const { type, leadAddress } = values;
                    const payload = {
                        leadAddress,
                        domain: type === 'domain'
                    };

                    try {
                        await beAPIResource.post('/support/leadgen-management/add-invalid-emails-domains', payload);  
                        return Modal.success({
                            title: "You have successfully added the Lead Address",
                            okText: "Go to back to list",
                            maskClosable: true,
                            onOk() {
                                goToListingPage();
                            }
                        });
                    } catch(e) {

                        return Modal.error({
                            title: "Could not add address",
                            content: (
                                <>
                                    <p>{e.data.data.details}</p>
                                </>
                            ),
                        });
                    }
            }
        });
    };

    return <div style={{ width: "80vw" }}>
        {
            unauthorized ? <Redirect to="/" /> : ''
        }
    <h2>Add Lead Address</h2>
    <Form onSubmit={handleSubmit}>
        <FormItem {...formItemLayout} label="Type">
            {getFieldDecorator('type', {
                initialValue: type
            })(
                <Radio.Group onChange={(e) => setType(e.target.value)}>
                    <Radio value={'email'} key={'email'} >Email</Radio>
                    <Radio value={'domain'} key={'domain'} >Domain</Radio>
                </Radio.Group>
            )}
        </FormItem>
        <FormItem {...formItemLayout} label="Lead Address" extra={<p>Eg. test@gmail.com, abc.com</p>}>
            {getFieldDecorator('leadAddress', {
                    initialValue: leadAddress,
                    rules: [{
                        required: true,
                        message: "Please provide Lead Address"
                    },
                    {
                        pattern: type === 'email' ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/ : /^[a-zA-Z0-9][a-zA-Z0-9-.]+\.([a-zA-Z]{2,})$/,
                        message: `Please provide a valid ${ type === 'email' ? 'Email' : 'Domain'}`
                    }
                ]
                })(
                    <Input />
                )}
        </FormItem>
        <FormItem {...formButtonLayout}>
            <Button type={"primary"} htmlType={"submit"}>Submit</Button>
        </FormItem>
    </Form>
</div>
}

const AddInvalidEmailDomainPage = Form.create()(AddInvalidEmailDomain);
export default AddInvalidEmailDomainPage;
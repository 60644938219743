export default {
    listingTableColumns: [
        {
            title: "Title",
            key: "title",
            dataIndex: "title",
            searchable: true,
            ellipsis: true,
            width: "50%",
        },
        {
            title: "History",
            key: "history",
            dataIndex: "history",
            width: "30%",
        },
        {
            title: "Actions",
            key: "actions",
            dataIndex: "actions",
            width: "20%",
            align: "center"
        },
    ]
};
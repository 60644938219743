import { connect } from "react-redux";

import {
    sendContactRequest, hideContactModalMethod,
    sendSfdcLeadFileRequest, sendSfdcLeadFormRequest, hideSfdcModalMethod,
    reqPageDataCampaignUrlBuilder, logCampaignUrlBuilderActivity, reqCampaignUrlBuilderArchive, reqContactDetails
} from "./actions";

import Contact from "./Contact";
import SfdcLead from "./SfdcLead";
import Report from "./Report";
import InvalidEmailDomain from "./InvalidEmailDomain";
import AddInvalidEmailDomain from "./AddInvalidEmailDomain";
import CampaignUrlBuilder from "./CampaignUrlBuilder";
import CampaignUrlBuilderArchive from "./CampaignUrlBuilderArchive";
import LeadUpload from "./leadUpload";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.LeadgenManagement
});

export const ContactPage = connect(
    mapStateToProps,
    { sendContactRequest, hideContactModalMethod, reqContactDetails }
)(Contact);

export const SfdcLeadPage = connect(
    mapStateToProps,
    { sendSfdcLeadFileRequest, sendSfdcLeadFormRequest, hideSfdcModalMethod, reqPageDataCampaignUrlBuilder }
)(SfdcLead);

export const SfdcLeadUploadPage = connect(
    mapStateToProps,
    { sendSfdcLeadFileRequest, sendSfdcLeadFormRequest, hideSfdcModalMethod, reqPageDataCampaignUrlBuilder }
)(LeadUpload);

export const CampaignUrlBuilderPage = connect(
    mapStateToProps,
    { reqPageDataCampaignUrlBuilder, logCampaignUrlBuilderActivity }
)(CampaignUrlBuilder);

export const CampaignUrlBuilderArchivePage = connect(
    mapStateToProps,
    { reqCampaignUrlBuilderArchive }
)(CampaignUrlBuilderArchive);

export const ReportPage = connect(
    mapStateToProps,
    { sendSfdcLeadFileRequest, sendSfdcLeadFormRequest, hideSfdcModalMethod, reqPageDataCampaignUrlBuilder }
)(Report);

export const InvalidEmailDomainPage = connect(
    mapStateToProps,
    { sendSfdcLeadFileRequest, sendSfdcLeadFormRequest, hideSfdcModalMethod, reqPageDataCampaignUrlBuilder }
)(InvalidEmailDomain);

export const AddInvalidEmailDomainPage = connect(
    mapStateToProps,
    { sendSfdcLeadFileRequest, sendSfdcLeadFormRequest, hideSfdcModalMethod, reqPageDataCampaignUrlBuilder }
)(AddInvalidEmailDomain);

import React, { Component } from "react";
import PropTypes from "prop-types";
import { throttle, isEmpty } from "lodash";
import { Table, Input, Icon, Button, Tag, Popover, Menu, Dropdown} from "antd";
import { ListItemAction } from "../../components";

import "./TableSkeleton.scss";

class TableSkeleton extends Component {
    state = {
        searchText: ""
    };

    componentDidMount() {
        this.handleScroll();
    }

    mountSearchableAddons = (column) => {
        const
        getPoints = (text = "", search = "") => {
            const points = [];
            text = text || "";
            search = search || "";
            for(let i =0; i <= (text.length - search.length); i++){
                if(text.substring(i).toLowerCase().startsWith(search.toLowerCase())){
                    points.push(i)
                    points.push(i + search.length)
                }
            }
            return points;
        },
        splitByIndices = (string = "", points) => {
            string = string || "";
            const indices = [0, ...points, string.length];
            const result = [];
            for(let i =0; i < indices.length -1; i ++){
                result.push(string.substring(indices[i], indices[i +1]))
            }
            return result.filter(s => s.length);
        },
        splitByKeyword = (text = "", search) => splitByIndices(text, getPoints(text, search));

        return {
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters
            }) => (
                <div className="custom-filter-dropdown">
                    <Input
                        ref={(ele) => (this.searchInput = ele)}
                        placeholder="Search name"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        onPressEnter={this.handleSearch(selectedKeys, confirm)}
                    />
                    <Button
                        type="primary"
                        onClick={this.handleSearch(selectedKeys, confirm)}>
                        Search
                    </Button>
                    <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
                </div>
            ),
            filterIcon: () => <Icon type="search" />,
            onFilter: (value, record) =>
                record[column.key] && record[column.key].toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => {
                        this.searchInput.focus();
                    });
                }
            },
            render: (text) => {
                const { searchText } = this.state;
                return searchText ? (
                    <span>
                        {splitByKeyword(text, searchText)
                            .map((fragment, i) => {
                                return fragment.toLowerCase() ===
                                    searchText.toLowerCase() ? (
                                        <span key={i} className="text-blue">
                                            {fragment}
                                        </span>
                                    ) : (
                                        fragment
                                    );
                            })}
                    </span>
                ) : (
                    text
                );
            }
        };
    };

    mountSearchableJSXAddons = (column) => {
        return {
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters
            }) => (
                <div className="custom-filter-dropdown">
                    <Input
                        ref={(ele) => (this.searchInput = ele)}
                        placeholder="Search name"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        onPressEnter={this.handleSearch(selectedKeys, confirm)}
                    />
                    <Button
                        type="primary"
                        onClick={this.handleSearch(selectedKeys, confirm)}>
                        Search
                    </Button>
                    <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
                </div>
            ),
            filterIcon: () => <Icon type="search" />,
            onFilter: (value, record) => {
                if(record[column.key].props){
                    const strChildren = record[column.key].props.children.filter((d) => typeof d === 'string');
                    const str = strChildren.join('');
                    return str && str.toLowerCase().includes(value.toLowerCase())
                }else{
                    return record[column.key] && record[column.key].toLowerCase().includes(value.toLowerCase())
                } 
            },
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => {
                        this.searchInput.focus();
                    });
                }
            },
            render: (text) => {
                const { searchText } = this.state;
                return searchText ? (
                    text
                ) : (
                    text
                );
            }
        };
    }

    mountFilterAddons = (column) => {
        return {
            filters: column.filters,
            filterIcon: () => <Icon type="filter" />,
            onFilter: (value, record) =>
            record[column.key].toLowerCase().includes(value.toLowerCase())
        };
    }

    mountExactFilterAddons = (column) => {
        return {
            filters: column.filters,
            filterIcon: () => <Icon type="filter" />,
            onFilter: (value, record) =>
                record[column.key].toLowerCase() === value.toLowerCase()
        };
    }

    mountSortableAddons = (column) => {
        if (column.sortAs === "date") {
            return {
                sorter: (a, b) => {
                    if (a.key === "total" || b.key === "total") {
                        return 0;
                    } else if (new Date(a[column.key]) - new Date(b[column.key])) {
                        return -1;
                    }
                }};
        } else if (column.sortAs === "number") {
            return {
                sorter: (a, b) => {
                    if (a.key === "total" || b.key === "total") {
                        return 0;
                    } else {
                        return a[column.key] - b[column.key];
                    }
                },
                align: "center"
            };
        } else if (column.sortAs === "numberString") {
            return {
                sorter: (a, b) => {
                    if (a.key === "total" || b.key === "total") {
                        return 0;
                    } else {
                        return a[`${column.key}Original`] - b[`${column.key}Original`];
                    }
                },
                align: "center"
            };
        } else if (column.sortAs === "string") {
            return { sorter: (a, b) => {
                if (a.key === "total" || b.key === "total") {
                    return 0;
                } else {
                    let aKey = a[column.key] === null || a[column.key] === undefined ? "" : a[column.key];
                    let bKey = b[column.key] === null || a[column.key] === undefined ? "" : b[column.key];
                    return aKey.localeCompare(bKey);
                }
            } };
        }
    };

    mountRenderAddons = (column) => {
        const { base, key } = column.renderAsLink;
        return {
            render: (text, record) => {
                const link = decodeURIComponent(`${base}${record[key]}`);
                return record[key] ? <a href={link} target="_blank">{text}</a> : <span>{text}</span>;
            }
        };
    };

    mountColumn = (fieldObj) => {
        return { dataIndex: fieldObj.key, ...fieldObj.addons, ...fieldObj };
    };

    mountFeatureIndicators = () => {
        return {
            render: (text, record) => this.props.renderFeatureIndicators(text, record)
        };
    }

    mapColumns = (columns, pathForEdit, handleDelete, additionalHandler) => {
        let mappedColumns = [
            ...columns.map((column) => {
                let addons = {};
                if (column.searchable) {
                    addons = this.mountSearchableAddons(column);
                }else if (column.searchableJSX) {
                    addons = this.mountSearchableJSXAddons(column);
                }else if (column.sortAs) {
                    addons = this.mountSortableAddons(column);
                } else if (column.filterable) {
                    addons = this.mountFilterAddons(column);
                } else if (column.exactFilterable) {
                    addons = this.mountExactFilterAddons(column);
                }
                if (column.renderAsLink) {
                    addons = {
                        ...addons,
                        ...this.mountRenderAddons(column)
                    };
                }
                if (column.featureIndicator) {
                    addons = {
                        ...addons,
                        ...this.mountFeatureIndicators()
                    };
                }
                return this.mountColumn({
                    ...column,
                    addons
                });
            })
        ];

       if (pathForEdit || handleDelete || additionalHandler) {
           mappedColumns.push({
               dataIndex: "",
               key: "action",
               render: (data) => (
                <Dropdown overlay = {
                    <ListItemAction  
                         data={data}
                         pathForEdit={pathForEdit && data.id && `${pathForEdit}${data.id}`}
                         handleDelete={data.id && handleDelete}
                         additionalActions={this.getadditionalActions(data, additionalHandler)} 
                    /> } trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                      <Icon type="ellipsis" rotate={90} style={{fontSize: '18px',color:"#555555"}} />
                    </a>
                </Dropdown>
               )
           });
        }

        return mappedColumns;
    };

    getadditionalActions(data, additionalHandler) {
        if (!additionalHandler || !additionalHandler.length) return [];
        return additionalHandler
            .filter(handler => handler.check(data))
            .map(handler => handler.render(data));
    }

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    handleScroll = () => {
        // TODO: remove when better approach is available
        const tableScrollEl = document.querySelector(".ant-table-scroll .ant-table-body");
        const footerScrollEl = document.querySelector(".ant-table-footer .ant-table-scroll .ant-table-body");
        if (footerScrollEl) {
            footerScrollEl.addEventListener("scroll", throttle(() => {
                tableScrollEl.scrollLeft = footerScrollEl.scrollLeft;
            }, 100));
        }
    };

    renderAggRow = () => {
        const { footerData, columns, scroll } = this.props;
        if (footerData) {
            return (
                <Table
                    {...this.props}
                    columns={this.mapColumns(columns)}
                    dataSource={[footerData]}
                    showHeader={false}
                    pagination={false}
                    style={scroll ? {position: "relative", top: 2} : {}}
                />
            );
        } else {
            return null;
        }
    }

    render() {
        const {
            expandedRowRender,
            dataSource,
            columns,
            pathForEdit,
            handleDelete,
            additionalHandler,
            size,
            disablePagination,
            pageSize,
            currentPage,
            totalData,
            showSizeChanger,
            pageSizeOptions,
            loading,
        } = this.props;

        const pagination = {
            showSizeChanger,
            pageSizeOptions,
        };

        if(pageSize){
            pagination.pageSize = pageSize;
        }

        if(totalData){
            pagination.total = totalData;
        }

        if(currentPage){
            pagination.current = currentPage;
        }

        return (
            <div>
                <Table
                    {...this.props}
                    columns={this.mapColumns(columns, pathForEdit, handleDelete, additionalHandler)}
                    expandedRowRender={expandedRowRender}
                    dataSource={dataSource}
                    pagination={(size === "small" || disablePagination)  ? false : pagination}
                    footer={() => this.renderAggRow()}
                    loading={loading}
                />
            </div>
        );
    }
}

TableSkeleton.propTypes = {
    expandedRowRender: PropTypes.func,
    dataSource: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    pathForEdit: PropTypes.string,
    handleDelete: PropTypes.func,
    bordered: PropTypes.bool,
    scroll: PropTypes.object,
    loading: PropTypes.bool
};

TableSkeleton.defaultProps = {
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30", "40", "50"],
}

export default TableSkeleton;

import { ScanToolsActions } from "./actions";

let ScanTool = {
    businessDetails: {},
    scanToolHistory: [],
    errorMessage: {},
    isLoading: false
};

const scanToolReducer = (state = ScanTool, action) => {
    switch (action.type) {
        case ScanToolsActions.REQ_BUSINESS_SIGN_UP:
            return {
                ...state,
                businessData: {},
                errorMessage: {},
                isLoading: true
            };
        case ScanToolsActions.REQ_BUSINESS_SIGN_UP_SUCCESS:
            return {
                ...state,
                businessDetails: {
                    ...action.payload
                },
                isLoading: false,
                errorMessage: {}
            };
        case ScanToolsActions.REQ_BUSINESS_SIGN_UP_ERROR:
            return {
                ...state,
                businessData: {},
                errorMessage: {... action.payload} || {},
                isLoading: false
            };
        case ScanToolsActions.GET_BUSINESS_SCAN_TOOL_HISTORY:
            return {
                ...state,
                scanToolHistory: [],
                errorMessage: {},
                isLoading: true
            };
        case ScanToolsActions.GET_BUSINESS_SCAN_TOOL_HISTORY_SUCCESS:
            return {
                ...state,
                scanToolHistory: [... action.payload] || [],
                isLoading: false,
                errorMessage: {},
            };
        case ScanToolsActions.GET_BUSINESS_SCAN_TOOL_HISTORY_ERROR:
            return {
                ...state,
                scanToolHistory: [],
                errorMessage: {... action.payload} || {},
                isLoading: false
            };
        default:
            return state;
    }
};

export default scanToolReducer;

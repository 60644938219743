import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Button, Spin, Modal } from "antd";
import { formItemLayout } from "../../constants";
import PropTypes from "prop-types";
const FormItem = Form.Item;

class SfdcLeadUpload extends Component {

    uploadedFile = {};

    componentWillMount() {
        const {campaignUrlBuilder} = this.props.pageData;
        !campaignUrlBuilder.length && this.props.reqPageDataCampaignUrlBuilder();
        this.props.reqPageDataCampaignUrlBuilder();
    }

    handleUpload = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll(["leadFile"], (error, values) => {
            let payload = new FormData();
            payload.append('leadFile', this.uploadedFile, this.uploadedFile.name);

            if (!error) {
                this.props.sendSfdcLeadFileRequest(payload);
            }
        })
    };

    handleFileChange = e => {
        this.uploadedFile = e.target.files[0];
    };

    handleModalMethodClose = (preserveForm = false) => {
        return new Promise((resolve, reject) => {
            this.props.hideSfdcModalMethod();
            if (preserveForm) {
                this.props.form.setFieldsValue(this.state.formValues);
            } else {
                this.setState({formValues: {}});
            }
            resolve();
        });
    };

    handleModalMethod = (modalObj) => {
        if (modalObj.visible) {
            switch (modalObj.type) {
                case "success":
                    Modal.success({
                        title: "Success!!!",
                        content: modalObj.message || "Action performed successfully.",
                        onOk: this.handleModalMethodClose
                    });
                    break;
                case "error":
                    Modal.warn({
                        title: "Oops!!!",
                        content: modalObj.message || "Something went wrong.",
                        onOk: () => {this.handleModalMethodClose(true)}
                    });
                    break;
            }
        }
    };

    componentDidUpdate = () => {
        this.handleModalMethod(this.props.modalMethod);
    };


    render = () => {

        const { error, userData, isLoading } = this.props;
        const { getFieldDecorator } = this.props.form;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        if(isLoading) {
            return <Spin />;
        }

        return (
            <div>
                <h2>Bulk Leads Upload</h2>
                <Form style={{"display": "flex", "flexDirection": "column", width: "35%", alignItems: "center"}} onSubmit={this.handleUpload}>
                    <FormItem {...formItemLayout} label={"Lead File"}>
                        {
                            getFieldDecorator( "leadFile", {
                                rules: [{
                                    "required": true,
                                    "message": "Please upload a CSV file"
                                }, {
                                    "pattern": /\.csv$/g,
                                    "message": "Please upload a CSV file"
                                }]
                            })(
                                <div>
                                    <Input type={"file"} style={{width: "100%"}} accept={".csv"} placeholder={"Upload Leads File For Bulk Upload"} onChange={this.handleFileChange}/>
                                    <a href={"/SfdcLead/sfdc-lead-sample.csv"}>Click to download sample .csv file</a>
                                </div>
                            )
                        }
                    </FormItem>
                    <FormItem>
                        <Button type="primary" htmlType="submit">
                            Upload SFDC Lead
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

SfdcLeadUpload.propTypes = {
    modalMethod: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    sendSfdcLeadFormRequest: PropTypes.func.isRequired,
    sendSfdcLeadFileRequest: PropTypes.func.isRequired,
    hideSfdcModalMethod: PropTypes.func.isRequired
};
const SfdcLead = Form.create()(SfdcLeadUpload);
export default SfdcLead;
import { createActionTypes } from "../../utils";

export const BusinessActions = createActionTypes("business", [
    "FETCH_BUSINESS_BY_BUSINESS_NUMBER",
    "FETCH_BUSINESS_BY_BUSINESS_NUMBER_SUCCESS",
    "FETCH_STATES",
    "FETCH_STATES_SUCCESS",
    "FETCH_COUNTRIES",
    "FETCH_COUNTRIES_SUCCESS",
    "UPDATE_BUSINESS_REQUEST",
    "UPDATE_BUSINESS_SUCCESS",
    "ADD_USER_REQUEST",
    "ADD_USER_SUCCESS",
    "FIND_BUSINESS_REQUEST",
    "FIND_BUSINESS_SUCCESS",
    "FETCH_USERS_BY_BUSINESS_NUMBER",
    "FETCH_USERS_BY_BUSINESS_NUMBER_SUCCESS",
    "DELETE_USER_REQUEST",
    "DELETE_USER_SUCCESS",
    "FETCH_AGGREGATION_REQUEST",
    "FETCH_AGGREGATION_SUCCESS",
    "DELETE_AGGREGATION_REQUEST",
    "DELETE_AGGREGATION_SUCCESS",
    "FETCH_AGGREGATION_SOURCES",
    "FETCH_AGGREGATION_SOURCES_SUCCESS",
    "ADD_AGGREGATION_REQUEST",
    "ADD_AGGREGATION_SUCCESS",
    "BUSINESS_ERROR",
    'BUSINESS_SIGNUP_REQUEST',
    'BUSINESS_SIGNUP_SUCCESS',
    'FETCH_BUSINESS_WITH_GOOGLE',
    'FETCH_BUSINESS_WITH_GOOGLE_SUCCESS',
    'FETCH_BUSINESS_BY_EMAIL',
    'FETCH_BUSINESS_BY_EMAIL_SUCCESS',
    'FETCH_BUSINESS_BY_EMAIL_ERROR',
    'FETCH_CITIES',
    'FETCH_CITIES_SUCCESS',
    'REAGGREGATION_REQUEST',
    'REAGGREGATION_SUCCESS'
]);

export const fetchBusinessByBusinessNumber = (obj) => {
    return {
        type: BusinessActions.FETCH_BUSINESS_BY_BUSINESS_NUMBER,
        payload: obj
    };
};

export const fetchBusinessByBusinessNumberSuccess = (obj) => {
    return {
        type: BusinessActions.FETCH_BUSINESS_BY_BUSINESS_NUMBER_SUCCESS,
        payload: obj
    };
};

export const fetchUsersByBusinessNumber = (obj) => {
    return {
        type: BusinessActions.FETCH_USERS_BY_BUSINESS_NUMBER,
        payload: obj
    };
};

export const fetchUsersByBusinessNumberSuccess = (obj) => {
    return {
        type: BusinessActions.FETCH_USERS_BY_BUSINESS_NUMBER_SUCCESS,
        payload: obj
    };
};

export const fetchAggregationRequest = (obj) => {
    return {
        type: BusinessActions.FETCH_AGGREGATION_REQUEST,
        payload: obj
    };
};

export const fetchAggregationSuccess = (obj) => {
    return {
        type: BusinessActions.FETCH_AGGREGATION_SUCCESS,
        payload: obj
    };
};

export const findBusinessRequest = (obj) => {
    return {
        type: BusinessActions.FIND_BUSINESS_REQUEST,
        payload: obj
    };
};

export const findBusinessSuccess = (obj) => {
    return {
        type: BusinessActions.FIND_BUSINESS_SUCCESS,
        payload: obj
    };
};

export const updateBusinessRequest = (obj) => {
    return {
        type: BusinessActions.UPDATE_BUSINESS_REQUEST,
        payload: obj
    };
};

export const updateBusinessSuccess = (obj) => {
    return {
        type: BusinessActions.UPDATE_BUSINESS_SUCCESS,
        payload: obj
    };
};

export const addUserRequest = (obj) => {
    return {
        type: BusinessActions.ADD_USER_REQUEST,
        payload: obj
    };
};

export const addUserSuccess = (obj) => {
    return {
        type: BusinessActions.ADD_USER_SUCCESS,
        payload: obj
    };
};

export const addAggregationRequest = (obj) => {
    return {
        type: BusinessActions.ADD_AGGREGATION_REQUEST,
        payload: obj
    };
};

export const addAggregationSuccess = (obj) => {
    return {
        type: BusinessActions.ADD_AGGREGATION_SUCCESS,
        payload: obj
    };
};

export const deleteUserRequest = (obj) => {
    return {
        type: BusinessActions.DELETE_USER_REQUEST,
        payload: obj
    };
};

export const deleteUserSuccess = (obj) => {
    return {
        type: BusinessActions.DELETE_USER_SUCCESS,
        payload: obj
    };
};

export const deleteAggregationRequest = (obj) => {
    return {
        type: BusinessActions.DELETE_AGGREGATION_REQUEST,
        payload: obj
    };
};

export const deleteAggregationSuccess = (obj) => {
    return {
        type: BusinessActions.DELETE_AGGREGATION_SUCCESS,
        payload: obj
    };
};

export const fetchAggregationSources = (obj) => {
    return {
        type: BusinessActions.FETCH_AGGREGATION_SOURCES,
        payload: obj
    };
};

export const fetchAggregationSourcesSuccess = (obj) => {
    return {
        type: BusinessActions.FETCH_AGGREGATION_SOURCES_SUCCESS,
        payload: obj
    };
};

export const fetchStates = (obj) => {
    return {
        type: BusinessActions.FETCH_STATES,
        payload: obj
    };
};

export const fetchStatesSuccess = (obj) => {
    return {
        type: BusinessActions.FETCH_STATES_SUCCESS,
        payload: obj
    };
};

export const fetchCountries = (obj) => {
    return {
        type: BusinessActions.FETCH_COUNTRIES,
        payload: obj
    };
};

export const fetchCountriesSuccess = (obj) => {
    return {
        type: BusinessActions.FETCH_COUNTRIES_SUCCESS,
        payload: obj
    };
};

export const businessError = (obj) => {
    return {
        type: BusinessActions.BUSINESS_ERROR,
        payload: obj
    };
};

export const businessSignupRequest = obj => {
    return {
        type: BusinessActions.BUSINESS_SIGNUP_REQUEST,
        payload: obj
    };
};

export const businessSignupSuccess = obj => {
    return {
        type: BusinessActions.BUSINESS_SIGNUP_SUCCESS,
        payload: obj
    };
};

export const fetchBusinessByNumberWithGoogle = obj => {
    return {
        type: BusinessActions.FETCH_BUSINESS_WITH_GOOGLE,
        payload: obj
    };
};

export const fetchBusinessByNumberWithGoogleSuccess = obj => {
    return {
        type: BusinessActions.FETCH_BUSINESS_WITH_GOOGLE_SUCCESS,
        payload: obj
    };
};

export const fetchBusinessByEmailId = obj => {
    return {
        type: BusinessActions.FETCH_BUSINESS_BY_EMAIL,
        payload: obj
    };
};

export const fetchCities = (obj) => {
    return {
        type: BusinessActions.FETCH_CITIES,
        payload: obj
    };
};

export const fetchBusinessByEmailIdSuccess = obj => {
    return {
        type: BusinessActions.FETCH_BUSINESS_BY_EMAIL_SUCCESS,
        payload: obj
    };
};

export const fetchCitiesSuccess = (obj) => {
    return {
        type: BusinessActions.FETCH_CITIES_SUCCESS,
        payload: obj
    };
};

export const reaggregationRequest = (obj) => {
    return {
        type: BusinessActions.REAGGREGATION_REQUEST,
        payload: obj
    };
};

export const reaggregationSuccess = (obj) => {
    return {
        type: BusinessActions.REAGGREGATION_SUCCESS,
        payload: obj
    };
};

export default BusinessActions;
import React, { Component } from "react";
import { Form, Input, Row, Col, Spin } from "antd";
import { formItemLayout } from "../../../constants";
import { isEmpty, map, unionBy, find, values, remove } from "lodash";


class SocialProfiles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            childCategories: [],
            displayCategories: [],
        };
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { isLoading, businessInfo } = this.props;
        const { socialElements } = businessInfo;
        return (
            <div>
                {isLoading &&
                <div className="spin-overlay">
                    <Spin size="large" />
                </div>
                }
                <Form>
                    <Row>
                        <Col span={24}>
                            <Form.Item {...formItemLayout} label="Google">
                                {getFieldDecorator('socialProfileURLs.googleUrl', {
                                    initialValue: (socialElements) ? socialElements[0].googleUrl : null
                                })(
                                    <Input
                                        placeholder="Google Profile"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Facebook">
                                {getFieldDecorator('socialProfileURLs.facebookUrl', {
                                    initialValue: (socialElements) ? socialElements[0].facebookUrl : null
                                })(
                                    <Input
                                        placeholder="Facebook Profile"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Twitter">
                                {getFieldDecorator('socialProfileURLs.twitterUrl', {
                                    initialValue: (socialElements) ? socialElements[0].twitterUrl : null
                                })(
                                    <Input
                                        placeholder="Twitter Profile"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Youtube">
                                {getFieldDecorator('socialProfileURLs.youTubeUrl', {
                                    initialValue: (socialElements) ? socialElements[0].youtubeUrl : null
                                })(
                                    <Input
                                        placeholder="Youtube Profile"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Linkedin">
                                {getFieldDecorator('socialProfileURLs.linkedinUrl', {
                                    initialValue: (socialElements) ? socialElements[0].linkedinUrl : null
                                })(
                                    <Input
                                        placeholder="Linkedin Profile"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Instagram">
                                {getFieldDecorator('socialProfileURLs.instagramUrl', {
                                    initialValue: (socialElements) ? socialElements[0].instagramUrl : null
                                })(
                                    <Input
                                        placeholder="Instagram Profile"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Pinterest">
                                {getFieldDecorator('socialProfileURLs.pintrestUrl', {
                                    initialValue: (socialElements) ? socialElements[0].pintrestUrl : null
                                })(
                                    <Input
                                        placeholder="Pinterest Profile"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Bbb">
                                {getFieldDecorator('socialProfileURLs.bbbUrl', {
                                    initialValue: (socialElements) ? socialElements[0].bbbUrl : null
                                })(
                                    <Input
                                        placeholder="BBB Profile"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}


export default SocialProfiles;
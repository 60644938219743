import React, { Component } from "react";
import PropTypes from "prop-types";
import { message, Tag, Popover, Modal } from "antd";
import { isEmpty, pick } from "lodash";

import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";
import lookup from "./featureReleases.json";
import {dateFormat} from "../../constants";
import { getIsPageActionAccessible } from "../../utils";
import moment from "moment/moment";

class FeatureReleases extends Component {

    pageKey = "featureReleaseList";

    componentDidMount() {
        this.props.fetchFeaturesListRequest();
        this.props.fetchBusinessTypeRequest();
        this.props.fetchApplicableModuleRequest();
        this.props.fetchReleaseTypesRequest();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.fetchBusinessTypeRequest();
            this.props.fetchApplicableModuleRequest();
            this.props.fetchReleaseTypesRequest();
        }
    }

    formatData = (data) => {
        let formattedData = [];
        if(data && data.length > 0) {
            formattedData = data.map((record) => {
                return {
                    ...record,
                    title: `${record.title}${record.archived ? ' [Archived]' : ''}`,
                    key: record.releaseId,
                    id: record.releaseId,
                    releaseTypeName: record.releaseType && record.releaseType.name,
                    showOnDashboard: record.showCalloutInDashboard && 'Yes',
                    link: record.detailsUrl && record.productionLiveDate <= moment().format(dateFormat) ? <a href={process.env.REACT_APP_WEBSITE_URL + 'updates/' + record.detailsUrl} target="_blank" rel="noopener noreferrer">Link</a> : "",
                };
            });
        }
        return formattedData;
    };

    handleDelete = (data) => {
        const { releaseId } = data;
        this.props.deleteFeatureRequest({ releaseId, cb: this.renderFeedbackMsg });
    };

    renderFeedbackMsg = (errorMsg) => {
        if (!errorMsg) {
            return message.success(
                "You have successfully deleted this record."
            );
        } else {
            return message.error(
                "Something went wrong... Please try again later."
            );
        }
    };

    renderExpandedRow = (record) => (
        <div>
            <div className="mb1">
                <b>Title: </b>
                <span>{record.title}</span>
            </div>
            <div className="mb1">
                <b>Release Type: </b>
                <span>{record.releaseType && record.releaseType.name}</span>
            </div>
            <div className="mb1">
                <b>Show on Dashboard: </b>
                <span>{record.showCalloutInDashboard ? 'true' : 'false' }</span>
            </div>
            <div className="mb1">
                <b>Release Date: </b>
                <span>{record.productionLiveDate && record.productionLiveDate}</span>
            </div>
            <div className="mb1">
                <b>{record.productionLiveDate <= moment().format(dateFormat) ? "Url: " : "Preview: "}</b>
                <a href= {process.env.REACT_APP_WEBSITE_URL + `${record.productionLiveDate <= moment().format(dateFormat) ? "updates/" : "preview/"}` + record.detailsUrl + "/"} target="_blank" rel="noopener noreferrer">
                    {process.env.REACT_APP_WEBSITE_URL + `${record.productionLiveDate <= moment().format(dateFormat) ? "updates/" : "preview/"}` + record.detailsUrl + "/"}
                </a>
            </div>
        </div>
    );

    renderFeatureIndicators = (text, record) => {
        const { businessTypes = [], productionLiveDate = "" } = record;
        if (isEmpty(businessTypes)) return <span> {text} </span>;
        let  html = [], featureTooltip = "", publishTooltip = "";
        for (let i = 0; i < businessTypes.length; i++) {
            if (businessTypes[i].featureInNewsletter) {
                featureTooltip.length ? featureTooltip += ", " + businessTypes[i].name : featureTooltip += businessTypes[i].name;
            }
            if (businessTypes[i].includedInNewsletter) {
                publishTooltip.length ? publishTooltip += ", " + businessTypes[i].name : publishTooltip += businessTypes[i].name;
            }
        }
        if (publishTooltip.length) {
            html.push(
                <Popover content={`Included in ${publishTooltip} ${publishTooltip.indexOf(",") !== -1 ? "newsletters" : "newsletter" }`}>
                    <Tag color="#87d068"> P </Tag>
                </Popover>
            )
        }
        if (featureTooltip.length) {
            html.push(
                <Popover content={`Featured in ${featureTooltip} ${featureTooltip.indexOf(",") !== -1 ? "newsletters" : "newsletter" }`}>
                    <Tag color="#2db7f5"> F </Tag>
                </Popover>
            );
        }
        return (
            <div>
                <span style={{ marginRight: 10 }}>{text}</span>
                { html }
            </div>
        );
    }

    mapDataToForm = (rawData) => {
        const
            aggData = pick(rawData, ["id", ...lookup.valueLookup.aggFields]),
            {businessTypes} = rawData,
            businessTypeIds = [], featureInNewsletterByBusinessType= [];

        let includedInNewsletter = false;

        if (businessTypes && businessTypes.length) {
            for (const businessType of businessTypes) {
                businessTypeIds.push(businessType.id);
                includedInNewsletter = includedInNewsletter || businessType.includedInNewsletter;
                businessType.includedInNewsletter && businessType.featureInNewsletter && featureInNewsletterByBusinessType.push(businessType.id);
            }
        }

        return {
            ...aggData,
            applicableModuleIds: rawData.applicableModules ? rawData.applicableModules.map(module => module.id) : [],
            businessTypeIds,
            releaseType: rawData.releaseType ? rawData.releaseType.id : "",
            includedInNewsletter,
            featureInNewsletterByBusinessType,
            releaseId: rawData.id ? rawData.id : 0,
            archived: !rawData.archived
        };
    };

    archiveIntegration = (record, isActionAccessible) => {
        if (isActionAccessible) {
            const data = this.mapDataToForm(record);
            this.props.updateFeatureRequest({ data, cb: this.renderFeedback });
        }
    }

    renderFeedback = (errorType) => {
        this.props.fetchFeaturesListRequest();
        const goToFeatureReleasesPage = () => this.props.history.push("/feature-releases");
        if (!errorType) {
            return Modal.success({
                title: `You have successfully edited a record`,
                content: `Check the updated record in feature release list page`,
                okText: "Go to feature releases page",
                maskClosable: true,
                onOk() {
                    goToFeatureReleasesPage();
                }
            });
        } else {
            return Modal.warning({
                title: `The record was not edited successfully...`,
                content: errorType || "Please check your input or try again later!"
            });
        }
    };

    render() {
        const { data, isLoading, error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;
        let sortedData = [];
        if (data && data.length > 0) {
            sortedData = data.sort(function (a, b) {
                return b.dateModified - a.dateModified;
            });
        }

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        const isActionAccessible = getIsPageActionAccessible(this.pageKey, userData.privileges);

        const additionalHandler = [
            { 
                check: () => true ,
                render: record => (
                    {
                        cb: () => this.archiveIntegration(record, isActionAccessible),
                        label: record.archived ? "Restore" : "Archive",
                        path: ""
                    }
                )
            }
        ];

        return (
            <div className="Press-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    columns={lookup.columns}
                    pathForEdit={isActionAccessible ? "/feature-release/" : ""}
                    handleDelete={isActionAccessible && this.handleDelete}
                    expandedRowRender={this.renderExpandedRow}
                    dataSource={this.formatData(sortedData)}
                    renderFeatureIndicators={this.renderFeatureIndicators}
                    loading={isLoading}
                    additionalHandler={additionalHandler}
                />
            </div>
        );
    }
}

FeatureReleases.propTypes = {
    data: PropTypes.array.isRequired, 
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchFeaturesListRequest: PropTypes.func.isRequired,
    deleteFeatureRequest: PropTypes.func.isRequired
};

export default FeatureReleases;

import { beAPIResource } from "../../utils/apiHelper";

const reviewApi = {
    getBysource: (data) => beAPIResource.post("/support/reviews/", data),
    deleteByreviewId: (data) => beAPIResource.delete("support/reviews/", data),
    fetchSourcesFromDatabase: (data) => beAPIResource.get("support/reviews/", data),
    postReviewResponse: (data) => beAPIResource.put("support/reviews/", data)
};

export default reviewApi;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { message } from "antd";
import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";
import { getIsPageActionAccessible, getIsPageAdmin } from "../../utils";

import lookup from "./users.json";

class SalesReps extends Component {

    pageKey = "salesRepsList";

    componentDidMount() {
        this.props.fetchSalesRepsListRequest();
    }

    formatData = (data) => {
        const { name, privileges } = this.props.userData;
        const isAdmin = getIsPageAdmin(this.pageKey, privileges);

        return data.filter((record) => (record.emailId === name.toLowerCase() || isAdmin)).map((record) => {
            return {
                ...record,
                key: record.id,
                active: record.active ? "Yes" : "No",
                preferableTime: record.preferableStartTime+' - '+record.preferableEndTime,
                pageUrlIds: (() => {
                    let pageUrls = [];
                    record.pageUrlMessages && record.pageUrlMessages.map((page) => {
                        pageUrls.push(page.type);
                    })
                    return pageUrls.join();
                })()
            };
        });
    };

    renderFeedbackMsg = (errorMsg) => {
        if (!errorMsg) {
            return message.success(
                "You have successfully deleted this record."
            );
        } else {
            return message.error(
                "Something went wrong... Please try again later."
            );
        }
    };

    renderExpandedRow = (record) => (
        <div>
            <div className="mb1">
                <b>Page Names: </b>
                {record.pageUrlIds && record.pageUrlIds}
            </div>
        </div>
    );

    handleDelete = (record) => {
        const { id } = record;
        this.props.deleteSalesRepRequest({ id, cb: this.renderFeedbackMsg });
    };

    render() {
        const { salesRepListData, loadingUser, loadingRep, userError, userData } = this.props;
        
        const unauthorized = userError === "unauthorized" || !userData.name;
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="users-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    columns={lookup.columns}
                    expandedRowRender={this.renderExpandedRow}
                    pathForEdit={ "/sales-rep/" }
                    handleDelete={ this.handleDelete }
                    dataSource={this.formatData(salesRepListData)}
                    loading={(loadingUser || loadingRep)}
                />
            </div>
        );
    }
}

SalesReps.propTypes = {
    salesRepListData: PropTypes.array.isRequired,
    loadingUser: PropTypes.bool.isRequired,
    userError: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchSalesRepsListRequest: PropTypes.func.isRequired,
    deleteSalesRepRequest: PropTypes.func.isRequired
};

export default SalesReps;

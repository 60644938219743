import React, { Component } from "react";
import PropTypes from "prop-types";
import { pick, includes, isEmpty } from "lodash";
import { Form, Input, Select, Button, Alert, Spin, Modal, Divider } from "antd";

import { getSlug} from "../../../utils";
import { formItemLayout, formButtonLayout, dateFormat } from "../../../constants";
import lookup from "../resources.json";
import moment from "moment";
import { MultiResolutionImage, TextEditor, RedirectWrapper } from "../../../components";

const { TextArea } = Input;
const Option = Select.Option;
const FormItem = Form.Item;

class VideoTutorialForm extends Component {
    initialData = {
        id: 0,
        heading: "",
        description: "",
        link: "",
        metaTitle: "",
        metaDesc: "",
        bannerTitle: "",
        resourceDesc: "",
        detailPageLink: "",
        embedLink: "",
        videoSource:"youtube",
        displayDate: moment(),
        imageUrl:"",
        thumbnailUrl:"",
        bgImageLink: "",
        category: ""
    }

    state = {
        data: this.initialData,
        formError: "",
        fieldError: "",
        isEditPage: this.props.match.params.id && this.props.match.params.id !== "new",
        uploading: false
    };

    slugUniquenessTO = null;
    timestamp = Date.now().toString();

    componentDidMount() {
        const { location, match, fetchVideoTutorial } = this.props;
        const { isEditPage } = this.state;
        if (location.state && location.state.data) {
            this.setState({ data: this.mapDataToForm(location.state.data) });
        } else if (isEditPage && match.params.id) {
            fetchVideoTutorial({ id: match.params.id });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.curData.id !== prevProps.curData.id) {
            this.setState({ data: this.mapDataToForm(this.props.curData) });
        }
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                isEditPage: !!this.props.match.params.id,
                data: this.initialData
             });
        }
    }

    mapDataToForm = (rawData) => {
        let data = pick(rawData, [
            "id",
            "heading",
            "description",
            "link",
            "metaTitle",
            "metaDesc",
            "bannerTitle",
            "resourceDesc",
            "detailPageLink",
            "embedLink",
            "videoSource",
            "displayDate",
            "imageUrl",
            "thumbnailUrl",
            "bgImageLink",
            "category"
        ]);
        return { ...data, slugTitle: data.link ? data.link.split("/").slice(-2, -1)[0] : getSlug(data.heading) };
    };

    handleInputChange = (e, fieldName) => {
        if (e.target) {
            const { name, value } = e.target;
            this.handleFieldChange(name, value);

        } else if (e.file) {
            if (e.file.status === "uploading") {
                this.setState(() => ({uploading: true}));
            } else if (e.file.status === "done") {
                this.setState(() => ({uploading: false}));
            }
            if (e.file.response) {
                const { imageUrl, bgImageLink } = e.file.response;
                let data = { ...this.state.data };

                if (imageUrl) {
                    if(data.thumbnailUrl === "" || (!imageUrl.includes('@2x') && !imageUrl.includes('@3x'))) {
                        data.thumbnailUrl = imageUrl;
                        this.setState({ data });
                    }
                    this.handleFieldChange(fieldName, imageUrl);
                }

                if (bgImageLink) {
                    this.handleFieldChange(fieldName, bgImageLink);
                }
            }
        }
    };

    handleDateChange = (date, fieldName) => {
        this.handleFieldChange(fieldName, date.format(dateFormat));
    };

    handleFieldChange = (name, value) => {
        if(name === "embedLink") {
            const validLink = value.match("^[a-zA-Z0-9\\-\\_\\?\\=]+$") ? "" : "Please Provide a valid Video ID";
            this.setState({ fieldError: validLink });
        }
        let data = { ...this.state.data };
        const isEditPage = this.state.isEditPage;
        data[name] = value;
        if (name === "heading" && !isEditPage) {
            const slug = getSlug(value);
            data.slugTitle = slug;
            const link = `/resources/video-tutorials/${slug}/`;

            if (this.slugUniquenessTO) {
                clearTimeout(this.slugUniquenessTO);
            }

            this.slugUniquenessTO = setTimeout(this.props.fetchSlugOccurenceRequest, 1000, link);
        }
        this.setState({ data, formError: "" });
    };

    generateDetailPageLink = heading => `/resources/video-tutorials/${getSlug(heading)}/`;

    handleSubmit = (e) => {
        e.preventDefault();
        const
            rawData = { ...this.state.data },
            {isEditPage} = this.state,
            isVideo = true;
        if (isVideo) {
            let data = { ...this.state.data };
            data.introVideoSource = 'youtube';
            this.setState({ data });
        }
        const invalidVideoResource = isVideo && includes(pick(rawData, lookup.videoTutorialsRequiredFields), "")

        if (invalidVideoResource) {
            this.setState({ formError: "Please fill in all relevant fields!" });
            return;
        } else if (!isEmpty(this.state.fieldError)) {
            this.setState({ formError: `Invalid video ID : ${rawData.embedLink}` });
            return;
        }
        let data = pick(rawData, ["heading", "description", "link", "metaTitle", "metaDesc", "bannerTitle", "resourceDesc", "embedLink", "", "videoSource", "displayDate", "imageUrl", "thumbnailUrl", "bgImageLink", "category"]);

        // Generate detailPageLink only when video is being created, can't be edited
        if(!isEditPage && isVideo ){
            data.detailPageLink = this.generateDetailPageLink(data.heading);
            data.link = data.detailPageLink;
        }

        if (this.state.isEditPage) {
            data.id = this.state.data.id;
            this.props.updateVideoTutorial({ data, cb: this.renderFeedback });
        } else {
            this.props.addVideoTutorial({ data, cb: this.renderFeedback });
        }
    };

    getImageUploadUrl = (name) => {
        return `${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=${name}`;
    };

    renderFeedback = (errorType) => {
        const goToResourcesPage = () => this.props.history.push("/resource/video-tutorials/");
        if (!errorType) {
            return Modal.success({
                title: `You have successfully ${
                    this.state.isEditPage ? "edited" : "added"
                } a video tutorial`,
                content: `Check the ${
                    this.state.isEditPage ? "updated" : "new"
                } record in video tutorial list page`,
                okText: "Go to video tutorials page",
                maskClosable: true,
                onOk() {
                    goToResourcesPage();
                }
            });
        } else {
            return Modal.warning({
                title: `The record was not ${
                    this.state.isEditPage ? "edited" : "added"
                } successfully...`,
                content: "Please check your input or try again later..."
            });
        }
    };

    handleSelectFilter = (input, opt) => {
        const lowerInput = input.toLowerCase();
        const lowerLabel = opt.props.children.toLowerCase();

        return lowerLabel.indexOf(lowerInput) !== -1
    };

    isRelative = link => !link.startsWith('http');

    renderFormBlock = () => {
        const {
            heading,
            description,
            metaTitle,
            metaDesc,
            bannerTitle,
            resourceDesc,
            detailPageLink,
            embedLink,
            imageUrl,
            bgImageLink,
            category
        } = this.state.data,
        { uploading } = this.state;
        const { slugOccurence } = this.props;

        if (this.state.isEditPage && this.props.error === "resourceNotFound") {
            return <Alert message="Resource not found" type="error" showIcon />;
        } else {
            return (
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Card Heading"  required={true}>
                        <Input
                            name="heading"
                            value={heading}
                            placeholder="Resource card heading"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Card Description"   required={true}>
                        <TextArea
                            name="description"
                            value={description}
                            placeholder="Short description of the resource"
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Category"  required={true}>
                        <Select
                            mode="single"
                            value={category}
                            placeholder="Select category of the tutorial"
                            onChange={(value) =>
                                {
                                this.handleFieldChange("category", value)}
                            }>
                            {lookup.valueList.videoTutorialCategories.map((category) => (
                                <Option
                                    key={category.value}
                                    value={category.value}>
                                    {category.label}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <MultiResolutionImage
                        formItemLayout = {formItemLayout}
                        handleInputChange = {this.handleInputChange}
                        imageFieldName = "bgImageLink"
                        uploadName={getSlug(`${heading}_card_image` || 'card_image')}
                        imagePath={bgImageLink}
                        timestamp={this.timestamp}
                        label="Resource Card Image"
                         required={true}
                    />
                    <Divider>Details to show when resource card is clicked:</Divider>
                    <FormItem {...formItemLayout} label="Link" extra={slugOccurence > 0 ? <span className="text-error">Another resource with same URL already exists</span> : ""}>
                        <span>{detailPageLink || (heading ? this.generateDetailPageLink(heading) : null)} </span>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Video ID"  required={true}>
                        <Input
                            name="embedLink"
                            value={embedLink}
                            placeholder="Embedded video link"
                            onChange={this.handleInputChange}
                            addonBefore={"https://www.youtube.com/embed/"}
                        />
                        <span className="text-error">
                            {this.state.fieldError}
                        </span>
                    </FormItem>
                    <MultiResolutionImage
                        formItemLayout = {formItemLayout}
                        handleInputChange = {this.handleInputChange}
                        imageFieldName = "imageUrl"
                        uploadName={getSlug(heading || 'video')}
                        imagePath={imageUrl}
                        timestamp={this.timestamp}
                        required={true}
                    />
                    <FormItem {...formItemLayout} label="Meta Title" required={true}>
                        <Input
                            name="metaTitle"
                            value={metaTitle}
                            placeholder="Detail page meta title"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Meta Description" required={true}>
                        <TextArea
                            name="metaDesc"
                            value={metaDesc}
                            placeholder="Detail page meta description"
                            autoSize={{minRows: 2, maxRows: 6}}
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Detailed Page Heading"  required={true}>
                        <Input
                            name="bannerTitle"
                            value={bannerTitle}
                            placeholder="Detail page banner title"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Detailed Page Content"  required={true}>
                        <TextEditor
                            name="resourceDesc"
                            value={resourceDesc}
                            handleEditorChange={html => this.handleFieldChange("resourceDesc", html)}
                        />
                    </FormItem>
                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit" block disabled={uploading || (slugOccurence > 0) ? true : false}>
                            {this.state.isEditPage ? "Save" : "Create"}
                        </Button>
                    </FormItem>
                </Form>
            );
        }
    };

    render() {
        const { isLoading, error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="resources-root">
                <h2>
                    {this.state.isEditPage ? "Edit" : "Create"} Video Tutorial
                </h2>
                {this.state.isEditPage && isLoading ? (
                    <Spin />
                ) : (
                    this.renderFormBlock()
                )}
            </div>
        );
    }
}

const UpdateVideoTutorial = Form.create()(VideoTutorialForm);

UpdateVideoTutorial.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    curData: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchVideoTutorial: PropTypes.func.isRequired,
    updateVideoTutorial: PropTypes.func.isRequired,
    addVideoTutorial: PropTypes.func.isRequired,
    slugOccurence: PropTypes.number.isRequired,
    fetchSlugOccurenceRequest: PropTypes.func.isRequired
};

export default UpdateVideoTutorial;
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";
import {Spin, message, Popconfirm, Divider} from "antd";
import moment from "moment";
import { TableSkeleton, ResourceSteps, PageHeaderContainer } from "../../../components";
import lookup from "./constants";
import { getIsPageActionAccessible } from "../../../utils";

class Podcast extends Component {
    constructor(props) {
        super(props);

        const { podcastId } = props.match && props.match.params;
        this.pageKey = "podcastList";

        this.state = {
            isListing: !podcastId,
            podcastId,
            isUpdate: podcastId && podcastId !== "new"
        };

        this.isReadonly = !getIsPageActionAccessible(this.pageKey, ((props.userData && props.userData.privileges) || []));
    };

    componentDidMount() {
        if (this.state.isUpdate) this.props.fetchPodcastByIdRequest({ id: this.state.podcastId });
        else this.props.fetchPodcastListRequest();
    };

    static getDerivedStateFromProps(nextProps, state) {
        const podcastId = state.podcastId;
        const newPodcastId = nextProps.match && nextProps.match.params && nextProps.match.params.podcastId;
        const newState = {};

        if (podcastId !== newPodcastId) {
            newState.podcastId = newPodcastId;
            newState.isListing = !newPodcastId;
            newState.isUpdate = newPodcastId && newPodcastId !== "new";

            if (newState.isUpdate) nextProps.fetchPodcastByIdRequest({ id: newPodcastId });
            else nextProps.fetchPodcastListRequest();
        }

        return newState;
    }


    handleStepsSubmission = (data) => {
        const { userData } = this.props;

        const { cardTitle: card_heading, cardDesc: card_description, link, classification, image: card_image, featuredImage: featured_card_image } = data.card || {};
        const backendMappedData = { ...data, card_heading, card_description, card_image, featured_card_image, link, classification };

        delete(backendMappedData.card);

        if (this.state.isUpdate) {
            backendMappedData.updated_by = userData.name;
            this.props.updatePodcastRequest({id: this.state.podcastId, data: backendMappedData}, this.callbackStepsSubmission);
        } else {
            backendMappedData.created_by = userData.name;
            this.props.addPodcastRequest(backendMappedData, this.callbackStepsSubmission);
        }
    };

    handleOnDeleteClick = (e, id) => {
        e.preventDefault();
        this.props.deletePodcastRequest({ id }, this.callbackStepsSubmission);
    }

    callbackStepsSubmission = (data) => {
        message[data.type](data.message);
        if (data.type === "success") this.props.history.push("/resource/podcast");
    };

    mapListToDataSource = (data) => {
        return data.map((row, index) => {
            return {
                ...row,
                key:index,
                actions: (
                    <Fragment>
                        {
                            row.link &&
                                <>
                                    <a target={"_blank"} href={`${process.env.REACT_APP_WEBSITE_URL}..${row.link}`}>Website URL</a>
                                    <Divider type="vertical" />
                                </>
                        }
                        {
                            !this.isReadonly && (
                                <>
                                    <Link to={`/resource/podcast/${row.id}`} style={{width: "100%"}}>Edit</Link>
                                    <Divider type="vertical" />
                                    <Popconfirm title={"Do you want to delete this podcast?"} onConfirm={(e) => this.handleOnDeleteClick(e, row.id)} okText="Yes" cancelText="No" placement={"top"}>
                                        <a href="#">Delete</a>
                                    </Popconfirm>
                                </>
                            )
                        }
                    </Fragment>
                )
            }
        });
    };

    renderDetails = (record) => {
        const { title, briefDescription, detailedDescription, episodeId, duration } = record.podcast;
        return (
            <Fragment>
                {
                    <>
                        <div className="mb1">
                            <b>Title: </b>
                            <span>{title}</span>
                        </div>
                        <div className="mb1">
                            <b>Bried Description: </b>
                            <span>{briefDescription}</span>
                        </div>
                        <div className="mb1">
                            <b>Detailed Description: </b>
                            <div dangerouslySetInnerHTML={{ __html: detailedDescription }} />
                        </div>
                        <div className="mb1">
                            <b>Episode ID: </b>
                            <span>{episodeId}</span>
                        </div>
                        <div className="mb1">
                            <b>Duration: </b>
                            <span>{duration}</span>
                        </div>
                    </>
                }
            </Fragment>
        );
    };

    render() {
        const { userData, isLoading, podcastList } = this.props;
        const { isListing, isUpdate } = this.state;
        const unauthorized = !userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        if (isLoading) {
            return <Spin />
        }

        if (!isListing) {
            const 
                { card_heading: cardTitle="", card_description: cardDesc="", classification="", link="", card_image, meta, podcast, featured_card_image } = this.props.podcast,
                initialValues = { card: { cardTitle, cardDesc, classification, link, image: (card_image || ""), featuredImage: (featured_card_image || "") }, meta, podcast };

            return <ResourceSteps type="podcast" title={ `${isUpdate ? "Edit" : "New"} Podcast` } initialValues={ isUpdate ? initialValues : {} } additional={{ isUpdate }} userData={ userData } handleStepsSubmission={this.handleStepsSubmission}/>
        }

        return (
            <div>
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton loading={isLoading} columns={lookup.listingTableColumns} dataSource={this.mapListToDataSource(podcastList)} expandedRowRender={this.renderDetails} pageSize={10} showSizeChanger={false} />
            </div>
        )
    }
}

Podcast.propTypes = {
    isLoading: PropTypes.bool,
    userData: PropTypes.object,
    fetchPodcastListRequest: PropTypes.func.isRequired,
    fetchPodcastByIdRequest: PropTypes.func.isRequired,
    addPodcastRequest: PropTypes.func.isRequired,
    updatePodcastRequest: PropTypes.func.isRequired,
    deletePodcastRequest: PropTypes.func.isRequired,
    prodcast: PropTypes.object,
    prodcastList: PropTypes.array
};

export default Podcast;
import React, { Component } from 'react';
import { formItemLayout, formButtonLayout } from "../../constants";
import { Form, Input, Select, Button, Modal } from "antd";
import data from "./pages.json";
import PropTypes from "prop-types";
import _ from 'lodash';

class InactiveEmpForm extends Component {
    
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((error, values) => {
            if(!error) {
                const payload = {};
                payload.loginEmailId = this.props.userData.name;
                payload.loginName = this.props.userData.full_name;
                payload.groupName = values.groupName;
                payload.inactiveEmailId = values.inactiveEmailId;
    
                this.props.addInactiveEmp({ data: payload, cb: this.renderFeedback });
            } 
        })
    }

    renderFeedback = (response) => {
        this.props.form.resetFields();
        if (!response.error) {
            return Modal.success({
                title: "You will shortly receive an email regarding the update on inactive employee.",
                maskClosable: true
            });
        } else {
            return Modal.warning({
                title: "Oops! Unable to remove the mentioned inactive employee.",
                content: _.isString(response.error) ? response.error : "Something went wrong"
            });
        }
    };
    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <h2  style={{marginLeft: '63px'}}>Inactive employees</h2>
                <Form {...formItemLayout} onSubmit={this.handleSubmit} >
                    <Form.Item  label="Group Name">
                        {   getFieldDecorator('groupName', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Select a group name"
                                    },
                                ]
                            })(
                                <Select
                                style={{width: '62%'}}
                                placeholder= "Select Group Name"
                                >
                                    {
                                        data.groupName.map((ele) => 
                                            <Select.Option key={ele.value} value={ele.value} >{ele.label}</Select.Option>
                                        )
                                    }
                                </Select>
                            )
                        }
                    </Form.Item>
                    <Form.Item  label="Inactive Employee Email">
                        {   getFieldDecorator('inactiveEmailId', {
                                rules: [
                                    {
                                        validator: async (rule, inactiveEmailId, cb) => {
                                            if(!inactiveEmailId){
                                                return cb("Please provide inactive email");
                                            }
                                            const isValid  = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@(birdeye.com|birdeye.co.uk|getbirdeye.com.au)$/gi.test(inactiveEmailId);
                                            if (!isValid){
                                                return cb("Please provide valid birdeye email");
                                            }
                                        },
                                        "message": ""
                                    }
                                ]
                            })(
                                <Input
                                    autoComplete="off"
                                    id={'inactiveEmailId'}
                                    style={{width: '62%'}}
                                    name={"inactiveEmailId"}
                                    type="email"
                                    placeholder={"Enter Inactive Email"} 
                                />
                            )
                        }
                    </Form.Item>
                    <Form.Item {...formButtonLayout} >
                        <Button type="primary" htmlType="submit">
                            Remove user
                        </Button>
                    </Form.Item>
                </Form>
            </div> 
        )
    }
}

InactiveEmpForm.propTypes = {
    addInactiveEmp: PropTypes.func.isRequired,
    userData: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    isLoading:  PropTypes.bool.isRequired,
}

export default InactiveEmpForm;
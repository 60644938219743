import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import FeatureResourceActions, * as actionCreator from "./actions";
import FeatureResourceAPI from "./http";

const FeatureResourceSaga = [
    takeLatest(FeatureResourceActions.REQ_FEATURE_RESOURCE, reqFeatureResource),
    takeEvery(FeatureResourceActions.REQ_AVAILABLE_FEATURE_RESOURCE, reqAvailableFeatureResource),
    takeLatest(FeatureResourceActions.REQ_UPDATE_FEATURE_RESOURCE, reqUpdateFeatureResource),
    takeLatest(FeatureResourceActions.REQ_DELETE_FEATURE_REQUEST, reqDeleteFeatureResource),
    takeLatest(FeatureResourceActions.REQ_ADD_FEATURE_RESOURCE, reqAddFeatureResource),
    takeLatest(FeatureResourceActions.REQ_FEATURE_RESOURCE_BY_PAGE, reqFeatureResourceByPage),
    takeLatest(FeatureResourceActions.REQ_ADD_FEATURE_RESOURCE_BY_PAGE, reqAddFeatureResourceByPage),
    takeLatest(FeatureResourceActions.REQ_UPDATE_FEATURE_RESOURCE_BY_PAGE, reqUpdateFeatureResourceByPage),
    takeLatest(FeatureResourceActions.REQ_DELETE_FEATURE_REQUEST_BY_PAGE, reqDeleteFeatureResourceByPage)
];

function* reqFeatureResource(action) {
    try {
        const response =  yield call(FeatureResourceAPI.reqFeatureResource, action.payload);
        yield put(actionCreator.successFeatureResource({type: action.payload, data: response.data}));
    } catch (e) {
        yield put(actionCreator.errorFeatureResource({type: action.payload, data: []}));
    }
}

function* reqAvailableFeatureResource(action) {
    try {
        const response =  yield call(FeatureResourceAPI.reqAvailableFeatureResource, action.payload);
        yield put(actionCreator.successAvailableFeatureResource({type: action.payload, data: response.data}));
    } catch (e) {
        yield put(actionCreator.errorAvailableFeatureResource({type: action.payload, data: []}));
    }
}

function* reqUpdateFeatureResource(action) {
    try {
        const response =  yield call(FeatureResourceAPI.reqUpdateFeatureResource, action.payload.data);
        yield put(actionCreator.successUpdateFeatureResource({type: action.payload.type, data: response.data}));
        yield call(action.payload.cb, {type: "success", message: "This resource is succesfully updated as featured!"});
    } catch (e) {
        yield put(actionCreator.errorUpdateFeatureResource({type: action.payload.type, data: {}}));
        yield call(action.payload.cb, {type: "error", message: "Something went wrong. Please retry after sometime."});
    }
}

function* reqDeleteFeatureResource(action) {
    try {
        yield call(FeatureResourceAPI.reqDeleteFeatureResource, action.payload.data.id);
        yield put(actionCreator.successDeleteFeatureResource({type: action.payload.data.type, id: action.payload.data.id}));
        yield call(action.payload.cb, {type: "success", message: "This resource is succesfully reemoved as featured!"});
    } catch (e) {
        yield put(actionCreator.errorDeleteFeatureResource({type: action.payload.type, data: {}}));
        yield call(action.payload.cb, {type: "error", message: "Something went wrong. Please retry after sometime."});
    }
}

function* reqAddFeatureResource(action) {
    try {
        const response =  yield call(FeatureResourceAPI.reqAddFeatureResource, action.payload.data);
        yield put(actionCreator.successAddFeatureResource({type: action.payload.type, data: response.data}));
        yield call(action.payload.cb, {type: "success", message: "This resource is succesfully added as featured!"});
    } catch (e) {
        yield put(actionCreator.errorAddFeatureResource({type: action.payload.type, data: {}}));
        yield call(action.payload.cb, {type: "error", message: "Something went wrong. Please retry after sometime."});
    }
}

function* reqFeatureResourceByPage(action) {
    try {
        const response =  yield call(FeatureResourceAPI.reqFeatureResourceByPage, action.payload);
        yield put(actionCreator.successFeatureResourceByPage(response.data));
    } catch (e) {
        yield put(actionCreator.errorFeatureResourceByPage({}));
    }
}

function* reqAddFeatureResourceByPage(action) {
    try {
        const response =  yield call(FeatureResourceAPI.reqAddFeatureResourceByPage, action.payload);
        yield put(actionCreator.successAddFeatureResourceByPage(response.data));
        yield call(action.cb, {type: "success", message: "This has been succesfully added!"});
    } catch (e) {
        yield put(actionCreator.errorAddFeatureResourceByPage());
        yield call(action.cb, {type: "error", message: "Something went wrong. Please retry after sometime."});
    }
}

function* reqUpdateFeatureResourceByPage(action) {
    try {
        const response =  yield call(FeatureResourceAPI.reqUpdateFeatureResourceByPage, action.payload);
        yield put(actionCreator.successUpdateFeatureResourceByPage(response.data));
        yield call(action.cb, {type: "success", message: "This has been succesfully updated!"});
    } catch (e) {
        yield put(actionCreator.errorUpdateFeatureResourceByPage());
        yield call(action.cb, {type: "error", message: "Something went wrong. Please retry after sometime."});
    }
}

function* reqDeleteFeatureResourceByPage(action) {
    try {
        yield call(FeatureResourceAPI.reqDeleteFeatureResourceByPage, action.payload.id);
        yield put(actionCreator.successDeleteFeatureResourceByPage(action.payload));
        yield call(action.cb, {type: "success", message: "This has been succesfully removed!"});
    } catch (e) {
        yield put(actionCreator.errorDeleteFeatureResourceByPage());
        yield call(action.cb, {type: "error", message: "Something went wrong. Please retry after sometime."});
    }
}

export default FeatureResourceSaga;
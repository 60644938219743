import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {Redirect, withRouter} from "react-router-dom";
import { Button, message } from "antd";
import _ from 'lodash';
import lookup from "./../../constants/permission.json";
import { beAPIResource } from "../../utils/apiHelper";
import ReactHtmlParser from 'react-html-parser';

class PageHeaderContainer extends Component {

    constructor (props) {
        super(props);
        this.state = {
            path: '',
            isBtnDisabled: false,
            lookupData: lookup[props.pageKey]
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            lookupData: lookup[nextProps.pageKey]
        });
    }

    handleCTAOnClick = (path, additional) => {

        if (additional.isDirectAPI) return window.open(`${process.env.REACT_APP_API_BASE_URL}support/integrations/csv`);
        if (!_.isEmpty(additional.urlQuery)) path += ("?" + new URLSearchParams(additional.urlQuery).toString());
        if (!additional.isCache) return this.props.history.push(path);

        try {
            beAPIResource.get(path);
            message.success("Cache update is initiated");
            this.setState({isBtnDisabled: true, path: path}, () => {
                setTimeout(() => {
                    this.setState({isBtnDisabled: false})
                }, 10000)
            });
        } catch (e) {
            console.error(e);
            message.error("Unable to initiate cache update. Kindly try after some time");
        }
    }

    checkIndividualPermission(userPermissions, permission) {
        return userPermissions.indexOf(permission) !== -1;
    }

    checkUserPermission = (userData) => {
        const {lookupData} = this.state;
        if (!userData.name) {
            return false;
        }
        if (
            !this.checkIndividualPermission(userData.privileges, lookupData.permission) &&
            !this.checkIndividualPermission(userData.privileges, lookupData.subTabPermission)
        ) {
            return false;
        }
        return true;
    }

    render() {
        const { path, isBtnDisabled } = this.state;
        const { userData } = this.props;
        const userHasPermission = this.checkUserPermission(userData);
        const {lookupData} = this.state;

        if (!userHasPermission) {
            return <Redirect to="/" />;
        }

        return (
            <h2 style={{ ...this.props.style}}>
                {this.props.title || ReactHtmlParser(lookupData.title)}
                {
                    lookupData.cta.map((c, i) => {

                        if (userData.privileges.indexOf(c.permission) === -1) return null;

                        return (
                            <Button
                                key={i}
                                type={c.type}
                                style={{float: "right", marginLeft: "10px"}}
                                onClick={(e) => (this.handleCTAOnClick(c.path, { isCache: c.isCache, isDirectAPI: c.isDirectAPI, ...this.props.additional }))}
                                disabled={((path === c.path) && isBtnDisabled)}
                                icon={c.icon}
                                >{c.label}</Button>
                        );
                    })
                }
            </h2>
        );
    }
}

PageHeaderContainer.defaultProps = {
    title: "",
    pageKey: ""
};

PageHeaderContainer.propTypes = {
    title: PropTypes.string,
    pageKey: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    userData: state.User.userData
});

export default withRouter(connect(mapStateToProps)(PageHeaderContainer));

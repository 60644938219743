import React, { Component } from "react";
import PropTypes from "prop-types";
import {Input, Form, Upload, Button, Icon} from "antd";
import {isEmpty} from "lodash";
import { formItemLayout, formButtonLayout } from "../../constants";
import { uploadUtility } from "../../utils";

import "./style.scss";

const
    {Item: FormItem} = Form;

class PresenterFormClass extends Component {
    constructor(props) {
        super(props);

        
        this.state = {
            isNew: isEmpty(props.presenterInfo),
            isLoading: false
        };
    }

    handlePhotoUploadChange = (info) => {

        let fileList = [...info.fileList];

        fileList = fileList.slice(-1);

        fileList = fileList.map(file => {

            if (file.response) {
                file.url = file.response.imageUrl;
            }

            return file;
        });

        this.setState({photoFileList: fileList});
    };

    handleDisplayPhotoValue = (info) => {

        if (info && info.fileList && info.fileList[0] && info.fileList[0].status === "done") {
            return info.fileList[0].url;
        }
    };

    handleFormSubmit = (e) => {
        e.preventDefault();

        const
            {form, addPresenter, updatePresenter, presenterInfo, type} =  this.props,
            {isNew} = this.state;

        form.validateFieldsAndScroll((error, values) => {

            if (error) {
                return;
            }

            values.photo = uploadUtility.singleFile.submittedValue(values.photo);

            if (isNew) {
                addPresenter(values, type);
            } else {
                updatePresenter({ ...values, id: presenterInfo.id }, type);
            }
        });
    }

    render() {
        const 
            {form, presenterInfo={}, allPresenterEmails=[]} = this.props,
            {isNew, isLoading} = this.state,
            {getFieldDecorator} = form,
            {name, designation, email, photo} = presenterInfo || {},
            finalAllPresentersEmail = isNew ? allPresenterEmails : allPresenterEmails.filter(value => (value !== email));

        const buttonLabel = isNew ? "Add Presenter" : "Update Presenter";

        return (
            <>
                <Form onSubmit={this.handleFormSubmit}>
                    <FormItem {...formItemLayout} label={"Name"}>
                        {
                            getFieldDecorator("name", {
                                rules: [{
                                    "required": true,
                                    "message": "Provide name of the presenter"
                                }],
                                initialValue: name,
                            })(
                                <Input placeholder={"Enter full name"}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Email"}>
                        {
                            getFieldDecorator("email", {
                                rules: [{
                                    "type": "email",
                                    "message": "Provide a valid email for presenter"
                                },{
                                    "required": true,
                                    "message": "Provide email of the presenter"
                                }, {
                                    validator: (rule, email, cb) => {

                                        if (finalAllPresentersEmail.indexOf(email) !== -1) {
                                            return cb("This email is already in use, try with other email address or update existing one");
                                        }

                                        cb();
                                    },
                                    "message": "This email is already in use, try with other email address or update existing one"
                                }],
                                initialValue: email,
                            })(
                                <Input placeholder={"Enter email address"}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Designation"}>
                        {
                            getFieldDecorator("designation", {
                                rules: [{
                                    "required": true,
                                    "message": "Provide designation of the presenter"
                                }],
                                initialValue: designation,
                            })(
                                <Input placeholder={"Enter designation"}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Display Photo"}>
                        {
                            getFieldDecorator("photo", {
                                getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                rules: [{
                                    "required": true,
                                    "message": "Upload a photo of the presenter"
                                }],
                                valuePropName: "fileList",
                                initialValue: photo && [{
                                    uid: '-1',
                                    name: email,
                                    status: 'done',
                                    url: photo
                                }]
                            })(
                                <Upload
                                    name="files"
                                    accept="image/*"
                                    action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=webinarPresenters&timestamp=${Date.now().toString()}&webp=true`}
                                    withCredentials
                                    listType="picture"
                                    className="presenterDisplayPhotoUpload"
                                    >
                                    <Button>
                                        <Icon type="upload"/> Upload Photo
                                    </Button>
                                </Upload>
                            )
                        }
                    </FormItem>
                    <FormItem {...formButtonLayout} extra={isNew ? "" : <p>The update will only <b>reflected on webinars which are to be scheduled</b>. You will need to update exisitng scheduled webinars to reflect this update.</p>}>
                        <Button type="primary" htmlType="submit" icon={"check"} loading={isLoading}>
                            {buttonLabel}
                        </Button>
                    </FormItem>
                </Form>
            </>
        );
    }
}

const PresenterForm = Form.create()(PresenterFormClass);

PresenterForm.propTypes = {
    presenterInfo: PropTypes.object.isRequired,
    addPresenter: PropTypes.func.isRequired,
    updatePresenter: PropTypes.func.isRequired,
    type: PropTypes.oneOf(["webinar", "meeting"])
};

export default PresenterForm;
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import reducer from "../reducer";
import { User } from "../pages/Users/reducer";
import rootSaga from "../sagas";

const configStore = (initialState) => {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [sagaMiddleware];

    if (localStorage.beCmsUserData) {
        initialState.User = { ...User, ...JSON.parse(localStorage.beCmsUserData) };
    }

    const store = createStore(
        reducer,
        initialState,
        applyMiddleware(...middlewares)
    );

    sagaMiddleware.run(rootSaga);

    return store;
};

export default configStore;

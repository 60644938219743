import React, { Component } from "react";
import { isEmpty, forEach } from "lodash";
import { Form, Input, Button, Spin, Modal, Icon, Popconfirm } from "antd";
import TableSkeleton from "../../components/TableSkeleton";

import { RedirectWrapper } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";
import tableColumns from './reviewsTable';
const FormItem = Form.Item;

class DeleteReviewForm extends Component {
    state = {
        data: {
            businessNumber: null,
        },
        currentReviewId: "",
        birdeyeReviews: this.props.beReviews,
        reviewerEmail: ""
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((error, values) => {
            if (error) {
                return;
            }
            const { businessNumber } = values;
            if (!isEmpty(businessNumber)) {
                this.props.fetchBirdeyeReviews({data: { businessNumber: businessNumber, sources: [100]}});
            }
        });
    }

    formatData = (response) => {
        const result = response.reviewsWithAvgRating &&  response.reviewsWithAvgRating.reviewMessages || [];
        return result && result.map((record) => {
            const reviewerFn = record.reviewer.firstName === null ? '' : record.reviewer.firstName;
            const reviewerLn = record.reviewer.lastName === null ? '' : record.reviewer.lastName;
            const emailId = record.reviewer.emailId === null ? '' : record.reviewer.emailId ;
            const customAction = (
                <div>
                    <Popconfirm title={"Are you sure to send deletion request to reviewer?"} onConfirm={() => {this.handleDeleteRequest(record)}} okText="Yes" cancelText="No" placement={"left"}>
                        <a href="#"><Icon type="mail" /> Send Email</a>
                    </Popconfirm>
                </div>
            );
            return {
                id: record.id,
                emailId: emailId,
                reviewId: record.id,
                rating: record.rating,
                reviewer: reviewerFn + ' ' + reviewerLn,
                review: record.comments,
                date: record.reviewDate,
                action: customAction,
                key: record.id,
                response: record.response
            };
        });
    };

    handleDeleteRequest = (currentRecord) => {
        const currentReviewId = currentRecord.id;
        const reviewerEmail = currentRecord.reviewer.emailId;
        this.setState({
            currentReviewId, reviewerEmail
        }, () => {
            this.props.deleteReviewRequest({data: { businessId: currentRecord.businessId, reviewId: currentReviewId }, cb: this.renderFeedback});
        });
    }

    renderFeedback = () => {
        const reviewerEmail = this.state.reviewerEmail;
        const successMsg = reviewerEmail ? `Review deletion request sent to "` + reviewerEmail + `"` : `Review deletion request sent`;
        const { deleteRequestSuccess } = this.props;
        if( deleteRequestSuccess ){
            return Modal.success({
                title: "Success",
                okText: "Close",
                content: successMsg
            });
        }
        else if( !deleteRequestSuccess ){
            return Modal.error({
                title: `Something went wrong.`,
                content: this.props.deleteReviewResp.message,
            });
        }
    };

    render() {
        const { error, userData, isLoading, beReviews, isProcessing } = this.props;
        const { businessNumber } = this.state.data;
        const {getFieldDecorator} = this.props.form;
        const unauthorized = error === "unauthorized" || !userData.name;

          if (unauthorized) {
              return <RedirectWrapper location={this.props.location} />;
          }

        return (
            <div>
                <h2>Delete Birdeye Review Request</h2>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Business Number">
                        {getFieldDecorator('businessNumber', {
                            rules: [
                                {
                                    required: true,
                                    message: "please provide Business Number"
                                }, {
                                    pattern: '^[0-9]*$',
                                    message: "Provided input is not valid business ID"
                                }
                            ],
                            initialValue: businessNumber
                        })(
                            <Input
                                name="businessNumber"
                                placeholder="Enter Business Number"
                                onChange={this.handleInputChange}
                            />
                        )}
                    </FormItem>
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit">
                            Get Reviews
                        </Button>
                    </FormItem>
                </Form>
                {
                    isLoading ? (
                        <div style={{ margin: "auto", width: "17%" }}>
                            <Spin tip="Fetching Birdeye Reviews..." />
                        </div>
                    ) : (
                        <Form layout ="inline">
                            <TableSkeleton
                                className = {"ant-table-bordered"}
                                columns = {tableColumns.columns}
                                dataSource = {this.formatData(beReviews)}
                            />
                        </Form>
                    )
                }
                {
                    isProcessing ? (
                        <div className="spin-overlay">
                            <Spin size="large" />
                        </div>
                    ) : ("")
                }
            </div>
        );
    }
}

const DeleteReview = Form.create()(DeleteReviewForm);

export default DeleteReview;

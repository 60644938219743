import { beAPIResource } from "../../utils/apiHelper";

export const FeatureResourceAPI = {
    reqFeatureResource: payload => beAPIResource.get(`/support/feature-resource/${payload}`),
    reqAvailableFeatureResource: payload => beAPIResource.get(`/support/feature-resource/available/${payload}`),
    reqUpdateFeatureResource: payload => beAPIResource.put(`/support/feature-resource`, payload),
    reqDeleteFeatureResource: id => beAPIResource.delete(`/support/feature-resource/${id}`),
    reqAddFeatureResource: payload => beAPIResource.post(`/support/feature-resource`, payload),
    reqFeatureResourceByPage: () => beAPIResource.get("/support/feature-resource/by/page"),
    reqAddFeatureResourceByPage: payload => beAPIResource.post("/support/feature-resource/by/page", payload),
    reqUpdateFeatureResourceByPage: payload => beAPIResource.put("/support/feature-resource/by/page", payload),
    reqDeleteFeatureResourceByPage: id => beAPIResource.delete(`/support/feature-resource/by/page/${id}`),
};

export default FeatureResourceAPI;
import { combineReducers } from "redux";

import userReducer from "../pages/Users/reducer";
import resourcesReducer from "../pages/Resources/reducer";
import integrationsReducer from "../pages/Integrations/reducer";
import integrationDomainsReducer from "../pages/IntegrationDomains/reducer";
import effectsReducer from "../pages/Effects/reducer";
import pressesReducer from "../pages/Presses/reducer";
import reviewReducer from "../pages/Reviews/reducer";
import analyticsReducer from "../pages/Analytics/reducer";
import productEmailsReducer from "../pages/ProductEmails/reducer";
import salesRepReducer from "../pages/SalesReps/reducer";
import pagesReducer from "../pages/Pages/reducer";
import leadgenManagementReducer from "../pages/LeadgenManagement/reducer";
import categoriesReducer from "../pages/Categories/reducer";
import activity from "../pages/Activity/reducer";
import featuresReducer from "../pages/FeatureReleases/reducer";
import freeProdDashboardReducer from "../pages/FreeProdDashboard/reducer";
import emailServiceReducer from "../pages/EmailService/reducer";
import prospectorReducer from "../pages/Prospector/redux/reducer";
import webinarToolReducer from "../pages/Webinar/reducer";
import businessReducer from "../pages/BusinessOperations/reducer";
import resourcesV2Reducer from "../pages/ResourcesV2/reducer";
import requestBusinessAccessReducer from "../pages/RequestBusinessAccess/reducer";
import featureResourceReducer from "../pages/FeatureResource/reducer";
import gmbReducer from "../pages/Gmb/reducer";
import scitorReducer from "../pages/Scitor/reducer";
import businessActionsReducer from "../pages/BusinessActions/reducer";
import signatureReducer from "../pages/Signature/reducer";
import partnerReducer from "../pages/Partner/reducer.js";
import upsellReducer from '../pages/Upsell/reducer.js';
import featurePagesReducer from '../pages/FeaturePages/reducer.js';
import twilioReducer from "../pages/Twilio/reducer";
import scanToolReducer from "../pages/ScanTool/reducer";
import selfServeReducer from "../pages/IntegrationSelfServe/reducer";

const rootReducer = combineReducers({
    User: userReducer,
    Resources: resourcesReducer,
    Integrations: integrationsReducer,
    IntegrationDomains: integrationDomainsReducer,
    Effects: effectsReducer,
    Presses: pressesReducer,
    ProductEmails: productEmailsReducer,
    SalesRep: salesRepReducer,
    Pages: pagesReducer,
    Reviews: reviewReducer,
    Analytics: analyticsReducer,
    LeadgenManagement: leadgenManagementReducer,
    Categories: categoriesReducer,
    activity,
    Features: featuresReducer,
    FreeProdDashboard: freeProdDashboardReducer,
    EmailService: emailServiceReducer,
    Prospector: prospectorReducer,
    WebinarTool: webinarToolReducer,
    Business: businessReducer,
    ResourcesV2: resourcesV2Reducer,
    RequestBusinessAccess: requestBusinessAccessReducer,
    FeatureResource: featureResourceReducer,
    Gmb: gmbReducer,
    Scitor: scitorReducer,
    BusinessActions: businessActionsReducer,
    Signature: signatureReducer,
    Partner: partnerReducer,
    Upsell: upsellReducer,
    FeaturePages: featurePagesReducer,
    Twilio: twilioReducer,
    ScanTool: scanToolReducer,
    SelfServe: selfServeReducer
});

export default rootReducer;

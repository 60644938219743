import { takeLatest, call, put } from "redux-saga/effects";
import LeadgenManagementActions, * as actionCreator from "./actions";
import LeadgenManagementAPI from "./http";

const leadgenManagementSaga = [
    takeLatest(LeadgenManagementActions.SEND_CONTACT_REQ, sendContactRequest),
    takeLatest(LeadgenManagementActions.REQ_CONTACT_DETAILS,reqContactDetails),
    takeLatest(LeadgenManagementActions.SEND_SFDC_LEAD_FORM_REQ, sendSfdcLeadFormRequest),
    takeLatest(LeadgenManagementActions.SEND_SFDC_LEAD_FILE_REQ, sendSfdcLeadFileRequest),
    takeLatest(LeadgenManagementActions.REQ_PAGEDATA_CAMPAIGN_URL_BUILDER, reqPageDataCampaignUrlBuilder),
    takeLatest(LeadgenManagementActions.LOG_CAMPAIGN_URL_BUILDER_ACTIVITY, logCampaignUrlBuilderActivity),
    takeLatest(LeadgenManagementActions.REQ_CAMPAIGN_URL_BUILDER_ARCHIVE, reqCampaignUrlBuilderArchive)
];

function* sendContactRequest(action) {

    try {

        let response;

        if (action.pageType === "subscribe") {
            response = yield call(LeadgenManagementAPI.sendSubscription, action.payload);
        } else {
            response = yield call(LeadgenManagementAPI.sendUnsubscription, action.payload);
        }

        yield put(actionCreator.successContactResponse(response.data.details));
    } catch (e) {

        yield put(actionCreator.errorContactResponse((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqContactDetails({payload}) {
    try {
        const {data=[]} = yield call(LeadgenManagementAPI.reqContactDetails, payload);
        yield put(actionCreator.successContactDetails(data));
    } catch (e) {
        yield put(actionCreator.errorContactDetails(e.data || {}));
    }
}

function* sendSfdcLeadFormRequest(action) {
    try {

        const response = yield call(LeadgenManagementAPI.sendSfdcLeadForm, action.payload);
        yield put(actionCreator.successSfdcLeadResponse(response.data.details || "Lead is successfully created."));
    } catch (e) {

        yield put(actionCreator.errorSfdcLeadResponse((e.data && e.data.data && e.data.data.message) || "Lead could not be created."));
    }
}

function* sendSfdcLeadFileRequest(action) {
    try {

        const response = yield call(LeadgenManagementAPI.sendSfdcLeadFile, action.payload);
        yield put(actionCreator.successSfdcLeadResponse(response.data.message || "File is successfully uploaded and is in process, we will notify you with the codes on your email once it is done."));
    } catch (e) {

        yield put(actionCreator.errorContactResponse((e.data && e.data.data && e.data.data.message) || "File failed to upload."));
    }
}

function* reqPageDataCampaignUrlBuilder(action) {
    try {
        const response = yield call(LeadgenManagementAPI.reqPageDataCampaignUrlBuilder);
        yield put(actionCreator.successPageDataCampaignUrlBuilder(response && response.data));
    } catch (e) {
        yield put(actionCreator.errorPageDataCampaignUrlBuilder([]));
    }
}

function* logCampaignUrlBuilderActivity(action) {
    try {
        const response = yield call(LeadgenManagementAPI.logCampaignUrlBuilderActivity, action.payload);
    } catch (e) {
        // NOTHING TO DO
    }
}

function* reqCampaignUrlBuilderArchive({payload}) {
    try {
        const {data=[]} = yield call(LeadgenManagementAPI.reqCampaignUrlBuilderArchive, payload);
        yield put(actionCreator.successCampaignUrlBuilderArchive(data));
    } catch (e) {
        yield put(actionCreator.errorCampaignUrlBuilderArchive());
    }
}

export default leadgenManagementSaga;

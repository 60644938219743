import React, { Component } from 'react';
import { TableSkeleton } from "../../components";
import { Button, Col, Tooltip } from 'antd';
import PropTypes from "prop-types";
import data from "./pages.json";

class History extends Component {

    formatData = (data) => {
        return data.map((record, idx) => {
            return {
                ...record,
                key: idx,
                groupName: record.groupName ?  record.groupName : "-",
                inactiveEmail: record.inactiveEmailId ? record.inactiveEmailId : "-",
                deletedDate: record.deletedDate ? new Date(record.deletedDate).toDateString() : "-",
            };
        });
    }

    render(){
        const {inactiveEmpList, isLoading } = this.props;
        return (
            <div>
              <h2>Inactive employee history</h2>
                <TableSkeleton
                    columns={data.historyTabColumns}
                    dataSource={this.formatData(inactiveEmpList)}
                    loading={isLoading}
                />  
            </div>
        )
    }
}

History.propTypes = {
    inactiveEmpList: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default History;
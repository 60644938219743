import { beAPIResource } from "../../utils/apiHelper";

const ResourcesV2API = {
    getEbook: () => beAPIResource.get(`support/resource/ebook`),
    getEbookById: (id) => beAPIResource.get(`support/resource/ebook/${id}`),
    addEbook: (payload) => beAPIResource.post(`support/resource/ebook`, payload),
    updateEbook: (payload) => beAPIResource.put(`support/resource/ebook`, payload),

    getGuide: () => beAPIResource.get(`support/resource/guide`),
    getGuideById: (id) => beAPIResource.get(`support/resource/guide/${id}`),
    addGuide: (payload) => beAPIResource.post(`support/resource/guide`, payload),
    updateGuide: (payload) => beAPIResource.put(`support/resource/guide`, payload),

    getWhitepaper: () => beAPIResource.get(`support/resource/whitepaper`),
    getWhitepaperById: (id) => beAPIResource.get(`support/resource/whitepaper/${id}`),
    addWhitepaper: (payload) => beAPIResource.post(`support/resource/whitepaper`, payload),
    updateWhitepaper: (payload) => beAPIResource.put(`support/resource/whitepaper`, payload),

    getArticle: () => beAPIResource.get('support/resource/article'),
    getArticleById: (payload) => beAPIResource.get(`support/resource/article/${payload.articleId}`),
    addArticle: (payload) => beAPIResource.post('support/resource/article', payload.data),
    updateArticle: (payload) => beAPIResource.put(`support/resource/article/${payload.articleId}`, payload.data),

    getPodcast: () => beAPIResource.get("support/resource/podcast"),
    getPodcastById: (id) => beAPIResource.get(`support/resource/podcast/${id}`),
    addPodcast: (payload) => beAPIResource.post("support/resource/podcast", payload),
    updatePodcast: (id, payload) => beAPIResource.put(`support/resource/podcast/${id}`, payload),
    deletePodcast: (id) => beAPIResource.delete(`support/resource/podcast/${id}`)
};

export default ResourcesV2API;

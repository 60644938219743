import { takeLatest, call, put } from "redux-saga/effects";
import ProductEmailsActions, * as actionCreator from "./actions";
import productEmailApi from "./http";

const productEmailSagas = [
    takeLatest(ProductEmailsActions.FETCH_EMAIL_BY_MONTH_REQUEST, fetchEmailByMonth),
    takeLatest(ProductEmailsActions.FETCH_EMAIL_BY_TEMPLATE_REQUEST, fetchEmailByTemplate),
    takeLatest(ProductEmailsActions.FETCH_TEMPLATE_STATS_REQUEST, fetchTemplateStats),
    takeLatest(ProductEmailsActions.FETCH_EMAIL_VERSIONS_REQUEST, fetchEmailVersions)
];

function* fetchEmailByMonth(action) {
    try {
        const resp = yield call(productEmailApi.getByMonthData, action.payload);
        yield put(actionCreator.fetchEmailByMonthSuccess(resp.data.data));
    } catch (e) {
        yield put(
            actionCreator.productEmailError(e.data.data || { error: "productEmailByMonthDataNotFound" })
        );
    }
}

function* fetchEmailByTemplate(action) {
    try {
        const resp = yield call(productEmailApi.getByTemplateData, action.payload);
        yield put(actionCreator.fetchEmailByTemplateSuccess(resp.data.data));
    } catch (e) {
        yield put(actionCreator.productEmailError(e.data.data || { error: "productEmailByTemplateDataNotFound" }));
    }
}

function* fetchTemplateStats(action) {
    try {
        const resp = yield call(productEmailApi.getTemplateStats, action.payload);
        yield put(actionCreator.fetchTemplateStatsSuccess(resp.data.data));
    } catch (e) {
        yield put(actionCreator.fetchTemplateStatsError(e.data.data || { error: "productEmailTemplateStatsNotFound" }));
    }
} 

function* fetchEmailVersions(action) {
    try {
        const resp = yield call(productEmailApi.getEmailVersions, action.payload);
        yield put(actionCreator.fetchEmailVersionsSuccess(resp.data.data));
    } catch (e) {
        yield put(actionCreator.fetchEmailVersionsError(e.data.data || { error: "productEmailVersionsNotFound" }));
    }
} 

export default productEmailSagas;

import React from "react";
import PropTypes from "prop-types";
import {salesforceBaseUrl} from "../../../../constants";

const Legend = ({ imgSrc, label, target, profileId }) => (
  <div className="legend">
    <img src={imgSrc} alt="" />
    {target ? (
      <a href={`${salesforceBaseUrl}${profileId}`} target="_blank">
        {label}
      </a>
    ) : (
      <span>{label}</span>
    )}
  </div>
);

Legend.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  target: PropTypes.bool,
  profileId: PropTypes.string
};

export default Legend;

import { createActionTypes } from "../../utils";

export const GmbActions = createActionTypes("gmb", [
    'GET_GMB_REQUEST',
    'GET_GMB_SUCCESS',
    'GET_GMB_ERROR'
]);

export const getGmbRequest = obj => {
    return {
        type: GmbActions.GET_GMB_REQUEST,
        payload: obj
    };
};

export const getGmbSuccess = obj => {
    return {
        type: GmbActions.GET_GMB_SUCCESS,
        payload: obj
    };
};

export const gmbError = (obj) => {
    return {
        type: GmbActions.GET_GMB_ERROR,
        payload: obj
    };
};

export default GmbActions;
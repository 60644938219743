import { beAPIResource } from "../../../utils/apiHelper";

const prospectorApi = {
  getRecord: (params) => beAPIResource.post("support/prospector/records", { params }),
  getTargetProfile: (params) => beAPIResource.post("support/prospector/profile", params),
  getStates: () => beAPIResource.get("support/prospector/states"),
  getCities: () => beAPIResource.get("support/prospector/cities"),
  getIndustries: () => beAPIResource.get("support/prospector/main-category"),
  getOwners: () => beAPIResource.get("support/prospector/owners")
};

export default prospectorApi;

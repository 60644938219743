import React from "react";
import PropTypes from "prop-types";
import { Statistic } from "antd";

import { capitalize } from "../../../../utils";
import panelConfig from "../panel/panelConfig.json";

const Info = ({ type, count }) => {
  const { typeLookup } = panelConfig;
  let title = typeLookup[type] ? capitalize(typeLookup[type]) : "";
  if (!title || !count) return null;
  if (count === 1) {
    if (type !== "opportunity") title = title.slice(0, -1);
    else title = "Opportunity";
  }

  return (
    <div className="info">
      <Statistic title={title} value={count} />
    </div>
  );
};

Info.propTypes = {
  type: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired
};

export default Info;

import { createActionTypes } from "../../utils";

export const SalesRepActions = createActionTypes("sales_reps", [
    "FETCH_SALES_REPS_LIST_REQUEST",
    "FETCH_SALES_REPS_LIST_SUCCESS",
    "FETCH_SALES_REP_REQUEST",
    "FETCH_SALES_REP_SUCCESS",
    "ADD_SALES_REP_REQUEST",
    "ADD_SALES_REP_SUCCESS",
    "UPDATE_SALES_REP_REQUEST",
    "UPDATE_SALES_REP_SUCCESS",
    "DELETE_SALES_REP_REQUEST",
    "DELETE_SALES_REP_SUCCESS",
    "SALES_REP_REQUEST_ERROR",
    "FETCH_ALL_SALES_REP_INVITATION_REQUEST",
    "FETCH_ALL_SALES_REP_INVITATION_SUCCESS",
    "FETCH_ALL_SALES_REP_INVITATION_ERROR",
    "FETCH_SALES_REPS_TIME_ZONES_REQUEST",
    "FETCH_SALES_REPS_TIME_ZONES_SUCCESS",
    "FETCH_SALES_REPS_TIME_ZONES_ERROR"
]);

export const fetchSalesRepsListRequest = () => {
    return {
        type: SalesRepActions.FETCH_SALES_REPS_LIST_REQUEST
    };
};

export const fetchSalesRepsListSucess = (obj) => {
    return {
        type: SalesRepActions.FETCH_SALES_REPS_LIST_SUCCESS,
        payload: obj
    };
};

export const fetchSalesRepRequest = (obj) => {
    return {
        type: SalesRepActions.FETCH_SALES_REP_REQUEST,
        payload: obj
    };
};

export const fetchSalesRepSuccess = (obj) => {
    return {
        type: SalesRepActions.FETCH_SALES_REP_SUCCESS,
        payload: obj
    };
};

export const addSalesRepRequest = (obj) => {
    return {
        type: SalesRepActions.ADD_SALES_REP_REQUEST,
        payload: obj
    };
};

export const addSalesRepSuccess = (obj) => {
    return {
        type: SalesRepActions.ADD_SALES_REP_SUCCESS,
        payload: obj
    };
};

export const updateSalesRepRequest = (obj) => {
    return {
        type: SalesRepActions.UPDATE_SALES_REP_REQUEST,
        payload: obj
    };
};

export const updateSalesRepSuccess = (obj) => {
    return {
        type: SalesRepActions.UPDATE_SALES_REP_SUCCESS,
        payload: obj
    };
};

export const deleteSalesRepRequest = (obj) => {
    return {
        type: SalesRepActions.DELETE_SALES_REP_REQUEST,
        payload: obj
    };
};

export const deleteSalesRepSuccess = (obj) => {
    return {
        type: SalesRepActions.DELETE_SALES_REP_SUCCESS,
        payload: obj
    };
};

export const salesRepError = (obj) => {
    return {
        type: SalesRepActions.SALES_REP_REQUEST_ERROR,
        payload: obj
    };
};

export const fetchSalesRepInvitationRequest = (obj) => {
    return {
        type: SalesRepActions.FETCH_ALL_SALES_REP_INVITATION_REQUEST,
        payload: obj
    };
};

export const fetchSalesRepInvitationSuccess = (obj) => {
    return {
        type: SalesRepActions.FETCH_ALL_SALES_REP_INVITATION_SUCCESS,
        payload: obj
    };
}

export const fetchSalesRepInvitationError = (obj) => {
    return {
        type: SalesRepActions.FETCH_ALL_SALES_REP_INVITATION_ERROR,
        payload: obj
    };
}

export const fetchSalesRepTimeZonesRequest = () => {
    return {
        type: SalesRepActions.FETCH_SALES_REPS_TIME_ZONES_REQUEST
    }
}

export const fetchSalesRepTimeZonesSuccess = (obj) => {
    return {
        type: SalesRepActions.FETCH_SALES_REPS_TIME_ZONES_SUCCESS,
        payload: obj
    }
}

export const fetchSalesRepTimeZonesError = () => {
    return {
        type: SalesRepActions.FETCH_SALES_REPS_TIME_ZONES_ERROR,
        payload: []
    }
}

export default SalesRepActions;

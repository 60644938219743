import React, { Component } from "react";
import PropTypes from "prop-types";
import { pick, includes, isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { Form, Input, Select, Button, Alert, Spin, Modal, Upload, Icon , Divider, Switch } from "antd";

import { createSFDCampaign, decodeResourceClassification,  encodeResourceClassification, getSlug } from "../../../utils";
import { formItemLayout, formButtonLayout, dateFormat, genericWebinarOGImagePath } from "../../../constants";
import lookup from "../resources.json";
import { MultiResolutionImage, RedirectWrapper } from "../../../components";


const { TextArea } = Input;
const Option = Select.Option;
const FormItem = Form.Item;

class ResourceForm extends Component {
    initialData = {
        id: 0,
        heading: "",
        description: "",
        leadSfdcCampaign: "",
        link: "",
        industries: [],
        topics: [],
        metaTitle: "",
        metaDesc: "",
        bannerTitle: "",
        resourceDesc: "",
        detailPageLink: "",
        pdfLink: "",
        slugTitle: "",
        bgImageLink: "",
        featuredCardImage: "",
        googleEventImage: genericWebinarOGImagePath,
        competitors: [],
        integrations: [],
        country: '',
        showInList: true
    };

    state = {
        data: this.initialData,
        formError: "",
        fieldError: "",
        isEditPage: this.props.match.params.id && this.props.match.params.id !== "new",
        uploading: false,
        descriptionLength: 0
    };

    slugUniquenessTO = null;
    timestamp = Date.now().toString();

    componentDidMount() {
        const { location, match, fetchResourceRequest, fetchIntegrationListRequest, fetchCompetitorListRequest } = this.props;
        if (location.state && location.state.data) {
            this.setState({ data: this.mapDataToForm(location.state.data) });
        } else if (match.params.id && this.state.isEditPage) {
            fetchResourceRequest({ id: match.params.id });
        }

        const description = this.props.form.getFieldValue('description');

        if(description) {
            this.setState({descriptionLength : description.length});
        }

        if(this.state.data.googleEventImage === "") {
            this.setState({googleEventImage : genericWebinarOGImagePath});
        }

        fetchIntegrationListRequest();
        fetchCompetitorListRequest();
    }

    componentDidUpdate(prevProps) {
        if (this.props.curData.id !== prevProps.curData.id) {
            this.setState({ data: this.mapDataToForm(this.props.curData) });
        }
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                isEditPage: !!this.props.match.params.id,
                data: this.initialData
             });
        }
    }

    mapDataToForm = (rawData) => {
        let data = pick(rawData, [
            "id",
            "heading",
            "description",
            "leadSfdcCampaign",
            "link",
            "metaTitle",
            "metaDesc",
            "bannerTitle",
            "resourceDesc",
            "detailPageLink",
            "pdfLink",
            "keyword",
            "displayDate",
            "bgImageLink",
            "featuredCardImage",
            "googleEventImage",
            "competitors",
            "integrations",
            "country",
            "showInList"
        ]);
        const { topics, industries } = decodeResourceClassification(
            rawData.classification,
            lookup
        );

        return { ...data, topics, industries, slugTitle: data.link ? data.link.split("/").slice(-2, -1)[0] : getSlug(data.heading) };
    };

    handleInputChange = (e, fieldName) => {
        if (e.target) {
            const { name, value } = e.target;
            this.handleFieldChange(name, value);

            if (name === "description") this.setState({descriptionLength: value.length});
        } else if (e.file) {
            if (e.file.status === "uploading") {
                this.setState(() => ({uploading: true}));
            } else if (e.file.status === "done") {
                this.setState(() => ({uploading: false}));
            }
            if (e.file.response) {
                const { imageUrl } = e.file.response;
                this.handleFieldChange(fieldName, imageUrl);
            }
        }
    };

    handleDateChange = (date, fieldName) => {
        this.handleFieldChange(fieldName, date.format(dateFormat));
    };

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        const isEditPage = this.state.isEditPage;
        data[name] = value;
        if (name === "heading" && !isEditPage) {
            data.slugTitle = getSlug(value);
        }
        if (!isEditPage && data.heading) {
            const link = `/resources/case-studies/${data.slugTitle}/`;
            data.link = link;

            if (this.slugUniquenessTO) {
                clearTimeout(this.slugUniquenessTO);
            }

            this.slugUniquenessTO = setTimeout(this.props.fetchSlugOccurenceRequest, 1000, link);
        }
        this.setState({ data, formError: "" });
    };

    generateDetailPageLink = heading => `/resources/videos/${getSlug(heading)}/`;

    handleSubmit = async (e) => {
        e.preventDefault();
        const
            rawData = { ...this.state.data },
            {isEditPage} = this.state,
            isVideo = false,
            isCaseStudy = true;
        const
            missingIndustry = isEmpty(rawData.industries),
            missingTopics   = isEmpty(rawData.topics),
            invalidCaseStudyResource = isCaseStudy && includes(pick(rawData, lookup.caseStudyRequiredFields), ""),
            hasErrors =  missingIndustry || missingTopics || invalidCaseStudyResource || !rawData.country;
        const descLength = this.state.descriptionLength;
        if (hasErrors) {
            this.setState({ formError: "Please fill in all relevant fields!" });
            return;
        } else if (descLength > 175) {
            return;
        } else if (!isEmpty(this.state.fieldError)) {
            this.setState({ formError: `Invalid video ID : ${rawData.embedLink}` });
            return;
        }

        let data = pick(rawData, ["heading", "description", "leadSfdcCampaign", "link", "metaTitle", "metaDesc", "bannerTitle", "resourceDesc", "embedLink", "pdfLink", "keyword", "displayDate", "bgImageLink", "googleEventImage", "competitors", "integrations", "featuredCardImage", "country", "showInList"]);
    
        // Generate detailPageLink only when video is being created, can't be edited
        if(!isEditPage && isVideo ){
            data.detailPageLink = this.generateDetailPageLink(data.heading);
            data.link = data.detailPageLink;
        }
        if(!data.leadSfdcCampaign) data.leadSfdcCampaign = await createSFDCampaign('case-study', data, this.props.userData.name);

        data = {
            ...data,
            category: lookup.categoryLookup["case study"],
            classification: encodeResourceClassification(
                "case study",
                rawData.industries,
                rawData.topics,
                lookup
            )
        };
        if (this.state.isEditPage) {
            data.id = this.state.data.id;
            this.props.updateResourceRequest({ data, cb: this.renderFeedback });
        } else {
            this.props.addResourceRequest({ data, cb: this.renderFeedback });
        }
    };

    getImageUploadUrl = (name) => {
        return `${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=${name}`;
    };

    renderFeedback = (errorType) => {
        const goToResourcesPage = () => this.props.history.push("/resource/case-studies");
        if (!errorType) {
            return Modal.success({
                title: `You have successfully ${
                    this.state.isEditPage ? "edited" : "added"
                } a resource`,
                content: `Check the ${
                    this.state.isEditPage ? "updated" : "new"
                } record in resources list page`,
                okText: "Go to resources page",
                maskClosable: true,
                onOk() {
                    goToResourcesPage();
                }
            });
        } else {
            return Modal.warning({
                title: `The record was not ${
                    this.state.isEditPage ? "edited" : "added"
                } successfully...`,
                content: "Please check your input or try again later..."
            });
        }
    };

    handleSelectFilter = (input, opt) => {
        const lowerInput = input.toLowerCase();
        const lowerLabel = opt.props.children.toLowerCase();

        return lowerLabel.indexOf(lowerInput) !== -1
    };

    isRelative = link => !link.startsWith('http');
    handleSwitch = (value) => this.setState({ data: {...this.state.data, showInList: value} });
    renderFormBlock = () => {
        const {
            id,
            heading,
            description,
            leadSfdcCampaign,
            link,
            industries,
            topics,
            metaTitle,
            metaDesc,
            pdfLink,
            slugTitle,
            bgImageLink,
            featuredCardImage,
            googleEventImage,
            competitors,
            integrations,
            country,
            showInList
        } = this.state.data,
        { uploading, descriptionLength } = this.state;
        const { slugOccurence } = this.props;

        if (this.state.isEditPage && this.props.error === "resourceNotFound") {
            return <Alert message="Resource not found" type="error" showIcon />;
        } else {
            return (
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Card Heading" required={true}>
                        <Input
                            name="heading"
                            value={heading}
                            placeholder="Resource card heading"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Card Description" extra={<div style={{'textAlign': 'right'}}> {descriptionLength > 175 ? <span style={{color: "red"}} >{descriptionLength-175} characters overloaded (Limit 175)</span> : <span>{175-descriptionLength} characters left.</span> }</div>} required={true}>
                        <TextArea
                            name="description"
                            value={description}
                            maxLength={175}
                            placeholder="Short description of the resource"
                            autosize={{ minRows: 2, maxRows: 6 }}
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Link" required={true} extra={slugOccurence > 0 ? <span className="text-error">Another resource with same URL already exists</span> : ""}>
                        <span>{link}</span>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Country" required={true}>
                        <Select
                            mode="single"
                            value={country}
                            placeholder="Select country"
                            required={true}
                            onChange={(value) =>
                                {
                                this.handleFieldChange("country", value)}
                            }>
                            {lookup.valueList.countries.map((country) => (
                                <Option
                                    key={country.value}
                                    value={country.value}>
                                    {country.label}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Related Industries" required={true}>
                        <Select
                            mode="multiple"
                            value={industries}
                            placeholder="Related industries for filtering"
                            onChange={(value) =>
                                {
                                this.handleFieldChange("industries", value)}
                            }>
                            {lookup.valueList.industries.map((industry) => (
                                <Option
                                    key={industry.value}
                                    value={industry.value}>
                                    {industry.label}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Related topics" required={true}>
                        <Select
                            mode="multiple"
                            value={topics}
                            placeholder="Related topics for filtering"
                            onChange={(value) =>
                                this.handleFieldChange("topics", value)
                            }>
                            {lookup.valueList.topics.map((topic) => (
                                <Option key={topic.value} value={topic.value}>
                                    {topic.label}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Related Integrations">
                        <Select
                            mode="multiple"
                            value={integrations}
                            placeholder="Related topics for filtering"
                            onChange={(value) => this.handleFieldChange("integrations", value)}
                            loading={this.props.isLoadingIntegrationList}
                            filterOption={this.handleSelectFilter}
                            >
                            {this.props.integrationList.map((integration, i) => (
                                <Option key={i} value={integration.id}>
                                    {integration.name}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Related Competitors">
                        <Select
                            mode="multiple"
                            value={competitors}
                            placeholder="Related topics for filtering"
                            onChange={(value) =>
                                this.handleFieldChange("competitors", value)
                            }
                            loading={this.props.isLoadingCompetitorList}
                            filterOption={this.handleSelectFilter}
                            >
                            {this.props.competitorList.map((competitor, i) => (
                                <Option key={i} value={competitor.id}>
                                    {competitor.name}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <Divider>Details to show when resource card is clicked:</Divider>
                    <FormItem {...formItemLayout} label="Meta Title" required={true}>
                        <Input
                            name="metaTitle"
                            value={metaTitle}
                            placeholder="Detail page meta title"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Meta Description" required={true}>
                        <TextArea
                            name="metaDesc"
                            value={metaDesc}
                            placeholder="Detail page meta description"
                            autosize={{minRows: 2, maxRows: 6}}
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <MultiResolutionImage
                        formItemLayout = {formItemLayout}
                        handleInputChange = {this.handleInputChange}
                        imageFieldName = "bgImageLink"
                        uploadName={getSlug(heading)}
                        imagePath={bgImageLink}
                        disabled={!heading}
                        timestamp={this.timestamp}
                        label="Resource Card Image"
                        required={true}
                    />
                    <MultiResolutionImage
                        formItemLayout = {formItemLayout}
                        handleInputChange = {this.handleInputChange}
                        imageFieldName = "featuredCardImage"
                        uploadName={`featured-card-${getSlug(heading)}`}
                        imagePath={featuredCardImage}
                        disabled={!heading}
                        timestamp={this.timestamp}
                        label="Featured Card Image"
                        required
                    />
                    <FormItem {...formItemLayout} label="OG Image" required={true}>
                        <Upload
                            name="files"
                            action={this.getImageUploadUrl(`og-image-${slugTitle}`)}
                            withCredentials
                            listType="picture"
                            accept={"image/*"}
                            onChange={(e) => this.handleInputChange(e, "googleEventImage")}>
                            <Button disabled={!heading}>
                                <Icon type="upload"/> Upload OG Image
                            </Button>
                        </Upload>
                        {
                            googleEventImage &&
                            <img className="mt1 mb1 mx-width-360" src={googleEventImage} alt=""/>
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label="PDF Link" required={true}>
                        <Upload
                            name="files"
                            action={this.getImageUploadUrl(slugTitle)}
                            withCredentials
                            listType="picture"
                            accept={"application/pdf"}
                            onChange={(e) => this.handleInputChange(e, "pdfLink")}>
                            <Button disabled={!heading}>
                                <Icon type="upload"/> Upload PDF
                            </Button>
                        </Upload>
                        {
                            pdfLink &&
                            <a href={pdfLink} target="_blank" rel="noopener noreferrer">
                                {pdfLink}
                            </a>
                        }
                    </FormItem>
                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}
                    <FormItem {...formItemLayout} label={"Show In Resource List"} extra={<p>This input is to distinguish between <b>Public</b> and <b>Private</b> case studies.<br/>In order to make it Private, toggle it to <b>No</b> otherwise toggle it to <b>Yes</b>.</p>}>
                        {
                            this.props.form.getFieldDecorator("showInList", {
                                rules: [{
                                    "required": false
                                }],
                                valuePropName: "checked",
                                initialValue: showInList
                            })(
                                <Switch checkedChildren={"Yes"} unCheckedChildren={"No"} style={{width: "60px"}} onChange={this.handleSwitch}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit" block disabled={uploading || (slugOccurence > 0) ? true : false}>
                            {this.state.isEditPage ? "Save" : "Create"}
                        </Button>
                    </FormItem>
                    {
                        this.state.isEditPage && <FormItem {...formButtonLayout} >
                            <Button type="primary" block>
                                <Link to={`/case-studies/${id}`}>
                                    <Icon type="edit" /> Edit case study detail
                                </Link>
                            </Button>
                        </FormItem>
                    }
                </Form>
            );
        }
    };

    render() {
        const { isLoading, error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="resources-root">
                <h2>
                    {this.state.isEditPage ? "Edit" : "Create"} Case Study
                </h2>
                {this.state.isEditPage && isLoading ? (
                    <Spin />
                ) : (
                    this.renderFormBlock()
                )}
            </div>
        );
    }
}

const UpdateResource = Form.create()(ResourceForm);

UpdateResource.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    curData: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchResourceRequest: PropTypes.func.isRequired,
    updateResourceRequest: PropTypes.func.isRequired,
    addResourceRequest: PropTypes.func.isRequired,
    fetchIntegrationListRequest: PropTypes.func.isRequired, 
    fetchCompetitorListRequest: PropTypes.func.isRequired,
    slugOccurence: PropTypes.number.isRequired,
    fetchSlugOccurenceRequest: PropTypes.func.isRequired
};

export default UpdateResource;

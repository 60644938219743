import { createActionTypes } from '../../utils';
export const SelfServePanelActions = createActionTypes("selfServePanel", [
    "FETCH_SELF_SERVE_LIST_REQUEST",
    "FETCH_SELF_SERVE_LIST_SUCCESS",
    "FETCH_SELF_SERVE_LIST_ERROR",
    "FETCH_SELF_SERVE_APPROVE",
    "FETCH_SELF_SERVE_APPROVE_SUCCESS",
    "FETCH_SELF_SERVE_APPROVE_ERROR",
    "FETCH_SELF_SERVE_REJECT",
    "FETCH_SELF_SERVE_REJECT_SUCCESS",
    "FETCH_SELF_SERVE_REJECT_ERROR",
    "SELF_SERVE_CRM_SAVE_REQUEST",
    "SELF_SERVE_CRM_SAVE_REQUEST_SUCCESS",
    "SELF_SERVE_CRM_SAVE_REQUEST_ERROR",
    "FETCH_REQUESTED_CRM_LIST_REQUEST",
    "FETCH_REQUESTED_CRM_LIST_SUCCESS",
    "FETCH_REQUESTED_CRM_LIST_ERROR",
])

export const reqBusinessDetails = () => {
    return {
        type: SelfServePanelActions.FETCH_SELF_SERVE_LIST_REQUEST
    }
}

export const reqBusinessDetailsSuccess = (data) => {
    return {
        type: SelfServePanelActions.FETCH_SELF_SERVE_LIST_SUCCESS,
        payload: [...data]
    }
}

export const reqBusinessDetailsError = () => {
    return {
        type: SelfServePanelActions.FETCH_SELF_SERVE_LIST_ERROR,
        payload: []
    }
}

export const reqSelfServeReject = (data) => {
    return {
        type: SelfServePanelActions.FETCH_SELF_SERVE_REJECT,
        payload: { ... data}
    }
}

export const reqSelfServeRejectSuccess = (data) => {
    return {
        type: SelfServePanelActions.FETCH_SELF_SERVE_REJECT_SUCCESS,
        payload: {...data}
    }
}

export const reqSelfServeRejectError = () => {
    return {
        type: SelfServePanelActions.FETCH_SELF_SERVE_REJECT_ERROR,
        payload: []
    }
}

export const reqSelfServeApprove = (data) => {
    return {
        type: SelfServePanelActions.FETCH_SELF_SERVE_APPROVE,
        payload: { ... data}
    }
}

export const reqSelfServeApproveSuccess = (data) => {
    return {
        type: SelfServePanelActions.FETCH_SELF_SERVE_APPROVE_SUCCESS,
        payload: {...data}
    }
}

export const reqSelfServeApproveError = () => {
    return {
        type: SelfServePanelActions.FETCH_SELF_SERVE_APPROVE_ERROR,
        payload: {}
    }
}

export const reqSelfServeCRMSave = (data) => {
    return {
        type: SelfServePanelActions.SELF_SERVE_CRM_SAVE_REQUEST,
        payload: { ... data}
    }
}

export const reqSelfServeCRMSaveSuccess = (data) => {
    return {
        type: SelfServePanelActions.SELF_SERVE_CRM_SAVE_REQUEST_SUCCESS,
        payload: {...data}
    }
}

export const reqSelfServeCRMSaveError = () => {
    return {
        type: SelfServePanelActions.SELF_SERVE_CRM_SAVE_REQUEST_ERROR,
        payload: {}
    }
}

export const requestedCRMIntegrationList = () => {
    return {
        type: SelfServePanelActions.FETCH_REQUESTED_CRM_LIST_REQUEST
    }
}

export const requestedCRMIntegrationSuccess = (data) => {
    return {
        type: SelfServePanelActions.FETCH_REQUESTED_CRM_LIST_SUCCESS,
        payload: [...data]
    }
}

export const requestedCRMIntegrationError = () => {
    return {
        type: SelfServePanelActions.FETCH_REQUESTED_CRM_LIST_ERROR,
        payload: []
    }
}

export default SelfServePanelActions;
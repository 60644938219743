
// return activity type for a sample url like 'POST /support/case-studies/111/blocks'
import moment from "moment";

export const getActivityType = url => ({
    "PUT"   : "Updated",
    "POST"  : "Created",
    "DELETE": "Deleted",
}[url.split(" ")[0].toUpperCase().trim()]);

export const fromActivityData = data => ({
    ...data,
    api   : data.api.split("-").join(" ").replace(/^./, str => str.toUpperCase()),
    type  : getActivityType(data.url),
    time  : moment(data.createdAt).format('llll')
});

export const toSearchQueryString = (params) => {
    const query = Object.keys(params).map(param => params[param] ? `${param}=${params[param]}`: null).filter(p => p).join("&");
    return `?${query}`
};

export const mapFilterOptions = filtersOptions => {
    const result = {};
    if(filtersOptions.username){
        result.user = filtersOptions.username;
    }

    if(filtersOptions.api){
        result.api = filtersOptions.api
    }

    if(filtersOptions.type){
        result.type = filtersOptions.type
    }

    return result;
};

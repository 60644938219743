import { RequestBusinessAccessActions } from "./actions";

let RequestBusinessAccess = {
    businessList: [],
    requestList: [],
    error: "",
    isLoading: false,
    isRequesting: false
};

const requestBusinessAccessReducer = (state = RequestBusinessAccess, action) => {
    switch (action.type) {
        case RequestBusinessAccessActions.FETCH_BUSINESS_LIST:
            return {
                ...state,
                error: "",
                isLoading: true,
                businessList: []
            };
        case RequestBusinessAccessActions.FETCH_BUSINESS_LIST_SUCCESS:
            return {
                ...state,
                businessList: action.payload,
                error: "",
                isLoading: false
            };
        case RequestBusinessAccessActions.FETCH_BUSINESS_LIST_ERROR:
            return {
                ...state,
                businessList: [],
                error: "",
                isLoading: false
            };
        case RequestBusinessAccessActions.REQUEST_ACCESS_TO_BUSINESS:
            return {
                ...state,
                error: "",
                isRequesting: true
            };
        case RequestBusinessAccessActions.REQUEST_ACCESS_TO_BUSINESS_SUCCESS:
            return {
                ...state,
                error: "",
                isRequesting: false
            };
        case RequestBusinessAccessActions.REQUEST_ACCESS_TO_BUSINESS_ERROR:
            return {
                ...state,
                error: "",
                isRequesting: false
            };
        case RequestBusinessAccessActions.FETCH_REQUEST_HISTORY_LIST:
            return {
                ...state,
                error: "",
                isLoading: true,
                requestList: []
            };
        case RequestBusinessAccessActions.FETCH_REQUEST_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                error: "",
                isLoading: false,
                requestList: action.payload
            };
        case RequestBusinessAccessActions.FETCH_REQUEST_HISTORY_LIST_ERROR:
            return {
                ...state,
                error: "",
                isLoading: false,
                requestList: []
            };
        default:
            return state;
    }
};

export default requestBusinessAccessReducer;
import React from "react";  
import moment from "moment";
import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";
import lookup from "./constants";

class ShowRequestedCRM extends React.Component {

    constructor(props) {
        super(props);
        this.pageKey = "integrationRequestedAppList";
    }

    componentDidMount (){
        this.props.requestedCRMIntegrationList();
    }

    formatData = (data = []) => {
        return data.map((record) => {
            return {
                key: record.id,
                businessName: record.businessName ?  record.businessName : "-",
                businessId: record.businessId ?  record.businessId : "-",
                requestedAppDetails: record.requestedAppDetails ? record.requestedAppDetails : "-",
                requestedAppName: record.requestedAppName ? record.requestedAppName : "-",
                requestedAt: record.requestedAt ? moment(record.requestedAt).format('MM-DD-YYYY HH:mm') : "-"
            }
        })
    }

    render() {
        const {userData, errorMessage, requestedCRMDetails= [], isLoading} = this.props;
        const unauthorized = errorMessage === "unauthorized" || !userData.name;
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location}/>;
        }
        return (
            <>
                <PageHeaderContainer pageKey={this.pageKey} title="Requested CRM Integration" />
                    <TableSkeleton
                        columns={lookup.showRequestedCRM}
                        dataSource={this.formatData(requestedCRMDetails)}
                        loading={isLoading}
                    />
            </>
        )
    }
}

export default ShowRequestedCRM;
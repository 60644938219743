import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import { Form, Button, message, Select, Spin, Icon, Row, Col } from "antd";
import lookup from "./lookup";

const FormItem = Form.Item;

class FeatureByPageTypeForm extends React.Component {
    constructor(props) {
        super(props);
        this.totalFeaturedResources = [0, 1, 2];
        this.state = {
            isEdit: !_.isEmpty(props.location.state)
        }
    }

    componentDidMount() {
        this.state.isEdit && this.initialiseForm();
        this.props.reqFeaturePagesList();
    }

    initialiseForm() {
        const initialValues = this.api2Form(this.props.location.state || {});
        this.totalFeaturedResources.map(resourceKey => {
            const resourceType = initialValues[`resourceType_${resourceKey}`];
            if (!resourceType) return;
            _.isEmpty(this.props.availableFeatureResource[resourceType]) && this.props.reqAvailableFeatureResource(resourceType);
        });
        this.props.form.setFieldsValue(initialValues);
    }

    getResourceFromResourceTypeAndId = (resourceType, resourceId) => {
        const resourceList = this.props.availableFeatureResource[resourceType];
        if (_.isEmpty(resourceList)) return {};
        return resourceList.filter((resource) => (resource.id === resourceId))[0];
    }

    api2Form = (values) => {
        let formValues = {};
        if (values.slug) formValues.slug = values.slug;

        values.resource && values.resource.map((row, i) => {
            formValues[`resourceType_${this.totalFeaturedResources[i]}`] = row.resourceType;
            formValues[`resourceId_${this.totalFeaturedResources[i]}`] = row.resource.id;
        });

        return formValues;
    }

    form2API = (values) => {
        let apiValues = { page: this.props.match.params.pageType };
        if (values.slug) apiValues.slug = values.slug;

        apiValues.resource = [];
        this.totalFeaturedResources.forEach(resourceKey => {
            if (!values[`resourceType_${resourceKey}`] || !values[`resourceId_${resourceKey}`]) return;
            const resourceType = values[`resourceType_${resourceKey}`], resourceId = values[`resourceId_${resourceKey}`];
            apiValues.resource.push({ resourceType, resource: this.getResourceFromResourceTypeAndId(resourceType, resourceId) });
        });

        return apiValues;
    }

    handleAfterSubmission = (data) => {
        message[data.type](data.message);
        this.props.history.push(`/feature-resource/by/${this.props.match.params.pageType}`);
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((error, values) => {
            if (error) return;

            const user = this.props.userData.name;
            const convertedValues = this.form2API(values);

            if (this.state.isEdit) {
                this.props.reqUpdateFeatureResourceByPage({
                    updated_by: user,
                    ...convertedValues
                }, this.handleAfterSubmission);
            } else {
                this.props.reqAddFeatureResourceByPage({
                    created_by: user,
                    ...convertedValues
                }, this.handleAfterSubmission);
            }
        });
    }

    handleFilterOption = (input, option) => {
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    handleResourceTypeChange = (resourceType, resourceKey) => {
        _.isEmpty(this.props.availableFeatureResource[resourceType]) && this.props.reqAvailableFeatureResource(resourceType);
        this.props.form.resetFields([`resourceId_${resourceKey}`]);
    }

    renderSingleFeatruedResource = (resourceKey) => {
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const availableFeatureResource = this.props.availableFeatureResource;

        const { [`resourceType_${resourceKey}`]: selectedResourceType, slug } = getFieldsValue();
        console.log(availableFeatureResource);
        const filteredResourceType = lookup.resource.filter(resource => (resource.value !== "press"));

        return (
            <Row key={resourceKey} align="middle" type="flex" gutter={[16, 16]}>
                <Col sm={2}>
                    <span style={{ display: "block" }}>Resource#{resourceKey+1}</span>
                </Col>
                <Col sm={6}>
                    <FormItem label="Resource Type">
                        {
                            getFieldDecorator(`resourceType_${resourceKey}`, {
                                rules: [{
                                    required: true,
                                    message: "Please select a resource type"
                                }]
                            })(
                                <Select placeholder="Select a resource type" onChange={(e) => this.handleResourceTypeChange(e, resourceKey)} disabled={!slug}>
                                    {
                                        filteredResourceType.map((r) => <Select.Option key={r.value} value={r.value}>{r.label}</Select.Option>)
                                    }
                                </Select> 
                            )
                        }
                    </FormItem>
                </Col>
                <Col sm={16}>
                    <FormItem label="Resource">
                        {
                            getFieldDecorator(`resourceId_${resourceKey}`, {
                                rules: [{
                                    required: true,
                                    message: "Please select a resource"
                                }]
                            })(
                                <Select placeholder="Type to search and select a resource" showSearch onSearch={this.handleOnSearch} optionFilterProp="children" filterOption={this.handleFilterOption} disabled={!selectedResourceType}>
                                    {
                                        selectedResourceType && !_.isEmpty(availableFeatureResource[selectedResourceType]) && availableFeatureResource[selectedResourceType].map((r) => <Select.Option key={r.id} value={r.id}>{r.heading}</Select.Option>)
                                    }
                                </Select> 
                            )
                        }
                    </FormItem>
                </Col>
            </Row>
        );
    } 

    renderFeatruedResources = () => {
        return (
            this.totalFeaturedResources.map(this.renderSingleFeatruedResource)
        )
    }

    render () {
        const { error, userData, isLoading, match, featurePagesList } = this.props;
        const { isEdit } = this.state;
        const unauthorized = error === "unauthorized" || !userData.name;
        const { getFieldDecorator } = this.props.form;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        if (!match.params || !match.params.pageType) {
            return <Redirect to={match.url.replace(/\/manage$/, "")} />;
        }

        return (
            <div>
                <h2 style={{ textTransform: "capitalize" }}>{isEdit ? "Edit" : "New"} Featured Resource By Page</h2>
                <Spin tip="Please wait..." spinning={isLoading} size="large" indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}>
                    <Form layout="vertical" onSubmit={this.handleFormSubmit}>
                        <Row align="middle" type="flex" gutter={[16, 16]}>
                            <Col sm={2}>
                                <span style={{ display: "block" }}>Page</span>
                            </Col>
                            <Col sm={22}>
                                <FormItem label="Page">
                                    {
                                        getFieldDecorator("slug", {
                                            rules: [{
                                                required: true,
                                                message: "Please select a target resource type"
                                            }]
                                        })(
                                            <Select placeholder="Select a page" disabled={isEdit} showSearch onSearch={this.handleOnSearch}>
                                                {
                                                    featurePagesList.map((product) => <Select.Option key={product.slug} value={product.slug}>{product.name}</Select.Option>)
                                                }
                                            </Select>
                                        )
                                    }
                                </FormItem>
                            </Col>
                        </Row>
                        { this.renderFeatruedResources() }
                        <FormItem>
                            <Row>
                                <Col sm={{ span:22, offset: 2 }}>
                                    <Button type="primary" htmlType="submit">Submit</Button>
                                </Col>
                            </Row>
                        </FormItem>
                    </Form>
                </Spin>
            </div>
        );
    };
}

const FeatureFormByPageType = Form.create()(FeatureByPageTypeForm);

FeatureFormByPageType.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    reqAvailableFeatureResource: PropTypes.func.isRequired,
    availableFeatureResource: PropTypes.object
};
export default FeatureFormByPageType;
import React from 'react';
import {getActivityType} from "./activityAPIHelper";
import { Tag } from 'antd';

const ActivitySearchFilter = ({resetSearchParam, searchParams}) => {
    const
        {user: filterUser, api: filterAPI, type: filterAction} = searchParams,
        showFilters = filterUser || filterAPI || filterAction;

    return  showFilters ? <div className='search-and-filter-bar'>
        <label>Filters : </label>
        {
            filterUser && <Tag closable={true} afterClose={() => resetSearchParam('user')}>
                User's name is like "{filterUser}"
            </Tag>
        }
        {
            filterAPI && <Tag closable={true} afterClose={() => resetSearchParam('api')}>
                Api name like "{filterAPI}"
            </Tag>
        }
        {
            filterAction && <Tag closable={true} afterClose={() => resetSearchParam('type')}>
                Action is "{getActivityType(filterAction)}"
            </Tag>
        }
    </div> : null;
};

export default ActivitySearchFilter;

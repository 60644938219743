import { createActionTypes } from "../../utils";

export const ReviewActions = createActionTypes("reviews", [
    "FETCH_REVIEWS_BY_SOURCE",
    "FETCH_REVIEWS_BY_SOURCE_SUCCESS",
    "FETCH_REVIEWS_BY_SOURCE_ERROR",
    "DELETE_REVIEW_BY_REVIEWID",
    "DELETE_REVIEW_BY_REVIEWID_SUCCESS",
    "DELETE_REVIEW_BY_REVIEWID_ERROR",
    "FETCH_SOURCES_FROM_DATABASE",
    "FETCH_SOURCES_FROM_DATABASE_SUCCESS",
    "FETCH_SOURCES_FROM_DATABASE_ERROR",
    "POST_REVIEW_RESPONSE",
    "POST_REVIEW_RESPONSE_SUCCESS",
    "POST_REVIEW_RESPONSE_ERROR",
]);

export const fetchReviewBySource = (obj) => {
    return {
        type: ReviewActions.FETCH_REVIEWS_BY_SOURCE,
        payload: obj
    };
};

export const fetchReviewBySourceSuccess = (obj) => {
    return {
        type: ReviewActions.FETCH_REVIEWS_BY_SOURCE_SUCCESS,
        payload: obj
    };
};

export const fetchReviewBySourceError = (obj) => {
    return {
        type: ReviewActions.FETCH_REVIEWS_BY_SOURCE_ERROR,
        payload: obj
    };
};

export const deleteReviewByReviewId = (obj) => {
    return {
        type: ReviewActions.DELETE_REVIEW_BY_REVIEWID,
        payload: obj
    };
};

export const deleteReviewByReviewIdSuccess = (obj) => {
    return {
        type: ReviewActions.DELETE_REVIEW_BY_REVIEWID_SUCCESS,
        payload: obj
    };
};

export const deleteReviewByReviewIdError = (obj) => {
    return {
        type: ReviewActions.DELETE_REVIEW_BY_REVIEWID_ERROR,
        payload: obj
    };
};

export const fetchSourcesFromDatabase = (obj) => {
    return {
        type: ReviewActions.FETCH_SOURCES_FROM_DATABASE,
        payload: obj
    };
};

export const fetchSourcesFromDatabaseSuccess = (obj) => {
    return {
        type: ReviewActions.FETCH_SOURCES_FROM_DATABASE_SUCCESS,
        payload: obj
    };
};

export const fetchSourcesFromDatabaseError = (obj) => {
    return {
        type: ReviewActions.FETCH_SOURCES_FROM_DATABASE_ERROR,
        payload: obj
    };
};

export const postReviewResponse = (obj) => {
    return {
        type: ReviewActions.POST_REVIEW_RESPONSE,
        payload: obj
    };
};

export const postReviewResponseSuccess = (obj) => {
    return {
        type: ReviewActions.POST_REVIEW_RESPONSE_SUCCESS,
        payload: obj
    };
};

export const postReviewResponseError = () => {
    return {
        type: ReviewActions.POST_REVIEW_RESPONSE_ERROR,
    };
};

export default ReviewActions;

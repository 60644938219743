import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Select, Input, Button, Spin, Modal, Switch, Divider, Row, Col } from "antd";
import lookup from "./sfdcLead.json";
import moment from "moment";
import PropTypes from "prop-types";
import axios, { beAPIResourceLeadGen } from "../../utils/apiHelper";
import { find, isArray, isEmpty, pick } from "lodash";
import { addDynamicScripts } from "../../utils";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;

const DEFAULT_BUSINESS_FIELDS = {
    businessPhone: '',
    businessAddress: '',
    zip: '',
    industry: '',
    customKeyword: '',
    countryCode: '',
    country: '',
    city: '',
    street: '',
    state: '',
    website: '',
    placeId: '',
    latitude: '',
    longitude: ''
}

const REGEX = {
    usZip: /(^\d{5}(-\d{4})?$)/,
    auZip: /(^\d{4}$)|(^\d{3}$)/,
    nzZip: /^\d{4}$/,
    ukZip: /^([A-Z]{1,2}[0-9R][0-9A-Z]? ?[0-9][ABD-HJLNP-UW-Z]{2})$/,
    caZip: /^[A-Z]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/
};

const googlePlacesFields = [
    "address_components",
    "formatted_address",
    "formatted_phone_number",
    "name",
    "place_id",
    "url",
    "vicinity",
    "types",
    "geometry",
    "website"
];

const menuItem = {
    display: "block",
    background: "#F8F8F8",
    margin: "10px",
    lineHeight: "30px",
    borderRadius: "5px"
}

const selectedItem = {
    border: "solid 1px #2196f3",
    userSelect: "none",
    cursor: "pointer",
    display: "block",
    background: "#F8F8F8",
    margin: "10px",
    lineHeight: "30px",
    borderRadius: "5px"
}
class SfdcLeadForm extends Component {

    uploadedFile = {};

    state = {
        formValues: {},
        leadOwner: [{value: 'Loading...', label: 'Loading...'}],
        leadSfdcCampaign: [{value: 'Loading...', label: 'Loading...'}],
        suggestions: [],
        isSelected: false,
        activeSuggestionIndex: 0,
        hideIndustry: false
    };
    removedIndustries = ["point_of_interest"];
    isReferralForm = false;

    componentWillMount() {
        const {campaignUrlBuilder} = this.props.pageData;
        !campaignUrlBuilder.length && this.props.reqPageDataCampaignUrlBuilder();
        this.props.reqPageDataCampaignUrlBuilder();
    }

    componentDidMount() {
        (async()=>{
            try {
                let leadOwner = [];
                let leadSfdcCampaign = [];
                const promises = [
                    beAPIResourceLeadGen.get(`leadgen/getAllSFDCUsers`),
                    beAPIResourceLeadGen.get(`leadgen/sfdcCampaign`)
                ]
                const [getAllSFDCUsersRes, sfdcCampaignRes] = await axios.all(promises);
                if (isArray(getAllSFDCUsersRes.data)) {
                    leadOwner = getAllSFDCUsersRes.data.filter(item => (item.sfdcId && item.active)).map(item => ({value: item.sfdcId, label: `${item.name} | ${item.emailId}`}));
                    leadOwner.unshift({ label: 'None', value: '005360000021APuAAM' });
                }
                if (isArray(sfdcCampaignRes.data)) {
                    leadSfdcCampaign = sfdcCampaignRes.data.filter(item => !!item.campaignId).map(item => ({value: item.campaignId, label: item.name}));
                }
                this.setState({ leadOwner, leadSfdcCampaign })

            } catch (error) {
                this.setState({ leadOwner: [], leadSfdcCampaign: [] });
            }
            
        })()
        this.addPlacesAPI();
    }

    addPlacesAPI = () => {
        addDynamicScripts(`${process.env.REACT_APP_GOOGLE_URL}?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&language=en&region=US`, "google-places-api");
    }

    getRegex = (countryCode) => {
        switch (countryCode) {
            case 'United States': return REGEX.usZip;
            case 'United Kingdom': return REGEX.ukZip;
            case 'Australia': return REGEX.auZip;
            case 'Canada': return REGEX.caZip;
            default: return REGEX.zip;
        }
    };

    maskPhoneNumber = (phone) => {
        const cleanedPhone = phone.replace(/\D/g, '');
        const match = cleanedPhone.match(/^(1|44|61)?(\d{3})(\d{3})(\d{4})$/);
        if (!match) return phone;
        
        const countryCode = (match[1] ? `+${match[1]} ` : '');
        return [countryCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll(
            ["businessName", "country", "countryCode", "zip", "industry", "businessLocations", "firstName", "lastName", "emailId", "mobilePhone", "businessPhone", "marketingChannel", "leadCampaign", "leadSubCampaign", "leadCampaignKW", "intent", "clickUrl", "leadUrl", "leadMedium", "leadContent", "leadSfdcCampaign", "description", "title", "numberOfEmployees", "leadOwner", "buyingIntent", "website", "leadSource", "leadStage", "referrerBusinessName", "referrerName", "referrerEmailId"],
            (error, values) => {
                if (!error) {
                    let payload = {...this.state.formValues, ...values};
                    let removeFields = []
                    if (this.isReferralForm) {
                        removeFields = ['leadSfdcCampaign', 'leadSubCampaign', 'clickUrl', 'leadUrl', 'leadContent'];
                        payload.leadCampaignKW = `${payload.referrerName} | ${payload.referrerBusinessName} | ${payload.referrerEmailId}`;
                        payload.buyingIntent = "Actively looking for a solution";
                        payload.leadStage = "MQL";
                    } else {
                        removeFields = ['referrerBusinessName', 'referrerName', 'referrerEmailId'];
                    }
                    for (const field of removeFields) {
                        delete payload[field];
                    }
                    this.setState({formValues: payload }, () => {
                        // To preserve form values for failure case.
                        this.props.sendSfdcLeadFormRequest({ ...payload, businessLocations: values.businessLocations === 'agency' ? '1' : values.businessLocations });
                    });
                }
            });
    };

    handleInputBlur = (name, e) => {
        e.preventDefault();
        const { value } = e.target;
        this.props.form.validateFieldsAndScroll([name], (error, values) => {
            if(!error) {
                this.props.form.setFieldsValue( {[name]: this.maskPhoneNumber(value.trim())});
            }
        })
    };

    handleModalMethodClose = (preserveForm = false) => {
        return new Promise((resolve, reject) => {
            this.props.hideSfdcModalMethod();
            if (preserveForm) {
                this.props.form.setFieldsValue(this.state.formValues);
            } else {
                const { leadSource, marketingChannel, leadMedium, leadCampaign, leadSfdcCampaign, leadSubCampaign, leadCampaignKW, clickUrl, leadUrl, leadContent, buyingIntent, leadStage } = this.state.formValues;
                const retainedFields = { leadSource, marketingChannel, leadMedium, leadCampaign, leadSfdcCampaign, leadSubCampaign, leadCampaignKW: this.isReferralForm ? '' : leadCampaignKW, clickUrl, leadUrl, leadContent: this.isReferralForm ? moment(new Date()).format("MMDDYYYY") : leadContent, buyingIntent: this.isReferralForm ? '' : buyingIntent, leadStage: this.isReferralForm ? '' : leadStage }
                this.setState({formValues: retainedFields});
                this.props.form.setFieldsValue(retainedFields);
            }
            resolve();
        });
    };

    getSuccessMessage = async () => {
        let count = 1;
        while(count <= 3){
            try {
                await new Promise(res => {setTimeout(res,5000)});
                const {data: {Id, Status, OwnerId}} = await beAPIResourceLeadGen.get(`/sfdcLead/?email=${this.state.formValues.emailId}`);
                let outcome = {};
                if (Id) outcome['leadUrl'] = `https://birdeye.lightning.force.com/lightning/r/Lead/${Id}/view`;
                if (Status) outcome['leadStatus'] = Status;
                if (OwnerId) {
                    let matchedOwner = this.state.leadOwner.filter(owner => owner.value.substring(0,15) === OwnerId.substring(0,15))[0];
                    if (matchedOwner){
                        outcome['leadOwner'] = matchedOwner.label;
                        if (matchedOwner.value !== this.state.formValues.leadOwner) outcome['leadOwner'] = 'This lead is already assigned to ' + outcome['leadOwner'];
                    }
                }
                return outcome;
            } catch (error) {
                console.error(error);
            }
            count++;
        }
        return {};
    }

    handleModalMethod = (modalObj) => {
        if (modalObj.visible) {
            let successResponse = null;
            switch (modalObj.type) {
                case "success":
                    const loadingModal = Modal.info({
                        title: "Creating lead",
                        content: 'Please wait',
                        footer: null
                    });
                    (async ()=>{
                        successResponse = await this.getSuccessMessage();
                        const {leadUrl, leadStatus, leadOwner} = successResponse;
                        loadingModal.destroy();
                        Modal.success({
                            title: "Success!!!",
                            content: (
                                <div>
                                  <p>{modalObj.message || "Action performed successfully."}</p>
                                  {leadUrl && <p> <strong>Lead URL:</strong> <a href={leadUrl} target="_blank" >{leadUrl}</a> </p>}
                                  {leadStatus && <p> <strong>Lead Status:</strong> {leadStatus} </p>}
                                  {leadOwner && <p> <strong>Lead Owner:</strong> {leadOwner} </p>}
                                </div>
                            ),
                            onOk: () => {this.handleModalMethodClose()},
                            width: 750
                        });
                    })()
                    break;
                case "error":
                    Modal.warn({
                        title: "Oops!!!",
                        content: modalObj.message || "Something went wrong.",
                        onOk: () => {this.handleModalMethodClose(true)}
                    });
                    break;
            }
        }
    };

    handleMarketingChannelChange = (selectedMarketingChannel) => {
        const
            {pageData} = this.props,
            {campaignUrlBuilder} = pageData;
        let leadCampaign = "", leadMedium = "";

        if (campaignUrlBuilder && selectedMarketingChannel) {
            const
                selectedMarketingChannelObj = campaignUrlBuilder.filter(value => (value.marketing_channel === selectedMarketingChannel));
            if (selectedMarketingChannelObj[0]) {
                leadCampaign = selectedMarketingChannelObj[0].campaign_source;
                leadMedium = selectedMarketingChannelObj[0].campaign_medium;
            }
        }

        this.isReferralForm = false;
        if (selectedMarketingChannel === 'Customer Referrals') {
            this.isReferralForm = true;
        }

        this.props.form.setFieldsValue({leadCampaign, leadMedium});
    };

    handleLeadSubCampaign = (e) => {
        const marketingChannel =  this.props.form.getFieldValue("marketingChannel");
        if (e.target.value) {
           if (marketingChannel === 'Prospect Emails') this.props.form.setFieldsValue({description: `Responded to "${e.target.value}" on ${moment().subtract('1', 'days').format("MM/DD")} with comments " ".`});
           else this.props.form.setFieldsValue({description: e.target.value})
        }
    }

    handleCountryChange = (e) => {
        const zipIndex = lookup.formFields.findIndex((item) => item.name === 'zip');
        lookup.formFields[zipIndex].rules[0].pattern = this.getRegex(e);
    }

    componentDidUpdate = () => {
        this.handleModalMethod(this.props.modalMethod);
    };

    handleCustomSelect = ({fieldName, value}) => {
        if(fieldName === 'leadSfdcCampaign') {
            this.props.form.setFieldsValue({leadSubCampaign: value.label})
        }
    }

    updateActiveSuggestionOnHover = (index) => {
        this.setState({ activeSuggestionIndex: index });
    };

    updatePlaceSuggestions = (predictions, status) => {
        const google = window.google
        let suggestions = [];
        const manualInputOption = {
            "place_id": "",
            "structured_formatting": {
                "main_text": "Can't find your business?",
                "secondary_text": "Add your business manually"
            }
        };

        if (status == google.maps.places.PlacesServiceStatus.OK) {
            suggestions = predictions.map(suggestion => pick(suggestion, ["place_id", "structured_formatting"]));
        }
        
        suggestions.push(manualInputOption);
        this.setState({ suggestions });
        document.addEventListener("keydown", this.updateActiveSuggestion);
    };

    handleAutocomplete = (value) => {
        const google = window.google;
        const sessionToken = this.state.sessionToken || new google.maps.places.AutocompleteSessionToken();
        this.autocomplete = new google.maps.places.AutocompleteService();
        this.autocomplete.getPlacePredictions({
            input: value,
            sessionToken,
            types: [ 'establishment' ],
            componentRestrictions: {
                country: ['gb','au','nz','ca','us']
            }
        }, this.updatePlaceSuggestions);
        if (!this.state.sessionToken) {
            this.setState({ sessionToken });
        }
    };

    handleBusinessSearch = (e) => {
        const value = e.target.value;
        if(value === ""){
            this.setState({street: null, city: null, state: null, zip: null, countryCode: null, industryKeywords: [], keyword: "", customKeyword: "" });
        }
        this.props.form.setFieldsValue({ businessName: value });
        this.setState({ formValues: { ...this.state.formValues, businessName: value}, isSelected: (value === "" ? true : false) });
        this.handleAutocomplete(value);
    }

    fetchBusinessInfoByPlaceID = (placeId, saveToCookie = false) => {
        const google = window.google
        try {
            const placeDetailRequest = {
                placeId,
                fields: googlePlacesFields,
                sessionToken: this.state.sessionToken || new google.maps.places.AutocompleteSessionToken()
            };
            const service = new google.maps.places.PlacesService(document.getElementById("businessInputField"));
            service.getDetails(placeDetailRequest, (place, status) => this.updatePlaceDetails(place, status, saveToCookie));
        } catch (e) {
            console.log(e,'error')
            // todo: log error
        }
    }

    updatePlaceDetails = (place, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
            const zipObj = place.address_components ? find(place.address_components, {"types": ["postal_code"]}) || {} : {};
            const zip = zipObj.short_name || "";
            const countryObj = place.address_components ? find(place.address_components, {"types": ["country", "political"]}) || {} : {};
            const countryCode = countryObj.short_name || "";
            const country = countryObj.long_name || "";
            const stateObj = place.address_components ? find(place.address_components, {"types": ["administrative_area_level_1", "political"]}) || {} : {};
            const state = stateObj.short_name || stateObj.long_name || "";
            const website = place.website || "";
            let cityObj = {};
            if (countryCode === "GB") {
                cityObj = place.address_components ? find(place.address_components, {"types": ["postal_town"]}) || {} : {};
            } else {
                cityObj = place.address_components ? find(place.address_components, {"types": ["locality", "political"]}) || {} : {};
            }
            const city = cityObj.long_name || "";
            const street = place.vicinity && place.vicinity.split(",").slice(0, -1).join(",");
            let industry = place.types.length ? place.types[0] : "Other";
            industry = industry && this.removedIndustries.includes(industry) ? "" : industry
            let businessSugObj = find(this.state.suggestions, { "place_id": place.place_id });
            if (isEmpty(businessSugObj)) {
                businessSugObj = {
                    "structured_formatting": {
                        "secondary_text": `${place["vicinity"]}, ${state}, ${countryCode}`
                    }
                };
            }
            const placeId = place.place_id || "";
            let latitude = place.geometry && place.geometry.location && place.geometry.location.lat();
            latitude = parseFloat(latitude.toFixed(7));
            let longitude = place.geometry && place.geometry.location && place.geometry.location.lng();
            longitude = parseFloat(longitude.toFixed(7));
            let businessPhone = place.formatted_phone_number || "";
            let businessAddress = businessSugObj && businessSugObj.structured_formatting.secondary_text || "";
            
            this.props.form.setFieldsValue({ businessName: place.name, country, industry, businessPhone, zip, website })
            
            this.setState({
                activeSuggestionIndex: 0,
                suggestions: [],
                sessionToken: "",
                hideIndustry: !!industry,
                formValues :{
                    ...this.state.formValues,
                    businessName: place.name,
                    businessPhone,
                    businessAddress,
                    zip,
                    industry,
                    customKeyword: "",
                    countryCode,
                    country,
                    city,
                    street,
                    state,
                    website,
                    placeId,
                    latitude,
                    longitude
                }
            });
            document.removeEventListener("keydown", this.updateActiveSuggestion);
        }
    }

    getUpdatedBusinessName = (activeSuggestion) => {
        const { suggestions, businessName } = this.state;
        let updatedBusinessName = businessName;

        if (activeSuggestion) {
            if (activeSuggestion.place_id) {
                updatedBusinessName = activeSuggestion.structured_formatting.main_text;
            } else {
                if (find(suggestions, {"structured_formatting": {"main_text": businessName}})) {
                    updatedBusinessName = "";
                }
            }
        }

        return updatedBusinessName;
    };

    updateWithAutoCompResult = (placeId) => {
        this.setState({isSelected: true})
        if (placeId) {
            this.fetchBusinessInfoByPlaceID(placeId);
        }
        else {
            this.props.form.setFieldsValue({ country: '', industry: '', businessPhone: '', zip: '', website: '' })
            this.setState({formValues: {...this.state.formValues, ...DEFAULT_BUSINESS_FIELDS}, hideIndustry: false});
            const { suggestions, activeSuggestionIndex } = this.state
            const activeSuggestion = suggestions[activeSuggestionIndex];
            this.getUpdatedBusinessName(activeSuggestion);
        }
        document.removeEventListener("keydown", this.updateActiveSuggestion);
    };

    getField = (field) => {
        const { campaignUrlBuilder } = this.props.pageData;
        const { suggestions, isSelected, activeSuggestionIndex } = this.state;
        switch(true) {
            case field.type === "textarea": return <TextArea placeholder={field.placeholder} rows={4} maxLength={1000}/>;
            case (field.type === "select" && field.name === "marketingChannel"): return (
                <Select onChange={this.handleMarketingChannelChange} showSearch={field.showSearch}>
                    {
                        campaignUrlBuilder && campaignUrlBuilder.map((marketingChannel, index) => (
                            <Option key={index} value={marketingChannel.marketing_channel}>{marketingChannel.marketing_channel}</Option>
                            ))
                        }
                    <Option value={"custom"}>Other</Option>
                </Select>
            );
            case (field.type === "select" && field.name === "country"): return (
                <Select onChange={this.handleCountryChange} placeholder={field.placeholder} showSearch={field.showSearch}>
                    {
                        lookup.options[field.name].map((option, index) => {
                            return (
                                <Option key={index} value={option.value}>{option.label}</Option>
                            );
                        })
                    }
                </Select>
            );
            case (field.type === "select" && field.customOptions): return (
                <Select 
                    showSearch={field.showSearch}
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                    this.state[field.name].map((option, index) => {
                            return (
                                <Option key={option.label + index} value={option.value} onClick={()=>{this.handleCustomSelect({fieldName: field.name, value: option})}}>{option.label}</Option>
                            );
                        })
                    }
                </Select>
            );
            case field.type === "select": return (
                <Select placeholder={field.placeholder} showSearch={field.showSearch}>
                    {
                        lookup.options[field.name].map((option, index) => {
                            return (
                                <Option key={index} value={option.value}>{option.label}</Option>
                            );
                        })
                    }
                </Select>
            );
            case field.name === "leadSubCampaign": return <Input type={field.type} placeholder={field.placeholder} onChange={this.handleLeadSubCampaign}/>;
            case field.name === "zip": return <Input type={field.type} id={'zip'}/>;
            case (field.name === "mobilePhone" || field.name === "businessPhone"): return (
                    <Input /* onBlur={(e) => this.handleInputBlur(field.name, e)} */ type={field.type} placeholder={field.placeholder}/>
            );
            case field.type === "switch": return (
                <Switch
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    style={{width: "60px"}}/>
            );
            case field.name === 'businessName': 
            return (
                <>
                    <Input
                        autoComplete="off"
                        id={'businessInputField'}
                        style={{width: "100%"}}
                        name={"businessName"}
                        onChange={this.handleBusinessSearch}
                        value={this.state.formValues.businessName}
                    />
                    {
                        !!suggestions.length && !isSelected &&
                        <ul  style={{ padding: "1px", boxShadow: "2px 2px 5px", margin: "3px", position: "absolute", zIndex: "1", width: "100%", background: "white" }}>
                            {
                                suggestions.map((suggest, index) => (
                                    <li key={suggest.place_id}
                                        style={activeSuggestionIndex === index ? selectedItem : menuItem}
                                        onClick={() => this.updateWithAutoCompResult(suggest.place_id)}
                                        onMouseEnter={() => this.updateActiveSuggestionOnHover(index)}>
                                        <div style={{lineHeight: "26px"}}>
                                            <span style={{fontSize: "14px", marginLeft: "10px", display: "inline-table"}}><b>{suggest.structured_formatting.main_text}</b></span><br/>
                                            <span style={{color: "#999999", marginLeft: "10px", display: "block", width: "397px", whiteSpace: "noWrap", overflow: "hidden", textOverflow: "ellipsis"}}>{suggest.structured_formatting.secondary_text}</span>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    }
                </>
            );
            default: return <Input type={field.type} placeholder={field.placeholder}/>;
        }
    }

    render = () => {
        const getFormFieldsList = () => {
            let formFields = [...lookup.formFields];
            const marketingChannel =  this.props.form.getFieldValue("marketingChannel");
            if (marketingChannel === 'Customer Referrals') {
                formFields.push(...lookup.referralFields);
            }
            return formFields;
        }
        const { error, userData, isLoading } = this.props;
        const { getFieldDecorator } = this.props.form;
        const formFields = getFormFieldsList();
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        if(isLoading) {
            return <Spin />;
        }
        
        const getInitialValues = (field) => {
            const marketingChannel =  this.props.form.getFieldValue("marketingChannel");
            if(field.name === "leadContent") return moment(new Date()).format("MMDDYYYY");
            if(field.name === "leadCampaignKW" && marketingChannel === 'Prospect Emails') return 'leadgen_reply';     
            if (field.name === 'leadOwner') {
                const matchedElement = this.state.leadOwner.filter(item => item.label.includes(userData.name))[0];
                if (matchedElement) return matchedElement.value;
            }       
            return field.defaultValue || '';
        }

        const getClassName = ({fieldName, hidden}) => {
            if (hidden) return 'hide';
            if (fieldName ==='industry' && this.state.hideIndustry) return 'hide';
            const { campaignUrlBuilder } = this.props.pageData;
            const marketingChannel =  this.props.form.getFieldValue("marketingChannel");
            let matchedChannel = {};
            if (campaignUrlBuilder.length) {
                const selectedChannel = campaignUrlBuilder.filter(value => (value.marketing_channel === marketingChannel));
                if (selectedChannel.length) matchedChannel = selectedChannel[0]
            }
            if (fieldName ==='leadMedium' && marketingChannel !== "custom" && (!matchedChannel['marketing_channel'] || matchedChannel['campaign_medium'])){
                return 'hide'
            } 
            if (fieldName ==='leadCampaign' && marketingChannel !== "custom" && (!matchedChannel['marketing_channel'] || matchedChannel['campaign_source'])){
                return 'hide'
            }
            if (marketingChannel === 'Customer Referrals' && ['leadSfdcCampaign', 'leadCampaignKW', 'clickUrl', 'leadUrl', 'leadContent', 'buyingIntent', 'leadStage'].includes(fieldName)) {
                return 'hide'
            }
            return '';
        }

        const getRules = (field) => {
            if (field.name === 'leadSfdcCampaign') {
                const marketingChannel =  this.props.form.getFieldValue("marketingChannel");
                if (marketingChannel && (marketingChannel.includes('Paid Field Marketing') || ['Paid Business Development', 'Business Development', 'Customer Marketing'].includes(marketingChannel))) {
                    return [{
                        "required": true,
                        "message": "Please provide Lead SFDC Campaign"
                      }];
                }
            }
            return field.rules;
        }

        return (
            <div>
                <h2>Lead Gen Form</h2>
                <Form style={{"display": "flex" , "flexWrap": "wrap"}} onSubmit={this.handleSubmit}>
                    <Row style={{"width": "100%"}}>
                        {
                            formFields.map((field) => {
                                if (field.dividerText) return <Divider key={field.dividerText}>{field.dividerText}</Divider>
                                return (
                                    <Col sm={24} md={12} className={getClassName({fieldName: field.name, hidden: field.hidden})}>
                                        <FormItem style={{"padding": " 0 20px 0 20px"}} label={field.label} key={field.label}>
                                        {
                                            getFieldDecorator( field.name, {
                                                initialValue: getInitialValues(field),
                                                rules: getRules(field)
                                            })(
                                                this.getField(field)
                                            )
                                        }
                                        </FormItem>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <FormItem style={{"width": "100%", "display": "flex", "justifyContent": "center", "padding": "20px"}}>
                        <Button type="primary" htmlType="submit">
                            Create SFDC Lead
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

SfdcLeadForm.propTypes = {
    modalMethod: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    sendSfdcLeadFormRequest: PropTypes.func.isRequired,
    sendSfdcLeadFileRequest: PropTypes.func.isRequired,
    hideSfdcModalMethod: PropTypes.func.isRequired
};
const SfdcLead = Form.create()(SfdcLeadForm);
export default SfdcLead;
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, Modal, Select, Switch } from "antd";
import { RedirectWrapper, PageHeaderContainer } from "../../components";
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { beAPIResource } from "../../utils/apiHelper";
const FormItem = Form.Item;
const InputGroup = Input.Group;

const DOMAINS = [
    { label: 'birdeye.com', value: '' },
    { label: 'getbirdeye.com.au', value: '/au' },
];

const formItemLayout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 3 }
    },
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 20 }
    }
};
const formButtonLayout = {
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 20, offset: 3 }
    }
};
class UpdateWebsiteContent extends Component {

    pageKey="websiteUpdatePage";
    jsonEditorRef = React.createRef();  
    state = {
        country: '',
        formData: {
            id: "",
            slug: "",
            data: null,
            pageType: "",
            activatedCampaigns: "",
            notes: "",
            hasVariations: false,
            variationCount: 1,
            variationData: null
        },
        slugToUpdate: "",
        errorValues: {
            errorSlug: false,
            errorData: false,
            genError: false
        }
    };
    
    async componentDidMount() {
        if( this.props.location.pathname.includes('update') ) {
            const response = await beAPIResource.get(`support/website/content/${this.props.match.params.pageId}`);
            let currentActiveContent = response.data;
            let country = '';
            if (currentActiveContent.slug.includes('/au/')) {
                currentActiveContent.slug = currentActiveContent.slug.replace('/au', '');
                country = '/au'
            }
            this.setState({ slugToUpdate: currentActiveContent.slug || "", country }, () => {
                this.updateslugToUpdate(currentActiveContent);
            });

            // When the component mounts, expand all nodes
            if (this.jsonEditorRef.current) {
                const jsonEditor = this.jsonEditorRef.current.jsonEditor;
                jsonEditor.expandAll();
            }
        }
    }

    updateslugToUpdate = (currentActiveContent) => {
        const hasVariations = !!(currentActiveContent.data && currentActiveContent.data['Control']);
        this.setState({
            formData: {
                ...this.state.formData,
                slug: this.state.slugToUpdate,
                data: !hasVariations ? currentActiveContent.data : {},
                pageType: currentActiveContent.pageType || "",
                activatedCampaigns: currentActiveContent.activated_campaigns ? JSON.parse(currentActiveContent.activated_campaigns).join(", ") : "",
                notes: currentActiveContent.notes || "",
                id: currentActiveContent.id || "",
                hasVariations,
                variationCount: hasVariations ? (Object.keys(currentActiveContent.data).length - 1) : 1,
                variationData: hasVariations ? currentActiveContent.data : {},
                includeInSitemap: currentActiveContent.includeInSitemap || false
            }
        });
    }
    
    handleSubmit = async (e) => {
        e.preventDefault();
        const { country } = this.state;
        const { slug, data, pageType, id, hasVariations, variationData, includeInSitemap, activatedCampaigns, notes } = this.state.formData;
        const goToContentPage = () => {
            this.props.history.push('/website-content')
        }
        const goToUpdatePage = (id) => {
            this.props.history.push(`/website-content/update/${id}`)
        }
        if( slug && ((!hasVariations && data) || (hasVariations && variationData))) {
            const pageData = hasVariations ? variationData : data;
            if(this.state.slugToUpdate) {
                const payload = {
                    id,
                    slug: slug.startsWith('/') ? country + slug : country + `/${slug}`,
                    data: (JSON.stringify(pageData)),
                    last_updated_by: this.props.userData.name,
                    country: country ? "au" : "us",
                    pageType,
                    includeInSitemap: includeInSitemap || false,
                    notes: notes || null,
                    activated_campaigns: activatedCampaigns ? JSON.stringify(activatedCampaigns.split(/\s*,\s*/).map(campaign => campaign.trim())) : null
                };
                try {
                    await beAPIResource.put(`support/website/content/update`, payload);
                    Modal.success({
                        title: "Your content has been updated!",
                        okText: "Go to content page",
                        maskClosable: true,
                        onOk() {
                            goToContentPage()
                        }
                    });
                } catch (error) {
                    Modal.error({
                        title: "Error",
                        okText: "Return",
                        content: "Please verify the data and try again..."
                    });
                }
            }
            else {
                const payload = {
                    slug: slug.startsWith('/') ? country + slug : country + `/${slug}`,
                    data: (JSON.stringify(pageData)),
                    last_updated_by: this.props.userData.name,
                    country: country ? "au" : "us",
                    pageType,
                    created_by: this.props.userData.name,
                    includeInSitemap: includeInSitemap || false,
                    notes: notes || null,
                    activated_campaigns: activatedCampaigns ? JSON.stringify(activatedCampaigns.split(/\s*,\s*/).map(campaign => campaign.trim())) : null
                };
                try {
                    await beAPIResource.post(`support/website/content/add`, payload, {  withCredentials: false });
                    Modal.success({
                        title: "Your content has been added!",
                        okText: "Go to content page",
                        maskClosable: true,
                        onOk() {
                            goToContentPage()
                        }
                    });
                } catch (error) {
                    if (error.status === 400){
                        Modal.error({
                            title: "Entry for this slug already exist!",
                            okText: "Update data for this slug",
                            maskClosable: true,
                            onOk() {
                                goToUpdatePage(error.data.data.id)
                            }
                        });
                    } else {
                        Modal.error({
                            title: "Error",
                            okText: "Return",
                            content: "Please verify the data and try again..."
                        });
                    }
                }
            }
            if(!slug) this.setState({errorSlug: false});
            else if(!(data)) this.setState({errorData: false});
            else this.setState({genError: false});
        }
        else {
            if(!slug) this.setState({errorSlug: true});
            else if(!(data)) this.setState({errorData: true});
            else this.setState({genError: true});
        }
    }

    handleInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (name === 'variationCount') {
            let localVariations = JSON.parse(JSON.stringify(this.state.formData.variationData));
            if (localVariations[`Variation-${parseInt(value)+1}`]) delete localVariations[`Variation-${parseInt(value)+1}`];
            this.setState({
                formData: {
                    ...this.state.formData,
                    variationData: localVariations,
                    [name]: value
                }
            });
            return;
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        });
        
    }

    handleJsonChange = (value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                data: value
            }
        });        
    }

    onSwitchChange = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        });
    }

    handleVariationJsonChange = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                variationData: {
                    ...this.state.formData.variationData,
                    [name]: value
                }
            }
        });        
    }

    getVariationEditors = (isUpdatePage) => {
        const {variationData} = this.state.formData;
        let editors = []
        for (let i = 0; i <= this.state.formData.variationCount; i++) {
            const label = i === 0? "Control": `Variation-${i}`;
            editors.push(
                <FormItem {...formItemLayout} label={label} required={true} key={i}>
                    <Editor
                        name={label}
                        value={variationData && variationData[label] ? variationData[label] : {}}
                        onChange={(value)=>{this.handleVariationJsonChange(label,value)}}
                        allowedModes={['text', 'tree']}
                        mode={ isUpdatePage ? 'tree' : "text"}
                    />
                    {
                        this.state.errorData && <p style={{color: "red", fontSize: "10px"}}>This field is required</p>
                    }
                </FormItem>
            )
        }
        return editors;
    }
    
    render() {
        const { error, userData } = this.props;
        const { formData, country } = this.state;
        const { slug, data, pageType, hasVariations, variationCount, includeInSitemap, activatedCampaigns, notes } = formData;
        const unauthorized = error === "unauthorized" || !userData.name;
        const isUpdatePage = this.props.location.pathname.includes("update") ? true : false;
        const allowEdit = userData.privileges.includes('websiteContent+');
        
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }
        
        return (
            <div className="resources-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label={"Path"} required={true} extra={<p>For example: "/messaging"</p>}>
                        <InputGroup compact style={{display: 'flex'}}>
                            <Select onChange={(e) =>  this.setState({ country: e } )} value={country} disabled={!!this.state.slugToUpdate} style={{minWidth: '160px'}}>
                                {
                                    DOMAINS.map((r) => <Select.Option key={r.value} value={r.value}>{r.label}</Select.Option>)
                                }
                            </Select>
                            <Input
                                name="slug"
                                value={slug}
                                placeholder="Slug"
                                onChange={this.handleInputChange}
                                disabled={!!this.state.slugToUpdate}
                            />
                            {
                                this.state.errorSlug && <p style={{color: "red", fontSize: "10px"}}>This field is required</p>
                            }
                        </InputGroup>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Page Type" >
                        <Input
                            name="pageType"
                            value={pageType}
                            placeholder="Page Type"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="CRO Campaign(s)" extra={<p>Check this <a href="https://docs.google.com/document/d/1JYeRtmm2hGD28NdRZFMv4jEgzemE34EZ_6I5s7wJ688/edit?usp=sharing">guide</a> for CRO activation.</p>}>
                        <Input.TextArea
                            name="activatedCampaigns"
                            value={activatedCampaigns}
                            placeholder="CRO Campaign(s)"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Has variations"}>
                        <Switch
                            checked={hasVariations}
                            checkedChildren={"Yes"}
                            unCheckedChildren={"No"}
                            style={{width: "60px"}}
                            onChange={(e) => this.onSwitchChange('hasVariations', e)}/>
                    </FormItem>
                    {
                        hasVariations && (
                            <FormItem {...formItemLayout} label="Variation count" extra={<p>Number of variations, excluding 'Control'</p>}>
                                <Input
                                    name="variationCount"
                                    value={variationCount}
                                    onChange={this.handleInputChange}
                                    type="number"
                                />
                            </FormItem>
                        )
                    }
                    { hasVariations && this.getVariationEditors(isUpdatePage) }
                    { 
                        !hasVariations && <React.Fragment key={data}>
                            <FormItem {...formItemLayout} label="Data" required={true}>
                                <Editor
                                    ref={this.jsonEditorRef}
                                    value={data || {}}
                                    onChange={this.handleJsonChange}
                                    allowedModes={['text', 'tree']}
                                    mode={ isUpdatePage ? 'tree' : "text"}
                                />
                                {
                                    this.state.errorData && <p style={{color: "red", fontSize: "10px"}}>This field is required</p>
                                }
                            </FormItem>
                        </React.Fragment>
                    }
                    <FormItem {...formItemLayout} label={"Include In Sitemap"}>
                        <Switch
                            checked={includeInSitemap}
                            checkedChildren={"Yes"}
                            unCheckedChildren={"No"}
                            style={{width: "60px"}}
                            onChange={(e) => this.onSwitchChange('includeInSitemap', e)}/>
                    </FormItem>
                    <FormItem {...formItemLayout} label="General notes" extra={<p>Record something to remember about this page.</p>}>
                        <Input.TextArea
                            name="notes"
                            value={notes}
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    {
                        this.state.genError && <p style={{color: "red", fontSize: "10px"}}>Enter all required values</p>
                    }
                    {allowEdit && <>
                        <FormItem {...formButtonLayout}>
                            <Button type="primary" htmlType="submit" block>
                                Save
                            </Button>
                        </FormItem>
                        <FormItem {...formButtonLayout}>
                            <Button type="primary" onClick={() => this.props.history.push(`/website-content/update/${this.props.match.params.pageId}`)} block>
                                Edit Components
                            </Button>
                        </FormItem>
                    </>}
                </Form>
            </div>
        );
    }
}

UpdateWebsiteContent.propTypes = {
    userData: PropTypes.object
};

export default UpdateWebsiteContent;
import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import FeaturesActions, * as actionCreator from "./actions";
import featureApi from "./http";

const featuresSagas = [
    takeLatest(FeaturesActions.FETCH_FEATURES_LIST_REQUEST, fetchFeaturesList),
    takeLatest(FeaturesActions.FETCH_FEATURE_REQUEST, fetchFeature),
    takeLatest(FeaturesActions.ADD_FEATURE_REQUEST, addFeature),
    takeLatest(FeaturesActions.UPDATE_FEATURE_REQUEST, updateFeature),
    takeLatest(FeaturesActions.FETCH_BUSINESS_TYPE_REQUEST, fetchBusinessType),
    takeLatest(FeaturesActions.FETCH_APPLICABLE_MODULE_REQUEST, fetchApplicableModule),
    takeLatest(FeaturesActions.FETCH_RELEASE_TYPE_REQUEST, fetchReleaseTypes),
    takeEvery(FeaturesActions.DELETE_FEATURE_REQUEST, deleteFeature),
    takeLatest(FeaturesActions.NEWSLETTER_LISTING, fetchNewsletterList)
];

function* fetchFeaturesList() {
    try {
        const resp = yield call(featureApi.getAll);
        yield put(actionCreator.fetchFeaturesListSuccess(resp.data.featureReleaseNoteResponseList));
    } catch (e) {
        yield put(
            actionCreator.featureError(e.data.data || { error: "featuresListNotFound" })
        );
    }
}

function* fetchFeature(action) {
    try {
        const resp = yield call(featureApi.getById, action.payload.id);
        yield put(actionCreator.fetchFeatureSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.featureError(e.data.data || { error: "featureNotFound" }));
    }
}

function* addFeature(action) {
    try {
        const resp = yield call(featureApi.add, action.payload.data);
        yield put(actionCreator.addFeatureSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.featureError(e.data.data || { error: "cannotAdd" }));
        yield call(action.payload.cb, e.data.data.message || "Unable to add feature. Please check your input or try again later!");
    }
}

function* updateFeature(action) {
    try {
        const resp = yield call(featureApi.update, action.payload.data);
        yield put(actionCreator.updateFeatureSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.featureError(e.data.data || { error: "cannotUpdate" }));
        yield call(action.payload.cb, e.data.data.message || "Unable to update feature. Please check your input or try again later!");
    }
}

function* deleteFeature(action) {
    const { releaseId } = action.payload;
    try {
        yield call(featureApi.delete, releaseId);
        yield put(actionCreator.deleteFeatureSuccess({ releaseId }));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.featureError(e.data.data || { error: "cannotDelete" }));
        yield call(action.payload.cb, "cannotDelete");
    }
}

function* fetchBusinessType(action) {
    try {
        const resp = yield call(featureApi.getAllBusinessType, action.data);
        yield put(actionCreator.fetchBusinessTypeSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.featureError(e.data.data || { error: "BusinessTypeNotFound" })
        );
    }
}

function* fetchApplicableModule(action) {
    try {
        const resp = yield call(featureApi.getAllApplicableModule, action.data);
        yield put(actionCreator.fetchApplicableModuleSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.featureError(e.data.data || { error: "ApplicableModuletNotFound" })
        );
    }
}

function* fetchReleaseTypes(action) {
    try {
        const resp = yield call(featureApi.getAllReleaseTypes, action.data);
        yield put(actionCreator.fetchReleaseTypesSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.featureError(e.data.data || { error: "ReleaseTypesNotFound" })
        );
    }
}

function* fetchNewsletterList(action) {
    try {
        const resp = yield call(featureApi.getNewsletter);
        yield put(actionCreator.fetchNewsletterListingSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.fetchNewsletterListingError(e.data.data || { error: "NewsletterNotFound" })
        );
    }
}

export default featuresSagas;

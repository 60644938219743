import { createActionTypes } from "../../utils";

export const FreeProdOverviewActions = createActionTypes("freeProdDashboard", [
    "REQ_OVERVIEW_PAGEDATA",
    "SUCCESS_OVERVIEW_PAGEDATA",
    "ERROR_OVERVIEW_PAGEDATA",
    "REQ_DETAIL_CATEGORIES",
    "SUCCESS_DETAIL_CATEGORIES",
    "ERROR_DETAIL_CATEGORIES",
    "REQ_DETAIL_TABLE_CONTENT",
    "SUCCESS_DETAIL_TABLE_CONTENT",
    "ERROR_DETAIL_TABLE_CONTENT",
    "REQ_SUMMARY_PAGEDATA",
    "SUCCESS_SUMMARY_PAGEDATA",
    "ERROR_SUMMARY_PAGEDATA",
    "REQ_EMAILS_PAGEDATA",
    "SUCCESS_EMAILS_PAGEDATA",
    "ERROR_EMAILS_PAGEDATA",
]);

export const reqOverviewPageData = () => ({
    type: FreeProdOverviewActions.REQ_OVERVIEW_PAGEDATA
});

export const successOverviewPageData = data => ({
    type: FreeProdOverviewActions.SUCCESS_OVERVIEW_PAGEDATA,
    data
});

export const errorOverviewPageData = data => ({
    type: FreeProdOverviewActions.ERROR_OVERVIEW_PAGEDATA,
    data
});

export const reqDetailCategories = () => ({
    type: FreeProdOverviewActions.REQ_DETAIL_CATEGORIES
});

export const successDetailCategories = data => ({
    type: FreeProdOverviewActions.SUCCESS_DETAIL_CATEGORIES,
    data
});

export const errorDetailCategories = data => ({
    type: FreeProdOverviewActions.ERROR_DETAIL_CATEGORIES,
    data
});

export const reqDetailTableContent = data => ({
    type: FreeProdOverviewActions.REQ_DETAIL_TABLE_CONTENT,
    data
});

export const successDetailTableContent = data => ({
    type: FreeProdOverviewActions.SUCCESS_DETAIL_TABLE_CONTENT,
    data
});

export const errorDetailTableContent = data => ({
    type: FreeProdOverviewActions.ERROR_DETAIL_TABLE_CONTENT,
    data
});

export const reqSummaryPageData = () => ({
    type: FreeProdOverviewActions.REQ_SUMMARY_PAGEDATA
});

export const successSummaryPageData = data => ({
    type: FreeProdOverviewActions.SUCCESS_SUMMARY_PAGEDATA,
    data
});

export const errorSummaryPageData = data => ({
    type: FreeProdOverviewActions.ERROR_SUMMARY_PAGEDATA,
    data
});

export const reqEmailsPageData = () => ({
    type: FreeProdOverviewActions.REQ_EMAILS_PAGEDATA
});

export const successEmailsPageData = data => ({
    type: FreeProdOverviewActions.SUCCESS_EMAILS_PAGEDATA,
    data
});

export const errorEmailsPageData = data => ({
    type: FreeProdOverviewActions.ERROR_EMAILS_PAGEDATA,
    data
});

export default FreeProdOverviewActions;

import { findIndex } from "lodash";
import { SalesRepActions } from "./actions";

export let SalesRep = {
    userData: {},
    curSalesData: {},
    salesRepListData: [],
    userError: "",
    loadingRep: false,
    salesRepInvitation: [],
    isSalesRepInvitationLoading: true,
    salesRepTimeZones: []
};

const salesRepReducer = (state = SalesRep, action) => {
    let index;
    switch (action.type) {
        case SalesRepActions.FETCH_SALES_REPS_LIST_REQUEST:
        case SalesRepActions.FETCH_SALES_REP_REQUEST:
        case SalesRepActions.ADD_SALES_REP_REQUEST:
        case SalesRepActions.UPDATE_SALES_REP_REQUEST:
        case SalesRepActions.DELETE_SALES_REP_REQUEST:
            return {
                ...state,
                userError: "",
                loadingRep: true
            };
        case SalesRepActions.SALES_REP_REQUEST_ERROR:
            return {
                ...state,
                userError: action.payload.error,
                loadingRep: false
            };
        case SalesRepActions.FETCH_SALES_REPS_LIST_SUCCESS:
            return {
                ...state,
                salesRepListData: action.payload,
                loadingRep: false
            };
        case SalesRepActions.FETCH_SALES_REP_SUCCESS:
            return {
                ...state,
                curSalesData: action.payload,
                loadingRep: false
            };
        case SalesRepActions.ADD_SALES_REP_SUCCESS:
            return {
                ...state,
                salesRepListData: [...state.salesRepListData, action.payload],
                loadingRep: false
            };
        case SalesRepActions.UPDATE_SALES_REP_SUCCESS:
            index = findIndex(state.salesRepListData, ["id", action.payload.id]);
            return {
                ...state,
                salesRepListData: [
                    ...state.salesRepListData.slice(0, index),
                    action.payload.data,
                    ...state.salesRepListData.slice(index + 1)
                ],
                loadingRep: false
            };
        case SalesRepActions.DELETE_SALES_REP_SUCCESS:
            index = findIndex(state.salesRepListData, ["id", action.payload]);
            return {
                ...state,
                salesRepListData: [
                    ...state.salesRepListData.slice(0, index),
                    ...state.salesRepListData.slice(index + 1)
                ],
                isSalesRepInvitationLoading: true
            };
        case SalesRepActions.FETCH_ALL_SALES_REP_INVITATION_REQUEST:
            return {
                ...state,
                isSalesRepInvitationLoading: true
            };
        case SalesRepActions.FETCH_ALL_SALES_REP_INVITATION_SUCCESS:
            return {
                ...state,
                salesRepInvitation: action.payload,
                isSalesRepInvitationLoading: false
            };
        case SalesRepActions.FETCH_ALL_SALES_REP_INVITATION_ERROR:
            return {
                ...state,
                salesRepInvitation: [],
                isSalesRepInvitationLoading: false
            };
        case SalesRepActions.FETCH_SALES_REPS_TIME_ZONES_REQUEST:
            return {
              ...state,
              salesRepTimeZones: []
            };
        case SalesRepActions.FETCH_SALES_REPS_TIME_ZONES_SUCCESS:
            return {
                ...state,
                salesRepTimeZones: action.payload
            };
        case SalesRepActions.FETCH_SALES_REPS_TIME_ZONES_ERROR:
            return {
                ...state,
                salesRepTimeZones: []
            };
        default:
            return state;
    }
};

export default salesRepReducer;

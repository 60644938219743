import { beAPIResource } from "../../utils/apiHelper";

const authUrl = process.env.REACT_APP_AUTH_BASE_URL;

const userApi = {
    login: (id) => beAPIResource.get(`users/${id}`),
    logout: () => beAPIResource.get(`${authUrl}logout`),
    getAll: () => beAPIResource.get("users"),
    getById: (id) => beAPIResource.get(`users/${id}`),
    add: (data) => beAPIResource.post("users", data),
    update: (data) => beAPIResource.put(`users/${data.id}`, data),
    getAllRoles: () => beAPIResource.get("support/user-roles"),
    checkSession: (id) => beAPIResource.get(`users/session-exists?userId=${id}`),
    getAllPermissions: () => beAPIResource.get("/support/permissions"),
    getPermissionById: (id) => beAPIResource.get(`/support/permissions/${id}`),
    addPermission: (data) => beAPIResource.post(`/support/permissions/`, data),
    updatePermission: (data) => beAPIResource.post(`/support/permissions/${data.id}`, data),
    createRole: data => beAPIResource.post("/support/user-roles", data),
    getRoleById: id => beAPIResource.get(`/support/user-roles/${id}`),
    updateRole: data => beAPIResource.put(`/support/user-roles/${data.id}`, data),
    deleteUser: id => beAPIResource.delete(`users/${id}`),
    deleteUserRole: id => beAPIResource.delete(`support/user-roles/${id}`),
    deletePermission: id => beAPIResource.delete(`support/permissions/${id}`),
    birdeyLogin: (data) => beAPIResource.post(`${authUrl}birdeye`, data),
};

export default userApi;
import { ReviewActions } from "./actions";

let Reviews = {
    bySource: [],
    error: "",
    isLoading: false,
    isDeleted: false,
    fetchReviewSuccess: false,
    reviewResponse: [],
    sourceData: [],
    reviewDeleteResponse: '',
    postReviewApiResponse: []
};

const reviewReducer = (state = Reviews, action) => {
  switch (action.type) {
    case  ReviewActions.FETCH_REVIEWS_BY_SOURCE:
            return {
                ...state,
                isLoading: true,
            };
    case ReviewActions.FETCH_REVIEWS_BY_SOURCE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                fetchReviewSuccess: true,
                reviewResponse: action.payload
            };
    case ReviewActions.FETCH_REVIEWS_BY_SOURCE_ERROR:
            return {
                ...state,
                isLoading: false,
                fetchReviewSuccess: false,
            };
    case  ReviewActions.DELETE_REVIEW_BY_REVIEWID:
            return {
                ...state,
                isLoading: true,
            };
    case  ReviewActions.DELETE_REVIEW_BY_REVIEWID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDeleted: true,
                reviewDeleteResponse: action.payload,
            };
    case  ReviewActions.DELETE_REVIEW_BY_REVIEWID_ERROR:
            return {
                ...state,
                isLoading: false,
                isDeleted: false,
                reviewDeleteResponse: action.payload,
            };
    case ReviewActions.FETCH_SOURCES_FROM_DATABASE:
          return {
                ...state,
                isLoading: true,
            };
    case ReviewActions.FETCH_SOURCES_FROM_DATABASE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                sourceData: action.payload
            };
    case ReviewActions.FETCH_SOURCES_FROM_DATABASE_ERROR:
            return {
                ...state,
                isLoading: false,
            };
    case ReviewActions.POST_REVIEW_RESPONSE:
            return {
                ...state,
                isLoading: true,
            };
    case ReviewActions.POST_REVIEW_RESPONSE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                postReviewApiResponse: action.payload
            };
    case ReviewActions.POST_REVIEW_RESPONSE_ERROR:
            return {
                ...state,
                isLoading: false,
          };
    default:
        return state;
  }
};
export default reviewReducer;

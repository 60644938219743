import React, { Component } from "react";
import { isEmpty, forEach } from "lodash";
import { Form, Input, Select, Button, Spin, Modal, Icon, Popconfirm } from "antd";
import TableSkeleton from "../../components/TableSkeleton";

import { RedirectWrapper } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";

const Option = Select.Option;
const FormItem = Form.Item;
const { TextArea } = Input;

class GetReviewsForm extends Component {
    state = {
        data: {
            businessNumber: null,
            source: []
        },
        formError: "",
        reviewResponse: this.props.reviewResponse,
        reviewDeleteResponse: [],
        isDeleted: false,
        fetchReviewSuccess: false,
        sourceData: [],
        currentReviewId: "",
        visible: false,
        comments: "",
        commentReviewId: "",
        commentBusinessId: "",
        commentUserEmailId: "sysadmin@birdeye.com"
    };

    componentDidMount() {
      this.fetchData(this.state);
    }

    fetchData = (obj) => {
      this.props.fetchSourcesFromDatabase(obj);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { businessNumber, source } = this.state.data;
        if ( isEmpty(businessNumber) || isEmpty(source) ) {
            this.setState({formError: "Fill in all the relevant fields"});
            return;
        };

        if(!isEmpty(source)){
          const sources = this.props.sourceData;
          const selectedSources = [];

          sources.filter( function (allSources) {
              forEach(source, function(eachSelectedSource){
                  if(eachSelectedSource === allSources.name) {
                      selectedSources.push(allSources.id);
                  }
              })
          });
          this.props.fetchReviewBySource({data: {...this.state.data, sources: selectedSources}});
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      return {
        reviewResponse: nextProps.reviewResponse
      }
    }

    handleDelete = (currentRecord) => {
        const businessNo = this.state.data.businessNumber;
        const currentReviewId = currentRecord.id;
        this.setState({
            currentReviewId
        }, () => {
            this.props.deleteReviewByReviewId({data: {businessNumber: businessNo, reviewId: currentReviewId}, cb: this.renderFeedback});
        });
    }

    handleReviewsLists = () => {
        const { source } = this.state.data;
        const sourcesLists = this.props.sourceData || [];
        const finalSources = [];
        sourcesLists.filter( function (allSources) {
            forEach(source, function(eachSelectedSource){
                if(eachSelectedSource === allSources.name) {
                    finalSources.push(allSources.id);
                }
            })
        });
        this.props.fetchReviewBySource({data: {...this.state.data, sources: finalSources}});
    }

    renderFeedback = () => {
      if(this.props.isDeleted === true){
        window.open(`${process.env.REACT_APP_FREE_WEBSITE_URL}a-${this.state.data.businessNumber}?update=1`, "_blank");
        this.handleReviewsLists();
        return Modal.success({
            title: `The record is deleted successfully`,
            maskClosable: true,
            okText: "Close"
        });
      }
      else if(this.props.isDeleted === false){
        const msg = "Invalid review id";
        if(this.props.reviewDeleteResponse.message === msg){
          const message = "This review is already deleted, kindly wait for few minutes to reflect changes.";
          return Modal.error({
              title: `Something went wrong.`,
              content: message,
          });
        }
      }
    };

    formatData = (response) => {
      const result = response.reviewsWithAvgRating &&  response.reviewsWithAvgRating.reviewMessages || [];
      return result && result.map((record) => {
        const reviewerFn = record.reviewer.firstName === null ? '' : record.reviewer.firstName;
        const reviewerLn = record.reviewer.lastName === null ? '' : record.reviewer.lastName;
        const emailId = record.reviewer.emailId === null ? '' : record.reviewer.emailId ;
        const beSource = (record.sourceType === "Our Website" || record.sourceType == '"Our Website"' ) ? record.sourceType = "Birdeye" : record.sourceType;
        const customAction = record.sourceType === "Birdeye" ? (
            <div>
                <Icon style={{fontSize: "18px", margin: "3px"}} type="message" theme="twoTone" onClick={(e) => {e.preventDefault(); this.showModal(record)}}/>
                <Popconfirm title={"Are you sure to delete this record?"} onConfirm={() => {this.handleDelete(record)}} okText={"Yes"} cancelText={"No"} placement={"left"} icon={<Icon type="delete" style={{color: "red"}}/>}>
                    <a className={"action"} title={"Click to delete"}><Icon style={{fontSize: "18px", margin: "3px"}} type="delete" theme={"twoTone"}/></a>
                </Popconfirm>
            </div>
        ) : (
            <div>
                <Popconfirm title={"Are you sure to delete this record?"} onConfirm={() => {this.handleDelete(record)}} okText={"Yes"} cancelText={"No"} placement={"left"} icon={<Icon type="delete" style={{color: "red"}}/>}>
                    <a className={"action"} title={"Click to delete"}><Icon style={{fontSize: "20px"}} type="delete" theme={"twoTone"}/></a>
                </Popconfirm>
            </div>
        );
          return {
              id: record.id,
              emailId: emailId,
              reviewId: record.id,
              rating: record.overallRating,
              reviewer: reviewerFn + ' ' + reviewerLn,
              review: record.comments,
              source: beSource,
              date: record.reviewDate,
              action: customAction,
              key: record.id,
              response: record.response
          };
      });
    };

    showModal = (record) => {
        this.setState({
            visible: true,
            commentReviewId: record.id,
            commentBusinessId: record.businessId,
        });
    };

    renderExpandedRow = (record) => (
        <div className="mb1">
            <b>Response: </b>
            <span>{record.response}</span>
        </div>
    );

    handleCommentSubmit = (e) => {
        e.preventDefault();
        const { commentReviewId, commentBusinessId, commentUserEmailId, comments } = this.state;
        this.setState({
            visible: false,
        });
        this.props.postReviewResponse({ data: { userEmailId: commentUserEmailId, response: comments, businessId: commentBusinessId, reviewId: commentReviewId}, cb: this.renderResponseFeedback });
    };

    renderResponseFeedback = () => {
        const cbResponse = this.props.postReviewApiResponse;
        const statusCode = cbResponse.status;
        if( statusCode === 200 || statusCode === 202 ) {
            this.handleReviewsLists();
            return Modal.success({
                title: `Success`,
                content: `Comment successfully posted`
            });
        }
        else {
            return Modal.error({
                title: `Error`,
                content: `Comment not posted`
            });
        }
    }

    onCommentChange = e => {
        this.setState({comments: e.target.value});
    };

    onCommentCancel = () => {
        this.setState({
            visible: false,
        });
        return;
    };

    handleInputChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            this.handleFieldChange(name, value);
        }
    }

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        this.setState({data, formError: ""});
    }

    render() {

        const { businessNumber, source } = this.state.data;
        const { reviewResponse } = this.state;

        const { visible } = this.state;
        const { response, error, userData, sourceData, isLoading } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        const tableColumns = [
              {
                  title: 'Reviewer',
                  dataIndex: 'reviewer',
                  key: 'reviewer',
                  width: '10%',
                  searchable: true
              },
              {
                  title: 'Email',
                  dataIndex: 'emailId',
                  key: 'emailId',
                  width: '15%',
                  searchable: true
              },
              {
                  title: 'Rating',
                  dataIndex: 'rating',
                  key: 'rating',
                  width: '5%',
                  sortAs: 'number'
              },
              {
                  title: 'Review',
                  dataIndex: 'review',
                  key: 'review',
                  width: '40%',
                  searchable: true
              },
              {
                  title: 'Source',
                  dataIndex: 'source',
                  key: 'source',
                  width: '10%',
              },
              {
                  title: 'Date',
                  dataIndex: 'date',
                  key: 'date',
                  width: '10%',
                  sortAs: "date",
                  defaultSortOrder: 'descend'
              },
              {
                 title: 'Action',
                 key: 'action',
                 width: '8%',
                 align: 'center'
              }
          ];

          if (unauthorized) {
              return <RedirectWrapper location={this.props.location} />;
          }

          if(isLoading) {
              return <Spin />;
          }

          if(visible) {
              return (
                  <div>
                      <Modal
                          title="Comments"
                          visible={visible}
                          footer={null}
                          closable={false}
                      >
                          <Form>
                              <FormItem>
                                  <TextArea rows={4} onChange={this.onCommentChange}/>
                              </FormItem>
                              <FormItem>
                                  <Button type="secondary" onClick={this.onCommentCancel} style={{margin: '7px'}}>Cancel</Button>
                                  <Button type="primary" onClick={this.handleCommentSubmit} >Comment</Button>
                              </FormItem>
                          </Form>
                      </Modal>
                  </div>
              );
          }

        return (
            <div>
                <h2>Reviews Management</h2>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Business Number">
                        <Input
                            name="businessNumber"
                            value={businessNumber}
                            placeholder="Enter Business Number"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem label="Source" {...formItemLayout} >
                        <Select
                            mode="multiple"
                            showSearch
                            placeholder="Select Source"
                            value={source || []}
                            onChange={(value) =>
                                this.handleFieldChange("source", value)
                            }
                        >
                            {sourceData && sourceData.map((option, index) => (
                                <Option key={`source-${index}`} value={option.name}>
                                    {option.name}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit">
                            Get Reviews
                        </Button>
                    </FormItem>
                </Form>
                <Form layout ="inline">
                  <TableSkeleton
                    className = {"ant-table-bordered"}
                    columns = {tableColumns}
                    expandedRowRender={this.renderExpandedRow}
                    dataSource = {this.formatData(reviewResponse)}

                  />
                </Form>
            </div>
        );
    }
}

const GetReview = Form.create()(GetReviewsForm);

export default GetReview;

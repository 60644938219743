import { createActionTypes } from "../../utils";

export const ACTIVITY_ACTIONS = createActionTypes("activities", [
        "FETCH_ACTIVITY_LIST_FROM_ROUTE",
        "FETCH_ACTIVITY_LIST",
        "SET_ACTIVITY_LIST",
]);

export const fetchActivitiesListFromRoute = () => {
    return {
        type: ACTIVITY_ACTIONS.FETCH_ACTIVITY_LIST_FROM_ROUTE
    };
};

export const fetchActivitiesList = (searchParams = null) => {
    return {
        type: ACTIVITY_ACTIONS.FETCH_ACTIVITY_LIST,
        payload: {searchParams}
    };
};

export const activityListFetched = payload => {
    return {
        type: ACTIVITY_ACTIONS.SET_ACTIVITY_LIST,
        payload
    };
};

export default ACTIVITY_ACTIONS;

import React, { Component } from "react";
import ReactDOMServer from 'react-dom/server';
import { Redirect } from "react-router-dom";
import { Form, Input, Button, message, Spin, Select, Icon, Switch, Row, Col, Upload } from "antd";
import _ from "lodash";
import { copyToClipboard } from '../../utils';
import { PageHeaderContainer } from "../../components";
import constant from "./signature.json";
import SignatureTemplate from "./SignatureTemplate";
import "./signature.scss";
import { uploadUtility, getSlug } from "../../utils";

class UserSignatureForm extends Component {

    constructor(props) {
        super();

        this.templateBanner = [];
        this.userData = {};
        this.pageKey = "signature";
        this.previewComponent = null;
    }

    componentDidMount() {
        this.props.userData.id && this.props.getUserByIdRequest({ id: this.props.userData.id });
        this.props.userData.name && this.props.getUserCalendarRequest({ email: this.props.userData.name });
    }

    componentDidUpdate(prevProps) {
        const template = this.props.form.getFieldValue("template");

        if (!prevProps.banner.hasOwnProperty(template) && this.props.banner.hasOwnProperty(template)) {
            this.setDefaultBanner(template);
        }

        if ((_.isEmpty(this.userData) && !_.isEmpty(this.props.user))|| !_.isEqual(this.props.user, prevProps.user)) {
            this.initialiseForm();
            const defaultUserTemplate = this.props.user.group;

            if (defaultUserTemplate && this.props.banner.hasOwnProperty(defaultUserTemplate)) {
                this.setDefaultBanner(defaultUserTemplate);
            }
        }
    }

    setDefaultBanner = (banner) => {
        this.templateBanner = this.props.banner[banner];
        const selectedBannerId = this.props.user && this.props.user.banner_id;
        this.templateBanner = this.templateBanner.filter((val) => val.country === this.userData.country);
        let defaultSelectedBanner = this.templateBanner.findIndex(v => (selectedBannerId === v.id));
        if (defaultSelectedBanner === -1) defaultSelectedBanner = this.templateBanner.findIndex(v => (v.isSelected|| v.country === this.userData.country ));
        if (defaultSelectedBanner !== -1) this.props.form.setFieldsValue({ banner: defaultSelectedBanner });
    }

    initialiseForm = async () => {
        this.userData = this.getMappedUserData(this.props.user);
        await this.props.form.setFieldsValue(this.userData);
        this.handleTemplateChange(this.userData.template);
    }

    getBannerMeta = (index) => {
        return this.templateBanner.hasOwnProperty(index) ? this.templateBanner[index] : null;
    }

    getOfficePhone = ( ext ) => {
        const countryPhoneExt  = this.userData.countryPhoneExt;
        
        if (this.userData.country !== "us") {
            return countryPhoneExt ? countryPhoneExt.replace(" ext. ", "").trim() : null;
        }

        return ext ? countryPhoneExt + ext : null;
    }

    getMappedUserData = (userData) => {

        if (_.isEmpty(userData) || !userData.name) return {};

        let formattedData = {};
        const countryPhoneExt = constant.value.countriesOptions.find( country => country.value === userData.country);
        if (userData.group) formattedData.template = userData.group || "default";
        formattedData.isCalendar = userData.is_calendar || false;
        if (userData.full_name) formattedData.name = userData.full_name;
        if (userData.designation) formattedData.designation = userData.designation;
        if (userData.phone_office && userData.phone_office.indexOf(countryPhoneExt.office_phone_ext) === 0) formattedData.oPhone = userData.phone_office.split(" ext. ")[1];
        if (userData.phone_cell) formattedData.cPhone = userData.phone_cell;
        if(userData.name) formattedData.website = userData.name.split('@')[1];
        if(userData.country) {
            formattedData.country = userData.country;
            formattedData.countryPhoneExt = countryPhoneExt.office_phone_ext;
        }
        if (!_.isEmpty(userData.calendar)) {
            formattedData.calendarLabel = userData.calendar.label;
            formattedData.calendarUrl = userData.calendar.url;
        } else {
            formattedData.calendarLabel = "Book a time with me";
            formattedData.calendarUrl = "";
        }
        formattedData.profileImage = userData.profile_image ? [
            {
                uid: '-1',
                name: '',
                status: 'done',
                url: userData.profile_image,
                }
        ] : null

        return formattedData;
    }

    getFormattedCellPhone = (e) => {
        // https://stackoverflow.com/a/8358141
        const phone = e.target.value;

        const cleanedPhone = phone.replace(/\D/g, '');
        let match = cleanedPhone.match(/^(1|91|44|61|63)?(\d{3})(\d{3})(\d{4})$/);

        if(this.userData.country === 'au'){
            match = cleanedPhone.match(/^(1|91|44|61|63)?(\d{3})(\d{3})(\d{3,4})$/);
        }

        if (!match) return phone;

        const countryCode = (match[1] ? `+${match[1]} ` : "");
        return [countryCode, '(', match[2], ') ', match[3], '-', match[4]].join(''); 
    }

    handleTemplateChange = async (template) => {
        this.templateBanner = [];
        await this.props.form.resetFields(["banner"]);

        if (template === "default") return;

        if (_.isEmpty(this.props.banner[template])) {
            this.props.getSignatureAssetRequest({ data: {type: "banner", template} });
        } else {
            this.setDefaultBanner(template);
        }
    }

    handleOnSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (error) {
                return;
            }
            const {id, name, country}  = this.props.userData;
            let commmonFields = _.pick(values, ["name", "designation", "cPhone", "oPhone", "template"]);
            if (values.oPhone || country !== "us") commmonFields.oPhone = this.getOfficePhone(values.oPhone);

            let data = { userId: id, email: name, ...commmonFields };
            data.isCalendar = values.isCalendar;

            if (values.profileImage) {
                data.profileImage = uploadUtility.singleFile.submittedValue(values.profileImage)
            } else {
                data.profileImage = null;
            }

            if (values.isCalendar) {
                data.calendar = { label: values.calendarLabel, url: this.props.calendar };
            }

            if (values.template !== "default") {
                data.banner = this.getBannerMeta(values.banner);
            }
            if(this.userData.country){
                data.country = this.userData.country;
            }

            this.props.updateUserRequest({ data, cb: this.handleSubmitResponse });
        });
    }

    handleCopyToClipboard = async (e) => {
        e.preventDefault();
        const previewText = ReactDOMServer.renderToString(this.previewComponent);
        try {
            await copyToClipboard(previewText);
            message.success("Signature is copied to clipboard. Please paste it where you want it to use.");
        } catch (e) {
            message.error(e.message);
        }
    }

    handleSubmitResponse = async (response) => {
        message[response.type](response.message);
    }

    isVisible = (template="default", field) => {
        if (!template || !field) return false;
        return constant.field[template].indexOf(field) !== -1;
    }

    render() {
        const unauthorized = !this.props.userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }
        
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        let formValues = getFieldsValue();

        const selectedTemplate = formValues.template;
        formValues = { ...this.userData, ...formValues, banner: this.getBannerMeta(formValues.banner), oPhone: this.getOfficePhone(formValues.oPhone), calendarUrl: this.props.calendar };

        if (uploadUtility.singleFile.submittedValue(formValues.profileImage)) {
            formValues.profileImage = uploadUtility.singleFile.submittedValue(formValues.profileImage)
        }

        this.previewComponent = (<SignatureTemplate template={formValues.template} params={formValues} />);
        getFieldDecorator("calendarUrl");

        const countryPhone = this.userData.country === "au" ? "^\\+?(1|44|61|63|91)? ?\\(?\\d{3}\\)? ?\\d{3}( |\\-)?\\d{3,4}$" : "^\\+?(1|44|61|63|91)? ?\\(?\\d{3}\\)? ?\\d{3}( |\\-)?\\d{4}$";

        return (
            <div>
                <PageHeaderContainer pageKey={this.pageKey} />
                <div style={{width: "100% !important"}} className={"userSignature"}>
                    <div style={{width: "60%", borderRight: "2px solid black", overflowY: "auto", paddingRight: "5%", float: "left"}}>
                        <Spin tip="Please wait..." spinning={this.props.isLoading} size={"large"} indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}>
                            <Form layout={"vertical"} onSubmit={this.handleOnSubmit}>
                                <Form.Item label={"Group"}>
                                    {
                                        getFieldDecorator("template", {
                                            rules: [{
                                                required: true,
                                                message: "Please provide your group"
                                            }],
                                            initialValue: this.userData.template
                                        })(
                                            <Select placeholder={"Employee group"} onChange={this.handleTemplateChange}>
                                                {
                                                    constant.template && constant.template.map(t => (
                                                        <Select.Option key={t[1]} value={t[1]}>{t[0]}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        )
                                    }
                                </Form.Item>
                                <Row gutter={12}>
                                    <Col span={12}>
                                        {
                                            this.isVisible(selectedTemplate, "name") && <Form.Item label={"Full name"} disabled={this.isVisible(selectedTemplate, "name")}>
                                                {
                                                    getFieldDecorator("name", {
                                                        rules: [{
                                                            required: true,
                                                            message: "Please provide your full name"
                                                        }],
                                                        initialValue: this.userData.name
                                                    })(
                                                        <Input placeholder={"Employee full name"}/>
                                                    )
                                                }
                                            </Form.Item>
                                        }
                                    </Col>
                                    <Col span={12}>
                                        {
                                            this.isVisible(selectedTemplate, "designation") && <Form.Item label={"Designation/Title"}>
                                                {
                                                    getFieldDecorator("designation", {
                                                        rules: [{
                                                            required: true,
                                                            message: "Please provide your designation/title"
                                                        }],
                                                        initialValue: this.userData.designation
                                                    })(
                                                        <Input placeholder={"Employee designation/title"}/>
                                                    )
                                                }
                                            </Form.Item>
                                        }
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col span={12}>
                                        {
                                            this.isVisible(selectedTemplate, "oPhone") && <Form.Item label={"Office phone"} help={this.userData.country !== "us" ? "Extension will not be required for AU and UK" : undefined}>
                                                {
                                                    getFieldDecorator("oPhone", {
                                                        rules: [{
                                                            required: false,
                                                            message: "Please provide your office phone number"
                                                        }, {
                                                            pattern: "^\\d+$",
                                                            message: "Please provide a valid phone extension"
                                                        }],
                                                        initialValue: this.userData.oPhone
                                                    })(
                                                        <Input addonBefore={this.userData.countryPhoneExt} placeholder={"Phone extension"} disabled={this.userData.country !== "us"}/>
                                                    )
                                                }
                                            </Form.Item>
                                        }
                                    </Col>
                                    <Col span={12}>
                                        {
                                            this.isVisible(selectedTemplate, "cPhone") && <Form.Item label={"Cell phone"} help={"Preferred: (xxx) xxx-xxxx, +1 (xxx) xxx-xxxx"}>
                                                {
                                                    getFieldDecorator("cPhone", {
                                                        rules: [{
                                                            required: false,
                                                            message: "Please provide your cell phone number"
                                                        }, {
                                                            pattern: countryPhone,
                                                            message: "Please provide a valid phone number"
                                                        }],
                                                        getValueFromEvent: this.getFormattedCellPhone,
                                                        initialValue: this.userData.cPhone
                                                    })(
                                                        <Input placeholder={"Cell phone"}/>
                                                    )
                                                }
                                            </Form.Item>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4}>
                                    {
                                        this.isVisible(selectedTemplate, "calendarLabel") && this.isVisible(selectedTemplate, "calendarUrl") && (
                                            <Form.Item label={"Calendar"}>
                                                {
                                                    getFieldDecorator("isCalendar", {
                                                        valuePropName: "checked",
                                                        initialValue: this.userData.isCalendar
                                                    })(
                                                        <Switch checkedChildren={"Yes"} unCheckedChildren={"No"} onChange={this.handleCalendarToggleChange} />
                                                    )
                                                }
                                            </Form.Item>
                                        )
                                    }
                                    </Col>
                                    <Col span={20}>
                                    {
                                        this.isVisible(selectedTemplate, "calendarLabel") && <Form.Item label={"Calendar label"} style={{ display: (formValues.isCalendar ? "block" : "none") }}>
                                            {
                                                getFieldDecorator("calendarLabel", {
                                                    rules: [{
                                                        required: true,
                                                        message: "Please provide your calendar label"
                                                    }],
                                                    initialValue: this.userData.calendarLabel
                                                })(
                                                    <Input placeholder={"Employee calendar label"} />
                                                )
                                            }
                                        </Form.Item>
                                    }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {
                                            this.isVisible(selectedTemplate, "calendarUrl") && formValues.isCalendar && <Form.Item>
                                                {
                                                this.props.calendar ? <><label>Calendar URL</label>: <a target="_blank" rel="noopener noreferrer" href={this.props.calendar}>{this.props.calendar}</a></> : "Please configure your personal calendar."
                                                }
                                            </Form.Item>
                                        }
                                    </Col>
                                </Row>
                                <Form.Item className="uploadProfileImageFormItem" label={"Upload a photo"} extra={<>Format: JPG & PNG, Minimum 200 x 200px </>}>
                                    {
                                        getFieldDecorator("profileImage", {
                                            rules: [{
                                                validator: uploadUtility.singleFile.validator,
                                            }],
                                            getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                            valuePropName: "fileList",
                                            initialValue: this.userData.profileImage,
                                        })(
                                            <Upload
                                                name={"files"}
                                                accept={"image/png, image/jpeg, image/jpg"}
                                                action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=profile-image-${formValues.name ? getSlug(formValues.name) : ''}-${Date.now()}`}
                                                withCredentials
                                                listType="picture"
                                            >
                                                <Button icon={"upload"} disabled={!formValues.name}>Upload</Button>
                                            </Upload>
                                        )
                                    }
                                </Form.Item>
                                {
                                    this.isVisible(selectedTemplate, "banner") && <Form.Item label={"Banner"}>
                                        {
                                            getFieldDecorator("banner", {
                                                rules: [{
                                                    required: true,
                                                    message: "Please choose an appropriate banner"
                                                }]
                                            })(
                                                <Select placeholder={"Chose an appropriate banner"} loading={_.isEmpty(this.templateBanner)} optionLabelProp={"label"}>
                                                    {
                                                        this.templateBanner.map((b,i) => {
                                                            return (
                                                                <Select.Option key={i} value={i} label={b.name}><img src={b.image} style={{maxHeight: "80px"}} alt={b.name}/></Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            )
                                        }
                                    </Form.Item>
                                }
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Set this as your signature
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Spin>
                    </div>
                    <div style={{width: "40%", background: "#fff", overflowY: "auto", padding: "0 5%"}}>
                        <div style={{position: "fixed"}}>
                            <h4 style={{marginBottom: "50px"}}>Your signature will appear like this:</h4>
                            <div>
                                {
                                    this.previewComponent
                                }
                            </div>
                            <div>
                                <Button type="primary" icon="copy" onClick={this.handleCopyToClipboard}>
                                    Copy this signature
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const UserSignature = Form.create()(UserSignatureForm);
export default UserSignature;
import React from "react";
import PropTypes from "prop-types";
import {Modal} from "antd";
import "./Preview.scss";

const Preview = ({title, body, onCancel}) => (
    <Modal
        title={`Preview | ${title}`}
        visible={true}
        cancelText={"Close"}
        onCancel={onCancel}
        footer={null}
        className={"modal preview"}
        mask={false}
    >
        {body}
    </Modal>
);

Preview.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.any.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default Preview;
import { connect } from "react-redux";
import {
    fetchBusinessList,requestAccessToBusiness, fetchRequestHistoryList
} from "./actions";

import RequestBusinessAccess from "./RequestBusinessAccess";
import AccessRequestHistory from "./AccessRequestHistory";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.RequestBusinessAccess
});

export const RequestBusinessAccessPage = connect(
    mapStateToProps, { fetchBusinessList, requestAccessToBusiness }
)(RequestBusinessAccess);

export const AccessRequestHistoryPage = connect(
    mapStateToProps, { fetchRequestHistoryList }
)(AccessRequestHistory);
import React, {Component} from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Icon, Upload, Radio } from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";
import { uploadUtility } from "../../utils";
import { TextEditor } from "../../components";
import lookup from "./constants";

const { Item: FormItem } =  Form;

class SneakPeakForm extends Component {
    constructor(props) {
        super(props);

        this.defaultMediaOption = lookup.sneakPeak.defaultMediaOption[props.type];
    }

    handleFormSubmission = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (error) {
                return;
            }

            let { media } = values;

            if ( media && media.type === "carousal" && media.images && media.images.length ) {
                const { images } = media;
                let imagesWithUrls = [];
                
                for (const image of images) {
                    imagesWithUrls.push(uploadUtility.singleFile.submittedValue(image));
                }

                media.images = imagesWithUrls;
            }

            this.props.handleStepFormSubmission({ sneakPeak: {...values, media} });
        });
    };

    handleFormSkip = (e) => {
        e.preventDefault();

        this.props.handleStepFormSubmission({ sneakPeak: {} });
    };

    handleMediaTypeChange = (e) => {
        e.preventDefault();

        const mediaType = this.props.form.getFieldValue("mediaType");
        this.setState({mediaFileList: (mediaType === "video" ? [] : {0: [], 1: [], 2: [], 3: []})});
    };

    handleDescriptionChange = (html, name) => {
        this.props.form.setFieldsValue({ desc: html });
    };

    render() {
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { sneakPeak = {} } = this.props.initialValues;
        const { title = "Interested? Here’s a Sneak Peek", desc, media = {} } = sneakPeak;
        const { media: fieldMedia={...media} } = getFieldsValue();
        const type = fieldMedia.type || this.defaultMediaOption;
        const optionalField = !!(this.props.type === "guide" || this.props.type === "webinar")

        return (
            <>
                <Form onSubmit={this.handleFormSubmission}>
                    <FormItem {...formItemLayout} label={"Title"} extra={<p>Title related to sneak peek</p>}>
                        {
                            getFieldDecorator("title", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable title for the sneak peak"
                                }],
                                initialValue: title
                            }) (
                                <Input placeholder={"Title"}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Description"} extra={<p>Description which describes the attached media</p>}>
                        {
                            getFieldDecorator("desc", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable title for the sneak peak"
                                }],
                                initialValue: desc
                            }) (
                                <TextEditor name="desc" handleEditorChange={this.handleDescriptionChange} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Media type"} extra={<p>Choose any of a suitable media format</p>} style={{display: "none"}}>
                        {
                            getFieldDecorator("media[type]", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable media for the sneak peak"
                                }],
                                initialValue: type
                            }) (
                                <Radio.Group options={lookup.sneakPeak.mediaOptions || []} onChange={this.handleMediaTypeChange}/>                               
                            )
                        }
                    </FormItem>
                    {
                        type === "video" ? (
                            <>
                                <FormItem {...formItemLayout} label={"Video Thumbnail URL"}>
                                    {
                                        getFieldDecorator("media[imgUrl]", {
                                            rules: [{
                                                required: optionalField ? false : true,
                                                message: "Provide a video thmbnail image URL"
                                            }, {
                                                type: "url",
                                                message: "Provide a valid URL"
                                            }],
                                            initialValue: media.imgUrl
                                        }) (
                                            <Input placeholder={"Video thumbnail image url"} />
                                        )
                                    }
                                </FormItem>
                                <FormItem {...formItemLayout} label={"Intro Video Source"} hidden={true}>
                                    {
                                        getFieldDecorator("media[videoSource]", {
                                            initialValue: media.videoSource || "youtube"
                                        }) (
                                            <Input type="hidden" />
                                        )
                                    }
                                </FormItem>
                                <FormItem {...formItemLayout} label={"Video Embed Code"}>
                                    {
                                        getFieldDecorator("media[embedLink]", {
                                            rules: [{
                                                required: optionalField ? false : true,
                                                message: "Provide a valid video embed code"
                                            }, {
                                                pattern: "^[a-zA-Z0-9\\-\\_\\?\\=]+$",
                                                message: "Please provide a valid code"
                                            }],
                                            initialValue: media.embedLink
                                        }) (
                                            <Input placeholder={"Video embed code"} addonBefore={ "https://www.youtube.com/embed/" }/>
                                        )
                                    }
                                </FormItem>
                            </>
                        ) : (
                            Array.from({ length: 4 }, (v, index) => {
                                const imageUrl = media && media.images && media.images[index];
                                return (
                                    <FormItem key={index} {...(index === 0 ? formItemLayout : formButtonLayout)} label={(index === 0  ? "Image" : "")}>
                                        {
                                            getFieldDecorator(`media[images][${index}]`, {
                                                getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                                rules: [{
                                                    "required": optionalField ? false : true,
                                                    "message": `Upload an image#${index + 1}`
                                                }, {
                                                    validator: uploadUtility.singleFile.validator
                                                }],
                                                valuePropName: "fileList",
                                                initialValue: imageUrl && [{
                                                    uid: "-1",
                                                    name: `${title}#${index + 1}`,
                                                    status: "done",
                                                    url: imageUrl
                                                }]
                                            })(
                                                <Upload
                                                    name={"files"}
                                                    accept={"image/*"}
                                                    action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=sneak-peak-image-${index + 1}&timestamp=${Date.now().toString()}`}
                                                    withCredentials
                                                    listType="picture"
                                                    >
                                                    <Button>
                                                        <Icon type="upload"/> Upload Image#{index + 1}
                                                    </Button>
                                                </Upload>
                                            ) 
                                        }
                                    </FormItem>
                                )
                            })
                        )
                    }
                    <FormItem {...formButtonLayout}>
                        <Button type={"primary"} htmlType={"submit"}>
                            Next<Icon type="right" />
                        </Button>
                        <Button htmlType={"button"} type={"default"} style={{ marginLeft: "20px" }} onClick={this.handleFormSkip}>
                            Skip <Icon type="stop" />
                        </Button>
                    </FormItem>
                </Form>
            </>
        );
    }
}

const SneakPeak = Form.create()(SneakPeakForm);

SneakPeak.propTypes = {
    userData: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    additional: PropTypes.object,
    handleStepFormSubmission: PropTypes.func.isRequired
};

export default SneakPeak;
import { takeLatest, call, put } from "redux-saga/effects";
import FreeProdOverviewActions, * as actionCreator from "./actions";
import FreeProdOverviewAPI from "./http";

const FreeProdOverviewSaga = [
    takeLatest(FreeProdOverviewActions.REQ_OVERVIEW_PAGEDATA, reqOverviewPageData),
    takeLatest(FreeProdOverviewActions.REQ_DETAIL_CATEGORIES, reqDetailCategories),
    takeLatest(FreeProdOverviewActions.REQ_DETAIL_TABLE_CONTENT, reqDetailTableContent),
    takeLatest(FreeProdOverviewActions.REQ_SUMMARY_PAGEDATA, reqSummaryPageData),
    takeLatest(FreeProdOverviewActions.REQ_EMAILS_PAGEDATA, reqEmailsPageData),

];

function* reqOverviewPageData(action) {
    try {
        const response =  yield call(FreeProdOverviewAPI.reqOverviewData, action.data);
        yield put(actionCreator.successOverviewPageData(response.data.data));
    } catch (e) {
        yield put(actionCreator.errorOverviewPageData((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqDetailCategories(action) {
    try {
        const response =  yield call(FreeProdOverviewAPI.reqDetailCategories, action.data);
        yield put(actionCreator.successDetailCategories(response.data.data));
    } catch (e) {
        yield put(actionCreator.errorDetailCategories((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqDetailTableContent(action) {
    try {
        const response =  yield call(FreeProdOverviewAPI.reqDetailTableContent, action.data);
        yield put(actionCreator.successDetailTableContent(response.data.data));
    } catch (e) {
        yield put(actionCreator.errorDetailTableContent((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqSummaryPageData(action) {
    try {
        const response =  yield call(FreeProdOverviewAPI.reqSummaryData, action.data);
        yield put(actionCreator.successSummaryPageData(response.data.data));
    } catch (e) {
        yield put(actionCreator.errorSummaryPageData((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqEmailsPageData(action) {
    try {
        const response =  yield call(FreeProdOverviewAPI.reqEmailsData, action.data);
        yield put(actionCreator.successEmailsPageData(response.data.data));
    } catch (e) {
        yield put(actionCreator.errorEmailsPageData((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

export default FreeProdOverviewSaga;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";

import UserWidget from "../User";
import logo from "../../../assets/images/birdeye-logo-2020.svg";
import "./Header.scss";

const Nav = Layout.Header;

const getHeading = (path) => {
    if (path.toLowerCase().indexOf("prospector") !== -1) return "Sales Prospector";
    return "Success Portal";
}

const Header = ({ history, sessionExists }) => {
    const heading = getHeading(getHeading(window.location.href));

    return (
        <Nav className="header">
            <div className="inner-wrapper">
                <Link to="/">
                    <img className="header-logo" alt="birdeye logo" src={logo} />
                    <span className="header-title">{heading}</span>
                </Link>
                <UserWidget history={history} sessionExists={sessionExists} />
            </div>
            <Menu theme="dark" mode="horizontal" />
        </Nav>
    );
};

Header.propTypes = {
    history: PropTypes.object.isRequired,
    sessionExists: PropTypes.bool.isRequired
};

export default Header;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
import ReactHtmlParser from "react-html-parser";

import { TableSkeleton } from "../../../../components";
import { formatContent, getFormattedTime, formatNumber } from "../../utils";
import tableConfig from "./tableConfig.json";

class Table extends Component {
    formatData = (data, profileData) => {
        const displayData = profileData ? [profileData, ...data] : data;
        return displayData.map((record, i) => {
            if (record.healthScore) {
                const healthScoreCheck = record.healthScore.match(/<a[^>]*>([^<]+)<\/a>/i);
                if (healthScoreCheck && healthScoreCheck.length === 2) {
                    record.healthScore = healthScoreCheck[1];
                }
            }

            let formattedRecord = {
                ...record,
                key: record.customerId,
                customerName: record.businessName,
                formattedCustomerSince: getFormattedTime(record.paidSinceC),
                formattedReviewsSinceJoining: formatNumber(record.reviewsSinceJoining),
                healthScoreRaw: record.healthScore ? parseInt(record.healthScore.replace('%', '')) : -1 // This is to place empty healthscore after 0
            };
            if (profileData && i === 0) {
                formattedRecord.customerName += " (Selected)";
            }
            return formattedRecord;
        });
    };

  renderExpandedRow = (record) => {
    const {industryToSubs} = this.props;

    let content = {...record};
    delete content.reviewsSinceJoining;
    delete content.paidSinceC;
    delete content.healthScore;
    return <div>{ReactHtmlParser(formatContent(content, industryToSubs))}</div>;
  };

  render() {
    const { data, profileData, isLoading } = this.props;

    return (
      <div className="table-wrapper">
        {isLoading ? (
          <Spin />
        ) : (
          <TableSkeleton 
            columns={tableConfig.columns} 
            expandedRowRender={this.renderExpandedRow} 
            dataSource={this.formatData(data, profileData)} />
        )}
      </div>
    );
  }
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default Table;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { pick, isEmpty, includes } from "lodash";
import { Form, Input, Select, Button, Alert, Spin, Modal } from "antd";

import { RedirectWrapper } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";

import PermissionSliderInput from "./PermissionSliderInput";
import { getTabPermission, getOtherPermission, getOtherPermissionSelectList, getPermissions } from "./utils";

const Option = Select.Option;
const FormItem = Form.Item;

class UserForm extends Component {
    initialData = {
        id: 0,
        name: "",
        tabPermissions: [],
        otherPermissions: [],
        role: null
    }

    constructor(props) {
        super();

        this.state = {
            data: this.initialData,
            formError: "",
            isEditPage: !!props.match.params.id
        };
    }

    componentDidMount() {
        const { match, fetchUserRequest, fetchUserRolesListRequest, fetchPermissionsList } = this.props;

        if (this.state.isEditPage) fetchUserRequest({ id: match.params.id });
        
        fetchUserRolesListRequest();
        fetchPermissionsList();
    }

    componentDidUpdate(prevProps) {
        if (this.state.isEditPage && prevProps.curData.id !== this.props.curData.id) {
            this.setState({ data: this.mapDataToForm(this.props.curData) });
        }
    }

    mapDataToForm = (rawData) => {
        let data = pick(rawData, [
            "id",
            "name",
            "role"
        ]);

        const allocatedPermission = rawData.privileges ? rawData.privileges.split(",").map(p => p.trim()) : [];
        data.otherPermissions = getOtherPermission(allocatedPermission);
        data.tabPermissions = getTabPermission(allocatedPermission);
        return data;
    };

    handleRoleChange = (v) => {console.log(this.props.rolesList);
        const roleMeta = this.props.rolesList.filter(d => (d.id === v))[0];
        let data = this.state.data;
        data = { ...data, role: v, privileges: roleMeta.privileges };
        this.setState({ data: this.mapDataToForm(data) });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.handleFieldChange(name, value);
    };

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        this.setState({ data, formError: "" });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const fieldsToBeValidated = pick(this.state.data, [
            "id",
            "name",
            "role",
        ]);

        if (includes(fieldsToBeValidated, "")) {
            this.setState({ formError: "User name and role are manadatory fields" });
            return;
        }

        if (isEmpty(this.state.data.tabPermissions)) {
            this.setState({ formError: "Tab permission is manadatory field" });
            return;
        }

        let data = pick(this.state.data, [
            "id",
            "name",
            "role"
        ]);

        data.permissions = this.state.data.tabPermissions;
        if (!isEmpty(this.state.data.otherPermissions)) data.permissions = data.permissions.concat(this.state.data.otherPermissions);

        data.permissions = getPermissions(data.permissions, this.props.permissionsList);
        
        // this step is needed here as data will be updated in DB based on IDs
        if (this.state.isEditPage) {
            this.props.updateUserRequest({ data, cb: this.renderFeedback });
        } else {
            this.props.addUserRequest({ data, cb: this.renderFeedback });
        }
    };

    renderFeedback = (userErrorType) => {
        const goToUsersPage = () => this.props.history.push("/users");
        if (!userErrorType) {
            return Modal.success({
                title: `Successfully ${this.state.isEditPage ? "updated" : "added"} this user`,
                content: `Check the ${this.state.isEditPage ? "updated" : "new"} record in user list page.`,
                okText: "Go to user list page",
                maskClosable: true,
                onOk() {
                    goToUsersPage();
                }
            });
        } else {
            return Modal.error({
                title: `Failed to ${this.state.isEditPage ? "update" : "add"} this user`,
                content: `Something went wrong while ${this.state.isEditPage ? "updating" : "adding"} this record. Please retry again.`
            });
        }
    }

    renderFormBlock = () => {
        const {
            name,
            role,
            tabPermissions,
            otherPermissions
        } = this.state.data;

        const { rolesList, permissionsList } = this.props;
        const otherPermissionList = getOtherPermissionSelectList(permissionsList);
        if (this.state.isEditPage && this.props.userError === "userNotFound") {
            return <Alert message="User not found" type="userError" showIcon />;
        } else {
            return (
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="User Email">
                        <Input
                            name="name"
                            value={name}
                            placeholder="Birdeye email"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Role" extra={<p>On changing the role, previously assigned all <strong>tab permissions</strong> will reset and selected role permissions will assign to user.</p>}>
                        <Select
                            value={role ? role : undefined}
                            placeholder="User Role"
                            onChange={this.handleRoleChange}>
                            {rolesList.map((role) => (
                                <Option
                                    key={role.id}
                                    value={role.id}>
                                    {role.name}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Tab Permissions">
                        <PermissionSliderInput handleChange={this.handleFieldChange} permissions={tabPermissions}/>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Other Permissions" extra="These are set of permissions which are independent of tabs.">
                        <Select
                            value={otherPermissions}
                            mode="multiple"
                            placeholder="Other Permissions"
                            onChange={(value) =>
                                this.handleFieldChange("otherPermissions", value)
                            }>
                            {otherPermissionList.map((permission) => (
                                <Option
                                    key={permission.name}
                                    value={permission.name}>
                                    {permission.label}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit">
                            {this.state.isEditPage ? "Save" : "Create"}
                        </Button>
                    </FormItem>
                </Form>
            );
        }
    };

    render() {
        const { loadingUser, loadingPermission, loadingRole, userError } = this.props;

        const unauthorized = userError === "unauthorized";
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="users-root">
                <h2>{this.state.isEditPage ? "Update" : "Add"} User</h2>
                {this.state.isEditPage && (loadingUser || loadingPermission || loadingRole) ? (
                    <Spin />
                ) : (
                    this.renderFormBlock()
                )}
            </div>
        );
    }
}

const UpdateUser = Form.create()(UserForm);

UpdateUser.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loadingUser: PropTypes.bool.isRequired,
    userError: PropTypes.string.isRequired,
    curData: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    fetchUserRequest: PropTypes.func.isRequired,
    updateUserRequest: PropTypes.func.isRequired,
    addUserRequest: PropTypes.func.isRequired,
    rolesList: PropTypes.array.isRequired
};

export default UpdateUser;
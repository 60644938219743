import React, { Component } from "react";
import PropTypes from "prop-types";
import {isEmpty, pick} from "lodash";
import { Form, Alert, Spin, Modal, Steps} from "antd";
import { RedirectWrapper } from "../../components";
import {getIsPageActionAccessible, getSlug} from "../../utils";

import UpdateIntegrationDefault from "./UpdateIntegrationDefault";
import MarketPlaceIntegration from './MarketPlaceIntegration';
import Overview from "./Overview";

const { Step } = Steps;

class IntegrationForm extends Component {

    constructor(props) {
        super(props);
        this.pageKey = "integrationsList";
    }

    initialData = {
        id: 0,
        name: "",
        domainId: 0,
        version: "",
        heading: "",
        blurb: "",
        url: "",
        type: [],
        availability: [],
        enabled: false,
        triggerSupported: "",
        location: "",
        specialInstructions: "",
        isPremium: false,
        integrationTier: "",
        cmpHeading: "",
        cmpBlurb: "",
        cmpLogo: "",
        cmpDescriptionHeading: "", 
        cmpDescriptionContent: "", 
        cmpDescriptionImage: "",
        referHeading: "",
        referBlurb: "",
        technicalDocument: "",
        countries: [],
        products: [],
        lessonlyLink: "",
        trainingResourcesDoc: "",
        trainingResourcesLink: "",
        technicalDocumentFeedbackForm: "",
        createdBy: "",
        updatedBy: "",
        partnership: "No partnership",
        isPublished: true,
        isExclusions: false,
        isSalesforce: true,
        isMarketPlaceEnable: false,
        marketPlace: {
            name: "",
            logoUrl: "",
            overview: {
                assets: [{type: "", thumbnailUrl: "", url: "", hasBEBranding: true}],
                isRecommended: false,
                industry: [], 
                shortDescription: "",
                categories: [],
                description: "",
                setupGuide: {
                    direct: {
                        label: "",
                        href: ""
                    },
                    partner: {
                        label: "",
                        href: ""
                    }
                }
            }, 
            features:  { 
                integrationFeatures: [{ 
                    icon: "", 
                    heading: "", 
                    description: ""
                }],
                shareData: []
            }, 
            connection: { 
                prerequisites: [], 
                steps: [{
                    heading: "", 
                    description: "", 
                    link: {
                        direct: {
                            label: "",
                            href: ""
                        },
                        partner: {
                            label: "",
                            href: ""
                        }
                    } 
                }]
            },
            configurations: {}
        }
    };

    state = {
        data: this.initialData,
        isEditPage: !!this.props.match.params.id,
        steps: [{
            title: "Default",
            isValid: !!this.props.match.params.id,
            isSkipped: false
        },
        {
            title: "Marketplace",
            isOptional: true,
            isSkipped: !this.props.match.params.id,
            isValid: true
        },
        {
            title: "Publish",
            dataKey: "overview",
            isIgnored: true
        }],
        currentStep: 0
    };

    componentDidMount() {
        const { match, fetchIntegrationRequest } = this.props;
        this.props.fetchIntegrationDomainsListRequest();
        if (match.params.id) {
            fetchIntegrationRequest({ id: match.params.id });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.curData !== prevProps.curData) {
            this.setState({ data: {...this.props.curData} });
        }
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                isEditPage: !!this.props.match.params.id,
                data: this.initialData
            });
        }
    }

    handleInputChange = (e, imageFieldName) => {
        if (e.target) {
            const { name, value } = e.target;
            this.handleFieldChange(name, value);
        } else if (e.file) {
            if(e.file.status) {
                if (e.file.status === "uploading") {
                    this.setState(() => ({uploadingImage: true}));
                } else if (e.file.status === "done") {
                    this.setState(() => ({uploadingImage: false}));
                }
                if (e.file.response) {
                    if (e.file.response.imageUrl) {
                        const {imageUrl} = e.file.response;
                        this.handleFieldChange(imageFieldName, imageUrl);
                    }
                }
            } else {
                this.setState(() => ({uploadingImage: true}));
            }
        }
    };

    handleCRMNameBlur = (e) => {
        const { heading } = this.state.data;
        if (e.target.value && !heading) {
            this.setState({
                data: {
                    ...this.state.data,
                    heading: `Get New Reviews with ${e.target.value} & Birdeye`
                }
            })
        }
    }

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        if (name === "name") {
            const u = getSlug(data.name);
            data.url = u.split("-").join("");
        }
        if(name === 'isExclusions' && value === true){
           data['isPublished'] = false;
           data['isSalesforce'] = false;
        }
        this.setState({ data });
    };

    handleDocumentUpload = (name, value) => {
        let data = { ...this.state.data };
        if(value.file.response) {
            data[name] = value.file.response.imageUrl;
        }
        this.setState({ data });
    }

    updateStepInfo = (isValid, isSkipped) => {
        const {currentStep, steps} = this.state;
        let stepsArr = [...steps];
        stepsArr[currentStep].isValid = isValid;
        stepsArr[currentStep].isSkipped = isSkipped;
        this.setState({ steps: stepsArr });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const rawData = { ...this.state.data };
        const {currentStep, steps} = this.state;

        let data = {
            ...rawData,
            cmpHeading: currentStep === 0 && !rawData.cmpHeading ? rawData.heading : rawData.cmpHeading,
            cmpBlurb: currentStep === 0 && !rawData.cmpBlurb ? rawData.blurb : rawData.cmpBlurb,
            referHeading: currentStep === 0 && !rawData.referHeading ? rawData.heading : rawData.referHeading,
            referBlurb: currentStep === 0 && !rawData.referBlurb ? rawData.blurb : rawData.referBlurb
        };

        this.setState({
            data
        });

        if (currentStep < steps.length) {
            this.setState({currentStep: currentStep + 1});
        }
    };

    handleMarketPlaceFormSubmission = (marketplaceForm, isMarketPlaceEnable) => {
        const {currentStep, steps, data} = this.state;
        const marketPlaceData = { ...data, marketPlace: isMarketPlaceEnable ? marketplaceForm : data.marketPlace, isMarketPlaceEnable };
        this.setState({data: marketPlaceData});
        this.updateStepInfo(true, false);
        if (currentStep < steps.length) {
            this.setState({currentStep: currentStep + 1});
        }
    }

    handleFinalSubmit = (e) => {
        e.preventDefault();

        const rawData = { ...this.state.data };
        const types = [];
        const productsModules = [];
        delete rawData['countriesForTable'];
        delete rawData['modifiedName'];
        delete rawData['productsForTable'];
        delete rawData['topLogo2x'];
        delete rawData['topLogo3x'];

        if(Array.isArray(rawData.type))
        rawData.type.map(function(type) {
            type.props ? types.push(type.props.children) : types.push(type);
        });

        if(Array.isArray(rawData.products))
        rawData.products.map(function(product) {
            product.props ? productsModules.push(product.props.children) : productsModules.push(product);
        });

        let data = {
            ...rawData,
            type: types.join(", "),
            availability: rawData.availability.join(", "),
            countries:  rawData.countries.join(", "),
            products:   productsModules.join(", "),
            isPublished: rawData.isExclusions ? false : rawData.isPublished,
            isSalesforce: rawData.isExclusions ? false : rawData.isSalesforce
        };

        if (data.id) {
            data.updatedBy = this.props.userData.name;
            this.props.updateIntegrationRequest({ data, cb: this.renderFeedback });
        } else {
            delete data.id;
            data.createdBy = this.props.userData.name;
            data.updatedBy = this.props.userData.name;
            this.props.addIntegrationRequest({ data, cb: this.renderFeedback });
        }
    };

    renderFeedback = (errorType) => {
        const goToIntegrationsPage = () => this.props.history.push("/integrations");
        if (!errorType) {
            return Modal.success({
                title: `You have successfully ${
                    this.state.isEditPage ? "edited" : "added"
                    } a record`,
                content: `Check the ${
                    this.state.isEditPage ? "updated" : "new"
                    } record in integrations list page`,
                okText: "Go to integrations page",
                maskClosable: true,
                onOk() {
                    goToIntegrationsPage();
                }
            });
        } else {
            return Modal.warning({
                title: `The record was not ${
                    this.state.isEditPage ? "edited" : "added"
                    } successfully...`,
                content: "Please check your input or try again later..."
            });
        }
    };

    renderFormBlock = () => {
        const {currentStep} = this.state;
        switch (currentStep) {
            case 1: return this.renderMarketPlace();
            case 2: return this.renderOverview();
            default: return this.renderDefault();
        }
    };

    renderOverview = () =>{
        const {steps} = this.state;
        return (
            <Overview steps={steps} handleFinalSubmit={this.handleFinalSubmit}/>
        );
    };


    renderDefault = () => {
        const { domains } = this.props;
        const data = pick(this.state.data, ["enabled", "name", "domainId", "version", "heading", "blurb", "type", "availability", "triggerSupported", "location", "specialInstructions", "isPremium", "integrationTier", "url", "technicalDocument", "countries", "products", "topLogo", "lessonlyLink", "trainingResourcesDoc", "trainingResourcesLink", "technicalDocumentFeedbackForm", "partnership", "isPublished", "isExclusions", "isSalesforce" ]);
        return (
            <UpdateIntegrationDefault formData={data} domains={domains} handleInputChange={this.handleInputChange} handleFieldChange={this.handleFieldChange} handleSubmit={this.handleSubmit} updateStepInfo={this.updateStepInfo} handleCRMNameBlur={this.handleCRMNameBlur} uploadingImage={this.state.uploadingImage} handleDocumentUpload={this.handleDocumentUpload} />
        );
    };

    renderMarketPlace = () => {
        const {marketPlace, name, isMarketPlaceEnable} = this.state.data;
        marketPlace.name = !marketPlace.name ? name : marketPlace.name;

        return (
            <MarketPlaceIntegration initialData={marketPlace} isMarketPlaceEnable={isMarketPlaceEnable} handleMarketPlaceSubmission={this.handleMarketPlaceFormSubmission} />
        );
    }

    handleStepsOnChange = (currentStep) => {
        this.setState({ currentStep });
    };

    renderContent = () => {
        const {currentStep, steps} = this.state;
        return (
            <>
                <Steps size={"small"} type={"navigation"} current={currentStep} onChange={this.handleStepsOnChange} status={"wait"}>
                    {
                        steps.map((step, index) => (
                            <Step
                                key={index}
                                title={<>{step.title} {!step.isOptional && <span style={{ color: "red" }}>*</span>}</>}
                                status={ ( currentStep > index ) && !steps[index].isValid ?  "error" : ""}
                            />))
                    }
                </Steps>
                <div style={{marginTop: "50px"}}>
                    {
                        this.renderFormBlock()
                    }
                </div>
            </>
        );
    };

    render() {
        const { isLoading, error, userData, location } = this.props;
        const { name } = userData;

        const unauthorized = error === "unauthorized" || !name;
        const isActionAccessible = getIsPageActionAccessible(this.pageKey, userData.privileges);

        if ( unauthorized || !isActionAccessible) {
            return <RedirectWrapper location={this.props.location}/>;
        }
        
        return (
            <div className="integrations-root">
                <h2>{this.state.isEditPage ? "Edit" : "Add New"} Integration</h2>
                {this.state.isEditPage ? (
                    this.props.error === "integrationNotFound" ? (
                        <Alert message="Integration not found" type="error" showIcon />
                    ): (
                        isLoading ? (
                            <Spin />
                        ) : (
                            this.renderContent()
                        )
                    )
                ): (
                    this.renderContent()
                )}
            </div>
        );
    }
}

const UpdateIntegration = Form.create()(IntegrationForm);

UpdateIntegration.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    curData: PropTypes.object.isRequired,
    formattedDomains: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchIntegrationRequest: PropTypes.func.isRequired,
    updateIntegrationRequest: PropTypes.func.isRequired,
    addIntegrationRequest: PropTypes.func.isRequired
};

export default UpdateIntegration;
import React, { useEffect, useState } from "react";
import { PageHeaderContainer, TableSkeleton } from "../../components";
import "./Report.scss";
import { Redirect } from "react-router-dom";
import { Popconfirm, Switch } from "antd";
import { beAPIResource } from "../../utils/apiHelper";

const InvalidEmailDomainPage = (props) => {

    const pageKey = "leadManagementList";
    const [ loading, setLoading ] = useState(false);
    const [ isDomain, setIsDomain ] = useState(true);
    const [emailDomainList, setEmailDomainList] = useState([]);

    const getAllInvalidEmailsDomains = async () => {
        const {data: emailDomainList} = await beAPIResource.get(`/support/leadgen-management/get-invalid-emails-domains?isDomain=${isDomain}`);

        setEmailDomainList(emailDomainList);
    }

    useEffect(() => {
        getAllInvalidEmailsDomains();
    }, [isDomain]);
    
    const columns = [
        {
            "title": "Lead Address",
            "key": "leadAddress",
            "searchable": true,
            "width": '50%'
        },
        {
            "title": "Actions",
            "key": "actions"
        }
    ];

    const handleDelete = async (leadAddress) => {
        setLoading(true);
        await beAPIResource.delete(`/support/leadgen-management/delete-invalid-emails-domains?leadAddress=${leadAddress}&domain=${isDomain}`);
        getAllInvalidEmailsDomains();
        setLoading(false);
    }

    const formatData = () => {

        if(emailDomainList.length) {
            return emailDomainList.map((listItem) => {
                return {
                    ...listItem,
                    actions: (
                        <>
                            <Popconfirm title={"Do you want to delete this?"} onConfirm={() => handleDelete(listItem.leadAddress)} okText="Yes" cancelText="No" placement={"top"}>
                                <a>Delete</a>
                            </Popconfirm>
                        </>
                    )
                };
            });
        }
        return emailDomainList;
    };

    const unauthorized = props.error === "unauthorized" || !props.userData.name;

    return <div style={{ width: "80vw" }}>
        {
            unauthorized ? <Redirect to="/" /> : ''
        }

    <PageHeaderContainer title={`Invalid ${ isDomain ? 'Domains' : 'Emails' }`} pageKey={pageKey} />

    <div style={{marginBottom: "20px"}}>
        <Switch checkedChildren={"Domains"} unCheckedChildren={"Emails"} style={{width: "77px"}} defaultChecked={isDomain} onChange={() => setIsDomain(!isDomain)}/>
    </div>
    
    <TableSkeleton
        columns={columns}
        dataSource={formatData()}
        loading={loading}
        scroll={{x: 1000, y: 800}}
    />
</div>
}

export default InvalidEmailDomainPage;
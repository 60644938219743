import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {formButtonLayout, formItemLayout, mapSourceIdToName} from "../../constants";
import { RedirectWrapper } from "../../components";
import { Form, Select, Button, Alert, Spin, Modal } from "antd";
import { getIsPageActionAccessible } from "../../utils";
const Option = Select.Option;
const FormItem = Form.Item;

class UpdateCategoryPage extends Component {

    constructor(props) {
        super(props);

         this.state = {
             isEditPage: !!this.props.match.params.sourceId
         };

    }
    pageKey = "categoriesMapping";
    componentDidMount() {
        const { match} = this.props;
        if (match.params) {
            this.props.fetchCategoriesListBySource({sourceId: match.params.sourceId});
            this.props.fetchBirdeyeCategoriesList();
        }
    }

    componentDidUpdate(prevProps) {
        const { match} = this.props;
        const { sourceId, categoryName } = match.params;

        if (sourceId !== prevProps.match.params.sourceId && categoryName !== prevProps.match.params.categoryName) {
            this.setState({ isEditPage: !!this.props.match.params.id });
            this.props.fetchBirdeyeCategoriesList();
        }
    }

    getSubCategories = (parentCategory) => {
        const {categories = []} = this.props;
        let childCategories = [];
        for (let i = 0; i < categories.length; i++) {
            if (categories[i]['parentCategory']['name'] === parentCategory){
                childCategories = categories[i]['childCategory'];
                break
            }
        }
        return childCategories;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((error, values) => {
            if(!error) {
                const {data, match} = this.props;
                const getBECategory = data.find((ele) => ele.name === match.params.categoryName);
                const {beParentCategory: beParentCategoryValue = "", beSubCategory: beSubCategoryValue = "", beSubCategoryId = ""} = getBECategory || {};
                const  payload = {
                    sourceId: match.params.sourceId,
                    name: match.params.categoryName,
                    beParentCategory: values.beParentCategory,
                    beSubCategory: values.beSubCategory,
                    oBeParentCategory:beParentCategoryValue,
                    oBeSubCategory: beSubCategoryValue,
                    oBeSubCategoryId: beSubCategoryId
                };
                this.props.updateCategoryRequest({ data: payload, cb: this.renderFeedback });
            } 
        })
    };

    handleSubCategories = () => {
        const { form } = this.props;
        console.log("handleSubCategories>>")
        form.setFieldsValue({beSubCategory: undefined})
    }

    renderFeedback = (errorType) => {
        const { match } = this.props;
        const goToCategoryPage = () => this.props.history.push("/categories/" + match.params.sourceId);
        if (!errorType) {
            return Modal.success({
                title: `You have successfully ${
                    this.state.isEditPage ? "edited" : "added"
                    } a record`,
                content: `Check the ${
                    this.state.isEditPage ? "updated" : "new"
                    } record in category list page`,
                okText: "Go to category page",
                maskClosable: true,
                onOk() {
                    goToCategoryPage();
                }
            });
        } else {
            return Modal.warning({
                title: `The record was not ${
                    this.state.isEditPage ? "edited" : "added"
                    } successfully...`,
                content: "Please check your input or try again later..."
            });
        }
    };

    renderFormBlock = () => {
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const { categories, data, match } = this.props;
        const {categoryName = "", sourceId = ""} =  match.params;
        const getBECategory = data.find((ele) => ele.name === categoryName);
        const {beParentCategory = "", beSubCategory = ""} = getBECategory || {};
        const beParentCategoryValue = getFieldValue('beParentCategory') ? getFieldValue('beParentCategory') : beParentCategory;
        const subCategories = this.getSubCategories(beParentCategoryValue);

        if (this.state.isEditPage && this.props.error === "categoryNotFound") {
            return <Alert message="Category not found" type="error" showIcon />;
        } else {
            return (
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Source">
                        <span> {mapSourceIdToName[sourceId]} </span>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Name">
                        <span> {categoryName} </span>
                    </FormItem>
                    {
                        <FormItem {...formItemLayout} label="Birdeye Parent Category">
                            {getFieldDecorator('beParentCategory', {
                                rules: [{ "required": true, "message": 'Please select parent category.' }],
                                initialValue: beParentCategory
                                })(
                                <Select showSearch placeholder="Select parent category" onChange={this.handleSubCategories}>
                                    {
                                        categories.map((d, i) => <Option key={i} value={d.parentCategory.name}>{d.parentCategory.name}</Option>)
                                    }
                                </Select>
                            )}
                        </FormItem>
                    }
                    {
                        <FormItem {...formItemLayout} label="Birdeye Sub Category">
                            {getFieldDecorator('beSubCategory', {
                                rules: [{ "required": true, "message": 'Please select sub category.' }],
                                initialValue: beSubCategory
                                })(
                                <Select showSearch placeholder="Select sub category">
                                    {
                                        subCategories.map((d, i) => <Option key={i} value={d.name}>{d.name}</Option>)
                                    }
                                </Select>
                            )}
                        </FormItem>
                    }
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit">
                            Edit
                        </Button>
                    </FormItem>
                </Form>
            );
        }
    };

    render() {

        const { isLoading, error, userData, location, categories = [], data = []} = this.props;
        const { name } = userData;
        const isActionAccessible = getIsPageActionAccessible(this.pageKey, userData.privileges);
        const unauthorized = error === "unauthorized" || !name || !isActionAccessible;

        if (unauthorized) {
            return <RedirectWrapper location={location} />;
        }

        return (
            <div className="categories-root">
                <h2>Update Category</h2>
                {isLoading || isEmpty(categories) || isEmpty(data) ? (
                    <Spin />
                ) : (
                    this.renderFormBlock()
                )}
            </div>
        );
    }
}

const UpdateCategory = Form.create()(UpdateCategoryPage);
UpdateCategory.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchBirdeyeCategoriesList: PropTypes.func.isRequired,
    updateCategoryRequest: PropTypes.func.isRequired,
    fetchCategoriesListBySource: PropTypes.func.isRequired,
    categories: PropTypes.array.isRequired
};

export default UpdateCategory;

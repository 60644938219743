import { FeatureResourceActions } from "./actions";

const DEFAULT_STATE = {
    isLoading: false,
    availableFeatureResource: {},
    featureResource: {},
    featureResourceByPage: []
};

const featureResource = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FeatureResourceActions.REQ_FEATURE_RESOURCE_BY_PAGE:
        case FeatureResourceActions.REQ_FEATURE_RESOURCE:
        case FeatureResourceActions.REQ_FEATURE_RESOURCE:
        case FeatureResourceActions.REQ_AVAILABLE_FEATURE_RESOURCE:
        case FeatureResourceActions.REQ_UPDATE_FEATURE_RESOURCE:
        case FeatureResourceActions.REQ_UPDATE_FEATURE_RESOURCE_BY_PAGE:
        case FeatureResourceActions.REQ_DELETE_FEATURE_REQUEST_BY_PAGE:
        case FeatureResourceActions.REQ_DELETE_FEATURE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case FeatureResourceActions.SUCCESS_FEATURE_RESOURCE:
        case FeatureResourceActions.ERROR_FEATURE_RESOURCE:
            return {
                ...state,
                isLoading: false,
                featureResource: {...state.featureResource, [action.payload.type]: action.payload.data}
            };
        case FeatureResourceActions.SUCCESS_AVAILABLE_FEATURE_RESOURCE:
        case FeatureResourceActions.ERROR_AVAILABLE_FEATURE_RESOURCE:
            return {
                ...state,
                isLoading: false,
                availableFeatureResource: {...state.availableFeatureResource, [action.payload.type]: action.payload.data}
            };
        case FeatureResourceActions.SUCCESS_UPDATE_FEATURE_RESOURCE:
            return {
                ...state,
                isLoading: false,
                featureResource: {...state.featureResource, [action.payload.type]: action.payload.data}
            };
        case FeatureResourceActions.ERROR_ADD_FEATURE_RESOURCE_BY_PAGE:
        case FeatureResourceActions.ERROR_UPDATE_FEATURE_RESOURCE_BY_PAGE:
        case FeatureResourceActions.ERROR_DELETE_FEATURE_REQUEST_BY_PAGE:
        case FeatureResourceActions.ERROR_ADD_FEATURE_RESOURCE:
        case FeatureResourceActions.ERROR_UPDATE_FEATURE_RESOURCE:
        case FeatureResourceActions.ERROR_DELETE_FEATURE_REQUEST:
            return {
                ...state,
                isLoading: false
            };
        case FeatureResourceActions.SUCCESS_DELETE_FEATURE_REQUEST:
            return {
                ...state,
                isLoading: false,
                featureResource: {
                    ...state.featureResource,
                    [action.payload.type]: state.featureResource.hasOwnProperty(action.payload.type) ? state.featureResource[action.payload.type].filter(({id}) => (id != action.payload.id)) : []
                }
            };
        case FeatureResourceActions.REQ_ADD_FEATURE_RESOURCE_BY_PAGE:
        case FeatureResourceActions.REQ_ADD_FEATURE_RESOURCE:
            return {
                ...state,
                isLoading: true
            };
        case FeatureResourceActions.SUCCESS_ADD_FEATURE_RESOURCE:
            return {
                ...state,
                isLoading: false,
                featureResource: {
                    ...state.featureResource,
                    [action.payload.type]: state.featureResource.hasOwnProperty(action.payload.type) ? [...state.featureResource[action.payload.type], action.payload.data] : [action.payload.data]
                }
            };
        case FeatureResourceActions.SUCCESS_FEATURE_RESOURCE_BY_PAGE:
        case FeatureResourceActions.ERROR_FEATURE_RESOURCE_BY_PAGE:
            return {
                ...state,
                isLoading: false,
                featureResourceByPage: action.payload
            };
        case FeatureResourceActions.SUCCESS_ADD_FEATURE_RESOURCE_BY_PAGE:
            return {
                ...state,
                isLoading: false,
                // featureResourceByPage: state.featureResourceByPage.push(action.payload)
            };
        case FeatureResourceActions.SUCCESS_UPDATE_FEATURE_RESOURCE_BY_PAGE:
            return {
                ...state,
                isLoading: false,
                // featureResourceByPage: state.featureResourceByPage.push(action.payload)
            };
        case FeatureResourceActions.SUCCESS_DELETE_FEATURE_REQUEST_BY_PAGE:
            return {
                ...state,
                isLoading: false,
                featureResourceByPage: state.featureResourceByPage.filter((record) => action.payload.id !== record.id)
            };
        default:
            return state;
    }
};

export default featureResource;
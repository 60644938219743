import { CategoriesActions } from "./actions";

let Categories = {
    categories: [],
    data: [],
    error: "",
    isLoading: false,
    updateSicNaics: [],
    loading: false
};

const categoriesReducer = (state = Categories, action) => {
    switch (action.type) {
        case CategoriesActions.UPDATE_CATEGORY_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case CategoriesActions.FETCH_BIRDEYE_CATEGORY_LIST:
            return {
                ...state,
                categories: action.payload,
                error: "",
                isLoading: true
            };
        case CategoriesActions.FETCH_BIRDEYE_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                categories: action.payload,
                error: "",
                isLoading: false
            };
        case CategoriesActions.FETCH_CATEGORIES_LIST_BY_SOURCE:
            return {
                ...state,
                error: "",
                data: [],
                isLoading: true
            };
        case CategoriesActions.FETCH_CATEGORIES_LIST_BY_SOURCE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: "",
                isLoading: false
            };
        case CategoriesActions.CATEGORIES_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        case CategoriesActions.ADD_BE_SUBCATEGORY:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case CategoriesActions.ADD_BE_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                error: "",
                isLoading: false
            };
        case CategoriesActions.ADD_BE_SUBCATEGORY_ERROR:
            return {
                ...state,
                error: "",
                isLoading: false
            };
        case CategoriesActions.UPDATE_SIC_NAICS:
        case CategoriesActions.ADD_NEW_CATEGORY:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case CategoriesActions.UPDATE_SIC_NAICS_SUCCESS:
            return {
                ...state,
                error: "",
                isLoading: false
            };
        case CategoriesActions.UPDATE_SIC_NAICS_ERROR:
            return {
                ...state,
                error: "",
                isLoading: false
            };
        case CategoriesActions.ADD_NEW_CATEGORY_SUCCESS:
            return {
                ...state,
                error: "",
                isLoading: false
            };
        case CategoriesActions.ADD_NEW_CATEGORY_ERROR:
            return {
                ...state,
                error: "",
                isLoading: false
            };

        case CategoriesActions.ADD_INDUSTRY:
            return {
                ...state,
                error: "",
                loading: true
            };
        case CategoriesActions.ADD_INDUSTRY_SUCCESS:
            return {
                ...state,
                error: "",
                isCreated: true,
                loading: false
            };
        case CategoriesActions.ADD_INDUSTRY_ERROR:
            return {
                ...state,
                error: "",
                isCreated: false,
                loading: false
            };
        default:
            return state;
    }
};

export default categoriesReducer;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Spin, message } from "antd";

import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";
import { getIsPageActionAccessible } from "../../utils";
import lookup from "./permissions.json";

class Permissions extends Component {

    pageKey = "userPermissionList";

    componentDidMount() {
        this.props.fetchPermissionsList();
    }

    formatData = (data) => {
        return data.map((record) => {
            return {
                ...record,
                key: record.id
            };
        });
    };

    handleDelete = (data) => {
        data && data.id && this.props.deletePermissionRequest({data: {...data}, cb: this.renderFeedbackMsg});
    }

    renderFeedbackMsg = (errorMsg) => {
        if (!errorMsg) {
            return message.success(
                "You have successfully deleted this record."
            );
        } else {
            return message.error(
                "Something went wrong... Please try again later."
            );
        }
    };

    render() {
        const { permissionsList, loadingPermission, userError, userData } = this.props;
        const unauthorized = userError === "unauthorized";
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        const isActionAccessible = getIsPageActionAccessible(this.pageKey, userData.privileges);

        return (
            <div className="users-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    columns={lookup.columns}
                    pathForEdit={isActionAccessible ? "/permission/" : ""}
                    handleDelete={isActionAccessible && this.handleDelete}
                    dataSource={this.formatData(permissionsList)}
                    loading={loadingPermission}
                />
            </div>
        );
    }
}

Permissions.propTypes = {
    permissionsList: PropTypes.array.isRequired,
    loadingPermission: PropTypes.bool.isRequired,
    userError: PropTypes.string.isRequired,
    fetchPermissionsList: PropTypes.func.isRequired,
    deletePermissionRequest: PropTypes.func.isRequired,
};

export default Permissions;
import { findIndex } from "lodash";
import { IntegrationsActions } from "./actions";

let Integrations = {
    data: [],
    curData: {},
    error: "",
    isLoading: false
};

const integrationsReducer = (state = Integrations, action) => {
    let index;
    switch (action.type) {
        case IntegrationsActions.FETCH_INTEGRATIONS_LIST_REQUEST:
        case IntegrationsActions.FETCH_INTEGRATION_REQUEST:
        case IntegrationsActions.ADD_INTEGRATION_REQUEST:
        case IntegrationsActions.UPDATE_INTEGRATION_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case IntegrationsActions.FETCH_INTEGRATIONS_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case IntegrationsActions.FETCH_INTEGRATION_SUCCESS:
            return {
                ...state,
                curData: action.payload,
                isLoading: false
            };
        case IntegrationsActions.ADD_INTEGRATION_SUCCESS:
            return {
                ...state,
                data: [...state.data, action.payload],
                curData: action.payload,
                isLoading: false
            };
        case IntegrationsActions.UPDATE_INTEGRATION_SUCCESS:
            index = findIndex(state.data, ["id", action.payload.id]);
            return {
                ...state,
                data: [
                    ...state.data.slice(0, index),
                    action.payload,
                    ...state.data.slice(index + 1)
                ],
                curData: action.payload,
                isLoading: false
            };
        case IntegrationsActions.DELETE_INTEGRATION_SUCCESS:
            index = findIndex(state.data, ["id", action.payload.id]);
            return {
                ...state,
                data: [
                    ...state.data.slice(0, index),
                    ...state.data.slice(index + 1)
                ],
                error: ""
            };
        case IntegrationsActions.INTEGRATION_ERROR:
            return {
                ...state,
                data: [],
                error: action.payload.error,
                isLoading: false
            };
        case IntegrationsActions.DOWNLOAD_INTEGRATION_CSV_REQ:
            return {
                ...state,
                error: ""
            };
        case IntegrationsActions.DOWNLOAD_INTEGRATION_CSV_SUCCESS:
            return {
                ...state,
                error: ""
            };
        default:
            return state;
    }
};

export default integrationsReducer;

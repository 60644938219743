import React, { Component } from "react";
import PropTypes from "prop-types";
import { pick, includes } from "lodash";
import moment from "moment";
import { Form, Input, Button, Alert, Spin, Modal, DatePicker, Select, Divider, Upload } from "antd";

import { TextEditor, MultiResolutionImage, RedirectWrapper } from "../../components";

import { getSlug, uploadUtility } from "../../utils";
import { formItemLayout, formButtonLayout, dateFormat, genericWebinarOGImagePath } from "../../constants";
import lookup from "./presses.json";

const FormItem = Form.Item;

class PressForm extends Component {
    initialData = {
        id: 0,
        pressLink: "",
        title: "",
        releasedDate: moment(),
        imagePath: "",
        releaseData: "",
        intro: "",
        underImage: "",
        quoteHTML: "",
        contentHTML: "",
        footerHTML: "",
        slugTitle: "",
        imagePath2x: "",
        imagePath3x: "",
        type: "",
        ogImage: genericWebinarOGImagePath,
    }

    state = {
        data: this.initialData,
        formError: "",
        isEditPage: !!this.props.match.params.id,
        currentPage: ""
    };

    componentDidMount() {
        const { location, match, fetchPressRequest } = this.props;
        if (location.state && location.state.data) {
            this.setState({ data: this.mapDataToForm(location.state.data) });
        } else if (match.params.id) {
            fetchPressRequest({ id: match.params.id });
        }
        this.timestamp = Date.now().toString();
    }

    componentDidUpdate(prevProps) {
        if (this.props.curData.id !== prevProps.curData.id) {
            this.setState({ data: this.mapDataToForm(this.props.curData) });
        }
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                isEditPage: !!this.props.match.params.id,
                data: this.initialData
             });
        }
    }

    mapDataToForm = (rawData) => {
        let aggData = pick(rawData, ["id", ...lookup.valueLookup.aggFields]);
        return {
            ...aggData,
            ...rawData.detail,
            title: rawData.linkTitle,
            slugTitle: getSlug(rawData.linkTitle),
            imagePath2x: this.getSiblingImagePath(rawData.imagePath, "2x"),
            imagePath3x: this.getSiblingImagePath(rawData.imagePath, "3x"),
            releasedDate: moment(rawData.releasedDate),
            ogImage: !!rawData.ogImage ? rawData.ogImage : genericWebinarOGImagePath,
        };
    };

    getImageUploadUrl = (name, pd) => {
        const pdQuery = pd ? `&pd=${pd}` : "";
        return `${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=${name}&timestamp=${this.timestamp}${pdQuery}&webp=true`;
    }

    getSiblingImagePath = (imagePath, pdSuffix) => {
        let imageUrlParts = imagePath.split(".");
        const ext = imageUrlParts.pop();
        return `${imageUrlParts.join(".")}@${pdSuffix}.${ext}`;
    }

    handleSelectChange = (value) => {
        this.handleFieldChange("type", value);
    };

    handleInputChange = (e, imageFieldName) => {
        if (e.target) {
            const { name, value } = e.target;
            this.handleFieldChange(name, value);
        } else if (e.file && e.file.response) {
            const { imageUrl } = e.file.response;
            this.handleFieldChange(imageFieldName, imageUrl);
        }
    };

    handleDateChange = (date, fieldName) => {
        this.handleFieldChange(fieldName, date.format(dateFormat));
    };

    handleEditorChange = (html, fieldName) => {
        this.handleFieldChange(fieldName, html);
    }

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        if (name === "title" && !this.state.isEditPage) {
            data.slugTitle = getSlug(value);
        }
        this.setState({ data, formError: "" });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const rawData = { ...this.state.data };

        if ( !rawData.type ) {
            this.setState({ formError: "Please select a type of this press release" });
            return;
        }

        if ( includes(pick(rawData, lookup.valueLookup.requiredFields[rawData.type]), "") ) {
            this.setState({ formError: "Please fill in all relevant fields!" });
            return;
        }

        let data = { ...pick(rawData, lookup.valueLookup.aggFields), linkTitle: rawData.title };

        if (rawData.type === "announcement") {
            data = Object.assign(data, {
                internalPath: `/press/${rawData.slugTitle}/`,
                detail: {
                    ...pick(rawData, lookup.valueLookup.detailFields),
                    title: rawData.title
                }
            });
        }

        this.setState({currentPage: rawData.type.toLowerCase()});
        
        if (this.state.isEditPage) {
            data.id = this.state.data.id;
            this.props.updatePressRequest({ data, cb: this.renderFeedback });
        } else {
            this.props.addPressRequest({ data, cb: this.renderFeedback });
        }
    };

    renderFeedback = (errorType) => {
        const goToPressesPage = () => this.props.history.push(`/presses-${this.state.currentPage}`);
        if (!errorType) {
            return Modal.success({
                title: `You have successfully ${
                    this.state.isEditPage ? "edited" : "added"
                } a record`,
                content: `Check the ${
                    this.state.isEditPage ? "updated" : "new"
                } record in press list page`,
                okText: "Go to press page",
                maskClosable: false,
                onOk() {
                    goToPressesPage();
                }
            });
        } else {
            return Modal.warning({
                title: `The record was not ${
                    this.state.isEditPage ? "edited" : "added"
                } successfully...`,
                content: "Please check your input or try again later..."
            });
        }
    };

    renderFormBlock = () => {
        const {
            pressLink,
            title,
            releasedDate,
            imagePath,
            releaseData,
            intro,
            underImage,
            quoteHTML,
            contentHTML,
            footerHTML,
            slugTitle,
            imagePath2x,
            imagePath3x,
            type,
            ogImage,
        } = this.state.data;
        const { getFieldDecorator } = this.props.form;

        if (this.state.isEditPage && this.props.error === "pressNotFound") {
            return <Alert message="Press not found" type="error" showIcon />;
        } else {
            return (
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Type">
                        <Select
                            name="type"
                            value={type || undefined}
                            placeholder="Select type"
                            onChange={this.handleSelectChange}
                        >
                            {
                                lookup.pressType.map((type) => (
                                    <Select.Option key={type.value}>{type.text}</Select.Option>
                                ))
                            }
                        </Select>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Title">
                        <Input
                            name="title"
                            value={title}
                            placeholder="Full title of the article"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Link">
                        <Input
                            name="pressLink"
                            value={pressLink}
                            placeholder="Link to the original PR web article"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Release Date">
                        <DatePicker
                            placeholder="Select release date"
                            allowClear={false}
                            value={moment(releasedDate, dateFormat)}
                            format={dateFormat}
                            onChange={(date) => this.handleDateChange(date, "releasedDate")} />
                    </FormItem>
                    <MultiResolutionImage
                        formItemLayout = {formItemLayout}
                        handleInputChange = {this.handleInputChange}
                        imageFieldName = "imagePath"
                        uploadName={slugTitle}
                        imagePath={imagePath}
                        imagePath2x={imagePath2x}
                        imagePath3x={imagePath3x}
                        disabled={!slugTitle}
                        timestamp={this.timestamp}
                    />
                    <FormItem {...formItemLayout} label={"og:image"} extra={<p>Recommended resolution <strong>1200 x 630 pixels</strong>, which is an approximate aspect ratio of <strong>1.91:1</strong>.</p>}>
                        {
                            getFieldDecorator("ogImage", {
                                getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                rules: [{
                                    required: true,
                                    message: "Upload an og:image"
                                }, {
                                    validator: uploadUtility.singleFile.validator
                                }],
                                initialValue: [{
                                    uid: "-1",
                                    name: `og:image ${title}`,
                                    status: "done",
                                    url: ogImage ? ogImage : genericWebinarOGImagePath
                                }],
                                valuePropName: "fileList"
                            })(
                                <Upload
                                    name={"files"}
                                    accept={"image/*"}
                                    action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=press-og-image&timestamp=${Date.now().toString()}&webp=true`}
                                    withCredentials
                                    listType="picture"
                                    onChange={(e) => this.handleInputChange(e, "ogImage")}
                                    >
                                    <Button icon={"upload"} disabled={!slugTitle}>og:image</Button>
                                </Upload>
                            )
                        }
                    </FormItem>
                    {
                        type === "announcement" && (
                            <React.Fragment>
                                <Divider orientation="left">Detailed Page Content</Divider>
                                <FormItem {...formItemLayout} label="Release Data">
                                    <Input
                                        name="releaseData"
                                        value={releaseData}
                                        placeholder="Meta data for the PR release"
                                        onChange={this.handleInputChange}
                                    />
                                </FormItem>
                                <FormItem {...formItemLayout} label="Image description">
                                    <Input
                                        name="underImage"
                                        value={underImage}
                                        placeholder="Short description that appears under the image"
                                        onChange={this.handleInputChange}
                                    />
                                </FormItem>
                                <FormItem {...formItemLayout} label="Short Summary">
                                    <Input
                                        name="intro"
                                        value={intro}
                                        placeholder="Short summary of the press article"
                                        onChange={this.handleInputChange}
                                    />
                                </FormItem>
                                <FormItem {...formItemLayout} label="Quotation HTML">
                                    <TextEditor
                                        name="quoteHTML"
                                        value={quoteHTML}
                                        handleEditorChange={(html) => this.handleEditorChange(html, "quoteHTML")}
                                    />
                                </FormItem>
                                <FormItem {...formItemLayout} label="Content HTML">
                                    <TextEditor
                                        name="contentHTML"
                                        value={contentHTML}
                                        handleEditorChange={(html) => this.handleEditorChange(html, "contentHTML")}
                                    />
                                </FormItem>
                                <FormItem {...formItemLayout} label="Footer HTML">
                                    <TextEditor
                                        name="footerHTML"
                                        value={footerHTML}
                                        handleEditorChange={(html) => this.handleEditorChange(html, "footerHTML")}
                                    />
                                </FormItem>
                            </React.Fragment>
                        )
                    }
                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit" block>
                            {this.state.isEditPage ? "Save" : "Create"}
                        </Button>
                    </FormItem>
                </Form>
            );
        }
    };

    render() {
        const { isLoading, error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="presses-root">
                <h2>{this.state.isEditPage ? "Edit" : "Add New"} Press</h2>
                {this.state.isEditPage && isLoading ? (
                    <Spin />
                ) : (
                    this.renderFormBlock()
                )}
            </div>
        );
    }
}

const UpdatePress = Form.create()(PressForm);

UpdatePress.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    curData: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchPressRequest: PropTypes.func.isRequired,
    updatePressRequest: PropTypes.func.isRequired,
    addPressRequest: PropTypes.func.isRequired
};

export default UpdatePress;

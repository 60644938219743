import React, { Component } from "react";
import PropTypes from "prop-types";
import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";
import { beAPIResource } from "../../utils/apiHelper";
import moment from "moment";
import { DatePicker, Input } from 'antd';
const { Search } = Input;
import "./SitemapsList.scss";

const columns = [
    {
        "title": "Domain",
        "key": "domain",
        "searchable": true,
        render: value => (<a href={'https://'+value} target="_blank">{value}</a>),
        "width": "19%"
    },
    {
        "title": "File name",
        "key": "file_name",
        render: value => (<a href={value} target="_blank">{value.split('/')[3]}</a>),
        "width": "13.5%"
    },
    {
        "title": "Temp",
        "key": "temp_created",
        render: value => value && moment(value).format('YYYY-MM-DD'),
        "width": "10%"
    },
    {
        "title": "Temp error",
        "key": "temp_error",
        "width": "12.5%"
    },
    {
        "title": "S3",
        "key": "upload_to_s3",
        render: value => value && moment(value).format('YYYY-MM-DD'),
        "width": "10%"
    },
    {
        "title": "S3 Error",
        "key": "s3_error",
        "width": "12.5%"
    },
    {
        "title": "Invalidation",
        "key": "invalidation",
        render: value => value && moment(value).format('YYYY-MM-DD'),
        "width": "10%"
    },
    {
        "title": "Invalidation error",
        "key": "invalidation_error",
        "width": "12.5%"
    }
];

class SitemapsList extends Component {

    pageKey="sitemapsList";
    state = {
        sitemaps: [],
        selectedDate: moment(new Date()).format('YYYY-MM-DD'),
        loading: false
    };

    async componentDidMount() {
        try {
            this.setState({ loading: true });
            const response = await beAPIResource.get(`support/sitemap/getSitemapReport?date=${this.state.selectedDate}`);
            response.data.length && this.setState({ sitemaps: response.data });
            this.setState({ loading: false });
        } catch (error) {
            console.error("Error while fetching sitemap report", error);
            this.setState({ loading: false });
        }
    }

    handleDateChange = async(date) => {
        if(!date) return;
        try {
            this.setState({ loading: true });
            const selectedDate = date.format('YYYY-MM-DD');
            const response = await beAPIResource.get(`support/sitemap/getSitemapReport?date=${selectedDate}`);
            this.setState({ selectedDate, sitemaps: response.data, loading: false });
        } catch (error) {
            console.error("Error while fetching sitemap report", error);
            this.setState({ loading: false });
        }
    }

    handleBusinessSearch = async(slug) => {
        if(!slug) return;
        try {
            this.setState({ loading: true });
            const response = await beAPIResource.get(`support/sitemap/getBusinessLookup?slug=${slug.trim()}`);
            this.setState({ sitemaps: response.data, loading: false });
        } catch (error) {
            console.error("Error while fetching sitemap report", error);
            this.setState({ loading: false });
        }
    }
    
    render() {
        const { error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div>
                <PageHeaderContainer pageKey={this.pageKey} />
                <Search className="businessSearchBar" placeholder="Search a business (no limitation on date)" onSearch={this.handleBusinessSearch} enterButton />
                <div className="datePicker">
                    <DatePicker defaultValue={moment(new Date())} onChange={this.handleDateChange} disabledDate={today => today > moment().endOf('day')}/>
                </div>
                <TableSkeleton
                    columns={columns}
                    dataSource={this.state.sitemaps}
                    loading={this.state.loading}
                />
            </div>
        );
    }
}

SitemapsList.propTypes = {
    userData: PropTypes.object
};

export default SitemapsList;
import { beAPIResource } from "../../utils/apiHelper";

const signatureAPI = {
    userUpdate: (data) => beAPIResource.put("/support/signature/user", data),
    usersUpdate: (data) => beAPIResource.put("/support/signature/users", data),
    getAsset: (data) => beAPIResource.get(`/support/signature/asset?type=${data.type}&template=${data.template}`),
    createAsset: (data) => beAPIResource.post("/support/signature/asset", data),
    deleteAsset: (data) => beAPIResource.delete(`/support/signature/asset?type=${data.type}&template=${data.template}&id=${data.id}`),
    getCalendar: (data) => beAPIResource.get(`/support/page/email?email=${data.email}`),
    getUserById: (data) => beAPIResource.get(`/users/${data.id}`),
};

export default signatureAPI;

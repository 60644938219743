import { takeLatest, call, put } from "redux-saga/effects";
import WebinarActions, * as actionCreator from "./actions";
import WebinarAPI from "./http";

const webinarToolSaga = [
    takeLatest(WebinarActions.REQ_WEBINAR_SCHEDULE, reqWebinarSchedule),
    takeLatest(WebinarActions.REQ_WEBINAR_EVENTS, reqWebinarEvents),
    takeLatest(WebinarActions.REQ_WEBINAR_DATA, reqWebinarEvent),
    takeLatest(WebinarActions.REQ_WEBINARE_PRESENTERS, reqWebinarPresenters),
    takeLatest(WebinarActions.REQ_WEBINAR_EVENT_DELETE, reqWebinarEventDelete),
    takeLatest(WebinarActions.REQ_WEBINAR_REGISTRANTS, reqWebinarRegistrants),
    takeLatest(WebinarActions.REQ_WEBINAR_ATTENDEES, reqWebinarAttendees),
    takeLatest(WebinarActions.REQ_WEBINAR_PRESENTER_ADD, reqWebinarPresenterAdd),
    takeLatest(WebinarActions.REQ_WEBINAR_PRESENTER_UPDATE, reqWebinarPresenterUpdate),
    takeLatest(WebinarActions.REQ_WEBINAR_PRESENTER_DELETE, reqWebinarPresenterDelete),
    takeLatest(WebinarActions.GET_LANDING_PAGE_WEBINAR, getLandingPageWebinar),
    takeLatest(WebinarActions.UPDATE_LANDING_PAGE_WEBINAR, updateLandingPageWebinar),
    takeLatest(WebinarActions.GET_WEBINAR_RECORDING_ASSETS, getWebinarRecordingAssets),
    takeLatest(WebinarActions.GET_WEBINAR_UPCOMING_SESSION, getWebinarUpcomingSession),
    takeLatest(WebinarActions.REFRESH_WEBINAR_ATTENDANCE, refreshWebinarAttendance),
    takeLatest(WebinarActions.REQ_MEETING_SCHEDULE, reqMeetingSchedule),
    takeLatest(WebinarActions.REQ_MEETING_DELETE, reqMeetingDelete),
];

function* reqWebinarSchedule(action) {
    try {
        const response =  yield call(WebinarAPI.reqWebinarSchedule, action.payload);
        yield put(actionCreator.respWebinarScheduleSuccess(response.data.data));
    } catch (e) {
        yield put(actionCreator.respWebinarScheduleError((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqWebinarEvents(action) {
    try {
        const response =  yield call(WebinarAPI.reqWebinarEvents, action.payload);
        yield put(actionCreator.respWebinarEventsSuccess({eventsType: action.payload.eventsType, data: response.data}));
    } catch (e) {
        yield put(actionCreator.respWebinarEventsError((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqWebinarEvent({payload}) {
    try {
        const {data} =  yield call(WebinarAPI.reqWebinarEvent, payload);
        yield put(actionCreator.respWebinarDataSuccess(data));
    } catch (e) {
        yield put(actionCreator.respWebinarDataError((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqWebinarPresenters({payload}) {
    try {
        const {data} =  yield call(WebinarAPI.reqWebinarPresenters, payload);
        yield put(actionCreator.respWebinarPresentersSuccess(data));
    } catch (e) {
        yield put(actionCreator.respWebinarPresentersError((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqWebinarEventDelete({payload}) {
    try {
        const {data} =  yield call(WebinarAPI.reqWebinarEventDelete, payload);
        yield put(actionCreator.respWebinarEventDeleteSuccess(data));
        yield put(actionCreator.reqWebinarEvents({eventsType: "upcoming"}));
    } catch (e) {
        yield put(actionCreator.respWebinarEventDeleteError((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqWebinarRegistrants(action) {
    try {
        const {data} =  yield call(WebinarAPI.reqWebinarRegistrants, action.payload);
        yield put(actionCreator.respWebinarRegistrantsSuccess(data));
    } catch (e) {
        yield put(actionCreator.respWebinarRegistrantsError((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqWebinarAttendees(action) {
    try {
        const {data} =  yield call(WebinarAPI.reqWebinarAttendees, action.payload);
        yield put(actionCreator.respWebinarAttendeesSuccess(data));
    } catch (e) {
        yield put(actionCreator.respWebinarAttendeesError((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqWebinarPresenterAdd({payload, pageType}) {
    try {
        const {data={}} =  yield call(WebinarAPI.reqWebinarPresenterAdd, payload, pageType);
        yield put(actionCreator.respWebinarPresenterAddSuccess(data.message));
        yield put(actionCreator.reqWebinarPresenters({ type: pageType }));
    } catch (e) {
        yield put(actionCreator.respWebinarPresenterAddError((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqWebinarPresenterUpdate({payload, pageType}) {
    try {
        const {data={}} =  yield call(WebinarAPI.reqWebinarPresenterUpdate, payload);
        yield put(actionCreator.respWebinarPresenterUpdateSuccess(data.message));
        yield put(actionCreator.reqWebinarPresenters({ type: pageType }));
    } catch (e) {
        yield put(actionCreator.respWebinarPresenterUpdateError((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqWebinarPresenterDelete({payload, pageType}) {
    try {
        const {data={}} =  yield call(WebinarAPI.reqWebinarPresenterDelete, payload);
        yield put(actionCreator.respWebinarPresenterDeleteSuccess(data.message));
        yield put(actionCreator.reqWebinarPresenters({ type: pageType }));
    } catch (e) {
        yield put(actionCreator.respWebinarPresenterDeleteError((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* getLandingPageWebinar() {
    try {
        const {data=[]} =  yield call(WebinarAPI.getLandingPageWebinar);
        yield put(actionCreator.getLandingPageWebinarSuccess(data));
    } catch (e) {
       yield put(actionCreator.getLandingPageWebinarError((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* updateLandingPageWebinar({payload}) {
    try {
        const {data={}} =  yield call(WebinarAPI.updateLandingPageWebinar, payload);
        yield put(actionCreator.updateLandingPageWebinarSuccess(data.message));
        yield call(payload.cb);
    } catch (e) {
       yield put(actionCreator.updateLandingPageWebinarError((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
       yield call(payload.cb, "updateLandingPageWebinarError");
    }
}

function* getWebinarRecordingAssets() {
    try {
        const {data=[]} =  yield call(WebinarAPI.getWebinarRecordingAssets);
        yield put(actionCreator.getWebinarRecordingAssetsSuccess(data));
    } catch (e) {
       yield put(actionCreator.getWebinarRecordingAssetsError([]));
    }
}

function* getWebinarUpcomingSession({payload}) {
    try {
        const {data=[]} =  yield call(WebinarAPI.getWebinarUpcomingSession, payload);
        yield put(actionCreator.getWebinarUpcomingSessionSuccess(data));
    } catch (e) {
       yield put(actionCreator.getWebinarUpcomingSessionError([]));
    }
}

function* refreshWebinarAttendance(action) {
    try {
        yield call(WebinarAPI.refreshWebinarAttendance, action.payload);
        yield put(actionCreator.reqWebinarEvents({eventsType: "past"}));
        yield put(actionCreator.refreshWebinarAttendanceSuccess({ type: "success", message: "Attendance report has been successfully refreshed." }));
        yield put(actionCreator.reqWebinarAttendees(action.payload));
    } catch (e) {
        yield put(actionCreator.refreshWebinarAttendanceError({ type: "error", message: "Oops! Something went wrong while refreshing the attendance report." }));
    }
}

function* reqMeetingSchedule(action) {
    try {
        const response =  yield call(WebinarAPI.reqMeetingSchedule, action.payload);
        yield put(actionCreator.respMeetingScheduleSuccess(response.data.data));
    } catch (e) {
        yield put(actionCreator.respMeetingScheduleError((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

function* reqMeetingDelete({payload}) {
    try {
        const {data} =  yield call(WebinarAPI.reqMeetingDelete, payload);
        yield put(actionCreator.respMeetingDeleteSuccess(data));
        yield put(actionCreator.reqWebinarEvents({eventsType: "upcoming"}));
    } catch (e) {
        yield put(actionCreator.respMeetingDeleteError((e.data && e.data.data && e.data.data.message) || "Failed to process request. Please retry."));
    }
}

export default webinarToolSaga;
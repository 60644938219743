import { takeLatest, call, put } from "redux-saga/effects";
import ReviewActions, * as actionCreator from "./actions";
import reviewApi from "./https";

const reviewSagas = [
    takeLatest(ReviewActions.FETCH_REVIEWS_BY_SOURCE, fetchReviewBySource),
    takeLatest(ReviewActions.DELETE_REVIEW_BY_REVIEWID, deleteReviewByReviewId),
    takeLatest(ReviewActions.FETCH_SOURCES_FROM_DATABASE, fetchSourcesFromDatabase),
    takeLatest(ReviewActions.POST_REVIEW_RESPONSE, postReviewResponse)
];

function* fetchSourcesFromDatabase(action) {
    try {
        const resp = yield call(reviewApi.fetchSourcesFromDatabase);
        yield put(actionCreator.fetchSourcesFromDatabaseSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.fetchSourcesFromDatabaseError(e.data.data || { error: "fetchSourcesFromDatabaseError" })
        );
    }
}

function* fetchReviewBySource(action) {
    try {
        const resp = yield call(reviewApi.getBysource, action.payload);
        yield put(actionCreator.fetchReviewBySourceSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.fetchReviewBySourceError(e.data.data || { error: "fetchReviewsBySourceError" })
        );
    }
}

function* deleteReviewByReviewId(action) {
    try {
        const resp = yield call(reviewApi.deleteByreviewId, action.payload.data);
        yield put(actionCreator.deleteReviewByReviewIdSuccess(resp));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(
            actionCreator.deleteReviewByReviewIdError(e.data.data || { error: "deleteReviewsByReviewIdError" })
        );
        yield call(action.payload.cb, "cannotDeleteReview");
    }
}

function* postReviewResponse(action) {
    try {
        const resp = yield call(reviewApi.postReviewResponse, action.payload.data);
        yield put(actionCreator.postReviewResponseSuccess(resp));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(
            actionCreator.postReviewResponseError(e.data.data || { error: "postReviewResponseError" })
        );
        yield call(action.payload.cb, e);
    }
}

export default reviewSagas;

import React, { Component } from "react";
import { TableSkeleton } from "../../components";
import { Button, Col, Tooltip } from 'antd';
import lookup from "./constants";
class History extends Component{

    constructor(props){
        super(props);

    }

    handleClick = (e, data) => {
        e.preventDefault();
        if(data.onlineListingBusinessUrl) {
            window.open(data.onlineListingBusinessUrl, "_blank");
        }
    }

    formatData = (data) => {
        return data.map((record) => {
            return {
                ...record,
                key: record.id,
                lastScanDate: record.createdAt ?  new Date(record.createdAt).toDateString() : "-",
                businessAddress: record.address ? record.address : "-",
                phoneNumber: record.phone ? record.phone : "-",
                category: record.industryKeyword ? record.industryKeyword : "-",
                score: record.businessScore ? record.businessScore : "-",
                action: (
                    <>
                        <Col  span ={10}>
                            <Tooltip placement="top" title={"Download business pdf"}>
                                <Button disabled={!record.businessPdf} href={record.businessPdf && record.businessPdf} target="_blank" size ="small" shape="round" icon="download" type="secondary"/>
                            </Tooltip>
                        </Col>
                        <Col span ={2}>
                            <Button size ="small" disabled={!record.onlineListingBusinessUrl} shape="round" onClick={ ( e ) => this.handleClick(e, record) } type="primary">Scan Again</Button>
                        </Col>
                    </>
                )
            };
        });
    }

    render() {
        const {scanToolHistory, isLoading } = this.props;

        return (
            <div>
                <h2>Scan history</h2>
                <TableSkeleton
                    columns={lookup.historyTabColumns}
                    dataSource={this.formatData(scanToolHistory)}
                    loading={isLoading}
                />
            </div>
        )
    }
}

export default History; 
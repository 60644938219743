import { BusinessActions } from "./actions";

let Business = {
    data: [],
    info:{},
    users:[],
    aggregations:[],
    sources: [],
    states:{},
    cities:{},
    countries:{},
    error: "",
    isLoading: false,
    googleData: {},
    isStateChange: false,
    findOnGoogle: false,
    reaggregationResponse: []
};

const businessReducer = (state = Business, action) => {

    switch (action.type) {
        case BusinessActions.FETCH_BUSINESS_BY_BUSINESS_NUMBER:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.FETCH_BUSINESS_BY_BUSINESS_NUMBER_SUCCESS:
            return {
                ...state,
                info: action.payload,
                error: "",
                isLoading: false
            };
        case BusinessActions.FETCH_USERS_BY_BUSINESS_NUMBER:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.FETCH_USERS_BY_BUSINESS_NUMBER_SUCCESS:
            return {
                ...state,
                users: action.payload,
                error: "",
                isLoading: false
            };
        case BusinessActions.FETCH_AGGREGATION_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.FETCH_AGGREGATION_SUCCESS:
            return {
                ...state,
                aggregations: action.payload,
                error: "",
                isLoading: false
            };
        case BusinessActions.FIND_BUSINESS_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.FIND_BUSINESS_SUCCESS:
            return {
                ...state,
                error: "",
                isLoading: false
            };
        case BusinessActions.UPDATE_BUSINESS_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.UPDATE_BUSINESS_SUCCESS:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.ADD_USER_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.ADD_USER_SUCCESS:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.DELETE_USER_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.DELETE_USER_SUCCESS:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.ADD_AGGREGATION_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.ADD_AGGREGATION_SUCCESS:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.DELETE_AGGREGATION_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.DELETE_AGGREGATION_SUCCESS:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.FETCH_STATES:
            return {
                ...state,
                error: "",
                isLoading: true,
                isStateChange: true,
                cities: {}
            };
        case BusinessActions.FETCH_STATES_SUCCESS:
            return {
                ...state,
                states: action.payload,
                error: "",
                isLoading: false,
                isStateChange: false
            };
        case BusinessActions.FETCH_AGGREGATION_SOURCES:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.FETCH_AGGREGATION_SOURCES_SUCCESS:
            return {
                ...state,
                sources: action.payload,
                error: "",
                isLoading: false
            };
        case BusinessActions.FETCH_COUNTRIES:
            return {
                ...state,
                error: "",
                isLoading: true,
                loading: true
            };
        case BusinessActions.FETCH_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: action.payload,
                error: "",
                isLoading: false,
                loading: false
            };
        case BusinessActions.BUSINESS_ERROR:
            return {
                ...state,
                data: [],
                error: action.payload,
                isLoading: false
            };
        case BusinessActions.BUSINESS_SIGNUP_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true,
                data: action.payload
            };
        case BusinessActions.BUSINESS_SIGNUP_SUCCESS:
            return {
                ...state,
                error: "",
                isLoading: false,
                data: action.payload,
                isCreated: true,
            };
        case BusinessActions.FETCH_BUSINESS_WITH_GOOGLE:
            return {
                ...state,
                error: "",
                isLoading: true,
                findOnGoogle: true
            };
        case BusinessActions.FETCH_BUSINESS_WITH_GOOGLE_SUCCESS:
            return {
                ...state,
                info: action.payload,
                googleData: action.payload.googleNewData,
                error: "",
                isLoading: false
            };
        case BusinessActions.FETCH_BUSINESS_BY_EMAIL:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.FETCH_BUSINESS_BY_EMAIL_SUCCESS:
            return {
                ...state,
                error: "",
                info: action.payload
            };
        case BusinessActions.FETCH_CITIES:
            return {
                ...state,
                error: "",
                isStateChange: true
            };
        case BusinessActions.FETCH_CITIES_SUCCESS:
            return {
                ...state,
                cities: action.payload,
                error: "",
                isStateChange: false
            };
        case BusinessActions.REAGGREGATION_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case BusinessActions.REAGGREGATION_SUCCESS:
            return {
                ...state,
                error: "",
                reaggregationResponse: action.payload.data,
                isLoading: false
            };
        default:
            return state;
    }
};

export default businessReducer;
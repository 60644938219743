import React, {Component} from "react";
import PropTypes from "prop-types";
import { Steps, Button, Modal } from "antd";
import { isEmpty } from "lodash";

import lookup from "./constants";
import "./index.scss";

const { Step } = Steps;

class ResourceSteps extends Component {
    constructor(props) {
        super(props);

        const { type } =  props;
        this.state = {
            currentStep: 0,
            values: {...(props.initialValues || {})}
        };

        // Class members
        this.steps = lookup.steps.hasOwnProperty(type) ? [...lookup.steps[type]] : [];

        // It will check for the past webinar events
        if ((type === "webinar" || type === "meeting") && props.additional.isFutureDatedEvent === false && props.additional.eventExperience !== "recurring" && props.additional.eventExperience !== "simulive") {
            // const startDate = !isEmpty(props.initialValues.webinar.startDate) ? props.initialValues.webinar.startDate : null;
            this.steps.shift();
        }
        
    }

    handleStepFormSubmission = (values) => {
        const {values: oldValues, currentStep} = this.state;
        this.setState({values: {...oldValues, ...values }, currentStep: ((currentStep + 1) % (this.steps.length))});
    };

    handleStepsOnChange = (currentStep) => {
        this.setState({ currentStep });
    };

    handleSaveBtnClick = (e) => {
        e.preventDefault();

        // Collecting all the dataKeys of the steps.
        // Additionally we may add required/optional steps.
        const dataKeys = this.steps.map(({dataKey}) => (dataKey));
        const submittedValues = this.state.values;
        let missingDataKeys = [];

        for(const dataKey of dataKeys) {
            if (!submittedValues.hasOwnProperty(dataKey)) {
                missingDataKeys.push(dataKey);
            }
        }

        if (missingDataKeys.length) {
            const missingSteps = this.steps.filter(({dataKey}) => (missingDataKeys.indexOf(dataKey) !== -1));
            // Missing few steps, required to show an alert
            return Modal.error({
                title: "Oops! Few of the steps are missing...",
                content: (
                    <>
                        <p>It seems like you have skipped following steps:</p>
                        <ul>{missingSteps.map(({title}, index) => (<li key={index}>{title}</li>))}</ul>
                    </>
                ),
            });
        }

        this.props.handleStepsSubmission(submittedValues);
    };

    render() {
        const { currentStep, values } = this.state;
        const { type, title, userData, initialValues, additional, handleStepsSubmission } = this.props;
        const StepComp = this.steps[currentStep].content;
        const totalSteps = this.steps.length;

        return (
            <div className={"resourcesSteps"}>
                <h2>
                    {title} 
                    <Button type={"primary"} style={{float: "right", display: "none"}} onClick={this.handleSaveBtnClick}>Save + Launch</Button>
                </h2>
                <Steps size={"small"} type={"navigation"} current={currentStep} onChange={this.handleStepsOnChange} status={"wait"}>
                    {
                        this.steps.map((step, index) => (<Step key={index} title={<>{step.title} {!step.isOptional && <span style={{ color: "red" }}>*</span>}</>} status={ ( currentStep > index ) && !values.hasOwnProperty(step.dataKey) ?  "error" : ""} />))
                    }
                </Steps>
                <div style={{marginTop: "50px"}}>
                    {
                        <StepComp handleStepFormSubmission={this.handleStepFormSubmission} type={type} userData={userData} initialValues={{ ...initialValues, ...values }} additional={additional} stepsSubmission={{ steps: this.steps, handleStepsSubmission }} />
                    }
                </div>
            </div>
        );
    }
}

ResourceSteps.propTypes = {
    userData: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    additional: PropTypes.object,
    handleStepsSubmission: PropTypes.func.isRequired
};

export default ResourceSteps;
import React, { Component } from "react";
import {Form, Input, Row, Select, Col, Spin, Typography, Button, Radio, Divider} from "antd";
import { formItemLayout } from "../../../constants";
import { isEmpty, map, unionBy, find, values, remove } from "lodash";
import timezones from "../timezones.json";
import googleLogo from '../../../../assets/images/google-logo-full.png';
import birdeyeLogo from '../../../../assets/images/logo-color.svg';

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;


class BusinessInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            childCategories: [],
            displayCategories: [],
        };
    }

    componentDidMount( ){
        const parentCategory = !isEmpty(this.props.businessInfo) && !isEmpty(this.props.businessInfo.categoryInformation.parentCategory) ? this.props.businessInfo.categoryInformation.parentCategory.name : {};
        if (parentCategory) {
            this.handleParentChange(parentCategory, true);
        }
    }

    componentWillMount () {
        const parentCategory = !isEmpty(this.props.businessInfo) && !isEmpty(this.props.businessInfo.categoryInformation.parentCategory) ? this.props.businessInfo.categoryInformation.parentCategory.name : {};
        if (parentCategory) {
            this.handleParentChange(parentCategory, true);
        }
    }

    onSync = (e) => {
        e.preventDefault();
        const { states= [], countries= [], googleData } = this.props;
        const { business_name, website, phone, address, city, state, zip, sub_category, main_category, country } = googleData;
        let countryFromGoogle = {};
        let stateFromGoogle = {};

        countries.map(countryData => {
            if(countryData.name === country) {
                countryFromGoogle = countryData;
            }
        })

        states.map(stateData => {
            if(stateData.code === state){
                stateFromGoogle = stateData;
            }
        });

        this.props.form.setFieldsValue({
            name : business_name,
            alias : business_name,
            parentCategory : main_category,
            childCategory : [sub_category],
            address1 : address,
            address2 : "",
            city : city,
            state : stateFromGoogle.code,
            phone : phone,
            zip : zip,
            countryCode : countryFromGoogle.code,
            websiteUrl : !isEmpty(website) ? ( website.includes("http") ? website : "http://" + website) : null,
        });
    }

    handleCountryChange = (value) => {
        const { businessInfo } = this.props;
        const { locations } = businessInfo;
        this.props.fetchStates( { country: value } );
        if(!isEmpty(locations) && !(locations[0].countryCode === value)){
            this.props.form.setFieldsValue({state: ""});
        } else {
            this.props.form.setFieldsValue({state: locations ? locations[0].state : null});
        }
    };

    handleParentChange = (value, initial = false) => {
        const { categories= [] } = this.props;
        let childCategories = [];

        for (let i = 0; i < categories.length; i++) {
            if (categories[i]['parentCategory']['name'] === value){
                childCategories = categories[i]['childCategory'];
            }
        }

        if(childCategories){
            this.setState({ childCategories: childCategories});
        }

        if(!initial){
            this.props.form.setFieldsValue({childCategory: []});
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { isLoading, states= [], countries= [], categories= [], businessInfo, googleData, findOnGoogle } = this.props;
        let stateFromGoogle = {};
        states.map(stateData => {
            if(googleData !== undefined){
                if(stateData.code === googleData.state){
                    stateFromGoogle = stateData;
                }
            }
        });

        const childCategories = this.state.childCategories;
        const { name , alias, locations, phone, websiteUrl, categoryInformation, timezone = null, description } = businessInfo;
        return (
            <div>
                {isLoading &&
                <div className="spin-overlay">
                    <Spin size="large" />
                </div>
                }
                <Form>
                    <Row>
                        <Col span={12}>
                            <a href={"http://reviews.birdeye.com/a-"+businessInfo.businessNumber+"?update=1"}><img src={birdeyeLogo} style={{ height:"30px", marginBottom: "10px" }} /></a>
                        </Col>
                        {
                            findOnGoogle ? (
                                <Col span={12}>
                                    {googleData !== undefined ? (
                                        <a href={googleData.final_place_url}><img src={googleLogo} style={{ height:"30px", marginBottom: "10px" }} /></a>
                                    ) : (
                                        <img src={googleLogo} style={{ height:"30px", marginBottom: "10px" }} />
                                    )
                                    }
                                </Col>
                            ) : (
                                ""
                            )
                        }

                        {findOnGoogle ? <Divider /> : ""}
                    </Row>
                    <div style={{display: "flex"}}>
                        <div style={{ width: "49%", float: "left" }}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout} label="Name">
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Please provide business number' }],
                                            initialValue: name
                                        })(
                                            <Input
                                                placeholder="Name"
                                            />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout} label="Alias">
                                        {getFieldDecorator('alias', {
                                            rules: [{ required: true, message: 'Please provide business alias' }],
                                            initialValue: alias
                                        })(
                                            <Input
                                                placeholder="Alias"
                                            />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout} label="Category">
                                        {getFieldDecorator('parentCategory', {
                                            rules: [{ required: true, message: 'Please select business category' }],
                                            initialValue: (categoryInformation && !isEmpty(categoryInformation.parentCategory)) ? categoryInformation.parentCategory.name : null
                                        })(
                                            <Select
                                                placeholder="Select Category"
                                                onChange={(value) => this.handleParentChange(value)}
                                            >
                                                {categories.map((category) => (
                                                    <Option key={category.parentCategory.name} value={category.parentCategory.name}>{category.parentCategory.name}</Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout} label="Subcategory">
                                        {getFieldDecorator('childCategory', {
                                            rules: [{ required: true, message: 'Please select business Subcategory' }],
                                            initialValue: categoryInformation ? map(categoryInformation.childCategory, 'name') : null
                                        })(
                                            <Select mode="multiple" placeholder="Select Subcategory">
                                                {childCategories.map((category) => (
                                                    <Option key={category.name} value={category.name}>{category.name}</Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout} label="Address 1">
                                        {getFieldDecorator('address1', {
                                            initialValue: locations ? locations[0].address1 : null
                                        })(
                                            <Input
                                                placeholder="Address 1"
                                            />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout} label="Address 2">
                                        {getFieldDecorator('address2', {
                                            initialValue: locations ? locations[0].address2 : null
                                        })(
                                            <Input
                                                placeholder="Address 2"
                                            />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout} label="City">
                                        {getFieldDecorator('city', {
                                            rules: [{ required: true, message: 'Please provide city' }],
                                            initialValue: locations ? locations[0].city : null
                                        })(
                                            <Input
                                                placeholder="City"
                                            />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout} label="Zip">
                                        {getFieldDecorator('zip', {
                                            rules: [{ required: true, message: '' }],
                                            initialValue: locations ? locations[0].zip : null
                                        })(
                                            <Input
                                                placeholder="Zip"
                                            />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout} label="State/Province">
                                        {getFieldDecorator('state', {
                                            rules: [{ required: true, message: 'Select State' }],
                                            initialValue: locations ? locations[0].state : null
                                        })(
                                            <Select showSearch optionFilterProp="label">
                                                {states.map((stateData) => (
                                                    <Option key={stateData.code} label={stateData.name} value={stateData.code}>
                                                        {stateData.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout} label="Country">
                                        {getFieldDecorator('countryCode', {
                                            rules: [{ required: true, message: 'Select Country' }],
                                            initialValue: locations ? locations[0].countryCode : null
                                        })(
                                            <Select onChange={(value) => this.handleCountryChange(value)}>
                                                {countries.map((country) => (
                                                    <Option key={country.code} value={country.code}>
                                                        {country.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout} label="Phone">
                                        {getFieldDecorator('phone', {
                                            initialValue: phone
                                        })(
                                            <Input
                                                placeholder="Phone"
                                            />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout} label="Website">
                                        {getFieldDecorator('websiteUrl', {
                                            initialValue: websiteUrl
                                        })(
                                            <Input
                                                placeholder="Website"
                                            />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout} label="Timezone">
                                        {getFieldDecorator('timezone', {
                                            initialValue: timezone ? timezone : null
                                        })(
                                            <Select >
                                                {timezones && timezones.map((timezoneOption) => (
                                                    <Option key={timezoneOption.value} value={timezoneOption.value}>
                                                        {timezoneOption.label}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout} label="About Us">
                                        {getFieldDecorator('description', {
                                            initialValue: description ? description : null
                                        })(
                                            <TextArea rows={4} />

                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        {findOnGoogle ? <div style={{width: "1px", backgroundColor: "#E8E8E8", marginBottom: "23px", marginLeft: "13px"}}></div> : ""}
                        {
                            findOnGoogle && googleData !== undefined ? (
                                <div style={{ width: "49%", float: "left" }}>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item {...formItemLayout} label="Name">
                                                <Input
                                                    defaultValue={googleData.business_name}
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item {...formItemLayout} label="Alias">
                                                <Input
                                                    defaultValue={googleData.business_name}
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item {...formItemLayout} label="Category">
                                                <Input
                                                    defaultValue={googleData.main_category}
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item {...formItemLayout} label="Subcategory">
                                                <Input
                                                    defaultValue={googleData.sub_category}
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item {...formItemLayout} label="Address 1">
                                                <Input
                                                    defaultValue={googleData.address}
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item {...formItemLayout} label="Address 2">
                                                <Input
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item {...formItemLayout} label="City">
                                                <Input
                                                    defaultValue={googleData.city}
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item {...formItemLayout} label="Zip">
                                                <Input
                                                    defaultValue={googleData.zip}
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item {...formItemLayout} label="State/Province">
                                                <Select defaultValue={stateFromGoogle.name} disabled>
                                                    <Option key={stateFromGoogle.value} value={stateFromGoogle.code} >
                                                        {stateFromGoogle.name}
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item {...formItemLayout} label="Country">
                                                <Input
                                                    defaultValue={googleData.country}
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item {...formItemLayout} label="Phone">
                                                <Input
                                                    defaultValue={googleData.phone}
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item {...formItemLayout} label="Website">
                                                <Input
                                                    defaultValue={googleData.website}
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                findOnGoogle && googleData == undefined ? (
                                    <div style={{ width: "49%", float: "left", margin: "auto", color: "red", fontWeight: 500, fontSize: "20px", fontFamily: "initial"  }}>
                                        Google Profile Not Found
                                    </div>
                                ) : (
                                    <></>
                                )
                            )
                        }
                    </div>
                    {
                        findOnGoogle && googleData !== undefined ? (
                            <Row style={{marginBottom: "10px"}}>
                                <Button type="primary" onClick={this.onSync}>
                                    Sync With Google
                                </Button>
                            </Row>) : ""
                    }
                    <Row style={{marginBottom: "10px"}}>
                        <Col span={24}>
                            <Text style = {{color: "red"}}><b>Disclaimer</b>: Do not skip the Find step (the 7th step), if you need to change the name, address or phone fields.</Text>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}


export default BusinessInfo;
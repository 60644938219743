import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";
import { dateFormat } from "../../constants";
import { getIsPageActionAccessible } from "../../utils";
import { beAPIResource } from "../../utils/apiHelper";
import "./WebsiteContent.scss";
const columns =[
    {
        "title": "Slug",
        "key": "slug",
        "searchable": true,
        render: value => (<a href={calculateHref(value)} target="_blank">{calculateHref(value)}</a>),
        "width": "30%"
    },
    {
        "title": "Page Type",
        "key": "pageType",
        "searchable": true,
        "width": "15%"
    },
    {
        "title": "Country",
        "key": "country",
        "exactFilterable": true,
        "filters": [
            { "text": "Australia", "value": "Australia" },
            { "text": "United States", "value": "United States" }
        ],
        "width": "10%"
    },
    {
        "title": "Updated By",
        "key": "last_updated_by",
        "width": "22%"
    },
    {
        "title": "Updated At",
        "key": "updatedAt",
        "width": "15%",
        "sortAs": "date",
        "defaultSortOrder": "ascend"
    }
];

const calculateHref = (value) => {
    if(value === '/home') return `${process.env.REACT_APP_WEBSITE_URL}`;
    if(value === '/au/home') return `${process.env.REACT_APP_WEBSITE_URL_AUS}`;
    if(value.includes('/au/')) return `${process.env.REACT_APP_WEBSITE_URL_AUS}${value.replace('/au/','')}/`;
    else return `${process.env.REACT_APP_WEBSITE_URL}${value.slice(1)}/`;
}
class WebsiteContent extends Component {

    pageKey="websiteContentList";
    isReadonly = !getIsPageActionAccessible(this.pageKey, this.props.userData.privileges);
    state = {
        websiteContentData: []
    };

    getContent = async () => {
        try {
            const response = await beAPIResource.get(`support/website/content/all`);
            this.setState({ websiteContentData: response.data })
        } catch (error) {
            console.error(error);
        }
    }

    async componentDidMount() {
        await this.getContent()
    }

    formatData = () => {
        const { websiteContentData } = this.state;
        const countries = {
            "au": "Australia",
            "us": "United States"
        };

        if(websiteContentData.length) {
            return websiteContentData.map((listItem) => {
                return {
                    ...listItem,
                    key: listItem.id,
                    updatedAt: moment(listItem.updatedAt).format(dateFormat),
                    country: countries[listItem.country]
                };
            });
        }
        return websiteContentData;
    };


    handleDelete = async (data) => {  
        try {
            await beAPIResource.delete(`support/website/content/delete/${data.id}`);
            await this.getContent()
        } catch (error) {
            console.error(error)
        }   
    };

    render() {
        const { error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="resources-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    columns={columns}
                    dataSource={this.formatData()}
                    pathForEdit={"/website-content/update-json/"}
                    handleDelete={!this.isReadonly && this.handleDelete}
                />
            </div>
        );
    }
}

WebsiteContent.propTypes = {
    userData: PropTypes.object
};

export default WebsiteContent;
import React from "react";
import PropTypes from "prop-types";
import { Select } from 'antd';

const { Option } = Select;

const SearchInput = (props) => {
    const { name, optionList, currentValue, placeholder, style, defaultActiveFirstOption, showArrow, filterOption, handleSearch, handleChange, notFoundContent  } = props;
    const options = optionList.map(option => <Option key={option.value} value={option.value}>{option.label}</Option>);
    
    return (
        <Select
            showSearch
            value={currentValue}
            placeholder={placeholder}
            style={style}
            defaultActiveFirstOption={defaultActiveFirstOption}
            showArrow={showArrow}
            filterOption={filterOption}
            onSearch={handleSearch}
            onChange={(value) => handleChange(name, value)}
            notFoundContent={notFoundContent}
        >
            {options}
        </Select>
    );
}

SearchInput.defaultProps = {
    optionList: [],
    currentValue: [],
    placeholder: "Search",
    style: null,
    defaultActiveFirstOption: false,
    showArrow: false,
    filterOption: false,
    notFoundContent: "No data"
};

SearchInput.propTypes = {
    options: PropTypes.array.isRequired,
    onSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    defaultActiveFirstOption: PropTypes.bool,
    showArrow: PropTypes.bool,
    filterOption: PropTypes.bool,
    notFoundContent: PropTypes.string
};

export default SearchInput;
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import { Form, Input, Button, Upload, Icon } from "antd";

import { RedirectWrapper } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";
const FormItem = Form.Item;

class UploadImageToCDNForm extends Component {
    state = {
        data: {
            imageName: "",
            imagePath: "",
            imageCDNPath: "",
            uploading: false
        },
        formError: ""
    };

    handleSubmit = () => {
        const { imageName, imagePath } = this.state.data;
        if ( isEmpty(imageName) || isEmpty(imagePath) ) {
            this.setState({formError: "Fill in all the relevant fields"});
            return false;
        };
        return true;
    }

    handleInputChange = (e, fieldName) => {
        if (e.target) {
            const { name, value } = e.target;
            this.handleFieldChange(name, value);
        } else if (e.file) {
            if (e.file.status === "uploading") {
                this.setState(() => ({uploading: true}));
            } else if (e.file.status === "done") {
                this.setState(() => ({uploading: false}));
            }
            if (e.file.response && !this.state.formError) {
                const { imageUrl } = e.file.response;
                this.handleFieldChange(fieldName, imageUrl);
            }
        }
    }

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        this.setState({data, formError: ""});
    }

    getImageUploadUrl = () => {
        const { imageName, imagePath } = this.state.data;
        const trimmedImagePath = imagePath.replace(/^\/|\/$/g, '');
        return `${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=${imageName}&imgpath=${trimmedImagePath}`;
    }

    render() {
        const { imageName, imagePath, imageCDNPath } = this.state.data;
        const { error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div>
                <h2>Upload Image</h2>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Name">
                        <Input
                            name="imageName"
                            value={imageName}
                            placeholder="image name"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Path on CDN">
                        <Input
                            name="imagePath"
                            value={imagePath}
                            placeholder="folder/subfolder"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label={`Image`}> 
                        <span>
                            <Upload
                                name="files"
                                beforeUpload={this.handleSubmit}
                                action={this.getImageUploadUrl()}
                                showUploadList={false}
                                withCredentials
                                onChange={(e) => this.handleInputChange(e, "imageCDNPath")}>
                                <Button style={{width: 200}}>
                                    <Icon type="upload" /> Upload
                                </Button>
                            </Upload>
                        </span>
                    </FormItem>
                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}
                    {imageCDNPath && (
                        <FormItem {...formButtonLayout}>
                            <span>
                                {`Image successfully uploaded`}
                            </span>
                            <a href={imageCDNPath}> here </a>
                        </FormItem>
                    )}
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.User
    }
};

const UploadImageToCDN = Form.create()(UploadImageToCDNForm);

export default connect(mapStateToProps)(UploadImageToCDN);

// Components to upload multiple dimensions of an image (@2x, @3x) and corresponding webp images.
import React from "react";
import { Form, Button, Upload, Icon} from "antd";

const FormItem = Form.Item;

const getSiblingImagePath = (imagePath, pdSuffix) => {
    if(!imagePath || !imagePath.length) return null;
    let imageUrlParts = imagePath.split(".");
    const ext = imageUrlParts.pop();
    return `${imageUrlParts.join(".")}@${pdSuffix}.${ext}`;
};

const getImageUploadUrl = (name, timestamp, pd) => {
    const
        pdQuery = pd ? `&pd=${pd}` : "",
        uploadUrl = `${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=${name}&timestamp=${timestamp}${pdQuery}&webp=true`;
    return uploadUrl;
};

const hideImage = function(e){e.target.style.opacity='0'};
const showImage = function(e){e.target.style.opacity='1'};

const MultiResolutionImage = ({formItemLayout, handleInputChange, imageFieldName, uploadName, imagePath, timestamp, required, disabled, label="Image"}) => {

    return <FormItem {...formItemLayout} label={label} required={required}>
        <Upload
            name="files"
            action={getImageUploadUrl(uploadName, timestamp)}
            withCredentials
            listType="picture"
            onChange={(e) => handleInputChange(e, `${imageFieldName}`)}>
            <Button disabled={disabled}>
                <Icon type="upload"/> Upload Image
            </Button>
        </Upload>
        {
            imagePath &&
            <img className="mt1 mb1 mx-width-360" src={imagePath} alt=""/>
        }
        <br/>
        <Upload
            name="files"
            action={getImageUploadUrl(uploadName, timestamp,"2x")}
            withCredentials
            listType="picture"
            onChange={(e) => handleInputChange(e, `${imageFieldName}2x`)}>
            <Button disabled={disabled}>
                <Icon type="upload"/> Upload 2x Image
            </Button>
        </Upload>
        {
            getSiblingImagePath(imagePath,"2x") &&
            <img className="mt1 mb1 mx-width-360" src={getSiblingImagePath(imagePath,"2x")} alt="Not found" onError={hideImage} onLoad={showImage}/>
        }
        <br/>
        <Upload
            name="files"
            action={getImageUploadUrl(uploadName, timestamp,"3x")}
            withCredentials
            listType="picture"
            onChange={(e) => handleInputChange(e, `${imageFieldName}3x`)}>
            <Button disabled={disabled}>
                <Icon type="upload"/> Upload 3x Image
            </Button>
        </Upload>
        {
            getSiblingImagePath(imagePath,"2x") &&
            <img className="mt1 mb1 mx-width-360" src={getSiblingImagePath(imagePath,"3x")} alt="No found" onError={hideImage} onLoad={showImage}/>
        }
    </FormItem>
};

export default MultiResolutionImage;

import { connect } from "react-redux";
import WebsiteContent from "./WebsiteContent";
import UpdateWebsiteContent from "./UpdateWebsiteContent";
import PageDetails from "./PageDetails";
import AddComponent from "./AddComponent";
import UpdateComponent from "./UpdateComponent";
import WebsiteRedirect from "./WebsiteRedirect";
import AddWebsiteRedirect from "./AddWebsiteRedirect";

const mapStateToProps = (state) => ({
    ...state.User
});

export const WebsiteContentPage = connect(
    mapStateToProps
)( WebsiteContent );

export const UpdateWebsiteContentPage = connect(
    mapStateToProps
)( UpdateWebsiteContent );

export const PageDetailsPage = connect(
    mapStateToProps
)( PageDetails );

export const AddComponentPage = connect(
    mapStateToProps
)( AddComponent );

export const UpdateComponentPage = connect(
    mapStateToProps
)( UpdateComponent );

export const WebsiteRedirectPage = connect(
    mapStateToProps
)( WebsiteRedirect );

export const WebsiteRedirectEditPage = connect(
    mapStateToProps
)( AddWebsiteRedirect );

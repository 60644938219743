import { createActionTypes } from "../../utils";

export const ScanToolsActions = createActionTypes("scanTool", [
    "REQ_BUSINESS_SIGN_UP",
    "REQ_BUSINESS_SIGN_UP_SUCCESS",
    "REQ_BUSINESS_SIGN_UP_ERROR",
    "GET_BUSINESS_SCAN_TOOL_HISTORY",
    "GET_BUSINESS_SCAN_TOOL_HISTORY_SUCCESS",
    "GET_BUSINESS_SCAN_TOOL_HISTORY_ERROR"
]);

export const reqBusinessDetails = (object) => {
    return {
        type: ScanToolsActions.REQ_BUSINESS_SIGN_UP,
        payload: {... object}
    }
}

export const successBusinessDetails = (object) => {
    return {
        type: ScanToolsActions.REQ_BUSINESS_SIGN_UP_SUCCESS,
        payload: {... object}
    }
}

export const errorBusinessDetails = (err) => {
    return {
        type: ScanToolsActions.REQ_BUSINESS_SIGN_UP_ERROR,
        payload: { ...err }
    }
}

export const getBusinessScanToolHistory = (data) => {
    return {
        type: ScanToolsActions.GET_BUSINESS_SCAN_TOOL_HISTORY,
        payload: { ... data}
    }
}

export const successBusinessScanToolHistory = (data) => {
    return {
        type: ScanToolsActions.GET_BUSINESS_SCAN_TOOL_HISTORY_SUCCESS,
        payload: [... data]
    }
}

export const errorBusinessScanToolHistory = (err) => {
    return {
        type: ScanToolsActions.GET_BUSINESS_SCAN_TOOL_HISTORY_ERROR,
        payload: {... err }
    }
}


export default ScanToolsActions;

import { createActionTypes } from "../../utils";

export const ProductEmailsActions = createActionTypes("productEmails", [
    "FETCH_EMAIL_BY_MONTH_REQUEST",
    "FETCH_EMAIL_BY_TEMPLATE_REQUEST",
    "FETCH_EMAIL_BY_MONTH_SUCCESS",
    "FETCH_EMAIL_BY_TEMPLATE_SUCCESS",
    "FETCH_EMAIL_ERROR",
    "FETCH_TEMPLATE_STATS_REQUEST",
    "FETCH_TEMPLATE_STATS_SUCCESS",
    "FETCH_TEMPLATE_STATS_ERROR",
    "ERASE_TEMPLATE_STATS_REQUEST",
    "FETCH_EMAIL_VERSIONS_REQUEST",
    "FETCH_EMAIL_VERSIONS_SUCCESS",
    "FETCH_EMAIL_VERSIONS_ERROR",
    "ERASE_EMAIL_VERSIONS_REQUEST"
]);

export const fetchEmailByMonthRequest = (obj) => {
    return {
        type: ProductEmailsActions.FETCH_EMAIL_BY_MONTH_REQUEST,
        payload: obj
    };
};

export const fetchEmailByMonthSuccess = (obj) => {
    return {
        type: ProductEmailsActions.FETCH_EMAIL_BY_MONTH_SUCCESS,
        payload: obj
    };
};

export const fetchEmailByTemplateRequest = (obj) => {
    return {
        type: ProductEmailsActions.FETCH_EMAIL_BY_TEMPLATE_REQUEST,
        payload: obj
    };
};

export const fetchEmailByTemplateSuccess = (obj) => {
    return {
        type: ProductEmailsActions.FETCH_EMAIL_BY_TEMPLATE_SUCCESS,
        payload: obj
    };
};

export const productEmailError = (obj) => {
    return {
        type: ProductEmailsActions.FETCH_EMAIL_ERROR,
        payload: obj
    };
};

export const fetchTemplateStatsRequest = (obj) => {
    return {
        type: ProductEmailsActions.FETCH_TEMPLATE_STATS_REQUEST,
        payload: obj
    };
}

export const fetchTemplateStatsSuccess = (obj) => {
    return {
        type: ProductEmailsActions.FETCH_TEMPLATE_STATS_SUCCESS,
        payload: obj
    };
}

export const fetchTemplateStatsError = (obj) => {
    return {
        type: ProductEmailsActions.FETCH_TEMPLATE_STATS_ERROR,
        payload: obj
    };
}

export const eraseTemplateStatsRequest = () => {
    return {
        type: ProductEmailsActions.ERASE_TEMPLATE_STATS_REQUEST
    };
}

export const fetchEmailVersionsRequest = (obj) => {
    return {
        type: ProductEmailsActions.FETCH_EMAIL_VERSIONS_REQUEST,
        payload: obj
    };
}

export const fetchEmailVersionsSuccess = (obj) => {
    return {
        type: ProductEmailsActions.FETCH_EMAIL_VERSIONS_SUCCESS,
        payload: obj
    };
}

export const fetchEmailVersionsError = (obj) => {
    return {
        type: ProductEmailsActions.FETCH_EMAIL_VERSIONS_ERROR,
        payload: obj
    };
}

export const eraseEmailVersionsRequest = () => {
    return {
        type: ProductEmailsActions.ERASE_EMAIL_VERSIONS_REQUEST
    };
}

export default ProductEmailsActions;

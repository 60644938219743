import { createActionTypes } from "../../utils";

export const BusinessActions = createActionTypes("beReviews", [
    "FETCH_BIRDEYE_REVIEWS",
    "FETCH_BIRDEYE_REVIEWS_SUCCESS",
    "FETCH_BIRDEYE_REVIEWS_ERROR",
    "DELETE_REVIEW_REQ",
    "DELETE_REVIEW_REQ_SUCCESS",
    "DELETE_REVIEW_REQ_ERROR"
]);

export const fetchBirdeyeReviews = (obj) => {
    return {
        type: BusinessActions.FETCH_BIRDEYE_REVIEWS,
        payload: obj
    };
};

export const fetchBirdeyeReviewsSuccess = (obj) => {
    return {
        type: BusinessActions.FETCH_BIRDEYE_REVIEWS_SUCCESS,
        payload: obj
    };
};

export const fetchBirdeyeReviewsError = (obj) => {
    return {
        type: BusinessActions.FETCH_BIRDEYE_REVIEWS_ERROR,
        payload: obj
    };
};

export const deleteReviewRequest = (obj) => {
    return {
        type: BusinessActions.DELETE_REVIEW_REQ,
        payload: obj
    };
};

export const deleteReviewReqSuccess = (obj) => {
    return {
        type: BusinessActions.DELETE_REVIEW_REQ_SUCCESS,
        payload: obj
    };
};

export const deleteReviewReqError = (obj) => {
    return {
        type: BusinessActions.DELETE_REVIEW_REQ_ERROR,
        payload: obj
    };
};

export default BusinessActions;
import { createActionTypes } from "../../utils";

export const TwilioAction = createActionTypes("twilio", [
    "PHONE_NUMBER_UPLOAD_REQUEST",
    "PHONE_NUMBER_UPLOAD_SUCCESS",
    "PHONE_NUMBER_UPLOAD_ERROR",
]);

export const phoneNumberUploadRequest = (obj) => {
    return {
        type: TwilioAction.PHONE_NUMBER_UPLOAD_REQUEST,
        payload: obj
    };
};

export const phoneNumberUploadSuccess = (obj) => {
    return {
        type: TwilioAction.PHONE_NUMBER_UPLOAD_SUCCESS,
        payload: obj
    };
}

export const phoneNumberUploadError = (obj) => {
    return {
        type: TwilioAction.PHONE_NUMBER_UPLOAD_ERROR,
        payload: obj
    };
}

export default TwilioAction;
import { connect } from "react-redux";

import {
    fetchPressesListRequest,
    fetchPressRequest,
    addPressRequest,
    updatePressRequest,
    deletePressRequest
} from "./actions";

import Presses from "./Presses";
import UpdatePress from "./UpdatePress";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Presses
});

export const PressesPage = connect(
    mapStateToProps,
    { fetchPressesListRequest, deletePressRequest }
)(Presses);

export const UpdatePressPage = connect(
    mapStateToProps,
    { fetchPressRequest, addPressRequest, updatePressRequest }
)(UpdatePress);

import { connect } from "react-redux";
import SitemapsList from "./SitemapsList";

const mapStateToProps = (state) => ({
    ...state.User
});

export const SitemapsListPage = connect(
    mapStateToProps
)( SitemapsList );


import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Select, Radio, Icon, InputNumber, message, Row, Col, Tooltip } from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";
import lookup from "./constants";

const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;

class ConfigureForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            countryWebsite: "https://birdeye.com/cal/"
        }
        this.pageId = '';
    }

    renderSalesRepsForPage = (salesRepListData, salesRepIds) => {
        const reps = salesRepListData.filter((r) => {
            return r.active || salesRepIds.includes(r.id);
        });

        return (
            reps.map((rep) => {
                return (
                    <Option
                        value={rep.id}
                        key={rep.id}
                        disabled={!rep.active}
                    >
                        {rep.name}
                    </Option>
                );
            })
        );
    };

    handleCountryWebsite = (value) => {
        this.setState({countryWebsite: value},() => {
            const {countryWebsite} = this.state;
            if(countryWebsite.indexOf('.au') > 0) {
                this.props.form.setFieldsValue({holiday: "Australia"});
            }else if (countryWebsite.indexOf('.uk') > 0) {
                this.props.form.setFieldsValue({holiday: "United Kingdom"});
            } else {
                this.props.form.setFieldsValue({holiday: "United States"});
            }      
            this.props.form.setFieldsValue({ previewUrl: this.state.countryWebsite});      
        })
    }

    handleDistributionType = () => {
        const calendarType = this.props.form.getFieldValue('requestType');
        if(calendarType === 'CALENDAR_LEAD') {
            this.props.form.setFieldsValue({distributionType: "Round_Robin"});
        }else {
            this.props.form.setFieldsValue({distributionType: "Based_on_Availability"});
        }
    }

    handleConfigureForm = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((error, values) => {
            if(!error) {
                const { configure } = this.props.initialValues;
                const { pageType, leadCampaignId } = configure || {};
                values.active = values.active ? values.active : "";
                values.pageType = values.pageType || pageType;
                values.leadCampaignId = values.leadCampaignId || leadCampaignId;
                this.props.handleCalendarStepsForm({configure: { ... values, id: this.pageId}});
            }
        });
    }

    handleCopyToClipboard = async (e) => {
        e.preventDefault();
        const previewText = this.props.form.getFieldValue('previewUrl') + this.props.form.getFieldValue('slug') +  `${this.props.form.getFieldValue('slug') ? "/" : ""}`;
        try {
            navigator.clipboard.writeText(previewText);;
            message.success("Page URL is copied to clipboard.");
        } catch (e) {
            message.error(e.message);
        }
    }

    removeSpecialSlugValues(typeField){
        if(typeField.trim().length <= 0) return "";
        let data = typeField.replace(/[^a-zA-Z0-9-\s]/g, '').toLowerCase();
        data = data.replace(/\s+/g, '-');
        data = data.replace(/^-+|-+$/g,'')
        return data;
    }

    handleSlugField = () => {
        if(this.props.initialValues.isEditPage) return;
        let typeField = this.props.form.getFieldValue('type');
        const finalSlug = this.removeSpecialSlugValues(typeField);
        this.props.form.setFieldsValue({slug : finalSlug});
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { configure, isEditPage } = this.props.initialValues;
        const { salesRepListData, hasCalendarAdminAccess, fallBackCal = [] } = this.props;
        const {id, active, type, slug = "", previewUrl, durationInMinutes, description, pageType, requestType, salesRepIds, skipHours, fallbackCalendar, holiday, slotsDuration, distributionType, leadCampaignId, slotsInterval } = configure || {};
        this.pageId = id;

        const selectCountryWebsite = (
                <Select disabled={isEditPage} style={{width:'230px'}} onChange={this.handleCountryWebsite} defaultValue={previewUrl}>
                    {lookup.valueList.countryWebsite.map((website) => (
                        <Option
                            key={website}
                            value={website}>
                            {website}
                        </Option>
                    ))}
                </Select>
        );

        return (
            <Form onSubmit={this.handleConfigureForm}>
                <FormItem {...formItemLayout} label="Page name (Internal)"  extra={"How would you search this page later? For example, 'Calendar for Pricing page'"} onChange={this.handleSlugField}>
                    {getFieldDecorator('type', {
                        rules: [{ "required": true, "message": 'Please provide internal page name.' }],
                        initialValue: type
                        })(
                        <Input
                            name="type"
                        />
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Page URL" extra={<span>Do not add cal/ in the slug. It will get added automatically</span>}>
                    {getFieldDecorator('slug', {
                        rules: [{ "required": true, "message": "Please provide page URL." }, {
                                    "max": 100, 
                                    "message": 'Page URL should be of maximum 100 characters.' 
                                }, {
                                    "pattern": "^[a-z0-9\\-]+$",
                                    "message": "Sorry, only letters (a-z), numbers (0-9) and hyphens (-) are allowed"
                                }],
                        initialValue: slug
                        })(
                        <Input
                            name="slug"
                            disabled={isEditPage}
                            placeholder="free-trial-v1"
                            maxLength={101}
                            addonBefore={isEditPage ? previewUrl : selectCountryWebsite }
                            addonAfter={"/"}
                        />
                    )}
                </FormItem>
                <Row>
                    <FormItem {...formItemLayout} label="Page URL (Preview)">
                        {getFieldDecorator('previewUrl', {
                            initialValue: previewUrl
                            })(
                            <>
                                <Col span={23}>
                                    <a disabled ={ isEditPage ? false : true } href = { getFieldValue('previewUrl') + getFieldValue('slug') } target="_blank" >{ getFieldValue('previewUrl')  + getFieldValue('slug') +  `${getFieldValue('slug') ? "/" : ""}` }</a>   
                                </Col>
                                <Col span={1}>
                                    <Tooltip placement="top" title={"Copy URL"}>
                                        <Button icon="copy" onClick={this.handleCopyToClipboard} />
                                    </Tooltip>
                                </Col>
                            </>
                        )}
                    </FormItem>
                </Row>
                <FormItem {...formItemLayout} label="Meeting duration">
                    {getFieldDecorator('durationInMinutes', {
                            rules: [{ "required": true, "message": "Please provide meeting duration." }],
                            initialValue: durationInMinutes
                        })(
                            <Select
                                placeholder="Duration (in minutes)"
                                >
                                {lookup.valueList.durationList.map((duration) => (
                                    <Option
                                        key={duration}
                                        value={duration} >
                                        {`${duration} minutes`}
                                    </Option>
                                ))}
                            </Select>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Calendar type" onChange={this.handleDistributionType}>
                    {getFieldDecorator('requestType', {
                        initialValue: requestType
                    })(
                        <Radio.Group
                            options={lookup.valueList.requestTypes}
                        />
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Associated reps">
                    {getFieldDecorator('salesRepIds', {
                        rules: [{ "required": true, "message": 'Please provide associated reps.' }],
                        initialValue: salesRepIds || []
                    })(
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="Select all the relevant sales reps"
                            loading={salesRepListData.length===0}
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                { this.renderSalesRepsForPage(salesRepListData, salesRepIds) }
                            </Select>
                        )}
                </FormItem>
                <FormItem {...formItemLayout} label="Distribution type">
                    {getFieldDecorator('distributionType', {
                        initialValue: distributionType
                    })(
                        <Radio.Group
                            options={lookup.valueList.distributionType}
                        />
                    )}
                </FormItem>
                {
                    isEditPage && 
                    <FormItem {...formItemLayout} label="Active">
                        {getFieldDecorator('active', {
                            initialValue: active
                        })(
                            <Select placeholder="Active">
                                {lookup.valueList.activeStatuses.map((role) => (
                                    <Option
                                        key={role.label}
                                        value={role.value}>
                                        {role.label}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </FormItem>
                }
                <FormItem {...formItemLayout} label="Fallback calendar" extra={<span>If your associated reps are not available at all, then time slots are shown from the fallback calendar</span>}>
                    {getFieldDecorator('fallbackCalendar', {
                            initialValue: fallbackCalendar || "none"
                    })(
                        <Select
                            showSearch
                            placeholder="Fallback calendar"
                            loading={fallBackCal.length===0}
                            >
                            {fallBackCal.map((fallback) => (
                                <Option
                                    key={fallback.slug}
                                    value={fallback.slug}>
                                    {fallback.pageName ? `${fallback.slug}  ( ${fallback.pageName} )` : fallback.slug  } 
                                </Option>
                            ))}
                        </Select>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Consider holidays for" >
                    {getFieldDecorator('holiday', {
                            initialValue: holiday
                        })(
                            <Select
                                placeholder="Country Bank Holiday"
                                >
                                {lookup.valueList.countryHolidays.map((country) => (
                                    <Option
                                        key={country}
                                        value={country}>
                                        {country}
                                    </Option>
                                ))}
                            </Select>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Slot intervals" extra="Timeslots will be shown at the selected interval and availability of the associated reps">
                    {getFieldDecorator('slotsInterval', {
                            initialValue: slotsInterval
                        })(
                            <Select
                                placeholder="Slots Interval"
                                >
                                {lookup.valueList.slotsInterval.map((intervals) => (
                                    <Option
                                        key={intervals.label}
                                        value={intervals.value}>
                                        {intervals.label}
                                    </Option>
                                ))}
                            </Select>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Show slots till" extra="Slots will be shown 1 to 76 days from the current date">
                        {getFieldDecorator('slotsDuration', {
                            rules: [
                                { 
                                    "required": true, "message": "Please provide slots" 
                                },
                                {
                                    validator: (rule, val, cb) => {
                                        const isInteger = Number.isInteger(val + 1) || Number.isInteger(val - 1);
                                        if (!isInteger && (val % 1 !== 0)) {
                                            return cb("Please provide valid days slots.");
                                        }
                                        cb();
                                    },
                                    "message": ""
                                }
                            ],
                            initialValue: slotsDuration
                        })(
                            <InputNumber
                                min={1}
                                max={76}
                                step={1}
                            />
                        )} 
                </FormItem>
                <FormItem {...formItemLayout} label="Show first slot after (hour)" extra={<span>Timeslots will be shown after a delay of selected value (in hours)</span>}>
                        {getFieldDecorator('skipHours', {
                            rules: [{ "required": true, "message": "Please provide a skip hour before your timeslot." 
                                },
                                {
                                    validator: (rule, skipHours, cb) => {
                                        const isInteger = Number.isInteger(skipHours + 0.5) || Number.isInteger(skipHours - 0.5)
                                        if (!isInteger && (skipHours % 1 !== 0)) {
                                            return cb("Please provide skip hour in multiple of half an hour.");
                                        }
        
                                        cb();
                                    },
                                    "message": ""
                                }
                            ],
                            initialValue: skipHours
                        })(
                            <InputNumber
                                min={0.5}
                                max={200}
                                step={0.5}
                                precision={1}
                            />
                        )}
                </FormItem>
                <FormItem {...formItemLayout} label="Additional information" extra={<span>Here you can add more details about this calendar page</span>}>
                    {getFieldDecorator('description', {
                            initialValue: description
                        })(
                            <TextArea
                                name="description"
                                placeholder="Enter Description"
                                rows={4}
                            />
                    )} 
                </FormItem>
                {
                    hasCalendarAdminAccess && <FormItem {...formItemLayout} label="Page type" >
                        {getFieldDecorator('pageType', {
                            rules: [{
                                validator: (rule, pageType, cb) => {

                                    const salesRepIds =  this.props.form.getFieldValue("salesRepIds");
                                    if (salesRepIds.length > 1 && pageType === 'personal') {
                                        return cb("To create a personal calendar, select only one rep.");
                                    }

                                    cb();
                                },
                                "message": "To create a personal calendar, select only one rep."
                            }],
                            initialValue: pageType
                        })(
                            <Radio.Group
                                options={lookup.valueList.pageTypes}
                            />
                        )}
                    </FormItem>
                }
                {
                    hasCalendarAdminAccess && <FormItem {...formItemLayout} label="Lead campaign Id" >
                        {getFieldDecorator('leadCampaignId', {
                            rules: [{
                                required: true,
                                message: "Please provide lead campaign id"
                            }],
                            initialValue: leadCampaignId
                        })(
                            <Input placeholder="Enter Lead Campaign ID" name="leadCampaignId" />
                        )}
                    </FormItem>
                }
                <FormItem {...formButtonLayout}>
                    <Button type={"primary"} htmlType={"submit"}>
                        Continue<Icon type="right" />
                    </Button>
                </FormItem>
            </Form>
        )
    }
}

const Configure = Form.create()(ConfigureForm);

Configure.propTypes = {
    initialValues: PropTypes.object.isRequired,
    salesRepListData:PropTypes.array,
    handleCalendarStepsForm: PropTypes.func.isRequired
};

export default Configure;
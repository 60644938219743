import React, { Component } from "react";
import PropTypes from "prop-types";
import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";
import { getIsPageActionAccessible } from "../../utils";
import { beAPIResource } from "../../utils/apiHelper";
import { Input, Button, Icon } from 'antd';

const calculateHref = (value) => {
    if(value.includes('http')) return value;
    if(value === '/home') return `${process.env.REACT_APP_WEBSITE_URL}`;
    if(value === '/au/home') return `${process.env.REACT_APP_WEBSITE_URL_AUS}`;
    if(value.includes('/au/')) return `${process.env.REACT_APP_WEBSITE_URL_AUS}${value.replace('/au/','')}`;
    else return `${process.env.REACT_APP_WEBSITE_URL}${value.slice(1)}`;
}
class WebsiteContentRedirect extends Component {

    pageKey="websiteRedirects";
    isActionAccessible = getIsPageActionAccessible(this.pageKey, this.props.userData.privileges);
    state = {
        websiteContentData: [],
        searchText: '',
        searchedColumn: '',
    };

    getContent = async () => {
        try {
            const response = await beAPIResource.get(`support/website/content/allRedirects`);
            const conditionedData = response.data.map(redirect => {
              if (redirect.source.startsWith('/au/') && !redirect.target.startsWith('http')) return {...redirect, target: '/au'+redirect.target};
              else return redirect;
            })
            this.setState({ websiteContentData: conditionedData })
        } catch (error) {
            console.error(error);
        }
    }

    async componentDidMount() {
        await this.getContent()
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            text
          ) : (
            text
          ),
    });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleDelete = async (data) => {  
        try {
            await beAPIResource.delete(`support/website/content/redirection/${data.id}`);
            await this.getContent()
        } catch (error) {
            console.error(error)
        }   
    };

    render() {
        const { error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        const columns = [
            {
                "title": "Source",
                "key": "source",
                "searchable": true,
                "dataIndex": "source",
                render: value => (value ? <a href={calculateHref(value)} target="_blank">{calculateHref(value)}</a> : ''),
                "width": "45%"
            },
            {
                "title": "Target",
                "key": "target",
                "searchable": true,
                "dataIndex": "target",
                render: value => (value ? <a href={calculateHref(value)} target="_blank">{calculateHref(value)}</a> : ''),
                "width": "45%"
            },
            {
                "title": "Status",
                "key": "status",
                "sortAs": "string",
                "searchable": true,
                "dataIndex": "status",
                "width": "5%",
                ...this.getColumnSearchProps('status')
            }
        ];

        return (
            <div className="resources-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    columns={columns}
                    dataSource={this.state.websiteContentData}
                    pathForEdit={this.isActionAccessible ? "/website-redirects/edit/" : ''}
                    handleDelete={this.isActionAccessible && this.handleDelete}
                />
            </div>
        );
    }
}

WebsiteContentRedirect.propTypes = {
    userData: PropTypes.object
};

export default WebsiteContentRedirect;
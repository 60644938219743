import axios from "axios";
import { toLower } from "lodash";
import qs from "qs";

import { store } from "../../index";
import { logoutRequest } from "../pages/Users/actions";

const formDataHeaders = {
    "Content-Type": "application/x-www-form-urlencoded"
};
const baseURL = process.env.REACT_APP_API_BASE_URL;
const leadGenURL = process.env.REACT_APP_LEADGEN_URL;

const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json"
};

const axiosInstance = axios.create({
    baseURL,
    timeout: 1800000,
    headers,
    withCredentials: true
});

export let beAPIResource = {
    resourceApi: axiosInstance,
    get: generateResourceMethod(axiosInstance, "get", "be"),
    post: generateResourceMethod(axiosInstance, "post", "be"),
    put: generateResourceMethod(axiosInstance, "put", "be"),
    delete: generateResourceMethod(axiosInstance, "delete", "be")
};

const axiosInstanceLeadGen = axios.create({
    baseURL: leadGenURL,
    timeout: 1800000,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
});

export let beAPIResourceLeadGen = {
    resourceApi: axiosInstanceLeadGen,
    get: generateResourceMethod(axiosInstanceLeadGen, "get", "be"),
    post: generateResourceMethod(axiosInstanceLeadGen, "post", "be"),
    put: generateResourceMethod(axiosInstanceLeadGen, "put", "be"),
    delete: generateResourceMethod(axiosInstanceLeadGen, "delete", "be")
};

const
    hideLoader = () => document.body.classList.remove("loading"),
    showLoader = () => document.body.classList.add("loading");

export function generateResourceMethod(resouceApi, method, resourceType) {
    /*eslint-disable */
    return function (path, data, config = {}) {
        showLoader();
        let apiCall, globalErrorHandling = true;
        if (config.formData) {
            config.headers = formDataHeaders;
            data = qs.stringify(data);
            delete config.formData;
        }
        if (typeof config.globalErrorHandling !== "undefined") {
            globalErrorHandling = config.globalErrorHandling;
            delete config.globalErrorHandling;
        }

        if (toLower(method) === "get" || toLower(method) === "delete") {
            if (data && Object.keys(data).length) {
                config.params = data;
            }
            apiCall = resouceApi[method](path, config);
        } else {
            apiCall = resouceApi[method](path, data, config);
        }

        return apiCall.then(
            responseWrapper => {
                hideLoader();
                // extracting response from responseWrapper ( papi layer )
                let { data: { response, status } } = responseWrapper;
                // Normalizing default resource and BE resource response here
                if (resourceType === 'default') {

                    if (!response) {
                        response = responseWrapper.data;
                    }

                    if (!status || status != 200) {

                        if (globalErrorHandling) {
                            globalApiErrorHandler({ response, status });
                        }

                        throw { data: response, status };
                    } else if (status == 200) {
                        return { data: response, status };
                    }

                } else if (resourceType === 'be') {
                    let { data, status } = responseWrapper;
                    return { data, status }
                } else if (resourceType === 'upload') {
                    let { data, status } = responseWrapper;
                    return { data, status }
                }
            },
            error => {
                hideLoader();
                const response = error.response || {};
                let status = response.status;

                if (status === 403) {
                    store.dispatch(logoutRequest());
                }

                if (typeof status === "undefined") {
                    status = -1;
                }

                if (globalErrorHandling) {
                    globalApiErrorHandler({ response, status });
                }

                // throw error to keep the promise chain
                throw { data: response, status };
            }
        );
    };
}

const globalApiErrorHandler = ({ response: errorBody, status }) => {

    console.log(`--- Global Api Error Handler --- \nError Status : ${status} \nError Body : `, errorBody);

    switch (status) {
        case 0:
            console.log("Api Call Error : Not connected. Please verify your network connection.", { errorBody, status });
            break;

        case 401:
            console.log("Api Call Error : Unauthorized access", { errorBody, status });
            break;

        case 404:
            console.log("Api Call Error : Method not found", { errorBody, status });
            break;

        case 405:
            console.log("Api call error : Method not allowed", { errorBody, status });
            break;

        case 500:
            console.log('Api Call Error : Internal Server Error.', { errorBody, status });
            break;
    }

};

export default axios;

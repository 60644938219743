import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

const RedirectWrapper = ({ location, state }) => {
    localStorage.setItem("return_url", location.pathname);
    return <Redirect to={{pathname: "/", state}} />;
};

RedirectWrapper.propTypes = {
    location: PropTypes.object.isRequired,
    state: PropTypes.object
};

export default RedirectWrapper;

import React, { Component } from "react";
import { Button } from "antd";
import "./FlyWheel.scss";
import lookup from './FlyWheel.json';

class FlyWheel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: "",
            isLocked: false
        };

        this.popupTimeout = null;
    }

    handleOnMouseLeave = (e) => {
        e.preventDefault();
        this.setState({ selected: "" });
    }

    handleOnMouseOver = (e, data) => {
        e.preventDefault();
        if (this.state.isLocked) return;
        if (this.popupTimeout) clearTimeout(this.popupTimeout);
        this.popupTimeout = setTimeout(() => {
            this.setState({ selected: data.key })
        }, 300);
    }

    handlePopOnMouseOver = (e, key) => {
        e.preventDefault();
        this.setState({ isLocked: true, selected: key });
    };

    handlePopOnMouseLeave = (e) => {
        e.preventDefault();
        this.setState({ isLocked: false, selected: "" });
    }


    renderPopup(key) {
        const content = lookup.popup.hasOwnProperty(key) ? lookup.popup[key] : null;
        
        if (!content) {
            this.setState({ selected: {} });
            return;
        }

        return (
            <div className="popup" style={{ ...content.position }} onMouseOver={e => this.handlePopOnMouseOver(e, key)} onMouseLeave={this.handlePopOnMouseLeave}>
                <i className={content.icon} />
                <h4>{ content.title }</h4>
                <p>{ content.description }</p>
                <Button type="primary" href={ content.cta.path }>{ content.cta.label }</Button>
            </div>
        );
    }

    render() {
        const { imgPath, areas } = lookup;
        const { selected } = this.state;

        return (
            <div className="container">
                <img src={imgPath} useMap="#fly_wheel" height="500" width="500" alt="Birdeye Product Fly Wheel" onMouseLeave={this.handleOnMouseLeave} />
                <map name="fly_wheel">
                    {
                        areas.map((area, i) => (
                            <area key={i} alt={area.title} shape={area.shape} coords={area.coords} onMouseOver={(e) => this.handleOnMouseOver(e, area)} />
                        ))
                    }
                </map>
                {
                    selected && this.renderPopup(selected)
                }
            </div>
        )
    }
}

FlyWheel.defaultProps = {};

export default FlyWheel;
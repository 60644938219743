import React, { Component } from "react";
import { Form, Input, Button, Spin, Modal, Checkbox, Row, Col, Radio, Select } from "antd";

import { RedirectWrapper, TableSkeleton } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";
import PropTypes from "prop-types";
import _ from "lodash";

const { Option } = Select;
const FormItem = Form.Item;

const source = {
    options: [
        {label: "Elastic", value: "elastic", disabled: false},
        {label: "Hubspot", value: "hubspot", disabled: false},
        {label: "Mailgun", value: "mailgun", disabled: false},
        {label: "SalesLoft", value: "salesloft", disabled: true},
        {label: "Salesforce", value: "sfdc", disabled: true}
    ],
    values: ["elastic", "hubspot", "mailgun"],
    contactDetailsHeader: [
        { label: "Source", key: "source" },
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Domain", key: "domain" },
        { label: "Status", key: "status" },
        { label: "Reason", key: "reason" },
        { label: "Last email received", key: "last_email_received" },
        { label: "Last activity", key: "last_activity" }
    ]
};

const unsubReasonsList = ["unsubscribed","spam","bounced","dropped","already_customer","bounce","spamreport","unsubscribe","no_clicks","leadgen_reply","domain_blacklisted"];

class ContactForm extends Component {

    constructor (props) {
        super(props);

        this.state = {
            indeterminate: false,
            checkAll: true,
            formValues: {
                type: 'email',
                domain: null,
                reason: null,
                email: null
            },
            isSearchComplete: false
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((error, values) => {
            if (!error) {
                const { type, source, email, domain, reason, unsub_source } = values;
                const { type: pageType } = this.props.match.params;
                const payload = {};
                if (pageType === 'subscribe' ) {
                    payload.sources = source.length === source.values.length ? ["all"] : this.handleLoweCase(source);
                } else {
                    payload.sources = this.handleLoweCase(unsub_source);
                }

                const isDomain = (type === "domain") ? true : false;
                const listItems = (type === "domain") ? this.handleLoweCase([domain]) : (pageType === "subscribe" ? this.handleLoweCase([email]) : this.handleLoweCase([email]));

                payload.listItems = listItems;
                payload.isDomain = isDomain;
                payload.reason = reason;

                this.setState({formValues: values}, () => {
                    // To preserve form values for failure case.
                    this.props.sendContactRequest(pageType , payload);
                });
            }
        });
    };

    handleLoweCase = (values) => {
        const lowercaseValues = [];
        values.map(value => {
            lowercaseValues.push(value.toLowerCase());
        })
        return lowercaseValues;
    }

    handleEmailAndDomain = (e) => {
        this.setState(() => ({
            formValues: {
                type : e.target.value
            },
            isSearchComplete: false
        }))
    }

    handleCheckAll = (e) => {
        this.props.form.setFieldsValue({"source" : e.target.checked ? source.values : []});
        this.setState({
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };

    handleCheckGroup = (checkedList) => {
        this.props.form.setFieldsValue({"source" : checkedList});
        this.setState({
            indeterminate: !!checkedList.length && (checkedList.length < source.values.length),
            checkAll: checkedList.length === source.values.length,
        });
    };

    handleModalMethodClose = (preserveForm = false) => {
        return new Promise((resolve, reject) => {
            this.props.hideContactModalMethod();
            if (preserveForm) {
                this.props.form.setFieldsValue(this.state.formValues);
            } else {
                this.setState(() => ({
                    formValues: {
                        type : 'email'
                    },
                    indeterminate: false, 
                    checkAll: true,
                    isSearchComplete: false
                }));
            }
            resolve();
        });
    };

    handleModalMethod = (modalObj) => {
        if (modalObj.visible) {
            switch (modalObj.type) {
                case "success":
                    Modal.success({
                        title: "Success!!!",
                        content: modalObj.message || "Action performed successfully.",
                        onOk: this.handleModalMethodClose
                    });
                    break;
                case "error":
                    Modal.warn({
                        title: "Oops!!!",
                        content: modalObj.message || "Something went wrong.",
                        onOk: () => {this.handleModalMethodClose(true)}
                    });
                    break;
            }
        }
    };

    formatData = (data) => {
        return data.map( (record, idx) => {
            const domain =  record.emailId ? record.emailId.split('@')[1] : '-';
            return {
                ...record,
                key: record.key ? record.key : record.emailId+"_"+idx,
                title:  record.label,
                source: record.source ? record.source : '-',
                email: record.emailId,
                domain: record.domain ? record.domain : domain,
                status: record.unsubscribed || record.unsubscribed === null ? "unsubscribed" : "subscribed",
                reason: record.unsubscriptionReason ? record.unsubscriptionReason : "-",
                last_email_received: record.emailLog ? record.emailLog.emailType : "-",
                last_activity: record.emailLog ?  new Date(record.emailLog.eventDate).toDateString() : "-"
            }
        })
    }

    handleFindContacts = (e) => {
        e.preventDefault();
        this.props.form.validateFields(["type", "email","domain"], (error, values) => {
            if (!error) {
                const { type, email, domain} = values;
                const { type: pageType } = this.props.match.params;
                const payload = {};
                const isDomain = (type === "domain") ? true : false;
                const listItems = (type === "domain") ? domain : email;

                payload.listItems = listItems;
                payload.isDomain = isDomain;

                this.setState({formValues: values, isSearchComplete: true }, () => {
                    this.props.reqContactDetails(pageType, payload);
                });
            }
        })
    }

    componentDidUpdate = () => {
        this.handleModalMethod(this.props.modalMethod);
    };

    render() {
        const { error, userData, isLoading } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { type: pageType } = this.props.match.params;
        const { contactDetails, errorMessage } = this.props.pageData;
        const { formValues, isSearchComplete } = this.state;
        const formattedData =  this.formatData(contactDetails);

        const unauthorized = error === "unauthorized" || !userData.name;

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                const selectedSource = selectedRows.map((values) => values.source);
                this.props.form.setFieldsValue({"unsub_source" : selectedSource});
            },
            getCheckboxProps: record => ({
                disabled: record.status === 'inactive',
                name: record.name,
            }),
        };

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        if(isLoading) {
            return <Spin />;
        }

        return (
            <div>
                <h2>
                    { pageType === "unsubscribe" ? "Unsubscribe" : "Subscribe" }
                </h2>
                <Form onSubmit={this.handleSubmit}>
                    {
                        pageType !== "subscribe" ?
                            (
                                <FormItem {...formItemLayout} label="Type">
                                    {getFieldDecorator("type", {
                                        initialValue: formValues.type
                                    })(
                                        <Radio.Group buttonStyle="solid" onChange={this.handleEmailAndDomain} >
                                            <Radio.Button value="email">Email</Radio.Button>
                                            <Radio.Button value="domain">Domain</Radio.Button>
                                        </Radio.Group>
                                    )}
                                </FormItem>
                            ) : ("")
                    }
                    { formValues.type == "domain" && pageType !== "subscribe" ?
                        (
                            <div>
                                <FormItem {...formItemLayout} label="Domain">
                                    {
                                        getFieldDecorator("domain", {
                                            initialValue: formValues.domain,
                                            rules: [{
                                                required: true, message: "Please provide a domain to unsubscribe"
                                            }, {
                                                validator: (rule, domain, cb) => {
                                                    if (!domain.match(/^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/)) {
                                                        return cb("Invalid Domain " + '"'+ domain + '"');
                                                    }
                                                    cb();
                                                }
                                            }]
                                        })(
                                            <Input
                                                placeholder="Enter Domains"
                                            />
                                        )
                                    }
                                </FormItem>
                            </div>
                        ) : (
                            pageType !== "subscribe" ? (
                                <div>
                                    <FormItem {...formItemLayout} label="Email">
                                        {
                                            getFieldDecorator("email", {
                                                initialValue: formValues.email,
                                                rules: [{
                                                    required: true,
                                                    message: "Please provide emails to unsubscribe"
                                                }, {
                                                    validator: (rule, email, cb) => {
                                                        if (!email.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,8})$/)) {
                                                            return cb("Invalid Email " + '"'+ email + '"');
                                                        }
                                                        cb();
                                                    }
                                                }]
                                            })(
                                                <Input
                                                    placeholder="Enter Emails"
                                                />
                                            )
                                        }
                                    </FormItem>
                                </div>
                            ) : (
                                <FormItem {...formItemLayout} label="Email">
                                    {
                                        getFieldDecorator("email", {
                                            rules: [{
                                                type: "email", message: "This input is not valid E-mail"
                                            }, {
                                                required: true, message: "Please provide an email to unsubscribe"
                                            }]
                                        })(
                                            <Input
                                                placeholder={"Enter email"}
                                            />
                                        )
                                    }
                                </FormItem>
                            )
                        )
                    }
                    {
                        pageType !== 'subscribe' && (
                            <FormItem {...formButtonLayout}>
                                <Button type="primary" onClick={this.handleFindContacts}>Find Contact</Button>
                            </FormItem>
                        )
                    }
                    {
                        pageType === "unsubscribe" && isSearchComplete && formattedData.length > 0 ? (
                            <FormItem {...formItemLayout} label="Reason">
                                {
                                    getFieldDecorator("reason", {
                                        initialValue: formValues.reason,
                                        rules: [{
                                            required: true, message: "Please select a reason to unsubscribe"
                                        }]
                                    })(
                                        <Select showSearch placeholder="Select Reason to unsubscribe">
                                            {
                                                unsubReasonsList.map((option, index) => {
                                                    return (
                                                        <Option key={index} value={option} >{option}</Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                        ) : (
                            <div></div>
                        )
                    }
                    {
                        pageType === "unsubscribe" && isSearchComplete && formattedData.length > 0  && (
                            <FormItem {...formButtonLayout} >
                                {
                                    getFieldDecorator("unsub_source", {
                                        rules: [{
                                            required: true, message: "Please provide a source to unsubscribe"
                                        }]
                                    })(
                                        <TableSkeleton
                                            rowSelection={rowSelection}
                                            columns={this.formatData(source.contactDetailsHeader)}
                                            dataSource={formattedData}
                                        />
                                    )
                                }
                            </FormItem>
                        )
                    }
                    {   pageType === 'subscribe' &&
                        <FormItem {...formItemLayout} label={"Source"}>
                        <Checkbox indeterminate={this.state.indeterminate} onChange={this.handleCheckAll} checked={this.state.checkAll}>All</Checkbox>
                        <hr/>
                        {
                            getFieldDecorator("source", {
                                initialValue: source.values,
                                rules: [{
                                    required: true, message: "Please provide a source to unsubscribe"
                                }]
                            })(
                                <Checkbox.Group style={{width: "100%"}} onChange={this.handleCheckGroup}>
                                    <Row>
                                        {
                                            source.options.map((option) => {
                                                return (
                                                    <Col span={8} key={option.value}>
                                                        <Checkbox value={option.value} disabled={option.disabled}>{option.label}</Checkbox>
                                                    </Col>
                                                );
                                            })
                                        }
                                    </Row>
                                </Checkbox.Group>
                            )
                        }
                        </FormItem>
                    }
                    {   
                        pageType === 'subscribe' && 
                        <FormItem {...formButtonLayout}>
                            <Button type="primary" htmlType="submit">Subscribe</Button>
                        </FormItem>
                    }
                    {
                        pageType === 'unsubscribe' && contactDetails.length > 0 && isSearchComplete &&
                        (
                            <FormItem {...formButtonLayout}>
                                <Button type="primary" htmlType="submit">Unsubscribe</Button>
                            </FormItem>
                        )
                    }
                    {
                        pageType === 'unsubscribe' && isSearchComplete && contactDetails.length == 0 && (
                            <FormItem {...formButtonLayout}>
                                {
                                    !_.isEmpty(errorMessage) ? <span style={{color: "#ff4d4f" }}> {errorMessage.message} </span> : <span> Email not found / Domain not found </span>
                                }
                            </FormItem>
                        )
                    }
                </Form>
            </div>
        );
    }
}

ContactForm.propTypes = {
    match: PropTypes.object.isRequired,
    modalMethod: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    sendContactRequest: PropTypes.func.isRequired,
    reqContactDetails: PropTypes.func.isRequired,
    hideContactModalMethod: PropTypes.func.isRequired
};
const Contact = Form.create()(ContactForm);
export default Contact;

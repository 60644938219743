import React, { Component } from "react";
import PropTypes from "prop-types";
import { pick, includes } from "lodash";
import moment from 'moment-timezone';
import { Form, Input, Select, Button, Alert, Spin, Modal, Checkbox, TimePicker } from "antd";

import { RedirectWrapper } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";
import lookup from "./users.json";

const Option = Select.Option;
const FormItem = Form.Item;

// Constants for user time preference
const TIME_FORMAT       = "HH:mm:ss";
const DEFAULT_TO_TIME   = '17:00:00';
const DEFAULT_FROM_TIME = '08:00:00';

class SalesRepForm extends Component {
    state = {
        data: {
            id: 0,
            name: "",
            role: "",
            emailId: "",
            timeZone: "US/Alaska (AKST)",
            pageUrlIds: [],
            active: false,
            preferableStartTime: DEFAULT_FROM_TIME,
            preferableEndTime: DEFAULT_TO_TIME
        },
        preferredTime: true,
        formError: "",
        isEditPage: !!this.props.match.params.id,
        modalType: "",
        timeZonesList: []
    };

    componentDidMount() {
        const { location, match, fetchSalesRepRequest, fetchPagesListRequest, fetchSalesRepTimeZonesRequest} = this.props;
        fetchPagesListRequest();
        fetchSalesRepTimeZonesRequest();
        if (location.state && location.state.data) {
            this.setState({ data: this.mapDataToForm(location.state.data) });
        } else if (match.params.id) {
            fetchSalesRepRequest({ id: match.params.id });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.curSalesData.id !== prevProps.curSalesData.id) {
            const data = this.mapDataToForm(this.props.curSalesData);
            this.setState({ data: data, preferredTime: !!(data.preferableStartTime && data.preferableEndTime)});
        }

        if(this.props.salesRepTimeZones !== prevProps.salesRepTimeZones) {
            this.setState({timeZonesList: this.props.salesRepTimeZones || []})
        }

        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                isEditPage: !!this.props.match.params.id,
                data : {
                    id: 0,
                    name: "",
                    role: "",
                    emailId: "",
                    timeZone: "",
                    pageUrlIds: [],
                    active: ""
                }
             });
        }
    }

    mapDataToForm = (rawData) => {
        rawData.pageUrlIds = (() => {
            let pageId = [];
            rawData.pageUrlMessages.map((page) => {
                pageId.push(page.id);
            })
            return pageId;
        })();
        rawData.active = rawData.active === true || rawData.active === "Yes" ? true : false ;

        let data = pick(rawData, [
            "id",
            "name",
            "role",
            "emailId",
            "timeZone",
            "pageUrlIds",
            "active",
            "preferableStartTime",
            "preferableEndTime"
        ]);

        return data;
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.handleFieldChange(name, value);
    };

    setTimePreference = (timeString, fieldName) => {
        this.setState({
            data: {
                ...this.state.data,
                [fieldName]: timeString
            }
        });
    };

    setFromTime =(time, timeString) => {
        this.setTimePreference(timeString, 'preferableStartTime');
    };

    setToTime = (time, timeString) => {
        this.setTimePreference(timeString, 'preferableEndTime');
    };

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        this.setState({ data, formError: "" });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let rawData = { ...this.state.data };
        const currentDate = moment(new Date()).format("YYYY-MM-DD");
        let requiredFields = pick(rawData, [
            "name",
            "emailId",
            "timeZone",
        ]);

        if (
            includes(requiredFields, "")
        ) {
            this.setState({ formError: "Please fill in all relevant fields!" });
            return;
        }

        let payLoadData = pick(rawData, [
            "name",
            "emailId",
            "role",
            "timeZone",
            "active",
            "pageUrlIds",
            "preferableStartTime",
            "preferableEndTime"
        ]);
        const { preferableStartTime, preferableEndTime, timeZone } = payLoadData;

        const {renderFeedback} = this;
        const {addSalesRepRequest, updateSalesRepRequest} = this.props;
        const {isEditPage } = this.state;
        const { id } = this.state.data;

        const preferableStartConvertedTime = moment.tz(currentDate+" "+preferableStartTime, timeZone).tz('America/Chicago').format("HH:mm:ss");
        const preferableEndConvertedTime = moment.tz(currentDate+" "+preferableEndTime, timeZone).tz('America/Chicago').format("HH:mm:ss");
        const startTime = moment(preferableStartConvertedTime, 'h:mma');
        const endTime = moment(preferableEndConvertedTime, 'h:mma');
        const minTime = moment(DEFAULT_FROM_TIME, 'h:mma');
        const maxTime = moment(DEFAULT_TO_TIME, 'h:mma');

        if(startTime.isBefore(minTime) || endTime.isAfter(maxTime)){
            return Modal.confirm({
                title: `Timing Warning`,
                content: `Sales Rep's preferable working hours in CST : 08:00:00 to 17:00:00 and your input preferable time is `+
                          `${preferableStartConvertedTime}`+` to `+
                            `${preferableEndConvertedTime}`+
                          `. Are you sure to add this Sales Rep? `,

                okText: "Yes",
                cancelText: "No",
                maskClosable: true,
                mask: true,
                closable: true,
                onOk() {
                    if (isEditPage) {
                        let data = {
                            id: id,
                            data: payLoadData
                        };
                        updateSalesRepRequest({ data,  cb: renderFeedback });
                    } else {
                        let data = payLoadData;
                        addSalesRepRequest({ data, cb: renderFeedback });
                    }
                },
                onCancel() {
                    return;
                }
            });
        }


        if (this.state.isEditPage) {
            let data = {
                id: this.state.data.id,
                data: payLoadData
            };
            this.props.updateSalesRepRequest({ data,  cb: this.renderFeedback });
        } else {
            let data = payLoadData;
            this.props.addSalesRepRequest({ data, cb: this.renderFeedback });
        }
    };

    handleModalOpen = (type) => {
        this.setState({ modalType: type });
    };

    handleModalClose = () => {
        this.setState({ modalType: "" });
    };

    renderFeedback = ({ userErrorType, errorMessage }) => {
        const goToUsersPage = () => this.props.history.push("/sales-reps");
        if (!userErrorType) {
            return Modal.success({
                title: `You have successfully ${
                    this.state.isEditPage ? "edited" : "added"
                } a user`,
                content: `Check the ${
                    this.state.isEditPage ? "updated" : "new"
                } record in sales rep list page`,
                okText: "Go to sales reps page",
                maskClosable: true,
                onOk() {
                    goToUsersPage();
                }
            });
        } else {
            return Modal.warning({
                title: `The record was not ${
                    this.state.isEditPage ? "edited" : "added"
                } successfully...`,
                content: errorMessage || "Please check your input or try again later..."
            });
        }
    };

    renderInfoModal = () => {
        const type = this.state.modalType;
        const gCalendarSteps = (
            <ol>
                <li>
                    <h4>Go to Google calendar page</h4>
                    <ul>
                        <li>Login into your Birdeye gmail.</li>
                        <li>Click the <b>"Google apps"</b> button (the matrix icon) on the upper right corner</li>
                        <li>In the expanded menu, click <b>"Calendar"</b>.</li>
                        <li>In the newly opened page, check again that the user information in the upper right corner is the correct Birdeye account.</li>
                    </ul>
                    <br/>
                </li>
                <li>
                    <h4>Open calendar setting page</h4>
                    <ul>
                        <li>In the left sidebar of this page, there is a section called <b>"My calendars"</b>. Beneath it, there is a row with your name. Mouseover to this row.</li>
                        <li>When hovering over, a button with vertical ellipsis should be shown on the right hand side, indicating more options. Click on this ellipsis.</li>
                        <li>In the expanded menu, click <b>"Settings and sharing"</b>.</li>
                    </ul>
                    <br/>
                </li>
                <li>
                    <h4>Add appropriate settings</h4>
                    <ul>
                        <li>In the newly opened <b>"Calendar settings"</b> page, find section <b>"Access permissions"</b>. Enable the option <b></b>"Make available for Birdeye".</li>
                        <li>On the same page, find the section <b>"Share with specific people"</b>.</li>
                        <li>Click on the "Add people" button in this section.</li>
                        <li>In the pop up modal, copy and paste this url <b>billing-calander@aggregation-search-api.iam.gserviceaccount.com</b> to the <b>"Add email or name"</b> field. Then, in the  permissions dropdown, select the option <b></b>"Make changes to events". Click <b>"Send"</b> to save the settings.</li>
                    </ul>
                    <br/>
                </li>
            </ol>
        );
        const sfidSteps = (
            <ul>
                <li>Open your profile page in SFDC</li>
                <li>Copy the SFDC ID from the url (look at u parameter)</li>
                <li>Example: ?u=00536000001zPd6</li>
            </ul>
        );

        return  <Modal
          footer={null}
          title={type === "emailId" ? "Steps to grant permission from your Google calendar" : "Steps to obtain your Salesforce ID"}
          visible={!!this.state.modalType}
          onOk={this.handleModalClose}
          onCancel={this.handleModalClose}
        >
            {type === "emailId" ? gCalendarSteps : sfidSteps}
        </Modal>
    };

    setUserTimePreference = preferredTime => {
        // const data = {
        //     ...this.state.data,
        //     preferableStartTime: preferredTime ?  this.state.data.preferableStartTime || DEFAULT_FROM_TIME : null,
        //     preferableEndTime  : preferredTime ?  this.state.data.preferableEndTime || DEFAULT_TO_TIME   : null
        // };
        // this.setState({preferredTime, data});
    };

    renderPagesForSalesRep = () => {
        const { pageData } = this.props;
        const { pageUrlIds } = this.state.data;

        const pages = pageData.filter((p) => {
            return p.active || pageUrlIds.includes(p.id);
        });

        return (
            pages.map((page) => {
                return (
                    <Option
                        value={page.id}
                        key={page.id}
                        disabled={!page.active}
                    >
                        {page.type}
                    </Option>
                );
            })
        );
    }

    renderFormBlock = () => {
        const {
            name,
            pageUrlIds,
            emailId,
            timeZone,
            active,
            preferableStartTime,
            preferableEndTime,
            role
        } = this.state.data;
        const curDate = moment(new Date()).format("YYYY-MM-DD");
        if (this.state.isEditPage && this.props.userError === "userNotFound") {
            return <Alert message="User not found" type="userError" showIcon />;
        } else {
            return (
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Name" required={true}>
                        <Input
                            name="name"
                            value={name}
                            placeholder="Employee Name"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Role">
                        <Input
                            name="role"
                            value={role}
                            placeholder="Employee Role"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Email" required={true}>
                        {
                            this.state.isEditPage ?  <span>{emailId}</span>
                                :  <Input
                                    name="emailId"
                                    value={emailId}
                                    placeholder="Birdeye email"
                                    onChange={this.handleInputChange}
                                />
                        }
                        <span className="form-footnote text-link" onClick={() => this.handleModalOpen("emailId")}>Steps to grant permission from your Google calendar</span>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Timezone" required={true}>
                        <Select
                            mode=""
                            value={timeZone}
                            placeholder="Representative Timezone"
                            onChange={(value) =>
                                this.handleFieldChange("timeZone", value)
                            }>
                            {this.state.timeZonesList.map((tz) => (
                                <Option
                                    key={tz.timeZoneId}
                                    value={tz.timeZoneId}>
                                    {`${tz.timeZoneId} (${tz.timeZoneAbbreviation})`}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Preferred time" required={true}>
                        {
                            <div>
                                <TimePicker
                                    placeholder='From'
                                    name='preferableStartTime'
                                    minuteStep={30}
                                    value={moment(preferableStartTime || DEFAULT_FROM_TIME, TIME_FORMAT)}
                                    defaultValue={moment(DEFAULT_FROM_TIME, TIME_FORMAT)}
                                    format={TIME_FORMAT}
                                    onChange={this.setFromTime}
                                />
                                <label> - </label>
                                <TimePicker
                                    placeholder='To'
                                    name='preferableEndTime'
                                    minuteStep={30}
                                    value={moment(preferableEndTime || DEFAULT_TO_TIME, TIME_FORMAT)}
                                    defaultValue={moment(DEFAULT_TO_TIME, TIME_FORMAT)}
                                    format={TIME_FORMAT}
                                    onChange={this.setToTime}
                                />
                            </div>
                        }
                        <span>America/Chicago (CST) Time: {moment.tz(curDate+" "+preferableStartTime, timeZone).tz('America/Chicago').format("HH:mm:ss")} To {moment.tz(curDate+" "+preferableEndTime, timeZone).tz('America/Chicago').format("HH:mm:ss")}</span>
                    </FormItem>
                    {
                        this.props.pageData.length !== 0 && (
                            <FormItem {...formItemLayout} label="Associate on Pages">
                                <Select
                                    mode="multiple"
                                    value={pageUrlIds}
                                    placeholder="User calendar visible at"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    onChange={(value) =>
                                        this.handleFieldChange("pageUrlIds", value)
                                    }>
                                    {this.renderPagesForSalesRep()}
                                </Select>
                            </FormItem>
                        )
                    }
                    <FormItem {...formItemLayout} label="Active">
                            <Checkbox
                                checked={active}
                                onChange={
                                    (e) => this.handleFieldChange("active", e.target.checked)
                                    }
                                >Active</Checkbox>
                    </FormItem>
                    {/* <FormItem {...formItemLayout} label="Enabled">
                        <Select
                            value={active.toString()}
                            placeholder="Enabled"
                            onChange={(value) =>
                                this.handleFieldChange("active", value)
                            }>
                            {lookup.valueList.activationStatuses.map((status) => (
                                <Option
                                    key={status.value}
                                    value={status.value}>
                                    {status.label}
                                </Option>
                            ))}
                        </Select>
                    </FormItem> */}
                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit">
                            {this.state.isEditPage ? "Save" : "Add"}
                        </Button>
                    </FormItem>
                </Form>
            );
        }
    };

    render() {
        const { loadingUser, userError, userData, loadingRep } = this.props;

        const unauthorized = userError === "unauthorized" || !userData.name;
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="users-root">
                <h2>{this.state.isEditPage ? "Edit" : "Add New"} Employee</h2>
                {(this.state.isEditPage && loadingUser) || (loadingRep) ? (
                    <Spin />
                ) : (
                    this.renderFormBlock()
                )}
                {
                    this.renderInfoModal()
                }
            </div>
        );
    }
}

const UpdateSalesRep = Form.create()(SalesRepForm);

UpdateSalesRep.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loadingUser: PropTypes.bool.isRequired,
    userError: PropTypes.string.isRequired,
    curSalesData: PropTypes.object.isRequired,
    pageData: PropTypes.array.isRequired,
    userData: PropTypes.object.isRequired,
    fetchSalesRepRequest: PropTypes.func.isRequired,
    updateSalesRepRequest: PropTypes.func.isRequired,
    addSalesRepRequest: PropTypes.func.isRequired,
    fetchPagesListRequest: PropTypes.func.isRequired,
    fetchSalesRepTimeZonesRequest: PropTypes.func.isRequired
};

export default UpdateSalesRep;

import { beAPIResource } from "../../utils/apiHelper";

const pressApi = {
    getAll: () => beAPIResource.get("support/press/group/releasedate"),
    getById: (id) => beAPIResource.get(`support/press/${id}`),
    add: (data) => beAPIResource.post("support/press/", data),
    update: (data) =>
        beAPIResource.put(`support/press/${data.id}`, data),
    delete: (id) => beAPIResource.delete(`support/press/${id}`)
};

export default pressApi;

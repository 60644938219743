import { connect } from "react-redux";

import {
    fetchFeaturesListRequest, deleteFeatureRequest, fetchFeatureRequest, addFeatureRequest, updateFeatureRequest, fetchBusinessTypeRequest, fetchApplicableModuleRequest, fetchReleaseTypesRequest,
    fetchNewsletterListing, openNewsLetterPreview, closeNewsLetterPreview
} from "./actions";

import FeatureReleases from "./FeatureReleases";
import UpdateFeatureRelease from "./UpdateFeatureRelease";
import Newsletter from "./Newsletter";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Features
});

export const FeatureReleasesPage = connect(
    mapStateToProps,
    { fetchFeaturesListRequest, deleteFeatureRequest, updateFeatureRequest, fetchBusinessTypeRequest, fetchApplicableModuleRequest, fetchReleaseTypesRequest }
)(FeatureReleases);

export const UpdateFeatureReleasePage = connect(
    mapStateToProps,
    { fetchFeatureRequest, addFeatureRequest, updateFeatureRequest, fetchBusinessTypeRequest, fetchApplicableModuleRequest, fetchReleaseTypesRequest  }
)(UpdateFeatureRelease);

export const NewsletterPage = connect(
    mapStateToProps,
    {fetchNewsletterListing, openNewsLetterPreview, closeNewsLetterPreview}
)(Newsletter);
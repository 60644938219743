import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Select, Button, Spin, Modal } from "antd";
import { isEmpty } from "lodash";

import { RedirectWrapper } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";
import { beAPIResource } from "../../utils/apiHelper";

const Option = Select.Option;
const FormItem = Form.Item;

const envOptions = [
    {label: "Free to Free", value: "local"},
    {label: "Free to Paid", value: "paid"}
];

class TransferBusinessForm extends Component {
    state = {
        formData: {
            businessNumber: null,
            redirectionId: null,
            env: null
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { businessNumber, env, redirectionId } = this.state.formData;
        if ( isEmpty(businessNumber) || isEmpty(env) || isEmpty(redirectionId) ) {
            this.setState({formError: "Fill in all the relevant fields"});
            return;
        };
        beAPIResource.post("/support/freeprod/business/transfer", this.state.formData).then(() => {
            this.setState({
                isLoading: false,
                formError: ""
            }, () => {
                return Modal.success({
                    title: `The business was transferred successfully`,
                    content: ""
                });
            });
        }).catch((err) => {
            this.setState({
                isLoading: false,
                formError: ""
            }, () => {
                return Modal.warning({
                    title: `The business could not be transferred`,
                    content: (err.data && err.data.data && err.data.data.message) || ""
                });
            });
        });
    }

    handleInputChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            this.handleFieldChange(name, value);
        }
    }

    handleFieldChange = (name, value) => {
        let formData = { ...this.state.formData };
        formData[name] = value;
        this.setState({formData, formError: ""});
    }

    render() {
        const { businessNumber, env, redirectionId } = this.state.formData;
        const { error, userData } = this.props;

        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        if(this.state.isLoading) {
            return <Spin />;
        }

        return (
            <div>
                <h2>Transfer Business</h2>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Business Number">
                        <Input
                            name="businessNumber"
                            value={businessNumber}
                            placeholder="Enter Business Number"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Redirection ID">
                        <Input
                            name="redirectionId"
                            value={redirectionId}
                            placeholder="Enter Redirection ID"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Environment">
                        <Select
                            value={env || []}
                            placeholder="Select environment"
                            onChange={(value) =>
                                this.handleFieldChange("env", value)
                            }>
                            {envOptions.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit">
                            Transfer
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

const TransferBusiness = Form.create()(TransferBusinessForm);

export default TransferBusiness;

import { connect } from "react-redux";

import { reqOverviewPageData, reqDetailCategories, reqDetailTableContent, reqSummaryPageData, reqEmailsPageData } from "./actions";

import Overview from "./Overview";
import Detail from "./Detail";
import Summary from "./Summary";
import Emails from "./Emails";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.FreeProdDashboard
});

export const FreeProdOverviewPage = connect(
    mapStateToProps,
    { reqOverviewPageData }
)(Overview);

export const FreeProdDetailPage = connect(
    mapStateToProps,
    { reqDetailCategories, reqDetailTableContent }
)(Detail);

export const FreeProdSummaryPage = connect(
    mapStateToProps,
    { reqSummaryPageData }
)(Summary);

export const FreeProdEmailsPage = connect(
    mapStateToProps,
    { reqEmailsPageData }
)(Emails);

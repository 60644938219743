import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { includes } from "lodash";
import { Form, Input, Button, Alert, Spin, Modal } from "antd";

import { RedirectWrapper } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";

const FormItem = Form.Item;
const { TextArea } = Input;

class EffectCategoryForm extends Component {
    state = {
        data: {
            label: "",
            headerDesc: "",
            footerDesc: ""
        },
        formError: ""
    };

    componentDidMount() {
        const { location, match, fetchCategoryRequest } = this.props;
        if (location.state && location.state.data) {
            this.setState({ data: {...location.state.data} });
        } else {
            fetchCategoryRequest({ id: match.params.id });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.curCategoryData.id !== prevProps.curCategoryData.id) {
            this.setState({ data: {...this.props.curCategoryData} });
        }
    }

    handleInputChange = (e, imageFieldName) => {
        const { name, value } = e.target;
        this.handleFieldChange(name, value);
    };

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        this.setState({ data, formError: "" });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {data} = this.state;
        if (includes(data, "")) {
            this.setState({ formError: "Please fill in all relevant fields!" });
            return;
        }
        this.props.updateCategoryRequest({ data, cb: this.renderFeedback });
    };

    renderFeedback = (errorType) => {
        const goToEffectsPage = () => this.props.history.push("/effects-categories");
        if (!errorType) {
            return Modal.success({
                title: "You have successfully edited this record",
                content: "Check the updated record in effects list page",
                okText: "Go to effects page",
                maskClosable: true,
                onOk() {
                    goToEffectsPage();
                }
            });
        } else {
            return Modal.warning({
                title: "The record was not edited successfully...",
                content: "Please check your input or try again later..."
            });
        }
    };

    renderFormBlock = () => {
        const {
            label,
            headerDesc,
            footerDesc
        } = this.state.data;

        if (this.state.isEditPage && this.props.error === "effectNotFound") {
            return <Alert message="Ind not found" type="error" showIcon />;
        } else {
            return (
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Industry Name">
                        <span className="ant-form-text">{label}</span>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Header Description">
                        <TextArea
                            name="headerDesc"
                            value={headerDesc}
                            placeholder="Industry description that appears in the header"
                            autosize={{ minRows: 2, maxRows: 6 }}
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Footer Description">
                        <TextArea
                            name="footerDesc"
                            value={footerDesc}
                            placeholder="Industry description that appears in the page bottom"
                            autosize={{ minRows: 2, maxRows: 6 }}
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </FormItem>
                </Form>
            );
        }
    };

    render() {
        const { isLoading, error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="effects-root">
                <h2>Edit Industry Description</h2>
                {isLoading ? (
                    <Spin />
                ) : (
                    this.renderFormBlock()
                )}
            </div>
        );
    }
}

const UpdateEffectsCategories = Form.create()(EffectCategoryForm);

UpdateEffectsCategories.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    curCategoryData: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    error: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchCategoryRequest: PropTypes.func.isRequired,
    updateCategoryRequest: PropTypes.func.isRequired
};

export default UpdateEffectsCategories;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { pick } from "lodash";
import { Form, Input, Button, Icon, Switch, Upload } from "antd";
import { MultiResolutionImage, TextEditor } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";
import lookup from "./integrations.json";
import {Select} from "antd/lib/index";
import twowayIcon from '../../../assets/images/repeat.svg';

const { TextArea } = Input;
const Option = Select.Option;
const FormItem = Form.Item;

class IntegrationForm extends Component {

    state = {
        formError: ""
    };
    timestamp = Date.now().toString();
    
    handleSubmit = (e) => {
        e.preventDefault();

        const rawData = pick(this.props.formData, ["enabled", "name", "domainId", "version", "heading", "blurb", "type", "availability", "triggerSupported", "location", "specialInstructions", "isPremium", "integrationTier", "countries", "products", "topLogo", "lessonlyLink", "trainingResourcesDoc", "trainingResourcesLink", "technicalDocumentFeedbackForm", "isExclusions"]);
        let formError = null;

        if (!rawData.domainId) {
            formError = "Domain is a required field";
        }

        if (!rawData.name) {
            formError = "CRM Name is a required field";
        }

        if (!rawData.integrationTier ) {
            formError = "Integration Tier is a required field";
        }

        if (!rawData.heading ) {
            formError = "Heading is a required field";
        }

        if(!rawData.isExclusions) {
            if (!(rawData.blurb && rawData.blurb.trim().length >= 600)) {
                formError = "Content requires minimum 600 characters";
            }

            if (!rawData.name && !rawData.domainId && !rawData.integrationTier) {
                formError = "Name, domain and Integration Tier are required fields";
            }
        }

        this.setState({
            formError
        });
        if (!formError) {
            this.props.updateStepInfo(true, false);
            this.props.handleSubmit(e);
        } else {
            this.props.updateStepInfo(false, false);
        }
    };

    getImageUploadUrl = (isHeading) => {
        const { name } = this.props.formData;
        const imgName = `birdeye-and-${name ? name.toLowerCase().split(" ").join("-") : "integration"}-${isHeading ? "banner" : "description"}`;
        return `${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=${imgName}&timestamp=${this.timestamp}&webp=true`;
    }

    render() {
        const { handleInputChange, handleFieldChange, domains, formData, handleCRMNameBlur, uploadingImage, handleDocumentUpload } = this.props;
        const { getFieldDecorator } = this.props.form;
        const {
            enabled,
            name,
            domainId,
            version,
            heading,
            blurb,
            type,
            availability,
            triggerSupported,
            location,
            specialInstructions,
            isPremium,
            integrationTier,
            url,
            technicalDocument,
            countries,
            products,
            topLogo,
            lessonlyLink,
            trainingResourcesDoc,
            trainingResourcesLink,
            technicalDocumentFeedbackForm,
            partnership,
            isPublished,
            isExclusions,
            isSalesforce
        } = formData;

        let types = [];
        let productModules = [];

        if(typeof type !== 'string'){
            types = type.map(function(type) {
                return type.props ? type.props.children : type;
            });
        }

        if(typeof products !== 'string'){
            productModules = products.map(function(product) {
                return  product.props ? product.props.children : product;
            });
        } 
        return (
            <Form onSubmit={this.handleSubmit}>
                {
                    !!(domains && domains.length) &&
                    <FormItem {...formItemLayout} label="Domain" extra="* Required">
                        <Select
                            value={domainId || []}
                            placeholder="Select integration domain"
                            onChange={(value) =>
                                handleFieldChange("domainId", value)
                            }>
                            {
                                domains.map(d => <Option key={d.id} value={d.id}>{d.domain}</Option>)
                            }
                        </Select>
                    </FormItem>
                }
                <FormItem {...formItemLayout} label="CRM name" extra="* Required">
                    <Input
                        name="name"
                        value={name}
                        placeholder="CRM name"
                        onChange={handleInputChange}
                        onBlur={handleCRMNameBlur}
                    />
                </FormItem>
                <FormItem {...formItemLayout} label={"Link"} extra={<p>CRM link is dynamically generated through the input provided at <strong>CRM name</strong></p>}>
                    <a href = { `${process.env.REACT_APP_WEBSITE_URL}integration/` + (url ? url.slice(-1)[0] !== "/" ? `${url}/` : url  : "") } target="_blank" >{ `${process.env.REACT_APP_WEBSITE_URL}integration/` + (url ? url.slice(-1)[0] !== "/" ? `${url}/` : url  : "") }</a>
                </FormItem>
                <FormItem {...formItemLayout} label="Version">
                    <Input
                        name="version"
                        value={version}
                        placeholder="CRM versions"
                        onChange={handleInputChange}
                    />
                    <span className="form-footnote">* Versions should be comma (, ) separated.</span>
                </FormItem>
                <FormItem {...formItemLayout} label="Heading" extra="* Required">
                    <Input name="heading" placeholder={"Heading"} value={heading} onChange={handleInputChange} />
                </FormItem>
                <FormItem {...formItemLayout} label="Content" extra={!isExclusions && "* Maximum 6000 characters"}>
                    <TextEditor
                        placeholder="Content of integration detail page"
                        name="blurb"
                        value={blurb}
                        handleEditorChange={html => handleFieldChange("blurb", html)}
                        advanced
                    />
                </FormItem>
                <FormItem {...formItemLayout} label="Integration Type">
                    <Select
                        mode="multiple"
                        value={types}
                        placeholder="Select integration type"
                        onChange={(value) =>
                            handleFieldChange("type", value)
                        }>
                        {
                            lookup.value.typeOptions.map((t, i) => <Option key={i} value={t}>{t}</Option>)
                        }
                    </Select>
                </FormItem>
                <FormItem {...formItemLayout} label="Countries">
                    <Select
                        mode="multiple"
                        value={ countries && countries.map ? countries : countries !=='-' && countries.split(", ")  }
                        placeholder="Select Countries"
                        onChange={(value) =>{
                            handleFieldChange("countries", value)
                        } 
                        }>
                        {
                            lookup.value.countriesOptions.map((t, i) => <Option key={i} value={t.value}>{t.text}</Option>)
                        }
                    </Select>
                </FormItem>
                <FormItem {...formItemLayout} label="Products">
                    <Select
                        mode="multiple"
                        value={ productModules }
                        placeholder="Select Products"
                        onChange={(value) =>{
                            handleFieldChange("products", value)
                        }  
                        }>
                        {
                            lookup.value.productsOptions.map((t) => {
                                if (products.indexOf(t.value+' 2way') !== -1 && t.value.indexOf('2way') ===-1) {
                                    return <Option key={t.value} value={t.value} disabled > <span>{t.value.indexOf('2way') !== -1 && <Icon component={() => (<img src={twowayIcon} style={{marginTop:'-5px'}} />)} /> }</span> {t.text} </Option>;
                                } else if ( products.indexOf(t.value.replace(' 2way','')) !== -1 && t.value.indexOf('2way') !== -1 ) {
                                    return <Option key={t.value} value={t.value} disabled > <span>{t.value.indexOf('2way') !== -1 && <Icon component={() => (<img src={twowayIcon} style={{marginTop:'-5px'}} />)} />}</span> {t.text} </Option>;
                                } else {
                                    return  <Option key={t.value} value={t.value} > <span>{t.value.indexOf('2way')  !== -1 && <Icon component={() => (<img src={twowayIcon} style={{marginTop:'-5px'}} />)} />}</span> {t.text} </Option>;
                                }
                            })
                        }
                    </Select>
                </FormItem>
                <FormItem {...formItemLayout} label="Availability">
                    <Select
                        mode="multiple"
                        value={availability && availability.map ? availability : availability.split(", ")}
                        placeholder="Select integration availability"
                        onChange={(value) =>
                            handleFieldChange("availability", value)
                        }>
                        <Option value="Direct">Direct</Option>
                        <Option value="Resellers">Resellers</Option>
                    </Select>
                </FormItem>
                <FormItem {...formItemLayout} label="Is Supported">
                    <Switch
                        checkedChildren="Supported"
                        unCheckedChildren="Non-supported"
                        defaultChecked={false}
                        checked={enabled}
                        onChange={(value) => handleFieldChange("enabled", value)} />
                </FormItem>
                <FormItem {...formItemLayout} label="Trigger">
                        <TextArea
                            name="triggerSupported"
                            value={triggerSupported}
                            placeholder="Trigger Supported for this CRM"
                            autosize={{ minRows: 6, maxRows: 12 }}
                            onChange={handleInputChange}
                        />
                </FormItem>
                <FormItem {...formItemLayout} label="Locations">
                    <Select
                        value={location || []}
                        placeholder="Select location type"
                        onChange={(value) =>
                            handleFieldChange("location", value)
                        }>
                        <Option value="Multiple Locations">Multiple Locations</Option>
                        <Option value="Single Location">Single Location</Option>
                        <Option value="">-</Option>
                    </Select>
                </FormItem>
                <FormItem {...formItemLayout} label="Special Instructions">
                        <TextArea
                            name="specialInstructions"
                            value={specialInstructions}
                            placeholder="Special Instructions for this CRM"
                            autosize={{ minRows: 6, maxRows: 12 }}
                            onChange={handleInputChange}
                        />
                </FormItem>
                <FormItem {...formItemLayout} label="Is Premium" style={{ display: 'none' }}>
                    <Switch
                        checkedChildren="Premium"
                        unCheckedChildren="Non-premium"
                        defaultChecked={false}
                        checked={isPremium}
                        onChange={(value) => handleFieldChange("isPremium", value)} />
                </FormItem>
                <FormItem {...formItemLayout} label="Integration Tier" extra="* Required">
                    <Select
                        value={integrationTier || []}
                        placeholder="Select Integration Tier"
                        onChange={(value) =>
                            handleFieldChange("integrationTier", value)
                        }>
                        <Option value="Standard">Standard</Option>
                        <Option value="Premium">Premium</Option>
                        <Option value="Enterprise">Enterprise</Option>
                        <Option value="Platinum">Platinum</Option>
                    </Select>
                </FormItem>
                <FormItem {...formItemLayout} label="Technical Document">
                    <Input
                        name="technicalDocument"
                        value={technicalDocument}
                        placeholder="Technical document"
                        onChange={handleInputChange}
                    />
                </FormItem>
                <FormItem {...formItemLayout} label="Lessonly Link">
                    { getFieldDecorator("lessonlyLink", {
                            rules: [{
                                type: "url",
                                message: "Please provide a valid lessonly link."
                            }],
                            initialValue: lessonlyLink
                            })(
                            <Input
                                name="lessonlyLink"
                                value={lessonlyLink}
                                placeholder="Lessonly link"
                                onChange={handleInputChange}
                            />
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Training Deck Link">
                    { getFieldDecorator("trainingResourcesDoc", {
                        rules: [{
                            type: "url",
                            message: "Please provide a valid training deck link."
                        }],
                        initialValue: trainingResourcesDoc
                        })(
                        <Input
                            name="trainingResourcesDoc"
                            value={trainingResourcesDoc}
                            placeholder="Training Deck Link"
                            onChange={handleInputChange}
                        />
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Training Recording Link">
                    { getFieldDecorator("trainingResourcesLink", {
                        rules: [{
                            type: "url",
                            message: "Please provide a valid training recording link."
                        }],
                        initialValue: trainingResourcesLink
                        })(
                        <Input
                            name="trainingResourcesLink"
                            value={trainingResourcesLink}
                            placeholder="Training Recording Link"
                            onChange={handleInputChange}
                        />
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Technical Document Feedback Form">
                    <Input
                        name="technicalDocumentFeedbackForm"
                        value={technicalDocumentFeedbackForm}
                        placeholder="Technical Document Feedback Form"
                        onChange={handleInputChange}
                    />
                </FormItem>
                <FormItem {...formItemLayout} label="Partnership">
                    <Select
                        value={partnership}
                        placeholder="Select partner"
                        onChange={(value) =>
                            handleFieldChange("partnership", value)
                        }>
                        {
                            lookup.value.partnership.map(({text, value}) => <Option key={value} value={value}>{text}</Option>)
                        }
                    </Select>
                </FormItem>
                <FormItem {... formItemLayout} label="Show CRM on website" >
                    <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked={isPublished}
                        checked={ isExclusions ? false : isPublished }
                        disabled={isExclusions}
                        onChange={(value) => handleFieldChange("isPublished", value)} />
                </FormItem>
                <FormItem {... formItemLayout} label="Show CRM on salesforce" >
                    <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked={isSalesforce}
                        checked={ isExclusions ? false : isSalesforce }
                        disabled={isExclusions}
                        onChange={(value) => handleFieldChange("isSalesforce", value)} />
                </FormItem>
                <FormItem {... formItemLayout} label="Exclusions" >
                    <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked={isExclusions}
                        checked={isExclusions}
                        onChange={(value) => handleFieldChange("isExclusions", value)} />
                </FormItem>
                <MultiResolutionImage
                    formItemLayout = {formItemLayout}
                    handleInputChange = {handleInputChange}
                    imageFieldName = "topLogo"
                    uploadName={heading.trim().toLowerCase().replace(/\s+/g, "-")}
                    imagePath={topLogo}
                    disabled={!heading}
                    timestamp={this.timestamp}
                    label="Top Logo"
                />
                {topLogo &&
                    <FormItem {...formButtonLayout}><Button {...formItemLayout} style={{width: 200}} type="danger" onClick={() => handleFieldChange("topLogo", "")} disabled={uploadingImage ? true : false}>
                        Delete Top Logo
                    </Button></FormItem>
                }
                {this.state.formError && (
                    <FormItem {...formButtonLayout}>
                        <span className="text-error">
                            {this.state.formError}
                        </span>
                    </FormItem>
                )}
                <FormItem {...formButtonLayout}>
                    <Button type="primary" htmlType="submit">
                        Next<Icon type="right" />
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

const UpdateIntegrationDefault = Form.create()(IntegrationForm);

UpdateIntegrationDefault.propTypes = {
    formData: PropTypes.object,
    domains: PropTypes.array,
    handleInputChange: PropTypes.func.isRequired,
    handleFieldChange: PropTypes.func.isRequired,
    updateStepInfo: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    userData: PropTypes.object,
    handleDocumentUpload: PropTypes.func
};

export default UpdateIntegrationDefault;

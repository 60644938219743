import React, { Component } from "react";
import { isEmpty } from "lodash";
import {Form, Select, Spin, DatePicker, Tabs} from "antd";
import momentTz from 'moment-timezone';
import { RedirectWrapper } from "../../components";
import { LineChart } from 'react-chartkick';
import 'chart.js';
import {dateFormat} from "../../constants";
import lookup from "./analytics.json";

const { RangePicker }  = DatePicker;
const { TabPane } = Tabs;
const Option = Select.Option;

class AnalyticsForm extends Component {
    initOffsetIndex = 1;
    initDate = {
        startDate: momentTz().tz("America/Los_Angeles").subtract(...lookup.offsetOptions[this.initOffsetIndex].value).format(dateFormat),
        endDate: momentTz().tz("America/Los_Angeles").subtract(1, "days").format(dateFormat)
    };
    state = {
        isLoading: true,
        ...this.initDate,
        data: this.props.data,
        blog: [],
        corporate: [],
        freeprofiles: [],
        hubspot: [],
        dateOffset: this.initOffsetIndex,
    };
    componentDidMount() {
        this.fetchData(this.state);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.path !== this.props.match.path) {
            this.pageInit();
        }
        if (prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) {
            this.fetchData(this.state);
        }
    }

    fetchData = (obj) => {
        this.props.analyticsReport({data: {startDate: obj.startDate, endDate: obj.endDate}, cb: this.formatData});
    };

    pageInit = () => {
        this.fetchData(this.state);
    };

    formatData = (upm = "session") => {
        const { blog, corporate, freeprofiles, hubspot } = this.props.data[upm];
        this.setState({
            blog,
            corporate,
            freeprofiles,
            hubspot
        });
    };

    dateRange = (date) => {
        const startDate = date[0].format("YYYY/MM/DD");
        const endDate = date[1].format("YYYY/MM/DD");
        const obj = {startDate, endDate};
        this.fetchData(obj);
    };

    disabledDate = current => {
        return current && current >= momentTz().tz("America/Los_Angeles").endOf('day').subtract(1, 'days');
    };

    handleFieldChange = (name, value) => {
        let nextState = { ...this.state };
        nextState[name] = value;
        if (name === "dateOffset" && value) {
            nextState.startDate = momentTz().tz("America/Los_Angeles").subtract(...lookup.offsetOptions[value].value).format(dateFormat);
            nextState.endDate = this.initDate.endDate;
        }
        this.setState(nextState);
    };

    render() {
        const { blog, corporate, freeprofiles, hubspot, dateOffset, startDate, endDate } = this.state;

        const { error, userData, isLoading } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        const
            width = "1000px",
            height = "400px",
            marginTop = "50px",
            underline = {textDecorationLine: 'underline'};

          if (unauthorized) {
              return <RedirectWrapper location={this.props.location} />;
          }

        return (
            <div className="google-analytics-root">
                <h2 style={{padding: "5px", margin: "0px"}}>Google Analytics Report</h2>
                <div className="chart-controllers" style={{backgroundColor: "#fafafa", padding: "20px"}}>
                    <Form layout = "inline">
                        <Form.Item>
                            <Tabs defaultActiveKey="session" onChange={ (key = "session") => {this.formatData(key)}}>
                                <TabPane tab={"Sessions"} key={"session"}></TabPane>
                                <TabPane tab={"Pageviews"} key={"pageview"}></TabPane>
                                <TabPane tab={"Users"} key={"user"}></TabPane>
                            </Tabs>
                        </Form.Item>
                    </Form>
                    <Form layout = "inline">
                        <Form.Item label="Time Period">
                            <Select
                                value={lookup.offsetOptions[dateOffset].label || []}
                                onChange={(value) => this.handleFieldChange("dateOffset", value)}
                                style={{ width: 168 }}>
                                {
                                    lookup.offsetOptions.map((d, i) => <Option key={i} value={i}>{d.label}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Date Range">{
                            dateOffset ?
                                <span>{startDate+` ~ `+endDate}</span>
                                :
                                <RangePicker disabledDate={this.disabledDate}
                                             onChange={this.dateRange}/>
                        }
                        </Form.Item>
                    </Form>
                </div>
                {isLoading || isEmpty(blog) || isEmpty(corporate) || isEmpty(freeprofiles) || isEmpty(hubspot) ? (
                    <Spin />
                    ) : (
                            <div>
                                <div style={{marginTop: marginTop}}>
                                    <h2 style={underline}>Corporate Website</h2>
                                    <LineChart curve={false} width={width} height={height} data={corporate} />
                                </div>
                                <div>
                                    <h2 style={underline}>Blog</h2>
                                    <LineChart curve={false} width={width} height={height} data={blog} />
                                </div>
                                <div style={{marginTop: marginTop}}>
                                    <h2 style={underline}>Free Profiles</h2>
                                    <LineChart curve={false} width={width} height={height} data={freeprofiles} />
                                </div>
                                <div style={{marginTop: marginTop}}>
                                    <h2 style={underline}>Hubspot</h2>
                                    <LineChart curve={false} width={width} height={height} data={hubspot} />
                                </div>
                            </div>
                    )
                }
            </div>
        );
    }
}

const Analytics = Form.create()(AnalyticsForm);

export default Analytics;

import { takeLatest, call, put } from "redux-saga/effects";
import BusinessActions, * as actionCreator from "./actions";
import businessApi from "./http";

const businessSagas = [
    takeLatest( BusinessActions.FETCH_BUSINESS_BY_BUSINESS_NUMBER, fetchBusinessByBusinessNumber),
    takeLatest( BusinessActions.FETCH_STATES, fetchStates),
    takeLatest( BusinessActions.FETCH_COUNTRIES, fetchCountries),
    takeLatest( BusinessActions.UPDATE_BUSINESS_REQUEST, updateBusiness),
    takeLatest( BusinessActions.ADD_USER_REQUEST, addUser),
    takeLatest( BusinessActions.DELETE_USER_REQUEST, deleteUser),
    takeLatest( BusinessActions.FIND_BUSINESS_REQUEST, findBusiness),
    takeLatest( BusinessActions.FETCH_AGGREGATION_REQUEST, fetchAggregation),
    takeLatest( BusinessActions.DELETE_AGGREGATION_REQUEST, deleteAggregation),
    takeLatest( BusinessActions.FETCH_AGGREGATION_SOURCES, fetchAggregationSources),
    takeLatest( BusinessActions.ADD_AGGREGATION_REQUEST, addAggregation),
    takeLatest( BusinessActions.FETCH_USERS_BY_BUSINESS_NUMBER, fetchUsersByBusinessNumber),
    takeLatest( BusinessActions.BUSINESS_SIGNUP_REQUEST, businessSignupRequest),
    takeLatest( BusinessActions.FETCH_BUSINESS_WITH_GOOGLE, fetchBusinessByNumberWithGoogle),
    takeLatest( BusinessActions.FETCH_BUSINESS_BY_EMAIL, fetchBusinessByEmail),
    takeLatest( BusinessActions.FETCH_CITIES, fetchCities),
    takeLatest( BusinessActions.REAGGREGATION_REQUEST, reaggregation)
];

function* fetchBusinessByBusinessNumber(action) {
    try {
        const resp = yield call(businessApi.getByBusinessNumber, action.payload.businessNumber);
        yield put(actionCreator.fetchBusinessByBusinessNumberSuccess(resp.data));
        if(action.payload.cb){
            yield call(action.payload.cb);
        }
    } catch (e) {
        yield put(
            actionCreator.businessError(e.data.data || { error: "Business Not Found" })
        );
    }
}

function* updateBusiness(action) {
    try {
        const resp = yield call(businessApi.updateBusiness, action.payload);
        yield put(actionCreator.updateBusinessSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.businessError(e.data.data || { error: "cannotUpdate" }));
        yield call(action.payload.cb, e.data.data);
    }
}

function* addUser(action) {
    try {
        const resp = yield call(businessApi.addUser, action.payload);
        yield put(actionCreator.addUserSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.businessError(e.data.data || { error: "cannotUpdate" }));
        yield call(action.payload.cb, "cannotUpdate");
    }
}

function* deleteUser(action) {
    try {
        const resp = yield call(businessApi.deleteUser, action.payload);
        yield put(actionCreator.deleteUserSuccess(resp.data));
        if(action.payload.cb){
            yield call(action.payload.cb);
        }
    } catch (e) {
        yield put(actionCreator.businessError(e.data.data || { error: "cannotDelete" }));
        yield call(action.payload.cb, "cannotDelete");
    }
}

function* findBusiness(action) {
    try {
        const resp = yield call(businessApi.findBusiness, action.payload.data);
        yield put(actionCreator.findBusinessSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(
            actionCreator.businessError(e.data.data || { error: "Business Not Found" })
        );
    }
}

function* fetchStates(action) {
    try {
        const resp = yield call(businessApi.getStates, action.payload.country);
        yield put(actionCreator.fetchStatesSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.businessError(e.data.data || { error: "States Not Found" })
        );
    }
}

function* fetchAggregationSources(action) {
    try {
        const resp = yield call(businessApi.aggregationSources, action.payload.businessId);
        yield put(actionCreator.fetchAggregationSourcesSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.businessError(e.data.data || { error: "Sources Not Found" })
        );
    }
}

function* fetchCountries() {
    try {
        const resp = yield call(businessApi.getCountries);
        yield put(actionCreator.fetchCountriesSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.businessError(e.data.data || { error: "States Not Found" })
        );
    }
}

function* fetchUsersByBusinessNumber(action) {
    try {
        const resp = yield call(businessApi.getUsers, action.payload.businessNumber);
        yield put(actionCreator.fetchUsersByBusinessNumberSuccess(resp.data));
        if(action.payload.cb){
            yield call(action.payload.cb);
        }
    } catch (e) {
        yield put(
            actionCreator.businessError(e.data.data || { error: "Business Not Found" })
        );
    }
}

function* fetchAggregation(action) {
    try {
        const resp = yield call(businessApi.getAggregation, action.payload.businessId);
        yield put(actionCreator.fetchAggregationSuccess(resp.data));
        if(action.payload.cb){
            yield call(action.payload.cb);
        }
    } catch (e) {
        yield put(
            actionCreator.businessError(e.data.data || { error: "Aggregation sources Not Found" })
        );
    }
}

function* addAggregation(action) {
    try {
        const resp = yield call(businessApi.addAggregation, action.payload);
        yield put(actionCreator.addAggregationSuccess(resp.data));
        if(action.payload.cb){
            yield call(action.payload.cb);
        }
    } catch (e) {
        yield put(actionCreator.businessError(e.data.data || { error: "cannotAdded" }));
        yield call(action.payload.cb, "cannotAdded");
    }
}

function* deleteAggregation(action) {
    try {
        const resp = yield call(businessApi.deleteAggregation, action.payload);
        yield put(actionCreator.deleteAggregationSuccess(resp.data));
        if(action.payload.cb){
            yield call(action.payload.cb);
        }
    } catch (e) {
        yield put(actionCreator.businessError(e.data.data || { error: "cannotDelete" }));
        yield call(action.payload.cb, "cannotDelete");
    }
}

function* businessSignupRequest(action) {
    try {
        const resp = yield call(businessApi.businessSignup, action.payload.payload);
        yield put(actionCreator.businessSignupSuccess(resp.data));
        if(action.payload.cb){
            yield call(action.payload.cb, {...resp.data, "status": "success"});
        }
    } catch (e) {
        yield put(
            actionCreator.businessError(e.data.data)
        );
        yield call(action.payload.cb, {...e.data.data, "status": "error"});
    }
}

function* fetchBusinessByNumberWithGoogle(action) {
    try {
        const resp = yield call(businessApi.getBusinessWithGoogle, action.payload);
        yield put(actionCreator.fetchBusinessByNumberWithGoogleSuccess(resp.data));
        if(action.payload.cb){
            yield call(action.payload.cb);
        }
    } catch (e) {
        yield put(
            actionCreator.businessError(e.data.data || { error: "Business Not Found" })
        );
    }
}

function* fetchBusinessByEmail(action) {
    try {
        const resp = yield call(businessApi.fetchBusinessByEmailId, action.payload);
        yield put(actionCreator.fetchBusinessByEmailIdSuccess(resp.data));
        if(action.payload.cb){
            yield call(action.payload.cb);
        }
    } catch (e) {
        yield put(
            actionCreator.businessError(e.data.data || { error: "Business Not Found" })
        );
    }
}

function* fetchCities(action) {
    try {
        const resp = yield call(businessApi.getAllCities, action.payload);
        yield put(actionCreator.fetchCitiesSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.businessError(e.data.data || { error: "Cities Not Found" })
        );
    }
}

function* reaggregation(action) {
    try {
        const resp = yield call(businessApi.reaggregation, action.payload);
        yield put(actionCreator.reaggregationSuccess(resp));
        if(action.payload.cb){
            yield call(action.payload.cb, {...resp.data, "status": "success"});
        }
    } catch (e) {
        yield put(actionCreator.businessError(e.data || { error: "cannotReaggregate" }));
        yield call(action.payload.cb, e.data || "cannotReaggregate");
    }
}

export default businessSagas;

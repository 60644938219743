import React, { Component } from 'react';
import { Form, Input, Spin, Button, Modal } from "antd";

import { formItemLayout, formButtonLayout } from "../../constants";
import { RedirectWrapper } from "../../components";
import { isEmpty } from "lodash";

const FormItem = Form.Item;

export class FeaturePageForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                type: "product", // discussed with AC, to send product for default. After a while we will modify this.
                name: "",
                slug: ""
            },
            isEdit: !!props.match.params.id
        };
    }
    
    componentDidMount() {
        const { match } = this.props;
        
        match.params.id && this.props.reqFeaturePageById({id: match.params.id});
        
        isEmpty(this.props.featurePagesList) && this.props.reqFeaturePagesList();
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        
        this.props.form.validateFieldsAndScroll((error, values) => {
            if (error) { return; }
            
            const { type, name, slug } = this.props.featurePageToUpdate;
            if(values.type === type && values.name === name && values.slug === slug) {
                return Modal.error({
                    title: "Error",
                    okText: "Return",
                    content: "Please update the data and try again..."
                })
            }
            
            
            const pageExists = this.props.featurePagesList.filter(existingData => {
                return (
                    values.name === existingData.name && 
                    values.type === existingData.type && 
                    values.slug === existingData.slug
                )
            })
            
            if(pageExists.length) {
                return Modal.error({
                    title: "Error",
                    content: "This page is already present. Please confirm and try again.",
                    closable: true,
                    maskClosable: true,
                });
            }
            
            if(this.state.isEdit) {
                this.props.reqUpdateFeaturePage({data: values, id: this.props.featurePageToUpdate.id, cb: this.renderUpdateCallback});   
            } else {
                this.props.reqAddFeaturePage({data: values, cb: this.renderAddedcb});
            }
        });
    }
    
    renderUpdateCallback = () => {
        const {titleText, content} = this.props.updateResponse;
        const goToListPage = () => {
            this.props.history.push("/synced-website-pages");
            this.props.reqFeaturePagesList();
        };
        if(titleText === "Success") {
            return Modal.success({
                title: titleText,
                content: content,
                maskClosable: true,
                okText: "Go to Featured Pages",
                onOk() {
                    goToListPage();
                }
            });
        } else if (titleText === "Error") {
            return Modal.error({
                title: titleText,
                content: content,
                closable: true,
                maskClosable: true,
            });
        }
    }
    
    renderAddedcb = () => {
        const { isAdded, AddedData } = this.props;
        const {titleText, content} = this.props.updateResponse;
        const goToListPage = () => {
            this.props.history.push("/synced-website-pages");
            this.props.reqFeaturePagesList();
        }
        if(isAdded) {
           return Modal.success({
                title: "Success",
                content: `${AddedData.name} page added successfully`,
                closable: true,
                maskClosable: true,
                okText: "Go to Featured Pages",
                onOk() {
                    goToListPage();
                }
            }); 
        } else {
            return Modal.error({
                title: titleText,
                content: content,
                closable: true,
                maskClosable: true,
            });
        }
    }
    
    render() {
        const { featurePageToUpdate, isLoading, error, userData, form } = this.props;
        const { isEdit, data } = this.state;
        
        const type = isEdit ? featurePageToUpdate.type : data.type;
        const name = isEdit ? featurePageToUpdate.name : null;
        const slug = isEdit ? featurePageToUpdate.slug : null;
        
        const { getFieldDecorator } = form;
        const unauthorized = error === "unauthorized" || !userData.name;
        
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }
        
        if(isLoading) {
            return <Spin />
        }
        
        return (
            <div>
                <h2>{isEdit ? "Update" : "New"} Website Page</h2>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Page Type" style={{ display: "none" }}>
                        {
                            getFieldDecorator("type", {
                                rules: [{
                                    required: true,
                                    message: "Please provide a page type"
                                }],
                                initialValue: type
                            }) (
                                <Input />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label="Page Name">
                        {
                            getFieldDecorator("name", {
                                rules: [{
                                    required: true,
                                    message: "Please provide a page name"
                                }],
                                initialValue: name
                            }) (
                                <Input placeholder="Name from which it can be refrenced" />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label="URL">
                        {
                            getFieldDecorator("slug", {
                                rules: [{
                                    required: true,
                                    message: "Please provide a valid url"
                                },
                                {
                                    pattern: "^(?!/|resources)([A-Za-z0-9]+((?!(-|/){2,})[-/]([A-Za-z0-9])*)*[A-Za-z0-9]+)$",
                                    message: "Please provide a valid page slug"
                                }],
                                initialValue: slug
                            }) (
                                <Input addonBefore={process.env.REACT_APP_WEBSITE_URL} addonAfter="/" placeholder="Remaining part of the URL" />
                            )
                        }
                    </FormItem>
                    <FormItem {...formButtonLayout}>
                        <Button htmlType="submit" type="primary">Submit</Button>
                    </FormItem>
                </Form>
            </div>
        )
    }
}

const FeaturePage = Form.create()(FeaturePageForm);

export default FeaturePage;

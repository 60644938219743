import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import PartnerActions, * as actionCreator from "./actions";
import PartnerAPI from "./http";

const PartnerSaga = [
    takeLatest(PartnerActions.FETCH_PARTNER_DOCUMENT_REQUEST, fetchPartnerDocumentRequest),
    takeLatest(PartnerActions.FETCH_PARTNER_DOCUMENT_BYID_REQUEST, fetchPartnerDocumentByIdRequest),
    takeLatest(PartnerActions.CREATE_PARTNER_DOCUMENT_REQUEST, createPartnerDocumentRequest),
    takeLatest(PartnerActions.UPDATE_PARTNER_DOCUMENT_REQUEST, updatePartnerDocumentRequest),
    takeLatest(PartnerActions.DOWNLOAD_PARTNER_DOCUMENT_REQUEST, downloadPartnerDocumentRequest),
    takeEvery(PartnerActions.FETCH_PARTNER_DOCUMENT_BY_SECTION_REQUEST, fetchPartnerDocumentBySectionRequest),
];

function* fetchPartnerDocumentRequest(action) {
    try {
        const response =  yield call(PartnerAPI.fetch);
        yield put(actionCreator.fetchPartnerDocumentSuccess(response.data));
    } catch (e) {
        yield put(actionCreator.fetchPartnerDocumentError([]));
    }
}

function* fetchPartnerDocumentByIdRequest(action) {
    try {
        const response =  yield call(PartnerAPI.fetchById, action.payload);
        yield put(actionCreator.fetchPartnerDocumentByIdSuccess(response.data));
    } catch (e) {
        yield put(actionCreator.fetchPartnerDocumentByIdError({}));
    }
}

function* createPartnerDocumentRequest(action) {
    try {
        const response =  yield call(PartnerAPI.create, action.payload.data);
        yield put(actionCreator.createPartnerDocumentSuccess({type: action.payload, data: response.data}));
        yield call(action.payload.cb, {type: "success", message: "This document has been successfully created.", data: {...action.payload.data, id: response.data.id}});
    } catch (e) {
        yield put(actionCreator.createPartnerDocumentError({type: action.payload, data: []}));
        yield call(action.payload.cb, {type: "error", message: "Something went wrong. Please retry after sometime."});
    }
}

function* updatePartnerDocumentRequest(action) {
    try {
        const response =  yield call(PartnerAPI.update, action.payload.data, action.payload.documentId);
        yield put(actionCreator.updatePartnerDocumentSuccess({type: action.payload.type, data: response.data}));
        yield call(action.payload.cb, {type: "success", message: "This document has been successfully updated.", data: response.data});
    } catch (e) {
        yield put(actionCreator.updatePartnerDocumentError({type: action.payload.type, data: {}}));
        yield call(action.payload.cb, {type: "error", message: "Something went wrong. Please retry after sometime."});
    }
}

function* downloadPartnerDocumentRequest(action) {
    try {
        const response = yield call(PartnerAPI.download, action.payload);
        yield put(actionCreator.downloadPartnerDocumentSuccess());
        yield call(action.cb, response.data);
    } catch (e) {
        yield put(actionCreator.downloadPartnerDocumentError());
        yield call(action.cb, {});  
    }
}

function* fetchPartnerDocumentBySectionRequest(action) {
    try {
        const response = yield call(PartnerAPI.fetchBySection, action.payload);
        yield put(actionCreator.fetchPartnerDocumentBySectionSuccess({ ...action.payload, documents: response.data }));
    } catch (e) {
        yield put(actionCreator.fetchPartnerDocumentBySectionError({ ...action.payload, documents: [] }));
    }
}

export default PartnerSaga;
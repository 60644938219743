import React from "react";
import { connect } from "react-redux";

import {
    fetchActivitiesList,
    fetchActivitiesListFromRoute
} from './actions';

import ViewActivities from "./ViewActivities";

// TODO return only activity state to child component
const mapStateToProps = (state) => ({
    ...state.User,
    ...state.activity
});

export const ActivityPage = connect(
    mapStateToProps,
    {fetchActivitiesList, fetchActivitiesListFromRoute}
)(ViewActivities);

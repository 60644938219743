import React, { Component } from "react";
import { RedirectWrapper, DocumentSection } from "../../components";
import _ from "lodash";
import lookup from "./lookup.json";
import "./Partner.scss";

class ProductBasedLibrary extends Component {
    constructor(props) {
        super();
        this.state = {
            product: props.match.params.product
        };
        this.type = (props.userData.account_type === 2) 
            ? "coBranded" : (
                (props.userData.account_type === 3) ? "whitelabeled" : 
                    "both"
            );
    }

    componentDidMount() {
        const { product } = this.state;
        if (!this.props.section.hasOwnProperty("new") || _.isEmpty(this.props.section.new[product])) this.props.fetchPartnerDocumentBySectionRequest({ section: "new", category: "all", product, type: this.type });
        if (!this.props.section.hasOwnProperty("twoPagers") || _.isEmpty(this.props.section.twoPagers[product])) this.props.fetchPartnerDocumentBySectionRequest({ section: "twoPagers", category: "two-pagers", product, type: this.type });
        if (!this.props.section.hasOwnProperty("caseStudies") || _.isEmpty(this.props.section.caseStudies[product])) this.props.fetchPartnerDocumentBySectionRequest({ section: "caseStudies", category: "all", product, type: this.type });
        if (!this.props.section.hasOwnProperty("all") || _.isEmpty(this.props.section.all[product])) this.props.fetchPartnerDocumentBySectionRequest({ section: "all", category: "all", product, type: this.type });
    }

    render() {
        const { error, userData, isLoading } = this.props;
        const { product } = this.state;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized || !lookup.productSpecific.hasOwnProperty(product)) {
            return <RedirectWrapper location={this.props.location} />;
        }

        const content = lookup.productSpecific[product];
        const newDocuments = this.props.section.hasOwnProperty("new") && !_.isEmpty(this.props.section.new[product]) ? this.props.section.new[product].filter(d => (this.type !== "both" ? (d.type === this.type) : true)) : [];
        const twoPagersDocuments = this.props.section.hasOwnProperty("twoPagers") && !_.isEmpty(this.props.section.twoPagers[product]) ? this.props.section.twoPagers[product].filter(d => (this.type !== "both" ? (d.type === this.type) : true)) : [];
        const caseStudiesDocuments = this.props.section.hasOwnProperty("caseStudies") && !_.isEmpty(this.props.section.caseStudies[product]) ? this.props.section.caseStudies[product].filter(d => (this.type !== "both" ? (d.type === this.type) : true)) : [];
        const overallDocuments = this.props.section.hasOwnProperty("all") && !_.isEmpty(this.props.section.all[product]) ? this.props.section.all[product].filter(d => (this.type !== "both" ? (d.type === this.type) : true)) : [];
        const youtubeParameters = new URLSearchParams(lookup.youtubeVideoParameters).toString();

        return (
            <div className="productBasedLibrary">
                <h1 className="heading">{ content.title }</h1>
                <p className="subHeading">{ content.description }</p>
                <iframe type="text/html" width="640" height="360" frameborder="0" title={content.title} src={`https://www.youtube.com/embed/${content.video}?${youtubeParameters}`} />
                <DocumentSection
                    cols={[
                        {
                            type: "new",
                            headingIcon: "sketch",
                            heading: "New Listings Content",
                            isLoading: isLoading,
                            list: newDocuments,
                        },
                        {
                            type: "twoPagers",
                            headingIcon: "read",
                            heading: "2 Pagers",
                            isLoading: isLoading,
                            list: twoPagersDocuments,
                        },
                        {
                            type: "caseStudies",
                            headingIcon: "solution",
                            heading: "Testimonials/Case Studies",
                            isLoading: isLoading,
                            list: caseStudiesDocuments,
                        },
                        {
                            type: "overall",
                            headingIcon: "bars",
                            heading: "All Listings Content",
                            isLoading: isLoading,
                            list: overallDocuments,
                        }
                    ]}
                    downloadPartnerDocumentRequest={this.props.downloadPartnerDocumentRequest}
                />
            </div>
        );
    }
}

export default ProductBasedLibrary;

import React, { Component } from "react";
import {Redirect} from "react-router-dom";
import {Spin, Table, Tag, Select} from "antd";

class Detail extends Component  {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentWillMount() {
        this.props.reqDetailCategories();
    }

    componentDidMount() {
        let {topCategoryId} = this.props.match.params;
        topCategoryId = topCategoryId || 1;
        this.handleCategoryChange(topCategoryId);
    }

    getValueForFilter = (records, index) => {
        let filters = [];

        for (const record of records) {
            filters.push({text: record[index], value: record[index]});
        }

        return filters;
    };

    handleCategoryChange = value => {
        this.props.history.push(`/free-prod-dashboard/detail/${value}`);
        this.props.reqDetailTableContent({categoryId: value});
    };

    render() {

        const { error, userData, isLoading, pageData, match } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        if(isLoading) {
            return <Spin />;
        }

        const {detail: thisPageData} = pageData;
        const categories = thisPageData.categories || [];
        const isTableLoading = thisPageData.isTableLoading;
        const records = thisPageData.tableContent || [];
        const formatter = new Intl.NumberFormat('en-US');

        const columns = [
            {
                title: 'Sub Category',
                dataIndex: 'categoryName',
                key: 'categoryName',
                width: '25%',
                filters: this.getValueForFilter(records, 'categoryName'),
                onFilter: (value, record) => record.categoryName.indexOf(value) === 0,
                render: (value, row) => (<span>{value} {row.isICP ? (<Tag color={"geekblue"}>ICP</Tag>) : (<Tag color={"volcano"}>Not ICP</Tag>)}</span>)
            }, {
                title: 'Business Count',
                dataIndex: 'totalBusinesses',
                key: 'totalBusinesses',
                width: '15%',
                sorter: (a, b) => a.totalBusinesses - b.totalBusinesses,
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                render: (value, row) => (formatter.format(value))
            }, {
                title: 'Total Email Count',
                dataIndex: 'totalEmails',
                key: 'totalEmails',
                width: '15%',
                sorter: (a, b) => a.totalEmails - b.totalEmails,
                sortDirections: ['descend', 'ascend'],
                render: (value, row) => (formatter.format(value))
            }, {
                title: 'Subscribed Email Count',
                dataIndex: 'totalSubscribedEmails',
                key: 'totalSubscribedEmails',
                width: '15%',
                sorter: (a, b) => a.totalSubscribedEmails - b.totalSubscribedEmails,
                sortDirections: ['descend', 'ascend'],
                render: (value, row) => (formatter.format(value))
            }, {
                title: '% Subscribed Email',
                dataIndex: 'totalCreatedBusinessesYesterday',
                key: 'totalCreatedBusinessesYesterday',
                width: '15%',
                sorter: (a, b) => a.totalCreatedBusinessesYesterday - b.totalCreatedBusinessesYesterday,
                sortDirections: ['descend', 'ascend'],
                render: (value, row) => {
                    const {totalEmails, totalSubscribedEmails} = row;
                    if (totalSubscribedEmails === 0) {
                        return '0';
                    }

                    if (!parseInt(totalSubscribedEmails)) {
                        return '-';
                    }

                    return `${((totalSubscribedEmails/totalEmails) * 100).toFixed(2)}%`;
                }
            }
        ];

        return (
            <div>
                <h2>FreeProd Detail</h2>
                <div>
                    <div>
                        <label>Top Category: </label>
                        <Select style={{width: "300px", marginLeft: "10px"}} placeholder={"Select a category"} onChange={this.handleCategoryChange} value={(this.props.match.params.topCategoryId && Number(this.props.match.params.topCategoryId)) || 1}>
                            {
                                categories.map((value, key) => (
                                    <Select.Option key={key} value={value.categoryId}>{value.categoryName}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                </div>
                <Table className={"ant-table-bordered"} style={{ marginTop: 20 }} size={"medium"} showHeader={true} columns={columns} dataSource={records} loading={isTableLoading} pagination={false} scroll={{y: 400}}/>
            </div>
        );
    }
}

export default Detail;
import { createActionTypes } from "../../utils";

export const IntegrationsActions = createActionTypes("integrations", [
    "FETCH_INTEGRATIONS_LIST_REQUEST",
    "FETCH_INTEGRATIONS_LIST_SUCCESS",
    "FETCH_INTEGRATION_REQUEST",
    "FETCH_INTEGRATION_SUCCESS",
    "ADD_INTEGRATION_REQUEST",
    "ADD_INTEGRATION_SUCCESS",
    "UPDATE_INTEGRATION_REQUEST",
    "UPDATE_INTEGRATION_SUCCESS",
    "DELETE_INTEGRATION_REQUEST",
    "DELETE_INTEGRATION_SUCCESS",
    "INTEGRATION_ERROR",
    "DOWNLOAD_INTEGRATION_CSV_REQ",
    "DOWNLOAD_INTEGRATION_CSV_SUCCESS"
]);

export const fetchIntegrationsListRequest = () => {
    return {
        type: IntegrationsActions.FETCH_INTEGRATIONS_LIST_REQUEST
    };
};

export const fetchIntegrationsListSucess = (obj) => {
    return {
        type: IntegrationsActions.FETCH_INTEGRATIONS_LIST_SUCCESS,
        payload: obj
    };
};

export const fetchIntegrationRequest = (obj) => {
    return {
        type: IntegrationsActions.FETCH_INTEGRATION_REQUEST,
        payload: obj
    };
};

export const fetchIntegrationSuccess = (obj) => {
    return {
        type: IntegrationsActions.FETCH_INTEGRATION_SUCCESS,
        payload: obj
    };
};

export const addIntegrationRequest = (obj) => {
    return {
        type: IntegrationsActions.ADD_INTEGRATION_REQUEST,
        payload: obj
    };
};

export const addIntegrationSuccess = (obj) => {
    return {
        type: IntegrationsActions.ADD_INTEGRATION_SUCCESS,
        payload: obj
    };
};

export const updateIntegrationRequest = (obj) => {
    return {
        type: IntegrationsActions.UPDATE_INTEGRATION_REQUEST,
        payload: obj
    };
};

export const updateIntegrationSuccess = (obj) => {
    return {
        type: IntegrationsActions.UPDATE_INTEGRATION_SUCCESS,
        payload: obj
    };
};

export const deleteIntegrationRequest = (obj) => {
    return {
        type: IntegrationsActions.DELETE_INTEGRATION_REQUEST,
        payload: obj
    };
};

export const deleteIntegrationSuccess = (obj) => {
    return {
        type: IntegrationsActions.DELETE_INTEGRATION_SUCCESS,
        payload: obj
    };
};

export const integrationError = (obj) => {
    return {
        type: IntegrationsActions.INTEGRATION_ERROR,
        payload: obj
    };
};

export const downloadIntegrationCsvRequest = () => {
    return {
            type: IntegrationsActions.DOWNLOAD_INTEGRATION_CSV_REQ
    };
};

export const downloadIntegrationCsvSuccess = () => {
    return {
        type: IntegrationsActions.DOWNLOAD_INTEGRATION_CSV_SUCCESS
    };
};

export default IntegrationsActions;

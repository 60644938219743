import { createActionTypes } from "../../utils";

export const RequestBusinessAccessActions = createActionTypes("business", [
    "FETCH_BUSINESS_LIST",
    "FETCH_BUSINESS_LIST_SUCCESS",
    "FETCH_BUSINESS_LIST_ERROR",
    "REQUEST_ACCESS_TO_BUSINESS",
    "REQUEST_ACCESS_TO_BUSINESS_SUCCESS",
    "REQUEST_ACCESS_TO_BUSINESS_ERROR",
    "FETCH_REQUEST_HISTORY_LIST",
    "FETCH_REQUEST_HISTORY_LIST_SUCCESS",
    "FETCH_REQUEST_HISTORY_LIST_ERROR"
]);

export const fetchBusinessList = (value) => {
    return {
        type: RequestBusinessAccessActions.FETCH_BUSINESS_LIST,
        payload: value
    };
};

export const fetchBusinessListSuccess = (results) => {
    return {
        type: RequestBusinessAccessActions.FETCH_BUSINESS_LIST_SUCCESS,
        payload: results
    };
};

export const fetchBusinessListError = (error) => {
    return {
        type: RequestBusinessAccessActions.FETCH_BUSINESS_LIST_ERROR,
        payload: error
    };
};

export const fetchRequestHistoryList = (value) => {
    return {
        type: RequestBusinessAccessActions.FETCH_REQUEST_HISTORY_LIST,
        payload: value
    };
};

export const fetchRequestHistoryListSuccess = (value) => {
    return {
        type: RequestBusinessAccessActions.FETCH_REQUEST_HISTORY_LIST_SUCCESS,
        payload: value
    };
};

export const fetchRequestHistoryListError = (value) => {
    return {
        type: RequestBusinessAccessActions.FETCH_REQUEST_HISTORY_LIST_ERROR,
        payload: value
    };
};

export const requestAccessToBusiness = (obj) => {
    return {
        type: RequestBusinessAccessActions.REQUEST_ACCESS_TO_BUSINESS,
        payload: obj
    };
};

export const requestAccessToBusinessSuccess = (obj) => {
    return {
        type: RequestBusinessAccessActions.REQUEST_ACCESS_TO_BUSINESS_SUCCESS,
        payload: obj
    };
};

export const requestAccessToBusinessError = (obj) => {
    return {
        type: RequestBusinessAccessActions.REQUEST_ACCESS_TO_BUSINESS_ERROR,
        payload: obj
    };
};

export default RequestBusinessAccessActions;
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Input, Button, Typography } from "antd";
import {birdeyeLoginRequest} from "../Users/actions";
import PartnerLibraryHome from "../Partner/PartnerLibraryHome";
import NewPartnerLibraryHome from "../Partner/NewPartnerLibraryHome";
const FormItem = Form.Item;

const authLink = process.env.REACT_APP_AUTH_BASE_URL;

class HomeForm extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((error, values) => {

            if (!error) {
                this.props.birdeyeLoginRequest({ values });
            }
        })
    };

    render() {
        const
            { userData, location, loadingUser } = this.props,
            { getFieldDecorator } = this.props.form;

        if ((!userData || !userData.name) && loadingUser) {
            return(
                <div style={{display: 'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100vh', fontSize:'24px'}}>
                    <div>Loading...</div>
                </div>
            )
        }

        if (!userData || !userData.name) {
            return (<div className="home">
                <div className="signinBox">
                    <a href={authLink} className="google-btn socialLogin" data-type="google" id="google-sign-btn">
                        <img loading="lazy" src="https://app.birdeye.com/public/StaticPage/css/images-v2/google.svg" />
                        <span>Sign In as a Birdeye Employee</span>
                    </a>
                    <span className="login-with">
                            <span>or</span>
                        </span>

                    <p className="form-label">Sign In as Partner</p>
                    <Form className="login-form" onSubmit={this.handleSubmit}>
                        <FormItem>
                            {getFieldDecorator('emailId', { rules: [{ required: true, message: 'Enter Email' }]})(
                                <Input type="text" placeholder="Email" />
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('password', { rules: [{ required: true, message: 'Enter Password' }]})(
                                <Input type="password" placeholder="Password" />
                            )}
                        </FormItem>
                        {this.props.userError ? <Typography.Text type="danger">{this.props.userError}</Typography.Text> : ""}
                        <FormItem>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Sign in
                            </Button>
                        </FormItem>
                    </Form>
                </div>
            </div>);
        }
        if (userData && userData.role === 18) {
            return (<NewPartnerLibraryHome />);
        }
        if (location.state && location.state.type === "unauthorized") {
            return <div className="home"><h3>Hey {userData.name}! We’re making ‘Prospector’ awesome for you. The tool will be available soon. Stay tuned!</h3></div>;
        }
        return <div className="home"><h3>Welcome, {userData.name}!</h3></div>;
    }
}

const Home = Form.create()(HomeForm);

Home.propTypes = {
    location: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    ...state.User
});

export default connect( mapStateToProps , { birdeyeLoginRequest })(Home);

import { createActionTypes } from "../../utils";

export const FeaturePagesActions = createActionTypes("featurePages", [
    "REQ_FEATURE_PAGES_LIST",
    "SUCCESS_FEATURE_PAGES_LIST",
    "ERROR_FEATURE_PAGES_LIST",
    
    "REQ_FEATURE_PAGE_BY_ID",
    "SUCCESS_FEATURE_PAGE_BY_ID",
    "ERROR_FEATURE_PAGE_BY_ID",
    
    "REQ_ADD_FEATURE_PAGE",
    "SUCCESS_ADD_FEATURE_PAGE",
    "ERROR_ADD_FEATURE_PAGE",
    
    "REQ_DELETE_FEATURE_PAGE",
    "SUCCESS_DELETE_FEATURE_PAGE",
    "ERROR_DELETE_FEATURE_PAGE",
    
    "REQ_UPDATE_FEATURE_PAGE",
    "SUCCESS_UPDATE_FEATURE_PAGE",
    "ERROR_UPDATE_FEATURE_PAGE",
]);

// Feature Pages request
export const reqFeaturePagesList = (data) => ({
    type: FeaturePagesActions.REQ_FEATURE_PAGES_LIST,
    payload: data
});

export const successFeaturePagesList = (data) => ({
    type: FeaturePagesActions.SUCCESS_FEATURE_PAGES_LIST,
    payload: data
});

export const errorFeaturePagesList = (data) => ({
    type: FeaturePagesActions.ERROR_FEATURE_PAGES_LIST,
    payload: data
});

export const reqFeaturePageById = (data) => ({
    type: FeaturePagesActions.REQ_FEATURE_PAGE_BY_ID,
    payload: data
});

export const successFeaturePageById = (data) => ({
    type: FeaturePagesActions.SUCCESS_FEATURE_PAGE_BY_ID,
    payload: data
});

export const errorFeaturePageById = (data) => ({
    type: FeaturePagesActions.ERROR_FEATURE_PAGE_BY_ID,
    payload: data
});

export const reqAddFeaturePage = (data) => ({
    type: FeaturePagesActions.REQ_ADD_FEATURE_PAGE,
    payload: data
});

export const successAddFeaturePage = (data) => ({
    type: FeaturePagesActions.SUCCESS_ADD_FEATURE_PAGE,
    payload: data
});

export const errorAddFeaturePage = (data) => ({
    type: FeaturePagesActions.ERROR_ADD_FEATURE_PAGE,
    payload: data
});

export const reqUpdateFeaturePage = (data) => ({
    type: FeaturePagesActions.REQ_UPDATE_FEATURE_PAGE,
    payload: data
});

export const successUpdateFeaturePage = (data) => ({
    type: FeaturePagesActions.SUCCESS_UPDATE_FEATURE_PAGE,
    payload: data
});

export const errorUpdateFeaturePage = (data) => ({
    type: FeaturePagesActions.ERROR_UPDATE_FEATURE_PAGE,
    payload: data
});

export const reqDeleteFeaturePage = (data) => ({
    type: FeaturePagesActions.REQ_DELETE_FEATURE_PAGE,
    payload: data
});

export const successDeleteFeaturePage = (data) => ({
    type: FeaturePagesActions.SUCCESS_DELETE_FEATURE_PAGE,
    payload: data
});

export const errorDeleteFeaturePage = (data) => ({
    type: FeaturePagesActions.ERROR_DELETE_FEATURE_PAGE,
    payload: data
});

export default FeaturePagesActions;

import { connect } from "react-redux";

import { searchDomainRequest, fetchEmailRequest, blankEmailRequest } from "./actions";

import EmailService from "./EmailService";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.EmailService
});

export const EmailServicePage = connect(
    mapStateToProps,
    { searchDomainRequest, fetchEmailRequest, blankEmailRequest }
)(EmailService);

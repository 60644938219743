import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import PressesActions, * as actionCreator from "./actions";
import pressApi from "./http";

const pressSagas = [
    takeLatest(
        PressesActions.FETCH_PRESSES_LIST_REQUEST,
        fetchPressesList
    ),
    takeLatest(PressesActions.FETCH_PRESS_REQUEST, fetchPress),
    takeLatest(PressesActions.ADD_PRESS_REQUEST, addPress),
    takeLatest(PressesActions.UPDATE_PRESS_REQUEST, updatePress),
    takeEvery(PressesActions.DELETE_PRESS_REQUEST, deletePress)
];

function* fetchPressesList() {
    try {
        const resp = yield call(pressApi.getAll);
        yield put(actionCreator.fetchPressesListSucess(resp.data.pressData));
    } catch (e) {
        yield put(
            actionCreator.pressError(e.data.data || { error: "presssListNotFound" })
        );
    }
}

function* fetchPress(action) {
    try {
        const resp = yield call(pressApi.getById, action.payload.id);
        yield put(actionCreator.fetchPressSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.pressError(e.data.data || { error: "pressNotFound" }));
    }
}

function* addPress(action) {
    try {
        const resp = yield call(pressApi.add, action.payload.data);
        yield put(actionCreator.addPressSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.pressError(e.data.data || { error: "cannotAdd" }));
        yield call(action.payload.cb, "cannotAdd");
    }
}

function* updatePress(action) {
    try {
        const resp = yield call(pressApi.update, action.payload.data);
        yield put(actionCreator.updatePressSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.pressError(e.data.data || { error: "cannotUpdate" }));
        yield call(action.payload.cb, "cannotUpdate");
    }
}

function* deletePress(action) {
    const { id } = action.payload;
    try {
        yield call(pressApi.delete, id);
        yield put(actionCreator.deletePressSuccess({ id }));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.pressError(e.data.data || { error: "cannotDelete" }));
        yield call(action.payload.cb, "cannotDelete");
    }
}

export default pressSagas;

import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Icon, Badge, Dropdown, Menu, Form, Input, Button, Modal, Popconfirm } from 'antd';
import { formButtonLayout, formItemLayout } from '../../constants';
import PartnerAPI from './http';

const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
}

const ScreenType = {
    ANNOUNCEMENT_VIEW: "announcementView",
    ANNOUNCEMENT_FORM: "announcementForm"
}

const showToastMessage = (content, isError = false) => {
    if(isError) {
        return Modal.error({
            title: "Error",
            content: content,
            okText: "Close"
        });
    }
    return Modal.success({
        title: `Success`,
        content: content,
        maskClosable: true,
        okText: "Close"
    }); 
}

const AnnouncementFormView = (props) => {

    const initialValues = {
        title:"",
        description:""
    }

    const {data, screen, setScreen, userData} = props || {};
    const isUpdate = !!data;
    const [formData, setFormData] = useState(initialValues);

    useEffect(() => {
        if(isUpdate) setFormData(data);
        else setFormData(initialValues);
    },[screen])

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if(!formData.title || !formData.description){
           return showToastMessage("Please fill in all required fields", true);
        }
        try {
            await PartnerAPI.updateAnnouncements({
                title: formData.title,
                description: formData.description,
                ...(isUpdate ? { 
                    id: formData.id,
                    updatedBy: userData.name
                 } : {
                    createdBy: userData.name
                 })
            });
            showToastMessage(isUpdate ? "Announcement Updated" : "Announcement Created");
            setScreen(ScreenType.ANNOUNCEMENT_VIEW);
        } catch (error) {
            showToastMessage("Error updating announcement", true);
        }
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target || {};
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    }

    return (
    <>
        <Form onSubmit={handleOnSubmit}>
            <Form.Item {...formItemLayout} label="Title"  required={true}>
                <Input
                    name="title"
                    value={formData.title}
                    placeholder="Please Enter title"
                    onChange={handleInputChange}
                />
            </Form.Item>

            <Form.Item {...formItemLayout} label="Description"  required={true}>
                <Input.TextArea
                    name="description"
                    value={formData.description}
                    placeholder="Please Enter description"
                    onChange={handleInputChange}
                />
            </Form.Item> 
            <Form.Item {...formButtonLayout}>
                <Button type="primary" htmlType="submit">Save</Button>
            </Form.Item>
        </Form>   
    </>
)}

const Announcement = (props) => {

    const { userData } = props || {};
    const showEdit = userData.privileges.includes('partner+')

    const [announcement, setAnnouncement] = useState([]);
    const [screen, setScreen] = useState(ScreenType.ANNOUNCEMENT_VIEW);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

    useEffect(() => {
        if(screen === ScreenType.ANNOUNCEMENT_VIEW) handleFetchAnnouncements();
    }, [screen]);

    const handleFetchAnnouncements = async () => {
        try{
            const {data} = await PartnerAPI.fetchAnnouncements();
            setAnnouncement(data);
        }catch (error) {
            console.error('Error fetching announcement:', error);
        }
    }

    const handleDeleteAnnouncement = async () => {  
        try{
            await PartnerAPI.deleteAnnouncement(selectedAnnouncement.id);
            showToastMessage("Announcement Deleted");
            handleFetchAnnouncements();
        } catch (error) {
            showToastMessage("Failed to delete announcement", true);
        }
    };

    const handleAnnouncementMenu = (type) => {
        ["create", "edit"].includes(type) ? setScreen(ScreenType.ANNOUNCEMENT_FORM) : handleDeleteAnnouncement();
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={() => handleAnnouncementMenu("edit")}>
            <span>Edit</span>
            </Menu.Item>
            <Menu.Item>
            <Popconfirm
                title={"Are you sure to delete this record?"}
                onConfirm={() => handleAnnouncementMenu("delete")}
                okText="Yes"
                cancelText="No"
                placement={"top"}
            >
                <a href="#">Delete</a>
            </Popconfirm>
            </Menu.Item>
        </Menu>
);

  return (
    <div className="announcementsWrapper">
        {!!announcement.length && <h3 className="sectionHeading">Announcements</h3>}
        { screen === ScreenType.ANNOUNCEMENT_VIEW && <div style={{display:"flex", justifyContent:"end", marginBottom:"10px"}}>
        {showEdit && <Button
            type='primary'
            onClick={() => {
                handleAnnouncementMenu("create");
                setSelectedAnnouncement(null);
            }}
        >
            Add New Announcement
        </Button>}
        </div>}
        <div className="cardsWrapper">
            <div>
                <Row gutter={16}>

                    {screen === ScreenType.ANNOUNCEMENT_VIEW ? <>
                    
                    {announcement.length ? announcement.map((item, index) => (
                        <>
                            <Col span={8}>
                                <Card style={{minHeight: 315}} bodyStyle={cardStyle} className="webinar-card">
                                    <div className="webinar-wrapper" key={index}>
                                        <div className="date-block"  style={{minHeight: 50}}>
                                            <Badge dot className="speak-icon">
                                                <Icon type="notification" />
                                            </Badge>
                                            <p className="cardlabel">{item.title}</p>
                                            {showEdit && <Dropdown overlay={menu} trigger={['click']} >
                                                <a onClick={e => {
                                                    e.preventDefault();
                                                    setSelectedAnnouncement(announcement[index]);
                                                }}>
                                                    <Icon type="more" style={{color:"white"}} />
                                                </a>
                                            </Dropdown>}
                                        </div>
                                        <p className="title" style={{whiteSpace:"pre-wrap"}} >{item.description}</p>
                                    </div>
                                </Card>
                            </Col>
                        </>
                    )) : <></>}
                     </> : <AnnouncementFormView screen={screen} setScreen={setScreen} data={selectedAnnouncement} userData={userData}/>}
                </Row>
            </div>
        </div>
    </div>
  )
}

export default Announcement

import { findIndex } from "lodash";
import { ResourcesActions } from "./actions";

let Resources = {
    data: [],
    curData: {},
    caseStudyData: [],
    caseStudyCurBlock: {},
    error: "",
    isLoading: false,
    integrationList: [],
    competitorList: [],
    isLoadingIntegrationList: true,
    isLoadingCompetitorList: true,
    slugOccurence: -1
};

const resourcesReducer = (state = Resources, action) => {
    let index;
    switch (action.type) {
        case ResourcesActions.FETCH_RESOURCES_LIST_REQUEST:
        case ResourcesActions.FETCH_RESOURCE_REQUEST:
        case ResourcesActions.ADD_RESOURCE_REQUEST:
        case ResourcesActions.UPDATE_RESOURCE_REQUEST:
        case ResourcesActions.FETCH_CASE_STUDY_REQUEST:
        case ResourcesActions.FETCH_CASE_STUDY_BLOCK_REQUEST:
        case ResourcesActions.ADD_CASE_STUDY_BLOCK_REQUEST:
        case ResourcesActions.UPDATE_CASE_STUDY_BLOCK_REQUEST:
        case ResourcesActions.FETCH_VIDEO_LIST_REQUEST:
        case ResourcesActions.UPDATE_VIDEO_REQUEST:
        case ResourcesActions.DELETE_VIDEO_REQUEST:
        case ResourcesActions.FETCH_VIDEO_TUTORIALS_LIST_REQUEST:
        case ResourcesActions.DELETE_VIDEO_TUTORIAL_REQUEST:
        case ResourcesActions.UPDATE_VIDEO_TUTORIAL_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true,
                slugOccurence: -1
            };
        case ResourcesActions.FETCH_RESOURCES_LIST_SUCCESS:
        case ResourcesActions.FETCH_VIDEO_LIST_SUCCESS:
        case ResourcesActions.FETCH_VIDEO_TUTORIALS_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case ResourcesActions.FETCH_RESOURCE_SUCCESS:
        case ResourcesActions.FETCH_VIDEO_SUCCESS:
        case ResourcesActions.FETCH_VIDEO_TUTORIAL_SUCCESS:
            return {
                ...state,
                curData: action.payload,
                isLoading: false
            };
        case ResourcesActions.ADD_RESOURCE_SUCCESS:
            return {
                ...state,
                data: [...state.data, action.payload],
                curData: action.payload,
                isLoading: false
            };
        case ResourcesActions.UPDATE_RESOURCE_SUCCESS:
        case ResourcesActions.UPDATE_VIDEO_SUCCESS:
        case ResourcesActions.UPDATE_VIDEO_TUTORIAL_SUCCESS:
            index = findIndex(state.data, ["id", action.payload.id]);
            return {
                ...state,
                data: [
                    ...state.data.slice(0, index),
                    action.payload,
                    ...state.data.slice(index + 1)
                ],
                curData: action.payload,
                isLoading: false
            };
        case ResourcesActions.DELETE_RESOURCE_SUCCESS:
            index = findIndex(state.data, ["id", action.payload.id]);
            return {
                ...state,
                data: [
                    ...state.data.slice(0, index),
                    ...state.data.slice(index + 1)
                ],
                error: ""
            };
        case ResourcesActions.DELETE_VIDEO_SUCCESS:
        case ResourcesActions.DELETE_VIDEO_TUTORIAL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: state.data.filter(({ id }) => action.payload.id !== id)
            };
        // for case study
        case ResourcesActions.FETCH_CASE_STUDY_SUCCESS:
            return {
                ...state,
                caseStudyData: action.payload,
                isLoading: false
            };
        case ResourcesActions.FETCH_CASE_STUDY_BLOCK_SUCCESS:
            return {
                ...state,
                caseStudyCurBlock: action.payload,
                isLoading: false
            };
        case ResourcesActions.ADD_CASE_STUDY_BLOCK_SUCCESS:
            return {
                ...state,
                caseStudyData: [...state.caseStudyData, action.payload],
                caseStudyCurBlock: action.payload,
                isLoading: false
            };
        case ResourcesActions.UPDATE_CASE_STUDY_BLOCK_SUCCESS:
            index = findIndex(state.caseStudyData, ["id", action.payload.id]);
            return {
                ...state,
                caseStudyData: [
                    ...state.caseStudyData.slice(0, index),
                    action.payload,
                    ...state.caseStudyData.slice(index + 1)
                ],
                caseStudyCurBlock: action.payload,
                isLoading: false
            };
        case ResourcesActions.DELETE_CASE_STUDY_BLOCK_SUCCESS:
            index = findIndex(state.caseStudyData, ["id", action.payload.id]);
            return {
                ...state,
                caseStudyData: [
                    ...state.caseStudyData.slice(0, index),
                    ...state.caseStudyData.slice(index + 1)
                ],
                error: ""
            };
        case ResourcesActions.RESOURCE_ERROR:
            return {
                ...state,
                data: [],
                curData: {},
                error: action.payload.error,
                isLoading: false
            };
        case ResourcesActions.FETCH_INTEGRATION_LIST_REQUEST:
            return {
                ...state,
                isLoadingIntegrationList: true,
                integrationList: []
            };
        case ResourcesActions.FETCH_INTEGRATION_LIST_SUCCESS:
        case ResourcesActions.FETCH_INTEGRATION_LIST_ERROR:
            return {
                ...state,
                isLoadingIntegrationList: false,
                integrationList: action.payload
            }
        case ResourcesActions.FETCH_COMPETITOR_LIST_REQUEST:
            return {
                ...state,
                isLoadingCompetitorList: true,
                competitorList: []
            };
        case ResourcesActions.FETCH_COMPETITOR_LIST_SUCCESS:
        case ResourcesActions.FETCH_COMPETITOR_LIST_ERROR:
            return {
                ...state,
                isLoadingCompetitorList: false,
                competitorList: action.payload
            }
        case ResourcesActions.FETCH_SLUG_OCCURENCE_REQUEST:
            return {
                ...state,
                slugOccurence: -1
            };
        case ResourcesActions.FETCH_SLUG_OCCURENCE_SUCCESS:
        case ResourcesActions.FETCH_SLUG_OCCURENCE_ERROR:
            return {
                ...state,
                slugOccurence: action.occurence
            };
        default:
            return state;
    }
};

export default resourcesReducer;

import { createActionTypes } from "../../utils";

export const ResourcesV2Actions = createActionTypes("resourcesV2", [
    // Ebook
    "FETCH_EBOOK_LIST_REQUEST",
    "FETCH_EBOOK_LIST_SUCCESS",
    "FETCH_EBOOK_LIST_ERROR",
    "FETCH_EBOOK_BYID_REQUEST",
    "FETCH_EBOOK_BYID_SUCCESS",
    "FETCH_EBOOK_BYID_ERROR",
    "ADD_EBOOK_REQUEST",
    "ADD_EBOOK_SUCCESS",
    "ADD_EBOOK_ERROR",
    "UPDATE_EBOOK_REQUEST",
    "UPDATE_EBOOK_SUCCESS",
    "UPDATE_EBOOK_ERROR",

    // Whitepaper
    "FETCH_WP_LIST_REQUEST",
    "FETCH_WP_LIST_SUCCESS",
    "FETCH_WP_LIST_ERROR",
    "FETCH_WP_BYID_REQUEST",
    "FETCH_WP_BYID_SUCCESS",
    "FETCH_WP_BYID_ERROR",
    "ADD_WP_REQUEST",
    "ADD_WP_SUCCESS",
    "ADD_WP_ERROR",
    "UPDATE_WP_REQUEST",
    "UPDATE_WP_SUCCESS",
    "UPDATE_WP_ERROR",

    // Guide
    "FETCH_GUIDE_LIST_REQUEST",
    "FETCH_GUIDE_LIST_SUCCESS",
    "FETCH_GUIDE_LIST_ERROR",
    "FETCH_GUIDE_BYID_REQUEST",
    "FETCH_GUIDE_BYID_SUCCESS",
    "FETCH_GUIDE_BYID_ERROR",
    "ADD_GUIDE_REQUEST",
    "ADD_GUIDE_SUCCESS",
    "ADD_GUIDE_ERROR",
    "UPDATE_GUIDE_REQUEST",
    "UPDATE_GUIDE_SUCCESS",
    "UPDATE_GUIDE_ERROR",

    // Article
    "FETCH_ARTICLE_LIST_REQUEST",
    "FETCH_ARTICLE_LIST_SUCCESS",
    "FETCH_ARTICLE_LIST_ERROR",
    "FETCH_ARTICLE_BYID_REQUEST",
    "FETCH_ARTICLE_BYID_SUCCESS",
    "FETCH_ARTICLE_BYID_ERROR",
    "ADD_ARTICLE_REQUEST",
    "ADD_ARTICLE_SUCCESS",
    "ADD_ARTICLE_ERROR",
    "UPDATE_ARTICLE_REQUEST",
    "UPDATE_ARTICLE_SUCCESS",
    "UPDATE_ARTICLE_ERROR",
    "DELETE_ARTICLE_REQUEST",
    "DELETE_ARTICLE_SUCCESS",
    "DELETE_ARTICLE_ERROR",

    // Podcast
    "FETCH_PODCAST_LIST_REQUEST",
    "FETCH_PODCAST_LIST_SUCCESS",
    "FETCH_PODCAST_LIST_ERROR",
    "FETCH_PODCAST_BYID_REQUEST",
    "FETCH_PODCAST_BYID_SUCCESS",
    "FETCH_PODCAST_BYID_ERROR",
    "ADD_PODCAST_REQUEST",
    "ADD_PODCAST_SUCCESS",
    "ADD_PODCAST_ERROR",
    "UPDATE_PODCAST_REQUEST",
    "UPDATE_PODCAST_SUCCESS",
    "UPDATE_PODCAST_ERROR",
    "DELETE_PODCAST_REQUEST",
    "DELETE_PODCAST_SUCCESS",
    "DELETE_PODCAST_ERROR",
]);

/**
 * Ebooks
 */

export const fetchEbookListRequest = () => ({
    type: ResourcesV2Actions.FETCH_EBOOK_LIST_REQUEST
});

export const fetchEbookListSuccess = (obj) => ({
    type: ResourcesV2Actions.FETCH_EBOOK_LIST_SUCCESS,
    payload: obj
});

export const fetchEbookListError = (obj) => ({
    type: ResourcesV2Actions.FETCH_EBOOK_LIST_ERROR,
    payload: obj
});

export const fetchEbookByIdRequest = (id) => ({
    type: ResourcesV2Actions.FETCH_EBOOK_BYID_REQUEST,
    payload: id
});

export const fetchEbookByIdSuccess = (obj) => ({
    type: ResourcesV2Actions.FETCH_EBOOK_BYID_SUCCESS,
    payload: obj
});

export const fetchEbookByIdError = (obj) => ({
    type: ResourcesV2Actions.FETCH_EBOOK_BYID_ERROR,
    payload: obj
});

export const addEbookRequest = (obj) => ({
    type: ResourcesV2Actions.ADD_EBOOK_REQUEST,
    payload: obj
});

export const addEbookSuccess = (obj) => ({
    type: ResourcesV2Actions.ADD_EBOOK_SUCCESS,
    payload: obj
});

export const addEbookError = (obj) => ({
    type: ResourcesV2Actions.ADD_EBOOK_ERROR,
    payload: obj
});

export const updateEbookRequest = (obj) => ({
    type: ResourcesV2Actions.UPDATE_EBOOK_REQUEST,
    payload: obj
});

export const updateEbookSuccess = (obj) => ({
    type: ResourcesV2Actions.UPDATE_EBOOK_SUCCESS,
    payload: obj
});

export const updateEbookError = (obj) => ({
    type: ResourcesV2Actions.UPDATE_EBOOK_ERROR,
    payload: obj
});

/**
 * Whitepaper
 */

export const fetchWPListRequest = () => ({
    type: ResourcesV2Actions.FETCH_WP_LIST_REQUEST
});

export const fetchWPListSuccess = (obj) => ({
    type: ResourcesV2Actions.FETCH_WP_LIST_SUCCESS,
    payload: obj
});

export const fetchWPListError = (obj) => ({
    type: ResourcesV2Actions.FETCH_WP_LIST_ERROR,
    payload: obj
});

export const fetchWPByIdRequest = (id) => ({
    type: ResourcesV2Actions.FETCH_WP_BYID_REQUEST,
    payload: id
});

export const fetchWPByIdSuccess = (obj) => ({
    type: ResourcesV2Actions.FETCH_WP_BYID_SUCCESS,
    payload: obj
});

export const fetchWPByIdError = (obj) => ({
    type: ResourcesV2Actions.FETCH_WP_BYID_ERROR,
    payload: obj
});

export const addWPRequest = (obj) => ({
    type: ResourcesV2Actions.ADD_WP_REQUEST,
    payload: obj
});

export const addWPSuccess = (obj) => ({
    type: ResourcesV2Actions.ADD_WP_SUCCESS,
    payload: obj
});

export const addWPError = (obj) => ({
    type: ResourcesV2Actions.ADD_WP_ERROR,
    payload: obj
});

export const updateWPRequest = (obj) => ({
    type: ResourcesV2Actions.UPDATE_WP_REQUEST,
    payload: obj
});

export const updateWPSuccess = (obj) => ({
    type: ResourcesV2Actions.UPDATE_WP_SUCCESS,
    payload: obj
});

export const updateWPError = (obj) => ({
    type: ResourcesV2Actions.UPDATE_WP_ERROR,
    payload: obj
});

/**
 * Guide
 */

 export const fetchGuideListRequest = () => ({
    type: ResourcesV2Actions.FETCH_GUIDE_LIST_REQUEST
});

export const fetchGuideListSuccess = (obj) => ({
    type: ResourcesV2Actions.FETCH_GUIDE_LIST_SUCCESS,
    payload: obj
});

export const fetchGuideListError = (obj) => ({
    type: ResourcesV2Actions.FETCH_GUIDE_LIST_ERROR,
    payload: obj
});

export const fetchGuideByIdRequest = (id) => ({
    type: ResourcesV2Actions.FETCH_GUIDE_BYID_REQUEST,
    payload: id
});

export const fetchGuideByIdSuccess = (obj) => ({
    type: ResourcesV2Actions.FETCH_GUIDE_BYID_SUCCESS,
    payload: obj
});

export const fetchGuideByIdError = (obj) => ({
    type: ResourcesV2Actions.FETCH_GUIDE_BYID_ERROR,
    payload: obj
});

export const addGuideRequest = (obj) => ({
    type: ResourcesV2Actions.ADD_GUIDE_REQUEST,
    payload: obj
});

export const addGuideSuccess = (obj) => ({
    type: ResourcesV2Actions.ADD_GUIDE_SUCCESS,
    payload: obj
});

export const addGuideError = (obj) => ({
    type: ResourcesV2Actions.ADD_GUIDE_ERROR,
    payload: obj
});

export const updateGuideRequest = (obj) => ({
    type: ResourcesV2Actions.UPDATE_GUIDE_REQUEST,
    payload: obj
});

export const updateGuideSuccess = (obj) => ({
    type: ResourcesV2Actions.UPDATE_GUIDE_SUCCESS,
    payload: obj
});

export const updateGuideError = (obj) => ({
    type: ResourcesV2Actions.UPDATE_GUIDE_ERROR,
    payload: obj
});

/**
 * Article
 */

export const fetchArticleListRequest = () => ({
    type: ResourcesV2Actions.FETCH_ARTICLE_LIST_REQUEST
});

export const fetchArticleListSuccess = (obj) => ({
    type: ResourcesV2Actions.FETCH_ARTICLE_LIST_SUCCESS,
    payload: obj
});

export const fetchArticleListError = (obj) => ({
    type: ResourcesV2Actions.FETCH_ARTICLE_LIST_ERROR,
    payload: obj
});

export const fetchArticleByIdRequest = (obj) => ({
    type: ResourcesV2Actions.FETCH_ARTICLE_BYID_REQUEST,
    payload: obj
});

export const fetchArticleByIdSuccess = (obj) => ({
    type: ResourcesV2Actions.FETCH_ARTICLE_BYID_SUCCESS,
    payload: obj
});

export const fetchArticleByIdError = (obj) => ({
    type: ResourcesV2Actions.FETCH_ARTICLE_BYID_ERROR,
    payload: obj
});

export const addArticleRequest = (obj) => ({
    type: ResourcesV2Actions.ADD_ARTICLE_REQUEST,
    payload: obj
});

export const addArticleSuccess = (obj) => ({
    type: ResourcesV2Actions.ADD_ARTICLE_SUCCESS,
    payload: obj
});

export const addArticleError = (obj) => ({
    type: ResourcesV2Actions.ADD_ARTICLE_ERROR,
    payload: obj
});

export const updateArticleRequest = (obj) => ({
    type: ResourcesV2Actions.UPDATE_ARTICLE_REQUEST,
    payload: obj
});

export const updateArticleSuccess = (obj) => ({
    type: ResourcesV2Actions.UPDATE_ARTICLE_SUCCESS,
    payload: obj
});

export const updateArticleError = (obj) => ({
    type: ResourcesV2Actions.UPDATE_ARTICLE_ERROR,
    payload: obj
});

/**
 * PODCAST
 */

export const fetchPodcastListRequest = () => ({
    type: ResourcesV2Actions.FETCH_PODCAST_LIST_REQUEST
});

export const fetchPodcastListSuccess = (obj) => ({
    type: ResourcesV2Actions.FETCH_PODCAST_LIST_SUCCESS,
    payload: obj
});

export const fetchPodcastListError = (obj) => ({
    type: ResourcesV2Actions.FETCH_PODCAST_LIST_ERROR,
    payload: obj
});

export const fetchPodcastByIdRequest = (obj) => ({
    type: ResourcesV2Actions.FETCH_PODCAST_BYID_REQUEST,
    payload: obj
});

export const fetchPodcastByIdSuccess = (obj) => ({
    type: ResourcesV2Actions.FETCH_PODCAST_BYID_SUCCESS,
    payload: obj
});

export const fetchPodcastByIdError = (obj) => ({
    type: ResourcesV2Actions.FETCH_PODCAST_BYID_ERROR,
    payload: obj
});

export const addPodcastRequest = (obj, cb) => ({
    type: ResourcesV2Actions.ADD_PODCAST_REQUEST,
    payload: obj,
    cb
});

export const addPodcastSuccess = (obj) => ({
    type: ResourcesV2Actions.ADD_PODCAST_SUCCESS,
    payload: obj
});

export const addPodcastError = (obj) => ({
    type: ResourcesV2Actions.ADD_PODCAST_ERROR,
    payload: obj
});

export const updatePodcastRequest = (obj, cb) => ({
    type: ResourcesV2Actions.UPDATE_PODCAST_REQUEST,
    payload: obj,
    cb
});

export const updatePodcastSuccess = (obj) => ({
    type: ResourcesV2Actions.UPDATE_PODCAST_SUCCESS,
    payload: obj
});

export const updatePodcastError = (obj) => ({
    type: ResourcesV2Actions.UPDATE_PODCAST_ERROR,
    payload: obj
});

export const deletePodcastRequest = (obj, cb) => ({
    type: ResourcesV2Actions.DELETE_PODCAST_REQUEST,
    payload: obj,
    cb
});

export const deletePodcastSuccess = (obj) => ({
    type: ResourcesV2Actions.DELETE_PODCAST_SUCCESS,
    payload: obj
});

export const deletePodcastError = (obj) => ({
    type: ResourcesV2Actions.DELETE_PODCAST_ERROR,
    payload: obj
});

export default ResourcesV2Actions;
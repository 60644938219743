import React, { Component } from "react";
import { Button, Modal, Form, Input, Row, Col, Popconfirm, message, Spin } from "antd";
import { TableSkeleton } from "../../../components";
import lookup from "../users.json";

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            users: [],
            isLoading:false
        }
    }

    componentDidMount(){
        const { users } = this.props;
        this.setState({users: users});
    }

    formatData = (data) => {
        if(data){
            return data.map((record, i) => {
                return {
                    ...record,
                    key: `${record.first_name}-${i}` ,
                    id: record.id,
                    unsubscribed_status: (record.unsubscribed === 1) ? 'Yes' : ' ',
                    customAction: (<Popconfirm title="Are you sure delete this task?" onConfirm={() => this.handleDelete(record)} okText="Yes" cancelText="No"><a href="#">Delete</a></Popconfirm>)
                };
            });
        } else {
            return;
        }
    };

    showAddForm = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleAdd = () => {
        this.props.form.validateFieldsAndScroll(["userEmailId"], (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                const { businessInfo } = this.props;
                const { businessId } = businessInfo;
                const formData = this.props.form.getFieldsValue();
                formData.userRole = "owner";
                formData.userPassword = "Test1234";
                formData.sendInvite = false;
                formData.subscribed = true;
                this.props.addUserRequest( { businessId, data: formData, cb: this.addUserCallBack } );
            }
        });
    };

    addUserCallBack = (response) => {
        this.setState({ isLoading: false });
        if(!response){
            this.props.form.resetFields();
            this.setState({ visible: false });
            message.success('User added successfully, updating user list.');
            this.refreshUser();
        } else {
            message.error('Error occur, Try again or contact growth@birdeye.com');
        }
    };

    refreshUser = () => {
        this.setState({ isLoading: true });
        const { businessInfo } = this.props;
        const { businessNumber } = businessInfo;
        setTimeout(() => {
            this.props.fetchUsersByBusinessNumber( { businessNumber: businessNumber ,  cb: this.usersCallBack });
        }, 5000);
    }

    usersCallBack = (response) => {
        if(!response){
            this.setState({ isLoading: false });
            const { users } = this.props;
            this.setState({users: users});
        } else {
            message.error({ content: 'Error Occurred'});
        }
    };

    handleDelete = (data) => {
        const { businessInfo } = this.props;
        const { businessNumber } = businessInfo;
        this.setState({ isLoading: true });
        this.props.deleteUserRequest({ businessNumber: businessNumber, data: data, cb: this.deleteCallBack });
    };

    deleteCallBack = (error) => {
        if(!error){
            this.refreshUser();
        } else {
            this.setState({ isLoading: false });
            message.error('Error occur, Try again or contact growth@birdeye.com');
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { isLoading, users } = this.state;
        return (
            <div>
                { isLoading &&
                <div className="spin-overlay">
                    <Spin size="large" />
                </div>
                }
                <div className="">
                    <Row>
                        <Col span={4} offset={20}>
                            <Button type="primary" onClick={this.showAddForm}>
                                Add User
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <TableSkeleton
                                columns={lookup.columns}
                                dataSource={this.formatData(users)}
                            />
                        </Col>
                    </Row>
                    <Modal
                        visible={this.state.visible}
                        title="Add User"
                        okText="Add"
                        onCancel={this.handleCancel}
                        onOk={this.handleAdd}
                    >
                        <Form layout="vertical">
                            <Form.Item label="First Name">
                                {getFieldDecorator('firstName', {
                                    initialValue: null
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Last Name">
                                {getFieldDecorator('lastName', {
                                    initialValue: null
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Email Id">
                                {getFieldDecorator('userEmailId', {
                                    rules: [{ required: true, message: 'Provide User EmailId' }],
                                    initialValue: null
                                })(<Input />)}
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </div>
        );
    }
}


export default Users;
import React from "react";
import PropTypes from "prop-types";
import { Icon, Skeleton, List, message, Tag } from "antd";

const DocumentListCol = (props) => {
    const { heading, headingIcon, isLoading, list, type, downloadPartnerDocumentRequest } = props;

    const onDownloadCb = (response) => {
        message.destroy();
        if (response.document) {
            window.open(response.document);
        } else {
            message.error("Oops! Something went wrong while downloading this file.");
        }
    };

    const handleDownload = (meta) => {
        if (meta.document) {
            onDownloadCb(meta);
            return;
        }
        
        message.loading("Your download is preparing. Please wait...", 0);
        downloadPartnerDocumentRequest({ fileType: meta.file_type, fileId: meta.file_id }, onDownloadCb);
    }

    const renderCategory = (category) => {
        switch (category) {
            case "sales-decks": return "Sales Decks";
            case "product-brochures": return "Product Brochures";
            case "competitive": return "Competitive Materials";
            case "prospecting-kits": return "Prospecting Kits";
            case "email-templates": return "Email Templates";
            default: return "";
        }
    }

    const renderAvtar = (item, section) => {
        const imageURL = (section === "featured") ? item.featured_card_image : item.new_card_image;

        if (imageURL) {
            return <img src={imageURL} style={{ width: "75px", height: "75px" }} alt=""/>;
        }

        let fileIcon = "file";
        switch(item.file_type) {
            case "docx": fileIcon = "file-word"; break;
            case "pptx": fileIcon = "file-ppt"; break;
            case "xlsx": fileIcon = "file-excel"; break;
            case "pdf": fileIcon = "file-pdf"; break;
            default: return "";
        }

        return <div style={{ height: "75px", width: "75px", background: "#000" }}><Icon type={fileIcon} style={{ fontSize: "35px", padding: "20px", color: "#fff" }}/></div>;

    }

    const renderListItem = (item, section) => {
        return (
            <List.Item
                actions={[
                    <Icon type="download" onClick={(e) => {e.preventDefault(); handleDownload(item)}} />
                ]}
                >
                <List.Item.Meta
                    avatar={renderAvtar(item, section)}
                    title={
                        <>{item.title}{item.group ? <><br/><small>({item.group})</small></> : ""}</>
                    }
                    description={
                        <div>
                            {
                                item.type !== "both" && (item.type === "coBranded" ? (<Tag color="#2db7f5">Co-Branded</Tag>) : (<Tag color="#87d068">Whitelabeled</Tag>))
                            }
                            {
                                item.category && ( <Tag>{renderCategory(item.category)}</Tag> )
                            }
                        </div>
                    }
                />
            </List.Item>
        )
    };

    return (
        <React.Fragment>
            <h2><Icon type={ headingIcon } />{ heading }</h2>
            <Skeleton active={true} loading={isLoading && !list.length} title={false} paragraph={{ rows: 6 }}>
                <List
                    itemLayout="horizontal"
                    dataSource={list}
                    renderItem={item => (renderListItem(item, type))}
                    bordered={true}
                    pagination={{ pageSize: 3, position: "top" }}
                    loading={isLoading}
                />
            </Skeleton>
        </React.Fragment>
    );
}

DocumentListCol.propTypes = {
    type: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    headingIcon: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    list: PropTypes.array,
    downloadPartnerDocumentRequest: PropTypes.func.isRequired
};

export default DocumentListCol;
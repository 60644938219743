import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Select, Button, Alert, Spin, Modal } from "antd";
import { pick, includes } from "lodash";

import { RedirectWrapper } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";

const Option = Select.Option;
const FormItem = Form.Item;

class UserForm extends Component {
    initialData = {
        id: 0,
        name: "",
        label: "",
        description: "",
        roles: [],
        users: []
    };

    state = {
        data: this.initialData,
        formError: "",
        isEditPage: !!this.props.match.params.id
    };

    componentDidMount() {
        const { location, match, fetchPermission, fetchUsersListRequest, fetchUserRolesListRequest } = this.props;
        if (location.state && location.state.data) {
            this.setState({ data: location.state.data });
        } else if (match.params.id) {
            fetchPermission({ id: match.params.id });
        }
        if (!match.params.id) {
            fetchUserRolesListRequest();
            fetchUsersListRequest();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.curData.id !== prevProps.curData.id) {
            this.setState({ data: this.props.curData });
        }
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                isEditPage: !!this.props.match.params.id,
                data : this.initialData
            });
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.handleFieldChange(name, value);
    };

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        this.setState({ data, formError: "" });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const rawData = { ...this.state.data };
        const fieldsToBeValidated = pick(this.state.data, [
            "name",
            "label"
        ]);

        if (includes(fieldsToBeValidated, "")) {
            this.setState({ formError: "Name and Label are mandatory fields" });
            return;
        }
        if (this.state.isEditPage) {
            rawData.id = this.state.data.id;
            this.props.updatePermission({ data:rawData, cb: this.renderFeedback });
        } else {
            this.props.addPermission({ data:rawData, cb: this.renderFeedback });
        }
    };

    renderFeedback = (userErrorType) => {
        const goToPermissionsPage = () => this.props.history.push("/permissions");
        if (!userErrorType) {
            return Modal.success({
                title: `You have successfully ${
                    this.state.isEditPage ? "edited" : "added"
                } a permission`,
                content: `Check the ${
                    this.state.isEditPage ? "updated" : "new"
                } record in permissions list page`,
                okText: "Go to permissions page",
                maskClosable: true,
                onOk() {
                    goToPermissionsPage();
                }
            });
        } else {
            return Modal.warning({
                title: `The record was not ${
                    this.state.isEditPage ? "edited" : "added"
                } successfully...`,
                content: "Please check your input or try again later..."
            });
        }
    };

    renderFormBlock = () => {
        const {
            name,
            label,
            description,
            roles,
            users
        } = this.state.data;

        const { rolesList, userListData } = this.props;

        if (this.state.isEditPage && this.props.userError === "userNotFound") {
            return <Alert message="User not found" type="userError" showIcon />;
        } else {
            return (
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Name">
                        <Input
                            name="name"
                            value={name}
                            placeholder="Permission name"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Label">
                        <Input
                            name="label"
                            value={label}
                            placeholder="Permission label"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Description">
                        <Input
                            name="description"
                            value={description}
                            placeholder="Permission description"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    {
                        !this.state.isEditPage && <FormItem {...formItemLayout} label="Add Permission to following Roles:">
                            <Select
                                value={roles}
                                mode="multiple"
                                placeholder="Select User Role(s)"
                                onChange={(value) =>
                                    this.handleFieldChange("roles", value)
                                }>
                                {rolesList.map((role) => (
                                    <Option
                                        key={role.id}
                                        value={role.id}>
                                        {role.name}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                    }
                    {
                        !this.state.isEditPage && <FormItem {...formItemLayout} label="Add Permission to following Users:">
                            <Select
                                value={users}
                                mode="multiple"
                                placeholder="Select User(s)"
                                onChange={(value) =>
                                    this.handleFieldChange("users", value)
                                }>
                                {userListData.map((user) => (
                                    <Option
                                        key={user.id}
                                        value={user.id}>
                                        {user.name}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                    }
                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit">
                            {this.state.isEditPage ? "Save" : "Create"}
                        </Button>
                    </FormItem>
                </Form>
            );
        }
    };

    render() {
        const { loadingUser, userError } = this.props;

        const unauthorized = userError === "unauthorized";
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="users-root">
                <h2>{this.state.isEditPage ? "Update" : "Add New"} Permission</h2>
                {this.state.isEditPage && loadingUser ? (
                    <Spin />
                ) : (
                    this.renderFormBlock()
                )}
            </div>
        );
    }
}

const UpdatePermission = Form.create()(UserForm);

UpdatePermission.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loadingUser: PropTypes.bool.isRequired,
    userError: PropTypes.string.isRequired,
    curData: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    fetchUserRequest: PropTypes.func.isRequired,
    updateUserRequest: PropTypes.func.isRequired,
    addUserRequest: PropTypes.func.isRequired,
    rolesList: PropTypes.array.isRequired
};

export default UpdatePermission;
import { LeadgenManagementActions } from "./actions";

let LeadgenManagement = {
    isLoading: false,
    modalMethod: {
        timestamp: 0,
        visible: false,
        type: null,
        message: null,
        duration: 2
    },
    pageData: {
        campaignUrlBuilder: [],
        campaignUrlBuilderArchive: [],
        campaignUrlBuilderEdit: {},
        contactDetails: [],
        errorMessage:{}
    }
};

const leadgenManagementReducer = (state = LeadgenManagement, action) => {
    switch (action.type) {
        case LeadgenManagementActions.SEND_CONTACT_REQ:
        case LeadgenManagementActions.SEND_SFDC_LEAD_FORM_REQ:
        case LeadgenManagementActions.SEND_SFDC_LEAD_FILE_REQ:
        case LeadgenManagementActions.REQ_CONTACT_DETAILS:
            return {
                ...state,
                isLoading: true,
            };
        case LeadgenManagementActions.REQ_CONTACT_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                pageData: {
                    ...state.pageData,
                    contactDetails: action.payload.contactsInfoList || [],
                    errorMessage: {}
                }
            };
        case LeadgenManagementActions.REQ_CONTACT_DETAILS_ERROR:
            return {
                ...state,
                isLoading: false,
                pageData: {
                    ...state.pageData,
                    contactDetails: [],
                    errorMessage: {... action.payload} || {}
                }
            };
        case LeadgenManagementActions.REQ_EMAIL_UPDATER:
            return {
                ...state,
                isLoading: true,
                modalMethod: {
                    visible: false,
                    type: null,
                    message: null
                }
            };
        case LeadgenManagementActions.SUCCESS_CONTACT_RESP:
        case LeadgenManagementActions.SUCCESS_SFDC_LEAD_RESP:
            return {
                ...state,
                isLoading: false,
                modalMethod: {
                    visible: true,
                    type: 'success',
                    message: action.payload
                }
            };
        case LeadgenManagementActions.ERROR_CONTACT_RESP:
        case LeadgenManagementActions.ERROR_SFDC_LEAD_RESP:
            return {
                ...state,
                isLoading: false,
                modalMethod: {
                    visible: true,
                    type: 'error',
                    message: action.payload
                }
            };
        case LeadgenManagementActions.HIDE_CONTACT_MODAL_METHOD:
        case LeadgenManagementActions.HIDE_SFDC_LEAD_MODAL_METHOD:
            return {
                ...state,
                isLoading: false,
                modalMethod: {
                    visible: false,
                    type: null,
                    message: null
                }
            };
        case LeadgenManagementActions.REQ_PAGEDATA_CAMPAIGN_URL_BUILDER:
            return {
                ...state,
                isLoading: true
            };
        case LeadgenManagementActions.REQ_PAGEDATA_CAMPAIGN_URL_BUILDER_SUCCESS:
        case LeadgenManagementActions.REQ_PAGEDATA_CAMPAIGN_URL_BUILDER_ERROR:
            return {
                ...state,
                isLoading: false,
                pageData: {
                    ...state.pageData,
                    campaignUrlBuilder: action.payload
                }
            };
        case LeadgenManagementActions.REQ_CAMPAIGN_URL_BUILDER_ARCHIVE:
            return {
                ...state,
                isLoading: true,
            };
        case LeadgenManagementActions.REQ_CAMPAIGN_URL_BUILDER_ARCHIVE_SUCCESS:
        case LeadgenManagementActions.REQ_CAMPAIGN_URL_BUILDER_ARCHIVE_ERROR:
            return {
                ...state,
                isLoading: false,
                pageData: {
                    ...state.pageData,
                    campaignUrlBuilderArchive: action.payload
                }
            };
        case LeadgenManagementActions.EMAIL_UPDATER_SUCCESS:
        case LeadgenManagementActions.EMAIL_UPDATER_ERROR:
            return {
                ...state,
                isLoading: false,
                pageData: action.payload
            };
        default:
            return state;
    }
};

export default leadgenManagementReducer;

import { connect } from "react-redux";
import {
    fetchBusinessByBusinessNumber, fetchStates, fetchCountries, updateBusinessRequest, addUserRequest, findBusinessRequest, fetchUsersByBusinessNumber, deleteUserRequest, fetchAggregationRequest, deleteAggregationRequest, fetchAggregationSources, addAggregationRequest, businessSignupRequest, fetchBusinessByNumberWithGoogle, fetchCities, fetchBusinessByEmailId, reaggregationRequest
} from "./actions";

import {
    fetchBirdeyeCategoriesList
} from "../Categories/actions";

import TransferBusiness from "./TransferBusiness";
import DeleteBusiness from "./DeleteBusiness";
import FixProfile from "./FixProfile"
import Signup from "./Signup"

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Categories,
    ...state.Business
});

export const TransferBusinessPage = connect(
    mapStateToProps
)(TransferBusiness);

export const DeleteBusinessPage = connect(
    mapStateToProps
)(DeleteBusiness);

export const FixProfilePage = connect(
    mapStateToProps, { fetchBusinessByBusinessNumber, fetchStates, fetchCountries, fetchBirdeyeCategoriesList, addUserRequest, updateBusinessRequest, findBusinessRequest, fetchUsersByBusinessNumber, deleteUserRequest, fetchAggregationRequest, deleteAggregationRequest, fetchAggregationSources, addAggregationRequest, fetchBusinessByNumberWithGoogle, fetchBusinessByEmailId, reaggregationRequest }
)(FixProfile);

export const SignupPage = connect(
    mapStateToProps, { businessSignupRequest, fetchStates, fetchCountries, fetchCities }
)(Signup);

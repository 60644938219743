import { FeaturePagesActions } from "./actions";

const featurePagesState = {
    isLoading: false,
    isUpdated: false,
    isAdded: false,
    featurePagesList: [],
    updateResponse: [],
    deleteResponse: [],
    AddedData: [],
    featurePageToUpdate: []
};

const featurePagesReducer = (state = featurePagesState, action) => {
    switch (action.type) {
        case FeaturePagesActions.REQ_FEATURE_PAGES_LIST:
        case FeaturePagesActions.REQ_FEATURE_PAGE_BY_ID:
        case FeaturePagesActions.REQ_ADD_FEATURE_PAGE:
        case FeaturePagesActions.REQ_UPDATE_FEATURE_PAGE:
        case FeaturePagesActions.REQ_DELETE_FEATURE_PAGE:
            return {
                ...state,
                isLoading: true
            };
        case FeaturePagesActions.SUCCESS_FEATURE_PAGES_LIST:
            return {
                ...state,
                isLoading: false,
                featurePagesList: action.payload
            };
        case FeaturePagesActions.ERROR_FEATURE_PAGES_LIST:
            return {
                ...state,
                isLoading: false,
                featurePagesList: []
            };
        case FeaturePagesActions.SUCCESS_FEATURE_PAGE_BY_ID:
            return {
                ...state,
                isLoading: false,
                featurePageToUpdate: action.payload
            };
         case FeaturePagesActions.ERROR_FEATURE_PAGE_BY_ID:
            return {
                ...state,
                isLoading: false,
                featurePageToUpdate: []
            };
        case FeaturePagesActions.SUCCESS_ADD_FEATURE_PAGE:
            return {
                ...state,
                isLoading: false,
                isAdded: true,
                AddedData: action.payload
            };
        case FeaturePagesActions.ERROR_ADD_FEATURE_PAGE:
            return {
                ...state,
                isLoading: false,
                isAdded: false,
                AddedData: []
            };
        case FeaturePagesActions.SUCCESS_UPDATE_FEATURE_PAGE:
            return {
                ...state,
                isLoading: false,
                isUpdated: true,
                updateResponse: action.payload
            };
        case FeaturePagesActions.ERROR_UPDATE_FEATURE_PAGE:
            return {
                ...state,
                isLoading: false,
                isUpdated: false,
                updateResponse: []
            };
        case FeaturePagesActions.SUCCESS_DELETE_FEATURE_PAGE:
            return {
                ...state,
                isLoading: false,
                deleteResponse: action.payload
            };
        case FeaturePagesActions.ERROR_DELETE_FEATURE_PAGE:
            return {
                ...state,
                isLoading: false,
                deleteResponse: []
            };
        default:
            return state;
    }
};

export default featurePagesReducer;
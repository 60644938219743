import { connect } from "react-redux";

import { 
    reqWebinarSchedule, reqWebinarData, reqWebinarEvents, reqWebinarPresenters, reqWebinarEventDelete,
    reqWebinarRegistrants, reqWebinarAttendees, reqWebinarPresenterModalOpen, reqWebinarPresenterModalClose,
    reqWebinarPresenterAdd, reqWebinarPresenterUpdate, reqWebinarPresenterDelete, 
    getLandingPageWebinar, updateLandingPageWebinar, getWebinarRecordingAssets, getWebinarUpcomingSession, 
    refreshWebinarAttendance, reqMeetingSchedule, reqMeetingDelete
} from "./actions";

import Schedule from "./Schedule";
import Events from "./Events";
import LandingConfigurator from "./LandingConfigurator";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Schedule,
    ...state.WebinarTool
});

export const WebinarSchedulePage = connect(
    mapStateToProps,
    { 
        reqWebinarSchedule, reqWebinarData, reqWebinarPresenters, reqWebinarPresenterModalOpen, reqWebinarPresenterModalClose,
        reqWebinarPresenterAdd, reqWebinarPresenterUpdate, reqWebinarPresenterDelete, getWebinarRecordingAssets, getWebinarUpcomingSession,
        reqMeetingSchedule
    }
)(Schedule);

export const WebinarEventPage = connect(
    mapStateToProps,
    { reqWebinarEvents, reqWebinarEventDelete, reqWebinarRegistrants, reqWebinarAttendees, refreshWebinarAttendance, reqMeetingDelete }
)(Events);

export const WebinarLandingPage = connect(
    mapStateToProps,
    { reqWebinarEvents, updateLandingPageWebinar, getLandingPageWebinar }
)(LandingConfigurator);

export const tableColumns = [
    {
        "title": "Display Picture",
        "key": "photo",
        "dataIndex": "formattedPhoto",
        "width": "15%",
        "align": "center"
    }, {
        "title": "Name",
        "key": "name",
        "searchable": true,
        "width": "25%",
        "align": "center"
    }, {
        "title": "Designation",
        "key": "designation",
        "dataIndex": "formattedDesignation",
        "searchable": true,
        "width": "30%",
        "align": "center",
        "className": "tdBreakString"
    }, {
        "title": "Tag",
        "key": "tag",
        "dataIndex": "formattedTag",
        "filters": [{ text: "Birdeye", value: "Birdeye" }, { text: "Specialist", value: "Specialist" }],
        "onFilter": (value, record) => record.tag.includes(value),
        "width": "18%",
        "align": "center"
    }, {
        "title": "Action",
        "key": "action",
        "width": "12%",
        "align": "center",
        "className": "tdAction"
    }
];
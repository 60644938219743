import { connect } from "react-redux";

import {
  fetchRecordsRequest,
  fetchTargetProfileRequest,
  fetchStateListRequest,
  fetchCityListRequest,
  fetchIndustryListRequest,
  fetchOwnerListRequest
} from "./redux/actions";

import ProspectorWrapper from "./ProspectorWrapper";

const mapStateToProps = (state) => ({
  ...state.User,
  ...state.Prospector
});

export const Prospector = connect(mapStateToProps, {
  fetchRecordsRequest,
  fetchTargetProfileRequest,
  fetchStateListRequest,
  fetchCityListRequest,
  fetchIndustryListRequest,
  fetchOwnerListRequest
})(ProspectorWrapper);

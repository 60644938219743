import React, { Component } from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import {message, Tabs} from "antd";
import { filter } from "lodash";

import {default as ComponentWebinarEvents} from "../../components/WebinarEvents";
import { PageHeaderContainer } from "../../components";
import { getIsPageActionAccessible } from "./../../utils/";

const 
	{TabPane} = Tabs;

class WebinarEvents extends Component {

	constructor(props) {
        super(props);

        this.state = {
            isWebinarPage: true
        };
    }

    componentWillMount() {
        const 
            {reqWebinarEvents} = this.props;

        reqWebinarEvents({eventsType: "upcoming"});
    }

    componentDidUpdate(prevProps, prevState) {
        const 
            {notification: prevNotification} = prevProps,
            {notification: nextNotification} = this.props;

        if (prevNotification.timestamp < nextNotification.timestamp) {
            message[nextNotification.type](nextNotification.message);
        }

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isWebinarPage: nextProps.location.pathname.includes("webinar/events")
        });
    }

    handleTabClick = (activeKey) => {
        let 
            {reqWebinarEvents, events} = this.props,
            {upcoming: upcomingEvents, past: pastEvents} = events,
            eventsType = "";

        if (activeKey === "tab-upcomingEvents") {
            eventsType = "upcoming";
            !upcomingEvents.length && reqWebinarEvents({eventsType});
        } else if (activeKey === "tab-pastEvents") {
            eventsType = "past";
            !pastEvents.length && reqWebinarEvents({eventsType});
        }
    };

    render() {
        const { isWebinarPage } = this.state;

        const 
            PAGE_KEY = isWebinarPage ? "webinarList" : "meetingList",
            { error, userData, isLoading, events, reqWebinarEventDelete, reqMeetingDelete, reqWebinarRegistrants, reqWebinarAttendees, isLoadingUsers, registrants, attendance, fetchUsersFailed, refreshWebinarAttendance } = this.props,
            unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        const tabLabel = isWebinarPage ? "Webinars" : "Meetings";
        const type = isWebinarPage ? "Webinar" : "Meeting";
        const upcomingEvents = filter(events.upcoming, {type});
        const pastEvents = filter(events.past, {type});

        const isActionAccessbible = getIsPageActionAccessible(PAGE_KEY, userData.privileges);

    	return (
    		<div className="webinarEvents">
                <PageHeaderContainer pageKey={PAGE_KEY} />
    			<Tabs defaultActiveKey="tab-upcomingEvents" onTabClick={this.handleTabClick}>
					<TabPane tab={`Upcoming ${tabLabel}`} key="tab-upcomingEvents">
				        <ComponentWebinarEvents key={"componentWebinarEvents-upcomingEvents"} isLoading={isLoading} isUpcomingEvent={true} eventsList={upcomingEvents || []} reqEventDelete={isWebinarPage ? reqWebinarEventDelete : reqMeetingDelete} reqWebinarRegistrants={reqWebinarRegistrants} isLoadingUsers={isLoadingUsers} registrants={registrants} fetchUsersFailed={fetchUsersFailed} isActionAccessbible={isActionAccessbible} isWebinarPage={isWebinarPage} />
			      	</TabPane>
			      	<TabPane tab={`Past ${tabLabel}`} key="tab-pastEvents">
                        <ComponentWebinarEvents key={"componentWebinarEvents-pastEvents"} isLoading={isLoading} isUpcomingEvent={false} eventsList={pastEvents || []} reqEventDelete={isWebinarPage ? reqWebinarEventDelete : reqMeetingDelete} reqWebinarAttendees={reqWebinarAttendees} isLoadingUsers={isLoadingUsers} attendance={attendance} fetchUsersFailed={fetchUsersFailed} isActionAccessbible={isActionAccessbible} refreshWebinarAttendance={refreshWebinarAttendance} isWebinarPage={isWebinarPage} />
			      	</TabPane>
				</Tabs>
    		</div>
		);
    }
}

WebinarEvents.propTypes = {
    userData: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    reqWebinarEvents: PropTypes.func.isRequired,
    reqWebinarEventDelete: PropTypes.func.isRequired,
    reqMeetingDelete: PropTypes.func.isRequired,
    refreshWebinarAttendance: PropTypes.func.isRequired
};

export default WebinarEvents;
import { beAPIResource } from "../../utils/apiHelper";

const integrationApi = {
    getAll: () => beAPIResource.get("support/integrations/?showAllCrm=true"),
    getById: (id) => beAPIResource.get(`support/integrations/${id}`),
    add: (data) => beAPIResource.post("support/integrations/", data),
    update: (data) =>
        beAPIResource.put(`support/integrations/${data.id}`, data),
    delete: (id) => beAPIResource.delete(`support/integrations/${id}`),
    downloadCsvIntegration: () => beAPIResource.get(`support/integrations`)
};

export default integrationApi;

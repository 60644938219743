import { createActionTypes } from "../../../utils";

export const ProspectorActions = createActionTypes("prospector", [
  "FETCH_RECORDS_REQUEST",
  "FETCH_RECORDS_SUCCESS",
  "FETCH_RECORDS_FAILURE",
  "UPDATE_RECORD_COUNT",
  "FETCH_TARGET_PROFILE_REQUEST",
  "FETCH_TARGET_PROFILE_SUCCESS",
  "FETCH_TARGET_PROFILE_FAILURE",
  "FETCH_STATE_LIST_REQUEST",
  "FETCH_STATE_LIST_SUCCESS",
  "FETCH_CITY_LIST_REQUEST",
  "FETCH_CITY_LIST_SUCCESS",
  "FETCH_INDUSTRY_LIST_REQUEST",
  "FETCH_INDUSTRY_LIST_SUCCESS",
  "FETCH_OWNER_LIST_REQUEST",
  "FETCH_OWNER_LIST_SUCCESS",
  "UPDATE_FILTER",
  "FETCH_FORM_OPTION_FAILURE"
]);

/* records */
export const fetchRecordsRequest = (obj) => {
  return {
    type: ProspectorActions.FETCH_RECORDS_REQUEST,
    payload: obj
  };
};

export const fetchRecordsSucess = (obj) => {
  return {
    type: ProspectorActions.FETCH_RECORDS_SUCCESS,
    payload: obj
  };
};

export const fetchRecordsFailure = (obj) => {
  return {
    type: ProspectorActions.FETCH_RECORDS_FAILURE,
    payload: obj
  };
};

export const updateRecordCount = () => {
  return {
    type: ProspectorActions.UPDATE_RECORD_COUNT
  };
};

/* target profile */
export const fetchTargetProfileRequest = (obj) => {
  return {
    type: ProspectorActions.FETCH_TARGET_PROFILE_REQUEST,
    payload: obj
  };
};

export const fetchTargetProfileSuccess = (obj) => {
  return {
    type: ProspectorActions.FETCH_TARGET_PROFILE_SUCCESS,
    payload: obj
  };
};

export const fetchTargetProfileFailure = (obj) => {
  return {
    type: ProspectorActions.FETCH_TARGET_PROFILE_FAILURE,
    payload: obj
  };
};
/* state, industry, and owner list */
export const fetchStateListRequest = () => {
  return {
    type: ProspectorActions.FETCH_STATE_LIST_REQUEST
  };
};

export const fetchStateListSuccess = (obj) => {
  return {
    type: ProspectorActions.FETCH_STATE_LIST_SUCCESS,
    payload: obj
  };
};

export const fetchCityListRequest = () => {
  return {
    type: ProspectorActions.FETCH_CITY_LIST_REQUEST
  };
};

export const fetchCityListSuccess = (obj) => {
  return {
    type: ProspectorActions.FETCH_CITY_LIST_SUCCESS,
    payload: obj
  };
};

export const fetchIndustryListRequest = () => {
  return {
    type: ProspectorActions.FETCH_INDUSTRY_LIST_REQUEST
  };
};

export const fetchIndustryListSuccess = (obj) => {
  return {
    type: ProspectorActions.FETCH_INDUSTRY_LIST_SUCCESS,
    payload: obj
  };
};

export const fetchOwnerListRequest = () => {
  return {
    type: ProspectorActions.FETCH_OWNER_LIST_REQUEST
  };
};

export const fetchOwnerListSuccess = (obj) => {
  return {
    type: ProspectorActions.FETCH_OWNER_LIST_SUCCESS,
    payload: obj
  };
};

export const updateFilter = (obj) => {
  return {
    type: ProspectorActions.UPDATE_FILTER,
    payload: obj
  };
};

export const fetchFormOptionFailure = (obj) => {
  return {
    type: ProspectorActions.FETCH_FORM_OPTION_FAILURE,
    payload: obj
  };
};

export default ProspectorActions;

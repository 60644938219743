import React, { Component } from "react";
import PropTypes from "prop-types";
import lookup from "./integrations.json";
import { JsonEditor as Editor } from 'jsoneditor-react';
import { Form, Input, Switch, Icon, Select, Button, Upload, Divider, Checkbox, Radio} from "antd";
import { uploadUtility, getSlug } from "../../utils";
import { TextEditor } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";
import { Collapse } from 'antd';
import { isEmpty } from "lodash";

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;
const FormItem = Form.Item;
const dynamicDeleteButton = {
    cursor: "pointer",
    position: "relative",
    float: "right",
    top: "4px",
    fontSize: "20px",
    color: "#999",
    margin: "5px",
    transition: "all 0.3s",
    "&:hover": {
        color: "#777"
    },
    "&[disabled]": {
        cursor: "not-allowed",
        opacity: 0.5
      }
};

class MarketPlaceForm extends Component {

    constructor(props){
        super(props);
        this.featuresId = 0;
        this.stepsId = 0;
        this.overviewId = 0;
        this.prerequisitesId = 0;
        this.shareDataId = 0;
        this.isRendered =false;
    }

    handleAddMoreInBetween = (e, index) =>{
        const {form}  = this.props;
        const {name} = e.target;
        const insertIdx = ++index;
        switch(name) {
            case 'steps': {
                const steps = form.getFieldValue('connectionSteps');
                steps.splice(insertIdx, 0, this.stepsId++);
                this.props.form.setFieldsValue({connectionSteps: steps});
                break;
            }
            case 'features': {
                const features = form.getFieldValue('featuresSteps');
                features.splice(insertIdx, 0, this.featuresId++);
                this.props.form.setFieldsValue({featuresSteps: features});
                break;
            }
            case 'overview': {
                const overview = form.getFieldValue('assetsSteps');
                overview.splice(insertIdx, 0, this.overviewId++);
                this.props.form.setFieldsValue({assetsSteps: overview});
                break;
            }
            case 'prerequisites': {
                const prerequisite = form.getFieldValue('prerequisitesSteps');
                prerequisite.splice(insertIdx, 0, this.prerequisitesId++);
                this.props.form.setFieldsValue({prerequisitesSteps: prerequisite});
                break;
            }
        }
    }

    handleAddMore = (e) => {
        const {form}  = this.props;
        const {name} = e.target;
        switch(name) {
            case 'steps': {
                const steps = form.getFieldValue('connectionSteps');
                this.props.form.setFieldsValue({connectionSteps: steps.concat(this.stepsId++)});
                break;
            }
            case 'features': {
                const features = form.getFieldValue('featuresSteps');
                this.props.form.setFieldsValue({featuresSteps: features.concat(this.featuresId++)});
                break;
            }
            case 'overview': {
                const overview = form.getFieldValue('assetsSteps');
                this.props.form.setFieldsValue({assetsSteps: overview.concat(this.overviewId++)});
                break;
            }
            case 'prerequisites': {
                const steps = form.getFieldValue('prerequisitesSteps');
                this.props.form.setFieldsValue({prerequisitesSteps: steps.concat(this.prerequisitesId++)});
                break;
            }
            case 'shareData': {
                const steps = form.getFieldValue('shareDataSteps');
                this.props.form.setFieldsValue({shareDataSteps: steps.concat(this.shareDataId++)});
                break;
            }
        }
    }

    handleRemove = (e, removeEle) => {
        const {form}  = this.props; 
        const {name} = e.target;
        switch(name) {
            case 'steps': {
                const steps = form.getFieldValue('connectionSteps');
                const stepsIds= steps.filter((step) => step !== removeEle);
                this.props.form.setFieldsValue({connectionSteps: stepsIds});
                break;
            }
            case 'features': {
                const features = form.getFieldValue('featuresSteps');
                const featuresIds= features.filter((feature) => feature !== removeEle);
                this.props.form.setFieldsValue({featuresSteps: featuresIds});
                break;
            }
            case 'overview': {
                const overview = form.getFieldValue('assetsSteps');
                const overviewIds= overview.filter((ele) => ele !== removeEle);
                this.props.form.setFieldsValue({assetsSteps: overviewIds});
                break;
            }
            case 'prerequisites': {
                const prerequisites = form.getFieldValue('prerequisitesSteps');
                const prerequisitesIds= prerequisites.filter((ele) => ele !== removeEle);
                this.props.form.setFieldsValue({prerequisitesSteps: prerequisitesIds});
                break;
            }
            case 'shareData': {
                const shareData = form.getFieldValue('shareDataSteps');
                const shareDataIds= shareData.filter((ele) => ele !== removeEle);
                this.props.form.setFieldsValue({shareDataSteps: shareDataIds});
                break;
            }
        }
    }

    handleOverviewDescription = (html, name) => {
        this.props.form.setFieldsValue({description: html})
    }

    handleConfigurationsJSON = (data = {}) => {
        this.props.form.setFieldsValue({configurations: data})
    }

    handleMarketPlaceFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((error, values) => {
            if(!error) {
                const { name="", logoUrl="", isMarketPlaceEnable, assets = [{type: "", thumbnailUrl: "", url: "", hasBEBranding: true}], description="", integrationFeatures = [{ icon: "", heading: "", description: ""}], shareData= [{ integrationFeature: "", dataType: "", mode: "", integrationField: "", beField: ""}], prerequisites = [{heading: "", description: "", label: "", href: "" }], steps = [{heading: "", description: "", label: "", href: "" }], isRecommended, industry = [], shortDescription ="", categories = [], assetsSteps = [], prerequisitesSteps = [], connectionSteps = [], featuresSteps = [], shareDataSteps = [], setupGuideDirectLabel, setupGuideDirectHref, setupGuidePartnerLabel, setupGuidePartnerHref, configurations = {}} = values;
                const finalUrl =  Array.isArray(logoUrl) ? logoUrl[0].url : "";
                const assetsData =  assetsSteps.map(ele => assets[ele]).map((ele) => {
                    const {type="", url="", thumbnailUrl="", hasBEBranding=true } = ele || {};
                    const result = {
                        type: type || "",
                        url: Array.isArray(url) ? url[0].url : "",
                        hasBEBranding: hasBEBranding
                    }
                    if(type === 'video') {
                        result.thumbnailUrl = Array.isArray(thumbnailUrl) ? thumbnailUrl[0].url : "";
                    }
                    return result;
                });
                const prerequisiteData = prerequisitesSteps.reduce((prerequisitesValues, ele) => {
                    const {heading="", description="", directLabel="", directHref="", partnerLabel="", partnerHref="" } = prerequisites[ele] || {};
                    if(!isEmpty(heading)) {
                        prerequisitesValues.push({heading, description, link: {direct: {label: directLabel, href: directHref}, partner: {label: partnerLabel, href: partnerHref }}});
                    }
                    return prerequisitesValues;
                }, []);

                const stepsData = connectionSteps.map((ele) => {
                    const {heading="", description="", directLabel="", directHref="", partnerLabel="", partnerHref = ""} = steps[ele] || {};
                    return {heading, description, link: { direct: {label: directLabel, href: directHref}, partner: { label: partnerLabel, href: partnerHref }}};
                });
                const connection = {prerequisites: prerequisiteData, steps: stepsData};
                const integrationFeaturesData = featuresSteps.map((ele) => integrationFeatures[ele]);

                const integrationShareData = shareDataSteps.reduce((shareDataValues, ele) => {
                    const { integrationFeature = "", dataType= "Appointment", mode= "twoWay", integrationField= "", beField= "" } = shareData[ele] || {};
                    if(integrationFeature !== ''){
                        shareDataValues.push({integrationFeature, dataType, mode, integrationField, beField});
                    }

                    return shareDataValues;
                }, []);
                
                const features = {integrationFeatures: integrationFeaturesData, shareData: integrationShareData};
                const setupGuide = {direct: {label: setupGuideDirectLabel, href: setupGuideDirectHref}, partner: {label: setupGuidePartnerLabel, href: setupGuidePartnerHref}};
                const overview = {description, shortDescription, isRecommended, industry, categories, assets: assetsData, setupGuide};
                const configurationsData = configurations || {};
                const marketplace = isMarketPlaceEnable ? {name, logoUrl: finalUrl, features, overview, connection, configurations: configurationsData} : {};

                this.props.handleMarketPlaceSubmission(marketplace, isMarketPlaceEnable);
            }
        });
    }

    featureShareDataComponent = (shareData) => {
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { shareDataSteps = [], featuresSteps = [] } = getFieldsValue();
        const featureSize = featuresSteps.length;
        const integrationFeatureMapping = [{label:"Feature Step 1", value: 0}];
        for(let i=1; i<featureSize; i++){
            integrationFeatureMapping.push({label:`Feature Step ${i+1}`, value: i})
        }

        return shareDataSteps && shareDataSteps.map((shareDataStep, index) => {
                const {
                    integrationFeature = "",
                    dataType= "Appointment",
                    mode= "twoWay",
                    integrationField= "",
                    beField= ""    
                } = shareData.hasOwnProperty(shareDataStep) ? shareData[shareDataStep] || {}: 
                {
                    integrationFeature: "",
                    dataType: "Appointment",
                    mode: "twoWay",
                    integrationField: "",
                    beField: ""    
                };
                const integrationMapping = integrationFeature < featureSize ? integrationFeature  : 0;
                const shareDataValue = this.props.form.getFieldValue(`shareData[${shareDataStep}][integrationFeature]`);
                return (
                    <>
                        <FormItem {...(index === 0 ? formItemLayout : formButtonLayout)} label={index === 0 ? "Share data" : ""} key={`header-${index}`} extra="Feature step is a required field, without it this shared data will not be considered for publishing">
                            {getFieldDecorator(`shareData[${shareDataStep}][integrationFeature]`, {
                                initialValue: integrationFeature !== "" ? integrationMapping : ""
                                })(
                                <Select showSearch allowClear={shareDataValue >= 0 } placeholder="Select integration feature step.">
                                    {integrationFeatureMapping.map(({label, value}) => (
                                        <Option
                                            key={label}
                                            value={value} >
                                            {label}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </FormItem>
                        <FormItem {...formButtonLayout}>
                            {getFieldDecorator(`shareData[${shareDataStep}][dataType]`, {
                                rules: [{ "required": true, "message": 'Please provide dataType.' }],
                                initialValue: dataType
                                })(
                                <Select showSearch placeholder="Select share data type">
                                    {lookup.value.shareDataType.map(({label, value}) => (
                                        <Option
                                            key={label}
                                            value={value} >
                                            {value}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </FormItem>
                        <FormItem {...formButtonLayout}>
                            {getFieldDecorator(`shareData[${shareDataStep}][mode]`, {
                                rules: [{ "required": true, "message": 'Please provide mode.' }],
                                initialValue: mode
                                })(
                                <Radio.Group options={lookup.value.shareDataMode} />
                            )}
                        </FormItem> 
                        <FormItem {...formButtonLayout}>
                            {getFieldDecorator(`shareData[${shareDataStep}][integrationField]`, {
                                initialValue: integrationField
                                })(
                                <Input placeholder="Enter integration field"/>
                            )}
                        </FormItem>
                        <FormItem {...formButtonLayout}>
                            {getFieldDecorator(`shareData[${shareDataStep}][beField]`, {
                                initialValue: beField
                                })(
                                <Input placeholder="Enter Birdeye field"/>
                            )}
                        </FormItem> 
                        <FormItem {...formButtonLayout}>
                            <Button type="link" style={dynamicDeleteButton} name="shareData" onClick={(e) => this.handleRemove(e, shareDataStep)}>
                                <Icon type="minus-circle-o" />
                            </Button>
                            <Divider/>
                        </FormItem>
                    </>
                )
        })
    }

    render() {
        const {form, isMarketPlaceEnable, initialData} = this.props;
        const { getFieldDecorator, getFieldsValue } = form;
        const { name="", logoUrl="", overview = {}, features = {integrationFeatures: [{ icon: "", heading: "", description: "", shareData: []}]}, connection = {}, configurations = {} } = initialData || {};
        const {
            assets= [{type: "", thumbnailUrl: "", url: "", hasBEBranding: true}],
            isRecommended= false,
            industry= [], 
            shortDescription= "",
            categories= [],
            description= "",
            setupGuide= {
                direct: {
                    label: "",
                    href: ""
                },
                partner: {
                    label: "",
                    href: ""
                }
            }
        } = overview;
        const {
            integrationFeatures = [{ 
                icon: "", 
                heading: "", 
                description: "", 
                
            }],
            shareData = []
        } = features;
        const { 
            prerequisites= [], 
            steps= [{
                heading: "", 
                description: "", 
                link: {
                    direct: {
                        label: "",
                        href: ""
                    },
                    partner: {
                        label: "",
                        href: ""
                    }
                } 
            }]
        } = connection;
        const marketPlaceName = form.getFieldValue('name') || name;
        if(!this.isRendered){
            this.featuresId = integrationFeatures.length;
            this.stepsId = steps.length;
            this.prerequisitesId = prerequisites.length;
            this.overviewId = assets.length;
            this.shareDataId = shareData.length;
            this.isRendered = true;
        }

        getFieldDecorator("featuresSteps", { initialValue: integrationFeatures.map((ele, idx) => this.featuresId && idx )});
        getFieldDecorator("shareDataSteps", { initialValue: shareData.map((ele, idx) => this.shareDataId && idx )});
        getFieldDecorator("connectionSteps", { initialValue: steps.map((ele, idx) => this.stepsId && idx )});
        getFieldDecorator("prerequisitesSteps", { initialValue: prerequisites.map((ele, idx) => this.prerequisitesId && idx )});
        getFieldDecorator("assetsSteps", { initialValue: assets.map((ele, idx) => this.overviewId && idx)});

        

        const { featuresSteps, prerequisitesSteps, connectionSteps, assetsSteps} = getFieldsValue();

        return (
            <Form onSubmit={this.handleMarketPlaceFormSubmit}>
                <FormItem {...formItemLayout} label="Marketplace Enable">
                    {getFieldDecorator('isMarketPlaceEnable', {
                        initialValue: isMarketPlaceEnable
                        })(
                        <Switch
                            name="isMarketPlaceEnable"
                            checkedChildren={<Icon type="check"  />}
                            unCheckedChildren={<Icon type="close" />}
                            defaultChecked={isMarketPlaceEnable}
                        />
                    )}
                </FormItem>
                {
                    this.props.form.getFieldValue("isMarketPlaceEnable") && <> 
                        <Form.Item {...formItemLayout} label="Name">
                            {getFieldDecorator('name', {
                                rules: [{ "required": true, "message": 'Please provide name.' }],
                                initialValue: name
                                })(
                                <Input placeholder="Enter name" />
                            )}
                        </Form.Item>
                        <FormItem {...formItemLayout} label="Logo">
                            {
                                getFieldDecorator("logoUrl", {
                                    rules: [
                                    { "required": true, "message": 'Please upload logo.'},
                                    {
                                        validator: uploadUtility.singleFile.validator,
                                    }],
                                        getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                        valuePropName: "fileList",
                                        initialValue: logoUrl ? [
                                            {
                                                uid: '-1',
                                                name: 'Upload preview',
                                                status: 'done',
                                                url: logoUrl
                                            }
                                        ] : ''
                                })(
                                    <Upload
                                        name="files"
                                        accept={"image/*"}
                                        action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=logo-url-${marketPlaceName ? getSlug(marketPlaceName) : ''}-${Date.now()}`}
                                        withCredentials
                                    >
                                        <Button icon={"upload"} disabled={!marketPlaceName}>Upload</Button>
                                    </Upload>
                                )
                            }
                        </FormItem>
                        <Divider orientation="left">Overview</Divider>
                        <Collapse accordion>
                            <Panel header="Overview Assets" >
                            {
                                assetsSteps && assetsSteps.map((asset, index) => {
                                    const { type="", thumbnailUrl="", url="", hasBEBranding= true } = assets.hasOwnProperty(asset) ? assets[asset] || {} : { type: "", thumbnailUrl: "", url: "", hasBEBranding: true };
                                    const getUploadType = this.props.form.getFieldValue(`assets[${asset}][type]`) || type;
                                    return (
                                        <FormItem {...(index === 0 ? formItemLayout : formButtonLayout)} label={index === 0 ? "Overview" : ""} key={index} >
                                                <FormItem name = {`assets[${asset}][type]`} {...formButtonLayout} style={{ display: 'inline-flex', width:'33%'}}>
                                                    {getFieldDecorator(`assets[${asset}][type]`, {
                                                        rules: [{ "required": true, "message": 'Please provide asset type.' }],
                                                        initialValue: type || "image"
                                                        })(
                                                        <Select placeholder="Select asset type" style={{ marginLeft: '-45px', width:"130px"}}>
                                                            {
                                                                lookup.value.overviewAssets.map(d => <Option key={d.value} >{d.label}</Option>)
                                                            }
                                                        </Select>
                                                    )}
                                                </FormItem>
                                                {   getUploadType ==='video' && <FormItem name={`assets[${asset}][thumbnailUrl]`} {...formButtonLayout} style={{ display: 'inline-flex', width:'33%'}}>
                                                        {getFieldDecorator(`assets[${asset}][thumbnailUrl]`, {
                                                            rules: [
                                                            { "required": true, "message": 'Please upload thumbnail'},
                                                            {
                                                                validator: uploadUtility.singleFile.validator,
                                                            }],
                                                            getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                                            initialValue: typeof thumbnailUrl === 'string' && thumbnailUrl ? [
                                                                {
                                                                    uid: '-1',
                                                                    name: "Upload preview",
                                                                    status: 'done',
                                                                    url: thumbnailUrl,
                                                                }
                                                            ] : '',
                                                            valuePropName: "fileList",
                                                            })(
                                                            <Upload
                                                                name="files"
                                                                accept={"image/*"}
                                                                action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=thumbnail-${asset}-url-${marketPlaceName ? getSlug(marketPlaceName) : ''}-${Date.now()}`}
                                                                withCredentials
                                                            >
                                                                <Button icon={"upload"} disabled={!marketPlaceName}>Thumbnail Upload</Button>
                                                            </Upload>
                                                        )}
                                                    </FormItem>
                                                } 
                                                <FormItem {...formButtonLayout} name={`assets[${asset}][url]`} style={{ display: 'inline-flex', width:'33%'}}>
                                                    {getFieldDecorator(`assets[${asset}][url]`, {
                                                        rules: [
                                                        { "required": true, "message": 'Please upload asset'},
                                                        {
                                                            validator: uploadUtility.singleFile.validator,
                                                        }],
                                                        getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                                        initialValue: typeof url === 'string'  && url? [{
                                                            uid: '-1',
                                                            name: 'Upload preview',
                                                            status: 'done',
                                                            url: url,
                                                        }] : '',    
                                                        valuePropName: "fileList",
                                                        })(
                                                        <Upload
                                                            name="files"
                                                            accept={getUploadType === 'video' ? "video/*" : "image/*" }
                                                            action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=assets-${asset}-url-${marketPlaceName ? getSlug(marketPlaceName) : ''}-${Date.now()}`}
                                                            withCredentials
                                                        >
                                                            <Button icon={"upload"} style={{width: '170px'}} disabled={!marketPlaceName}>Upload</Button>
                                                        </Upload>
                                                    )}
                                                </FormItem>
                                                <FormItem  name={`assets[${asset}][hasBEBranding]`}>
                                                    {
                                                        getFieldDecorator(`assets[${asset}][hasBEBranding]`, {
                                                            initialValue: hasBEBranding,
                                                            valuePropName: "checked"
                                                        })(
                                                            <Checkbox>BE Branding</Checkbox>
                                                        )
                                                    }
                                                </FormItem>
                                                {   assetsSteps.length > 1 &&
                                                        <>
                                                            <Button type="link" style={dynamicDeleteButton} name="overview" onClick={(e) => this.handleRemove(e, asset)}>
                                                                <Icon type="minus-circle-o" />
                                                            </Button>
                                                            <Button type="link" name="overview" style={dynamicDeleteButton} onClick={(e) => this.handleAddMoreInBetween(e, index)}>
                                                                <Icon type="plus-circle" />
                                                            </Button>
                                                        </>
                                                }
                                                <Divider />
                                        </FormItem>
                                    )
                                })
                            }
                                <Form.Item {...formButtonLayout} >
                                    <Button type="dashed" name="overview" onClick={this.handleAddMore} style={{ width: '30%' }}>
                                        <Icon type="plus" /> More uploads
                                    </Button>
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Short Description">
                                    {getFieldDecorator('shortDescription', {
                                        rules: [{ "required": true, "message": 'Please provide short description.' }],
                                        initialValue: shortDescription
                                        })(
                                            <TextArea placeholder="Enter short description" rows={4} cols={7} />
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Long Description">
                                    {getFieldDecorator('description', {
                                        rules: [{ "required": true, "message": 'Please provide long description.' }],
                                        initialValue: description
                                        })(
                                            <TextEditor placeholder="Enter long description" name="description" handleEditorChange={this.handleOverviewDescription} advanced />
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label={"Recommended"} >
                                    {getFieldDecorator('isRecommended', {
                                        initialValue: isRecommended,
                                        valuePropName: "checked"
                                    })(
                                        <Checkbox />
                                    )}
                                </Form.Item>
                                <FormItem {...formItemLayout} label="Industry">
                                    {getFieldDecorator('industry', {
                                        rules: [{ "required": true, "message": 'Please select industry.' }],
                                        initialValue: industry
                                        })(
                                        <Select
                                            mode="multiple"
                                            placeholder="Select industry"
                                        >
                                            {
                                                lookup.value.industries.map((ele) => <Option key={ele.value}>{ele.label}</Option>)
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                                <Form.Item {...formItemLayout} label="Category">
                                    {   getFieldDecorator('categories', {
                                            rules: [{ "required": true, "message": 'Please select category.' }],
                                            initialValue: categories
                                            })(    
                                        <Select
                                            mode="multiple"
                                            placeholder="Select category"
                                        >
                                            {
                                                lookup.value.categoriesForMarketPlace.map((ele) => <Option key={ele.label}>{ele.label}</Option>)
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                                <FormItem  {...formItemLayout} label="Direct">
                                    <Form.Item style={{ display: 'inline-flex', width:'50%'}}>
                                        {getFieldDecorator("setupGuideDirectLabel", {
                                            initialValue: setupGuide.direct.label || ""
                                            })(
                                            <Input placeholder="Enter direct setup guide label" style={{width:'320px'}}/>
                                        )}
                                    </Form.Item>
                                    <Form.Item  style={{ display: 'inline-flex', width:'50%'}}>
                                        {getFieldDecorator("setupGuideDirectHref", {
                                            rules: [{
                                                type: "url",
                                                message: "Please provide a valid link."
                                            }],
                                            initialValue: setupGuide.direct.href || ""
                                            })(
                                            <Input placeholder="Enter direct setup guide link" style={{width:'340px'}} />
                                        )}
                                    </Form.Item>
                                </FormItem>
                                <FormItem  {...formItemLayout} label="Partner">
                                    <Form.Item style={{ display: 'inline-flex', width:'50%'}}>
                                        {getFieldDecorator("setupGuidePartnerLabel", {
                                            initialValue: setupGuide.partner.label || ""
                                            })(
                                            <Input placeholder="Enter partner setup guide label" style={{width:'320px'}}/>
                                        )}
                                    </Form.Item>
                                    <Form.Item  style={{ display: 'inline-flex', width:'50%'}}>
                                        {getFieldDecorator("setupGuidePartnerHref", {
                                            rules: [{
                                                type: "url",
                                                message: "Please provide a valid link."
                                            }],
                                            initialValue: setupGuide.partner.href || ""
                                            })(
                                            <Input placeholder="Enter partner setup guide link" style={{width:'340px'}} />
                                        )}
                                    </Form.Item>
                                </FormItem>
                            </Panel>
                        </Collapse>
                        <Divider orientation="left">Integration Features</Divider>
                        <Collapse accordion>
                            <Panel header="Features">
                            {
                                featuresSteps && featuresSteps.map((feature, index) => {
                                    const { icon="", heading="", description="" } = integrationFeatures.hasOwnProperty(feature) ? integrationFeatures[feature] || {} : { icon: "", heading: "", description: "" };
                                    return (
                                        <>
                                            <FormItem {...(index === 0 ? formItemLayout : formButtonLayout)} label={index === 0 ? "Features" : ""} key={`header-${index}`} >
                                                {getFieldDecorator(`integrationFeatures[${feature}][icon]`, {
                                                    rules: [{ "required": true, "message": 'Please provide integration features icon.' }],
                                                    initialValue: icon || "inbox-star"
                                                    })(
                                                    <Select placeholder="Select feature icon">
                                                        {
                                                            lookup.value.icons.map(d => <Option key={d.value}>{d.label}</Option>)
                                                        }
                                                    </Select>
                                                )}
                                            </FormItem>
                                            <FormItem {...formButtonLayout}>
                                                {getFieldDecorator(`integrationFeatures[${feature}][heading]`, {
                                                    rules: [{ "required": true, "message": 'Please provide integration feature heading.' }],
                                                    initialValue: heading
                                                    })(
                                                    <Input placeholder="Enter heading"/>
                                                )}
                                            </FormItem>
                                            <FormItem {...formButtonLayout}>
                                                {getFieldDecorator(`integrationFeatures[${feature}][description]`, {
                                                    rules: [{ "required": true, "message": 'Please provide integration feature description.' }],
                                                    initialValue: description
                                                    })(
                                                        <TextArea placeholder="Enter description" rows={4} cols={7} />
                                                )}
                                            </FormItem>
                                            <FormItem {...formButtonLayout}>
                                                {   featuresSteps.length > 1 && 
                                                    <>
                                                        <Button type="link" style={dynamicDeleteButton} name="features" onClick={(e) => this.handleRemove(e, feature)}>
                                                            <Icon type="minus-circle-o" />
                                                        </Button>
                                                        <Button type="link" name="features" style={dynamicDeleteButton} onClick={(e) => this.handleAddMoreInBetween(e, index)}>
                                                            <Icon type="plus-circle" />
                                                        </Button>
                                                    </>
                                                }
                                                <Divider />
                                            </FormItem>
                                        </>
                                    )
                                })
                            }
                            <Form.Item {...formButtonLayout}>
                                <Button type="dashed" name="features" onClick={this.handleAddMore} style={{ width: '30%' }}>
                                    <Icon type="plus" /> Add more features
                                </Button>
                            </Form.Item>
                            <Divider />
                            {
                                this.featureShareDataComponent(shareData)
                            }
                            <FormItem {...formButtonLayout} >
                                <Button type="dashed" name="shareData" onClick={this.handleAddMore} >
                                    <Icon type="plus" /> Add share data
                                </Button>
                            </FormItem>
                            </Panel>
                        </Collapse>
                        <Divider orientation="left">'How to Connect' steps </Divider>
                        <Collapse accordion>
                            <Panel header="Connections steps">
                                {
                                    prerequisitesSteps && prerequisitesSteps.map((prerequisite, index) => {
                                        const { heading, description, link = { direct: {label: "", href: ""}, partner: {label: "", href: ""} }
                                        } = prerequisites.hasOwnProperty(prerequisite) ? prerequisites[prerequisite] || {} : {heading: "", description: "", link: { direct: {label: "", href: ""}, partner: {label: "", href: ""} } };
                                        return (
                                            <>
                                                <FormItem {...(index === 0 ? formItemLayout : formButtonLayout)} label={index === 0 ? "Prerequisites" : ""} key={`header-${index}`} extra="Heading is a required field, without it this prerequisite will not be considered for publishing">
                                                    {getFieldDecorator(`prerequisites[${prerequisite}][heading]`, {
                                                        initialValue: heading
                                                        })(
                                                            <Input placeholder="Enter heading"/>
                                                    )}
                                                </FormItem>
                                                <FormItem {...formButtonLayout}>
                                                    {getFieldDecorator(`prerequisites[${prerequisite}][description]`, {
                                                        initialValue: description
                                                        })(
                                                            <TextArea placeholder="Enter description" rows={4} cols={7} />
                                                    )}
                                                </FormItem>
                                                <FormItem {...formButtonLayout}>
                                                    <FormItem style={{ display: 'inline-flex', width:'50%'}}>
                                                            {getFieldDecorator(`prerequisites[${prerequisite}][directLabel]`, {
                                                                initialValue: link.direct.label || ""
                                                                })(
                                                                <Input placeholder="Enter direct link label" style={{width:'320px'}}/>
                                                            )}
                                                    </FormItem>
                                                    <FormItem  style={{ display: 'inline-flex', width:'50%'}}>
                                                            {getFieldDecorator(`prerequisites[${prerequisite}][directHref]`, {
                                                                rules: [{
                                                                    type: "url",
                                                                    message: "Please provide a valid link."
                                                                }],
                                                                initialValue: link.direct.href || ""
                                                                })(
                                                                    <Input placeholder="Enter direct link URL" style={{width:'340px'}} />
                                                            )}
                                                    </FormItem>
                                                    <FormItem style={{ display: 'inline-flex', width:'50%'}}>
                                                            {getFieldDecorator(`prerequisites[${prerequisite}][partnerLabel]`, {
                                                                initialValue: link.partner.label || ""
                                                                })(
                                                                <Input placeholder="Enter partner link label" style={{width:'320px'}}/>
                                                            )}
                                                    </FormItem>
                                                    <FormItem  style={{ display: 'inline-flex', width:'50%'}}>
                                                            {getFieldDecorator(`prerequisites[${prerequisite}][partnerHref]`, {
                                                                rules: [{
                                                                    type: "url",
                                                                    message: "Please provide a valid link."
                                                                }],
                                                                initialValue: link.partner.href || ""
                                                                })(
                                                                    <Input placeholder="Enter partner link URL" style={{width:'340px'}} />
                                                            )}
                                                    </FormItem>
                                                    <>
                                                        <Button type="link" style={dynamicDeleteButton} name="prerequisites" onClick={(e) => this.handleRemove(e, prerequisite)}>
                                                            <Icon type="minus-circle-o" />
                                                        </Button>
                                                        <Button type="link" name="prerequisites" style={dynamicDeleteButton} onClick={(e) => this.handleAddMoreInBetween(e, index)}>
                                                            <Icon type="plus-circle" />
                                                        </Button>
                                                    </>
                                                    <Divider />
                                                </FormItem>   
                                            </>
                                        )
                                    })
                                }
                                <Form.Item {...formButtonLayout}>
                                    <Button type="dashed" name="prerequisites" onClick={this.handleAddMore} style={{ width: '30%' }}>
                                        <Icon type="plus" /> Add prerequisites
                                    </Button>
                                </Form.Item>
                                <Divider />
                                {
                                    connectionSteps && connectionSteps.map((connectionStep, index) => {
                                        const { heading, description, link = { direct: {label: "", href: ""}, partner: {label: "", href: ""} } } = steps.hasOwnProperty(connectionStep) ? steps[connectionStep] || {} : {heading: "", description: "", link: { direct: {label: "", href: ""}, partner: {label: "", href: ""} }};
                                        return (
                                            <>
                                                <FormItem {...(index === 0 ? formItemLayout : formButtonLayout)} label={index === 0 ? "Steps" : ""} key={`header-${index}`} >
                                                    {getFieldDecorator(`steps[${connectionStep}][heading]`, {
                                                        rules: [{ "required": true, "message": 'Please provide connection steps heading.' }],
                                                        initialValue: heading
                                                        })(
                                                        <Input placeholder="Enter heading"/>
                                                    )}
                                                </FormItem>
                                                <FormItem {...formButtonLayout}>
                                                    {getFieldDecorator(`steps[${connectionStep}][description]`, {
                                                        rules: [{ "required": true, "message": 'Please provide connection steps description.' }],
                                                        initialValue: description
                                                        })(
                                                        <TextArea placeholder="Enter description" rows={4} cols={7} />
                                                    )}
                                                </FormItem>
                                                <FormItem  {...formButtonLayout} >
                                                    <FormItem style={{ display: 'inline-flex', width:'50%'}} >
                                                        {getFieldDecorator(`steps[${connectionStep}][directLabel]`, {
                                                            initialValue: link.direct.label || ""
                                                            })(
                                                                <Input placeholder="Enter direct link label" style={{width:'320px'}}/>
                                                        )}
                                                    </FormItem>
                                                    <FormItem style={{ display: 'inline-flex', width:'50%'}} >
                                                        {getFieldDecorator(`steps[${connectionStep}][directHref]`, {
                                                            rules: [{
                                                                type: "url",
                                                                message: "Please provide a valid link."
                                                            }],
                                                            initialValue: link.direct.href || ""
                                                            })(
                                                                <Input placeholder="Enter direct link URL" style={{width:'340px'}} />
                                                        )}
                                                    </FormItem>
                                                    <FormItem style={{ display: 'inline-flex', width:'50%'}} >
                                                        {getFieldDecorator(`steps[${connectionStep}][partnerLabel]`, {
                                                            initialValue: link.partner.label || ""
                                                            })(
                                                                <Input placeholder="Enter partner link label" style={{width:'320px'}}/>
                                                        )}
                                                    </FormItem>
                                                    <FormItem style={{ display: 'inline-flex', width:'50%'}} >
                                                        {getFieldDecorator(`steps[${connectionStep}][partnerHref]`, {
                                                            rules: [{
                                                                type: "url",
                                                                message: "Please provide a valid link."
                                                            }],
                                                            initialValue: link.partner.href || ""
                                                            })(
                                                                <Input placeholder="Enter partner link URL" style={{width:'340px'}} />
                                                        )}
                                                    </FormItem>
                                                    { connectionSteps.length > 1 &&
                                                            <>
                                                                <Button type="link" name="steps" style={dynamicDeleteButton} onClick={(e) => this.handleRemove(e, connectionStep)}>
                                                                    <Icon type="minus-circle" />
                                                                </Button>
                                                                <Button type="link" name="steps" style={dynamicDeleteButton} onClick={(e) => this.handleAddMoreInBetween(e, index)}>
                                                                    <Icon type="plus-circle" />
                                                                </Button>
                                                            </>
                                                    }
                                                    <Divider />
                                                </FormItem>
                                            </>
                                        )
                                    })
                                }
                                <Form.Item {...formButtonLayout} >
                                    <Button type="dashed" name="steps" onClick={this.handleAddMore} style={{ width: '30%' }}>
                                        <Icon type="plus" /> Add more steps
                                    </Button>
                                </Form.Item>
                            </Panel>
                        </Collapse>
                        <Divider orientation="left">Configurations steps </Divider>
                        <Collapse>
                                <Panel header="Configurations">
                                    <FormItem {...formItemLayout} label={'Configurations'} extra = "Note: Only JSON format supported">
                                        {
                                            getFieldDecorator("configurations" , {
                                                initialValue: configurations
                                            })(<Editor
                                                allowedModes={['text', 'tree']}
                                                mode={'tree'}
                                                onChange={this.handleConfigurationsJSON}
                                            />)
                                        }
                                    </FormItem>
                                </Panel>
                        </Collapse>
                    </>

                }
                <FormItem {...formButtonLayout} style={{marginTop: "10px"}}>
                    <Button type={"primary"} htmlType={"submit"}>
                        Next <Icon type="right" />
                    </Button>
                </FormItem>
            </Form>
        )
    }
}

const MarketPlaceIntegration = Form.create()(MarketPlaceForm);

MarketPlaceIntegration.propTypes = {
    initialData: PropTypes.object.isRequired,
    handleMarketPlaceSubmission: PropTypes.func.isRequired
};

export default MarketPlaceIntegration;
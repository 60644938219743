import React, { useEffect, useState } from "react";
import { TableSkeleton } from "../../components";
import moment from "moment";
import { Button, DatePicker } from 'antd';
import "./Report.scss";
import { beAPIResource } from "../../utils/apiHelper";
import { Redirect } from "react-router-dom";

const ReportPage = (props) => {

    const [ loading, setLoading ] = useState(false);
    const [ dates, setDates ] = useState({
        startDate: moment(new Date()).subtract(24, 'hours'),
        endDate: moment(new Date())
    });
    const [report, setReport] = useState([]);
    const [ pageData, setpageData ] = useState(report);

    useEffect(() => {
        (
            async () => {
                let { startDate, endDate } = dates;
                startDate = dates.startDate.format('YYYY-MM-DD');
                endDate = dates.endDate.format('YYYY-MM-DD');
                const {data: report} = await beAPIResource.get(`/support/leadgen-management/get-report?start=${startDate}&end=${endDate}`);

                setReport(report);
                setpageData(report);
            }
        )()
    }, []);

    const mailgunResponseChildren = () => {
        return [
            {
                "title": 'Email Address',
                "width": 150,
                "render": (value) => {
                    const { exceptionMessage } = value;
                    if(exceptionMessage.toLowerCase().includes('mailgun')) {
                        let address = exceptionMessage;
                        const regex = /address=([^,]+)/;
                        const match = address.match(regex);
                        if(match) {
                            address = match[1];
                        }
                        return address;
                    }
                }
            },
            {
                "title": 'Suggested Email Address',
                "width": 150,
                "render": (value) => {
                    const { exceptionMessage } = value;
                    if(exceptionMessage.toLowerCase().includes('mailgun')) {
                        let didYouMean = exceptionMessage;
                        const regex = /didYouMean=([^,]+)/;
                        const match = didYouMean.match(regex);
                        if(match) {
                            didYouMean = match[1];
                        }
                        return didYouMean;
                    }
                }
            },
            {
                "title": 'Disposable Address',
                "width": 150,
                "render": (value) => {
                    const { exceptionMessage } = value;
                    if(exceptionMessage.toLowerCase().includes('mailgun')) {
                        let disposableAddress = exceptionMessage;
                        const regex = /disposableAddress=([^,]+)/;
                        const match = disposableAddress.match(regex);
                        if(match) {
                            disposableAddress = match[1];
                        }
                        return disposableAddress;
                    }
                }
            },
            {
                "title": 'Role Address',
                "width": 150,
                "render": (value) => {
                    const { exceptionMessage } = value;
                    if(exceptionMessage.toLowerCase().includes('mailgun')) {
                        let roleAddress = exceptionMessage;
                        const regex = /roleAddress=([^,]+)/;
                        const match = roleAddress.match(regex);
                        if(match) {
                            roleAddress = match[1];
                        }
                        return roleAddress;
                    }
                }
            },
            {
              "title": 'Reason',
              "width": 150,
              "render": (value) => {
                const { exceptionMessage } = value;
                if(exceptionMessage.toLowerCase().includes('mailgun')) {
                    let reason = exceptionMessage;
                    const regex = /reason=\[([^[\]]*)\]/;
                    const match = reason.match(regex);
                    if(match) {
                        reason = match[1];
                    }
                    return reason.replace(/_/g, ' ');
                }
              },
            },
            {
                "title": 'Result',
                "width": 150,
                "render": (value) => {
                    const { exceptionMessage } = value;
                    if(exceptionMessage.toLowerCase().includes('mailgun')) {
                        let result = exceptionMessage;
                        const regex = /result=([^,]+)/;
                        const match = result.match(regex);
                        if(match) {
                            result = match[1];
                        }
                        return result;
                    }
                }
            },
            {
                "title": 'Risk',
                "width": 150,
                "render": (value) => {
                    const { exceptionMessage } = value;
                    if(exceptionMessage.toLowerCase().includes('mailgun')) {
                        let risk = exceptionMessage;
                        const regex = /risk=([^,]+)/;
                        const match = risk.match(regex);
                        if(match) {
                            risk = match[1];
                        }
                        return risk;
                    }
                }
            },
            {
                "title": 'Root Address',
                "width": 150,
                "render": (value) => {
                    const { exceptionMessage } = value;
                    if(exceptionMessage.toLowerCase().includes('mailgun')) {
                        let rootAddress = exceptionMessage;
                        const regex = /rootAddress=([^>]+)>/;
                        const match = rootAddress.match(regex);
                        if(match) {
                            rootAddress = match[1];
                        }
                        return rootAddress;
                    }
                }
            }
          ]
    }

    const phoneNumberResponseChildren = () => {
        return [
            {
                "title": 'Raw Phone Number',
                "width": 150,
                "render": (value) => {
                    const { exceptionMessage } = value;
                    if(exceptionMessage.toLowerCase().includes('phone')) {
                        let rawPhoneNumber = exceptionMessage;
                        const regex = /rawPhoneNumber=([^,]+)/;
                        const match = rawPhoneNumber.match(regex);
                        if(match) {
                            rawPhoneNumber = match[1];
                        }
                        return rawPhoneNumber;
                    }
                }
            },
            {
                "title": 'Country Code',
                "width": 150,
                "render": (value) => {
                    const { exceptionMessage } = value;
                    if(exceptionMessage.toLowerCase().includes('phone')) {
                        let countryCode = exceptionMessage;
                        const regex = /countryCode=([^,]+)/;
                        const match = countryCode.match(regex);
                        if(match) {
                            countryCode = match[1];
                        }
                        return countryCode;
                    }
                }
            },
            {
                "title": 'Carrier Type',
                "width": 150,
                "render": (value) => {
                    const { exceptionMessage } = value;
                    if(exceptionMessage.toLowerCase().includes('phone')) {
                        let carrierType = exceptionMessage;
                        const regex = /carrierType=([^,]+)/;
                        const match = carrierType.match(regex);
                        if(match) {
                            carrierType = match[1];
                        }
                        return carrierType;
                    }
                }
            },
            {
                "title": "Reason",
                "width": 150,
                "render": (value) => {
                    const { exceptionMessage } = value;
                    if(exceptionMessage.toLowerCase().includes('phone')) {
                        let failureReason = exceptionMessage;
                        const regex = /failureReason=([^,]+)/;
                        const match = failureReason.match(regex);
                        if(match) {
                            failureReason = match[1];
                        }
                        return failureReason;
                    }
                }
            },
            {
                "title": 'Carrier Name',
                "width": 150,
                "render": (value) => {
                    const { exceptionMessage } = value;
                    if(exceptionMessage.toLowerCase().includes('phone')) {
                        let carrierName = exceptionMessage;
                        const regex = /carrierName=([^>]+)>/;
                        const match = carrierName.match(regex);
                        if(match) {
                            carrierName = match[1];
                        }
                        return carrierName;
                    }
                }
            }
          ]
    }
    
    const getFailureError = (exceptionMessage) => {
        if(exceptionMessage.toLowerCase().includes('mailgun')) {
            let reason = exceptionMessage;
            const regex = /\(Validation Failure\):(.*?):/;
            const match = reason.match(regex);
            if(match) {
                reason = match[1];
            }
            return reason;
        }
        if(exceptionMessage.toLowerCase().includes('phone')) {
            let reason = exceptionMessage;
            const regex = /\(Validation Failure\):(.*?):/;
            const match = reason.match(regex);
            if(match) {
                reason = match[1];
            }
            return reason;
        }
        else {
            return exceptionMessage.replace('(Validation Failure):', '');
        }
    }
    
    const columns = [
        {
            "title": "Name",
            "key": "name",
            "searchable": true,
            "width": 150
        },
        {
            "title": "Email Id",
            "key": "emailId",
            "searchable": true,
            "width": 150
        },
        {
            "title": "Business Name",
            "key": "businessName",
            "searchable": true,
            "width": 150
        },
        {
            "title": "Phone",
            "key": "phone",
            "searchable": true,
            "width": 150
        },
        {
            "title": "Lead URL",
            "key": "leadUrl",
            "searchable": true,
            "width": 250
        },
        {
            "title": "Lead Campaign",
            "key": "leadCampaign",
            "searchable": true,
            "width": 150
        },
        {
            "title": "Lead Sub Campaign",
            "key": "leadSubCampaign",
            "searchable": true,
            "width": 150
        },
        {
            "title": "Lead Campaign KW",
            "key": "leadCampaignKW",
            "searchable": true,
            "width": 150
        },
        {
            "title": "Lead Content",
            "key": "leadContent",
            "searchable": true,
            "width": 150
        },
        {
            "title": "Lead Medium",
            "key": "leadMedium",
            "searchable": true,
            "width": 150
        },
        {
            "title": "Lead SFDC Campaign",
            "key": "leadSfdcCampaign",
            "searchable": true,
            "width": 150
        },
        {
            "title": 'Failure Reason',
            "key": "failureReason",
            "searchable": true,
            "width": 150
          },
        {
            "title": "Mailgun Error",
            "key": "exceptionMessage",
            "children": mailgunResponseChildren(),
        },
        {
            "title": "Invalid Phone",
            "width": 250,
            "children": phoneNumberResponseChildren()
        }
    ];

    const handleDateChange = (name, value) => {
        setDates({
            ...dates,
            [name]: value
        })
    }

    const formatData = () => {

        if(pageData.length) {
            return pageData.map((listItem) => {
                return {
                    ...listItem,
                    failureReason: getFailureError(listItem.exceptionMessage)
                };
            });
        }
        return pageData;
    };

    const handleDateSubmit = async(date) => {
        if(!date) return;
        try {
            setLoading(true);
            const startDate = dates.startDate.format('YYYY-MM-DD');
            const endDate = dates.endDate.format('YYYY-MM-DD');
            const {data: report} = await beAPIResource.get(`/support/leadgen-management/get-report?start=${startDate}&end=${endDate}`);
            setReport(report);
            setpageData(report)
            setLoading(false);
        } catch (error) {
            console.error("Error while fetching sitemap report", error);
            setLoading(false);
        }
    }
    const unauthorized = props.error === "unauthorized" || !props.userData.name;

    return <div style={{ width: "80vw" }}>
        {
            unauthorized ? <Redirect to="/" /> : ''
        }
    <h2>Report</h2>
    <div className="datePickerFloat">
        <div className="datePicker">
            Start Date: <DatePicker defaultValue={dates.startDate} onChange={(e) => handleDateChange('startDate', e)} disabledDate={today => today > moment().endOf('day')}/>
        </div>
        <div className="datePicker">
            End Date: <DatePicker defaultValue={dates.endDate} onChange={(e) => handleDateChange('endDate', e)} disabledDate={today => today > moment().endOf('day')}/>
        </div>

        <Button type={"primary"} onClick={handleDateSubmit}>Submit</Button>
    </div>
    
    <TableSkeleton
        columns={columns}
        dataSource={formatData()}
        loading={loading}
        scroll={{x: 1000, y: 800}}
    />
</div>
}

export default ReportPage;
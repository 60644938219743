export const formItemLayout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 5 }
    },
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 14 }
    }
};

export const formButtonLayout = {
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 14, offset: 5 }
    }
};

export const monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
];

export const dateFormat = "YYYY-MM-DD";



export const calendarAdmins = process.env.NODE_ENV === "production" ? [
    "alyssa.joseph@birdeye.com"
] : [];

export const newsLetterPublishAccessList = [
    "neeraj@birdeye.com",
    "naveen@birdeye.com",
    "shivani.reddy@birdeye.com"
];

export const mapSourceIdToName = {
    "1": "Yelp",
    "2": "Google",
    "110": "Facebook",
    "0": "Zoominfo"
};

export const richResources = ["guide", "whitepaper", "ebook", "webinar", "video"];

export const websiteNodeAPIUrl = "https://birdeye.com/gapi/";
export const salesforceBaseUrl = "https://login.salesforce.com/";
export const genericWebinarImagePath = "https://d3cnqzq0ivprch.cloudfront.net/support-api/production/website/reosurces/resource-generic-image-1588907040816.svg";
export const genericWebinarOGImagePath = "https://d3cnqzq0ivprch.cloudfront.net/support-api/production/website/resources/resource-generic-og-image-1601641198805.png";

export const signatureAdmins = process.env.NODE_ENV === "production" ? [
    "jay.hinman@birdeye.com",
    "jennifer@birdeye.com"
] : [];
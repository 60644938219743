import { connect } from "react-redux";
import { phoneNumberUploadRequest } from "./action";
import TwilioLookup from "./TwilioLookup";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Twilio
});

export const TwilioLookupPage = connect(
    mapStateToProps,
    { phoneNumberUploadRequest }
)( TwilioLookup );

import React, { Component } from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import moment from "moment";
import {message, Popconfirm, Icon} from "antd";
import lookup from "./pages.json";

import {TableSkeleton, RedirectWrapper, PageHeaderContainer} from "../../components";
import { getIsPageAdmin } from "../../utils";

class Pages extends Component {

    pageKey = "pagesList";

    state = {
        filteredInfo: null,
        filteredLeadInfo: null
    };

    componentDidMount() {
        this.props.fetchPagesListRequest();
    }

    getOnlySlug = (slug) => {
        if (!slug || !slug.length)
            return "";
        let slugValue = slug;
        slugValue = slugValue.replace(/cal/, ''); // remove all instances of cal
        slugValue = slugValue.replace(/^\/+|\/+$/g, '') // remove ending / leading / duplicate slashes
        return slugValue;
    }

    formatData = (data) => {
        const { name, privileges } = this.props.userData;
        const isAdmin = getIsPageAdmin(this.pageKey, privileges);

        return data.filter((record) => ( record.createdByEmailId === name || isAdmin )).map((record) => {
            const dateModified = record.dateModified !== null ? record.dateModified : record.dateAdded;
            return {
                ...record,
                active: record.active ? record.active.toString() : (record.active === false ? "false": "" ),
                link: record.slug && record.previewUrl ? `${record.previewUrl}${this.getOnlySlug(record.slug)}/` : "",
                key: record.id,
                dateAdded: record.dateAdded && moment(record.dateAdded).format('MM-DD-YYYY'),
                actualAddedDate: record.dateAdded,
                actualModifiedDate: dateModified,
                dateModified: moment(dateModified).format('MM-DD-YYYY'),
                formType: record.requestType && lookup.valueList.requestTypes.find((item) => item.value === record.requestType).label,
                customAction: (
                    <center>
                        {
                            ( record.requestType === "CALENDAR" || record.requestType === "CALENDAR_SFDC_CASE" ) && <><span><Link to={{pathname: `/page/invitation/${record.id}`, state: {record}}}>See&nbsp;Invites</Link></span><br/></>
                        }
                        <span>
                            <Link to={{pathname: `/page/${record.id}`, state: {record}}}>Edit</Link>
                        </span><br/>
                        {
                            record.active && <span><Popconfirm title="Are you sure delete this task?" onConfirm={() => this.handleDelete(record)} okText="Yes" cancelText="No"><a href="#">Delete</a></Popconfirm></span>
                        }
                    </center>
                )
            };
        });
    };

    handleDelete = (data) => {
        const { id } = data;
        this.props.deletePageRequest({ id, cb: this.reloadData });
    };

    reloadData = (errorMsg) => {
        this.props.fetchPagesListRequest();
        const { pageData } = this.props;
        this.formatData(pageData);

        if (!errorMsg) {
            return message.success(
                "You have successfully deleted this record."
            );
        } else {
            return message.error(
                "Something went wrong... Please try again later."
            );
        }
    };

    filterChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters.active,
            filteredLeadInfo: filters.formType
        });
    }

    renderExpandedRow = (record) => {
        return (
            <div>
                <div className="mb1">
                    <b>External Page Name: </b>
                    <span>{record.meetingDescription}</span>
                </div>
                <div className="mb1">
                    <b>Sub Heading: </b>
                    <span>{record.subHeading}</span>
                </div>
                <div className="mb1">
                    <b>Description: </b>
                    <span>{record.description}</span>
                </div>
                <div className="mb1">
                    <b>Created On: </b>
                    <span>{moment(record.actualAddedDate).format('MM-DD-YYYY')}</span>
                </div>
                {
                    record.hasOwnProperty("additionalAttendeesSupported") && (
                        <div className="mb1">
                            <b>Additional attendees: </b>
                            <span>{ record.additionalAttendeesSupported ? <Icon type="check" style={{ color: "green" }} /> : <Icon type="close" style={{ color: "red" }} /> }</span>
                        </div>
                    )
                }
                {
                    record.hasOwnProperty("segmentAssignmentEnabled") && (
                        <div className="mb1">
                            <b>Segment assignment: </b>
                            <span>{ record.segmentAssignmentEnabled ? <Icon type="check" style={{ color: "green" }} /> : <Icon type="close" style={{ color: "red" }} /> }</span>
                        </div>
                    )
                }
            </div>
        );
    };

    render() {
        const { pageData, isLoading, error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;
        let { filteredInfo, filteredLeadInfo } = this.state;
        filteredInfo = filteredInfo || ["true"];
        filteredLeadInfo = filteredLeadInfo || [];

        const activitColumnObject = [{
            "title": "Active",
            "key": "active",
            "filterable": true,
            "filters":  [
                { "text": "Yes", "value": "true" },
                { "text": "No", "value": "false" }
            ],
            "filteredValue": this.state.filteredInfo
        }];

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        const columns = [
            {
                "title": "Internal Page Name",
                "key": "type",
                "sortAs": "string",
                "searchable": true,
                "renderAsLink": {
                    "key": "link",
                    "base": ""
                }
            },
            {
                "title": "Heading",
                "key": "heading",
                "searchable": true
            },
            {
                "title": "Created By",
                "key": "createdByEmailId",
                "searchable": true
            },
            {
                "title": "Form Type",
                "key": "formType",
                "exactFilterable": true,
                "filters":  [
                    { "text": "Calendar", "value": "Calendar" },
                    { "text": "Calendar + Lead", "value": "Calendar + Lead" },
                    { "text": "Calendar + SFDC Case", "value": "Calendar + SFDC Case"}
                ],
                "filteredValue": filteredLeadInfo || null,
            },
            {
                "title": "Modified On",
                "key": "dateModified",
                "width": "10%",
                "sortAs": "date",
                "defaultSortOrder": "descend",
                "sorter": (a, b) => moment(a.actualModifiedDate).format("x") - moment(b.actualModifiedDate).format("x"),
            },
            {
                "title": "Active",
                "key": "active",
                "filterable": true,
                "filters":  [
                    { "text": "Yes", "value": "true" },
                    { "text": "No", "value": "false" }
                ],
                "filteredValue": filteredInfo || null,
            },
            {
                "title": "Action",
                "key": "customAction"
            }
        ];

        return (
            <div className="page-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    columns={columns}
                    expandedRowRender={this.renderExpandedRow}
                    dataSource={this.formatData(pageData)}
                    onChange={this.filterChange}
                    loading={isLoading}
                />
            </div>
        );
    }
}

Pages.propTypes = {
    pageData: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchPagesListRequest: PropTypes.func.isRequired,
    deletePageRequest: PropTypes.func.isRequired
};

export default Pages;

import React, { useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { beAPIResource } from '../../utils/apiHelper';
import { RedirectWrapper, TableSkeleton } from "../../components";
import { Button, Modal, Typography } from 'antd';
import { withRouter } from 'react-router-dom';

const ItemTypes = {
  ROW: 'row'
};

const DraggableTableRow = ({ id, index, moveRow, children }) => {
  const ref = React.useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.ROW,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.ROW, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const opacity = isDragging ? 0.5 : 1;
  const cursor = isDragging ? "grabbing" : "grab"

  return (
    <tr ref={ref} style={{ opacity, cursor }}>
      {children}
    </tr>
  );
};

const DraggableTable = ({ history, match, userData }) => {
    
  const [rows, setRows] = useState([]);
  const {pageId} = match.params;

  useEffect(()=>{
    (updateTableData)()
  }, []);

  const updateTableData = async()=>{
      const {data: components} = await beAPIResource.get(`support/website/content/page/${pageId}`);
      const displayData = components.map(component => {
          const heading = JSON.parse(component.data).heading;
          return {...component, heading, show: component.show ? 'Visible' : 'Hidden'}
      })
      setRows(displayData);
  }
    
  const moveRow = (dragIndex, hoverIndex) => {
    const draggedRow = rows[dragIndex];
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows.splice(dragIndex, 1);
      updatedRows.splice(hoverIndex, 0, draggedRow);
      return updatedRows;
    });
  };

  const handleDelete = async (row) => {
    const { id } = row;
    const response = await beAPIResource.delete(`support/website/content/page/${id}`);

    if(response.data) updateTableData();
  }

  const handleSaveButton = async() => {
    let components = [];
    
    for(let i = 0; i < rows.length; i++) {
      try {
        await beAPIResource.put(`support/website/content/page/${rows[i].id}`, { order_no: i+1 });
      } catch (error) {
        console.error("Error while updating website component", error);
      }
      components.push({
        "type": rows[i].name,
        "data": JSON.parse(rows[i].data)
      })
    }
    try {
      await beAPIResource.put(`support/website/content/update`, { id: pageId , data: `{"components": ${JSON.stringify(components)}}` });
      Modal.success({
        title: "Page content has been updated!",
        okText: "Ok",
        maskClosable: true
      });
    } catch (error) {
      Modal.error({
        title: "Error",
        okText: "Return",
        content: "Please verify the data and try again..."
      });
      console.error("Error while updating website content", error);
    }
  }

  const handlePlugButton = async () => {
    let {data: {data: pageData}} = await beAPIResource.get(`support/website/content/${pageId}`);

    /* Below logic will only work properly for components with single appearance */
    for (const rowComponent of rows) {
      switch (true) {
        case !!(pageData["Control"]):
          for (let variationData in pageData) {
            pageData[variationData].components = pageData[variationData].components.map(component => {
              if (rowComponent.name === component.type) {
                return {...component, data: JSON.parse(rowComponent.data)}          
              } else { 
                return component;
              }
            })
          }
          break;
      
        case !!(pageData.components):
          pageData.components = pageData.components.map(component => {
            if (rowComponent.name === component.type) {
              return {...component, data: JSON.parse(rowComponent.data)}          
            } else { 
              return component;
            }
          })
          break;
      }
    }

    try {
      await beAPIResource.put(`support/website/content/update`, { id: pageId, data: JSON.stringify(pageData) });
      Modal.success({
        title: "Page content has been updated!",
        okText: "Ok",
        maskClosable: true
      });
    } catch (error) {
      Modal.error({
        title: "Error",
        okText: "Return",
        content: "Please verify the data and try again..."
      });
      console.error("Error while updating website content", error);
    }

  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Heading',
      dataIndex: 'heading',
      key: 'heading'
    },
    {
      title: 'Visibility',
      dataIndex: 'show',
      key: 'show'
    }
  ];

  return (
    <>
      <Typography.Text type="danger">To save your changes click on "Plug in"</Typography.Text> 
      <TableSkeleton
        dataSource={rows}
        columns={columns}
        rowKey={(record) => record.id}
        components={{
          body: {
            row: DraggableTableRow,
          },
        }}
        onRow={(record, index) => ({
          index,
          moveRow
        })}
        pathForEdit={"/website-content/update-component/"}
        handleDelete={(row) => handleDelete(row)}
      />
      <Button style={{marginRight: '10px'}} onClick={()=>{history.push(`/website-content/add-component/${pageId}`)}}>Add component</Button>
      {/* <Button style={{marginRight: '10px'}} onClick={handleSaveButton} type="primary">Save</Button> */}
      <Button onClick={handlePlugButton} type="danger">Plug in</Button>
    </>
  );
};

const ComponentsTable = ({history, match, userData, error, location}) => {

  const unauthorized = error === "unauthorized" || (!userData || !userData.name);
  if (unauthorized) {
      return <RedirectWrapper location={location} />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
        <DraggableTable history={history} match={match} userData={userData} />
    </DndProvider>
  );
};

export default withRouter(ComponentsTable);

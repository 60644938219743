import {beAPIResource} from "../../utils/apiHelper";

const categoriesApi = {
    getAll: () => beAPIResource.get("support/category"),
    getById: (sourceId) => beAPIResource.get(`support/category/${sourceId}`),
    update: (data) => beAPIResource.put(`support/category/update`, data),
    addBeSubCategory: (data) => beAPIResource.post(`support/category/add-be-subcategory`, data),
    updateSicNaicsRequest: (data) => beAPIResource.put(`support/category/update-sic-naics`, data),
    addNewIndustry: (data) => beAPIResource.post(`support/category/add-new-industry/addNewIndustry`, data)
};
export default categoriesApi;
import React from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";

import MapMask from "./MapMask";
import { addScripts } from "../../../../utils";
import { isSameRecord, getMarker, getZoomAndCenter } from "../../utils";
import mapConfig from "./mapConfig.json";

class GoogleMap extends React.Component {
  constructor(props) {
    super(props);

    const googleMapScript = `${process.env.REACT_APP_GOOGLE_URL}?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;
    const clusterScript = mapConfig.script.cluster;
    addScripts([
      { src: googleMapScript, id: "google-map" },
      { src: clusterScript, id: "google-map-cluster" }
    ]);

    this.state = {
      mapMounted: false
    };

    const {
      mapConstants: { defaultZoom, targetZoom }
    } = mapConfig;
    this.zoomLevel = this.props.hasTarget ? targetZoom : defaultZoom;
    this.googleMapRef = React.createRef();
    this.googleMap = null;
    this.zooming = false;
    this.markerCluster = null;
    this.markers = [];
    this.targetMarker = null;
  }

  shouldComponentUpdate(nextProps) {
    if (!window.google || !window.MarkerClusterer) return false;
    if (!this.googleMap || !this.markerCluster) {
      this.initMap();
      return true;
    }

    this.targetMarker = this.setTargetMarker();
    if (nextProps.data.length !== this.props.data.length || !isEqual(nextProps.data, this.props.data))
      this.mountMarkers(nextProps.data);

    return true;
  }

  initMap = () => {
    if (!window.google || !window.MarkerClusterer) return;
    this.googleMap = this.createGoogleMap();
    this.markerCluster = this.createClusterManager();
    this.targetMarker = this.setTargetMarker();
    this.mountMarkers();
  };

  createGoogleMap = () => {
    const { targetProfile } = this.props;
    const {
      mapConstants: { usCenterCoords },
      mapSettings
    } = mapConfig;
    const hasPopulatedProfile = targetProfile && Object.keys(targetProfile).length;
    const centerCoords = hasPopulatedProfile ? [targetProfile.latitude, targetProfile.longitude] : usCenterCoords;
    const map = new window.google.maps.Map(this.googleMapRef.current, {
      zoom: this.zoomLevel,
      center: new window.google.maps.LatLng(...centerCoords),
      ...mapSettings
    });

    map.addListener("tilesloaded", () => {
      if (!this.state.mapMounted) this.setState({ mapMounted: true }, this.updateMapZoomCenter);
    });

    return map;
  };

  createClusterManager = () => {
    const markerCluster = new window.MarkerClusterer(this.googleMap, this.markers, {
      ...mapConfig.clusterSettings
    });

    return markerCluster;
  };

  setTargetMarker = () => {
    const { targetProfile, industryToSubs } = this.props;
    if (!targetProfile || !targetProfile.longitude || !targetProfile.latitude) return;
    return getMarker(this.googleMap, mapConfig, industryToSubs, targetProfile, true);
  };

  mountMarkers = (data = this.props.data) => {
    this.markerCluster.clearMarkers();
    this.updateMarkers(data);
    for (let i = 0, j = this.markers.length; i < j; i++) {
      this.markerCluster.addMarker(this.markers[i]);
    }
    if (this.targetMarker) this.markerCluster.addMarker(this.targetMarker);
    this.updateMapZoomCenter();
  };

  updateMarkers = (data) => {
    const { upperLimit, targetProfile, industryToSubs } = this.props;

    this.markers = [];
    if (data.length > upperLimit) return;

    let hasTargetProfile = targetProfile && Object.keys(targetProfile).length;
    for (let i = 0; i < data.length; i++) {
      if (hasTargetProfile && isSameRecord(data[i], targetProfile)) continue;
      if (!data[i] || !data[i].latitude || !data[i].longitude) continue;
      let marker = getMarker(this.googleMap, mapConfig, industryToSubs, data[i], false);
      this.markers.push(marker);
    }
  };

  updateMapZoomCenter = () => {
    if (!this.state.mapMounted) return;
    const [newZoom, newCenter] = getZoomAndCenter(
      mapConfig,
      this.googleMapRef.current,
      this.markers,
      this.targetMarker
    );
    // TODO: explore other options
    this.zoomLevel = newZoom;
    this.zooming = true;
    setTimeout(() => {
      this.zooming = false;
      this.forceUpdate();
    }, 2000);
    this.googleMap.setZoom(this.zoomLevel);
    this.googleMap.setCenter(newCenter);
  };

  renderMask = () => {
    const { data, filterObj, isRecordLoading, isProfileLoading, upperLimit } = this.props;
    return (
      <MapMask
        data={data}
        filterObj={filterObj}
        isRecordLoading={isRecordLoading}
        isProfileLoading={isProfileLoading}
        upperLimit={upperLimit}
        zooming={this.zooming}
      />
    );
  };

  render() {
    return (
      <div className="map-inner-wrap">
        {this.renderMask()}
        <div id="google-map" className="google-map" ref={this.googleMapRef} />
      </div>
    );
  }
}

GoogleMap.propTypes = {
  data: PropTypes.array.isRequired,
  filterObj: PropTypes.object.isRequired,
  hasTarget: PropTypes.bool.isRequired,
  targetProfile: PropTypes.object.isRequired,
  isRecordLoading: PropTypes.bool.isRequired,
  isProfileLoading: PropTypes.bool.isRequired,
  industryToSubs: PropTypes.object.isRequired,
  upperLimit: PropTypes.number.isRequired
};

export default GoogleMap;

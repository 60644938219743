import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Input, Button, Modal, Form, Switch, Select } from "antd";
import lookupResource from "./../../../pages/Resources/resources.json";
import { formItemLayout, formButtonLayout } from "../../../constants";
import { encodeResourceClassification, decodeResourceClassification } from "../../../utils";

class ArticleFormComponent extends Component {
    constructor(props) {
        super();
        this.article = {};
        this.state = {
            articleId: props.match.params.articleId,
            isUpdate: props.match.params.articleId !== "new"
        }
    }

    componentDidMount() {
        if (this.state.isUpdate) this.props.fetchArticleByIdRequest({articleId: this.state.articleId});
    }

    handleFormSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (error) return;

            const userEmail = this.props.userData.name;
            const classification = encodeResourceClassification(
                "article",
                values.indusrties,
                values.topics,
                lookupResource
            );
            if (this.state.isUpdate) {
                this.props.updateArticleRequest({articleId: this.state.articleId, data: {...values, classification, updated_by: userEmail}, cb: this.renderFeedback});
            } else {
                this.props.addArticleRequest({data: {...values, classification, created_by: userEmail}, cb: this.renderFeedback});
            }
        });
    }

    renderFeedback = (notification) => {
        const historyPush = this.props.history.push;
        Modal[notification.type]({
            title: notification.type === "success" ? "Sucessfully saved" : "Something went wrong",
            content: notification.message,
            okText: notification.type === "error" ? "Close" : "Go to artile list page",
            maskClosable: false,
            onOk() {
                return notification.type === "error" ? "" : historyPush("/resource/article");
            }
        });
    }

    render() {
        const { userData, isLoading, article } = this.props;
        const { getFieldDecorator } = this.props.form;
        const unauthorized = !userData.name;
        const { industries=[], topics=[] } = lookupResource.valueList;
        const decodedClassification = decodeResourceClassification(article.classification || "");

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <h2>{`${this.state.isUpdate ? "Edit": "Add"}`}&nbsp;Article</h2>
                <Form onSubmit={this.handleFormSubmit} loading={isLoading}>
                    <Form.Item {...formItemLayout} label={"Card heading"}>
                        {
                            getFieldDecorator("card_heading", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable card heading related to resource"
                                }],
                                initialValue: article.card_heading
                            }) (
                                <Input placeholder={"Card heading"}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={"Card description"}>
                        {
                            getFieldDecorator("card_description", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable card description related to resource"
                                }],
                                initialValue: article.card_description
                            }) (
                                <Input.TextArea placeholder={"Card description"}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={"Link"}>
                        {
                            getFieldDecorator("link", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable card description related to resource"
                                }],
                                initialValue: article.link
                            }) (
                                <Input placeholder={"Card description"}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={"Related Industries"}>
                        {
                            getFieldDecorator("indusrties", {
                                rules: [{
                                    required: true,
                                    message: "Select suitable industries related to resource"
                                }],
                                initialValue: decodedClassification.industries
                            }) (
                                <Select placeholder={"Related industries"} showSearch={true} mode={"multiple"}>
                                {
                                    industries.map((industry, index) => (
                                        <Select.Option key={index} value={industry.value}>{industry.label}</Select.Option>
                                    ))
                                }
                                </Select>
                            )
                        }
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={"Related topics"}>
                        {
                            getFieldDecorator("topics", {
                                rules: [{
                                    required: true,
                                    message: "Select suitable topics related to resource"
                                }],
                                initialValue: decodedClassification.topics
                            }) (
                                <Select placeholder={"Related topics"} showSearch={true} mode={"multiple"} >
                                {
                                    topics.map((topic, index) => (
                                        <Select.Option key={index} value={topic.value}>{topic.label}</Select.Option>
                                    ))
                                }
                                </Select>
                            )
                        }
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={"Status"}>
                        {
                            getFieldDecorator("showInList", {
                                valuePropName: "checked",
                                initialValue: article.showInList
                            }) (
                                <Switch checkedChildren={"Public"} unCheckedChildren={"Private"} style={{width: "100px"}}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item {...formButtonLayout}>
                        <Button type={"primary"} htmlType={"submit"}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

const ArticleForm = Form.create({name: "ArticleForm"})(ArticleFormComponent);
export default ArticleForm;
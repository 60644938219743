import { takeLatest, call, put } from "redux-saga/effects";
import AnalyticsActions, * as actionCreator from "./actions";
import analyticsApi from "./https";

const analyticsSagas = [
    takeLatest(AnalyticsActions.ANALYTICS_REPORT, analyticsReport)
];

function* analyticsReport(action) {
    try {
        const resp = yield call(analyticsApi.analytics, action.payload.data);
        yield put(actionCreator.analyticsReportSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(
            actionCreator.analyticsReportError(e.data.data || { error: "analyticsReportError" })
        );
    }
}

export default analyticsSagas;

import { beAPIResource } from "../../utils/apiHelper";

const featureApi = {
    getAllBusinessType: () => beAPIResource.get("support/feature-releases/business-types"),
    getAllApplicableModule: () => beAPIResource.get("support/feature-releases/applicable-module"),
    getAllReleaseTypes: () => beAPIResource.get("support/feature-releases/release-types"),
    getAll: () => beAPIResource.get("support/feature-releases/?include_archived=true"),
    getById: (id) => beAPIResource.get(`support/feature-releases/${id}`),
    add: (data) => beAPIResource.post("support/feature-releases/", data),
    update: (data) => beAPIResource.put(`support/feature-releases/${data.releaseId}`, data),
    delete: (releaseId) => beAPIResource.delete(`support/feature-releases/${releaseId}`),
    getNewsletter: () => beAPIResource.get("support/feature-releases/newsletter")
};

export default featureApi;

import { createActionTypes } from "../../utils";

export const EmailServiceActions = createActionTypes("emailService", [
    "SEARCH_DOMAIN_REQUEST",
    "SUCCESS_SEARCH_DOMAIN_RESPONSE",
    "ERROR_SEARCH_DOMAIN_RESPONSE",
    "FETCH_EMAILS_REQUEST",
    "FETCH_EMAILS_RESPONSE",
    "ERROR_FETCH_EMAILS_RESPONSE",
    "BLANK_EMAILS_RESPONSE",
]);

export const searchDomainRequest = (payload) => {
    return {
        type: EmailServiceActions.SEARCH_DOMAIN_REQUEST,
        payload
    }
};

export const successSearchDomainResponse = (message) => {
    return {
        type: EmailServiceActions.SUCCESS_SEARCH_DOMAIN_RESPONSE,
        message: message
    };
};

export const errorSearchDomainResponse = (message) => {
    return {
        type: EmailServiceActions.ERROR_SEARCH_DOMAIN_RESPONSE,
        message: message
    };
};

export const fetchEmailRequest = (obj) => {
    return {
        type: EmailServiceActions.FETCH_EMAILS_REQUEST,
        payload: obj
    }
};

export const blankEmailRequest = () => {
    return {
        type: EmailServiceActions.BLANK_EMAILS_RESPONSE
    }
};

export const successFetchEmailResponse = (obj) => {
    return {
        type: EmailServiceActions.FETCH_EMAILS_RESPONSE,
        payload: obj
    };
};

export const errorFetchEmailResponse = (message) => {
    return {
        type: EmailServiceActions.ERROR_FETCH_EMAILS_RESPONSE,
        message: message
    };
};

export default EmailServiceActions;

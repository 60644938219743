import { beAPIResource } from "../../utils/apiHelper";

export const PartnerAPI = {
    fetch: (payload) => beAPIResource.get(`/support/partners/library`),
    fetchById: (payload) => beAPIResource.get(`/support/partners/library/${payload.documentId}`),
    update: (payload, documentId) => beAPIResource.put(`/support/partners/library/${documentId}`, payload),
    create: (payload) => beAPIResource.post("/support/partners/library", payload),
    download: (payload) => beAPIResource.get(`/support/partners/library/download/${payload.fileType}/${payload.fileId}/${payload.id}`),
    fetchBySection: (query) => beAPIResource.get(`/support/partners/library/section`, query),
    fetchAnnouncements: () => beAPIResource.get(`/support/partners/announcement`),
    updateAnnouncements: (payload) => beAPIResource.put(`/support/partners/announcement`, payload),
    deleteAnnouncement : (documentId) => beAPIResource.delete(`/support/partners/announcement/${documentId}`)
};

export default PartnerAPI;
import React, {Component} from "react";
import {Form, Button, Modal, Select, Icon, Checkbox, Tooltip, Mentions, Divider }from 'antd';
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";
import { formItemLayout, formButtonLayout } from "../../constants";
import lookup from "./constants";

const meetingSuggestions = {
    '<': ['attendeeName>', 'attendeePhone>', 'attendeeComments>', 'organizerEmail>', "organizerName>", "meetingTime>", "meetingDate>", "meetingTimeOnly>", "businessName>", "businessPhone>", "duration>", "meetingJoinUrl>", "rescheduleMeetingUrl>"]
};
const suggestionKeywords = ["<attendeeName> - Attendee’s name", "<attendeePhone> -  Attendee’s phone", "<attendeeComments> - Attendee’s comments", "<organizerEmail>  - Organizer’s email", "<organizerName> - Organizer’s name", "<meetingTime> - Meeting time", "<meetingDate> - Meeting date", "<meetingTimeOnly> - Meeting time", "<duration> - Meeting duration", "<businessName> - Business name", "<businessPhone> - Business phone", "<meetingJoinUrl> - Meeting join url", "<rescheduleMeetingUrl> - Reschedule meeting url"];
 
const FormItem = Form.Item;
const Option = Select.Option;
class MeetingInviteForm extends Component {

    constructor(props){
        super(props);
    }

    handleMeetingInviteForm = (e) =>{
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((error, values) => {
            if(!error) {
                values.meetingConfirmationEmailSubject = values.meetingConfirmationEmailSubject ? values.meetingConfirmationEmailSubject : lookup.meetingInviteData.meetingConfirmationEmailSubject,
                values.meetingConfirmationEmailBody = values.meetingConfirmationEmailBody ? values.meetingConfirmationEmailBody : lookup.meetingInviteData.meetingConfirmationEmailBody,
                values.meetingConfirmationTextCustomization = values.meetingConfirmationTextCustomization ? values.meetingConfirmationTextCustomization : lookup.meetingInviteData.meetingConfirmationTextCustomization,
                values.meetingReminderEmailSubjectFirst = values.meetingReminderEmailSubjectFirst ? values.meetingReminderEmailSubjectFirst : lookup.meetingInviteData.meetingReminderEmailSubjectFirst,
                values.meetingReminderEmailBodyFirst = values.meetingReminderEmailBodyFirst ? values.meetingReminderEmailBodyFirst : lookup.meetingInviteData.meetingReminderEmailBodyFirst,
                values.meetingReminderEmailSubjectSecond = values.meetingReminderEmailSubjectSecond ? values.meetingReminderEmailSubjectSecond : lookup.meetingInviteData.meetingReminderEmailSubjectSecond,
                values.meetingReminderEmailBodySecond = values.meetingReminderEmailBodySecond ? values.meetingReminderEmailBodySecond : lookup.meetingInviteData.meetingReminderEmailBodySecond,
                values.meetingReminderTextCustomizationFirst = values.meetingReminderTextCustomizationFirst ? values.meetingReminderTextCustomizationFirst : lookup.meetingInviteData.meetingReminderTextCustomizationFirst,
                values.meetingReminderTextCustomizationSecond = values.meetingReminderTextCustomizationSecond ? values.meetingReminderTextCustomizationSecond : lookup.meetingInviteData.meetingReminderTextCustomizationSecond,
                this.props.handleCalendarStepsForm({meetingInvite: {... values}});
            }
        });
    }

    supportedKeywordsInfo = () => {
        Modal.info({
          title: 'Supported keywords for meeting',
          width: "417px",
          content: (
            <>
            <Divider/>
            <div>
                {
                    suggestionKeywords.map((keyword) => {
                        return <p key={keyword}> {keyword} </p>
                    }) 
                }
            </div>
            </>
          )
        });
    }

    render(){
        const { getFieldDecorator } = this.props.form;
        const { meetingInvite } = this.props.initialValues;
        const { calendarMeetingDescription, 
                calendarMeetingTitle, 
                meetingConfirmationEmailSubject,
                meetingConfirmationEmailBody,
                meetingConfirmationTextCustomization,
                meetingReminderEmailSubjectFirst,
                meetingReminderEmailBodyFirst,
                meetingReminderEmailSubjectSecond,
                meetingReminderEmailBodySecond,
                meetingReminderTextCustomizationFirst,
                meetingReminderTextCustomizationSecond,
                meetingConfirmation,
                meetingReminder 
               } = meetingInvite || {};

        return (
            <Form onSubmit={this.handleMeetingInviteForm}>
                <FormItem {...formItemLayout} label="Meeting invite title" >
                    {getFieldDecorator('calendarMeetingTitle', {
                        rules: [{ 
                            "required": true, 
                            "message": 'Please provide meeting title.'
                        }, {
                            "max": 150, 
                            "message": 'Meeting title should be of maximum 150 characters.' 
                        }],
                        initialValue: calendarMeetingTitle
                    })(
                        <Mentions
                            rows="2"
                            prefix={['<']}
                            placeholder="Meeting invite title"
                            maxLength={151}
                            split=""
                        >
                            {(meetingSuggestions['<'] || []).map(value => (
                            <Option key={value} value={value}>
                                {'<'+value}
                            </Option>
                            ))}
                        </Mentions>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="Meeting invite description" extra= {
                        <p>Keywords: Type '&lt;' to look at available keywords. Click
                            <a onClick={this.supportedKeywordsInfo}> here </a>to view all supported keywords
                        </p>
                    }>
                    {getFieldDecorator('calendarMeetingDescription', {
                        rules: [{ 
                            "required": true, 
                            "message": 'Please provide meeting description.'
                        }, {
                            "max": 5000, 
                            "message": 'Meeting description should be of maximum 5000 characters.'
                        }],
                        initialValue: calendarMeetingDescription
                    })(
                        <Mentions
                            prefix={['<']}
                            rows="10"
                            maxLength={5001}
                            split=""
                        >
                        {(meetingSuggestions['<'] || []).map(value => (
                            <Option key={value} value={value}>
                                {'<'+value}
                            </Option>
                        ))} 
                        </Mentions>
                    )}
                </FormItem>
                <Divider orientation='left' style={{"margin":"30px"}}>Confirmation & reminder communication</Divider>
                <FormItem {...formItemLayout} label="Send confirmation communication" >
                    {getFieldDecorator('meetingConfirmation', {
                        initialValue: meetingConfirmation
                    })(
                    <Checkbox.Group
                        options={lookup.valueList.confirmationAndReminder}
                    />
                    )}
                </FormItem>
                {
                    !isEmpty(this.props.form.getFieldValue("meetingConfirmation")) &&
                        <Divider orientation='left'> Meeting confirmation </Divider>
                }  
                {
                    this.props.form.getFieldValue("meetingConfirmation").indexOf('email') !== -1 && 
                        <>
                            <FormItem {...formItemLayout} label="Subject" key={"meetingConfirmationSubject"} >
                                {getFieldDecorator('meetingConfirmationEmailSubject', {
                                    rules: [{ 
                                        "required": true, 
                                        "message": 'Please provide confirmation email subject.'
                                    }, {
                                        "max": 150, 
                                        "message": 'Confirmation email subject should be of maximum 150 characters.' 
                                    }],
                                    initialValue: meetingConfirmationEmailSubject
                                })(
                                    <Mentions
                                        rows="2"
                                        prefix={['<']}
                                        placeholder="Enter confirmation email subject"
                                        maxLength={151}
                                        split=""
                                    >
                                        {(meetingSuggestions['<'] || []).map(value => (
                                        <Option key={value} value={value}>
                                            {'<'+value}
                                        </Option>
                                        ))}
                                    </Mentions>
                                )}
                            </FormItem>
                            <FormItem {...formItemLayout} label="Body" extra= {
                                <p>Keywords: Type '&lt;' to look at available keywords. Click
                                    <a onClick={this.supportedKeywordsInfo}> here </a>to view all supported keywords
                                </p>
                            }>
                            {getFieldDecorator('meetingConfirmationEmailBody', {
                                rules: [{ 
                                    "required": true, 
                                    "message": 'Please provide confirmation email body.'
                                }, {
                                    "max": 5000, 
                                    "message": 'Confirmation email body should be of maximum 5000 characters.'
                                }],
                                initialValue: meetingConfirmationEmailBody
                            })(
                                <Mentions
                                    prefix={['<']}
                                    rows="7"
                                    maxLength={5001}
                                    split=""
                                >
                                {(meetingSuggestions['<'] || []).map(value => (
                                    <Option key={value} value={value}>
                                        {'<'+value}
                                    </Option>
                                ))} 
                                </Mentions>
                            )}
                            </FormItem>
                        </>    
                }
                {
                    this.props.form.getFieldValue("meetingConfirmation").indexOf('text') !== -1 &&
                        <FormItem {...formItemLayout} label="Text"  extra= {
                            <p>Keywords: Type '&lt;' to look at available keywords. Click
                                <a onClick={this.supportedKeywordsInfo}> here </a>to view all supported keywords
                            </p>
                        }>
                        {getFieldDecorator('meetingConfirmationTextCustomization', {
                            rules: [{ 
                                "required": true, 
                                "message": 'Please provide confirmation text.'
                            }],
                            initialValue: meetingConfirmationTextCustomization
                        })(
                            <Mentions
                                prefix={['<']}
                                rows="4"
                                split=""
                            >
                            {(meetingSuggestions['<'] || []).map(value => (
                                <Option key={value} value={value}>
                                    {'<'+value}
                                </Option>
                            ))} 
                            </Mentions>
                        )}
                        </FormItem>
                }
                <FormItem {...formItemLayout} label="Send reminder communication" >
                    {getFieldDecorator('meetingReminder', {
                            initialValue: meetingReminder
                    })(
                        <Checkbox.Group
                            options={lookup.valueList.confirmationAndReminder}
                        />
                    )}
                        <Tooltip title="Reminders are sent a day before the meeting and 60 minutes before the meeting" >
                            <Icon  type="info-circle" theme="filled" style={{ fontSize: "16px", color: "#0049B0", cursor: "pointer" }} />
                        </Tooltip> 
                </FormItem>
                {
                    !isEmpty(this.props.form.getFieldValue("meetingReminder")) &&
                        <Divider orientation='left'>Reminder 1 (Sent 1 day before the meeting)</Divider>
                }
                {
                    this.props.form.getFieldValue("meetingReminder").indexOf('email') !== -1  && 
                    <>
                        <FormItem {...formItemLayout} label="Email subject" >
                            {getFieldDecorator('meetingReminderEmailSubjectFirst', {
                                rules: [{ 
                                    "required": true, 
                                    "message": 'Please provide reminder email subject.'
                                }, {
                                    "max": 150, 
                                    "message": 'Reminder email subject should be of maximum 150 characters.' 
                                }],
                                initialValue: meetingReminderEmailSubjectFirst
                            })(
                                <Mentions
                                    rows="2"
                                    prefix={['<']}
                                    placeholder="Enter reminder email subject"
                                    maxLength={151}
                                    split=""
                                >
                                    {(meetingSuggestions['<'] || []).map(value => (
                                    <Option key={value} value={value}>
                                        {'<'+value}
                                    </Option>
                                    ))}
                                </Mentions>
                            )}
                        </FormItem>
                        <FormItem {...formItemLayout} label="Email body" extra= {
                            <p>Keywords: Type '&lt;' to look at available keywords. Click
                                <a onClick={this.supportedKeywordsInfo}> here </a>to view all supported keywords
                            </p>
                        }>
                        {getFieldDecorator('meetingReminderEmailBodyFirst', {
                            rules: [{ 
                                "required": true, 
                                "message": 'Please provide reminder email body.'
                            }, {
                                "max": 5000, 
                                "message": 'Reminder email body should be of maximum 5000 characters.'
                            }],
                            initialValue: meetingReminderEmailBodyFirst
                        })(
                            <Mentions
                                prefix={['<']}
                                rows="7"
                                maxLength={5001}
                                split=""
                            >
                            {(meetingSuggestions['<'] || []).map(value => (
                                <Option key={value} value={value}>
                                    {'<'+value}
                                </Option>
                            ))} 
                            </Mentions>
                        )}
                        </FormItem>
                    </>
                }
                {
                    this.props.form.getFieldValue("meetingReminder").indexOf('text') !== -1  && 
                        <FormItem {...formItemLayout} label="Text" extra= {
                            <p>Keywords: Type '&lt;' to look at available keywords. Click
                                <a onClick={this.supportedKeywordsInfo}> here </a>to view all supported keywords
                            </p>
                        }>
                        {getFieldDecorator('meetingReminderTextCustomizationFirst', {
                            rules: [{ 
                                "required": true, 
                                "message": 'Please provide reminder text.'
                            }],
                            initialValue: meetingReminderTextCustomizationFirst
                        })(
                            <Mentions
                                prefix={['<']}
                                rows="4"
                                split=""
                            >
                            {(meetingSuggestions['<'] || []).map(value => (
                                <Option key={value} value={value}>
                                    {'<'+value}
                                </Option>
                            ))} 
                            </Mentions>
                        )}
                        </FormItem>

                }
                {
                    !isEmpty(this.props.form.getFieldValue("meetingReminder")) &&
                        <Divider orientation='left'>Reminder 2 (Sent 1 hour before the meeting)</Divider>
                }
                {
                    this.props.form.getFieldValue("meetingReminder").indexOf('email') !== -1  && 
                    <>
                        <FormItem {...formItemLayout} label="Email subject" >
                        {getFieldDecorator('meetingReminderEmailSubjectSecond', {
                            rules: [{ 
                                "required": true, 
                                "message": 'Please provide email subject.'
                            }, {
                                "max": 150, 
                                "message": 'Email subject should be of maximum 150 characters.' 
                            }],
                            initialValue: meetingReminderEmailSubjectSecond
                        })(
                            <Mentions
                                rows="2"
                                prefix={['<']}
                                placeholder="Enter reminder email subject"
                                maxLength={151}
                                split=""
                            >
                                {(meetingSuggestions['<'] || []).map(value => (
                                <Option key={value} value={value}>
                                    {'<'+value}
                                </Option>
                                ))}
                            </Mentions>
                        )}
                        </FormItem>
                        <FormItem {...formItemLayout} label="Email body" extra= {
                            <p>Keywords: Type '&lt;' to look at available keywords. Click
                                <a onClick={this.supportedKeywordsInfo}> here </a>to view all supported keywords
                            </p>
                        }>
                        {getFieldDecorator('meetingReminderEmailBodySecond', {
                            rules: [{ 
                                "required": true, 
                                "message": 'Please provide email body.'
                            }, {
                                "max": 5000, 
                                "message": 'Meeting description should be of maximum 5000 characters.'
                            }],
                            initialValue: meetingReminderEmailBodySecond
                        })(
                            <Mentions
                                prefix={['<']}
                                rows="7"
                                maxLength={5001}
                                split=""
                            >
                            {(meetingSuggestions['<'] || []).map(value => (
                                <Option key={value} value={value}>
                                    {'<'+value}
                                </Option>
                            ))} 
                            </Mentions>
                        )}
                        </FormItem>
                    </>
                }
                {
                    this.props.form.getFieldValue("meetingReminder").indexOf('text') !== -1  && 
                        <FormItem {...formItemLayout} label="Text" extra= {
                            <p>Keywords: Type '&lt;' to look at available keywords. Click
                                <a onClick={this.supportedKeywordsInfo}> here </a>to view all supported keywords
                            </p>
                        }>
                        {getFieldDecorator('meetingReminderTextCustomizationSecond', {
                            rules: [{ 
                                "required": true, 
                                "message": 'Please provide reminder text.'
                            }],
                            initialValue: meetingReminderTextCustomizationSecond
                        })(
                            <Mentions
                                prefix={['<']}
                                rows="4"
                                split=""
                            >
                            {(meetingSuggestions['<'] || []).map(value => (
                                <Option key={value} value={value}>
                                    {'<'+value}
                                </Option>
                            ))} 
                            </Mentions>
                        )}
                        </FormItem>
                }
               
                <FormItem {...formButtonLayout}>
                    <Button type={"primary"} htmlType={"submit"}>
                        Continue<Icon type="right" />
                    </Button>
                </FormItem>
            </Form>
        )
    }
}

const MeetingInvite = Form.create()(MeetingInviteForm);

MeetingInvite.propTypes = {
    initialValues: PropTypes.object.isRequired,
    handleCalendarStepsForm: PropTypes.func.isRequired
};

export default MeetingInvite;
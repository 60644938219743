import ACTIVITY_ACTIONS from './actions';

const DEFAULT_SEARCH_PARAMS = {
    user: null,
    api : null,
    type: null,
    pageSize : 1,
    pageIndex: 2,
    sortBy   : 'createdAt'  ,
    sortDesc : false
};

const DEFAULT_ACTIVITY_STATE = {
    isLoading: true,
    data: [],
    totalData: 0,
    searchParams: DEFAULT_SEARCH_PARAMS,
    username: null,
};

const activityReducer = (state = DEFAULT_ACTIVITY_STATE, action) => {
    switch (action.type) {
        case ACTIVITY_ACTIONS.FETCH_ACTIVITY_LIST :
            return {
                ...state,
                isLoading: true,
            };
        case ACTIVITY_ACTIONS.SET_ACTIVITY_LIST :
            return {
                ...state,
                isLoading: false,
                data: action.payload.data,
                totalData: action.payload.total,
                searchParams: {
                    user: action.payload.user,
                    api : action.payload.api,
                    type: action.payload.type,
                    pageSize : action.payload.pageSize,
                    pageIndex: action.payload.pageIndex,
                }
            };
        default:
            return state;
    }
};

export default activityReducer;

export default {
    listingTableColumns: [
        {
            title: "Title",
            key: "card_heading",
            dataIndex: "card_heading",
            searchable: true,
            ellipsis: true,
            width: "70%",
        },
        {
            title: "Actions",
            key: "actions",
            dataIndex: "actions",
            width: "30%",
            align: "center"
        },
    ]
};
import React, { Component } from "react";
import PropTypes from "prop-types";
import { pick, uniq } from "lodash";
import { Form, Modal } from "antd";
import { RedirectWrapper } from "../../components";
import { CalendarSteps } from "../../components";
import  lookup from "../../components/CalendarSteps/constants";


class PageForm extends Component {
    configureData = {
        id: "",
        active: "",
        type: "", // internal description
        slug: "",
        previewUrl: "https://birdeye.com/cal/",
        durationInMinutes: 30,
        requestType: "CALENDAR",
        salesRepIds: [],
        fallbackCalendar: "none",
        holiday: "United States",
        slotsDuration: 14,
        description: "",
        pageType: "group",
        skipHours: 0.5,
        distributionType: "Based_on_Availability",
        leadCampaignId: "701PA000005en5eYAA",
        slotsInterval: 30
    };

    customizeData = {
        heading: "",
        subHeading: "",
        additionalAttendeesSupported: false,
        message: false,
        commentLabel: "Message",
        calendarPageSequence: "SHOW_TIME_SLOTS"
    };

    meetingInviteData = {
        calendarMeetingTitle: lookup.meetingInviteData.calendarMeetingTitle,
        calendarMeetingDescription: lookup.meetingInviteData.calendarMeetingDescription,
        meetingConfirmationEmailSubject: lookup.meetingInviteData.meetingConfirmationEmailSubject,
        meetingConfirmationEmailBody: lookup.meetingInviteData.meetingConfirmationEmailBody,
        meetingConfirmationTextCustomization: lookup.meetingInviteData.meetingConfirmationTextCustomization,
        meetingReminderEmailSubjectFirst: lookup.meetingInviteData.meetingReminderEmailSubjectFirst,
        meetingReminderEmailBodyFirst: lookup.meetingInviteData.meetingReminderEmailBodyFirst,
        meetingReminderEmailSubjectSecond: lookup.meetingInviteData.meetingReminderEmailSubjectSecond,
        meetingReminderEmailBodySecond: lookup.meetingInviteData.meetingReminderEmailBodySecond,
        meetingReminderTextCustomizationFirst: lookup.meetingInviteData.meetingReminderTextCustomizationFirst,
        meetingReminderTextCustomizationSecond: lookup.meetingInviteData.meetingReminderTextCustomizationSecond,
        meetingConfirmation: [],
        meetingReminder: []
    }

    state = {
        configure: this.configureData,
        customize: this.customizeData,
        meetingInvite: this.meetingInviteData,
        isEditPage: !!this.props.match.params.id
    };

    componentDidMount() {
        const { location, match, fetchPageRequest, fetchSalesRepsListRequest, reqFallBackCal } = this.props;
        fetchSalesRepsListRequest();
        reqFallBackCal();

        if (location.state && location.state.record) {
            this.setState({ configure: {
                ...this.state.configure,
                ...this.mapConfigureDataToForm(location.state.record)
            },
            customize: {
                ...this.state.customize,
                ...this.mapCustomizeDataToForm(location.state.record)
            },
            meetingInvite: {
                ...this.state.meetingInvite,
                ...this.mapMeetingInviteDataToForm(location.state.record)
            },
        });
        } else if (match.params.id) {
            fetchPageRequest({ id: match.params.id });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.curPage.id !== prevProps.curPage.id) {
            this.setState({ configure: {
                ...this.state.configure,
                ...this.mapConfigureDataToForm(this.props.curPage)
            },
            customize: {
                ...this.state.customize,
                ...this.mapCustomizeDataToForm(this.props.curPage)
            },
            meetingInvite: {
                ...this.state.meetingInvite,
                ...this.mapMeetingInviteDataToForm(this.props.curPage)
            }});
        }
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                isEditPage: !!this.props.match.params.id,
                configure: this.configureData,
                customize: this.customizeData
             });
        }
    }

    mapConfigureDataToForm = (rawData) => {
        let data = pick(rawData, [
            "id",
            "active",
            "type",
            "slug",
            "previewUrl",
            "durationInMinutes",
            "requestType",
            "salesRepIds",
            "fallbackCalendar",
            "holiday",
            "slotsDuration",
            "description",
            "pageType",
            "skipHours",
            "distributionType",
            "leadCampaignId",
            "slotsInterval"
        ]);

        data.slug = this.getOnlySlug(data.slug);

        return {
            ...data,
            active: data.active ? data.active.toString() : (data.active === false ? "false" : ""),
            salesRepIds: data.salesRepIds ? uniq(data.salesRepIds) : []
        };
    };

    mapCustomizeDataToForm = (rawData) => {
        let data = pick(rawData, [
            "heading",
            "subHeading",
            "additionalAttendeesSupported",
            "message",
            "commentLabel",
            "calendarPageSequence"
        ]);

        return { ...data };
    };

    mapMeetingInviteDataToForm = (rawData) => {
        let data = pick(rawData, [
            "calendarMeetingTitle",
            "calendarMeetingDescription",
            "meetingConfirmationEmailSubject",
            "meetingConfirmationEmailBody",
            "meetingConfirmationTextCustomization",
            "meetingReminderEmailSubjectFirst",
            "meetingReminderEmailBodyFirst",
            "meetingReminderEmailSubjectSecond",
            "meetingReminderEmailBodySecond",
            "meetingReminderTextCustomizationFirst",
            "meetingReminderTextCustomizationSecond",
            "meetingConfirmation",
            "meetingReminder"
        ]);

        return { ...data };
    };

    getOnlySlug = (slug) => {
        if (!slug || !slug.length)
            return "";
        let slugValue = slug;
        slugValue = slugValue.replace(/cal/, ''); // remove all instances of cal
        slugValue = slugValue.replace(/^\/+|\/+$/g, '') // remove ending / leading / duplicate slashes
        return slugValue;
    }

    handleSubmit = (data) => {

        const {configure, customize, meetingInvite } = data;
        const result = {...configure, ...customize, ...meetingInvite};

        let slugValue = "cal/" + configure.slug;
        let calendarPageSequenceValue = configure.requestType === "CALENDAR_LEAD" ? "SHOW_LEAD_FORM" : customize.calendarPageSequence;
        let finalData = {...result, slug: slugValue, calendarPageSequence: calendarPageSequenceValue};
        
        if (this.state.isEditPage) {
            this.props.updatePageRequest({ data: finalData, cb: this.renderFeedback });
        } else {
             this.props.addPageRequest({ data: {...finalData, createdByEmailId: this.props.userData.name}, cb: this.renderFeedback });
        }
    };

    renderFeedback = (response) => {
        const goToPagesPage = () => this.props.history.push("/pages");
        if (!response.error && response.error !== null) {
            return Modal.success({
                title: `You have successfully ${
                    this.state.isEditPage ? "edited" : "created"
                } this calendar.`,
                okText: "View the calendar list",
                maskClosable: true,
                onOk() {
                    goToPagesPage();
                }
            });
        } else {
            return Modal.warning({
                title: `You have not successfully ${
                    this.state.isEditPage ? "edited" : "created"
                } this calendar.`,
                content: response.error
            });
        }
    };

    render() {
        const { error, userData, fallBackCal } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;
        const hasCalendarAdminAccess = userData.privileges && userData.privileges.indexOf('calendarManagementAdmin') !== -1;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <>
                <h2>{this.state.isEditPage ? "Edit": "Add New"} Calendar Page</h2>
                <CalendarSteps initialValues={this.state} salesRepListData={this.props.salesRepListData} handleCalendarFinalSubmission={this.handleSubmit} fallBackCal={fallBackCal} hasCalendarAdminAccess={hasCalendarAdminAccess} />
            </>
        );
    }
}

const UpdatePage = Form.create()(PageForm);

UpdatePage.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    curPage: PropTypes.object.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchPageRequest: PropTypes.func.isRequired,
    fetchSalesRepsListRequest: PropTypes.func.isRequired,
    updatePageRequest: PropTypes.func.isRequired,
    addPageRequest: PropTypes.func.isRequired,
    reqFallBackCal: PropTypes.func.isRequired
};

export default UpdatePage;

import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, Popconfirm, Icon, message , Tag} from 'antd';
import { PageHeaderContainer, TableSkeleton } from "../../components";
import { getIsPageActionAccessible } from "../../utils";
import lookup from "./lookup";

class List extends Component {
    constructor(props) {
        super(props);
        this.pageKey = "featureResource";
        this.resourceTypeMapping = {};
        this.isReadonly = !getIsPageActionAccessible(this.pageKey, ((props.userData && props.userData.privileges) || []));
    }

    componentDidMount() {
        this.props.reqFeatureResource("all");

        if (_.isEmpty(this.resourceTypeMapping)) {
            const allResource = lookup.resource;

            for(const resource of allResource) {
                this.resourceTypeMapping[resource.value] = resource.label;
            }
        }
    }

    handleFeatureRemoveOnClick = (e, data) => {
        e.preventDefault();
        this.props.reqDeleteFeatureResource({ data: { type: "all", id: data.id }, cb: this.handleActionResponse });
    };

    handleFeatureEditOnClick = (e, data) => {
        e.preventDefault();
        this.props.history.push({
            pathname: "/feature-resource/manage",
            state: data,
        })
    }

    handleActionResponse = (response) => {
        message[response.type](response.message);
    }

    mapData2Table = () => {
        const allData = this.props.featureResource && this.props.featureResource.hasOwnProperty("all") && this.props.featureResource.all;
        if (_.isEmpty(allData))  return [];

        return allData.map(data => {
            return {
                rawTarget: data.target_resource_type,
                target: <Tag color="#2db7f5" style={{ minWidth: "70%" }}>{ this.resourceTypeMapping.hasOwnProperty(data.target_resource_type) ? this.resourceTypeMapping[data.target_resource_type] : "Main" }</Tag>,
                rawFeature: data.feature_resource_type,
                feature: <Tag style={{ minWidth: "70%" }}>{ this.resourceTypeMapping.hasOwnProperty(data.feature_resource_type) ? this.resourceTypeMapping[data.feature_resource_type] : "N/A" }</Tag>,
                heading: data.feature_resource_json.heading,
                action: (
                    <React.Fragment>
                        <Button size="small" icon="edit" type="link" onClick={(e) => {this.handleFeatureEditOnClick(e, data)}} />
                        <Popconfirm title="Do you want to remove this from featured resource?" icon={<Icon type="delete"/>} okText="Yes" cancelText="No" placement="left" onConfirm={(e) => {this.handleFeatureRemoveOnClick(e, data)}}>
                            <Button size="small" icon="delete" type="link" />
                        </Popconfirm>
                    </React.Fragment>
                ),
                featureResourceData: data.feature_resource_json,
            };
        });
    }

    renderExpandedRow = (row) => {
        const featureResourceData = row.featureResourceData || {};
        const link = process.env.REACT_APP_WEBSITE_URL + featureResourceData.link.replace("/", "");
        return (
            <ul style={{ padding: 0 }}>
                <li style={{ margin: "0 0 10px 0" }}><strong>Description</strong>: {featureResourceData.description || "-"}</li>
                <li style={{ margin: "0 0 10px 0" }}><strong>Link</strong>: {featureResourceData.link ? <a target="_blank" rel="noopener noreferrer" href={link}>{ link }</a> : "-"}</li>
                {
                    featureResourceData.image && <li style={{ margin: "0 0 10px 0" }}><img src={featureResourceData.image} style={{ height: "100px" }} /></li>
                }
            </ul>
        );
    }

    render () {
        return (
            <React.Fragment>
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    dataSource={this.mapData2Table()}
                    columns={lookup.tableCols.filter((col) => (this.isReadonly ? col.key !== "action" : true))}
                    loading={this.props.isLoading}
                    expandedRowRender={this.renderExpandedRow}
                />
            </React.Fragment>
        );
    }
};

List.propTypes = {
    reqFeatureResource: PropTypes.func.isRequired,
    reqDeleteFeatureResource: PropTypes.func.isRequired
};

export default List;
import { takeLatest, call, put } from "redux-saga/effects";
import GmbActions, * as actionCreator from "./actions";
import gmbApi from "./http";

const gmbSagas = [
    takeLatest( GmbActions.GET_GMB_REQUEST, getGmb)
];

function* getGmb(action) {
    try {
        const resp = yield call(gmbApi.getGmb, action.payload.data);
        yield put(actionCreator.getGmbSuccess(resp.data));
        if(action.payload.cb){
            yield call(action.payload.cb, {...resp.data});
        }
    } catch (e) {
        yield put(
            actionCreator.gmbError(e.data.data)
        );
        yield call(action.payload.cb, {...e.data.data, "status": "error"});
    }
}

export default gmbSagas;

import { connect } from "react-redux";

import {
    fetchResourcesListRequest,
    fetchResourceRequest,
    addResourceRequest,
    updateResourceRequest,
    deleteResourceRequest,
    fetchCaseStudyRequest,
    fetchCaseStudyBlockRequest,
    addCaseStudyBlockRequest,
    updateCaseStudyBlockRequest,
    deleteRCaseStudyBlockRequest,
    insertCaseStudyBlockRequest,
    fetchIntegrationListRequest, 
    fetchCompetitorListRequest,
    fetchVideo,
    fetchVideoSuccess,
    fetchVideoList,
    fetchVideoListSuccess,
    addVideo,
    addVideoSuccess,
    updateVideo,
    updateVideoSuccess,
    deleteVideo,
    deleteVideoSuccess,
    fetchSlugOccurenceRequest,
    fetchVideoTutorialsList,
    fetchVideoTutorialsListSuccess,
    fetchVideoTutorial,
    fetchVideoTutorialSuccess,
    addVideoTutorial,
    addVideoTutorialSuccess,
    deleteVideoTutorial,
    deleteVideoTutorialSuccess,
    updateVideoTutorial,
    updateVideoTutorialSuccess
} from "./actions";

import CaseStudy from "./CaseStudies/CaseStudyBlock/CaseStudy";
import UpdateCaseStudyBlock from "./CaseStudies/CaseStudyBlock/UpdateCaseStudyBlock";
import CaseStudyList from "./CaseStudies/CaseStudyList";
import VideoList from "./Videos/VideoList";
import UpdateCaseStudy from "./CaseStudies/UpdateCaseStudy";
import UpdateVideo from "./Videos/UpdateVideo";
import VideoTutorialsList from "./VideoTutorials/VideoTutorialsList";
import UpdateVideoTutorial from "./VideoTutorials/UpdateVideoTutorial";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.Resources
});

export const CaseStudyPage = connect(
    mapStateToProps,
    { fetchResourceRequest, fetchCaseStudyRequest, deleteRCaseStudyBlockRequest, updateResourceRequest }
)(CaseStudy);

export const UpdateCaseStudyBlockPage = connect(
    mapStateToProps,
    { fetchCaseStudyBlockRequest, addCaseStudyBlockRequest, updateCaseStudyBlockRequest, insertCaseStudyBlockRequest }
)(UpdateCaseStudyBlock);

export const CaseStudyListPage = connect(
    mapStateToProps,
    { fetchResourcesListRequest, deleteResourceRequest, updateResourceRequest }
)(CaseStudyList);

export const VideoListPage = connect(
    mapStateToProps,
    { 
        fetchVideoList,
        fetchVideoListSuccess,
        deleteVideo,
        deleteVideoSuccess,
        updateVideo,
        updateVideoSuccess
    }
)(VideoList);

export const VideoTutorialsListPage = connect(
    mapStateToProps,
    { 
        fetchVideoTutorialsList,
        fetchVideoTutorialsListSuccess,
        deleteVideoTutorial,
        deleteVideoTutorialSuccess,
        updateVideoTutorial,
        updateVideoTutorialSuccess
    }
)(VideoTutorialsList);

export const UpdateVideoPage = connect(
    mapStateToProps,
    { 
        fetchVideo,
        fetchVideoSuccess,
        addVideo,
        addVideoSuccess,
        updateVideo,
        updateVideoSuccess,
        fetchSlugOccurenceRequest
    }
)(UpdateVideo);

export const UpdateVideoTutorialPage = connect(
    mapStateToProps,
    { 
        fetchVideoTutorial,
        fetchVideoTutorialSuccess,
        addVideoTutorial,
        addVideoTutorialSuccess,
        updateVideoTutorial,
        updateVideoTutorialSuccess,
        fetchSlugOccurenceRequest
    }
)(UpdateVideoTutorial);

export const UpdateCaseStudyPage = connect(
    mapStateToProps,
    { fetchResourceRequest, addResourceRequest, updateResourceRequest, 
        fetchIntegrationListRequest, fetchCompetitorListRequest, fetchSlugOccurenceRequest }
)(UpdateCaseStudy);



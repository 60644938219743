import sidebar from "../../containers/SideBar/sideBar.json";
import lodash from "lodash";

export const getAllTabPermission = () => {
    return lodash.flatten(sidebar.menuList.map((i) => ([i.permission, `${i.permission}+`])));
};

export const getTabPermission = (permission) => {
    return lodash.intersection(permission, getAllTabPermission());
}

export const getOtherPermission = (permission) => {
    return lodash.difference(permission, getAllTabPermission());
}

export const getOtherPermissionSelectList = (permission) => {
    const tabList = getAllTabPermission();
    return permission.filter(p => !tabList.includes(p.name));
}

export const getPermissions = (permissions, allPermissionList) => {
    const permissionMapper = allPermissionList.reduce((acc, permission) => {
        acc[permission.name] = permission.id;
        return acc;
    }, {});
    return permissions.map(permission => permissionMapper[permission]).filter(v => (v));
}

export const getPermissionToDisplay = (permissions, allPermissionList) => {
    const displayPermission = [];
    
    permissions.map(permission => {
        const hasAdvancePermission = lodash.includes(permissions, `${permission}+`);
        const permissionMeta = allPermissionList.filter(v => (v.name === (hasAdvancePermission ? `${permission}+` : permission)))[0];
        if (permissionMeta && permissionMeta.label) displayPermission.push(permissionMeta.label);
    });

    return lodash.uniq(displayPermission);
}

import React, {Component} from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Icon, Radio, Upload } from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";
import { uploadUtility } from "../../utils";
import lookup from "./constants";

const { Item: FormItem } =  Form;

class HighlightsForm extends Component {
    constructor( props ) {
        super(props);

        this.itemId = 0;
        this.defaultTitle = lookup.highlights.defaultTitle[props.type] || "";
        this.imageResolutions = lookup.highlights.imageResolutions || [];
        this.timestamp = `${Math.floor(0x100000000 * Math.random())}_${Date.now().toString()}`;
    }

    handleAlignmentChange = (e) => {
        e.preventDefault();
        this.props.form.setFieldsValue({ itemIds: [++this.itemId] });
    }

    handleFormSubmission = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            if (error) {
                return;
            }

            const { items, image={} } = values;
            let imagesWithUrls = {};

            values.items = items.filter((item) => (!!item));
            delete(values.itemIds);

            for (const resolution in image) {

                if (image.hasOwnProperty(resolution)) {
                    imagesWithUrls[resolution] = uploadUtility.singleFile.submittedValue(image[resolution]);
                }
            }

            values.image = imagesWithUrls;

            this.props.handleStepFormSubmission({ highlights: {...values} });
        });
    }

    handleFormSkip = (e) => {
        e.preventDefault();

        this.props.handleStepFormSubmission({ highlights: {} });
    }

    handleItemFieldAdd = (e) => {
        e.preventDefault();
        const itemIds = this.props.form.getFieldValue("itemIds");

        this.props.form.setFieldsValue({itemIds: itemIds.concat( ++this.itemId )});
    }

    handleItemFieldRemove = (removeItemId) => {
        const itemIds = this.props.form.getFieldValue("itemIds");

        if (itemIds.length === 1) {
            return;
        }

        this.props.form.setFieldsValue({ itemIds: itemIds.filter(itemId => itemId !== removeItemId)});
    }

    render() {

        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { highlights={} } = this.props.initialValues;
        const { title = this.defaultTitle, align: initialValueOfAlignment=false, items=[{heading: "", desc: ""}], image = {} } = highlights;
        getFieldDecorator("itemIds", { initialValue: items.map((item, index) => (++this.itemId && index)) });

        const { itemIds, align=initialValueOfAlignment } = getFieldsValue();
        const isHeadingRequired = align;
        return (
            <>
                <Form onSubmit={this.handleFormSubmission}>
                    <FormItem {...formItemLayout} label={"Title"} extra={<p>Title related to resource which attract audience. Ex: <strong>Free Webinar</strong>, <strong>Free ebook</strong></p>}>
                        {
                            getFieldDecorator("title", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable title related to resource"
                                }],
                                initialValue: title
                            }) (
                                <Input placeholder={"Title"}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Alignment"} extra={<p>Based on the design there are only above listed alignments</p>}>
                        {
                            getFieldDecorator("align", {
                                rules: [{
                                    required: true,
                                    message: "Provide a suitable tag related to resource"
                                }],
                                initialValue: align
                            }) (
                                <Radio.Group options={lookup.highlights.withHeading || []} placeholder={"Resource tag"} onChange={this.handleAlignmentChange}/>
                            )
                         }
                    </FormItem>
                    {
                        this.imageResolutions.map((resolution, index) => {
                            const { key, pd } = resolution;
                            const imageUrl = image[key];

                            return (
                                <FormItem key={index} {...formItemLayout} label={`Highlights Image ${pd}`}>
                                    {
                                        getFieldDecorator(`image[${key}]`, {
                                            getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                            rules: [{
                                                required: false,
                                                message: `Upload a highlights ${key} image`
                                            }, {
                                                validator: uploadUtility.singleFile.validator
                                            }],
                                            valuePropName: "fileList",
                                            initialValue: imageUrl && [{
                                                uid: "-1",
                                                name: `${title} ${pd}`,
                                                status: "done",
                                                url: imageUrl
                                            }],
                                        })(
                                            <Upload
                                                name={"files"}
                                                accept={"image/*"}
                                                action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=highlights-image-${this.timestamp}&pd=${pd}&webp=true&excludeTimestamp=true`}
                                                withCredentials
                                                listType="picture"
                                                >
                                                <Button icon={"upload"}>Highlights image {pd}</Button>
                                            </Upload>
                                        )
                                    }
                                </FormItem>
                            )
                        })
                    }
                    {
                        itemIds && itemIds.map((itemId, index) => {
                            const { heading, desc } = items.hasOwnProperty(itemId) ? items[itemId] : { heading: "", desc: "" };

                            return (
                                <>
                                    {
                                        isHeadingRequired && (
                                            <FormItem {...(index === 0 ? formItemLayout : formButtonLayout)} label={index === 0 ? "Highlights" : ""} key={`header-${index}`}>
                                                {
                                                    getFieldDecorator(`items[${itemId}][heading]`, {
                                                        rules: [{
                                                            required: true,
                                                            message: "Provide a suitable heading to item"
                                                        }],
                                                        initialValue: heading
                                                    }) (
                                                        <Input placeholder={"Heading"} />
                                                    )
                                                }
                                            </FormItem>
                                        )
                                    }
                                    <FormItem {...((index === 0 && !isHeadingRequired) ? formItemLayout : formButtonLayout)} label={(index === 0 && !isHeadingRequired) ? "Highlights" : ""} key={`description-${index}`} extra={(itemIds.length > 1) && <Button key={`remove-btn-${index}`} style={{float: "right"}} type={"link"} onClick={(e) => {e.preventDefault(); this.handleItemFieldRemove(itemId)}}>Click to remove this item</Button>}>
                                        {
                                            getFieldDecorator(`items[${itemId}][desc]`, {
                                                rules: [{
                                                    required: true,
                                                    message: "Provide a suitable description to item"
                                                }],
                                                initialValue: desc
                                            }) (
                                                <Input placeholder={"Description"}/>
                                            )
                                        }
                                    </FormItem>
                                </>
                            )
                        })
                    }
                    <FormItem {...formButtonLayout}>
                        <Button type="dashed" onClick={this.handleItemFieldAdd} style={{ width: '44%' }}>
                            <Icon type="plus" /> Add highlight
                        </Button>
                    </FormItem>
                    <FormItem {...formButtonLayout}>
                        <Button type={"primary"} htmlType={"submit"}>
                            Next<Icon type="right" />
                        </Button>
                        <Button htmlType={"button"} type={"default"} style={{ marginLeft: "20px" }} onClick={this.handleFormSkip}>
                            Skip <Icon type="stop" />
                        </Button>
                    </FormItem>
                </Form>
            </>
        );
    }
}

const Highlights = Form.create()(HighlightsForm);

Highlights.propTypes = {
    userData: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    additional: PropTypes.object,
    handleStepFormSubmission: PropTypes.func.isRequired
};

export default Highlights;
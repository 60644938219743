import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { message, Switch, Divider } from "antd";
import { TableSkeleton } from "../../../components";
import lookup from "./constants";
import moment from "moment";
import { PageHeaderContainer } from "../../../components";
import { getIsPageActionAccessible } from "../../../utils";

class ArticleList extends Component {
    constructor(props) {
        super();
        this.pageKey = "articleList";

        this.state = {
            notification: props.notification
        };

        this.isReadonly = !getIsPageActionAccessible(this.pageKey, ((props.userData && props.userData.privileges) || []));
    }

    componentDidMount() {
        this.props.fetchArticleListRequest();
    }

    handleNewArticleClick = (e) => {
        e.preventDefault();
        this.props.history.push("/resource/article/new");
    }

    handleStatusChange = (articleId, value) => {
        const article = this.props.articleList.filter(d => (d.id === articleId))[0];
        const data = {
            ...article,
            updated_by: this.props.userData.name,
            showInList: value
        };
        this.props.updateArticleRequest({articleId, data, cb: this.handleArticleUpdateResponse});
    }

    handleArticleUpdateResponse = (response) => {
        message[response.type](response.message);
        this.props.fetchArticleListRequest();
    }

    mapListToDataSource = (data) => {

        return data.map((ebook, index) => {
            const {id, card_heading, link, showInList, created_at, created_by, updated_at, updated_by, banner} = ebook;

            return {
                key: index,
                title: card_heading,
                status: (
                    <Switch checkedChildren={"Public"} unCheckedChildren={"Private"} style={{width: "70px"}} defaultChecked={showInList} onChange={(v) => (this.handleStatusChange(id, v))} disabled={this.isReadonly}/>
                ),
                createdAt: created_at && moment(created_at).format("MMMM Do, YYYY HH:mm z"),
                updatedAt: updated_at && moment(updated_at).format("MMMM Do, YYYY HH:mm z"),
                actions: (
                    <>
                        {
                            link &&
                            <>
                                <a target={"_blank"} href={`${process.env.REACT_APP_WEBSITE_URL}${link.replace(/^\/+/g, '')}`}>Website URL</a>

                            </>
                        }
                        {
                            !this.isReadonly &&
                            <>
                                <Divider type="vertical" />
                                <Link to={`/resource/article/${id}`} style={{width: "100%"}}>Edit</Link>
                            </>
                        }
                    </>
                )
            }
        });
    };

    render() {
        const { userData, isLoading } = this.props;
        const unauthorized = !userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton loading={isLoading} columns={lookup.listingTableColumns} dataSource={this.mapListToDataSource(this.props.articleList)} expandedRowRender={this.renderDetails} pageSize={10} showSizeChanger={false} />
            </div>
        )
    }
}

export default ArticleList;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";
import { mapSourceIdToName } from "../../constants";
import { getIsPageActionAccessible } from "../../utils";
import lookup from "./categories.json";

class Categories extends Component {

    pageKey = "categoriesMapping";

    componentDidMount() {
        const { sourceId } = this.props.match.params;
        this.props.fetchCategoriesListBySource({ sourceId });
    }

    componentDidUpdate(prevProps) {
        const { sourceId } = this.props.match.params;
        if (sourceId !== prevProps.match.params.sourceId) {
            this.props.fetchCategoriesListBySource({ sourceId });
        }
    }

    formatData = (data) => {
        if(data){
            return data.map((record, i) => {
                return {
                    ...record,
                    key: `${record.beSubCategoryId}-${i}`,
                    id: record.name // Use name as id for creating edit links
                };
            });
        } else {
            return;
        }
    };

    renderExpandedRow = (record) => (
        <div>
            <div className="mb1">
                <b>Main Category ID: </b>
                <span>{record.beParentCategoryId}</span>
            </div>
            <div className="mb1">
                <b>Sub Category ID: </b>
                <span>{record.beSubCategoryId}</span>
            </div>
        </div>
    );

    render() {
        const { data, isLoading, error, userData, match } = this.props;
        const { sourceId } = match.params;

        const editUrl = '/categories/' + sourceId + '/';
        const unauthorized = error === "unauthorized" || !userData.name;
        const isActionAccessible = getIsPageActionAccessible(this.pageKey, userData.privileges);
        const withUpdatePrivilege = isActionAccessible;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="categories-root">
                <PageHeaderContainer pageKey={this.pageKey} title={`${mapSourceIdToName[sourceId]} Categories List`} />
                <TableSkeleton
                    columns={lookup.columns}
                    pathForEdit= {withUpdatePrivilege ? editUrl : ""}
                    dataSource={this.formatData(data)}
                    expandedRowRender={this.renderExpandedRow}
                    loading={isLoading}
                />
            </div>
        );
    }
}

Categories.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchCategoriesListBySource: PropTypes.func.isRequired,
};

export default Categories;

import React from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";

import { SideBar } from "../index";

const { Content } = Layout;

const Page = ({ children, history }) => {
    let path = window.location.href;
    history.listen((location, action) => {
        path = location.href;
    });

    return (
        <Layout style={{ height: "calc(100vh - 65px)", width: "100%" }}>
            <SideBar path={path} />
            <Layout style={{ padding: "20px 40px", background: "#fff" }}>
                <Content>{children}</Content>
            </Layout>
        </Layout>
    );
};

Page.propTypes = {
    children: PropTypes.node
};

export default Page;

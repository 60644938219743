export const isSameRecord = (r1, r2) => {
  const id1 = r1.id;
  const id2 = r2.id;
  return id1 === id2;
};

export const getRecordCount = (records, targetProfile) => {
  const recordCount = { lead: 0, opportunity: 0, customer: 0 };

  let hasTargetProfile = targetProfile && Object.keys(targetProfile).length;
  for (let record of records) {
    if (hasTargetProfile && isSameRecord(record, targetProfile)) continue;
    if (record.type) recordCount[record.type]++;
  }

  if (hasTargetProfile) recordCount[targetProfile.type]++;

  return recordCount;
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import TableSkeleton from "../TableSkeleton";
import moment from "moment-timezone";
import {Icon, Popconfirm, Modal, Tag, Button, Descriptions, Skeleton, Divider, Row, Col} from "antd";
import {salesforceBaseUrl} from "../../constants";
import lookupResource from "./../../pages/Resources/resources.json";

import "./style.scss";
import lookup from "./constants.js";
import { decodeResourceClassification } from "../../utils";

const { Item: DescriptionsItem } = Descriptions;

class WebinarEvents extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        showUsersModal: false,
        activeWebinarInfo: {},
        tableCols: []
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.isWebinarPage) {
            return {
                ...prevState,
                tableCols: !nextProps.isActionAccessbible ? lookup.meetingsTableColumns.filter((v) => (v.key !== 'action')) : lookup.meetingsTableColumns
            }
        } else {
            return {
                ...prevState,
                tableCols: !nextProps.isActionAccessbible ? lookup.tableColumns.filter((v) => (v.key !== 'action')) : lookup.tableColumns
            }
        }
    }

    formatTimes = (times, timeZone) => {
        if (!(times && times.length)) {
            return (
                <></>
            );
        }
        
        return times.map((time, index) => {
            const 
                {startTime: startTimestamp, endTime: endTimestamp} = time,
                startDate = moment.tz(startTimestamp, timeZone).format('MMMM Do YYYY'),
                startTime = moment.tz(startTimestamp, timeZone).format('h:mm a'),
                endTime = moment.tz(endTimestamp, timeZone).format('h:mm a');
    
            return (
                <div className={"session"} key={`time-${index}`}>
                    <p><Icon type="calendar" /> {startDate}</p>
                    <p><Icon type="dashboard" /> {startTime} - {endTime}<br/><small>{timeZone}</small></p>
                </div>
            );
        });
    };

    fetchUsers = ({eventId, webinarKey, eventTitle, createSfdcLead, attendanceFileUrl, eventExperience}) => {
        if (eventExperience === "recurring") return;
        if (this.props.isUpcomingEvent) {
            this.props.reqWebinarRegistrants({webinarKey});
        } else {
            this.props.reqWebinarAttendees({eventId});
        }
        this.setState({activeWebinarInfo: {eventId, webinarKey, eventTitle, createSfdcLead, attendanceFileUrl}}, this.toggleUsersModal);
    };

    toggleUsersModal = () => {
        const showUsersModal = this.state.showUsersModal;
        this.setState({
            showUsersModal: !showUsersModal
        });
    };

    renderModal = () => {
        const userType = this.getUserType();
        const {isUpcomingEvent, isLoadingUsers, fetchUsersFailed, registrants, attendance, refreshWebinarAttendance, isLoading} = this.props;
        const {activeWebinarInfo: {eventId, eventTitle, attendanceFileUrl}, modalRefreshLoading} = this.state;
        const totalRegistrants = isUpcomingEvent ? (registrants ? registrants.length : '-') : (attendance ? attendance.length : '-');
        const totalAttendees = !isUpcomingEvent && attendance && attendance.filter(({isAttendee}) => (isAttendee)).length;

        return (
            <Modal
                title={`${userType} | ${eventTitle}`}
                visible={this.state.showUsersModal}
                footer={null}
                onCancel={this.toggleUsersModal}
                mask={true}
                width={"98%"}
                className={"webinarUserInfo"}
            >
                {
                    fetchUsersFailed ? <p>Failed to process request. Please retry.</p> : (
                        <div>
                            <Skeleton loading={isLoadingUsers} active>
                                {
                                    isUpcomingEvent ? (
                                        <>
                                            <Descriptions>
                                                <DescriptionsItem label={"Total Registrants"}>{totalRegistrants}</DescriptionsItem>
                                            </Descriptions>
                                            <TableSkeleton loading={isLoadingUsers} columns={lookup.registrantsTableColumns} dataSource={this.mapUsersListToDataSource(registrants)} pageSize={5} showSizeChanger={false} pageSizeOptions={['5']}/>
                                        </>
                                    ) : (
                                        <>
                                            <div style={{display: "inline-flex"}}>
                                                <Descriptions>
                                                    <DescriptionsItem label={"Total Registrants"}>{totalRegistrants}</DescriptionsItem>
                                                    <DescriptionsItem label={"Total Attendees"}>{totalAttendees}</DescriptionsItem>
                                                </Descriptions>
                                                {attendanceFileUrl && <Button type={"primary"} title={"Click to download attendance"} onClick={() => (window.open(attendanceFileUrl))}>Download</Button>}
                                                {eventId && <Button loading={isLoading} style={{marginLeft: "10px"}} type={"primary"} title={"Click to refresh list"} onClick={() => {refreshWebinarAttendance({ eventId })}}>Refresh</Button>}
                                            </div>
                                            <TableSkeleton loading={isLoadingUsers} columns={lookup.attendanceTableColumns} dataSource={this.mapAttendanceToDataSource(attendance)} pageSize={4} showSizeChanger={false} pageSizeOptions={['5']}/>
                                        </>
                                    )
                                }
                            </Skeleton>
                        </div>
                    )
                }
            </Modal>
        );
    };

    getUserType = () => {
        return this.props.isUpcomingEvent ? "Registrants" : "Attendance";
    };
    
    renderAction = ({eventId, resourceId, webinarKey, eventTitle, meetingId, organiserEmailId, calendarId, resourceLink, createSfdcLead, attendanceFileUrl, eventExperience, hostPlatform}) => {
        const 
            {isUpcomingEvent, reqEventDelete, refreshWebinarAttendance, isWebinarPage} = this.props;

        if (isWebinarPage) {
            if (isUpcomingEvent) {
                if (eventExperience === "recurring" || hostPlatform === 'goldcast'){
                    return (
                        <>
                            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                <Col span={3}>
                                    <a href={`/#/webinar/schedule/${eventId}`} title={"Click to edit"} style={{ fontSize: "12px", display: "inline-block", width: "100%", textAlign: "right" }}>Edit</a>
                                </Col>
                                <Col span={1}><Divider type="vertical" /></Col>
                                <Col>
                                    <a target="_blank" href={`${process.env.REACT_APP_WEBSITE_URL}${resourceLink.replace("/", "")}`} title="Visit website" style={{ fontSize: "12px", display: "inline-block", width: "100%", textAlign: "center" }}>Website URL</a>
                                </Col>
                                <Col span={1}><Divider type="vertical" /></Col>
                                <Col span={3}>
                                    <Popconfirm title={"Are you sure delete this event?"} onConfirm={() => {reqEventDelete({eventId, resourceId, webinarKey, eventExperience, hostPlatform})}} okText={"Yes"} cancelText={"No"} placement={"left"} icon={<Icon type="delete" style={{color: "red"}}/>}>
                                        <a title={"Click to delete"} style={{ fontSize: "12px", display: "inline-block", width: "100%", textAlign: "left" }}>Delete</a>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        </>
                    );
                }
                return (
                    <>
                        <Row>
                            <Col span={12}>
                                <a title={"Click to view registrants"} onClick={() => (this.fetchUsers({webinarKey, eventTitle, eventExperience}))} style={{ fontSize: "12px", display: "inline-block", width: "100%", textAlign: "right",  }}>Registrants</a>
                            </Col>
                            <Col span={2}><Divider type="vertical" /></Col>
                            <Col span={8}>
                                <a href={`/#/webinar/schedule/${eventId}`} title={"Click to edit"} style={{ fontSize: "12px", display: "inline-block", width: "100%", textAlign: "left" }}>Edit</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <a target="_blank" href={`${process.env.REACT_APP_WEBSITE_URL}${resourceLink.replace("/", "")}`} title="Visit website" style={{ fontSize: "12px", display: "inline-block", width: "100%", textAlign: "right" }}>Website URL</a>
                            </Col>
                            <Col span={2}><Divider type="vertical" /></Col>
                            <Col span={8}>
                                <Popconfirm title={"Are you sure delete this event?"} onConfirm={() => {reqEventDelete({eventId, resourceId, webinarKey, eventExperience})}} okText={"Yes"} cancelText={"No"} placement={"left"} icon={<Icon type="delete" style={{color: "red"}}/>}>
                                    <a title={"Click to delete"} style={{ fontSize: "12px", display: "inline-block", width: "100%", textAlign: "left" }}>Delete</a>
                                </Popconfirm>
                            </Col>
                        </Row>
                    </>
                );
            } else {
                if (eventExperience === "recurring" || hostPlatform === 'goldcast'){
                    return (
                        <>
                            <a target="_blank" href={`${process.env.REACT_APP_WEBSITE_URL}${resourceLink.replace("/", "")}`} title="Visit website" style={{ fontSize: "12px" }}>Website URL</a>
                            <Divider type="vertical" />
                            <a href={`/#/webinar/schedule/${eventId}`} title={"Click to edit"} style={{ fontSize: "12px" }}>Edit</a>
                        </>
                    );
                }
                return (
                    <>
                        <a title={"Click to view attendance"} onClick={() => (this.fetchUsers({eventId, eventTitle, createSfdcLead, attendanceFileUrl}))} style={{ fontSize: "12px" }}>Attendance</a>
                        <Divider type="vertical" />
                        <a target="_blank" href={`${process.env.REACT_APP_WEBSITE_URL}${resourceLink.replace("/", "")}`} title="Visit website" style={{ fontSize: "12px" }}>Website URL</a>
                        <Divider type="vertical" />
                        <a href={`/#/webinar/schedule/${eventId}`} title={"Click to edit"} style={{ fontSize: "12px" }}>Edit</a>
                    </>
                );
            }
        } else {
            const link = resourceLink.replace(/\//g, "!(--)");
            return (
                <>
                    <a target="_blank" href={`${process.env.REACT_APP_WEBSITE_URL}${resourceLink.replace("/", "")}`} title="Visit website" style={{ fontSize: "12px" }}>Website URL</a>
                    <Divider type="vertical" />
                    <a href={`/#/meeting/schedule/${eventId}`} title={"Click to edit"} style={{ fontSize: "12px" }}>Edit</a>
                    {
                        !isUpcomingEvent &&
                        <>
                            <Divider type="vertical" />
                            <Popconfirm title={"Are you sure delete this meeting?"} onConfirm={() => {reqEventDelete({eventId, resourceId, meetingId, organiserEmailId, calendarId, link})}} okText={"Yes"} cancelText={"No"} placement={"left"} icon={<Icon type="delete" style={{color: "red"}}/>}>
                                <a title={"Click to delete"} style={{ fontSize: "12px" }}>Delete</a>
                            </Popconfirm>
                        </>
                    }
                </>
            )
        }
    };

    renderInformation = ({eventExperience}) => {
        return (
            <>
            {this.renderEventExperience(eventExperience)}
            </>
        )
    }

    renderEventExperience = (eventExperience) => {
        return (
            <>
                <Tag style={{ margin: "0 0 5px", width: "50%" }}>
                    {(() => {
                        switch (eventExperience) {
                            case 'classic':
                            case 'fav':
                                return <span>Live<Icon type="audio" style={{marginLeft: "10%"}} /></span>;
                            case 'recurring':
                                return <span>Recurring<Icon type="retweet" style={{marginLeft: "10%"}} /></span>;
                            default:
                                return <span>Simu-live<Icon type="youtube" style={{marginLeft: "10%"}} /></span>;
                        }
                    })()}
                </Tag><br/>
            </>
        );
    };
    
    mapEventsListToDataSource = (eventsList) => {
        if (!(eventsList && eventsList.length)) {
            return [];
        }
    
        return eventsList.map((event, index) => {
            const 
                {eventId, resourceId, webinarKey, meetingId, organiserEmailId, calendarId, resourceLink, eventTitle, eventTimezone, times, attendanceFileUrl, createSfdcLead, eventExperience, lastUpdatedAt, hostPlatform} = event,
                pasedTimes = times;
    
            return {
                ...event,
                key: index,
                eventTitle,
                eventTimezone,
                times: this.formatTimes(pasedTimes, eventTimezone),
                time: pasedTimes[0] && pasedTimes[0].startTime,
                lastUpdated: moment(lastUpdatedAt).format("YYYY-MM-DD"),
                action: (this.renderAction({eventId, resourceId, webinarKey, eventTitle, meetingId, organiserEmailId, calendarId, resourceLink, createSfdcLead, attendanceFileUrl, eventExperience, hostPlatform})),
                information: (this.renderInformation({eventId, resourceId, webinarKey, eventTitle, attendanceFileUrl, createSfdcLead, eventExperience})),
            }
        });
    };

    mapUsersListToDataSource = (usersList) => {
        if (!(usersList && usersList.length)) {
            return [];
        }

        return usersList.map((user, index) => {
            const
                {firstName, lastName, email, registrationDate, timeZone} = user;

            return {
                key: index,
                name: `${firstName} ${lastName}`,
                email,
                registrationDate: moment(registrationDate).tz(timeZone).format("MMMM Do, YYYY HH:mm z")
            }
        });
    };
    
    mapAttendanceToDataSource = (attendanceList) => {
        if (!attendanceList) {
            return [];
        }

        return attendanceList.map((user, index) => {
            const
                {fname, lname, isAttendee, objectId, objectType, status, objectOwner} = user;

            const keyObjectType = objectId ? objectType : "-";

            return {
                key: index,
                name: `${fname} ${lname}`,
                isAttendee,
                formattedIsAttendee: isAttendee ? <Tag color={"green"}>Present</Tag> : <Tag color={"red"}>Absent</Tag>,
                salesforceUrl: objectId && (<a href={`${salesforceBaseUrl}${objectId}`} target={"_blank"}>{objectId}</a>),
                formattedObjectType: keyObjectType !== "-" ? (<Tag color="#108ee9">{keyObjectType.toUpperCase()}</Tag>) : "-",
                keyObjectType,
                salesforceInfo: (
                    !objectId ? 
                        "-" : 
                        (
                            <>
                                <div><a href={`${salesforceBaseUrl}${objectId}`} target={"_blank"}>{objectId}</a></div>
                                {objectOwner && <div><small><strong>Owner:&nbsp;</strong>{objectOwner}</small></div>}
                                {status && <div><small><strong>{status}</strong></small></div>}
                            </>
                        )
                ),
                ...user
            }
        });
    }

    renderEventPresenter = (presenters) => {
        if (!presenters) {
            return <>N.A.</>;
        }
    
        return (
            <ul className={"presenters"}>
                {
                    presenters.map(({name, photo, designation}) => {
                        return (
                            <li className={"presenter"}>
                                <img className={"photo"} src={photo} alt={name}/> {name}, <small>{designation}</small>
                            </li>
                        )
                    })
                }
            </ul>
        )
    };
    
    getTimeDifferenceString = (time) => {
        const 
            diff = moment().diff(moment(time), 'seconds'),
            timeString = moment(time).format("MMMM Do YYYY, h:mm a");
    
        let timeDiffString = "";
    
        if (diff < 60) {
            timeDiffString =  "few seconds ago";
        } else if (diff < 3600) {
            const min = Math.floor(diff/60);
            timeDiffString = min === 1 ? `${min} minute ago` : `${min} minutes ago`;
        } else if (diff < 86400) {
            const hour = Math.floor(diff/3600);
            timeDiffString = hour === 1 ? `${hour} hour ago` : `${hour} hours ago`;
        } else {
            const day = Math.floor(diff/86400);
            timeDiffString = day === 1 ? `${day} day ago` : `${day} days ago`;
        }
    
        return <small title={timeString}>{timeDiffString}</small>;
    }
    
    renderEventDetails = (event) => {
        const 
            {eventPresenter, showInList, leadSfdcCampaign, classification} = event;
    
        let tableContent = [];

        const { isWebinarPage } = this.props;
        const { topics, industries } = decodeResourceClassification(classification, lookupResource, true);

        tableContent.push(
            {key: "listed", label: <b>Listed : </b>, value: <span>{showInList ? "Yes" : "No"}</span> },
            {key: "eventPresenter", label: <b>Panelist:</b>, value: this.renderEventPresenter(eventPresenter)},
            {
                key: "industries",
                label: <b>Industries : </b>,
                value: industries && industries.map((tag) => (
                        tag && <Tag color="blue" key={tag}>
                        {tag}
                    </Tag>
                ))
            },
            {
                key: "topics",
                label: <b>Topics : </b>,
                value: topics && topics.map((tag) => (
                    tag && <Tag color="blue" key={tag}>
                        {tag}
                    </Tag>
                ))
            }
        );

        if (isWebinarPage) {
            tableContent.push({
                key: "leadSfdcCampaign",
                label: <b>SFDC Campaign ID: </b>,
                value: <span>{leadSfdcCampaign}</span>
            });
        }

        return (
            <div className={"eventDetail"}>
                {
                    tableContent.map(({key, label, value}) => {
                        return (
                            <div className={"eventDetailRow"} key={`event-details-${key}`}><label className={"eventDetailLabel"}>{label}</label> {value}</div>
                        )
                    })
                }
            </div>
        );
    }

    render () {
        const 
            {isUpcomingEvent, isLoading, eventsList} = this.props;

        return (
            <>
                <div className={`eventsContainer ${isUpcomingEvent}`}>
                    <TableSkeleton loading={isLoading} columns={this.state.tableCols} dataSource={this.mapEventsListToDataSource(eventsList)} expandedRowRender={this.renderEventDetails} pageSize={5} showSizeChanger={false}/>
                </div>
                {
                    this.state.showUsersModal && this.renderModal()
                }
            </>
        );
    }
}

WebinarEvents.defaultProps = {
    isWebinarPage: true
};

WebinarEvents.propTypes = {
    isUpcomingEvent: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    eventsList: PropTypes.array.isRequired,
    reqEventDelete: PropTypes.func.isRequired,
    reqWebinarRegistrants: PropTypes.func,
    reqWebinarAttendees: PropTypes.func,
    refreshWebinarAttendance: PropTypes.func,
    isWebinarPage: PropTypes.bool
};

export default WebinarEvents;

import { ResourcesV2Actions } from "./actions";

const RESOURCESV2_INITAIL_STATE = {
    isLoading: false,
    addStatus: null,
    ebookList: [],
    ebook: {},
    whitepaperList: [],
    whitepaper: {},
    guideList: [],
    guide: {},
    articleList: [],
    article: {},
    podcast: {},
    podcastList: [],
    notification: {
        timestamp: 0
    },
    isError: false
};

const resourcesV2Reducer = (state = RESOURCESV2_INITAIL_STATE, action) => {

    switch (action.type) {
        case ResourcesV2Actions.FETCH_EBOOK_LIST_REQUEST:
        case ResourcesV2Actions.FETCH_WP_LIST_REQUEST:
        case ResourcesV2Actions.FETCH_GUIDE_LIST_REQUEST:
        case ResourcesV2Actions.FETCH_ARTICLE_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                article: {},
                ebook: {},
                whitepaper: {},
                guide: {},
            };
        case ResourcesV2Actions.FETCH_EBOOK_LIST_SUCCESS:
        case ResourcesV2Actions.FETCH_EBOOK_LIST_ERROR:
            return {
                ...state,
                isLoading: false,
                ebookList: action.payload
            };
        case ResourcesV2Actions.FETCH_WP_LIST_SUCCESS:
        case ResourcesV2Actions.FETCH_WP_LIST_ERROR:
            return {
                ...state,
                isLoading: false,
                whitepaperList: action.payload
            };
        case ResourcesV2Actions.FETCH_GUIDE_LIST_SUCCESS:
        case ResourcesV2Actions.FETCH_GUIDE_LIST_ERROR:
            return {
                ...state,
                isLoading: false,
                guideList: action.payload
            };
        case ResourcesV2Actions.FETCH_ARTICLE_LIST_SUCCESS:
        case ResourcesV2Actions.FETCH_ARTICLE_LIST_ERROR:
            return {
                ...state,
                isLoading: false,
                articleList: action.payload
            };
        case ResourcesV2Actions.FETCH_EBOOK_BYID_REQUEST:
        case ResourcesV2Actions.FETCH_WP_BYID_REQUEST:
        case ResourcesV2Actions.FETCH_GUIDE_BYID_REQUEST:
        case ResourcesV2Actions.FETCH_ARTICLE_BYID_REQUEST:
                return {
                    ...state,
                    isLoading: true,
                };
        case ResourcesV2Actions.FETCH_EBOOK_BYID_SUCCESS:
        case ResourcesV2Actions.FETCH_EBOOK_BYID_ERROR:
            return {
                ...state,
                isLoading: false,
                ebook: action.payload
            };
        case ResourcesV2Actions.FETCH_WP_BYID_SUCCESS:
        case ResourcesV2Actions.FETCH_WP_BYID_ERROR:
            return {
                ...state,
                isLoading: false,
                whitepaper: action.payload
            };
        case ResourcesV2Actions.FETCH_GUIDE_BYID_SUCCESS:
        case ResourcesV2Actions.FETCH_GUIDE_BYID_ERROR:
            return {
                ...state,
                isLoading: false,
                guide: action.payload
            };
        case ResourcesV2Actions.FETCH_ARTICLE_BYID_SUCCESS:
        case ResourcesV2Actions.FETCH_ARTICLE_BYID_ERROR:
            return {
                ...state,
                isLoading: false,
                article: action.payload
            };
        case ResourcesV2Actions.ADD_EBOOK_REQUEST:
        case ResourcesV2Actions.ADD_WP_REQUEST:
        case ResourcesV2Actions.ADD_GUIDE_REQUEST:
        case ResourcesV2Actions.ADD_ARTICLE_REQUEST:
            return {
                ...state,
                isLoading: false,
            };  
        case ResourcesV2Actions.ADD_EBOOK_SUCCESS:
        case ResourcesV2Actions.ADD_EBOOK_ERROR:
        case ResourcesV2Actions.ADD_WP_SUCCESS:
        case ResourcesV2Actions.ADD_WP_ERROR:
        case ResourcesV2Actions.ADD_GUIDE_SUCCESS:
        case ResourcesV2Actions.ADD_GUIDE_ERROR:
        case ResourcesV2Actions.ADD_ARTICLE_SUCCESS:
        case ResourcesV2Actions.ADD_ARTICLE_ERROR:
            return {
                ...state,
                isLoading: false,
                notification: action.payload
            };
        case ResourcesV2Actions.UPDATE_EBOOK_REQUEST:
        case ResourcesV2Actions.UPDATE_WP_REQUEST:
        case ResourcesV2Actions.UPDATE_GUIDE_REQUEST:
        case ResourcesV2Actions.UPDATE_ARTICLE_REQUEST:
                return {
                    ...state,
                    isLoading: true,
                };  
        case ResourcesV2Actions.UPDATE_EBOOK_SUCCESS:
        case ResourcesV2Actions.UPDATE_EBOOK_ERROR:
        case ResourcesV2Actions.UPDATE_WP_SUCCESS:
        case ResourcesV2Actions.UPDATE_WP_ERROR:
        case ResourcesV2Actions.UPDATE_GUIDE_SUCCESS:
        case ResourcesV2Actions.UPDATE_GUIDE_ERROR:
        case ResourcesV2Actions.UPDATE_ARTICLE_SUCCESS:
        case ResourcesV2Actions.UPDATE_ARTICLE_ERROR:
            return {
                ...state,
                isLoading: false,
                notification: action.payload
            };
        case ResourcesV2Actions.FETCH_PODCAST_LIST_REQUEST:
        case ResourcesV2Actions.FETCH_PODCAST_BYID_REQUEST:
            return {
                ...state,
                isLoading: true,
                podcast: {},
                podcastList: [],
            };
        case ResourcesV2Actions.ADD_PODCAST_REQUEST:
            return {
                ...state,
                isLoading: true,
                podcast: action.payload
            };
        case ResourcesV2Actions.UPDATE_PODCAST_REQUEST:
            return {
                ...state,
                isLoading: true,
                podcast: action.payload.data
            };
        case ResourcesV2Actions.DELETE_PODCAST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ResourcesV2Actions.ADD_PODCAST_SUCCESS:
        case ResourcesV2Actions.ADD_PODCAST_ERROR:
        case ResourcesV2Actions.UPDATE_PODCAST_SUCCESS:
        case ResourcesV2Actions.UPDATE_PODCAST_ERROR:
        case ResourcesV2Actions.DELETE_PODCAST_ERROR:
            return {
                ...state,
                isLoading: false,
                podcast: {}
            };
        case ResourcesV2Actions.DELETE_PODCAST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                podcastList: state.podcastList.filter(({ id }) => action.payload.id !== id)
            };
        case ResourcesV2Actions.FETCH_PODCAST_LIST_SUCCESS:
        case ResourcesV2Actions.FETCH_PODCAST_LIST_ERROR:
            return {
                ...state,
                isLoading: false,
                podcastList: action.payload
            };
        case ResourcesV2Actions.FETCH_PODCAST_BYID_SUCCESS:
        case ResourcesV2Actions.FETCH_PODCAST_BYID_ERROR:
            return {
                ...state,
                isLoading: false,
                podcast: action.payload
            }
        default:
            return state;
    }
};

export default resourcesV2Reducer;

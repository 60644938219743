import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tag, message } from "antd";
import { isEmpty } from "lodash";
import { getIsPageActionAccessible } from "../../utils";
import { getPermissionToDisplay } from "./utils";

import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";

import lookup from "./users.json";

class Users extends Component {

    pageKey = "userList";

    componentDidMount() {
        this.props.fetchUsersListRequest();
        this.props.fetchUserRolesListRequest();
        this.props.fetchPermissionsList();
    }

    formatData = (data) => {
        const { rolesList, permissionsList } = this.props;

        return data.map((record) => {
            const permissions = record.privileges ? record.privileges.split(",") : [];
            const roleMeta = rolesList.filter(r => (r.id === record.role))[0];
            const permissionForDisplay = getPermissionToDisplay(permissions, permissionsList);
            
            return {
                ...record,
                key: record.id,
                roleName: roleMeta ? <Tag color="#108ee9">{roleMeta.name}</Tag> : "N/A",
                permissionsForDisplay: !isEmpty(permissionForDisplay) && permissionForDisplay.map((p, i) => <Tag key={i} color="green">{p}</Tag>)
            };
        });
    };

    renderFeedbackMsg = (errorMsg) => {
        if (!errorMsg) {
            return message.success(
                "You have successfully deleted this record."
            );
        } else {
            return message.error(
                "Something went wrong... Please try again later."
            );
        }
    };

    render() {
        const { userListData, loadingUser, loadingRole, loadingPermission, userError, deleteUserRequest, userData } = this.props;

        const unauthorized = userError === "unauthorized";
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        const isActionAccessible = getIsPageActionAccessible(this.pageKey, userData.privileges);

        return (
            <div className="users-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    columns={lookup.columns}
                    pathForEdit={isActionAccessible ? "/user/" : ""}
                    dataSource={this.formatData(userListData)}
                    handleDelete={isActionAccessible && ((data) => (data && data.id && deleteUserRequest({id: data.id})))}
                    loading={loadingUser || loadingPermission || loadingRole}
                />
            </div>
        );
    }
}

Users.propTypes = {
    userListData: PropTypes.array.isRequired,
    loadingUser: PropTypes.bool.isRequired,
    userError: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchUsersListRequest: PropTypes.func.isRequired,
    deleteUserRequest: PropTypes.func.isRequired
};

export default Users;
import {beAPIResource} from "../../utils/apiHelper";

const businessApi = {
    getStates: (country) => beAPIResource.get(`support/freeprod/business/states/${country}`),
    getCountries: () => beAPIResource.get(`support/freeprod/business/countries`),
    getByBusinessNumber: (businessNumber) => beAPIResource.get(`support/freeprod/business/${businessNumber}`),
    updateBusiness: (payload) => beAPIResource.put(`support/freeprod/business/${payload.businessId}`, payload.data),
    findBusiness: (payload) => beAPIResource.post(`support/freeprod/business/find`, payload),
    getUsers: (businessNumber) => beAPIResource.get(`support/freeprod/business/users/${businessNumber}`),
    addUser: (payload) => beAPIResource.post(`support/freeprod/business/users/add/${payload.businessId}`, payload.data),
    deleteUser: (payload) => beAPIResource.post(`support/freeprod/business/users/remove/${payload.businessNumber}`, payload.data),
    getAggregation: (businessId) => beAPIResource.get(`support/freeprod/business/aggregation/${businessId}`),
    deleteAggregation: (payload) => beAPIResource.delete(`support/freeprod/business/aggregation/${payload.businessId}/${payload.aggregationId}`),
    aggregationSources: (businessId) => beAPIResource.get(`support/freeprod/business/aggregation/sources/${businessId}`),
    addAggregation: (payload) => beAPIResource.post(`support/freeprod/business/aggregation/add/${payload.businessId}`, payload.data),
    businessSignup: (payload) => beAPIResource.post(`support/freeprod/business/signup/`, payload),
    getBusinessWithGoogle: (payload) => beAPIResource.post(`support/freeprod/business/`, payload.data),
    fetchBusinessByEmailId: (payload) => beAPIResource.post(`support/freeprod/business/fetchBusinessByEmailId/`, payload),
    getAllCities: (payload) => beAPIResource.get(`support/freeprod/business/cities/${payload.country}/${payload.state}`),
    reaggregation: (payload) => beAPIResource.post(`support/freeprod/business/aggregation/reaggregation`, payload),
};
export default businessApi;
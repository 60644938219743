import { takeLatest, call, put } from "redux-saga/effects";
import UpsellActions, * as actionCreator from "./actions";
import UpsellAPI from "./http";

const UpsellSaga = [
    takeLatest(UpsellActions.FETCH_ALL_PRODUCT_MESSAGE_REQUEST, fetchAllProductMessage),
    takeLatest(UpsellActions.UPDATE_PRODUCT_MESSAGE_REQUEST, updateProductMessage),
    takeLatest(UpsellActions.DELETE_PRODUCT_MESSAGE_REQUEST, deleteProductMessageRequest),
    takeLatest(UpsellActions.UPDATE_PRODUCT_MESSAGE_INDUSTRY_IMAGE_REQUEST, updateProductMessageIndustryImageRequest)
];

function* fetchAllProductMessage(action) {
    try {
        const response =  yield call(UpsellAPI.fetchAllProductMessage, action.payload);
        yield put(actionCreator.fetchAllProductMessageSuccess(response.data, action.payload.isCache));
    } catch (e) {
        yield put(actionCreator.fetchAllProductMessageError(action.payload.isCache ? {} : [], action.payload.isCache));
    }
}

function* updateProductMessage(action) {
    try {
        const response =  yield call(UpsellAPI.updateProductMessage, action.payload);
        yield put(actionCreator.updateProductMessageSuccess(response.data));
        // yield put(actionCreator.fetchAllProductMessageRequest({ isCache: 0 }));
        yield call(action.callback, 
            { status: "success", message: "Success", 
            description: "We have successfully added this change, and this can be verified." });
    } catch (e) {
        yield put(actionCreator.updateProductMessageError([]));
        yield call(action.callback, 
            { status: "error", message: "Oops!", 
            description: "Something went wrong while adding this change. Please retry after sometime." });
    }
}

function* deleteProductMessageRequest(action) {
    try {
        yield call(UpsellAPI.removeProductMessage, action.payload);
        yield put(actionCreator.deleteProductMessageSuccess(action.payload));
        // yield put(actionCreator.fetchAllProductMessageRequest({ isCache: 0 }));
        yield call(action.callback, 
            { status: "success", message: "Success", 
            description: "We have successfully removed this message." });
    } catch (e) {
        yield put(actionCreator.deleteProductMessageError());
        yield call(action.callback, 
            { status: "error", message: "Oops!", 
            description: "Something went wrong while removing this message. Please retry after sometime." });
    }
}

function* updateProductMessageIndustryImageRequest(action) {
    try {
        yield call(UpsellAPI.updateProductMessageIndustryImage, action.payload);
        yield put(actionCreator.updateProductMessageIndustryImageSuccess(action.payload));
        yield call(action.callback, 
            { status: "success", message: "Success", 
            description: "We have successfully added this change, and this can be verified." });
    } catch (e) {
        yield put(actionCreator.updateProductMessageIndustryImageError());
        yield call(action.callback, 
            { status: "error", message: "Oops!", 
            description: "Something went wrong while adding this change. Please retry after sometime." });
    }
}

export default UpsellSaga;
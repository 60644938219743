import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { chunk } from "lodash";
import DocumentListCol from "./DocumentListCol";
import "./DocumentSection.scss";

const DocumentSection = (props) => {
    const { downloadPartnerDocumentRequest, cols } = props;
    const chunkedCols = chunk(cols, 2);

    return (
        <React.Fragment>
            {
                chunkedCols.map((chunk, index) => (
                    <Row className="documentContainer" key={index} gutter={16} style={{ margin: "10px 0 0 0" }}>
                        {
                            chunk.map((col, i) => (
                                <Col key={i} span={12}>
                                    <DocumentListCol { ...col } downloadPartnerDocumentRequest={downloadPartnerDocumentRequest} />
                                </Col>
                            ))
                        }
                    </Row>
                ))
            }
        </React.Fragment>
    )
};

DocumentSection.defaultProps = {
};

DocumentSection.propTypes = {
    downloadPartnerDocumentRequest: PropTypes.func.isRequired,
    cols: PropTypes.array.isRequired
};

export default DocumentSection;
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { Tag, message, Button, Icon, Popconfirm, Divider } from "antd";

import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../../components";

import { dateFormat } from "../../../constants";
import { getIsPageActionAccessible, decodeResourceClassification } from "../../../utils";
import lookup from "../resources.json";
class VideoList extends Component {

    pageKey="videoList";
    isReadonly = !getIsPageActionAccessible(this.pageKey, this.props.userData.privileges);
    state = {
        records: null,
        editSortingOrderKey: null,
        showEditSortingOrderSubmit: false
    };

    componentDidMount() {
        this.props.fetchVideoList();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            ...prevState,
            records: nextProps.data
        };
    }

    formatData = () => {
        const baseURL = process.env.REACT_APP_WEBSITE_URL.replace(/\/$/, '');
        const { records } = this.state;

        return records.map((record, index) => {
            const dataId = record.id;
            const displayLink = this.getVideoEmbedLink(record);
            const { topics, industries } = decodeResourceClassification(record.classification, lookup, true);
            return {
                ...record,
                key: dataId,
                topics, 
                industries,
                sortingOrder: record.sortingOrder,
                sortingOrderField: this.getSortingOrder(index, record.sortingOrder),
                lastUpdated: moment(record.updatedAt).format(dateFormat),
                actions: (
                    <>
                        {
                            displayLink && (
                                <>
                                    <a href={displayLink} target="_blank" rel="noopener noreferrer">View</a>
                                </>
                            )
                        }
                        {
                            record.ctaLink && (
                                <>
                                    <Divider type="vertical" />
                                    <a target={"_blank"} href={`${baseURL}${record.link}`}>Website URL</a>
                                </>
                            )
                        }
                        {
                            !this.isReadonly && (
                                <>
                                    <Divider type="vertical" />
                                    <Link to={`/resource/video/${dataId}`} style={{width: "100%"}}>Edit</Link>
                                    <Divider type="vertical" />
                                    <Popconfirm title={"Are you sure to delete this record?"} onConfirm={() => this.handleDelete(record)} okText="Yes" cancelText="No" placement={"top"}>
                                        <a href="#">Delete</a>
                                    </Popconfirm>
                                </>
                            )
                        }
                    </>
                )
            };
        });
    };
    
    getSortingOrder = (i, value) => {
        const { editSortingOrderKey } = this.state;
        return (
            <div className="sortingOrderWrapper">
                {
                    editSortingOrderKey !== null && editSortingOrderKey === i ?
                        <div>
                            <input type="number" min="1" name={`sortingOrder_${i}`} value={value} onChange={(e) => this.inputChangeHandler(e, i)}/>
                            <Icon type="check-circle" theme="twoTone" onClick={(e) => this.updateSortingOrder(e, i)} />
                        </div> :
                        <div>
                            <Button type="dashed" disabled={ this.isReadonly } onClick={(e) => { e.preventDefault(); this.updateEditSortingOrder(i); }}>{value}</Button>
                        </div>
                }
            </div>
        );
    };

    inputChangeHandler = (e, index) => {
        e.preventDefault();
        const data = {...this.state.records};
        data[index].sortingOrder = e.target.value;
        this.setState({
            records: data,
            showEditSortingOrderSubmit: true
        });
    };

    updateEditSortingOrder = (i) => {
        this.setState({
            editSortingOrderKey: i
        })
    };

    updateSortingOrder = (e, index) => {
        e.preventDefault();
        const { records } = this.state;
        const sortingOrder = records[index].sortingOrder;
        if (sortingOrder && parseInt(sortingOrder) > 0) {
            const payload = {
                ...records[index],
                sortingOrder: parseInt(sortingOrder)
            };
            
            this.props.updateVideo({ data: payload, cb: ((resp) => {
                    if (!resp) {
                        this.props.fetchVideoList();
                        this.setState({
                            editSortingOrderKey: null,
                            showEditSortingOrderSubmit: false
                        });
                        return message.success(
                            "You have successfully updated this record."
                        );
                    } else {
                        return message.error(
                            "Something went wrong. Please try again later."
                        );
                    }
                })
            });
        }
    };

    handleDelete = (data) => {
        const { id } = data;
        this.props.deleteVideo({ id, cb: this.renderFeedbackMsg });
    };

    renderFeedbackMsg = (errorMsg) => {
        if (!errorMsg) {
            return message.success(
                "You have successfully deleted this record."
            );
        } else {
            return message.error(
                "Something went wrong. Please try again later."
            );
        }
    };

    renderExpandedRow = (record) => {
        return (
            <div>
                <div className="mb1">
                    <b>Industries: </b>
                    {record.industries.map((tag) => (
                        tag && <Tag color="blue" key={tag}>
                            {tag}
                        </Tag>
                    ))}
                </div>
                <div className="mb1">
                    <b>Topics: </b>
                    {record.topics.map((tag) => (
                        tag && <Tag color="blue" key={tag}>
                            {tag}
                        </Tag>
                    ))}
                </div>
                <div className="mb1">
                    <b>SFDC Campaign ID: </b>
                    { record.leadSfdcCampaign &&
                        <span>{record.leadSfdcCampaign}</span>
                    }
                </div>
            </div>
        )
    };

    getVideoEmbedLink = (record) => {
        const { videoSource, embedLink } = record;	

        if (!(videoSource && embedLink)) {	
            return null;	
        }	

        const videoBaseUrl = {	
            youtube: "https://www.youtube.com/watch?v="	
        }	
        
        return videoBaseUrl[videoSource] + embedLink;
    }

    render() {
        const { isLoading, error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="resources-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    columns={lookup.videoColumns}
                    expandedRowRender={this.renderExpandedRow}
                    dataSource={this.formatData()}
                    loading={isLoading}
                />
            </div>
        );
    }
}

VideoList.propTypes = {
    data: PropTypes.array.isRequired, 
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchVideoList: PropTypes.func.isRequired,
    deleteVideo: PropTypes.func.isRequired
};

export default VideoList;
import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import PropTypes from "prop-types";
import {Spin, Modal, message} from "antd";
import { TableSkeleton, ResourceSteps, PageHeaderContainer } from "../../../components";
import lookup from "./constants";
import moment from 'moment';
import {websiteNodeAPIUrl} from "../../../constants";
import axios from "axios";
import { downloadFile, getIsPageActionAccessible } from "../../../utils";

class Whitepaper extends Component {
    constructor(props) {
        super(props);

        const { wpId } = props.match && props.match.params;
        this.pageKey = "whitepaperList";

        this.state = {
            isListing: !wpId,
            wpId,
            notification: props.notification
        };

        this.isReadonly = !getIsPageActionAccessible(this.pageKey, ((props.userData && props.userData.privileges) || []));
    }

    componentDidMount() {
        const { wpId } = this.props.match && this.props.match.params;

        if (!wpId) {
            this.props.fetchWPListRequest();
        } else if (wpId !== "new") {
            this.props.fetchWPByIdRequest(wpId)
        }
    }

    componentDidUpdate() {
        this.state.downLoadingPDF && this.renderPDFDownloadingMessage();
    }

    static getDerivedStateFromProps(nextProps, state) {
        const { wpId, notification } = state;
        const { match: newMatch, notification: newNotification } = nextProps;
        const { wpId: newWPId } = newMatch && newMatch.params;

        if (notification && newNotification && newNotification.timestamp > notification.timestamp) {
            const notificationType = newNotification.type;
            Modal[notificationType]({
                title: notificationType === "success" ? "Sucessfully saved" : "Something went wrong",
                content: newNotification.message,
                okText: notificationType === "error" ? "Close" : "Go to whitepaper list page",
                maskClosable: false,
                onOk() {
                    return notificationType === "error" ? "" : nextProps.history.push("/resource/whitepaper");
                }
            });

            return { notification: newNotification }
        } else if (wpId !== newWPId) {

            if (!newWPId) {
                nextProps.fetchWPListRequest();
            } else if (newWPId !== "new") {
                nextProps.fetchWPByIdRequest(newWPId);
            }

            return { wpId: newWPId, isListing: !newWPId };
        }

        return null;
    }

    handleStepsSubmission = (data) => {
        const { userData } = this.props;

        const { cardTitle: card_heading, cardDesc: card_description, link, classification } = data.card || {};
        const backendMappedData = { ...data, card_heading, card_description, link, classification, sneak_peak: {...(data.sneakPeak || {})} };

        delete(backendMappedData.card);
        delete(backendMappedData.sneakPeak);

        if (this.state.wpId === "new") {
            backendMappedData.created_by = userData.name || "unknown";
            this.props.addWPRequest(backendMappedData);
        } else {
            backendMappedData.updated_by = userData.name || "unknown";
            this.props.updateWPRequest({id: this.state.wpId, data: backendMappedData});
        }
    }

    downloadPDF = (path) => {
        this.setState({
            downLoadingPDF: true
        }, () => {
            const url = `${websiteNodeAPIUrl}getPDFDownloads?pdfPath=${path}`;
            axios.get(url, { responseType: "blob" }).then((response) => {
                downloadFile({
                    response,
                    cb: () => this.setState({downLoadingPDF: false}, () => this.hidePDFDownloadMessage && this.hidePDFDownloadMessage())
                });
            }).catch(() => {
                this.setState({downLoadingPDF: false})
            });
        });
    }

    renderPDFDownloadingMessage = () => {
        this.hidePDFDownloadMessage && this.hidePDFDownloadMessage();
        this.hidePDFDownloadMessage = message.loading("Downloading PDF...", 0);
        return this.hidePDFDownloadMessage;
    }

    mapListToDataSource = (data) => {

        return data.map((ebook, index) => {
            const {id, card_heading, link, created_at, created_by, updated_at, updated_by, banner} = ebook;

            return {
                key: index,
                title: card_heading,
                createdAt: created_at && moment(created_at).format("MMMM Do, YYYY HH:mm z"),
                updatedAt: updated_at && moment(updated_at).format("MMMM Do, YYYY HH:mm z"),
                history: (
                    <div>
                        <p>
                            <strong>Created by: </strong> { created_by || "unknown" }
                            <br/><strong>Created at: </strong> { created_at ? moment(created_at).format("MMMM Do, YYYY HH:mm z") : "n/a" }
                        </p>
                        <p>
                            <strong>Updated by: </strong> { updated_by || "unknown" }
                            <br/><strong>Updated at: </strong> { updated_at ? moment(updated_at).format("MMMM Do, YYYY HH:mm z") : "n/a" }
                        </p>
                    </div>
                ),
                actions: (
                    <>
                        {
                            !this.isReadonly && (
                                <>
                                    <Link to={`/resource/whitepaper/${id}`} style={{width: "100%"}}>Edit</Link><br/>
                                </>
                            )
                        }
                        {
                            link &&
                                <>
                                    <a target={"_blank"} href={`${process.env.REACT_APP_WEBSITE_URL}..${link}`}>Link</a><br/>
                                </>
                        }
                        {
                            banner.pdf && (
                                <a onClick={() => this.downloadPDF(banner.pdf)}>Download</a>
                            )
                        }
                    </>
                )
            }
        });
    }

    render() {
        const { userData, isLoading } = this.props;
        const { isListing } = this.state;
        const unauthorized = !userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        if (isLoading) {
            return <Spin />
        }

        if (!isListing) {
            const 
                { card_heading: cardTitle="", card_description: cardDesc="", classification="", link="", meta={}, banner={}, highlights={}, sneak_peak: sneakPeak={}, result={}, cta={} } = this.props.whitepaper,
                initialValues = { card: { cardTitle, cardDesc, classification, link }, meta, banner, highlights, sneakPeak, result, cta };

            const isUpdate = this.state.wpId && this.state.wpId !== "new";

            return <ResourceSteps type={"whitepaper"} title={ `${isUpdate ? "Edit" : "New"} Whitepaper` } initialValues={ isUpdate ? initialValues : {} } additional={{ isUpdate }} userData={ userData } handleStepsSubmission={this.handleStepsSubmission}/>
        }

        return (
            <div>
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton loading={isLoading} columns={lookup.listingTableColumns} dataSource={this.mapListToDataSource(this.props.whitepaperList)} expandedRowRender={this.renderDetails} pageSize={10} showSizeChanger={false} />
            </div>
        )
    }
}

export default Whitepaper;

import React from "react";
import {connect} from "react-redux";
import { HashRouter as Router} from "react-router-dom";
import "./components/ApiLoader/ApiLoader.scss";

import { Header, Page } from "./containers";
import Routes from './Routes';
import { redirectToLoginIfNoSession } from "./pages/Users/actions";

import { createBrowserHistory } from "history";
import { getCookie } from "./utils";
import { beAPIResource } from "./utils/apiHelper";

const history = createBrowserHistory();

class App extends React.Component{
    constructor(props) {
        super(props)    ;
        this.state = {
            isCheckingLogin: true,
            sessionExists: false
        };
    }

    async checkForLogin() {
        const userId = getCookie("user_id") || "";
        const session = await beAPIResource.get(`users/session-exists?userId=${userId}`)
        if(!session.data.sessionExists){
            await beAPIResource.get(`${process.env.REACT_APP_AUTH_BASE_URL}logout`)
            this.props.dispatch(redirectToLoginIfNoSession(userId));
        }
        if (this.state.isCheckingLogin){
            this.setState({ isCheckingLogin: false, sessionExists: !!session.data.sessionExists });
        }
    }

    componentDidMount(){
        (async()=>{await this.checkForLogin();})();
    }
    
    componentDidUpdate(prevProps, prevState){
        if (this.state.isCheckingLogin === prevState.isCheckingLogin) {
            this.checkForLogin();
        }
    }

    render(){
        if (this.state.isCheckingLogin) {
            return(
                <div style={{display: 'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100vh', fontSize:'24px'}}>
                    <div>Loading...</div>
                </div>
            )
        }
        
        return (
            <Router>
                <div>
                    <div id='api-loader'></div>
                    <Header history={history} sessionExists={this.state.sessionExists} />
                    <Page history={history}>
                        <Routes/>
                    </Page>
                </div>
            </Router>
        );
    }
}

export default connect()(App);

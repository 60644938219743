import { createActionTypes } from "../../utils";

export const LeadgenManagementActions = createActionTypes("leadgenManagement", [
    "SEND_CONTACT_REQ",
    "SUCCESS_CONTACT_RESP",
    "ERROR_CONTACT_RESP",
    "REQ_CONTACT_DETAILS",
    "REQ_CONTACT_DETAILS_SUCCESS",
    "REQ_CONTACT_DETAILS_ERROR",
    "HIDE_CONTACT_MODAL_METHOD",
    "SEND_SFDC_LEAD_FORM_REQ",
    "SEND_SFDC_LEAD_FILE_REQ",
    "SUCCESS_SFDC_LEAD_RESP",
    "ERROR_SFDC_LEAD_RESP",
    "HIDE_SFDC_LEAD_MODAL_METHOD",
    "REQ_PAGEDATA_CAMPAIGN_URL_BUILDER",
    "REQ_PAGEDATA_CAMPAIGN_URL_BUILDER_SUCCESS",
    "REQ_PAGEDATA_CAMPAIGN_URL_BUILDER_ERROR",
    "LOG_CAMPAIGN_URL_BUILDER_ACTIVITY",
    "REQ_CAMPAIGN_URL_BUILDER_ARCHIVE",
    "REQ_CAMPAIGN_URL_BUILDER_ARCHIVE_SUCCESS",
    "REQ_CAMPAIGN_URL_BUILDER_ARCHIVE_ERROR",
    "REQ_EMAIL_UPDATER",
    "EMAIL_UPDATER_SUCCESS",
    "EMAIL_UPDATER_ERROR"
]);

export const sendContactRequest = (pageType, obj) => {
    return {
        type: LeadgenManagementActions.SEND_CONTACT_REQ,
        pageType: pageType,
        payload: obj
    }
};

export const successContactResponse = (message) => {
    return {
        type: LeadgenManagementActions.SUCCESS_CONTACT_RESP,
        payload: message
    };
};

export const errorContactResponse = (message) => {
    return {
        type: LeadgenManagementActions.ERROR_CONTACT_RESP,
        payload: message
    };
};

export const reqContactDetails = (pageType, object) => {
    return {
        type: LeadgenManagementActions.REQ_CONTACT_DETAILS,
        pageType: pageType,
        payload: {... object}
    }
}

export const successContactDetails = (object) => {
    return {
        type: LeadgenManagementActions.REQ_CONTACT_DETAILS_SUCCESS,
        payload: object
    }
}

export const errorContactDetails = (obj) => {
    return {
        type: LeadgenManagementActions.REQ_CONTACT_DETAILS_ERROR,
        payload: {... obj.data}
    }
}

export const hideContactModalMethod = () => {
    return {
        type: LeadgenManagementActions.HIDE_CONTACT_MODAL_METHOD
    }
};

export const sendSfdcLeadFormRequest = (object) => {
    return {
        type: LeadgenManagementActions.SEND_SFDC_LEAD_FORM_REQ,
        payload: object
    };
};

export const sendSfdcLeadFileRequest = (object) => {
    return {
        type: LeadgenManagementActions.SEND_SFDC_LEAD_FILE_REQ,
        payload: object
    };
};

export const successSfdcLeadResponse = (message) => {
    return {
        type: LeadgenManagementActions.SUCCESS_SFDC_LEAD_RESP,
        payload: message
    };
};

export const errorSfdcLeadResponse = (message) => {
    return {
        type: LeadgenManagementActions.ERROR_SFDC_LEAD_RESP,
        payload: message
    };
};

export const hideSfdcModalMethod = () => {
    return {
        type: LeadgenManagementActions.HIDE_SFDC_LEAD_MODAL_METHOD
    };
};

export const reqPageDataCampaignUrlBuilder = () => ({
    type: LeadgenManagementActions.REQ_PAGEDATA_CAMPAIGN_URL_BUILDER
});

export const successPageDataCampaignUrlBuilder = (object) => ({
    type: LeadgenManagementActions.REQ_PAGEDATA_CAMPAIGN_URL_BUILDER_SUCCESS,
    payload: object
});

export const errorPageDataCampaignUrlBuilder = (object) => ({
    type: LeadgenManagementActions.REQ_PAGEDATA_CAMPAIGN_URL_BUILDER_ERROR,
    payload: object
});

export const logCampaignUrlBuilderActivity = (object) => ({
    type: LeadgenManagementActions.LOG_CAMPAIGN_URL_BUILDER_ACTIVITY,
    payload: object
});

export const reqCampaignUrlBuilderArchive = (object) => ({
    type: LeadgenManagementActions.REQ_CAMPAIGN_URL_BUILDER_ARCHIVE,
    payload: object
});

export const successCampaignUrlBuilderArchive = (object) => ({
    type: LeadgenManagementActions.REQ_CAMPAIGN_URL_BUILDER_ARCHIVE_SUCCESS,
    payload: object
});

export const errorCampaignUrlBuilderArchive = () => ({
    type: LeadgenManagementActions.REQ_CAMPAIGN_URL_BUILDER_ARCHIVE_ERROR,
    payload: []
});

export default LeadgenManagementActions;

import { all } from "redux-saga/effects";

import userSagas from "../pages/Users/saga";
import resourceSagas from "../pages/Resources/saga";
import integrationSagas from "../pages/Integrations/saga";
import integrationDomainSagas from "../pages/IntegrationDomains/saga";
import effectSagas from "../pages/Effects/saga";
import pressSagas from "../pages/Presses/saga";
import productEmailSagas from "../pages/ProductEmails/saga";
import salesRepSaga from "../pages/SalesReps/saga";
import pageSagas from "../pages/Pages/saga";
import reviewSagas from "../pages/Reviews/saga";
import analyticsSagas from "../pages/Analytics/saga";
import leadgenManagementSaga from "../pages/LeadgenManagement/saga";
import activitySagas from "../pages/Activity/saga";
import categoriesSagas from "../pages/Categories/saga";
import featuresSagas from "../pages/FeatureReleases/saga";
import freeProdDashboardSagas from "../pages/FreeProdDashboard/saga";
import emailServiceSaga from "../pages/EmailService/saga";
import prospectorSagas from "../pages/Prospector/redux/saga";
import webinarToolSaga from "../pages/Webinar/saga";
import businessSagas from "../pages/BusinessOperations/saga";
import resourcesV2Sagas from "../pages/ResourcesV2/saga";
import requestBusinessAccessSagas from "../pages/RequestBusinessAccess/saga";
import featureResourceSagas from "../pages/FeatureResource/saga";
import gmbSagas from "../pages/Gmb/saga";
import scitorSaga from "../pages/Scitor/saga";
import twilioSaga from "../pages/Twilio/saga";
import businessActionsSagas from "../pages/BusinessActions/saga";
import signatureSaga from "../pages/Signature/saga";
import partnerSaga from "../pages/Partner/saga.js";
import upsellSaga from "../pages/Upsell/saga";
import featurePagesSaga from "../pages/FeaturePages/saga";
import scanToolSaga from "../pages/ScanTool/saga";
import selfServePanelSaga from "../pages/IntegrationSelfServe/saga";


export default function* rootSaga() {
    yield all([
        ...userSagas,
        ...resourceSagas,
        ...integrationSagas,
        ...integrationDomainSagas,
        ...effectSagas,
        ...pressSagas,
        ...productEmailSagas,
        ...salesRepSaga,
        ...pageSagas,
        ...leadgenManagementSaga,
        ...activitySagas,
        ...categoriesSagas,
        ...featuresSagas,
        ...freeProdDashboardSagas,
        ...emailServiceSaga,
        ...reviewSagas,
        ...prospectorSagas,
        ...webinarToolSaga,
        ...analyticsSagas,
        ...businessSagas,
        ...resourcesV2Sagas,
        ...requestBusinessAccessSagas,
        ...featureResourceSagas,
        ...gmbSagas,
        ...scitorSaga,
        ...businessActionsSagas,
        ...signatureSaga,
        ...partnerSaga,
        ...upsellSaga,
        ...featurePagesSaga,
        ...twilioSaga,
        ...scanToolSaga,
        ...selfServePanelSaga
    ]);
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Form, Input, Button, Spin } from "antd";
import {formItemLayout, formButtonLayout} from "../../constants";
import { TableSkeleton, RedirectWrapper } from "../../components";
import Select from "antd/es/select";
import {Modal} from "antd/lib/index";
import lookup from "./emails.json";
const FormItem = Form.Item;

class EmailServiceForm extends Component {

    constructor (props) {
        super(props);
    }

    componentDidMount() {
        const { match, fetchEmailRequest} = this.props;
        if (match.params.domain) {
            fetchEmailRequest({ domain: match.params.domain });
        }
    }

    componentDidUpdate(prevProps) {
        const { match, fetchEmailRequest, blankEmailRequest} = this.props;
        console.log(this.props.match.params.domain);
        console.log(prevProps.match.params.domain);
        if (this.props.match.params.domain !== prevProps.match.params.domain) {
            if(this.props.match.params.domain){
                fetchEmailRequest({ domain: match.params.domain });
            } else {
                blankEmailRequest();
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((error, values) => {

            if (!error) {
                const payload = {
                    website: values.website,
                    recipient: values.recipient
                };
                this.props.searchDomainRequest({ payload, cb: this.renderFeedback });
            }
        })
    };

    renderFeedback = (response) => {
        const goToEmailServicePage = () => this.props.history.push("/email-service");
        if(response.type === 'SUCCESS'){
            if(response.domain){
                this.props.history.push("/email-service/" + response.domain);
            } else {
                return Modal.success({
                    title: `You have successfully added a record`,
                    content: response.message,
                    okText: "Ok",
                    maskClosable: true,
                    onOk() {
                        goToEmailServicePage();
                    }
                });
            }

        } else {
            return Modal.warning({
                title: response.type,
                content: response.message
            });
        }
    };

    render() {
        const
            { error, userData, isLoading, domain, emails, location } = this.props,
            { getFieldDecorator } = this.props.form,
            unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={location} />;
        }

        return (
            <div>
                <h2>Find Emails</h2>
                {isLoading ? (
                    <Spin />
                ) : (
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Website">
                        {getFieldDecorator('website', {
                            rules: [{ required: true, message: 'Enter Website' }],
                            initialValue: domain.domain,
                        })(
                            <Input
                                name="website"
                                placeholder="Enter website url"
                            />
                        )}
                    </FormItem>
                    <FormItem className={"hide"} {...formItemLayout} label={"Recipient(s)"}>
                        {
                            getFieldDecorator("recipient", {
                                rules: [{
                                    "required": true,
                                    "message": "Please provide at least a recipient to receive email"
                                }, {
                                    "pattern": /@birdeye\.com$/g,
                                    "message": "Please provide valid email like xxx.xxx@birdeye.com"
                                }],
                                initialValue: userData.name,
                            })(
                                <Select mode="tags" placeholder={"Provide a recipient"} allowClear={true} disabled={true}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Submit
                        </Button>
                    </FormItem>
                </Form>
                )},
                {
                    emails && emails.length > 0 && !isLoading && (
                        <TableSkeleton
                            columns={lookup.columns}
                            expandedRowRender={this.renderExpandedRow}
                            dataSource={emails}
                        />
                    )
                }
            </div>
        );
    }
}

const EmailService = Form.create()(EmailServiceForm);

EmailService.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    userData: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    searchDomainRequest: PropTypes.func.isRequired,
    fetchEmailRequest: PropTypes.func.isRequired,
    blankEmailRequest: PropTypes.func.isRequired
};

export default EmailService;
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Form, Input } from "antd";
import { addNewComponent, updateComponent } from '../../formUtils';
import { TextEditor } from '../../../../components';
const FormItem = Form.Item;

export const formItemLayout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 4 }
    },
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 18 }
    }
};

export const formButtonLayout = {
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 18, offset: 4 }
    }
};

const HTMLHeaderForm = ({ match, form, data={}, pageId }) => {
    const params = match.params;
    const { getFieldDecorator } = form;
    const [ formData, setFormData] = useState(data || '');

    const handleChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: value
        });
    };

    const handleSubmit = () => {

        form.validateFieldsAndScroll(async(err, values) => {
            if (!err) {
                const data = JSON.stringify(values)
                const payload = {
                    data,
                    page_id: params.pageId,
                    order_no: 999,
                    name: 'htmlBlock'
                }
                if( params.pageId ) {
                    addNewComponent(payload);
                }
                else {
                    updateComponent(params.componentId, data, pageId);
                }
            }
            else {
                return;
            }
        }); 
    }
    
    return (
        <>
            <FormItem {...formItemLayout} label={"Heading"}>
                {
                    getFieldDecorator("heading", {
                        initialValue: formData.heading
                    }) (
                        <Input
                            placeholder='Heading'
                            onChange={(e) => handleChange('heading', e.target.value)}
                        />
                    )
                }
            </FormItem>
            <FormItem {...formItemLayout} label={"Sub Heading"}>
                {
                    getFieldDecorator("subHeading", {
                        initialValue: formData.subHeading
                    }) (
                        <Input
                            placeholder='Sub Heading'
                            onChange={(e) => handleChange('subHeading', e.target.value)}
                        />
                    )
                }
            </FormItem>
            <FormItem {...formItemLayout} label={"Description"}>
                {
                    getFieldDecorator("description", {
                        initialValue: formData.description
                    }) (
                        <TextEditor placeholder={"Description"} handleEditorChange={(value) => handleChange('description', value)} advanced/>
                    )
                }
            </FormItem>
            <FormItem {...formItemLayout} label={"Data"}>
                {
                    getFieldDecorator("html", {
                        initialValue: formData.html
                    }) (
                        <TextEditor placeholder={"Data"} handleEditorChange={(value) => handleChange('html', value)} advanced/>
                    )
                }
            </FormItem>
            <FormItem {...formButtonLayout}>
                <Button type="primary" htmlType="submit" block onClick={handleSubmit}>
                    Save
                </Button>
            </FormItem>
        </>
    );
}

const HTMLForm = Form.create()(withRouter(HTMLHeaderForm));
export default HTMLForm;
import React, { Component } from "react";
import PropTypes from "prop-types";
import {Typography, PageHeader, Steps, Form, Button, message, Spin, Modal} from "antd";
import { forEach, unset, isEmpty } from "lodash";

import ProvideBusinessNumber from "./steps/ProvideBusinessNumber";
import BusinessInfo from "./steps/BusinessInfo";
import BusinessHours from "./steps/BusinessHours";
import SocialProfiles from "./steps/SocialProfiles";
import Users from "./steps/Users";
import Find from "./steps/Find";
import Complete from "./steps/Complete";
import AggregationSource from "./steps/AggregationSource";
import { RedirectWrapper } from "../../components";
import TableSkeleton from "../../components/TableSkeleton";

const { Step } = Steps;
const { Title } = Typography;

class FixProfileForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            businessInfo:{},
            isLoading: false,
            otherPages: true,
            businessNumberPage: false,
            googleData: {},
            findProcessed: false,
            businesses: []
        };
    }

    componentDidMount() {
        this.props.fetchStates( { country: 'US' } );
        this.props.fetchCountries();
        this.props.fetchBirdeyeCategoriesList();
    }

    formatHoursOfOperations(hoursOperations) {
        return hoursOperations.map(({day, comment, isOpen, startHour, endHour}) => ({day, comment, isOpen, workingHours: [{startHour, endHour}]}));
    }

    next() {
        const { current, otherPages } = this.state;
        if(current === 0 && otherPages === true){
            this.setState({otherPages: false, businessNumberPage: true});
        }

        if (this.state.current === 0) {
            this.props.form.validateFieldsAndScroll(["business_number"], (err, values) => {
                if (!err) {
                    const businessNumber = this.props.form.getFieldValue('business_number');
                    const findOnGoogle = this.props.form.getFieldValue('findOnGoogle');
                    const findByEmail = this.props.form.getFieldValue('findByEmail');
                    const emailId = this.props.form.getFieldValue('emailId');

                    if(businessNumber && findOnGoogle){
                        this.setState({ isLoading: true });
                        this.props.fetchBusinessByNumberWithGoogle( { data : { businessNumber: businessNumber , withGoogle: findOnGoogle }, cb: this.businessInfoCallBack } );
                    } else if(businessNumber && !findOnGoogle) {
                        this.setState({ isLoading: true });
                        this.props.fetchBusinessByBusinessNumber( { businessNumber: businessNumber, cb: this.businessInfoCallBack } );
                    } else if(findByEmail && emailId) {
                        this.setState({ isLoading: true });
                        this.props.fetchBusinessByEmailId( { emailId: emailId, cb: this.businessInfoByEmailCallBack } );
                    }
                }
            });
        } else if (this.state.current === 1) { // Update Business Info
            this.props.form.validateFieldsAndScroll(["name", "alias", "parentCategory", "childCategory", "city", "zip", "state", "countryCode"], (err, values) => {
                if (!err) {
                    this.setState({ isLoading: true });
                    const location = {
                        address1	:	this.props.form.getFieldValue('address1'),
                        address2	:	this.props.form.getFieldValue('address2'),
                        city	    :	this.props.form.getFieldValue('city'),
                        state	    :	this.props.form.getFieldValue('state'),
                        zip	        :	this.props.form.getFieldValue('zip'),
                        countryCode	:	this.props.form.getFieldValue('countryCode'),
                    };
                    const data = {
                        name            : this.props.form.getFieldValue('name'),
                        alias           : this.props.form.getFieldValue('alias'),
                        location        : location,
                        websiteUrl      : this.props.form.getFieldValue('websiteUrl'),
                        phone           : this.props.form.getFieldValue('phone'),
                        categoryList    : this.props.form.getFieldValue('childCategory'),
                        timezone        : this.props.form.getFieldValue('timezone'),
                        description      : this.props.form.getFieldValue('description')
                    };
                    this.props.updateBusinessRequest( { businessId: this.state.businessInfo.businessId, data , cb: this.businessInfoUpdateCallBack } );
                }
            });

        } else if (this.state.current === 2) {
            const formData = this.props.form.getFieldsValue();
            const {hoursOfOperations = []} = formData;
            formData.hoursOfOperations = this.formatHoursOfOperations(hoursOfOperations);
            unset(formData, ['hours']);
            if(formData.wholeWeekOperating === 0){
                forEach(formData.hoursOfOperations, function(hour, index) {
                    formData.hoursOfOperations[index].isOpen = hour.isOpen ? 1 : 0 ;
                });
            } else {
                unset(formData, ['hoursOfOperations']);
            }
            this.setState({ isLoading: true });
            this.props.updateBusinessRequest( { businessId: this.state.businessInfo.businessId, data: formData, cb: this.businessInfoUpdateCallBack } );
        } else if (this.state.current === 3) {
            this.props.form.validateFieldsAndScroll(["socialProfileURLs"], (err, values) => {
                if (!err) {
                    this.setState({ isLoading: true });
                    const data = {
                        socialProfileURLs : this.props.form.getFieldValue('socialProfileURLs')
                    };
                    this.props.updateBusinessRequest( { businessId: this.state.businessInfo.businessId, data , cb: this.businessInfoUpdateCallBack } );
                }
            });
        } else if (this.state.current === 6) {
            this.setState({ isLoading: true, findProcessed: true });
            const businessInfo = this.state.businessInfo;
            let data = {};
            data.businessId = businessInfo.businessNumber;
            data.bizId = businessInfo.businessId;
            data.name = businessInfo.name;
            data.phone = businessInfo.phone;

            if (businessInfo.locations && businessInfo.locations.length) {
                const location = businessInfo.locations[0];

                let address = [];
                if (location.address1) address.push(location.address1);
                if (location.address2) address.push(location.address2);
                if (location.city) address.push(location.city);
                if (location.state) address.push(location.state);

                if (location.zip) {
                    address.push(location.state);
                    data.zip = location.zip;
                }

                if (address.length) data.address = address.join(", ");
            }

            this.props.findBusinessRequest({ data, cb: this.findCallBack });
        } else {
            const current = this.state.current + 1;
            this.setState({ current });
        }
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }

    skip() {
        const current = this.state.current + 1;
        this.setState({ current });
    }

    complete() {
        window.open(`${this.state.businessInfo.publicProfileUrl}?update=1`, "_blank");
        window.location.reload();
    }

    businessInfoCallBack = (error) => {
        this.setState({ isLoading: false });
        if(!error){
            const current = this.state.current + 1;
            this.setState({ current, businessInfo: this.props.info}); //change to props
            if(!isEmpty(this.props.info.locations) && this.props.info.locations[0].countryCode !== 'US'){
                this.props.fetchStates( { country: this.props.info.locations[0].countryCode } );
            }
            this.props.fetchUsersByBusinessNumber( { businessNumber: this.props.info.businessNumber } );
            this.props.fetchAggregationRequest( { businessId: this.props.info.businessId } );
            this.props.fetchAggregationSources({ businessId: this.props.info.businessId });
        } else {
            message.error({ content: 'Error Occurred'});
        }
    };

    businessInfoByEmailCallBack = (error) => {
        if(!error) {
            const businessDetails = this.props.info;
            const lengthOfResults = businessDetails.length;
            if(lengthOfResults > 1) {
                this.setState({ businesses: businessDetails, isLoading: false });
            } else {
                const businessNumber = this.props.info[0];
                const findOnGoogle = this.props.form.getFieldValue('findOnGoogle');
                if(!isEmpty(businessNumber)) {
                    if(!findOnGoogle) {
                        this.props.fetchBusinessByBusinessNumber( { businessNumber: businessNumber.business_number, cb: this.businessInfoCallBack } );
                    } else {
                        this.props.fetchBusinessByNumberWithGoogle( { data : { businessNumber: businessNumber.business_number , withGoogle: findOnGoogle }, cb: this.businessInfoCallBack } );
                    }
                } else {
                    this.setState({isLoading: false});
                    return Modal.error({
                        title: `No Business Found`,
                        content: "Please try with different email",
                    });
                }
            }
        }
    }

    formatData = (response) => {
        return response && response.map((record, index) => {
            const businessNumber = record.business_number === null ? '' : record.business_number;
            const profile = record.business_number === null ? '' : (<div><a href={'https://reviews.birdeye.com/a-'+businessNumber} target="_blank">Profile</a></div>);
            const name = record.business_name === null ? '' : record.business_name;
            const phone = record.phone === null ? '' : record.phone;
            const website = record.website === null ? '' : (<div><a href={record.website} target="_blank">website</a></div>);
            const action = (
                <div>
                    <Button type="primary" onClick={() => this.handleNext(record)}>
                        Next
                    </Button>
                </div>
            )

            return {
                key: index,
                businessNumber: businessNumber,
                profileLink: profile,
                businessName: name,
                phone: phone,
                website: website,
                action: action
            };

        });
    };

    handleNext = (record) => {
        const { current, otherPages } = this.state;
        if(current === 0 && otherPages === true){
            this.setState({otherPages: false, businessNumberPage: true});
        }
        const businessNumber = record.business_number;
        const findOnGoogle = this.props.form.getFieldValue('findOnGoogle');
        
        if(businessNumber !== null && findOnGoogle){
            this.setState({ isLoading: true });
            this.props.fetchBusinessByNumberWithGoogle( { data : { businessNumber: businessNumber , withGoogle: findOnGoogle }, cb: this.businessInfoCallBack } );
        } else if(businessNumber !== null && !findOnGoogle) {
            this.setState({ isLoading: true });
            this.props.fetchBusinessByBusinessNumber( { businessNumber: businessNumber, cb: this.businessInfoCallBack } );
        }
    }

    businessInfoUpdateCallBack = (error) => {
        this.setState({ isLoading: false });
        if(!error){
            this.props.fetchBusinessByBusinessNumber( { businessNumber: this.state.businessInfo.businessNumber , cb: this.businessInfoFetchCallBack } );
        } else {
            message.error({ "content": error.data.message});
        }
    };

    businessInfoFetchCallBack = (error) => {
        if(!error){
            const current = this.state.current + 1;
            this.setState({ current, businessInfo: this.props.info});
        } else {
            message.error({ content: 'Error Occurred'});
        }
    };

    findCallBack = (error) => {
        this.setState({ isLoading: false });
        if(!error){
            window.open(`${this.state.businessInfo.publicProfileUrl}?update=1`);
            const current = this.state.current + 1;
            this.setState({ current });
        } else {
            message.error({ content: 'Error Occurred'});
        }
    };

    onChange = current => {
        this.setState({ current: current });
    };

    render() {
        const steps = [
            {
                title: 'Business Number',
                content: <ProvideBusinessNumber form={this.props.form} />,
            },
            {
                title: 'Business Info',
                content: <BusinessInfo isLoading={this.props.isLoading} businessInfo={this.state.businessInfo} fetchStates={this.props.fetchStates} categories={this.props.categories} countries={this.props.countries} states={this.props.states} form={this.props.form} googleData={this.props.googleData} findOnGoogle={this.props.findOnGoogle}/>,
            },
            {
                title: 'Business Hours',
                content: <BusinessHours businessInfo={this.state.businessInfo} form={this.props.form} googleData={this.props.googleData} findOnGoogle={this.props.findOnGoogle}/>,
            },
            {
                title: 'Social Profiles',
                content: <SocialProfiles businessInfo={this.state.businessInfo} form={this.props.form} />,
            },
            {
                title: 'Users',
                content: <Users businessInfo={this.state.businessInfo} users={this.props.users} addUserRequest={this.props.addUserRequest} fetchUsersByBusinessNumber={this.props.fetchUsersByBusinessNumber} deleteUserRequest={this.props.deleteUserRequest} form={this.props.form} />,
            },
            {
                title: 'Review Sites',
                content: <AggregationSource businessInfo={this.state.businessInfo} aggregations={this.props.aggregations} deleteAggregationRequest={this.props.deleteAggregationRequest} reaggregationRequest={this.props.reaggregationRequest} fetchAggregationRequest={this.props.fetchAggregationRequest} addAggregationRequest={this.props.addAggregationRequest} sources={this.props.sources} form={this.props.form} reaggregationResponse={this.props.reaggregationResponse} />,
            },
            {
                title: 'Find',
                content: <Find  />,
            },
            {
                title: 'Complete',
                content: <Complete findProcessed={this.state.findProcessed}/>,
            }

        ];
        const { current, isLoading, findProcessed, businesses } = this.state;
        const { userData, error, categories = [] } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        const tableColumns = [
            {
                title: 'Business Number',
                dataIndex: 'businessNumber',
                key: 'business_number',
                width: '12%'
            },
            {
                title: 'BE Profile',
                dataIndex: 'profileLink',
                key: 'profileLink',
                width: '8%'
            },
            {
                title: 'Business Name',
                dataIndex: 'businessName',
                key: 'business_name',
                width: '40%',
                searchable: true
            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
                width: '10%',
            },
            {
                title: 'Website',
                dataIndex: 'website',
                key: 'webiste',
                width: '4%'
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                width: '10%',
                align: 'center'
            }
        ];

        return (
            <div className="main-container">
                {categories.length === 0 &&
                <div className="spin-overlay">
                    <Spin size="large" />
                </div>
                }
                <PageHeader
                    style={{ border: '1px solid rgb(235, 237, 240)',}}
                    onBack={() => null}
                    title="Fix Profile"
                    subTitle="Follow steps to fix Business Profile"
                />
                <Steps
                    type="navigation"
                    size="small"
                    current={current}
                    onChange={this.onChange}
                    className="site-navigation-steps"
                >
                    {steps.map((item, index) => (
                        item.title === "Business Number" ? (
                            <Step key={item.title} title={item.title} disabled={this.state.businessNumberPage}/>
                        ) : (
                            item.title === "Complete" && current === 7 && !findProcessed ? (
                                <Step key={item.title} title={item.title && item.title === "Find" ? <>{item.title} <span style={{ color: "red" }}>*</span></> : item.title } disabled={this.state.otherPages}/>
                            ) : (
                                item.title === "Find" && current === 7 && !findProcessed ? (
                                    <Step key={item.title} title={item.title && item.title === "Find" ? <>{item.title} <span style={{ color: "red" }}>*</span></> : item.title } disabled={this.state.otherPages} status={"error"}/>
                                ) : (
                                    <Step key={item.title} title={item.title && item.title === "Find" ? <>{item.title} <span style={{ color: "red" }}>*</span></> : item.title } disabled={this.state.otherPages}/>
                                )
                            )
                        )

                    ))}
                </Steps>
                <div className="steps-content">{steps[current].content}</div>
                <div className="steps-action">
                    {current > 0 && (
                        <Button  onClick={() => this.prev()}>
                            Previous
                        </Button>
                    )}
                    {current > 0 && current < steps.length - 2 &&(
                        <Button style={{ marginLeft: 8 }} onClick={() => this.skip()} >
                            skip
                        </Button>
                    )}
                    {current === 0 && (
                        <Button style={{ marginLeft: 8 }} type="primary" onClick={() => this.next()}>
                            Next
                        </Button>
                    )}
                    {current < steps.length - 1 && current > 0 &&  (
                        <Button style={{ marginLeft: 8 }} type="primary" onClick={() => this.next()}>
                            Save & Next
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        findProcessed ?
                        <Button style={{ marginLeft: 8 }} type="primary" onClick={() => this.complete()}>
                            Complete
                        </Button> : ""
                    )}
                </div>

                {
                    !isEmpty(businesses) && (current === 0) ? (
                        <Form layout ="inline">
                            <TableSkeleton
                                style = {{ marginTop: "30px" }}
                                className = {"ant-table-bordered"}
                                columns = {tableColumns}
                                expandedRowRender={this.renderExpandedRow}
                                dataSource = {this.formatData(businesses)}
                            />
                        </Form>
                    ) : (
                        <> </>
                    )
                }

            </div>
        );
    }
}

const FixProfile = Form.create()(FixProfileForm);
FixProfile.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchBusinessByBusinessNumber: PropTypes.func.isRequired,
    fetchStates: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    fetchBirdeyeCategoriesList: PropTypes.func.isRequired,
    updateBusinessRequest: PropTypes.func.isRequired,
    findBusinessRequest: PropTypes.func.isRequired,
    fetchUsersByBusinessNumber: PropTypes.func.isRequired,
    addUserRequest: PropTypes.func.isRequired,
    deleteUserRequest: PropTypes.func.isRequired,
    fetchAggregationRequest: PropTypes.func.isRequired,
    deleteAggregationRequest: PropTypes.func.isRequired,
    reaggregationRequest: PropTypes.func.isRequired,
    fetchAggregationSources: PropTypes.func.isRequired,
    addAggregationRequest: PropTypes.func.isRequired
};

export default FixProfile;

import { connect } from "react-redux";
import {
    fetchBirdeyeReviews,
    fetchBirdeyeReviewsSuccess,
    fetchBirdeyeReviewsError,
    deleteReviewRequest,
    deleteReviewReqSuccess,
    deleteReviewReqError
} from "./actions";


import DeleteReview from "./DeleteReview";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.BusinessActions
});

export const DeleteReviewPage = connect(
    mapStateToProps,
    {
        fetchBirdeyeReviews,
        fetchBirdeyeReviewsSuccess,
        fetchBirdeyeReviewsError,
        deleteReviewRequest,
        deleteReviewReqSuccess,
        deleteReviewReqError
    }
)(DeleteReview);
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { pick, includes, flatten, compact } from "lodash";
import { Form, Button, Alert, Spin, Modal, Upload, Icon, Select, Input, Checkbox } from "antd";

import { RedirectWrapper } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";
import { getSlug } from "../../utils";
import lookup from "./effects.json";

const FormItem = Form.Item;
const Option = Select.Option;
const OptGroup = Select.OptGroup;
class EffectForm extends Component {
    state = {
        data: {
            businessName: "",
            businessUrl: "",
            label: "",
            address: "",
            avgRating: 0,
            reviewCount: 0,
            reviewImg: "",
            prevAvgRating: 0,
            prevReviewCount: 0,
            prevReviewImg: "",
            signupDate: "",
            dataCaptureDate: "",
            resourceType: "",
            resourceUrl: "",
            heading: "",
            subHeading: "",
            avatarUrl: "",
            pageNames: [],
            birdeyePageMessages: []
        },
        formError: "",
        uploadingImage: false,
        uploadingAvatar: false
    };

    componentDidMount() {
        const { location, match, fetchEffectRequest } = this.props;
        if (location.state && location.state.data) {
            this.setState({ data: {...location.state.data, pageNames: this.getPageNames(location.state.data.birdeyePageMessages)} });
        } else if (match.params.businessNumber) {
            fetchEffectRequest({ businessNumber: match.params.businessNumber });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.curData.businessNumber !== prevProps.curData.businessNumber) {
            this.setState({ data: {...this.props.curData, pageNames: this.getPageNames(this.props.curData.birdeyePageMessages)} });
        }
    }

    getImageUploadUrl = (type) => {
        let timestamp = Date.now().toString();
        const { businessUrl, businessName, businessNumber } = this.state.data;
        const imgName = businessName ? `${businessUrl ? businessUrl : getSlug(businessName)}-${businessNumber}` : "";
        return `${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=${imgName}-${type}&timestamp=${timestamp}&webp=true`;
    }

    handleInputChange = (e, imageFieldName) => {
        if (e.target) {
            const { name, value } = e.target;
            this.handleFieldChange(name, value);
        } else if (e.file) {
            if (e.file.status === "uploading") {
                imageFieldName === "avatarUrl" ? this.setState(() => ({uploadingAvatar: true})) : this.setState(() => ({uploadingImage: true}));
            } else if (e.file.status === "done") {
                imageFieldName === "avatarUrl" ? this.setState(() => ({uploadingAvatar: false})) : this.setState(() => ({uploadingImage: false}));
            }
            if (e.file.response) {
                const { imageUrl } = e.file.response;
                this.handleFieldChange(imageFieldName, imageUrl);
            }
        }
    };

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        if (name === "allPages") {
            data["pageNames"] = this.setPageNames(value);
        } else if  (name === "pageNames") {
            data["allPages"] = false;
        }
        this.setState({ data, formError: "" });
    };

    isValidResourceUrl = (url) => {
        return  url.match(/(https?:\/\/(.+?\.)?birdeye\.com(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/);
    }

    handleSubmit = (e, isDelete = false) => {
        e.preventDefault();
        const { resourceType, avatarUrl } = this.state.data;
        let data = pick(this.state.data, ["reviewImg", "prevReviewImg", "businessNumber"]);
        // construct the data for API here....
        if ((data.prevReviewImg && !data.reviewImg) || (data.reviewImg && !data.prevReviewImg)) {
            this.setState({ formError: "Please upload both images" });
            return;
        };
        if (resourceType) {
            const resourceData =  resourceType.toLowerCase() === "review" ? pick(this.state.data, ["resourceType", "heading", "subHeading"])
                : pick(this.state.data, ["resourceType", "resourceUrl", "heading", "subHeading"]);
    
            if (includes(resourceData, "") || includes(resourceData, null) || includes(resourceData, undefined)) {
                this.setState({ formError: "Please fill in all relevant fields!" });
                return;
            } else if (resourceData.resourceUrl && !this.isValidResourceUrl(resourceData.resourceUrl)) {
                this.setState({ formError: "Please enter a valid resource url" });
                return;    
            };
            data = {...data, ...resourceData, avatarUrl, birdeyePageMessages: this.formatPageNames(this.state.data.pageNames)};
        };
        data = {...data, isDelete: !!isDelete};
        this.props.updateEffectRequest({ data, cb: this.renderFeedback });
    };

    formatPageNames = (pageNames) => {
        if (!pageNames || !pageNames.length) {
            return [];
        }
        return pageNames.map((page) => {
            return {
                pageId: Number.parseInt(page)
            };
        });
    }

    getPageNames = (pageArr) => {
        if (!pageArr || !pageArr.length) {
            return [];
        }
        const validIds = this.setPageNames(true);
        const newPageArr = compact(pageArr.map((pageObj) => {
            const id = pageObj.pageId.toString();
            if (validIds.indexOf(id) !== -1) return id;
            return "";
        }));
        return newPageArr;
    }

    getPageEnv = () => {
        return process.env.NODE_ENV === "production" ? "production" : "demo";
    }

    setPageNames = (all) => {
        const env = this.getPageEnv();
        if (all) {
            return flatten(lookup.pageOptions[env].map((pageObj) => {
                const options = pageObj.options.map((optionObj) => {
                    return optionObj.value;
                });
                return options;
            }));
        } else {
            return [];
        }
    }

    clearPages = () => {
        this.setState({
            data: {
                ...this.state.data,
                pageNames: [],
                allPages: false
            }
        });
    }

    handleImageDelete = (event) => {
        this.setState(curState => {
            curState.data["prevReviewImg"] = "";
            curState.data["reviewImg"] = "";
            return curState;
        }, () => {
            this.handleSubmit(event, true);
        });
    }

    handleAvatarDelete = (event) => {
        this.setState(curState => {
            curState.data["avatarUrl"] = null;
            return curState;
        }, () => {
            this.handleSubmit(event);
        });
    }

    renderFeedback = (errorType) => {
        const goToEffectsPage = () => this.props.history.push("/effects");
        if (!errorType) {
            return Modal.success({
                title: "You have successfully edited this record",
                content: "Check the updated record in effects list page",
                okText: "Go to effects page",
                maskClosable: true,
                onOk() {
                    goToEffectsPage();
                }
            });
        } else {
            return Modal.warning({
                title: "The record was not edited successfully...",
                content: "Please check your input or try again later..."
            });
        }
    };

    renderFormBlock = () => {
        const {
            businessNumber,
            businessName,
            label,
            address,
            avgRating,
            reviewCount,
            reviewImg,
            prevAvgRating,
            prevReviewCount,
            prevReviewImg,
            signupDate,
            dataCaptureDate,
            resourceType,
            resourceUrl,
            heading,
            subHeading,
            avatarUrl,
            pageNames,
            allPages
        } = this.state.data;

        const { uploadingImage, uploadingAvatar } = this.state;
        const isReview = resourceType && resourceType.toLowerCase() === "review";
        const env = this.getPageEnv();

        if (this.state.isEditPage && this.props.error === "effectNotFound") {
            return <Alert message="Effect not found" type="error" showIcon />;
        } else {
            return (
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Business Name">
                        <span className="ant-form-text">{businessName}</span>
                    </FormItem>
                    <FormItem {...formItemLayout} label="Info">
                        <span className="ant-form-text">Business Number: {businessNumber}</span><br/>
                        <span className="ant-form-text">{label} | {address}</span><br/>
                        <span className="ant-form-text">Signup Date: {signupDate}</span><br/>
                        <span className="ant-form-text">Data Capture Date: {dataCaptureDate}</span>
                    </FormItem>
                    <FormItem {...formItemLayout} label={`"Before"`}>
                        <span className="ant-form-text">{prevAvgRating} star | {prevReviewCount} reviews</span><br/>
                        <Upload
                            name="files"
                            action={this.getImageUploadUrl("before")}
                            withCredentials
                            listType="picture"
                            onChange={(e) => this.handleInputChange(e, "prevReviewImg")}>
                            <Button style={{width: 200}}>
                                <Icon type="upload" /> Upload "Before" Image
                            </Button>
                        </Upload>
                        <div />
                        {/* {   prevReviewImg &&
                            <div>
                                <Button style={{width: 200}} type="danger" onClick={() => this.handleImageDelete("before")}>
                                    <Icon type="delete" /> Delete "Before" Image
                                </Button>
                            </div>
                        } */}
                        <img className="mx-width-360 mt1" src={prevReviewImg} alt=""/>
                    </FormItem>
                    <FormItem {...formItemLayout} label={`"After"`}>
                        <span className="ant-form-text">{avgRating} star | {reviewCount} reviews</span><br/>
                        <span>
                            <Upload
                                layout="inline"
                                name="files"
                                action={this.getImageUploadUrl("after")}
                                withCredentials
                                listType="picture"
                                onChange={(e) => this.handleInputChange(e, "reviewImg")}>
                                <Button style={{width: 200}}>
                                    <Icon type="upload" /> Upload "After" Image
                                </Button>
                            </Upload>
                        </span>
                        <img className="mx-width-360 mt1" src={reviewImg} alt=""/>
                    </FormItem>
                    {(reviewImg || prevReviewImg) && <FormItem {...formButtonLayout}>
                        <Button style={{width: 200}} type="danger" onClick={(e) => this.handleImageDelete(e)} disabled={uploadingImage ? true : false}>
                            Delete Images
                        </Button>
                    </FormItem>}
                    <FormItem {...formItemLayout} label="Resource Type">
                        <Select
                            value={resourceType || []}
                            placeholder="Select resource type"
                            onChange={(value) =>
                                this.handleFieldChange("resourceType", value)
                            }>
                            {lookup.options.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    {
                        resourceType &&  <FormItem {...formItemLayout} label="Resource Heading">
                            <Input
                                name="heading"
                                value={heading}
                                placeholder="Resource heading"
                                onChange={this.handleInputChange}
                            />
                        </FormItem>
                    }
                    {
                        resourceType && <FormItem {...formItemLayout} label="Resource Sub Heading">
                            <Input
                                name="subHeading"
                                value={subHeading}
                                placeholder="Resource sub heading"
                                onChange={this.handleInputChange}
                            />
                        </FormItem>
                    }
                    {
                        resourceType && resourceType.toLowerCase() !== "review" && <FormItem {...formItemLayout} label="Resource URL">
                            <Input
                                name="resourceUrl"
                                value={resourceUrl}
                                placeholder="Resource url"
                                onChange={this.handleInputChange}
                            />
                        </FormItem>
                    }
                    {
                        isReview &&  <FormItem {...formItemLayout} label={`Avatar:`}>
                            <span className="ant-form-text">Reviewer Avatar Image</span><br/>
                            <span>
                                <Upload
                                    layout="inline"
                                    name="files"
                                    action={this.getImageUploadUrl("avatar")}
                                    withCredentials
                                    listType="picture"
                                    onChange={(e) => this.handleInputChange(e, "avatarUrl")}>
                                    <Button style={{width: 200}}>
                                        <Icon type="upload" /> Upload "Avatar" Image
                                    </Button>
                                </Upload>
                            </span>
                            <img className="mx-width-360 mt1" src={avatarUrl} alt=""/>
                        </FormItem>
                    }
                     {(isReview && avatarUrl) && <FormItem {...formButtonLayout}>
                         <Button style={{width: 200}} type="danger" onClick={(e) => this.handleAvatarDelete(e)} disabled={uploadingAvatar ? true : false}>
                             Delete Avatar
                         </Button>
                     </FormItem>}
                    {
                        isReview && <FormItem {...formItemLayout} label="Associated Page">
                            <Select
                                mode="multiple"
                                value={pageNames || []}
                                placeholder="Select page name"
                                onChange={(value) =>
                                    this.handleFieldChange("pageNames", value)
                                }
                            >
                                {lookup.pageOptions[env].map((grp) => {
                                    const options = grp.options.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ));
                                    return (
                                        <OptGroup label={grp.label} key={grp.label}> 
                                            { options }
                                        </OptGroup>
                                    );
                                })}
                            </Select>
                            <Checkbox
                                checked={allPages}
                                onChange={
                                    (e) => this.handleFieldChange("allPages", e.target.checked)
                                    }
                            >Associate on All pages</Checkbox>
                            <Button type="primary" htmlType="button" onClick={this.clearPages} disabled={pageNames && pageNames.length ? false : true}>
                                Clear
                            </Button>
                        </FormItem>  
                    }
                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit" disabled={uploadingAvatar || uploadingImage ? true : false}>
                            Save
                        </Button>
                    </FormItem>
                </Form>
            );
        }
    };

    render() {
        const { isLoading, error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="effects-root">
                <h2>Edit Effect</h2>
                {isLoading ? (
                    <Spin />
                ) : (
                    this.renderFormBlock()
                )}
            </div>
        );
    }
}

const UpdateEffect = Form.create()(EffectForm);

UpdateEffect.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    curData: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    error: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchEffectRequest: PropTypes.func.isRequired,
    updateEffectRequest: PropTypes.func.isRequired
};

export default UpdateEffect;

import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import ReactHtmlParser from "react-html-parser";

import "./NewsletterPreview.scss";

const getMediaExtension = fileUrl => {
    const ext = fileUrl.toLowerCase().match(/\.(jpeg|jpg|png|gif|mp4)$/);
    return (ext && ext[1]) || null;
};

const NewsletterPreview = ({title, features, emailHtml}) => (
    <div className={"main"}>
        {
            !isEmpty(emailHtml) ? (
                ReactHtmlParser(emailHtml)
            ) : (
                <div className={"wrapper"}>
                    <div className={"header"}>
                        <a href={`${process.env.REACT_APP_WEBSITE_URL}`} target={"_blank"}>
                            <img src={"https://d3cnqzq0ivprch.cloudfront.net/support-api/production/newFeature/birdeye_logo-1566886935212.png"}/>
                        </a>
                        <h1>{title}</h1>
                    </div>
                    <div className={"features"}>
                        {
                            !!features.length && features.map((data, key) => {
                                const
                                    {title, imageUrl: mediaUrl, shortSummary, detailsUrl} = data,
                                    websiteBaseUrl = process.env.REACT_APP_WEBSITE_URL,
                                    mediaExtension = getMediaExtension(mediaUrl),
                                    imgExtensions = ["jpg", "jpeg", "png", "gif"], videoExtensions = ["mp4"];

                                return !isEmpty(title) && !isEmpty(shortSummary) && (
                                    <div className={"feature"} key={title}>
                                        {
                                            !isEmpty(mediaUrl) && mediaExtension && (
                                                ((imgExtensions.indexOf(mediaExtension) !== -1) && (
                                                    <div className={"media"} key={key}>
                                                        <img alt={title} src={mediaUrl}/>
                                                    </div>
                                                )) || ((videoExtensions.indexOf(mediaExtension) !== -1) && (
                                                    <div className={"media"} key={key}>
                                                        <video controls>
                                                            <source src={mediaUrl} type={`video/${mediaExtension}`}/>
                                                            Your browser does not support the videos.
                                                        </video>
                                                    </div>
                                                ))
                                            )
                                        }
                                        <div className={"content"}>
                                            <h2 className={"title"}>{title}</h2>
                                            <div className={"shortSummary"}>{ReactHtmlParser(shortSummary)}</div>
                                            {
                                                !isEmpty(detailsUrl) && (
                                                    <a className={"readMoreBtn"} href={`${websiteBaseUrl}updates/${detailsUrl}`} target={"_blank"}>Read more</a>
                                                )
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            )
        }
    </div>
);

NewsletterPreview.propTypes = {
    title: PropTypes.string.isRequired,
    features: PropTypes.array.isRequired,
    emailHtml: PropTypes.string
};

export default NewsletterPreview;

import React, { Component } from "react";
import { Tag, Icon, Card, Col, Row, Pagination, Tooltip, Input } from 'antd';
import { RedirectWrapper, PageHeaderContainer } from "../../components";
import _ from "lodash";

class PartnerDocumentLibrary extends Component {

    constructor(props) {
        super();
        this.pageKey = "partner";
        switch (props.userData.account_type) {
            case 2:
                this.type = "coBranded";
                break;
            case 3:
                this.type = "whitelabeled";
                break;
            default:
                this.type = "both";
        }
        this.listData = [];
        this.displayList = [];
        this.state = {
            searchText: '',
            pageNumber: 1
        }

    }

    componentDidMount() {
        this.props.fetchPartnerDocumentRequest();
    }

    componentDidUpdate(preState) {
        if (!this.props.location.pathname.includes(preState.match.params.category)) {
            this.setState({ searchText: '', pageNumber: 1 });
        }        
    }
    
    getPageTitle = (category) => {
        switch (category) {
            case "sales-decks": return "Sales Decks";
            case "product-brochures": return "Product Brochures";
            case "competitive": return "Competitive Materials";
            case "prospecting-kits": return "Prospecting Kits";
            case "email-templates": return "Email Templates";
            case "quick-start-guides": return "Quick Start Guides";
            case "case-studies": 
            default: return "Case Studies";
        }
    }

    handleSearch = (value) => {
        this.setState({searchText: value.target.value, pageNumber: 1});
    }

    getDisplayList = (category) => {
        if (this.state.searchText) {
            return this.props.list.filter(item => item.category === category && (this.type !== 'both' ? item.partner_type === this.type : true) && item.title.toLowerCase().includes(this.state.searchText.toLowerCase()));
        } else return this.props.list.filter(item => item.category === category && (this.type !== 'both' ? item.partner_type === this.type : true));
    }

    handlePageChange = (page) => {
        if (page !== this.state.pageNumber) this.setState({pageNumber: page});
    }

    getTag = (partnerType) => {
        switch (partnerType) {
            case "coBranded": return <Tag color="blue">Co-Branded</Tag>;
            case "whitelabeled": return <Tag color="green">Whitelabeled</Tag>
            default: return ""
        }
    }

    render() {
        const { error, userData } = this.props;
        const { pageNumber, searchText } = this.state;
        const unauthorized = error === "unauthorized" || !userData.name;
        
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }
        const showEdit = userData.privileges.includes("partner+");
        const category = !_.isEmpty(this.props.match.params.category) ? this.props.match.params.category : "";
        this.listData = this.getDisplayList(category);

        const itemsPerPage = 12;
        const startIndex = (pageNumber - 1) * itemsPerPage;
        const endIndex = pageNumber * itemsPerPage;
        this.displayList = this.listData.slice(startIndex, endIndex);
        const pageTitle = this.getPageTitle(category);

        return (
            <div>
                <PageHeaderContainer pageKey={this.pageKey} title={pageTitle} additional={{ urlQuery: { category } }} />
                <div className="tableWrapper">
                    <Input
                        placeholder="Search an item"
                        size="large"
                        value={searchText}
                        onChange={this.handleSearch}
                    />
                    <Row gutter={16} className="rowSection">
                        {this.displayList.map((row, i) => {
                            return (
                                <Col span={6} className="gutter-row">
                                    <div key={i} className="card-wrapper">
                                        <Card bordered={false} cover={
                                            <a href={`https://drive.google.com/file/d/${row.file_id}`} target="blank" title="preview" className="img-wrapper">
                                                <img
                                                    alt="Preview image"
                                                    src={row.preview_image}
                                                />
                                                <Icon type="eye" className="view-icon" style={{ color: '#08c' }} />
                                            </a>
                                        }>
                                            <div className="cardRowWrapper">
                                                <div className="textRegion">
                                                    {this.type === 'both' && this.getTag(row.partner_type)}
                                                    <p className="title">{row.title}</p>
                                                    <p className="description">{row.description}</p>
                                                    <div className="link-wrapper">                                                        
                                                        {row.file_id &&
                                                            <>
                                                            <Tooltip placement="top" title={"Download"}>
                                                                <a href={row.document} className="link" title="download" >
                                                                    <Icon type="download" style={{ color: '#ffa500' }} />
                                                                </a>
                                                            </Tooltip>
                                                            <Tooltip placement="top" title={"Asset preview"}>
                                                                <a href={`https://drive.google.com/file/d/${row.file_id}`} target="blank" className="link" title="link url" >
                                                                    <Icon type="eye" style={{ color: '#08c' }} />
                                                                </a>
                                                            </Tooltip>
                                                            </>
                                                        }
                                                        {showEdit && <Tooltip placement="top" title={"Edit"}>
                                                            <a title="edit" href={`/#/partner/document/manage/${row.id}`}>
                                                                <Icon type="edit" style={{ color: 'dimgrey' }} />
                                                            </a>
                                                        </Tooltip>}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </Col>
                           )
                        })}
                        {!this.displayList.length && 
                            <>
                                <div className="no-data">
                                <p>No data available</p>
                                </div>
                            </>
                        }
                    </Row>
                    {!!this.listData.length && <Pagination size="small" current={pageNumber} total={this.listData.length} onChange={this.handlePageChange} defaultPageSize={itemsPerPage} />}
                </div>
            </div>
        );
    }
}

export default PartnerDocumentLibrary;

import { TwilioAction } from "./action";

export let TwilioState = {
    isLoading: false,
    response: {} //
};

const twilioReducer = (state = TwilioState, action) => {
    
    switch(action.type) {
        case TwilioAction.PHONE_NUMBER_UPLOAD_REQUEST:
            return {
                ...state,
                isLoading: true, //
            };
        case TwilioAction.PHONE_NUMBER_UPLOAD_SUCCESS:
        case TwilioAction.PHONE_NUMBER_UPLOAD_ERROR:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default twilioReducer;
import { takeLatest, call, put } from "redux-saga/effects";
import ScanToolActions, * as actionCreator from "./actions";
import ScanToolAPI from "./http";

const scanToolSaga = [
    takeLatest(ScanToolActions.REQ_BUSINESS_SIGN_UP, reqBusinessDetails),
    takeLatest(ScanToolActions.GET_BUSINESS_SCAN_TOOL_HISTORY, reqBusinessScanToolHistory)
];

function* reqBusinessDetails({ payload }) {
    try {
        const {data} = yield call(ScanToolAPI.reqBusinessSignUpDetails, payload);
        yield put(actionCreator.successBusinessDetails(data));
    } catch (err) {
        console.log(err.data,"errorBusinessDetails")
        yield put(actionCreator.errorBusinessDetails(err.data.data || {
            "code": 9000,
            "message": "INTERNAL SERVER ERROR"
        }));
    }
}

function* reqBusinessScanToolHistory({ payload }){
    try {
        const { data } = yield call(ScanToolAPI.getBusinessScanToolHistory, payload);
        yield put(actionCreator.successBusinessScanToolHistory(data));
    } catch (error) {
        yield put(actionCreator.errorBusinessScanToolHistory(error || {}));
    }
}

export default scanToolSaga;
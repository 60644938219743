import Configure from "./Configure";
import Customize from "./Customize";
import Overview from "./Overview";
import MeetingInvite from "./MeetingInvite";


const constants = {
    steps: {
        calendar: [
            {
                title: "Configure",
                content: Configure,
                dataKey: "configure"
            },
            {
                title: "Customise calendar page",
                content: Customize,
                dataKey: "customize"
            },
            {
                title: "Customize communication",
                content: MeetingInvite,
                dataKey: "meetingInvite"
            },
            {
                title: "Publish",
                content: Overview,
                dataKey: "overview",
                isIgnored: true
            }
        ]
    },
    valueList: {
        durationList: [15, 30, 45, 60, 90],
        requestTypes: [
            { label: "Create a meeting only", value: "CALENDAR" },
            { label: "Create a meeting and a lead", value: "CALENDAR_LEAD" },
            { label: "Create a meeting and SFDC case", value: "CALENDAR_SFDC_CASE" }
        ],
        distributionType: [
            { label: "Optimize for availability", value: "Based_on_Availability" },
            { label: "Optimize for equal distribution", value: "Round_Robin" }
        ],
        pageTypes: [
            { label: "Personal", value: "personal" },
            { label: "Group", value: "group" }
        ],
        countryHolidays: ["Australia", "Canada", "India", "New Zealand", "United Kingdom", "United States"],
        slotsInterval: [
            { label: "Every 15 minutes", value: 15 },
            { label: "Every 30 minutes", value: 30 },
            { label: "Every 45 minutes", value: 45 },
            { label: "Every 60 minutes", value: 60 }
        ],
        countryWebsite: ["https://birdeye.com/cal/", "https://getbirdeye.com.au/cal/"],
        additionalAttendees: [
            { label: "Yes", value: true},
            { label: "No", value: false}
        ],
        activeStatuses: [
            { label: "true", value: "true"},
            { label: "false", value: "false"}
        ],
        confirmationAndReminder: [
            { label: 'Email', value: 'email' },
            { label: 'Text', value: 'text' }
        ],
        calendarPageSequence: [
            { label: "Show time slots before the lead form", value: "SHOW_TIME_SLOTS" },
            { label: "Show lead form before the time slots", value: "SHOW_LEAD_FORM" }
        ]
    },
    meetingInviteData: {
        calendarMeetingTitle: "Meeting scheduled with <organizerName> and <attendeeName>",
        calendarMeetingDescription: "Hello <attendeeName>, \n\nThanks for scheduling time with <organizerName>. \n\nPlease join the meeting using the joining instructions in the invite. \nIf you want to reschedule, Please click here <rescheduleMeetingUrl> \n\nContact details:\n<businessName>\n<attendeeName>\n<attendeePhone> ",
        meetingConfirmationEmailSubject: "Your Birdeye meeting has been scheduled",
        meetingConfirmationEmailBody: "Hi <attendeeName> \n\nThanks for scheduling time with <organizerName>. Your meeting has been confirmed for <meetingTime> and you will shortly receive a separate calendar invite for the meeting with the instructions to join. \n\nThanks & Looking forward to talking to you! \nBirdeye",
        meetingConfirmationTextCustomization: "Hi <attendeeName>, This is <organizerName> from Birdeye! I am excited to talk to you on <meetingTime>. Looking forward to our meeting!.",
        meetingReminderEmailSubjectFirst: "Our upcoming meeting for <meetingDate>",
        meetingReminderEmailBodyFirst: "Hi <attendeeName> \n\nYou have an upcoming meeting with <organizerName> for <meetingTime>. You must already have a calendar invite with the instructions to join the meeting. \n\nThanks \nBirdeye",
        meetingReminderEmailSubjectSecond: "Our meeting is scheduled for <meetingTimeOnly> today",
        meetingReminderEmailBodySecond: "Hi <attendeeName> \n\nLooking forward to connecting with you shortly. We will be using this link for the meeting: Link <meetingJoinUrl> \n\nThanks \nBirdeye",
        meetingReminderTextCustomizationFirst: "Hi <attendeeName>, Just wanted to confirm if we’re all set to meet tomorrow at <meetingTimeOnly>! If you have any questions, let us know by simply replying to this text",
        meetingReminderTextCustomizationSecond: "Hey <attendeeName>, <organizerName> from Birdeye! Excited to talk to you today at <meetingTimeOnly>. You can join by simply clicking here: <meetingJoinUrl>"
    }
};

export default constants;

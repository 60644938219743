import { connect } from "react-redux";

import {
    fetchUsersListRequest,
    fetchUserRequest,
    addUserRequest,
    updateUserRequest,
    fetchRoleRequest,
    addRoleRequest,
    updateRoleRequest,
    fetchUserRolesListRequest,
    fetchPermissionsList,
    fetchPermission,
    updatePermission,
    addPermission,
    deleteUserRequest,
    deleteUserRolesRequest,
    deletePermissionRequest,
} from "./actions";

import Users from "./Users";
import UpdateUser from "./UpdateUser";
import Permissions from "./Permissions";

import UsersRoles from "./UsersRoles";
import UpdateUserRoles from "./UpdateUserRoles";
import UpdatePermission from "./UpdatePermission";

const mapStateToProps = (state) => ({
    ...state.User
});

export const UsersPage = connect(
    mapStateToProps,
    { fetchUsersListRequest, fetchUserRolesListRequest, fetchPermissionsList, deleteUserRequest }
)(Users);

export const UpdateUserPage = connect(
    mapStateToProps,
    { fetchUserRequest, addUserRequest, updateUserRequest, fetchUserRolesListRequest, fetchPermissionsList }
)(UpdateUser);

export const UsersRolesPage = connect(
    mapStateToProps,
    { fetchUserRolesListRequest, fetchPermissionsList, deleteUserRolesRequest }
)(UsersRoles);

export const UpdateUserRolesPage = connect(
    mapStateToProps,
    { fetchPermissionsList, fetchRoleRequest, updateRoleRequest, addRoleRequest }
)(UpdateUserRoles);

export const PermissionsPage = connect(
    mapStateToProps,
    { fetchPermissionsList, deletePermissionRequest }
)(Permissions);

export const UpdatePermissionPage = connect(
    mapStateToProps,
    { fetchPermission, updatePermission, addPermission, fetchUserRolesListRequest, fetchUsersListRequest }
)(UpdatePermission);
import React, { Component } from "react";
import {Redirect} from "react-router-dom";
import {Spin, Table, Tag} from "antd";

class Emails extends Component  {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        this.props.reqEmailsPageData();
    }

    render() {

        const { error, userData, isLoading, pageData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        if(isLoading) {
            return <Spin />;
        }

        const {emails: thisPageData} = pageData;
        const isTableLoading = thisPageData.isTableLoading;
        const records = thisPageData.tableContent || [];

        const columns = [
            {
                title: 'First Name',
                dataIndex: 'fName',
                key: 'fName',
                width: '20%',
                render: value => (!!value ? value : '-'),
                searchable: true,
            }, {
                title: 'Last Name',
                dataIndex: 'lName',
                key: 'lName',
                width: '20%',
                render: value => (!!value ? value : '-'),
                searchable: true,
            }, {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                width: '40%',
                searchable: true,
                render: value => (<a href={"maito:" + value}>{value}</a>)
            }, {
                title: 'Status',
                dataIndex: 'unsubscribed',
                key: 'unsubscribed',
                width: '20%',
                render: value => (<span>{!value ? (<Tag color={"geekblue"}>SUBSCRIBED</Tag>) : (<Tag color={"volcano"}>UNSUBSCRIBED</Tag>)}</span>)
            }
        ];

        return (
            <div>
                <h2>FreeProd Yesterday Created Emails</h2>
                <Table className={"ant-table-bordered"} style={{ marginTop: 20, overflowY: "auto" }} size={"medium"} showHeader={true} columns={columns} dataSource={records} loading={isTableLoading} pagination={false} scroll={{y: 450}}/>
            </div>
        );
    }
}

export default Emails;
import { UpsellActions } from "./actions";

const DEFAULT_STATE = {
    isLoading: false,
    productMessageLookup: {},
    productMessageList: [],
    industryList: []
};

const upsell = (state = DEFAULT_STATE,  action) => {
    switch (action.type) {
        case UpsellActions.FETCH_ALL_PRODUCT_MESSAGE_REQUEST:
        case UpsellActions.UPDATE_PRODUCT_MESSAGE_REQUEST:
        case UpsellActions.DELETE_PRODUCT_MESSAGE_REQUEST:
        case UpsellActions.UPDATE_PRODUCT_MESSAGE_INDUSTRY_IMAGE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case UpsellActions.FETCH_ALL_PRODUCT_MESSAGE_REQUEST_SUCCESS:
        case UpsellActions.FETCH_ALL_PRODUCT_MESSAGE_REQUEST_ERROR:
            return {
                ...state,
                isLoading: false,
                [action.isCache ? "productMessageLookup" : "productMessageList"]: action.payload
            };
        case UpsellActions.UPDATE_PRODUCT_MESSAGE_SUCCESS:
        case UpsellActions.UPDATE_PRODUCT_MESSAGE_ERROR:
        case UpsellActions.DELETE_PRODUCT_MESSAGE_ERROR:
        case UpsellActions.UPDATE_PRODUCT_MESSAGE_INDUSTRY_IMAGE_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case UpsellActions.DELETE_PRODUCT_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                productMessageList: state.productMessageList.filter(({id}) => (action.payload.id !== id))
            };
        case UpsellActions.UPDATE_PRODUCT_MESSAGE_INDUSTRY_IMAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                productMessageList: state.productMessageList.map((message) => {

                    if (message.id != action.payload.id) return message;
                    return {
                        ...message,
                        industry_image: { ...message.industry_image, ...action.payload.industry_image }
                    }
                })
            };
        default:
            return state;
    }
};

export default upsell;
import { takeLatest, call, put } from "redux-saga/effects";
import ReviewActions, * as actionCreator from "./actions";
import reviewApi from "./https";

const businessActionsSagas = [
    takeLatest(ReviewActions.FETCH_BIRDEYE_REVIEWS, fetchBirdeyeReviews),
    takeLatest(ReviewActions.DELETE_REVIEW_REQ, deleteReviewReq),
];

function* fetchBirdeyeReviews(action) {
    try {
        const resp = yield call(reviewApi.getReviews, action.payload);
        yield put(actionCreator.fetchBirdeyeReviewsSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.fetchBirdeyeReviewsError(e.data.data || { error: "fetchBirdeyeReviewsError" })
        );
    }
}

function* deleteReviewReq(action) {
    try {
        const resp = yield call(reviewApi.deleteReviewReq, action.payload);
        yield put(actionCreator.deleteReviewReqSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(
            actionCreator.deleteReviewReqError({"message":"Unable to delete review"})
        );
        yield call(action.payload.cb, e);
    }
}

export default businessActionsSagas;

import { takeLatest, call, put } from "redux-saga/effects";
import ResourcesV2Actions, * as actionCreator from "./actions";
import ResourcesV2API from "./http";

const resourcesV2Sagas = [
    takeLatest(ResourcesV2Actions.FETCH_EBOOK_LIST_REQUEST, fetchEbookList),
    takeLatest(ResourcesV2Actions.FETCH_EBOOK_BYID_REQUEST, fetchEbookById),
    takeLatest(ResourcesV2Actions.ADD_EBOOK_REQUEST, addEbook),
    takeLatest(ResourcesV2Actions.UPDATE_EBOOK_REQUEST, updateEbook),

    takeLatest(ResourcesV2Actions.FETCH_WP_LIST_REQUEST, fetchWPList),
    takeLatest(ResourcesV2Actions.FETCH_WP_BYID_REQUEST, fetchWPById),
    takeLatest(ResourcesV2Actions.ADD_WP_REQUEST, addWP),
    takeLatest(ResourcesV2Actions.UPDATE_WP_REQUEST, updateWP),

    takeLatest(ResourcesV2Actions.FETCH_GUIDE_LIST_REQUEST, fetchGuideList),
    takeLatest(ResourcesV2Actions.FETCH_GUIDE_BYID_REQUEST, fetchGuideById),
    takeLatest(ResourcesV2Actions.ADD_GUIDE_REQUEST, addGuide),
    takeLatest(ResourcesV2Actions.UPDATE_GUIDE_REQUEST, updateGuide),

    takeLatest(ResourcesV2Actions.FETCH_ARTICLE_LIST_REQUEST, fetchArticleList),
    takeLatest(ResourcesV2Actions.FETCH_ARTICLE_BYID_REQUEST, fetchArticleById),
    takeLatest(ResourcesV2Actions.ADD_ARTICLE_REQUEST, addArticle),
    takeLatest(ResourcesV2Actions.UPDATE_ARTICLE_REQUEST, updateArticle),

    takeLatest(ResourcesV2Actions.FETCH_PODCAST_LIST_REQUEST, fetchPodcastList),
    takeLatest(ResourcesV2Actions.FETCH_PODCAST_BYID_REQUEST, fetchPodcastById),
    takeLatest(ResourcesV2Actions.ADD_PODCAST_REQUEST, addPodcast),
    takeLatest(ResourcesV2Actions.UPDATE_PODCAST_REQUEST, updatePodcast),
    takeLatest(ResourcesV2Actions.DELETE_PODCAST_REQUEST, deletePodcast)
];

/**
 * Ebook
 */

function* fetchEbookList() {
    try {
        const resp = yield call(ResourcesV2API.getEbook);
        yield put(actionCreator.fetchEbookListSuccess(resp.data || []));
    } catch (e) {
        yield put(
            actionCreator.fetchEbookListError([])
        );
    }
}

function* fetchEbookById(action) {
    try {
        const resp = yield call(ResourcesV2API.getEbookById, action.payload);
        yield put(actionCreator.fetchEbookByIdSuccess(resp.data || {}));
    } catch (e) {
        yield put(
            actionCreator.fetchEbookByIdError({})
        );
    }
}

function* addEbook(action) {
    try {
        const resp = yield call(ResourcesV2API.addEbook, action.payload);
        const response = resp.data.response ? { message: "Card Heading Name already exists.", type: "error", timestamp: (Date.now().toString()) } : { message: "Successfully created this event.", type: "success", timestamp: (Date.now().toString()) };
        yield put(actionCreator.addEbookSuccess(response));
    } catch (e) {
        yield put(
            actionCreator.addEbookError({ error: "Unable to add this ebook. Kindly retry.", type: "error", timestamp: (Date.now().toString()) })
        );
    }
}

function* updateEbook(action) {
    try {
        const resp = yield call(ResourcesV2API.updateEbook, action.payload);
        const response = resp.data.response ? { message: "Card Heading Name already exists.", type: "error", timestamp: (Date.now().toString()) } : { message: "Successfully created this event.", type: "success", timestamp: (Date.now().toString()) };
        yield put(actionCreator.updateEbookSuccess(response));
    } catch (e) {
        yield put(
            actionCreator.updateEbookError({ error: "Unable to update this ebook. Kindly retry.", type: "error", timestamp: (Date.now().toString()) })
        );
    }
}

/**
 * Whitepaper
 */

function* fetchWPList() {
    try {
        const resp = yield call(ResourcesV2API.getWhitepaper);
        yield put(actionCreator.fetchWPListSuccess(resp.data || []));
    } catch (e) {
        yield put(
            actionCreator.fetchWPListError([])
        );
    }
}

function* fetchWPById(action) {
    try {
        const resp = yield call(ResourcesV2API.getWhitepaperById, action.payload);
        yield put(actionCreator.fetchWPByIdSuccess(resp.data || {}));
    } catch (e) {
        yield put(
            actionCreator.fetchWPByIdError({})
        );
    }
}

function* addWP(action) {
    try {
        const resp = yield call(ResourcesV2API.addWhitepaper, action.payload);
        const response = resp.data.response ? { message: "Card Heading Name already exists.", type: "error", timestamp: (Date.now().toString()) } : { message: "Successfully created this event.", type: "success", timestamp: (Date.now().toString()) };
        yield put(actionCreator.addWPSuccess(response));
    } catch (e) {
        yield put(
            actionCreator.addWPError({ error: "Unable to add this whitepaper. Kindly retry.", type: "error",
            timestamp: (Date.now().toString()) })
        );
    }
}

function* updateWP(action) {
    try {
        const resp = yield call(ResourcesV2API.updateWhitepaper, action.payload);
        const response = resp.data.response ? { message: "Card Heading Name already exists.", type: "error", timestamp: (Date.now().toString()) } : { message: "Successfully created this event.", type: "success", timestamp: (Date.now().toString()) };
        yield put(actionCreator.updateWPSuccess(response));
    } catch (e) {
        yield put(
            actionCreator.updateWPError({ error: "Unable to update this whitepaper. Kindly retry.", type: "error", timestamp: (Date.now().toString()) })
        );
    }
}

/**
 * Guide
 */

function* fetchGuideList() {
    try {
        const resp = yield call(ResourcesV2API.getGuide);
        yield put(actionCreator.fetchGuideListSuccess(resp.data || []));
    } catch (e) {
        yield put(
            actionCreator.fetchGuideListError([])
        );
    }
}

function* fetchGuideById(action) {
    try {
        const resp = yield call(ResourcesV2API.getGuideById, action.payload);
        yield put(actionCreator.fetchGuideByIdSuccess(resp.data || {}));
    } catch (e) {
        yield put(
            actionCreator.fetchGuideByIdError({})
        );
    }
}

function* addGuide(action) {
    try {
        const resp = yield call(ResourcesV2API.addGuide, action.payload);
        const response = resp.data.response ? { message: "Card Heading Name already exists.", type: "error", timestamp: (Date.now().toString()) } : { message: "Successfully created this event.", type: "success", timestamp: (Date.now().toString()) };
        yield put(actionCreator.addGuideSuccess(response));
    } catch (e) {
        yield put(
            actionCreator.addGuideError({ error: "Unable to add this guide. Kindly retry.", type: "error", timestamp: (Date.now().toString()) })
        );
    }
}

function* updateGuide(action) {
    try {
        const resp = yield call(ResourcesV2API.updateGuide, action.payload);
        const response = resp.data.response ? { message: "Card Heading Name already exists.", type: "error", timestamp: (Date.now().toString()) } : { message: "Successfully updated this guide.", type: "success", timestamp: (Date.now().toString()) };
        yield put(actionCreator.updateGuideSuccess(response));
        if(action.payload.cb) {
            yield call(action.payload.cb, {type: "success", message: "Successfully updated this guide"});   
        }
    } catch (e) {
        yield put(
            actionCreator.updateGuideError({ error: "Unable to update this guide. Kindly retry.", type: "error", timestamp: (Date.now().toString()) })
        );
    }
}

/**
 * Article
 */

function* fetchArticleList() {
    try {
        const resp = yield call(ResourcesV2API.getArticle);
        yield put(actionCreator.fetchArticleListSuccess(resp.data || []));
    } catch (e) {
        yield put(
            actionCreator.fetchArticleListError([])
        );
    }
}

function* fetchArticleById(action) {
    try {
        const resp = yield call(ResourcesV2API.getArticleById, action.payload);
        yield put(actionCreator.fetchArticleByIdSuccess(resp.data || {}));
    } catch (e) {
        yield put(
            actionCreator.fetchArticleByIdError({})
        );
    }
}

function* addArticle(action) {
    try {
        const resp = yield call(ResourcesV2API.addArticle, action.payload);
        yield put(actionCreator.addArticleSuccess());
        yield call(action.payload.cb, {type: "success", message: "Successfully added this article"});
    } catch (e) {
        yield put(actionCreator.addArticleError());
        yield call(action.payload.cb, {type: "error", message: (e.data && e.data.data && e.data.data.message) || "Unable to add this article. Kindly retry."});
    }
}

function* updateArticle(action) {
    try {
        const resp = yield call(ResourcesV2API.updateArticle, action.payload);
        yield put(actionCreator.updateArticleSuccess());
        yield call(action.payload.cb, {type: "success", message: "Successfully updated this article"});
    } catch (e) {
        yield put(actionCreator.updateArticleError());
        yield call(action.payload.cb, {type: "error", message: (e.data && e.data.data && e.data.data.message) || "Unable to update this article. Kindly retry."});
    }
}


/**
 * Podcast
 */

function* fetchPodcastList() {
    try {
        const { data } = yield call(ResourcesV2API.getPodcast);
        yield put(actionCreator.fetchPodcastListSuccess(data));
    } catch (e) {
        yield put(actionCreator.fetchPodcastListError([]));
    }
}

function* fetchPodcastById(action) {
    try {
        const { data } = yield call(ResourcesV2API.getPodcastById, action.payload.id);
        yield put(actionCreator.fetchPodcastByIdSuccess(data));
    } catch (e) {
        yield put(actionCreator.fetchPodcastByIdError({}));
    }
}

function* addPodcast(action) {
    try {
        yield call(ResourcesV2API.addPodcast, action.payload);
        yield put(actionCreator.addPodcastSuccess({}));
        yield call(action.cb, { type: "success", message: "Successfully adding this podcast" });
    } catch (e) {
        yield put(actionCreator.addPodcastError({}));
        yield call(action.cb, { type: "error", message: "Oops! Something went wrong while adding this podcast" });
    }
}

function* updatePodcast(action) {
    try {
        yield call(ResourcesV2API.updatePodcast, action.payload.id, action.payload.data);
        yield put(actionCreator.updatePodcastSuccess({}));
        yield call(action.cb, { type: "success", message: "Successfully updated this podcast" });
    } catch (e) {
        yield put(actionCreator.updatePodcastError({}));
        yield call(action.cb, { type: "error", message: "Oops! Something went wrong while updating this podcast" });
    }
}

function* deletePodcast(action) {
    try {
        yield call(ResourcesV2API.deletePodcast, action.payload.id);
        yield put(actionCreator.deletePodcastSuccess(action.payload));
        yield call(action.cb, { type: "success", message: "Successfully deleted this podcast" });
    } catch (e) {
        yield put(actionCreator.deletePodcastError({}));
        yield call(action.cb, { type: "error", message: "Oops! Something went wrong while deleting this podcast" });
    }
}

export default resourcesV2Sagas;

import { beAPIResource } from "../../utils/apiHelper";

export const WebinarAPI = {
    reqWebinarSchedule: payload => beAPIResource.post("/support/webinar-tool/schedule", payload),
    reqWebinarEvents: payload => beAPIResource.get("/support/webinar-tool/events", payload),
    reqWebinarEvent: payload => beAPIResource.get("/support/webinar-tool/event", payload),
    reqWebinarPresenters: payload => beAPIResource.get("/support/webinar-tool/presenters", payload),
    reqWebinarEventDelete: ({eventId, resourceId, webinarKey, eventExperience, hostPlatform}) => beAPIResource.delete(`/support/webinar-tool/event/${eventId}/${webinarKey}/${eventExperience}?hostPlatform=${hostPlatform || 'on24'}`),
    reqWebinarRegistrants: payload => beAPIResource.get("/support/webinar-tool/registrants", payload),
    reqWebinarAttendees: payload => beAPIResource.get("/support/webinar-tool/attendees", payload),
    reqWebinarPresenterAdd: payload => beAPIResource.post("/support/webinar-tool/presenter", payload),
    reqWebinarPresenterUpdate: payload => beAPIResource.put("/support/webinar-tool/presenter", payload),
    reqWebinarPresenterDelete: payload => beAPIResource.delete("/support/webinar-tool/presenter", payload),
    getLandingPageWebinar: payload => beAPIResource.get("/support/web/getLandingPageWebinar?source=success"),
    updateLandingPageWebinar: payload => beAPIResource.post("/support/webinar-tool/landing", payload),
    getWebinarRecordingAssets: () => beAPIResource.get("/support/webinar-tool/recording-assets"),
    getWebinarUpcomingSession: (payload) => beAPIResource.get("/support/webinar-tool/upcoming-session-timing", payload),
    refreshWebinarAttendance: (payload) => beAPIResource.get("/support/webinar-support/attendee-registrants", payload),
    reqMeetingSchedule: payload => beAPIResource.post("/support/webinar-tool/meeting/schedule", payload),
    reqMeetingDelete: (payload) => beAPIResource.delete(`/support/webinar-tool/meeting/event`, payload)
};

export default WebinarAPI;

import React from 'react';
import { Route, Switch } from "react-router-dom";

import {
    CaseStudyPage, ContactPage, DeleteBusinessPage,
    EffectsCategoriesPage,
    EffectsPage,
    GetReviewPage,
    Home,
    IntegrationDomainsPage,
    IntegrationSelfServeTabPage, IntegrationRequestedCRMPage,
    IntegrationsPage, PageNotFound, PagesPage, PressesPage, ProductEmailsPage,
    SalesRepsPage, SfdcLeadPage, TransferBusinessPage, SfdcLeadUploadPage,
    UpdateCaseStudyBlockPage,
    UpdateEffectPage,
    UpdateEffectsCategoriesPage,
    UpdateIntegrationDomainPage,
    UpdateIntegrationPage, UpdatePagesPage, UpdatePressPage,
    InactiveEmployeesPage,
    CaseStudyListPage, VideoListPage, UpdateCaseStudyPage, UpdateVideoPage, VideoTutorialsListPage, UpdateVideoTutorialPage,
    UpdateSalesRepPage,
    UploadImageToCDN,
    UpdateUserPage,
    UsersPage,
    UpdateUserRolesPage,
    UsersRolesPage,
    ActivityPage,
    CategoriesPage, UpdateCategoriesPage, AddBeSubCategoryPage, UpdateSicNaicsPage, AddNewIndustryPage,
    FeatureReleasesPage, UpdateFeatureReleasePage, NewsletterPage,
    FreeProdOverviewPage, FreeProdDetailPage, FreeProdSummaryPage, FreeProdEmailsPage,
    CampaignUrlBuilderPage, CampaignUrlBuilderArchivePage,
    EmailServicePage,
    Prospector,
    ScanPage,
    WebinarSchedulePage, WebinarEventPage, WebinarLandingPage,
    AnalyticsPage,
    FixProfilePage,
    SignupPage, GetGmbPage,
    EbookPage, WhitepaperPage, GuidePage, ArticleListPage, ArticleFormPage,
    RequestBusinessAccessPage, AccessRequestHistoryPage,
    PermissionsPage,
    UpdatePermissionPage,
    FeaturePage, CalendarInvitationPage,
    ScitorUploadPage,
    DeleteReviewPage,
    UserSignaturePage, MultipleUserSignaturePage, ManageBannerPage, PartnerDocumentLibraryPage, PartnerFormPage,
    ProductMessageFormPage, ProductMessageListPage,
    FeatureListPage, PodcastPage, FeatureFormByPageTypePage, FeatureListByPageTypePage,
    FeaturedPageManagementPage, 
    PageDetailsPage, AddComponentPage, UpdateComponentPage, FeaturePagePage, ProductBasedLibraryPage, TwilioLookupPage, WebsiteContentPage, UpdateWebsiteContentPage,
    SitemapsListPage, ReportPage, InvalidEmailDomainPage, AddInvalidEmailDomainPage, WebsiteRedirectPage, WebsiteRedirectEditPage, ProductRelease, ProductReleaseUpdate
} from "./pages";

const Routes = () => (
    <Switch>
        <Route exact path="/" component={Home} />

        <Route exact path="/users" component={UsersPage} />
        <Route path="/user/new" component={UpdateUserPage} />
        <Route path="/user/:id" component={UpdateUserPage} />

        <Route exact path="/user-roles" component={UsersRolesPage} />
        <Route path="/user-role/new" component={UpdateUserRolesPage} />
        <Route path="/user-role/:id" component={UpdateUserRolesPage} />
        <Route path="/reviews" component={GetReviewPage} />

        <Route exact path="/sales-reps" component={SalesRepsPage} />
        <Route path="/sales-rep/new" component={UpdateSalesRepPage} />
        <Route path="/sales-rep/:id" component={UpdateSalesRepPage} />
        <Route path="/resource/article/:articleId" component={ArticleFormPage} />
        <Route path="/resource/article" component={ArticleListPage} />
        <Route path="/resource/case-studies" component={CaseStudyListPage} />
        <Route path="/resource/videos" component={VideoListPage} />
        <Route path="/resource/case-study/:id" component={UpdateCaseStudyPage} />
        <Route path="/resource/video/:id" component={UpdateVideoPage} />
        <Route path="/resource/case-study/new" component={UpdateCaseStudyPage} />
        <Route path="/resource/video/new" component={UpdateVideoPage} />
        <Route path="/resource/ebook/:ebookId*" component={EbookPage} />
        <Route path="/resource/whitepaper/:wpId*" component={WhitepaperPage} />
        <Route path="/resource/guide/:guideId*" component={GuidePage} />
        <Route path="/resource/podcast/:podcastId*" component={PodcastPage} />

        <Route path="/resource/video-tutorials" component={VideoTutorialsListPage} />
        <Route path="/resource/video-tutorial/new" component={UpdateVideoTutorialPage} />
        <Route path="/resource/video-tutorial/:id" component={UpdateVideoTutorialPage} />

        <Route path='/case-studies/:case_study_id/blocks/insert/:at_position' component={UpdateCaseStudyBlockPage} />
        <Route path='/case-studies/:case_study_id/blocks/:id' component={UpdateCaseStudyBlockPage} />
        <Route path='/case-studies/:case_study_id/blocks' exact component={UpdateCaseStudyBlockPage} />
        <Route path='/case-studies/:id' component={CaseStudyPage} />

        <Route exact path='/effects' component={EffectsPage} />
        <Route exact path='/effects-categories' component={EffectsCategoriesPage} />
        <Route exact path='/effects-category/:id' component={UpdateEffectsCategoriesPage} />
        <Route path='/effect/:businessNumber' component={UpdateEffectPage} />
        <Route exact path="/integrations" component={IntegrationsPage} />
        <Route path="/integration/new" component={UpdateIntegrationPage} />
        <Route path="/integration/:id" component={UpdateIntegrationPage} />
        <Route exact path="/integration-domains" component={IntegrationDomainsPage} />
        <Route path="/integration-domain/new" component={UpdateIntegrationDomainPage} />
        <Route path="/integration-domain/:id" component={UpdateIntegrationDomainPage} />
        <Route exact path="/integrations/self-serve" component={IntegrationSelfServeTabPage} />
        <Route exact path="/integrations/requested-crm" component={IntegrationRequestedCRMPage} />
        <Route exact path='/presses-news' component={PressesPage} />
        <Route exact path='/presses-announcement' component={PressesPage} />
        <Route path='/press/new' component={UpdatePressPage} />
        <Route path='/press/:id' component={UpdatePressPage} />
        <Route path='/email-overall' component={ProductEmailsPage} />
        <Route path='/email-detail' component={ProductEmailsPage} />
        <Route path='/business-transfer' component={TransferBusinessPage} />
        <Route path='/business-delete' component={DeleteBusinessPage} />
        <Route path='/business-operation/fix-profile' component={FixProfilePage} />
        <Route path="/business-operation/business-signup" component={SignupPage} />
        <Route path='/business-actions/delete-review' component={DeleteReviewPage} />
        <Route path="/gmb/" component={GetGmbPage} />
        <Route exact path='/pages' component={PagesPage} />
        <Route path="/page/invitation/:pageId" component={CalendarInvitationPage} />
        <Route path='/page/new' component={UpdatePagesPage} />
        <Route path='/page/:id' component={UpdatePagesPage} />
        <Route path='/leadgen-mngt/contact/:type' component={ContactPage} />
        <Route path='/leadgen-mngt/sfdc-lead' component={SfdcLeadPage} />
        <Route path='/leadgen-mngt/sfdc-lead-upload' component={SfdcLeadUploadPage} />
        <Route path='/leadgen-mngt/report' component={ReportPage} />
        <Route path='/leadgen-mngt/invalid-email-domain/add' component={AddInvalidEmailDomainPage} />
        <Route path='/leadgen-mngt/invalid-email-domain' component={InvalidEmailDomainPage} />
        <Route exact path='/leadgen-mngt/campaign-url-builder' component={CampaignUrlBuilderPage} />
        <Route exact path='/campaign-url-builder/new' component={CampaignUrlBuilderPage} />
        <Route path='/campaign-url-builder/modify/:urlId' component={CampaignUrlBuilderPage} />
        <Route exact path='/campaign-url-builder/archive' component={CampaignUrlBuilderArchivePage} />
        <Route path='/upload' component={UploadImageToCDN} />
        <Route exact path="/activity" component={ActivityPage} />

        <Route exact path="/categories/add-be-subcategory" component={AddBeSubCategoryPage} />
        <Route exact path="/categories/add-new-industry" component={AddNewIndustryPage} />
        <Route exact path="/categories/:sourceId" component={CategoriesPage} />
        <Route exact path="/categories/:sourceId/:categoryName" component={UpdateCategoriesPage} />
        <Route exact path="/update-sic-naics" component={UpdateSicNaicsPage} />

        <Route exact path="/feature-releases" component={FeatureReleasesPage} />
        <Route path='/feature-release/new' component={UpdateFeatureReleasePage} />
        <Route path='/feature-release/newsletter' component={NewsletterPage} />
        <Route path='/feature-release/:id' component={UpdateFeatureReleasePage} />

        <Route path="/free-prod-dashboard/overview" component={FreeProdOverviewPage} />
        <Route path="/free-prod-dashboard/detail/:topCategoryId?" component={FreeProdDetailPage} />
        <Route path="/free-prod-dashboard/summary" component={FreeProdSummaryPage} />
        <Route path="/free-prod-dashboard/emails" component={FreeProdEmailsPage} />

        <Route exact path="/email-service" component={EmailServicePage} />
        <Route exact path="/email-service/:domain" component={EmailServicePage} />

        <Route exact path='/prospector' component={Prospector} />
        <Route exact path='/scan-tool' component={ScanPage} />

        <Route exact path="/webinar/schedule" component={WebinarSchedulePage} />
        <Route exact path="/webinar/schedule/:eventId" component={WebinarSchedulePage} />
        <Route exact path="/webinar/events" component={WebinarEventPage} />
        <Route exact path="/webinar/landing" component={WebinarLandingPage} />

        <Route path='/analytics' component={AnalyticsPage} />

        <Route path="/request-access" component={RequestBusinessAccessPage} />
        <Route path="/request-history" component={AccessRequestHistoryPage} />

        <Route exact path="/feature-resource" component={FeatureListPage} />
        <Route exact path="/feature-resource/manage" component={FeaturePage} />
        <Route path="/feature-resource/by/:pageType/manage" component={FeatureFormByPageTypePage} />
        <Route path="/feature-resource/by/:pageType" component={FeatureListByPageTypePage} />

        <Route exact path="/synced-website-pages" component={FeaturedPageManagementPage} />
        <Route exact path="/synced-website-pages/new" component={FeaturePagePage} />
        <Route path="/synced-website-pages/:id" component={FeaturePagePage} />

        <Route path='/permissions' component={PermissionsPage} />
        <Route path='/permission/new' component={UpdatePermissionPage} />
        <Route path='/permission/:id' component={UpdatePermissionPage} />

        <Route path="/scitor/optum" component={ScitorUploadPage} />

        <Route exact path="/signature/user" component={UserSignaturePage} />
        <Route exact path="/signature/group" component={MultipleUserSignaturePage} />
        <Route exact path="/signature/banner" component={ManageBannerPage} />

        <Route path="/partner/document/product/:product" component={ProductBasedLibraryPage} />
        <Route path="/partner/document/manage/:documentId" component={PartnerFormPage} />
        <Route path="/partner/document/:category" component={PartnerDocumentLibraryPage} />

        <Route exact path="/meeting/events" component={WebinarEventPage} />
        <Route exact path="/meeting/schedule" component={WebinarSchedulePage} />
        <Route exact path="/meeting/schedule/:eventId" component={WebinarSchedulePage} />
        <Route exact path="/upsell/product-message" component={ProductMessageListPage} />
        <Route exact path="/upsell/product-message/manage" component={ProductMessageFormPage} />

        <Route exact path="/website-content" component={WebsiteContentPage} />
        <Route exact path="/website-content/add" component={UpdateWebsiteContentPage} />
        <Route exact path="/website-content/update-json/:pageId([0-9]+)?" component={UpdateWebsiteContentPage} />
        <Route exact path="/website-content/update/:pageId([0-9]+)?" component={PageDetailsPage} />
        <Route exact path="/website-content/add-component/:pageId([0-9]+)?" component={AddComponentPage} />
        <Route exact path="/website-content/update-component/:componentId([0-9]+)?" component={UpdateComponentPage} />
        <Route exact path="/website-redirects" component={WebsiteRedirectPage} />
        <Route exact path="/website-redirects/edit/:pageId([0-9]+)?" component={WebsiteRedirectEditPage} />

        <Route exact path="/product-release" component={ProductRelease} />
        <Route exact path="/product-release/:id" component={ProductReleaseUpdate} />

        <Route path="/twilio/lookup" component={TwilioLookupPage} />

        <Route path="/sitemaps/lookup" component={SitemapsListPage} />
        
        <Route exact path="/inactive-employees" component={InactiveEmployeesPage} />

        <Route component={PageNotFound} />
    </Switch>
);

export default Routes;

import { createActionTypes } from "../../utils";

export const FeatureResourceActions = createActionTypes("featureResource", [
    "REQ_FEATURE_RESOURCE",
    "SUCCESS_FEATURE_RESOURCE",
    "ERROR_FEATURE_RESOURCE",

    "REQ_AVAILABLE_FEATURE_RESOURCE",
    "SUCCESS_AVAILABLE_FEATURE_RESOURCE",
    "ERROR_AVAILABLE_FEATURE_RESOURCE",

    "REQ_UPDATE_FEATURE_RESOURCE",
    "SUCCESS_UPDATE_FEATURE_RESOURCE",
    "ERROR_UPDATE_FEATURE_RESOURCE",

    "REQ_DELETE_FEATURE_REQUEST",
    "SUCCESS_DELETE_FEATURE_REQUEST",
    "ERROR_DELETE_FEATURE_REQUEST",

    "REQ_ADD_FEATURE_RESOURCE",
    "SUCCESS_ADD_FEATURE_RESOURCE",
    "ERROR_ADD_FEATURE_RESOURCE",

    "REQ_FEATURE_RESOURCE_BY_PAGE",
    "SUCCESS_FEATURE_RESOURCE_BY_PAGE",
    "ERROR_FEATURE_RESOURCE_BY_PAGE",

    "REQ_ADD_FEATURE_RESOURCE_BY_PAGE",
    "SUCCESS_ADD_FEATURE_RESOURCE_BY_PAGE",
    "ERROR_ADD_FEATURE_RESOURCE_BY_PAGE",

    "REQ_UPDATE_FEATURE_RESOURCE_BY_PAGE",
    "SUCCESS_UPDATE_FEATURE_RESOURCE_BY_PAGE",
    "ERROR_UPDATE_FEATURE_RESOURCE_BY_PAGE",

    "REQ_DELETE_FEATURE_REQUEST_BY_PAGE",
    "SUCCESS_DELETE_FEATURE_REQUEST_BY_PAGE",
    "ERROR_DELETE_FEATURE_REQUEST_BY_PAGE",
]);

// Feature reqource request
export const reqFeatureResource = (data) => ({
    type: FeatureResourceActions.REQ_FEATURE_RESOURCE,
    payload: data
});

export const successFeatureResource = (data) => ({
    type: FeatureResourceActions.SUCCESS_FEATURE_RESOURCE,
    payload: data
});

export const errorFeatureResource = (data) => ({
    type: FeatureResourceActions.ERROR_FEATURE_RESOURCE,
    payload: data
});

// Available feature resource
export const reqAvailableFeatureResource = (data) => ({
    type: FeatureResourceActions.REQ_AVAILABLE_FEATURE_RESOURCE,
    payload: data
});

export const successAvailableFeatureResource = (data) => ({
    type: FeatureResourceActions.SUCCESS_AVAILABLE_FEATURE_RESOURCE,
    payload: data
});

export const errorAvailableFeatureResource = (data) => ({
    type: FeatureResourceActions.ERROR_AVAILABLE_FEATURE_RESOURCE,
    payload: data
});

// Update feature resource
export const reqUpdateFeatureResource = (data) => ({
    type: FeatureResourceActions.REQ_UPDATE_FEATURE_RESOURCE,
    payload: data
});

export const successUpdateFeatureResource = (data) => ({
    type: FeatureResourceActions.SUCCESS_UPDATE_FEATURE_RESOURCE,
    payload: data
});

export const errorUpdateFeatureResource = (data) => ({
    type: FeatureResourceActions.ERROR_UPDATE_FEATURE_RESOURCE,
    payload: data
});

// Delete feature resource
export const reqDeleteFeatureResource = (data) => ({
    type: FeatureResourceActions.REQ_DELETE_FEATURE_REQUEST,
    payload: data
});

export const successDeleteFeatureResource = (data) => ({
    type: FeatureResourceActions.SUCCESS_DELETE_FEATURE_REQUEST,
    payload: data
});

export const errorDeleteFeatureResource = (data) => ({
    type: FeatureResourceActions.ERROR_DELETE_FEATURE_REQUEST,
    payload: data
});

// Add feature resource
export const reqAddFeatureResource = (data) => ({
    type: FeatureResourceActions.REQ_ADD_FEATURE_RESOURCE,
    payload: data
});

export const successAddFeatureResource = (data) => ({
    type: FeatureResourceActions.SUCCESS_ADD_FEATURE_RESOURCE,
    payload: data
});

export const errorAddFeatureResource = (data) => ({
    type: FeatureResourceActions.ERROR_ADD_FEATURE_RESOURCE,
    payload: data
});

// Feature reqource request BY PAGE
export const reqFeatureResourceByPage = (data) => ({
    type: FeatureResourceActions.REQ_FEATURE_RESOURCE_BY_PAGE,
    payload: data
});

export const successFeatureResourceByPage = (data) => ({
    type: FeatureResourceActions.SUCCESS_FEATURE_RESOURCE_BY_PAGE,
    payload: data
});

export const errorFeatureResourceByPage = (data) => ({
    type: FeatureResourceActions.ERROR_FEATURE_RESOURCE_BY_PAGE,
    payload: data
});

// Add feature resource BY PAGE
export const reqAddFeatureResourceByPage = (data, cb) => ({
    type: FeatureResourceActions.REQ_ADD_FEATURE_RESOURCE_BY_PAGE,
    payload: data,
    cb
});

export const successAddFeatureResourceByPage = (data) => ({
    type: FeatureResourceActions.SUCCESS_ADD_FEATURE_RESOURCE_BY_PAGE,
    payload: data
});

export const errorAddFeatureResourceByPage = (data) => ({
    type: FeatureResourceActions.ERROR_ADD_FEATURE_RESOURCE_BY_PAGE,
    payload: data
});

// Update feature resource BY PAGE
export const reqUpdateFeatureResourceByPage = (data, cb) => ({
    type: FeatureResourceActions.REQ_UPDATE_FEATURE_RESOURCE_BY_PAGE,
    payload: data,
    cb
});

export const successUpdateFeatureResourceByPage = (data) => ({
    type: FeatureResourceActions.SUCCESS_UPDATE_FEATURE_RESOURCE_BY_PAGE,
    payload: data
});

export const errorUpdateFeatureResourceByPage = (data) => ({
    type: FeatureResourceActions.ERROR_UPDATE_FEATURE_RESOURCE_BY_PAGE,
    payload: data
});

// Delete feature resource BY PAGE
export const reqDeleteFeatureResourceByPage = (data, cb) => ({
    type: FeatureResourceActions.REQ_DELETE_FEATURE_REQUEST_BY_PAGE,
    payload: data,
    cb
});

export const successDeleteFeatureResourceByPage = (data) => ({
    type: FeatureResourceActions.SUCCESS_DELETE_FEATURE_REQUEST_BY_PAGE,
    payload: data
});

export const errorDeleteFeatureResourceByPage = (data) => ({
    type: FeatureResourceActions.ERROR_DELETE_FEATURE_REQUEST_BY_PAGE,
    payload: data
});

export default FeatureResourceActions;

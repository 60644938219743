import { AnalyticsActions } from "./actions";

let Analytics = {
    isLoading: false,
    data: []
};

const analyticsReducer = (state = Analytics, action) => {
  switch (action.type) {
    case  AnalyticsActions.ANALYTICS_REPORT:
            return {
                ...state,
                isLoading: true,
            };
    case AnalyticsActions.ANALYTICS_REPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            };
    case AnalyticsActions.ANALYTICS_REPORT_ERROR:
        return {
                ...state,
                isLoading: false,
                data: action.payload
            };
    default:
        return state;
  }
};
export default analyticsReducer;

import React, { Component } from "react";
import PropTypes from "prop-types";
import {Spin, Form, Input, Button, Select, Modal} from "antd";
import { isEmpty } from "lodash";
import { RedirectWrapper } from "../../components";
import {formItemLayout, formButtonLayout} from "../../constants";

const FormItem = Form.Item;
const Option = Select.Option;

class UpdateSicNaicsForm extends Component {

    componentDidMount() {
        this.props.fetchBirdeyeCategoriesList();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if(!err) {
                this.props.updateSicNaicsRequest({data: values, cb: this.renderCallback});
            } else {
                return;
            }
        });
    }

    renderCallback = ( response ) => {
        if(response.type === 'Success') {
            this.props.form.resetFields();
            return Modal.success({
                title: "Success",
                content: response.details,
                maskClosable: true,
                okText: "Close"
            });
        } else {
            return Modal.error({
                title: `Something went wrong.`,
                content: response.details,
                maskClosable: true
            });
        }
    }

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        this.setState({data, formError: ""});
    }

    render() {
        const { getFieldDecorator }  = this.props.form;
        const { error, userData, isLoading, categories } = this.props;
        const childCategories = [];
        if(categories) {
            categories.filter(record => {
                const categ = record.childCategory;

                categ && categ.map((option) => {
                    childCategories.push(option);
                })
            });
        }

        const unauthorized = error === "unauthorized" || !userData.name;
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div>
                <h2>Update SIC/NIACS</h2>
                {isLoading && isEmpty(categories) ? (
                    <Form>
                        <FormItem {...formItemLayout}>
                            <Spin tip="Fetching Categories..." />
                        </FormItem>
                    </Form>

                ) : (
                    isLoading && !isEmpty(categories) ? (
                        <div className="spin-overlay">
                            <Spin size="large"  />
                        </div>
                    ) : (
                        <Form onSubmit={this.handleSubmit} >
                            <FormItem {...formItemLayout} label={"BE Sub Category"} required={true}>
                                {
                                    getFieldDecorator("categoryName", {
                                        rules: [{
                                            required: true,
                                            message: "Provide a BE sub category"
                                        }],
                                    })(
                                        <Select showSearch placeholder={"Select Sub Category"}>
                                            {childCategories && childCategories.sort((a, b) => a.name > b.name ? 1 : -1).map((option) => (
                                                <Option key={option.id} value={option.name}>
                                                    {option.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem {...formItemLayout} label={"NAICS Sub Industry"} required={true}>
                                {
                                    getFieldDecorator("naicsSubIndustry", {
                                        rules: [{
                                            required: true,
                                            message: "Please Provide a NAICS Sub industry"
                                        }],
                                    })(
                                        <Input placeholder={"Enter a NAICS Sub Industry"}/>
                                    )
                                }
                            </FormItem>
                            <FormItem {...formItemLayout} label={"NAICS Code"} required={true}>
                                {
                                    getFieldDecorator("naicsCode", {
                                        rules: [{
                                            required: true,
                                            message: "Please Provide a NAICS Code"
                                        }, {
                                            pattern: '^[0-9]*$',
                                            message: "Please provide valid NAICS Code"
                                        }],
                                    })(
                                        <Input placeholder={"Enter a NAICS Code"}/>
                                    )
                                }
                            </FormItem>
                            <FormItem {...formItemLayout} label={"SIC Sub Industry"} required={true}>
                                {
                                    getFieldDecorator("sicSubIndustry", {
                                        rules: [{
                                            required: true,
                                            message: "Please Provide a SIC Sub industry"
                                        }],
                                    })(
                                        <Input placeholder={"Enter a SIC Sub Industry"}/>
                                    )
                                }
                            </FormItem>
                            <FormItem {...formItemLayout} label={"SIC Code"} required={true}>
                                {
                                    getFieldDecorator("sicCode", {
                                        rules: [{
                                            required: true,
                                            message: "Please Provide a SIC Code"
                                        }, {
                                            pattern: '^[0-9]*$',
                                            message: "Please provide valid NAICS Code"
                                        }],
                                    })(
                                        <Input placeholder={"Enter a SIC Code"}/>
                                    )
                                }
                            </FormItem>
                            <FormItem {...formButtonLayout}>
                                <Button type={"primary"} htmlType={"submit"}>
                                    Submit
                                </Button>
                            </FormItem>
                        </Form>
                        )
                )}
            </div>
        );
    }
}

const UpdateSicNaics = Form.create()(UpdateSicNaicsForm);

UpdateSicNaics.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    userData: PropTypes.object.isRequired,
    fetchBirdeyeCategoriesList: PropTypes.func.isRequired,
    updateSicNaicsRequest: PropTypes.func.isRequired
};

export default UpdateSicNaics;

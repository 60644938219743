import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import TableSkeleton from "../../components/TableSkeleton";
import PageHeaderContainer from "../../components/PageHeaderContainer";
import { getIsPageActionAccessible } from "../../utils";
import moment from "moment";
import {message, Icon} from "antd";

import "./CampaignUrlBuilder.scss";

class CampaignUrlBuilderArchive extends Component {

    pageKey = "campaignUrlBuilderList";

    constructor (props) {
        super(props);

        const 
            {userData: {name}, reqCampaignUrlBuilderArchive, pageData: {campaignUrlBuilderArchive=[]}} = this.props;
        !campaignUrlBuilderArchive.length && reqCampaignUrlBuilderArchive({email: name});   
    }

    formatData = (data) => {

        const isActionAccessible = getIsPageActionAccessible(this.pageKey, this.props.userData.privileges);

        return data.map(({created_at, url, id: urlId}, id) => {
            return {
                key: id,
                createdAtString: <><span>{moment(created_at).format('dddd, MMM DD, YYYY')}</span><br/><small>{moment(created_at).format('hh:mm a')}</small></>,
                createdAt: created_at,
                url,
                action: <div className={"actionComponent"}><Icon className={"actionIcon"} type="copy" theme="twoTone" title={"Copy"} onClick={() => (this.handleClipboardCopy(url))}/>{isActionAccessible && (<><br/><Icon className={"actionIcon"} type="edit" theme="twoTone" title={"Edit"} onClick={() => {this.props.history.push(`/campaign-url-builder/modify/${encodeURIComponent(url)}`)}}/></>)}</div>
            };
        });
    }

    expandCampaignUrl = ({id, url}) => {
        const 
            {origin, pathname, searchParams, hash} = new URL(url),
            queryParams = [];

        for (const [key, value] of searchParams) {
            queryParams.push({key, value});
        }

        return (
            <div key={`expandCampaignUrl-${id}`}>
                <p><b>{origin}{pathname}{hash}</b></p>
                <ul>
                    {
                        queryParams.map(({key, value}) => (<p key={`expandCampaignUrl-queryParam-${key}`}>{key} = <code>{value}</code></p>))
                    }
                </ul>
            </div>
        )
    };

    handleClipboardCopy = (url) => {
        try {
            let hiddenField = document.createElement('textarea');
            hiddenField.innerText = url;
            document.body.appendChild(hiddenField);
            hiddenField.select();
            document.execCommand('copy');
            hiddenField.remove();
            message.success('Successfully copied!');
        } catch (e) {
            message.error('Oops! Unable to copy, though url has been generated.');
        }
    };

    render() {
        const
            { error, userData, isLoading, pageData: {campaignUrlBuilderArchive=[]} } = this.props,
            unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        return (
            <div className={"campaignUrlBuilder"} ref={el => { this.mainDiv = el }}>
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    columns = {[
                        {
                            title: 'Campaign URL',
                            dataIndex: 'url',
                            key: 'url',
                            width: '70%',
                            searchable: true,
                            ellipsis: true,
                            render: (text, row, index) => (<p className={"urlComponent"}>{text}</p>)
                        },
                        {
                            title: 'Created At',
                            dataIndex: 'createdAtString',
                            key: 'createdAt',
                            width: '20%',
                            sortable: true,
                            align: "center",
                            sortAs: "date",
                            defaultSortOrder: "descend"
                        },
                        {
                            title: 'Action',
                            dataIndex: 'action',
                            width: '10%',
                            align: "center",
                        }
                    ]}
                    dataSource = {this.formatData(campaignUrlBuilderArchive)}
                    expandedRowRender={this.expandCampaignUrl}
                    loading={isLoading}
                    scroll={{ y: 500 }}
                    disablePagination={true}
                  />
            </div>
        );
    }
}

CampaignUrlBuilderArchive.propTypes = {
    userData: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    reqCampaignUrlBuilderArchive: PropTypes.func.isRequired
};

export default CampaignUrlBuilderArchive;

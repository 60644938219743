import { connect } from "react-redux";

import {
    fetchPagesListRequest,
    fetchPageRequest,
    addPageRequest,
    updatePageRequest,
    deletePageRequest,
    reqFallBackCal,
    addInactiveEmp,
    getInactiveEmp
} from "./actions";

import {
    fetchSalesRepsListRequest
} from "../SalesReps/actions";

import Pages from "./Pages";
import UpdatePages from "./UpdatePages";
import InactiveEmployees from './InactiveEmployees';

const mapStateToProps = (state) => {
    return {
        ...state.SalesRep,
        ...state.User,
        ...state.Pages
    }
};

export const PagesPage = connect(
    mapStateToProps,
    { fetchPagesListRequest, deletePageRequest }
)(Pages);

export const UpdatePagesPage = connect(
    mapStateToProps,
    { fetchPageRequest, fetchSalesRepsListRequest, addPageRequest, updatePageRequest, reqFallBackCal }
)(UpdatePages);

export const InactiveEmployeesPage = connect(
    mapStateToProps,
    { addInactiveEmp, getInactiveEmp }
)(InactiveEmployees);
import React, {Component} from 'react';
import {Form, Input, Button, Spin, Modal, Radio} from 'antd';
import { isEmpty } from "lodash";
import {formButtonLayout, formItemLayout} from '../../constants';
import {RedirectWrapper} from '../../components';

const FormItem = Form.Item;

class GmbForm extends Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if(!err) {
                if(!isEmpty(values.businessNumber) || !isEmpty(values.businessType)) {
                    const data = {};
                    data.businessNumber = values.businessNumber;
                    data.businessType = values.businessType;
                    data.emailAddress = this.props.userData.name;

                    this.props.getGmbRequest({data: data, cb: this.renderCallback});
                }
            }
        });
    }

    renderCallback = (response) => {
        if (response.success) {
            return Modal.success({
                title: "Success",
                maskClosable: true,
                okText: "Close",
                content: response.success
            });
        } else {
            return Modal.error({
                title: `Something went wrong.`,
                content: response.error
            });
        }
    };

    render() {
        const { error, userData, isLoading } = this.props;
        const {getFieldDecorator} = this.props.form;

        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        if(isLoading) {
            return <Spin />;
        }

        return (
            <div>
                <h2>GMB Data</h2>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Business Type">
                        {getFieldDecorator('businessType', {
                            rules: [{ required: true, message: 'Please select business type' }],
                            initialValue: "enterprise"
                        })(
                            <Radio.Group>
                                <Radio value={"enterprise"}>Enterprise</Radio>
                                <Radio value={"smb"}>SMB</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Business Number" extra={<><span>GMB only provides data for the <strong>past 18 months</strong></span></>}>
                        {getFieldDecorator('businessNumber', {
                            rules: [
                                {
                                    required: true,
                                    message: "please provide business number"
                                },
                                {
                                    pattern: '^[0-9]*$',
                                    message: 'Please provide valid business number'
                                }
                            ],
                        })(<Input placeholder="Enter business number" />)}
                    </FormItem>
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

const Gmb = Form.create()(GmbForm);

export default Gmb;
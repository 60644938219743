import { connect } from "react-redux";
import { reqBusinessDetails, getBusinessScanToolHistory } from "./actions";

import ScanTool from "./ScanTool";

const mapStateToProps = (state) => ({
    ...state.User,
    ...state.ScanTool
});

export const ScanPage = connect(
    mapStateToProps, 
    { reqBusinessDetails, getBusinessScanToolHistory }
)(ScanTool);
import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import {Spin, Modal, message, Tag, Button, Icon, Divider} from "antd";
import { TableSkeleton, ResourceSteps, PageHeaderContainer } from "../../../components";
import lookupResource from "./../../../pages/Resources/resources.json";
import lookup from "./constants";
import moment from 'moment';
import {websiteNodeAPIUrl} from "../../../constants";
import axios from "axios";
import {
    downloadFile, getIsPageActionAccessible, decodeResourceClassification,
    createSFDCampaign
} from "../../../utils";

class Guide extends Component {
    constructor(props) {
        super(props);

        const { guideId } = props.match && props.match.params;
        this.pageKey = "guideList";

        this.state = {
            isListing: !guideId,
            guideId,
            notification: props.notification,
            records: null,
            editSortingOrderKey: null,
            showEditSortingOrderSubmit: false
        };

        this.isReadonly = !getIsPageActionAccessible(this.pageKey, ((props.userData && props.userData.privileges) || []));
    }

    componentDidMount() {
        const { guideId } = this.props.match && this.props.match.params;

        if (!guideId) {
            this.props.fetchGuideListRequest();
        } else if (guideId !== "new") {
            this.props.fetchGuideByIdRequest(guideId)
        }
    }

    componentDidUpdate() {
        this.state.downLoadingPDF && this.renderPDFDownloadingMessage();
    }

    static getDerivedStateFromProps(nextProps, state) {
        const { guideId, notification } = state;
        const { match: newMatch, notification: newNotification } = nextProps;
        const { guideId: newGuideId } = newMatch && newMatch.params;

        if (notification && newNotification && newNotification.timestamp > notification.timestamp && nextProps.history.location.pathname !== '/resource/guide') {
            const notificationType = newNotification.type;
            Modal[notificationType]({
                title: notificationType === "success" ? "Successfully saved" : "Something went wrong",
                content: newNotification.message,
                okText: notificationType === "error" ? "Close" : "Go to guide list page",
                maskClosable: false,
                onOk() {
                    return notificationType === "error" ? "" : nextProps.history.push("/resource/guide");
                }
            });

            return { notification: newNotification }
        } else if (guideId !== newGuideId) {
            if (!newGuideId) {
                nextProps.fetchGuideListRequest();
            } else if (newGuideId !== "new") {
                nextProps.fetchGuideByIdRequest(newGuideId);
            }

            return { guideId: newGuideId, isListing: !newGuideId };
        }

        return {...state, records: nextProps.guideList};
    }

    handleStepsSubmission = async (data) => {
        const { userData } = this.props;

        const { cardTitle: card_heading, cardDesc: card_description, link, classification, image: card_image, featuredImage: featured_card_image, showInList } = data.card || {};
        if(data.banner && !data.banner.leadSfdcCampaign){
            data.leadSfdcCampaign = await createSFDCampaign('guide', data, this.props.userData.name);
        }
        else{
            data.leadSfdcCampaign = (data.banner && data.banner.leadSfdcCampaign) ? data.banner.leadSfdcCampaign : null;
        }
        const backendMappedData = {
            ...data,
            banner: data.banner,
            card_heading,
            card_description,
            card_image,
            featured_card_image,
            link,
            classification,
            showInList,
            sneak_peak: {...(data.sneakPeak || {})}
        };

        delete(backendMappedData.card);
        delete(backendMappedData.sneakPeak);

        if (this.state.guideId === "new") {
            backendMappedData.created_by = userData.name || "unknown";
            this.props.addGuideRequest(backendMappedData);
        } else {
            backendMappedData.updated_by = userData.name || "unknown";
            this.props.updateGuideRequest({id: this.state.guideId, data: backendMappedData});
        }
    };

    downloadPDF = (path) => {
        this.setState({
            downLoadingPDF: true
        }, () => {
            const url = `${websiteNodeAPIUrl}getPDFDownloads?pdfPath=${path}`;
            axios.get(url, { responseType: "blob" }).then((response) => {
                downloadFile({
                    response,
                    cb: () => this.setState({downLoadingPDF: false}, () => this.hidePDFDownloadMessage && this.hidePDFDownloadMessage())
                });
            }).catch(() => {
                this.setState({downLoadingPDF: false})
            });
        });
    }

    renderPDFDownloadingMessage = () => {
        this.hidePDFDownloadMessage && this.hidePDFDownloadMessage();
        this.hidePDFDownloadMessage = message.loading("Downloading PDF...", 0);
        return this.hidePDFDownloadMessage;
    }

    mapListToDataSource = (data) => {

        return data.map((guide, index) => {
            const {id, card_heading, link, updated_at, banner, leadSfdcCampaign, classification, sortingOrder} = guide;
            const { topics, industries } = decodeResourceClassification(classification, lookupResource, true);

            return {
                key: index,
                title: card_heading,
                lastUpdated: updated_at && moment(updated_at).format("YYYY-MM-DD"),
                leadSfdcCampaign: leadSfdcCampaign,
                topics,
                industries,
                sortingOrderField: this.getSortingOrder(index, sortingOrder),
                sortingOrder: sortingOrder,
                actions: (
                    <>
                        {
                            banner.pdf ? (
                                <>
                                    <a onClick={() => this.downloadPDF(banner.pdf)}>Download</a>
                                </>
                            ) : (
                                banner.episode && banner.episode.id && (
                                    <>
                                        <a href={`https://www.buzzsprout.com/1728881/${banner.episode.id}`} target="_blank" rel="noopener noreferrer">Listen</a>
                                    </>
                                )
                            )
                        }
                        {
                            link &&
                                <>
                                    <Divider type="vertical" />
                                    <a target={"_blank"} href={`${process.env.REACT_APP_WEBSITE_URL}.${link}`}>Website URL</a>
                                </>
                        }
                        {
                            !this.isReadonly && (
                                <>
                                    <Divider type="vertical" />
                                    <Link to={`/resource/guide/${id}`} style={{width: "100%"}}>Edit</Link>
                                </>
                            )
                        }
                    </>
                )
            }
        });
    }
    
    getSortingOrder = (i, value) => {
        const { editSortingOrderKey } = this.state;
        return (
            <div className="sortingOrderWrapper">
                {
                    editSortingOrderKey !== null && editSortingOrderKey === i ?
                        <div>
                            <input type="number" min="1" name={`sortingOrder_${i}`} value={value} onChange={(e) => this.inputChangeHandler(e, i)}/>
                            <Icon type="check-circle" theme="twoTone" onClick={(e) => this.updateSortingOrder(e, i)} />
                        </div> :
                        <div>
                            <Button type="dashed" disabled={ !this.isReadonly ? false : true } onClick={() => this.updateEditSortingOrder(i)}>{value}</Button>
                        </div>
                }
            </div>
        );
    };

    inputChangeHandler = (e, index) => {
        const data = {...this.state.records};
        data[index].sortingOrder = e.target.value;
        this.setState({
            records: data,
            showEditSortingOrderSubmit: true
        });
    };

    updateEditSortingOrder = (i) => {
        this.setState({
            editSortingOrderKey: i
        })
    };

    updateSortingOrder = (e, index) => {
        const { records } = this.state;
        const sortingOrder = records[index].sortingOrder;
        const id = records[index]['id'];
        if (sortingOrder && parseInt(sortingOrder) > 0) {
            const payload = {
                ...records[index],
                sortingOrder: parseInt(sortingOrder)
            };
            
            delete payload["id"];
            this.props.updateGuideRequest({ id: id, data: payload, cb: ((resp) => {
                    if (resp.type === 'success') {
                        this.props.fetchGuideListRequest();
                        this.setState({
                            editSortingOrderKey: null,
                            showEditSortingOrderSubmit: false
                        });
                        return message.success(
                            "You have successfully updated this record."
                        );
                    } else {
                        return message.error(
                            "Something went wrong... Please try again later."
                        );
                    }
                })
            });
        }
    };

    renderDetails = (record) => {
        return (
            <div>
                <div className="mb1">
                    <b>Industries: </b>
                    { record.industries && record.industries.map((tag) => (
                        tag && <Tag color="blue" key={tag}>
                            {tag}
                        </Tag>
                    ))}
                </div>
                <div className="mb1">
                    <b>Topics: </b>
                    { record.topics && record.topics.map((tag) => (
                        tag && <Tag color="blue" key={tag}>
                            {tag}
                        </Tag>
                    ))}
                </div>
                <div className="mb1">
                    <b>SF Campaign ID: </b>
                    <span>{record.leadSfdcCampaign}</span>
                </div>
            </div>
        )
    };

    render() {
        const { userData, isLoading } = this.props;
        const { isListing } = this.state;
        const unauthorized = !userData.name;

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        if (!isListing) {
            if (isLoading) {
                return <Spin />
            }
            const 
                { card_heading: cardTitle="", card_description: cardDesc="", classification="", link="", meta={}, banner={}, highlights={}, sneak_peak: sneakPeak={}, result={}, cta={}, card_image, leadSfdcCampaign, featured_card_image, showInList, ungatedSupport } = this.props.guide,
                initialValues = { card: { cardTitle, cardDesc, classification, link, image: (card_image || ""), featuredImage: (featured_card_image || ""), showInList }, meta, banner : {...banner, leadSfdcCampaign}, highlights, sneakPeak, result, cta, ungatedSupport };

            const isUpdate = this.state.guideId && this.state.guideId !== "new";

            return <ResourceSteps type={"guide"} title={ `${isUpdate ? "Edit" : "New"} Guide` } initialValues={ isUpdate ? initialValues : {} } additional={{ isUpdate }} userData={ userData } handleStepsSubmission={this.handleStepsSubmission}/>
        }

        return (
            <div>
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton loading={isLoading} columns={lookup.listingTableColumns} dataSource={this.mapListToDataSource(this.props.guideList)} expandedRowRender={this.renderDetails} pageSize={10} showSizeChanger={false} />
            </div>
        )
    }
}

export default Guide;

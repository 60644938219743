import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { message } from "antd";

import { TableSkeleton, RedirectWrapper, PageHeaderContainer } from "../../components";
import { getIsPageActionAccessible } from "../../utils";

import lookup from "./effects.json";

class Effects extends Component {

    pageKey = "effectList";

    componentDidMount() {
        this.props.fetchEffectsListRequest();
    }

    formatData = (data) => {
        return data.map((record) => {
            return {
                ...record,
                id: record.businessNumber, // Use businessNumber as id for creating edit links
                key: record.businessNumber,
                industry: record.label,
                withImages: !!record.reviewImg && !!record.prevReviewImg ? "Yes" : "No",
                resourceType: this.getResourceTypeValue(record.resourceType)
            };
        });
    };

    renderFeedbackMsg = (errorMsg) => {
        if (!errorMsg) {
            return message.success(
                "You have successfully deleted this record."
            );
        } else {
            return message.error(
                "Something went wrong... Please try again later."
            );
        }
    };

    renderExpandedRow = (record) => (
        <div>
            <div className="mb1">
                <b>Business Number: </b>
                <span>{record.businessNumber}</span>
            </div>
            {
                record.prevReviewImg && (
                    <div className="mb1">
                        <b>"Before" Image: </b>
                        <a href={record.prevReviewImg} target="_blank" rel="noopener noreferrer">
                            {record.prevReviewImg}
                        </a>
                    </div>
                )
            }
            {
                record.reviewImg && (
                    <div className="mb1">
                        <b>"After" Image: </b>
                        <a href={record.reviewImg} target="_blank" rel="noopener noreferrer">
                            {record.reviewImg}
                        </a>
                    </div>
                )
            }
            {
                record.resourceType && (
                    <div>
                        <div className="mb1">
                            <b>Resource Type: </b>
                            <span>{record.resourceType}</span>
                        </div>
                        <div className="mb1">
                            <b>Resource Heading: </b>
                            <span>{record.heading}</span>
                        </div>
                        <div className="mb1">
                            <b>Resource Sub Heading: </b>
                            <span>{record.subHeading}</span>
                        </div>
                        <div className="mb1">
                            <b>Resource URL: </b>
                            <a href={record.reviewImg} target="_blank" rel="noopener noreferrer">
                                {record.resourceUrl}
                            </a>
                        </div>
                    </div>
                )
            }
            {
                record.resourceType && record.resourceType === "Review" && (
                    <div className="mb1">
                        <b>Avatar URL: </b>
                        <a href={record.reviewImg} target="_blank" rel="noopener noreferrer">
                            {record.avatarUrl}
                        </a>
                    </div>
                )
            }
            {
                record.birdeyePageMessages && (
                    <div className="mb1">
                        <b>Associated Pages </b>
                        <span>{this.getEffectPageName(record.birdeyePageMessages)}</span>
                    </div>
                )
            }
            
        </div>
    );

    getResourceTypeValue = (resourceType) => {
        if (resourceType) return resourceType.charAt(0).toUpperCase() + resourceType.slice(1);
        return null;
    }

    getEffectPageName = (pages) => {
        if (!pages || !pages.length) return "";
        const pageArr = pages.map((page) => {
            return page.pageName.split("-").join(" ");
        });
        return pageArr.join(", ");
    }

    render() {
        const { data, isLoading, error, userData } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        const isActionAccessible = getIsPageActionAccessible(this.pageKey, userData.privileges);

        return (
            <div className="effects-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    columns={lookup.columns}
                    pathForEdit={ isActionAccessible && "/effect/"}
                    expandedRowRender={isActionAccessible && this.renderExpandedRow}
                    dataSource={this.formatData(data)}
                    loading={isLoading}
                />
            </div>
        );
    }
}

Effects.propTypes = {
    data: PropTypes.array.isRequired, 
    isLoading: PropTypes.bool.isRequired,
    userData: PropTypes.object.isRequired,
    error: PropTypes.string.isRequired,
    fetchEffectsListRequest: PropTypes.func.isRequired
};

export default Effects;

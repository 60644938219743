import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Form, Input, Row, Col, Select } from "antd";
import { beAPIResource } from '../../../../utils/apiHelper';
import { addNewComponent, updateComponent } from '../../formUtils';
const FormItem = Form.Item;

export const formItemLayout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 6 }
    },
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 18 }
    }
};

export const formButtonLayout = {
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 18, offset: 4 }
    }
};

export const RESOURCES_TYPE = [
    {
        value: 'case-study',
        label: 'Case Study'
    },
    {
        value: 'guide',
        label: 'Guide'
    },
    {
        value: 'video',
        label: 'Video'
    },
    {
        value: 'webinar',
        label: 'Webinar'
    },
    {
        value: 'podcast',
        label: 'Podcast'
    },
    {
        value: 'blog',
        label: 'Blog'
    },
    {
        value: 'support',
        label: 'Support'
    },
    {
        value: 'toolkit',
        label: 'Toolkit'
    }
];

const FeaturedResources = ({ match, form, data={}, pageId }) => {
    const params = match.params;
    const { getFieldDecorator } = form;
    const [ formData, setFormData] = useState(data.resources || [{}, {}, {}]);
    const [ heading, setHeading ] = useState(data.heading || 'Resources');
    const [ bgColor, setBgColor ] = useState(data.bgColor || '#FFFFFF');
    const [ availableWebinars, setAvailableWebinars ] = useState([]);

    useEffect(() => {
        (async()=>{
            const {data} = await beAPIResource.get(`support/feature-resource/available/webinar`);  
            setAvailableWebinars(data)      
        })()
    }, [])
    
    
    const handleChange = (index, field, value) => {
        setFormData((prev) => {
          const updatedState = [...prev];
          updatedState[index][field] = value;
          return updatedState;
        });
    };
    
    const handleWebinarSelected = (index, value) => {
        const selectedWebinar = availableWebinars.filter(webinar => value === webinar.id)[0];
        setFormData((prev) => {
            const updatedState = [...prev];
            /* IMP: It is crucial that id and startDate are in this order, consecutively */
            updatedState[index] = {
                type: 'webinar',
                title: selectedWebinar.heading,
                desc: selectedWebinar.description,
                link: selectedWebinar.link,
                webinarId: selectedWebinar.id,
                startDate: selectedWebinar.start_date,
                timezone: selectedWebinar.timezone
            };
            return updatedState;
        });

    };

    const handleSubmit = () => {

        form.validateFieldsAndScroll(async(err, values) => {
            if (!err) {
                const data = JSON.stringify({heading, bgColor, resources: formData})
                const payload = {
                    data,
                    page_id: params.pageId,
                    order_no: 999,
                    name: 'resourcesSection'
                }
                if( params.pageId ) {
                    addNewComponent(payload);
                }
                else {
                    updateComponent(params.componentId, data, pageId);
                }
            }
            else {
                return;
            }
        }); 
    }
    
    return (
        <>
            <FormItem {...formItemLayout} label={"Heading"}>
                {
                    getFieldDecorator("heading", {
                        rules: [{
                            required: true,
                            message: "Heading cannot be empty"
                        }],
                        initialValue: heading
                    }) (
                        <Input placeholder={"Heading"} onChange={(e) => setHeading(e.target.value)}/>
                    )
                }
            </FormItem>
            <FormItem {...formItemLayout} label={"Background color"}>
                {
                    getFieldDecorator("bgColor", {
                        rules: [{
                            required: false
                        }],
                        initialValue: bgColor
                    }) (
                        <Input placeholder={"Background color"} onChange={(e) => setBgColor(e.target.value)}/>
                    )
                }
            </FormItem>
            {
                formData.map((item, index) => (
                    <FormItem {...formItemLayout} label={`Resource #${index+1}:`} key={index}>
                        <Row align="middle" type="flex" gutter={[16, 16]}>
                            <Col sm={13}>
                                <FormItem {...formItemLayout} label={"Resource Type"}>
                                    {
                                        getFieldDecorator(`resources[${index}].type`, {
                                            rules: [{
                                                required: true,
                                                message: "Resource Type cannot be empty"
                                            }],
                                            ...(item.type ? {initialValue: item.type} : '')
                                        }) (
                                            <Select placeholder="Resource type" onChange={(value) => handleChange(index, 'type', value)}>
                                        {
                                            RESOURCES_TYPE.map((r) => <Select.Option key={r.value} value={r.value}>{r.label}</Select.Option>)
                                        }
                                    </Select>
                                        )
                                    }
                                </FormItem>
                            </Col>
                            {
                                item.type === "webinar" && <Col sm={13}>
                                            <FormItem {...formItemLayout} label={"Select webinar"}>
                                                {
                                                    getFieldDecorator(`resources[${index}].availableWebinars`, {
                                                        valuePropName: 'checked',
                                                        initialValue: availableWebinars
                                                    }) (
                                                        <Select 
                                                            showSearch 
                                                            placeholder="Select webinar" 
                                                            onChange={(value) => handleWebinarSelected(index, value)}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {
                                                                availableWebinars.map((webinar) => <Select.Option key={webinar} value={webinar.id}>{webinar.heading}</Select.Option>)
                                                            }
                                                        </Select>
                                                    )
                                                }
                                            </FormItem>
                                    </Col>
                            }
                            <Col sm={13}>
                                <FormItem {...formItemLayout} label={"Title"}>
                                    {
                                        getFieldDecorator(`resources[${index}].title`, {
                                            rules: [{
                                                required: true,
                                                message: "Title cannot be empty"
                                            }],
                                            initialValue: item.title
                                        }) (
                                            <Input
                                                placeholder='Title'
                                                onChange={(e) => handleChange(index, 'title', e.target.value)}
                                            />
                                        )
                                    }
                                </FormItem>
                            </Col>
                            <Col sm={13}>
                                <FormItem {...formItemLayout} label={"Description"}>
                                    {
                                        getFieldDecorator(`resources[${index}].desc`, {
                                            initialValue: item.desc
                                        }) (
                                            <Input
                                                placeholder='Description'
                                                onChange={(e) => handleChange(index, 'desc', e.target.value)}
                                            />
                                        )
                                    }
                                </FormItem>
                            </Col>
                            <Col sm={13}>
                                <FormItem {...formItemLayout} label={"Link"}>
                                    {
                                        getFieldDecorator(`resources[${index}].link`, {
                                            rules: [{
                                                required: true,
                                                message: "Link cannot be empty"
                                            }],
                                            initialValue: item.link
                                        }) (
                                            <Input
                                                placeholder='Link'
                                                onChange={(e) => handleChange(index, 'link', e.target.value)}
                                                disabled={item.type === "webinar"}
                                            />
                                        )
                                    }
                                </FormItem>
                            </Col>
                            {item.type !== 'webinar' && <Col sm={13}>
                                <FormItem {...formItemLayout} label={"Label"}>
                                    {
                                        getFieldDecorator(`resources[${index}].label`, {
                                            rules: [{
                                                required: true,
                                                message: "Label cannot be empty"
                                            }],
                                            initialValue: item.label
                                        }) (
                                            <Input
                                                placeholder='Label'
                                                onChange={(e) => handleChange(index, 'label', e.target.value)}
                                            />
                                        )
                                    }
                                </FormItem>
                            </Col>}
                    {
                        item.type === "guide" && <Col sm={13}>
                                    <FormItem {...formItemLayout} label={"Duration (in mins)"}>
                                        {
                                            getFieldDecorator(`resources[${index}].duration`, {
                                                rules: [{
                                                    required: false
                                                }],
                                                initialValue: item.duration
                                            }) (
                                                <Input
                                                    placeholder='Duration (in mins)'
                                                    onChange={(e) => handleChange(index, 'duration', e.target.value)}
                                                />
                                            )
                                        }
                                    </FormItem>
                            </Col>
                    }
                        </Row>
                    </FormItem>
                ))
            }
            <FormItem {...formButtonLayout}>
                <Button type="primary" htmlType="submit" block onClick={handleSubmit}>
                    Save
                </Button>
            </FormItem>
        </>
    );
}

const FeaturedResourcesForm = Form.create()(withRouter(FeaturedResources));
export default FeaturedResourcesForm;

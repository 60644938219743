import React, { Component } from "react";
import PropTypes from "prop-types";
import TableSkeleton from "../TableSkeleton";
import PresenterForm from "./PresenterForm";
import {Icon, Popconfirm, Button, Tag, Modal} from "antd";
import {isEmpty} from "lodash";

import "./style.scss";
import {tableColumns} from "./constants.js";

class Presenter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            action: 'list',
            presenterInfo: null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const 
            {notificationPresenterModal: nextNotification} = this.props,
            {notificationPresenterModal: prevNotification} = prevProps;

        const handleActionToggle = this.handleActionToggle;
        
        if (prevNotification && nextNotification && nextNotification.timestamp > prevNotification.timestamp) {
            Modal[nextNotification.type]({
                title: nextNotification.type === 'success' ? (`Success`) : (`Error`),
                content: nextNotification.message,
                okText: "Close",
                maskClosable: false,
                onOk() {
                    handleActionToggle('list');
                }
            });
        }
    }

    handlePresenterEdit = (e, data) => {
        e.preventDefault();
        this.setState({action: "form", presenterInfo: data});
    };

    mapRawDataToDataSource = (data) => {
        const { deletePresenter, type } = this.props;
        
        return data.map((presenter, index) => {
            const 
                {id, email, photo, designation} = presenter,
                tagObj = email.indexOf("@birdeye.com") !== -1 ? 
                    {tag: "Birdeye", formattedTag: <Tag color={"#108ee9"}>Birdeye</Tag>} : 
                    {tag: "Specialist", formattedTag: <Tag color={"#87d068"}>Specialist</Tag>};

            const action = (
                <>
                    <Button title="Click to edit" type="link" onClick={e => (this.handlePresenterEdit(e, presenter))}><Icon type="edit" theme="twoTone"/></Button>
                    <Popconfirm title="Are you sure delete this presenter?" onConfirm={() => {deletePresenter({id}, type)}} okText="Yes" cancelText="No" placement="left" icon={<Icon type="delete" style={{color: "red"}}/>}>
                        <Button className="action" title="Click to delete" type="link"><Icon type="delete" theme="twoTone"/></Button>
                    </Popconfirm>
                </>
            )

            return {
                ...presenter,
                key: index,
                formattedPhoto: <img className={"presenterDisplayPicture"} src={photo} alt={email}/>,
                tag: tagObj.tag,
                formattedTag: tagObj.formattedTag,
                formattedDesignation: [designation.slice(0, designation.lastIndexOf(",") + 1), designation.slice(designation.lastIndexOf(",") + 1)].join("\n").trim(),
                action
            }
        });
    };

    handleActionToggle = (nextAction) => {
        this.setState({action: nextAction, presenterInfo: null});
    }

    render() {
        const 
            { isLoadingPresenterModal: isLoading, presenterList, addPresenter, updatePresenter, type } = this.props,
            {action, presenterInfo} = this.state;

        let displayContentObj = {};

        if (action === "list") {
            displayContentObj.heading = "All Presenters";
            displayContentObj.nextAction = "form";
            displayContentObj.btnLabel = "Add New Presenter";
        } else {
            displayContentObj.heading = isEmpty(presenterInfo) ? "Add Presenter" : "Update Presenter";
            displayContentObj.nextAction = "list";
            displayContentObj.btnLabel = "Show All Presenters";
        }

        const actionButtonObj = (action === "list") ? {heading: "All Presenters", nextAction: "form", btnLabel: "Add New Presenter"} : {heading: "All Presenters", nextAction: "list", btnLabel: "Show All Presenters"};

        return (
            <div className={"presenter"}>
                <div className="tableOperations">
                    <h2 className={"heading"}>
                        {
                            displayContentObj.heading
                        }
                    </h2>
                    <div className={"btnContainer"}>
                        <Button className={"btnList"} onClick={() => this.handleActionToggle(actionButtonObj.nextAction)}>
                            {
                                actionButtonObj.btnLabel
                            } 
                        </Button>
                    </div>
                </div>
                {
                    action === "list" ? (
                        <TableSkeleton pagination={{ pageSize: 50 }} scroll={{ y: 400 }} loading={isLoading} columns={tableColumns} dataSource={this.mapRawDataToDataSource(presenterList)} disablePagination={true}/>
                    ) : (
                        <PresenterForm type={type} presenterInfo={presenterInfo} addPresenter={addPresenter} updatePresenter={updatePresenter} allPresenterEmails={presenterList.map(({email}) => (email))}/>
                    )
                }
            </div>  
        );
    }
}

Presenter.propTypes = {
    isLoadingPresenterModal: PropTypes.bool.isRequired,
    presenterList: PropTypes.array.isRequired,
    addPresenter: PropTypes.func.isRequired,
    updatePresenter: PropTypes.func.isRequired,
    deletePresenter: PropTypes.func.isRequired,
    notificationPresenterModal: PropTypes.object.isRequired,
    type: PropTypes.oneOf(["webinar", "meeting"])
};

export default Presenter;
import React, { Component } from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import {Upload, Form, Input, Button, message, Select, Spin, Icon, Tag} from "antd";
import {formItemLayout, formButtonLayout} from "../../constants";
import lookup from "./lookup";
import { uploadUtility } from "../../utils";
import { isEmpty } from "lodash";

const FormItem = Form.Item;

class FeatureForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            descriptionLength: 0,
            isEdit: !isEmpty(props.location.state),
            timestamp: `${Math.floor(0x100000000 * Math.random())}_${Date.now().toString()}`
        };
    }

    componentDidMount() {
        this.handleFieldsPopulationOnTargetChange(this.props.location.state);
    }

    handleFieldsPopulationOnTargetChange = (featureResource) => {
        if (isEmpty(featureResource)) {
            return this.props.form.setFieldsValue({featureResourceId: undefined, featureHeading: undefined, featureDescription: undefined});
        } else {
            const resourceJSON = featureResource.feature_resource_json;
            this.handleFeatureResourceChange(featureResource.feature_resource_type);
            const featureImageWithResolutions = this.getFeatureImageWithResolutions(resourceJSON.image);
            this.props.form.setFieldsValue({
                targetResource: featureResource.target_resource_type,
                featureResource: featureResource.feature_resource_type,
                featureResourceId: featureResource.feature_resource_id,
                featureHeading: resourceJSON.heading,
                featureDescription: resourceJSON.description,
                ...featureImageWithResolutions
            });
        }
    }

    handleTargetResourceChange = (v) => {
        if (!this.props.featureResource.hasOwnProperty(v)) this.props.reqFeatureResource(v);
        this.props.form.setFieldsValue({ featureResource: undefined, featureResourceId: undefined, featureHeading: undefined, featureDescription: undefined });
    }

    handleFeatureResourceChange = (v) => {
        this.props.reqAvailableFeatureResource(v);
        this.props.form.setFieldsValue({ featureResourceId: undefined, featureHeading: undefined, featureDescription: undefined });
    }

    handleAvailableListChange = (v) => {
        const { availableFeatureResource } = this.props;
        const { setFieldsValue, getFieldValue } = this.props.form;

        const fResource = getFieldValue("featureResource");
        const availableList = fResource && availableFeatureResource.hasOwnProperty(fResource) ? availableFeatureResource[fResource] : [];
        const filteredValue = availableList.filter((l) => (l.id === v));
        
        setFieldsValue({ featureHeading: filteredValue[0].heading, featureDescription: filteredValue[0].description, ...this.getFeatureImageWithResolutions(filteredValue[0].image) });
        this.setState({descriptionLength : filteredValue[0].description.length});
    }

    handleFormSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((e,v) => {
            const descLength = this.state.descriptionLength;
            if (e) return;
            if (descLength > 175) return;

            const availableList = v.featureResource && this.props.availableFeatureResource.hasOwnProperty(v.featureResource) ? this.props.availableFeatureResource[v.featureResource ] : [];
            const filteredValue = availableList.filter((l) => (l.id === v.featureResourceId));

            let featureResourceObj = {};
            featureResourceObj = !isEmpty(filteredValue[0]) ? {...filteredValue[0]} : {};
            featureResourceObj.heading = v.featureHeading;
            featureResourceObj.description = v.featureDescription;
            featureResourceObj.image = uploadUtility.singleFile.submittedValue(v.featureImage[lookup.imageResolutions[0].key]);
            
            delete featureResourceObj.id;

            let payload = {};
            payload[this.state.isEdit ? "updated_by" : "created_by"] = this.props.userData.name;
            payload.target_resource_type = v.targetResource;
            payload.feature_resource_type = v.featureResource;
            payload.feature_resource_id = v.featureResourceId;
            payload.feature_resource_json = featureResourceObj;

            if (this.state.isEdit) {
                this.props.reqUpdateFeatureResource({type: v.targetResource, data: payload, cb: this.handleAfterSubmission});
            } else {
                this.props.reqAddFeatureResource({type: v.targetResource, data: payload, cb: this.handleAfterSubmission});
            }
        });
    }

    handleAfterSubmission = (data) => {
        message[data.type](data.message);
        this.props.history.push("/feature-resource");
    }

    onDescriptionChange = (e) => {
        this.setState({descriptionLength: e.target.value.length});
    }

    isFeaturedResourceAlreadySelected = (fResourceType, fResourceId) => {
        const tResource = this.props.form.getFieldValue("targetResource");
        const selectedResources = !isEmpty(this.props.featureResource[tResource]) ? this.props.featureResource[tResource] : [];
        for(const resource of selectedResources) {
            if (resource.feature_resource_type == fResourceType && resource.feature_resource_id == fResourceId) {
                return true;
            }
        }

        return false;
    };

    getFeatureImageWithResolutions = (image) => {
        if (!image) return {};

        const extensionIndex = image.lastIndexOf(".");
        const featureImageWithResolutions = {};
        lookup.imageResolutions.forEach(({key, pd}) => {
            const imageURL = image.substr(0, extensionIndex) + ( pd ? ("@" + pd) : "" ) + image.substr(extensionIndex);
            featureImageWithResolutions[`featureImage.${key}`] = [{
                uid: "-1",
                name: `image@${key}`,
                status: "done",
                url: imageURL
            }]
        });

        return featureImageWithResolutions;
    };

    render() {
        const { error, userData, isLoading, availableFeatureResource } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { descriptionLength, isEdit } = this.state;
        const { featureResource: fResource, targetResource: tResource } = getFieldsValue(["featureResource", "targetResource"]);
        const availableList = !isEmpty(availableFeatureResource[fResource]) ? availableFeatureResource[fResource].map((value) => ({ ...value, disabled: this.isFeaturedResourceAlreadySelected(fResource, value.id) })) : [];

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <h2>{ isEdit ? "Edit" : "New" } Feature Resource</h2>
                <Spin tip="Please wait..." spinning={isLoading} size={"large"} indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}>
                    <Form onSubmit={this.handleFormSubmit}>
                        <FormItem {...formItemLayout} label={"Target Page"}>
                            {
                                getFieldDecorator("targetResource", {
                                    rules: [{
                                        required: true,
                                        message: "Please select a target resource type"
                                    }]
                                })(
                                    <Select placeholder={"Select a target resource to add associate feature"} onChange={this.handleTargetResourceChange} disabled={isEdit}>
                                        <Select.Option value={"main"}>Main</Select.Option>
                                        {
                                            lookup.resource && lookup.resource.map((r, i) => {
                                                return (
                                                    <Select.Option key={i} value={r.value}>{r.label}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                )
                            }
                        </FormItem>
                        <FormItem {...formItemLayout} label={"Resource Type"}>
                            {
                                getFieldDecorator("featureResource", {
                                    rules: [{
                                        required: true,
                                        message: "Please select a feature resource type"
                                    }],
                                })(
                                    <Select placeholder={"Select a feature resource to associate with target"} onChange={this.handleFeatureResourceChange} disabled={isEdit || !tResource}>
                                        {
                                            lookup.resource && lookup.resource.filter(r => (tResource === "press" ? r.value === "press" : r.value !== "press")).map((r, i) => {
                                                return (
                                                    <Select.Option key={i} value={r.value}>{r.label}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                )
                            }
                        </FormItem>
                        <FormItem {...formItemLayout} label={"Available list"}>
                            {
                                getFieldDecorator("featureResourceId", {
                                    rules: [{
                                        required: true,
                                        message: "Please select a feature resource to display on target resource"
                                    }]
                                })(
                                    <Select placeholder={"Select a feature resource to associate with target"} onChange={this.handleAvailableListChange} disabled={isEdit || !availableList.length}>
                                        {
                                            availableList.map((r, i) => {
                                                let heading = r.heading;

                                                if (fResource === "press") {
                                                    heading = <span><Tag color="#2db7f5" style={{textTransform: "uppercase", width: "110px", textAlign: "center"}}>{r.type}</Tag> {heading}</span>
                                                }

                                                return (
                                                    <Select.Option key={i} value={r.id} disabled={r.disabled}>{heading}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                )
                            }
                        </FormItem>
                        <FormItem {...formItemLayout} label={"Heading"}>
                            {
                                getFieldDecorator("featureHeading", {
                                    rules: [{
                                        required: true,
                                        message: "Please select a feature resource to display on target resource"
                                    }]
                                })(
                                    <Input placeholder={"Feature heading"} />
                                )
                            }
                        </FormItem>
                        <FormItem {...formItemLayout} label={"Description"} extra={<div style={{'textAlign': 'right'}}> {descriptionLength > 175 ? <span style={{color: "red"}} >{descriptionLength-175} characters overloaded (Limit 175)</span> : <span>{175-descriptionLength} characters left.</span> }</div>}>
                            {
                                getFieldDecorator("featureDescription", {
                                    rules: [{
                                        required: true,
                                        message: "Please select a feature resource to display on target resource"
                                    }]
                                })(
                                    <Input.TextArea placeholder={"Feature description"} autoSize={{minRows: 2, maxRows: 6}} maxLength={175} onChange={this.onDescriptionChange} allowClear/>
                                )
                            }
                        </FormItem>
                        {
                            lookup.imageResolutions.map((resolution, index) => {
                                const { key, pd } = resolution;
                                const layout = index === 0 ? formItemLayout : formButtonLayout;
                                const label = index === 0 ? "Image" : "";

                                return (
                                    <FormItem key={index} {...layout} label={label}>
                                        {
                                            getFieldDecorator(`featureImage.${key}`, {
                                                getValueFromEvent: uploadUtility.singleFile.getValueFromEvent,
                                                rules: [{
                                                    required: true,
                                                    message: `Upload a ${key} image`
                                                }, {
                                                    validator: uploadUtility.singleFile.validator
                                                }],
                                                valuePropName: "fileList",
                                            })(
                                                <Upload
                                                    name={"files"}
                                                    accept={"image/*"}
                                                    action={`${process.env.REACT_APP_API_BASE_URL}support/image-upload?imgname=feature_resource__image_${this.state.timestamp}&pd=${pd}&webp=true&excludeTimestamp=true`}
                                                    withCredentials
                                                    listType="picture"
                                                    >
                                                    <Button icon={"upload"}>Card image {pd}</Button>
                                                </Upload>
                                            )
                                        }
                                    </FormItem>
                                )
                            })
                        }
                        <FormItem {...formButtonLayout}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </FormItem>
                    </Form>
                </Spin>
            </div>
        );
    }
}

const Feature = Form.create()(FeatureForm);

Feature.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    reqAddFeatureResource: PropTypes.func.isRequired
};

export default Feature;
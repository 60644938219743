import { createActionTypes } from "../../utils";

export const PressesActions = createActionTypes("presses", [
    "FETCH_PRESSES_LIST_REQUEST",
    "FETCH_PRESSES_LIST_SUCCESS",
    "FETCH_PRESS_REQUEST",
    "FETCH_PRESS_SUCCESS",
    "ADD_PRESS_REQUEST",
    "ADD_PRESS_SUCCESS",
    "UPDATE_PRESS_REQUEST",
    "UPDATE_PRESS_SUCCESS",
    "DELETE_PRESS_REQUEST",
    "DELETE_PRESS_SUCCESS",
    "PRESS_ERROR"
]);

export const fetchPressesListRequest = () => {
    return {
        type: PressesActions.FETCH_PRESSES_LIST_REQUEST
    };
};

export const fetchPressesListSucess = (obj) => {
    return {
        type: PressesActions.FETCH_PRESSES_LIST_SUCCESS,
        payload: obj
    };
};

export const fetchPressRequest = (obj) => {
    return {
        type: PressesActions.FETCH_PRESS_REQUEST,
        payload: obj
    };
};

export const fetchPressSuccess = (obj) => {
    return {
        type: PressesActions.FETCH_PRESS_SUCCESS,
        payload: obj
    };
};

export const addPressRequest = (obj) => {
    return {
        type: PressesActions.ADD_PRESS_REQUEST,
        payload: obj
    };
};

export const addPressSuccess = (obj) => {
    return {
        type: PressesActions.ADD_PRESS_SUCCESS,
        payload: obj
    };
};

export const updatePressRequest = (obj) => {
    return {
        type: PressesActions.UPDATE_PRESS_REQUEST,
        payload: obj
    };
};

export const updatePressSuccess = (obj) => {
    return {
        type: PressesActions.UPDATE_PRESS_SUCCESS,
        payload: obj
    };
};

export const deletePressRequest = (obj) => {
    return {
        type: PressesActions.DELETE_PRESS_REQUEST,
        payload: obj
    };
};

export const deletePressSuccess = (obj) => {
    return {
        type: PressesActions.DELETE_PRESS_SUCCESS,
        payload: obj
    };
};

export const pressError = (obj) => {
    return {
        type: PressesActions.PRESS_ERROR,
        payload: obj
    };
};

export default PressesActions;

export default {
    historyTabColumns: [
        {
            "title": "Business Name",
            "key": "businessName",
            "width": "20%",
            "align": "left",
            "searchable": true,
        },
        {
            "title": "Business Address",
            "key": "businessAddress",
            "width": "15%",
            "align": "left"
        },
        {
            "title": "Phone Number",
            "key": "phoneNumber",
            "width": "10%",
            "align": "center"
        },
        {
            "title": "Category",
            "key": "category",
            "width": "10%",
            "align": "center"
        },
        {
            "title": "Last Scan",
            "key": "lastScanDate",
            "width": "10%",
            "align": "center"
        },
        {
            "title": "Score",
            "key": "score",
            "width": "8%",
            "align": "center"
        },
        {
            "title": "Action",
            "key": "action",
            "width": "15%",
            "align": "center"
        }
    ],
    industry: [
        {"label": "Advertising / Media / Agency", "value": "Advertising / Media / Agency"},
        {"label": "Agriculture, Fishing & Forestry", "value": "Agriculture, Fishing & Forestry"},
        {"label": "Arts & Entertainment", "value": "Arts & Entertainment"},
        {"label": "Automotive", "value": "Automotive"},
        {"label": "Beauty", "value": "Beauty"},
        {"label": "Business Services", "value": "Business Services"},
        {"label": "Community & Social Services", "value": "Community & Social Services"},
        {"label": "Construction", "value": "Construction"},
        {"label": "Consumer Goods", "value": "Consumer Goods"},
        {"label": "Consumer Services", "value": "Consumer Services"},
        {"label": "Contractors", "value": "Contractors"},
        {"label": "Dental", "value": "Dental"},
        {"label": "E-commerce", "value": "E-commerce"},
        {"label": "Education", "value": "Education"},
        {"label": "Finance", "value": "Finance"},
        {"label": "Government", "value": "Government"},
        {"label": "Healthcare", "value": "Healthcare"},
        {"label": "Home Services", "value": "Home Services"},
        {"label": "Hospitality", "value": "Hospitality"},
        {"label": "Insurance", "value": "Insurance"},
        {"label": "Legal", "value": "Legal"},
        {"label": "Manufacturing", "value": "Manufacturing"},
        {"label": "Real Estate", "value": "Real Estate"},
        {"label": "Recreation", "value": "Recreation"},
        {"label": "Restaurants", "value": "Restaurants"},
        {"label": "Retail", "value": "Retail"},
        {"label": "Technology", "value": "Technology"},
        {"label": "Transportation Services", "value": "Transportation Services"},
        {"label": "Wellness", "value": "Wellness"},
        {"label": "Other", "value": "Other"}
    ],
    country: [
        {"label": "United States", "value": "US"},
        {"label": "Australia", "value": "AU"},
        {"label": "Canada", "value": "CA"},
        {"label": "United Kingdom", "value": "UK"}
    ]
}
import { takeLatest, call, put } from "redux-saga/effects";
import SalesRepActions, * as actionCreator from "./actions";
import salesRepApi from "./http";
import { isObject } from "util";

const salesRepSaga = [
    takeLatest(
        SalesRepActions.FETCH_SALES_REPS_LIST_REQUEST,
        fetchSalesRepsList
    ),
    takeLatest(SalesRepActions.FETCH_SALES_REP_REQUEST, fetchUser),
    takeLatest(SalesRepActions.ADD_SALES_REP_REQUEST, addUser),
    takeLatest(SalesRepActions.UPDATE_SALES_REP_REQUEST, updateUser),
    takeLatest(SalesRepActions.DELETE_SALES_REP_REQUEST, deleteUser),
    takeLatest(SalesRepActions.FETCH_ALL_SALES_REP_INVITATION_REQUEST, fetchAllInvitation),
    takeLatest(SalesRepActions.FETCH_SALES_REPS_TIME_ZONES_REQUEST, fetchSalesRepTimeZonesRequest)
];

function* fetchSalesRepsList() {
    try {
        const resp = yield call(salesRepApi.getAll);
        yield put(actionCreator.fetchSalesRepsListSucess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.salesRepError(e.data.data || { error: "usersListNotFound" })
        );
    }
}

function* fetchUser(action) {
    try {
        const resp = yield call(salesRepApi.getById, action.payload.id);
        yield put(actionCreator.fetchSalesRepSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.salesRepError(e.data.data || { error: "userNotFound" }));
    }
}

function* addUser(action) {
    try {
        const resp = yield call(salesRepApi.add, action.payload.data);
        yield put(actionCreator.addSalesRepSuccess(action.payload.data));
        yield call(action.payload.cb, {});
    } catch (e) {
        yield put(actionCreator.salesRepError(e.data.data || { error: "cannotAdd" }));
        yield call(action.payload.cb, {
            userErrorType: "cannotAdd",
            errorMessage: e.data.data.error
        });
    }
}

function* updateUser(action) {
    try {
        const resp = yield call(salesRepApi.update, action.payload.data);
        yield put(actionCreator.updateSalesRepSuccess(action.payload.data));
        yield call(action.payload.cb, {});
    } catch (e) {
        yield put(actionCreator.salesRepError(e.data.data || { error: "cannotUpdate" }));
        yield call(action.payload.cb, {
            userErrorType: "cannotUpdate",
            errorMessage: e.data.data.error
        });
    }
}

function* deleteUser(action) {
    try {
        const resp = yield call(salesRepApi.delete, action.payload.id);
        yield put(actionCreator.deleteSalesRepSuccess(action.payload.id));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.salesRepError(e.data.data || { error: "cannotDelete" }));
        yield call(action.payload.cb, "cannotDelete");
    }
}

function* fetchAllInvitation(action) {
    try {
        const resp = yield call(salesRepApi.getInvitation, action.payload.pageId);
        yield put(actionCreator.fetchSalesRepInvitationSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.fetchSalesRepInvitationError([]));
    }
}

function* fetchSalesRepTimeZonesRequest() {
    try{
        const resp = yield call(salesRepApi.getTimezones);
        yield put(actionCreator.fetchSalesRepTimeZonesSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.fetchSalesRepTimeZonesError([]));
    }
}

export default salesRepSaga;

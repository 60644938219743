import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {Button, Form, Input, Select, message, Alert, Icon} from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";
import {isEmpty} from "lodash";

import "./CampaignUrlBuilder.scss";

const
    FormItem = Form.Item,
    SelectOption = Select.Option,
    {TextArea} = Input;

class CampaignUrlBuilderForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            finalCampaignUrl: "",
            modifyUrlId: "",
            channelType: "Paid"
        };

        this.mainDiv = null;
        this.finalUrlDiv = null;
        this.hiddenField = null;
        this.customFieldId = 0;
        this.campaignUrlFields = {};
    }

    componentDidMount() {
        const 
            {history: {location: {pathname = ""}, push}, pageData: {campaignUrlBuilder=[]}, reqPageDataCampaignUrlBuilder} =  this.props;
        
        isEmpty(campaignUrlBuilder) && reqPageDataCampaignUrlBuilder();

        if (pathname.indexOf("/leadgen-mngt/campaign-url-builder") !== -1) {
            push('/campaign-url-builder/new');
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const 
            {match: {params: {urlId: nextUrlId = ""}}} = nextProps,
            {modifyUrlId} = prevState;

        if (nextUrlId && (modifyUrlId !== nextUrlId)) {
            const
                decodedUrl = decodeURIComponent(nextUrlId);

            return {modifyUrlId: decodedUrl};
        }

        if (nextUrlId === "") {
            return {modifyUrlId: ""};
        }
        
        return null;
    }

    splitUrlToFieldValue = (url) => {
        const 
            {pageData: {campaignUrlBuilder}} = this.props,
            {origin, pathname, searchParams} = new URL(url);

        let fieldObj = {};

        if (isEmpty(campaignUrlBuilder)) {
            return fieldObj;
        }

        fieldObj.websiteUrl = `${origin}${pathname}`;
        fieldObj.customFields = [];
        fieldObj.customFieldIds = [];
        
        for (const [key, value] of searchParams) {

            switch (key) {
                case 'utm_source': fieldObj['campaignSource'] = value; break;
                case 'utm_medium': fieldObj['campaignMedium'] = value; break;
                case 'utm_campaign': fieldObj['campaignName'] = value; break;
                case 'utm_term': fieldObj['campaignTerm'] = value; break;
                case 'utm_content': fieldObj['campaignContent'] = value; break;
                case 'utm_sfcamp': fieldObj['campaignSalesforceId'] = value; break;
                default:
                    fieldObj.customFields.push({key, value});
                    fieldObj.customFieldIds.push(this.customFieldId);
                    this.customFieldId += 1;
            }
        }

        const filteredMarketingChannel = campaignUrlBuilder.filter(({campaign_medium, campaign_source}) => (campaign_source === fieldObj.campaignSource && campaign_medium === fieldObj.campaignMedium));
        if (isEmpty(filteredMarketingChannel)) {
            fieldObj.marketingChannel = "custom";
        } else {
            fieldObj.marketingChannel = filteredMarketingChannel[0].marketing_channel;
        }

        return fieldObj;
    };

    handleOnSubmit = (e) => {
        e.preventDefault();

        const {form, logCampaignUrlBuilderActivity, userData} = this.props;

        form.validateFieldsAndScroll((error, values) => {

            if (!!error) {
                return;
            }

            const
                {websiteUrl, campaignSource, campaignMedium, campaignName, campaignTerm, campaignContent, campaignSalesforceId, customFields} = values,
                ignoredCustomKeys = [undefined, null, '', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_sfcamp'],
                url = new URL(websiteUrl);

            let finalCampaignUrl = '';

            if (campaignSource) {
                url.searchParams.set('utm_source', this.replaceSpaceWithUnderscore(campaignSource.toLowerCase()));
            }

            if (campaignMedium) {
                url.searchParams.set('utm_medium', this.replaceSpaceWithUnderscore(campaignMedium.toLowerCase()));
            }

            if (campaignName) {
                url.searchParams.set('utm_campaign', this.replaceSpaceWithUnderscore(campaignName.toLowerCase()));
            }

            if (campaignTerm) {
                url.searchParams.set('utm_term', this.replaceSpaceWithUnderscore(campaignTerm.toLowerCase()));
            }

            if (campaignContent) {
                url.searchParams.set('utm_content', this.replaceSpaceWithUnderscore(campaignContent.toLowerCase()));
            }

            if (campaignSalesforceId) {
                url.searchParams.set('utm_sfcamp', this.replaceSpaceWithUnderscore(campaignSalesforceId));
            }

            if (customFields) {
                for(const customField of customFields) {
                    if (!customField) {
                        continue;
                    }

                    const {key, value} = customField;

                    if (ignoredCustomKeys.indexOf(key) === -1) {
                        url.searchParams.set(this.replaceSpaceWithUnderscore(key.toLowerCase()), this.replaceSpaceWithUnderscore(value.toLowerCase()));
                    }
                }
            }

            finalCampaignUrl = url.toString();

            this.setState({finalCampaignUrl: finalCampaignUrl}, () => {
                this.finalUrlDiv.scrollIntoView({ behavior: 'smooth' });
                this.handleCopyToClipboard();
                logCampaignUrlBuilderActivity({user: userData.name, campaignUrl: finalCampaignUrl});
            });
        });
    };

    handleMarketingChannelChange = (selectedMarketingChannel) => {
        const
            {pageData} = this.props,
            {campaignUrlBuilder} = pageData;
        let campaignSource = "",  campaignMedium = "";

        if (campaignUrlBuilder && selectedMarketingChannel !== "custom") {
            const
                selectedMarketingChannelObj = campaignUrlBuilder.filter(value => (value.marketing_channel === selectedMarketingChannel));

            if (selectedMarketingChannelObj[0]) {
                campaignSource = selectedMarketingChannelObj[0].campaign_source;
                campaignMedium = selectedMarketingChannelObj[0].campaign_medium;
            }
        }

        this.props.form.setFieldsValue({campaignSource, campaignMedium});
    };

    handleChannelTypeUpdate = (channelType) => {
        this.setState({ channelType });
        this.props.form.setFieldsValue({marketingChannel: "",campaignSource: "", campaignMedium: "", campaignName: "", campaignSalesforceId: "", campaignContent: "", campaignTerm: ""});
    }

    handleFormReset = (e) => {
        e.preventDefault();
        this.setState({finalCampaignUrl: ""}, () => {
            this.mainDiv.scrollIntoView({ behavior: 'smooth' });
            this.props.form.resetFields()
        });
    };

    handleCustomParameterFieldAdd = (e) => {
        const
            {form} = this.props,
            customFieldIds = form.getFieldValue('customFieldIds');

        form.setFieldsValue({customFieldIds: customFieldIds.concat(this.customFieldId++)});
    };

    handleCustomParameterFieldRemove = (removeCustomFieldId) => {
        const
            { form } = this.props,
            customFieldIds = form.getFieldValue('customFieldIds');

        form.setFieldsValue({
            customFieldIds: customFieldIds.filter(customFieldId => customFieldId !== removeCustomFieldId),
        });
    };

    handleCopyToClipboard = () => {
        try {
            this.hiddenField.select();
            document.execCommand('copy');
            message.success('Successfully copied!');
        } catch (e) {
            message.error('Oops! Unable to copy, though url has been generated.');
        }
    };

    replaceSpaceWithUnderscore = (string) => {
        return string.replace(/ +/g, '_');
    }

    render() {
        const
            { error, userData, isLoading, pageData } = this.props,
            {finalCampaignUrl, modifyUrlId, channelType} = this.state,
            { getFieldDecorator } = this.props.form,
            unauthorized = error === "unauthorized" || !userData.name,
            {campaignUrlBuilder} = pageData,
            marketingChannel =  this.props.form.getFieldValue("marketingChannel"),
            customFieldIds =  this.props.form.getFieldValue("customFieldIds") || this.campaignUrlFields.customFieldIds || [],
            isMarketingChannelCustom = (marketingChannel === "custom"),
            channelsWithEmptyCampaignSource = campaignUrlBuilder.filter(option => option.campaign_source === "").map(option => option.marketing_channel),
            isEmptyCampaignSource = channelsWithEmptyCampaignSource.indexOf(marketingChannel) !== -1,
            channelTypeOptions = [ {value: "Paid"}, {value: "Organic"} ];

        let channelOptions = [];
        if (channelType === "Paid") {
            channelOptions = campaignUrlBuilder.filter(option => option.marketing_channel.indexOf("Paid") !== -1);
        } else {
            channelOptions = campaignUrlBuilder.filter(option => option.marketing_channel.indexOf("Paid") === -1);
        }

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        if (!isEmpty(modifyUrlId) && !isEmpty(campaignUrlBuilder) && isEmpty(this.campaignUrlFields)) {
            this.campaignUrlFields = this.splitUrlToFieldValue(modifyUrlId);
        }

        if (modifyUrlId === "") {
            this.campaignUrlFields = {};
        }

        getFieldDecorator("customFieldIds", {initialValue: this.campaignUrlFields.customFieldIds || []});

        return (
            <div className={"campaignUrlBuilder"} ref={el => { this.mainDiv = el }}>
                <h2>Campaign URL Builder</h2>
                <Form onSubmit={this.handleOnSubmit}>
                    <FormItem {...formItemLayout} label={"Landing Page URL"} extra={<p>The landing  page URL without query string (e.g. <code>https://birdeye.com/landingPage/</code>)</p>}>
                        {
                            getFieldDecorator("websiteUrl", {
                                rules: [{
                                    "required": true,
                                    "message": "Provide a landing page URL"
                                }, {
                                    "type": "url",
                                    "message": "Provide valid landing page URL"
                                }, {
                                    "pattern": /^((?!\?).)*$/,
                                    "message": "Provide landing page URL without query string"
                                }],
                                initialValue: this.campaignUrlFields.websiteUrl
                            })(
                                <TextArea placeholder={"Landing Page URL"} autosize={{minRows: 1}}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Channel Type"} extra={""}>
                        {
                            getFieldDecorator("channelType", {
                                rules: [{
                                    "required": true,
                                    "message": "Select a channel type"
                                }],
                                initialValue: "Paid"
                            })(
                                <Select placeholder={"Channel Type"} onChange={this.handleChannelTypeUpdate} showSearch>
                                    {
                                        channelTypeOptions.map((channelType, index) => (
                                            <SelectOption key={index} value={channelType.value}>{channelType.value}</SelectOption>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Channel"} extra={""}>
                        {
                            getFieldDecorator("marketingChannel", {
                                rules: [{
                                    "required": true,
                                    "message": "Select a channel"
                                }],
                                initialValue: this.campaignUrlFields.marketingChannel
                            })(
                                <Select placeholder={"Channel"} onChange={this.handleMarketingChannelChange} showSearch>
                                    {

                                        channelOptions.map((marketingChannel, index) => (
                                            <SelectOption key={`marketingChannels-${marketingChannel.marketing_channel}`} value={marketingChannel.marketing_channel}>{marketingChannel.marketing_channel}</SelectOption>
                                        ))
                                    }
                                    {channelType === "Organic" && <SelectOption key={"marketingChannels-custom"} value={"custom"}>Custom</SelectOption>}
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Campaign Source"} extra={<><p>Use <code>utm_source</code> to identify a search engine, newsletter name, or other source.<br/><i>Example</i>: <code>google</code>, <code>newsletter</code></p><p><Icon type="info-circle" theme={"filled"}/> In SFDC <code>Campaign Source = Lead Campaign</code></p></>}>
                        {
                            getFieldDecorator("campaignSource", {
                                rules: [{
                                    "required": true,
                                    "message": "Provide a campaign source"
                                }],
                                initialValue: this.campaignUrlFields.campaignSource
                            })(
                                <Input placeholder={"Campaign Source (utm_source)"} readOnly={!isMarketingChannelCustom && !isEmptyCampaignSource}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Campaign Medium"} extra={<><p>Use <code>utm_medium</code> to identify a medium such as email or cost-per-click.<br/><i>Example</i>: <code>cpc</code>, <code>banner</code>, <code>email</code></p><p><Icon type="info-circle" theme={"filled"}/> In SFDC this field isn't stored instead, it is used to distinguish the traffic.</p></>}>
                        {
                            getFieldDecorator("campaignMedium", {
                                rules: [{
                                    "required": true,
                                    "message": "Provide a campaign medium"
                                }],
                                initialValue: this.campaignUrlFields.campaignMedium
                            })(
                                <Input placeholder={"Campaign Medium (utm_medium)"} readOnly={!isMarketingChannelCustom}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Campaign Name"} extra={<><p>Use <code>utm_campaign</code> to identify a specific product promotion or strategic campaign.<br/><i>Example</i>: <code>utm_campaign=spring_sale</code></p><p><Icon type="info-circle" theme={"filled"}/> In SFDC <code>Campaign Name = Lead Sub Campaign</code></p></>}>
                        {
                            getFieldDecorator("campaignName", {
                                rules: [{
                                    "required": true,
                                    "message": "Provide a campaign name"
                                }],
                                initialValue: this.campaignUrlFields.campaignMedium
                            })(
                                <Input placeholder={"Campaign Name (utm_campaign)"}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Campaign Term"} extra={<><p>Use <code>utm_term</code> to track additional information and variants for a single campaign or post.<br/><i>Example</i>: <code>running+shoes</code></p><p><Icon type="info-circle" theme={"filled"}/> In SFDC <code>Campaign Term = Lead Campaign KW</code></p></>}>
                        {
                            getFieldDecorator("campaignTerm", {
                                rules: [{
                                    "required": true,
                                    "message": "Provide a campaign term"
                                }],
                                initialValue: this.campaignUrlFields.campaignTerm
                            })(
                                <Input placeholder={"Campaign Term (utm_term)"}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Campaign Content"} extra={<><p>Use <code>utm_content</code> to differentiate ads or links that point to the same URL.<br/><i>Example</i>: <code>logolink</code> or <code>textlink</code></p><p><Icon type="info-circle" theme={"filled"}/> In SFDC this field isn't stored instead, it is used to distinguish the traffic.</p></>}>
                        {
                            getFieldDecorator("campaignContent", {
                                rules: [],
                                initialValue: this.campaignUrlFields.campaignContent
                            })(
                                <Input placeholder={"Campaign Content (utm_content)"}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Salesforce Campaign ID"} extra={<><p>Use <code>utm_sfcamp</code> to provide Salesforce campaign ID. It helps in associating multiple campaigns into single Salesforce campaign.</p><p><Icon type="info-circle" theme={"filled"}/> In SFDC <code>utm_sfcamp = Salesforce Campaign ID</code></p></>}>
                        {
                            getFieldDecorator("campaignSalesforceId", {
                                rules: [],
                                initialValue: this.campaignUrlFields.campaignSalesforceId
                            })(
                                <Input placeholder={"Salesforce Campaign ID (utm_sfcamp)"}/>
                            )
                        }
                    </FormItem>
                    <div>
                        {
                            customFieldIds && customFieldIds.map((customFieldId, index) => {
                                const 
                                    {customFields=[]} = this.campaignUrlFields,
                                    {key = "", value = ""} = customFields[customFieldId] || {};

                                return (
                                    /*
                                        Someone need to fix this FormItem and getFieldDecorator warning,
                                        as this isn't severe right now, but it can't be ignored
                                    */
                                    <FormItem {...(index === 0 ? formItemLayout : formButtonLayout)} label={index === 0 ? "Custom Parameters" : ""} key={`custom-parameter-${index}`}>
                                        {
                                            getFieldDecorator(`customFields[${customFieldId}][key]`, {initialValue: key})(<Input className={"inputCustomFieldKey"} placeholder={"Key"}/>)
                                        }
                                        {
                                            getFieldDecorator(`customFields[${customFieldId}][value]`, {initialValue: value})(<Input className={"inputCustomFieldValue"} placeholder={"Value"}/>)
                                        }
                                        {
                                            customFieldIds.length > 0 ? (<Icon className={"btnRemoveCustomField"} type="minus-circle-o" onClick={() => this.handleCustomParameterFieldRemove(customFieldId)}/>) : null
                                        }
                                    </FormItem>
                                )
                            })
                        }
                    </div>
                    <Form.Item {...formButtonLayout}>
                        <Button type="dashed" onClick={this.handleCustomParameterFieldAdd} style={{ width: '44%' }}>
                            <Icon type="plus" /> Add Custom Parameter
                        </Button>
                    </Form.Item>
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit" icon={"copy"} loading={isLoading} className={"btnGenerateCopy"}>
                            Generate & Copy
                        </Button>
                        <Button type="default" htmlType="reset" className={"btnReset"} onClick={this.handleFormReset}>
                            Reset
                        </Button>
                    </FormItem>
                    <FormItem>
                        <div ref={el => { this.finalUrlDiv = el}}>
                            {
                                finalCampaignUrl && <Alert className={"generatedCampaignUrlBuilderUrl"} message={finalCampaignUrl} type={"info"}/>
                            }
                        </div>
                        <textarea ref={el => {this.hiddenField = el}} value={finalCampaignUrl} className={"generatedCampaignUrlBuilderUrlTextArea"} readOnly={true}/>
                    </FormItem>
                </Form>
            </div>
        );
    }
}

CampaignUrlBuilderForm.propTypes = {
    userData: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    reqPageDataCampaignUrlBuilder: PropTypes.func.isRequired,
    logCampaignUrlBuilderActivity: PropTypes.func.isRequired
};
const CampaignUrlBuilder = Form.create()(CampaignUrlBuilderForm);
export default CampaignUrlBuilder;

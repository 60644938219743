import React, { useEffect, useState } from 'react'
import {Form, Select} from "antd";
const { Option } = Select;
import {formItemLayout} from "../../constants";
import { beAPIResource } from '../../utils/apiHelper';
import { getForm } from './getForm';
import { RedirectWrapper } from '../../components';

function AddComponent({userData, error, location}) {
    const unauthorized = error === "unauthorized" || (!userData || !userData.name);
    if (unauthorized) {
        return <RedirectWrapper location={location} />;
    }
    const [options, setOptions] = useState([]);
    const [selectedComponent, setSelectedComponent] = useState({});

    useEffect(() => {
        (async()=>{
            const {data: components} = await beAPIResource.get('support/website/content/page/components');
            setOptions(components);
        })()    
    }, [])
    

    const onChange = (value) => {
        const matchedComponent = options.filter(option => option.name === value)[0];
        setSelectedComponent(matchedComponent);
    }

    return (
        <Form>
            <Form.Item {...formItemLayout} label="Component" style={{ textAlign: "left" }}>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select a component"
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        options.map((component, i) => (<Option key={i} value={component.name}>{component.label}</Option>))
                    }
                </Select>
            </Form.Item>
            {
                selectedComponent.img && 
                <Form.Item {...formItemLayout} label="Preview" style={{ textAlign: "left" }}>
                    <img src={selectedComponent.img} alt="Preview" style={{ maxWidth: '800px' }} />
                </Form.Item>
            }
            {
                getForm({name: selectedComponent.name})
            }
        </Form>
  )
}

export default AddComponent
import React, { Component } from 'react';
import { Steps } from 'antd';
import PropTypes from "prop-types";
import lookup from './constants';
const { Step } = Steps;



class CalendarSteps extends Component{
    constructor(props){
        super(props);

        this.state = {
            currentStep: 0,
            values: {}
        };

        this.steps = lookup.steps.calendar;
    }
    
    handleCalendarStepsForm = (values) => {
        const {values: oldValues, currentStep} = this.state;
        this.setState({values: {...oldValues, ...values }, currentStep: ((currentStep + 1) % (this.steps.length))});
    };
    
    handleStepsChange = (currentStep) => {
        this.setState({ currentStep });
    };
    
    render() {
        const { currentStep, values } = this.state;
        const StepComponent = this.steps[currentStep].content;
        const { handleCalendarFinalSubmission, initialValues } = this.props;
        return(
            <>
                <Steps size="small" type="navigation" current={currentStep} onChange={this.handleStepsChange} status={"wait"} >
                    {
                        this.steps.map((step, idx) => (
                            <Step key = {idx} 
                                status={ !(initialValues.isEditPage) && (currentStep > idx && !values.hasOwnProperty(step.dataKey)) ? "error" : ""} 
                                title={<>{step.title} {!step.isOptional && <span style={{ color: "red" }}>*</span>}</>}  
                            />
                        ))
                    }
                </Steps>
                <div style={{marginTop: "50px"}}>
                    {
                        <StepComponent handleCalendarStepsForm={this.handleCalendarStepsForm} formInitialValues={values} initialValues={{ ...initialValues, ...values }} salesRepListData ={this.props.salesRepListData} handleCalendarFormSubmission={{steps: this.steps, handleCalendarFinalSubmission}} fallBackCal ={this.props.fallBackCal} hasCalendarAdminAccess={this.props.hasCalendarAdminAccess} />
                    }
                </div>
            </>
        )
    }
};

CalendarSteps.propTypes = {
    salesRepListData:PropTypes.array.isRequired,
    initialValues: PropTypes.object.isRequired,
    handleCalendarFinalSubmission: PropTypes.func.isRequired,
    fallBackCal: PropTypes.array.isRequired,
    hasCalendarAdminAccess: PropTypes.bool.isRequired
};

export default CalendarSteps;




import React from "react";  
import { TableSkeleton } from "../../components";
import lookup from "./constants";
import { Button, Divider, Modal, Form, Input} from 'antd';
import { formItemLayout } from "../../constants";
import { isEmpty } from "lodash";

class SelfServePage extends React.Component {

    constructor(props) {
        super(props);
        this.pageKey = "integrationsList";
        this.state = {
            visible: true,
            actionBtn: null
        }
    }


    handleActionMethod = (e, record) => {
        const {sourceDisplayName, parentBusinessNumber} = record;
        const actionValue = e.target.name;
        const payload = {crmName: sourceDisplayName, businessNum: parentBusinessNumber };
        this.setState({ visible: true, actionBtn: actionValue});
        if(actionValue === 'Reject'){
            this.props.data.reqSelfServeReject({data: payload, cb: this.renderRejectModal});
        }else {
            this.props.data.reqSelfServeApprove({data: payload, cb: this.renderApproveWithoutFields});    
        }
    }

    
    handleApproveCancel = () => {
        this.setState({ visible: false });
    };

    handleApproveSubmit = (e, result ={}) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }
            const {properties = [], groupId} = result;
            const mandatoryFields = properties.map((ele) => {
                const { encrypted,fieldKey, fieldLabel } = ele;
                return {encrypted, fieldKey, fieldValue: values[fieldLabel]};
            })
            const finalData = {crmData: {groupId, properties: mandatoryFields }, userEmail: this.props.data.userData.name};
            this.props.data.reqSelfServeCRMSave({data: finalData, cb: this.renderFeedback });
        })
    }

    renderApproveWithoutFields = (result ={}) => {
        const {properties = [], groupId = ''} = result;
        const mandatoryFields = properties.filter((ele) => ele.requiredForSupportCase);
        if(isEmpty(mandatoryFields)){
            const finalData = {crmData: { groupId }, userEmail: this.props.data.userData.name};
            this.props.data.reqSelfServeCRMSave({data: finalData, cb: this.renderFeedback });
        }
    }

    renderRejectModal = (response = {}) => {
        const result = response;
        const {groupId} = result;
        const mandatoryFields = [{ fieldKey: "support.case.status", fieldValue: "Rejected" }];
        const finalData = {crmData: { groupId, properties: mandatoryFields }, userEmail: this.props.data.userData.name };
        this.props.data.reqSelfServeCRMSave({data: finalData, cb: this.renderFeedback });
    };

    renderFeedback = (response) => {
        const {actionBtn} = this.state;
        if (!isEmpty(response) && response.status === 200) {
            this.props.data.reqBusinessDetails();
            return Modal.success({
                title: `${actionBtn} action performed successfully`,
                okText: "OK",
                maskClosable: true
            });
        } else {
            return Modal.warning({
                title: `${actionBtn} action not performed successfully, Please try again later.`,
                content: response.data.message || "API failed"
            });
        }
    };

    formatData = (data = []) => {
        return data.map((record, idx) => {
            const requestSent = record.caseStatus ? record.caseStatus.toLowerCase() : '';
            return {
                key: record.parentBusinessNumber + "_" + idx,
                crmName: record.sourceDisplayName ?  record.sourceDisplayName : "-",
                sfdcCaseURL: record.link ? record.link : "",
                sfdcCaseId: record.caseId ? String(record.caseId) : "-",
                caseStatus: record.caseStatus ? record.caseStatus : "-",
                businessNumber: record.parentBusinessNumber ? String(record.parentBusinessNumber) : "-",
                assignee: record.assignee ? record.assignee : "-",
                requestedAt: record.requestAt ? record.requestAt : "-",
                updatedAt: record.updatedAt ? record.updatedAt : "-",
                action: (
                    requestSent === 'request sent' &&
                    <span>
                        <a name="Approve" onClick={( e ) => this.handleActionMethod(e, record)}>Approve</a>
                        <Divider type="vertical" />
                        <a name="Reject" onClick={( e ) => this.handleActionMethod(e, record)}>Reject</a>
                    </span>
                )
            }
        })
    }

    handleColumnsFormat = (tabName = "Pending") => {
        const actionColumn = tabName === "Pending" ? 
        [{
            title:"Action",
            key:"action",
            width: "15%",
            align: "center"
        }] : [] ;

        const caseStatusColumn = tabName !== "Pending" ? 
            [{
                "title": "Case Status",
                "key": "caseStatus",
                "width": "13%",
                "filters": [{
                    "text": "Approved",
                    "value": "Approved"
                },
                {
                    "text": "Rejected",
                    "value": "Rejected"
                }],
                onFilter: (value, lists) => (lists.caseStatus === value),
            }] : [];
        
       return [...lookup.selfServe, ...caseStatusColumn, ...actionColumn];
    }

    render() {
        const { businessDetailsData, data, form, tabName} = this.props;
        const {selfServeCRMDetails = {}, isLoading } = data;
        const { getFieldDecorator } = form;
        const {visible, actionBtn} = this.state;
        const {properties = [], groupId = ''} = selfServeCRMDetails;
        const mandatoryFields = properties.filter((ele) => ele.requiredForSupportCase);
        const selfServeData = {properties: mandatoryFields, groupId};
        const columnNameSetup = this.handleColumnsFormat(tabName);

        return (
            <div>
                <TableSkeleton 
                    columns={columnNameSetup}
                    dataSource={this.formatData(businessDetailsData)}
                    loading={isLoading}
                />
                { !isEmpty(mandatoryFields) && actionBtn === 'Approve' &&
                    <Modal
                        title="Self Serve Approve Panel"
                        visible={visible}
                        onCancel={this.handleApproveCancel}
                        width={820}
                        footer = {[
                            <Button key="cancel" onClick={this.handleApproveCancel}>Cancel</Button>,
                            <Button key="approve" type="primary" onClick={(e) => this.handleApproveSubmit(e, selfServeData)}>Approve</Button>
                        ]}
                        >
                        <Form {...formItemLayout} onSubmit={(e) => this.handleApproveSubmit(e, selfServeData)}>
                            {
                                mandatoryFields.map((ele) => {
                                    const {fieldLabel, fieldKey} = ele;
                                    return (
                                        <Form.Item key={`${fieldKey}`} label={`${fieldLabel}`}>
                                        {getFieldDecorator(`${fieldLabel}`, {
                                            rules: [{ required: true, message: `Please provide ${fieldLabel}` }],
                                        })(
                                            <Input placeholder={`Please provide ${fieldLabel}`}/>
                                        )}
                                        </Form.Item>
                                    )
                                })
                            }
                        </Form>
                    </Modal>
                }
            </div>
        )
    }
}
const SelfServePanel = Form.create()(SelfServePage);
export default SelfServePanel;
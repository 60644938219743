import { takeLatest, call, put } from "redux-saga/effects";
import RequestActions, * as actionCreator from "./actions";
import requestBusinessAccessApi from "./http";

const userOperationSagas = [
    takeLatest( RequestActions.FETCH_BUSINESS_LIST, fetchBusinessList),
    takeLatest( RequestActions.FETCH_REQUEST_HISTORY_LIST, fetchRequestHistoryList),
    takeLatest( RequestActions.REQUEST_ACCESS_TO_BUSINESS, requestAccessToBusiness)
];

function* fetchBusinessList(action) {
    try {
        const resp = yield call(requestBusinessAccessApi.getBusinessList, action.payload);
        yield put(actionCreator.fetchBusinessListSuccess(resp.data.businessList));
    } catch (e) {
        yield put(
            actionCreator.fetchBusinessListError(e.data.data || { error: "Business Not Found" })
        );
    }
}

function* requestAccessToBusiness(action) {
    try {
        const resp = yield call(requestBusinessAccessApi.requestAccessToBusiness, action.payload);
        yield put(actionCreator.requestAccessToBusinessSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.requestAccessToBusinessError(e.data || { error: "requestAccessToBusinessError" }));
        yield call(action.payload.cb, e.data.data);
    }
}

function* fetchRequestHistoryList(action) {
    try {
        const resp = yield call(requestBusinessAccessApi.getBusinessAccessRequestHistory, action.payload);
        yield put(actionCreator.fetchRequestHistoryListSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.fetchRequestHistoryListError(e.data.data || { error: "fetchRequestListErrorError" })
        );
    }
}

export default userOperationSagas;

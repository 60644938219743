import { createActionTypes } from "../../utils";

export const EffectsActions = createActionTypes("effects", [
    "FETCH_EFFECTS_LIST_REQUEST",
    "FETCH_EFFECTS_LIST_SUCCESS",
    "FETCH_EFFECT_REQUEST",
    "FETCH_EFFECT_SUCCESS",
    "UPDATE_EFFECT_REQUEST",
    "UPDATE_EFFECT_SUCCESS",
    "FETCH_CATEGORIES_LIST_REQUEST",
    "FETCH_CATEGORIES_LIST_SUCCESS",
    "FETCH_CATEGORY_REQUEST",
    "FETCH_CATEGORY_SUCCESS",
    "UPDATE_CATEGORY_REQUEST",
    "UPDATE_CATEGORY_SUCCESS",
    "EFFECT_ERROR"
]);

export const fetchEffectsListRequest = () => {
    return {
        type: EffectsActions.FETCH_EFFECTS_LIST_REQUEST
    };
};

export const fetchEffectsListSucess = (obj) => {
    return {
        type: EffectsActions.FETCH_EFFECTS_LIST_SUCCESS,
        payload: obj
    };
};

export const fetchEffectRequest = (obj) => {
    return {
        type: EffectsActions.FETCH_EFFECT_REQUEST,
        payload: obj
    };
};

export const fetchEffectSuccess = (obj) => {
    return {
        type: EffectsActions.FETCH_EFFECT_SUCCESS,
        payload: obj
    };
};

export const updateEffectRequest = (obj) => {
    return {
        type: EffectsActions.UPDATE_EFFECT_REQUEST,
        payload: obj
    };
};

export const updateEffectSuccess = (obj) => {
    return {
        type: EffectsActions.UPDATE_EFFECT_SUCCESS,
        payload: obj
    };
};

export const fetchCategoriesListRequest = () => {
    return {
        type: EffectsActions.FETCH_CATEGORIES_LIST_REQUEST
    };
};

export const fetchCategoriesListSucess = (obj) => {
    return {
        type: EffectsActions.FETCH_CATEGORIES_LIST_SUCCESS,
        payload: obj
    };
};

export const fetchCategoryRequest = (obj) => {
    return {
        type: EffectsActions.FETCH_CATEGORY_REQUEST,
        payload: obj
    };
};

export const fetchCategorySucess = (obj) => {
    return {
        type: EffectsActions.FETCH_CATEGORY_SUCCESS,
        payload: obj
    };
};

export const updateCategoryRequest = (obj) => {
    return {
        type: EffectsActions.UPDATE_CATEGORY_REQUEST,
        payload: obj
    };
};

export const updateCategorySucess = (obj) => {
    return {
        type: EffectsActions.UPDATE_CATEGORY_SUCCESS,
        payload: obj
    };
};

export const effectError = (obj) => {
    return {
        type: EffectsActions.EFFECT_ERROR,
        payload: obj
    };
};

export default EffectsActions;

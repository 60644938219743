import React, {Component} from "react";
import PropTypes from "prop-types";
import { Tooltip, Button, Descriptions, Icon, Alert } from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";
import { isEmpty } from "lodash";

const { Item: DescriptionsItem } = Descriptions;

class Overview extends Component {

    handleStepsSubmission = (e) => {
        e.preventDefault();

        let stepValues = this.props.initialValues;
        const steps = this.props.stepsSubmission.steps || [];
        for ( const step of steps ) {
            if ( step.isOptional && !stepValues.hasOwnProperty(step.dataKey) ) {
                stepValues[step.dataKey] = {};
            }
        }
        
        this.props.stepsSubmission.handleStepsSubmission(stepValues);
    }

    render() {
        const { stepsSubmission, initialValues } = this.props;
        const { steps = [] } = stepsSubmission;

        // Collecting all the dataKeys of the steps.
        // Additionally we may add required/optional steps.
        const filteredSteps = steps.filter(({isIgnored}) => (!isIgnored))
        let stepsWithStatus = [];
        let incompleteStep = 0;

        for(const step of filteredSteps) {
            const isSubmitted = initialValues.hasOwnProperty(step.dataKey);
            let isSkipped = false;

            if (!step.isOptional && !isSubmitted) {
                incompleteStep += 1;
            } else if (step.isOptional && isSubmitted && isEmpty(initialValues[step.dataKey])) {
                isSkipped = true;
            }

            stepsWithStatus.push({ ...step, isSubmitted, isSkipped });
        }

        const description = (
            <>
                <Descriptions size={"middle"} title={"Overall Status"} className={"overviewDescription"}>
                    {
                        stepsWithStatus.map((step, index) => (
                            <DescriptionsItem key={index} label={`${step.title} Step`}>
                                {
                                    step.isSkipped ? (
                                        <Tooltip title={"Skipped"} placement={"right"}><Icon type="issues-close" style={{ fontSize: "20px", color: "#ffc107" }} /></Tooltip>
                                    ) : (
                                        step.isSubmitted ? (
                                            <Tooltip title={"Submitted"} placement={"right"}><Icon type="check-circle" style={{ fontSize: "20px", color: "#008000" }} /></Tooltip>
                                        ) : (
                                            <Tooltip title={"Missed"} placement={"right"}><Icon type="warning" style={{ fontSize: "20px", color: "#ff0000" }} /></Tooltip>
                                        )
                                    )
                                     
                                }
                            </DescriptionsItem>
                        ))
                    }
                </Descriptions>
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                    {
                        incompleteStep ? (
                            <strong>Ooops! It seems like you missed few of required steps. Kindly fill them to publish changes.</strong>
                        ) : (
                            <Button type={"primary"} onClick={this.handleStepsSubmission}>Click to Publish</Button>
                        )
                    }
                </div>
            </>
        )

        return (
            <div>
                <Alert description={description} type={ incompleteStep ? "error" : "success" } />
            </div>
        );
    }
}


Overview.propTypes = {
    userData: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    additional: PropTypes.object,
    stepsSubmission: PropTypes.object.isRequired
};

export default Overview;
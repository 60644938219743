import { FreeProdOverviewActions } from "./actions";

const DEFAULT_STATE = {
    isLoading: true,
    pageData: {
        overview: {
            isTableLoading: true,
            tableContent: []
        },
        detail: {
            categories: [],
            isTableLoading: false,
            tableContent: []
        },
        summary: {
            isTableLoading: false,
            tableContent: []
        },
        emails: {
            isTableLoading: false,
            tableContent: []
        }
    },
    notification: {}
};

const freeProdDashboard = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FreeProdOverviewActions.REQ_OVERVIEW_PAGEDATA:
            return {
                ...state,
                isLoading: false,
                pageData: {
                    ...state.pageData,
                    overview: {
                        ...state.pageData.overview,
                        isTableLoading: true
                    }
                }

            };
        case FreeProdOverviewActions.SUCCESS_OVERVIEW_PAGEDATA:
            return {
                ...state,
                isLoading: false,
                pageData: {
                    ...state.pageData,
                    overview: {
                        ...state.pageData.overview,
                        isTableLoading: false,
                        tableContent: action.data
                    }
                }

            };
        case FreeProdOverviewActions.ERROR_OVERVIEW_PAGEDATA:
            return {
                ...state,
                isLoading: false,
                pageData: {
                    ...state.pageData,
                    overview: {
                        ...state.pageData.overview,
                        isTableLoading: false,
                        tableContent: []
                    }
                }

            };
        case FreeProdOverviewActions.REQ_DETAIL_CATEGORIES:
            return {
                ...state,
                isLoading: true,
            };
        case FreeProdOverviewActions.SUCCESS_DETAIL_CATEGORIES:
            return {
                ...state,
                isLoading: false,
                pageData: {
                    ...state.pageData,
                    detail: {
                        ...state.pageData.detail,
                        categories: action.data,
                    }
                }
            };
        case FreeProdOverviewActions.ERROR_DETAIL_CATEGORIES:
            return {
                ...state,
                isLoading: false,
            };
        case FreeProdOverviewActions.REQ_DETAIL_TABLE_CONTENT:
            return {
                ...state,
                pageData: {
                    ...state.pageData,
                    detail: {
                        ...state.pageData.detail,
                        isTableLoading: true
                    }
                }
            };
        case FreeProdOverviewActions.SUCCESS_DETAIL_TABLE_CONTENT:
            return {
                ...state,
                pageData: {
                    ...state.pageData,
                    detail: {
                        ...state.pageData.detail,
                        isTableLoading: false,
                        tableContent: action.data,
                    }
                },
            };
        case FreeProdOverviewActions.ERROR_DETAIL_TABLE_CONTENT:
            return {
                ...state,
                pageData: {
                    ...state.pageData,
                    detail: {
                        ...state.pageData.detail,
                        isTableLoading: false,
                        tableContent: [],
                    }
                },
            };
        case FreeProdOverviewActions.REQ_SUMMARY_PAGEDATA:
            return {
                ...state,
                isLoading: false,
                pageData: {
                    ...state.pageData,
                    summary: {
                        ...state.pageData.summary,
                        isTableLoading: true,
                        tableContent: []
                    }
                }

            };
        case FreeProdOverviewActions.SUCCESS_SUMMARY_PAGEDATA:
            return {
                ...state,
                isLoading: false,
                pageData: {
                    ...state.pageData,
                    summary: {
                        ...state.pageData.summary,
                        isTableLoading: false,
                        tableContent: action.data
                    }
                }

            };
        case FreeProdOverviewActions.ERROR_SUMMARY_PAGEDATA:
            return {
                ...state,
                isLoading: false,
                pageData: {
                    ...state.pageData,
                    summary: {
                        ...state.pageData.summary,
                        isTableLoading: false,
                        tableContent: []
                    }
                }

            };
        case FreeProdOverviewActions.REQ_EMAILS_PAGEDATA:
            return {
                ...state,
                isLoading: false,
                pageData: {
                    ...state.pageData,
                    emails: {
                        ...state.pageData.emails,
                        isTableLoading: true,
                        tableContent: []
                    }
                }

            };
        case FreeProdOverviewActions.SUCCESS_EMAILS_PAGEDATA:
            return {
                ...state,
                isLoading: false,
                pageData: {
                    ...state.pageData,
                    emails: {
                        ...state.pageData.emails,
                        isTableLoading: false,
                        tableContent: action.data
                    }
                }

            };
        case FreeProdOverviewActions.ERROR_EMAILS_PAGEDATA:
            return {
                ...state,
                isLoading: false,
                pageData: {
                    ...state.pageData,
                    emails: {
                        ...state.pageData.emails,
                        isTableLoading: false,
                        tableContent: []
                    }
                }

            };
        default:
            return state;
    }
};

export default freeProdDashboard;
import queryString from "query-string";

import { validTypes, productLookup, stateLookup } from "../constants";
import { capitalize } from "../../../utils";

/** get initial state from url query params */
export const extractParamsFromUrl = (urlQuery) => {
  const param = queryString.parse(urlQuery);
  let filterObj = {},
    targetProfileId = "",
    targetProfileType = "";
  for (let key in param) {
    if (!param[key]) continue;
    if (key.indexOf("id") === -1) {
      let vals = param[key].split("|");
      switch (key) {
        case "type":
          vals = vals.filter((val) => Object.keys(validTypes).indexOf(val) !== -1).map((val) => validTypes[val]);
          break;
        case "state":
          vals = vals.map((val) => getStateVal(val)).filter((val) => !!val);
          break;
        case "city":
          if (!param.state || ~param.state.indexOf("|") || !getStateVal(param.state)) continue;
          vals = vals.map((val) => getCityVal(val));
          break;
        case "industry":
          vals = vals.map((val) => val.toLowerCase());
          break;
        default:
          continue; /* do not handle passed params other than type, state, city, industry */
      }
      if (vals.length) filterObj[key] = vals;
    } else {
      targetProfileId = param[key];
      targetProfileType = key.slice(0, -3);
    }
  }

  if (targetProfileType && targetProfileType !== "customer") {
    filterObj.healthScore = ["71-85", "86-100"]
  }

  return [filterObj, targetProfileId, targetProfileType];

  // helpers
  function getStateVal(urlStr) {
    urlStr = urlStr.toLowerCase();
    if (urlStr.length === 2) {
      let stateCode = urlStr;
      return stateLookup[stateCode] ? `${stateCode}_${stateLookup[stateCode]}` : "";
    }
    for (let stateCode in stateLookup) {
      if (stateLookup[stateCode] !== urlStr) continue;
      return `${stateCode}_${urlStr}`;
    }

    return "";
  }

  function getCityVal(urlStr) {
    return `${capitalize(urlStr)}, ${getStateVal(param.state)
      .split("_")[0]
      .toUpperCase()}`;
  }
};

/** get payload to fetch profile */
export const getProfileParams = (id, type) => {
  return {
    [`${type}Id`]: id,
    types: [type || "prospector"]
  };
};

/** get payload to fetch records */
export const getApiParamsFromFilterObj = (filterObj, { industryToSubs, cityList }) => {
  let filterParams = {};

  for (let key in filterObj) {
    if (filterObj.hasOwnProperty(key)) {
      switch (key) {
        case "type":
          filterParams[key] =
            filterObj[key].indexOf("all-selected") !== -1 ? Object.keys(validTypes) : filterObj[key].map((val) => val.split("_")[0]);
          break;
        case "state":
        case "city":
          if (!filterParams[key]) setStateCityParams(filterParams, filterObj.state, filterObj.city, cityList);
          break;
        case "industry":
        case "subindustry":
          if (!filterParams[key]) setIndustrySubindustryParams(filterParams, filterObj.industry, filterObj.subindustry, industryToSubs);
          break;
        case "owner":
          filterParams[key] = filterObj[key].map((val) => val.split("_")[1]);
          break;
        case "products":
          filterParams[key] = setProductParams(filterObj[key]);
          break;
        case "revenueRange":
          filterParams[key] = setRevenueParams(filterObj[key]);
          break;
        case "healthScore":
          filterParams[key] = setHealthScoreParams(filterObj[key]);
          break;
        default:
          filterParams[key] = filterObj[key];
      }
    }
  }

  return filterParams;

  // helpers
  function setStateCityParams(filterParams, states, cities, cityList) {
    let stateParams = [],
      cityParams = {};
    states = states && states.map((stateStr) => stateStr.split("_")[0]);

    if (!states || states.length === 0) {
      // if no state is selected, only the selected cities will be included in the results.
      cities.forEach((city) => {
        const [cityName, stateCode] = city.toLowerCase().split(", ");
        if (!cityParams[stateCode]) {
          stateParams.push(stateCode);
          cityParams[stateCode] = [cityName];
        } else {
          cityParams[stateCode].push(cityName);
        }
      });
    } else if (cities && cities.length) {
      // if both state and city are selected, city filter apply only within the state it belongs to, that is, states without a city being selected WILL BE included.
      let stateSet = new Set(states); // states whose cities NEED to be populated
      stateParams = states;
      states.forEach((stateCode) => (cityParams[stateCode] = []));
      cities.forEach((city) => {
        const [cityName, stateCode] = city.toLowerCase().split(", ");
        if (stateSet.has(stateCode)) stateSet.delete(stateCode);
        if (!cityParams[stateCode]) cityParams[stateCode] = [];
        cityParams[stateCode].push(cityName);
      });
      // We are skipping this auto-pouplate of cities
      // backfillCities(cityParams, stateSet, cityList);
    } else {
      // if only state is selected, NO NEED to populate all cities of the selected state
      stateParams = states;

      for (const state of states) {
        cityParams[state] = [];
      }
    }
    filterParams.state = stateParams;
    filterParams.city = cityParams;

    function backfillCities(cityParams, stateSet, cityList) {
      for (const { value } of cityList) {
        const [cityName, stateCode] = value.toLowerCase().split(", ");
        if (stateSet.has(stateCode)) cityParams[stateCode].push(cityName);
      }
    }
  }

  function setIndustrySubindustryParams(filterParams, industries, subindustries, industryToSubs) {
    if (!subindustries && industries.length) {
      // if no selection is passed from subindustry filter
      filterParams.industry = industries;
      let subindustry = {};

      for (const industry of industries) {
        subindustry[industry] = [];
      }
      
      filterParams.subindustry = subindustry;
    } else {
      // industry params will be rewritten according to the selections from subindustry filter
      filterParams.industry = [];
      filterParams.subindustry = {};
      subindustries.forEach((value) => {
        if (value.slice(0, 8) === "industry") {
          // this is an industry selected in the subindustry filter, all its value WILL BE INCLUDED.
          const [, industryName] = value.split("_");
          filterParams.industry.push(industryName);
          // We are skipping auto-populate of subIndustries
          // filterParams.subindustry[industryName] = industryToSubs[industryName].children.map((c) => c.key);
          filterParams.subindustry[industryName] = [];
        } else {
          // this is subindustry selected in the subindustry filter.
          const [salesforceId, industryName] = value.split("_");
          if (!filterParams.subindustry[industryName]) {
            filterParams.industry.push(industryName);
            filterParams.subindustry[industryName] = [salesforceId];
          } else {
            filterParams.subindustry[industryName].push(salesforceId);
          }
        }
      });
    }
  }

  // send all listed products with status true
  function setProductParams(products) {
    const productParams = [];

    for (const key in productLookup) {
      if (productLookup.hasOwnProperty(key) && products.indexOf(key) !== -1) {
        productParams.push(productLookup[key]);
      }
    }

    return productParams;
  }

  function setRevenueParams(revenues) {
    return revenues.map((revenue) => {
      switch (revenue) {
        case "1-100000":
          return { minRevenue: 1, maxRevenue: 100000 };
        case "100001-500000":
          return { minRevenue: 100001, maxRevenue: 500000 };
        case "500001-1000000":
          return { minRevenue: 500001, maxRevenue: 1000000 };
        case "1000001-10000000":
          return { minRevenue: 1000001, maxRevenue: 10000000 };
        case "10000001+":
          return { minRevenue: 10000001, maxRevenue: null };
        default:
          return { minRevenue: null, maxRevenue: null };
      }
    });
  }

  function setHealthScoreParams(scores) {
    return scores.map((score) => {
      switch(score) {
        case "0-70":
          return { minScore: 0, maxScore: 70 };
        case "71-85":
          return { minScore: 71, maxScore: 85 };
        case "86-100":
          return { minScore: 86, maxScore: 100 };
        default:
          return { minScore: null, maxScore: null };
      }
    });
  }
};

/** get formatted state data */
export const formatStateData = (states) => states.map(({ name, code }) => ({ code: code.toLowerCase(), name }));

/** get formatted industry data */
export const formatIndustryData = (industryData) => {
  let industryList = [];
  let industryToSubs = {};
  industryData.forEach(({ id, name, subCategory }) => {
    let industryName = name.toLowerCase();
    industryList.push({ id, name });
    industryToSubs[industryName] = {
      title: name,
      value: "industry_" + industryName,
      key: "industry_" + id,
      children: subCategory.map(({ salesforceId, name }) => ({
        title: name,
        value: `${salesforceId}_${industryName}_${name.toLowerCase()}`,
        key: salesforceId
      }))
    };
  });
  return { industryList, industryToSubs };
};

/** get formatted owner data */
export const formatOwnerData = (owners) => {
  return owners.map(({ id, name }) => ({ title: name, value: `${name}_${id}`.toLowerCase(), key: id }));
};

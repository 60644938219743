import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Alert, Spin, Modal, Select } from "antd";
import { isEmpty } from "lodash";

import { RedirectWrapper } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";
import { getSelectedCategoryLabel } from "./";
import {getIsPageActionAccessible} from "../../utils";
const FormItem = Form.Item;
const Option = Select.Option;

class IntegrationDomainForm extends Component {

    constructor(props) {
        super(props);
        this.pageKey = "integrationsList";
    }

    initialData = {
        id: 0,
        domain: "",
        category: ""
    }

    state = {
        data: this.initialData,
        formError: "",
        isEditPage: !!this.props.match.params.id
    };

    componentDidMount() {
        const { location, match, fetchIntegrationDomainRequest, categories, fetchBirdeyeCategoriesList } = this.props;
        if (location.state && location.state.data) {
            this.setState({ data: {...location.state.data} });
        } else if (match.params.id) {
            fetchIntegrationDomainRequest({ id: match.params.id });
        }
        if (isEmpty(categories)) {
            fetchBirdeyeCategoriesList();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.curData.id !== prevProps.curData.id) {
            this.setState({ data: {...this.props.curData} });
        }
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({ 
                isEditPage: !!this.props.match.params.id,
                data: this.initialData
            });
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.handleFieldChange(name, value);
    };

    handleFieldChange = (name, value) => {
        let data = { ...this.state.data };
        data[name] = value;
        this.setState({ data, formError: "" });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { data } = this.state;
        if (!data.domain) {
            this.setState({ formError: "Please fill in all relevant fields!" });
            return;
        }
        if (this.state.isEditPage) {
            this.props.updateIntegrationDomainRequest({ data, cb: this.renderFeedback });
        } else {
            delete data.id; 
            this.props.addIntegrationDomainRequest({ data, cb: this.renderFeedback });
        }
    };

    renderFeedback = (errorType) => {
        const goToIntegrationDomainsPage = () => this.props.history.push("/integration-domains");
        if (!errorType) {
            return Modal.success({
                title: `You have successfully ${
                    this.state.isEditPage ? "edited" : "added"
                } a record`,
                content: `Check the ${
                    this.state.isEditPage ? "updated" : "new"
                } record in domain list page`,
                okText: "Go to domains page",
                maskClosable: true,
                onOk() {
                    goToIntegrationDomainsPage();
                }
            });
        } else {
            return Modal.warning({
                title: `The record was not ${
                    this.state.isEditPage ? "edited" : "added"
                } successfully...`,
                content: "Please check your input or try again later..."
            });
        }
    };

    renderFormBlock = () => {
        const { domain, category } = this.state.data;
        const { categories = [] } = this.props;

        if (this.state.isEditPage && this.props.error === "integrationDomainNotFound") {
            return <Alert message="Domain not found" type="error" showIcon />;
        } else {
            return (
                <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label="Domain Name">
                        <Input
                            name="domain"
                            value={domain}
                            placeholder="Integration domain name"
                            onChange={this.handleInputChange}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Birdeye Category">
                        <Select
                            value={getSelectedCategoryLabel(categories, category) || []}
                            placeholder="Assoicated Birdeye category"
                            onChange={(value) =>
                                this.handleFieldChange("category", value)
                            }>
                            <Option key="All" value="All">All</Option>
                            {
                                categories && categories.map(d => <Option key={d.parentCategory.id} value={d.parentCategory.id}>{d.parentCategory.name}</Option>)
                            }
                        </Select>
                    </FormItem>
                    {this.state.formError && (
                        <FormItem {...formButtonLayout}>
                            <span className="text-error">
                                {this.state.formError}
                            </span>
                        </FormItem>
                    )}
                    <FormItem {...formButtonLayout}>
                        <Button type="primary" htmlType="submit">
                            {this.state.isEditPage ? "Edit" : "Add"}
                        </Button>
                    </FormItem>
                </Form>
            );
        }
    };

    render() {
        const { isLoading, isIntegrationLoading, error, userData } = this.props;
        const { name } = userData;

        const unauthorized = error === "unauthorized" || !name;
        const isActionAccessible = getIsPageActionAccessible(this.pageKey, userData.privileges);

        if (unauthorized || !isActionAccessible) {
            return <RedirectWrapper location={this.props.location}/>;
        }

        return (
            <div className="integration-domains-root">
                <h2>{this.state.isEditPage ? "Edit" : "Add New"} Integration Domain</h2>
                {isLoading || isIntegrationLoading ? (
                    <Spin />
                ) : (
                    this.renderFormBlock()
                )}
            </div>
        );
    }
}

const UpdateIntegrationDomain = Form.create()(IntegrationDomainForm);

UpdateIntegrationDomain.propTypes = {
    match: PropTypes.object.isRequired, 
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    curData: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isIntegrationLoading: PropTypes.bool,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    fetchIntegrationDomainRequest: PropTypes.func.isRequired,
    updateIntegrationDomainRequest: PropTypes.func.isRequired,
    addIntegrationDomainRequest: PropTypes.func.isRequired
};

export default UpdateIntegrationDomain;

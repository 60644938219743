import React, { Component } from "react";

const Find = () => (
    <div>
        If you have changed name, address or phone on the business Info tab then please click the “Next & Save” button.
    </div>
);

export default Find;


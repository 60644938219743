import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, Drawer, notification } from "antd";
import { TableSkeleton, PageHeaderContainer } from "../../components";
import ProductMessageImageList from "./ProductMessageImageList";
import { getIsPageActionAccessible } from "../../utils";
import lookup from "./lookup.json";

class ProductMessageList extends Component {
    constructor(props) {
        super(props);
        this.pageKey = "upsell";
        this.isReadonly = !getIsPageActionAccessible(this.pageKey, ((props.userData && props.userData.privileges) || []));
        this.state = {
            isImageDrawerOpen: false,
            imageDrawerMessaageId: -1,
            imageDrawerData: {} 
        };
    }

    componentDidMount() {
        this.props.fetchAllProductMessageRequest({ isCache: 0 });
    }

    handleOnEditClick = (e, data) => {
        e.preventDefault();
        this.props.history.push({
            pathname: "/upsell/product-message/manage",
            state: data,
        });
    }

    handleOnDeleteClick = (e, data) => {
        e.preventDefault();
        this.props.deleteProductMessageRequest({ id: data.id }, this.handleAfterSubmit); 
    }

    handleOnImageClick = (e, data) => {
        e.preventDefault();
        this.setState({ isImageDrawerOpen: true, imageDrawerMessaageId: data.id, imageDrawerData: data });
    }

    handleImageDrawerClose = (e) => {
        e.preventDefault();
        this.setState({ isImageDrawerOpen: false, imageDrawerMessaageId: -1, imageDrawerData: {} });
    }

    getImageDrawerData = () => {
        const { imageDrawerMessaageId } = this.state;
        if (imageDrawerMessaageId === -1) return {};
        return this.props.productMessageList.filter(({id}) => (id === imageDrawerMessaageId))[0];
    }

    handleOnIndustryImageSubmit = (payload) => {
        this.props.updateProductMessageIndustryImageRequest(payload, this.handleAfterSubmit);
    };

    handleAfterSubmit = (response) => {
        notification[response.status]({
            message: response.message,
            description: response.description,
            placement: "bottomRight",
            duration: 30
        });
    }

    mapToDataSource = (data) => {
        if (_.isEmpty(data)) return [];

        let lookupProduct = {};
        lookup.products.forEach((product) => {
            lookupProduct[product[0]] = product[1];
        });

        let lookupLayout = {};
        lookup.layouts.forEach((layout) => {
            lookupLayout[layout[0]] = layout[1];
        });

        return data.map((row) => {
            const productLabel = lookupProduct[row.product] ? lookupProduct[row.product] : "N/A";
            const layoutLabel = lookupLayout[row.layout] ? lookupLayout[row.layout] : "N/A";
            return {
                key: row.id,
                product: productLabel,
                layout: layoutLabel,
                action: (
                    <React.Fragment>
                        <Button type="link" icon="edit" onClick={(e) => this.handleOnEditClick(e, row)} />
                        <Button type="link" icon="picture" onClick={(e) => this.handleOnImageClick(e, { ...row, productLabel, layoutLabel })} disabled={row.layout == "info_container"} />
                        <Button type="link" icon="delete" onClick={(e) => this.handleOnDeleteClick(e, row)} />
                    </React.Fragment>
                )
            }
        });
    };

    render() {
        const { userData, isLoading, productMessageList } = this.props;
        const { isImageDrawerOpen } = this.state;
        const unauthorized = !userData.name;
        const imageDrawerData = { ...this.state.imageDrawerData, ...this.getImageDrawerData() };

        if (unauthorized) {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton loading={isLoading} columns={lookup.tableColumn} dataSource={this.mapToDataSource(productMessageList)} pageSize={10} showSizeChanger={false} />
                {
                    <Drawer visible={isImageDrawerOpen} destroyOnClose={true} onClose={this.handleImageDrawerClose} width="80%" title={<>{imageDrawerData.productLabel} <small>( {imageDrawerData.layoutLabel} )</small></>}>
                        <ProductMessageImageList isLoading={isLoading} data={imageDrawerData} handleOnIndustryImageSubmit={this.handleOnIndustryImageSubmit} />
                    </Drawer>
                }
            </div>
        )
    }
}

ProductMessageList.propTypes = {
    userData: PropTypes.object,
    fetchAllProductMessageRequest: PropTypes.func,
    deleteProductMessageRequest: PropTypes.func,
    updateProductMessageIndustryImageRequest: PropTypes.func,
    isLoading: PropTypes.bool,
    productMessageList: PropTypes.array
};

export default ProductMessageList;
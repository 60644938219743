import React from "react";
import PropTypes from "prop-types";
import ReactQuill, { Quill } from "react-quill";

import { isWebsiteLink } from "../../utils";

import "react-quill/dist/quill.snow.css";
import "./TextEditor.scss";

let Link = Quill.import("formats/link");

class BeLink extends Link {
    static create(value) {
        let node = super.create(value);
        value = this.sanitize(value);
        node.setAttribute("href", value);
        if (isWebsiteLink(value)) {
            node.removeAttribute("target");
        } else {
            node.setAttribute("rel", "nofollow noopener noreferrer");
        }
        return node;
    }
}

Quill.register(BeLink);

const basicModules = {
    toolbar: {
        container: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ size: ["small", false, "large", "huge"] }],
            ["image", "link"],
            ["clean"]
        ]
    }
};

const advancedModules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            [{ 'list': 'ordered'}, { 'list': 'bullet' }], // lists
            [{ 'script': 'sub'}, { 'script': 'super' }], // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }], // outdent/indent
            [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ 'header': [2, 3, 4, 5, 6, false] }], // headings     
            [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
            [{ 'align': [] }], // alignment options
            ["link"] //link
        ]          
    }
};

const TextEditor = ({ name, value, placeholder, handleEditorChange, advanced = false }) => {
    const modules = advanced ? advancedModules : basicModules;
    return (
        <ReactQuill
            modules={modules}
            name={name}
            value={value || ""}
            placeholder={placeholder}
            onChange={(html) => handleEditorChange(html, name)}
        />
    );
};

TextEditor.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    handleEditorChange: PropTypes.func.isRequired
};

export default TextEditor;

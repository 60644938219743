import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Select, Form, Spin, Modal, Steps, Icon } from "antd";
import { throttle } from "lodash";
import { RedirectWrapper, SearchInput } from "../../components";
import { formItemLayout, formButtonLayout } from "../../constants";
const FormItem = Form.Item;

const { Option } = Select;
const { Step } = Steps;

class RequestAccessForm extends Component {
    constructor(props) {
        super(props);
        this.handleSearch = throttle(this.handleSearch, 500);
        this.steps = [
            {
                title: "Select Business",
                content: () => this.renderSelectBusinessUI()
            },
            {
                title: "Select Role",
                content: () => this.renderSelectRoleUI()
            },
            {
                title: "Confirm",
                content: () => this.renderCompleteUI()
            }
        ];
    }

    state = {
        selectedBusiness: [],
        userRole: "owner",
        current: 0
    };

    handleSearch = (value) => {
        const { fetchBusinessList } = this.props;
        value && value.length >=3 && fetchBusinessList(value);
    };

    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };

    getOptionList = (businessList) => {
        const optionList = businessList.map(({businessId, businessName, businessNumber}) => ({ key: businessId, value: businessNumber, label: businessName }));
        return optionList;
    }

    renderFeedbackMsg = (error) => {
        if (!error) {
            const goToHistoryPage = () => this.props.history.push("/request-history/");
            return Modal.success({
                title: `Your request is successful.`,
                content:  "You should also see an email in your inbox regarding the same.",
                onOk() {
                    goToHistoryPage();
                }
            });
        } else {
            return Modal.warning({
                title: `Your request could not be processed`,
                content: error.message ? `Reason: ${error.message}` : `Please try again later`
            });
        }
    };

    renderSelectBusinessUI = () => {
        const { isLoading, businessList } = this.props;
        const { selectedBusiness } = this.state;
        return (
            <FormItem {...formItemLayout} label="Search Business" required extra="You can search for businesses by their name or their business ID and select one from the results">
                <SearchInput 
                    name="selectedBusiness"
                    placeholder="Enter business name or id to search"
                    optionList={this.getOptionList(businessList)}
                    currentValue={selectedBusiness}
                    handleSearch={this.handleSearch}
                    handleChange={this.handleChange}
                    notFoundContent={isLoading ? <Spin size="small" /> : "There were no results matching your search"}
                />
            </FormItem>
        );
    }

    renderSelectRoleUI = () => {
        const { userRole } = this.state;
        return (
            <FormItem {...formItemLayout} label="Select User Role" required>
                <Select defaultValue={userRole} onChange={(value) => this.handleChange("userRole", value)}>
                    <Option value="owner">Owner</Option>
                    <Option value="admin">Admin</Option>
                    <Option value="guest">Guest</Option>
                </Select>
            </FormItem>
        );
    }

    renderCompleteUI = () => {
        const { selectedBusiness, userRole } = this.state;
        const { businessList, isRequesting } = this.props;
        const business = businessList.find((business) => business.businessNumber === selectedBusiness);
        return (<div>
            <h3 style={{margin: "-20px 0 20px"}}>
                You are requesting access for business: <span style={{textTransform: "capitalize", color: "#1976d2"}}>{business.businessName} ({business.businessId})</span> with role: <span style={{textTransform: "capitalize", color: "#1976d2"}}>{userRole}</span>.
            </h3>
            <div>
                { isRequesting ? <Spin /> : <Button style={{ marginLeft: 8 }} type="primary" onClick={() => this.complete()}>
                        Request Access
                    </Button>
                } 
            </div>
        </div>);
    }

    prev = () => {
        const current = this.state.current - 1;
        this.setState({ current });
    }

    next = () => {
        const current = this.state.current + 1;
        this.setState({ current });
    }

    complete = () => {
        const { requestAccessToBusiness, userData } = this.props;
        const { selectedBusiness, userRole } = this.state;
        requestAccessToBusiness({
            userEmailId: userData.name,
            userRole,
            businessId: selectedBusiness,
            cb: this.renderFeedbackMsg
        });
    }

    render() {
        const { error, userData } = this.props;
        const { current, selectedBusiness } = this.state;
        const unauthorized = error === "unauthorized" || !userData.name;
        const isDisabled = current === 0 && !String(selectedBusiness).length;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div style={{"position": "absolute", "top": "30%", "left": "40%", "textAlign":"center", "width": "400px", "backgroundColor": "#e6f7ff", "borderRadius":"2rem"}}>
               <h2 style={{"font-size": "1.5rem"}}><Icon type="warning" theme="twoTone" /> Please use Birdeye Assist </h2>
               <p style={{"font-size": "1rem"}}><a href="https://assist.birdeye.com/">Click to visit Birdeye Assist</a></p>
            </div>
        );
    }
}

const RequestAccess = Form.create()(RequestAccessForm);

RequestAccessForm.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    businessList: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired
};

export default RequestAccess;
import { findIndex } from "lodash";
import { FeaturesActions } from "./actions";

let Features = {
    businessTypes: [],
    applicableModules: [],
    releaseTypes: [],
    data: [],
    curData: {},
    error: "",
    isLoading: false,
    newsletter: {
        pageData: {},
        preview: {
            visible: false,
            data: {}
        }
    }
};

const featuresReducer = (state = Features, action) => {
    let index;
    switch (action.type) {
        case FeaturesActions.FETCH_FEATURES_LIST_REQUEST:
        case FeaturesActions.FETCH_FEATURE_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case FeaturesActions.FETCH_BUSINESS_TYPE_REQUEST:
            return {
                ...state,
                businessTypes: action.payload,
                error: "",
                isLoading: true
            };
        case FeaturesActions.FETCH_BUSINESS_TYPE_SUCCESS:
            return {
                ...state,
                businessTypes: action.payload,
                error: "",
                isLoading: false
            };
        case FeaturesActions.FETCH_APPLICABLE_MODULE_REQUEST:
            return {
                ...state,
                applicableModules: action.payload,
                error: "",
                isLoading: true
            };
        case FeaturesActions.FETCH_APPLICABLE_MODULE_SUCCESS:
            return {
                ...state,
                applicableModules: action.payload,
                error: "",
                isLoading: false
            };
        case FeaturesActions.FETCH_RELEASE_TYPE_REQUEST:
            return {
                ...state,
                releaseTypes: action.payload,
                error: "",
                isLoading: true
            };
        case FeaturesActions.FETCH_RELEASE_TYPE_SUCCESS:
            return {
                ...state,
                releaseTypes: action.payload,
                error: "",
                isLoading: false
            };
        case FeaturesActions.FETCH_FEATURES_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                curData: {}
            };
        case FeaturesActions.FETCH_FEATURE_SUCCESS:
            return {
                ...state,
                curData: action.payload,
                isLoading: false
            };
        case FeaturesActions.ADD_FEATURE_REQUEST:
            return {
                ...state,
                curData: action.payload,
                isLoading: false
            };
        case FeaturesActions.UPDATE_FEATURE_REQUEST:
            return {
                ...state,
                curData: action.payload,
                isLoading: false
            };
        case FeaturesActions.DELETE_FEATURE_SUCCESS:
            index = findIndex(state.data, ["releaseId", action.payload.releaseId]);
            return {
                ...state,
                data: [
                    ...state.data.slice(0, index),
                    ...state.data.slice(index + 1)
                ],
                error: ""
            };
        case FeaturesActions.FEATURE_ERROR:
            return {
                ...state,
                data: [],
                curData: {},
                error: action.payload.message|| action.payload.error,
                isLoading: false
            };
        case FeaturesActions.NEWSLETTER_LISTING:
            return {
                ...state,
                isLoading: true,
            };
        case FeaturesActions.NEWSLETTER_LISTING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                newsletter: {
                    ...state.newsletter,
                    pageData: action.payload,
                }
            };
        case FeaturesActions.NEWSLETTER_LISTING_ERROR:
            return {
                ...state,
                isLoading: false,
                newsletter: {
                    ...state.newsletter,
                    pageData: action.payload,
                }
            };
        case FeaturesActions.NEWSLETTER_PREVIEW_OPEN:
            return {
                ...state,
                newsletter: {
                    ...state.newsletter,
                    preview: {
                        visible: true,
                        data: action.payload
                    }
                }
            };
        case FeaturesActions.NEWSLETTER_PREVIEW_CLOSE:
            return {
                ...state,
                newsletter: {
                    ...state.newsletter,
                    preview: {
                        visible: false,
                        data: {}
                    }
                }
            };
        default:
            return state;
    }
};

export default featuresReducer;

import React, { Component } from 'react';
import SelfServePanel from './SelfServePanel';
import { RedirectWrapper, PageHeaderContainer } from "../../components";
import { Tabs } from 'antd';
const { TabPane } = Tabs;

class SelfServeTab extends  Component {
    constructor(props) {
        super(props);
        this.pageKey = "integrationSelfServeList"
    }

    componentDidMount (){
        this.props.reqBusinessDetails();
    }

    render() {
        const { userData, errorMessage, businessDetails } = this.props;
        const unauthorized = errorMessage === "unauthorized" || !userData.name;
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location}/>;
        }

        const businessDetailsForPending = businessDetails.filter((ele) => {
            const requestSent = ele.caseStatus ? ele.caseStatus.toLowerCase() : '';
            return requestSent === 'request sent';
        });
        const businessDetailsForDone = businessDetails.filter((ele) => { 
            const requestSent = ele.caseStatus ? ele.caseStatus.toLowerCase() : '';
            return requestSent !== 'request sent';
        });

        return (
            <div>
                <PageHeaderContainer pageKey={this.pageKey} title="Self Serve Panel"/>
                <Tabs style={{width: "73rem"}}>
                    <TabPane tab="Pending" key="Pending">
                        <SelfServePanel style={{width: "100"}} tabName = "Pending" businessDetailsData = {businessDetailsForPending} data = {this.props} /> 
                    </TabPane>
                    <TabPane tab="Done" key="Done">
                        <SelfServePanel tabName = "Done" businessDetailsData = {businessDetailsForDone}  data = {this.props} /> 
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

export default SelfServeTab;
import { beAPIResource } from "../../utils/apiHelper";

export const leadgenManagementAPI = {
    sendSubscription: (payload) => beAPIResource.post("/support/leadgen-management/subscribe", payload),
    sendUnsubscription: (payload) => beAPIResource.post("/support/leadgen-management/unsubscribe", payload),
    reqContactDetails: (payload) => beAPIResource.get("/support/leadgen-management/unsubscribe", payload),
    sendSfdcLeadForm: (payload) => beAPIResource.post("/support/leadgen-management/sfdc-lead", payload),
    sendSfdcLeadFile: (payload) => beAPIResource.post("/support/leadgen-management/sfdc-leads", payload),
    reqPageDataCampaignUrlBuilder: () => beAPIResource.get("/support/leadgen-management/campaign-url-builder"),
    logCampaignUrlBuilderActivity: (payload) => beAPIResource.post("/support/leadgen-management/log-campaign-url-builder-activity", payload),
    reqCampaignUrlBuilderArchive: (payload) => beAPIResource.get("/support/leadgen-management/campaign-url-builder-archive", payload),
};

export default leadgenManagementAPI;

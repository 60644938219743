import { takeLatest, call, put } from "redux-saga/effects";
import TwilioAction, * as actionCreator from "./action";
import twilioAPI from "./http";

const twilioSaga = [
    takeLatest(TwilioAction.PHONE_NUMBER_UPLOAD_REQUEST, phoneNumberUploadRequest),
];

function* phoneNumberUploadRequest(action) {
    try {
        const resp = yield call(twilioAPI.phoneNumberUpload, action.payload.data);
        yield put(actionCreator.phoneNumberUploadSuccess({msg: ""}));
        yield call(action.payload.cb, resp);
    } catch (e) {
        yield put(actionCreator.phoneNumberUploadError({msg: ""}));
        yield call(action.payload.cb, {type: "error", message: "Something went wrong. Please retry!"});
    }
}

export default twilioSaga;
import React, {Component} from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Select, DatePicker, TimePicker, Switch, Icon, Radio } from "antd";
import { formItemLayout, formButtonLayout } from "../../constants";
import moment from "moment-timezone";
import lookup from "./constants";

const { Item: FormItem } = Form;
const dateFormat = "dddd, MMMM DD, YYYY", timeFormat = "HH:mm:ss";

class WebinarForm extends Component {

    state = {
        descriptionLength: 0,
        countryValue: 'us',
        countryTimezones: lookup.webinar.timezoneOptions['us'],
        eventTimezone: "America/Los_Angeles"
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const description = this.props.form.getFieldValue('eventDescription');
        const country = this.props.form.getFieldValue('country');
        const eventTimezone = this.props.form.getFieldValue('eventTimezone');
        if(description) {
            this.setState({descriptionLength : description.length});
        }
        if(country) {
            this.setState({countryValue: country, countryTimezones: lookup.webinar.timezoneOptions[country] || []});
        }

        if(eventTimezone) {
            this.setState({eventTimezone});
        }
    }

    getConvertedDate = (date, currentTimezone, newTimezone) => {
        return moment.tz(date, currentTimezone).tz(newTimezone)
    }

    handleRecordingAssetsFilter = (input, opt) => {
        const lowerInput = input.toLowerCase();
        const lowerLabel = opt.props.children.toLowerCase();

        return lowerLabel.indexOf(lowerInput) !== -1
    }

    handleFormSubmission = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values) => {
            const descLength = this.state.descriptionLength;
            if (error) {
                return;
            } else if(descLength > 175) {
                return;
            }

            const { presenters=[], upcomingSession = [] } = this.props.additional;

            const 
                { startDate, startTime, endTime, eventTimezone, eventDuration, eventPresenter, hostPlatform } = values,
                startDateString = startDate.format("YYYY-MM-DD"),
                startTimeString = startTime.format("HH:mm:ss"),
                startTimestampUTC = `${startDateString} ${startTimeString}`,
                startTimeStringInUTC = this.getConvertedDate(startTimestampUTC, eventTimezone, "GMT").format("YYYY-MM-DD[T]HH:mm:ss[Z]");

            let endTimestampUTC = null;
            if (eventDuration === "custom") {
                const endTimeString = endTime.format("HH:mm:ss");
                endTimestampUTC = `${startDateString} ${endTimeString}`
            } else {
                endTimestampUTC = moment.utc(startTimestampUTC).add(eventDuration, "minutes").format("YYYY-MM-DD HH:mm:ss");
            }
            const endTimeStringInUTC = this.getConvertedDate(endTimestampUTC, eventTimezone, "GMT").format("YYYY-MM-DD[T]HH:mm:ss[Z]");

            const mStartTime = moment.utc(startTimeStringInUTC);
            const mEndTime = moment.utc(endTimeStringInUTC);
            for (const session of upcomingSession) {
                const
                    mSessionStartDate = moment.utc(session.startDate),
                    mSessionEndDate = moment.utc(session.endDate);
                
                if (hostPlatform === 'on24' && ((mSessionStartDate.diff(mStartTime) <= 0 && mSessionEndDate.diff(mStartTime) >= 0)
                    || (mSessionStartDate.diff(mEndTime) <= 0 && mSessionEndDate.diff(mEndTime) >= 0))) {
                        const message = `A session is already scheduled on this date from ${this.getConvertedDate(session.startDate, "UTC", eventTimezone).format("HH:mm")} to ${this.getConvertedDate(session.endDate, "UTC", eventTimezone).format("HH:mm")}`
                        this.props.form.setFields({ startDate: { value: startDate, errors: [{ message }] } });
                        return;
                    }
            }

                        
            values.times = [{
                startTime: startTimeStringInUTC,
                endTime: endTimeStringInUTC
            }];
            values.eventPresenter = presenters.filter(({email}) => (eventPresenter.indexOf(email) !== -1)).map(({email, name, photo, designation}) => ({email, name, photo, designation}));
            values.startDate = startTimeStringInUTC;
            values.endDate = endTimeStringInUTC;

            delete(values.startTime);
            delete(values.eventDuration);

            if (values.audienceUrl) {
                const components = values.audienceUrl.split("/")
                values.webinarKey = components[components.length - 2];
                values.audienceKey = components[components.length - 1];
            }
            this.props.handleStepFormSubmission({ webinar: {...values} });
        })
    }

    onDescriptionChange = (e) => {
        this.setState({descriptionLength: e.target.value.length});
    }

    handlePresenterSearch = (input, option) => {
        if (!input || !input.trim().length) return false;

        const 
            { name, value } = option.props,
            input2Lower = input.trim().toLowerCase(),
            name2Lower = name.toLowerCase(),
            value2Lower = value.toLowerCase();

        return name2Lower.includes(input2Lower) || value2Lower.includes(input2Lower);
    }

    filterTimezonesByCountry = () => {
        const filteredTimezones = lookup.webinar.timezoneOptions.filter(timezone => timezone.label.includes(this.state.countryValue));
    
        return filteredTimezones || lookup.webinar.timezoneOptions;
    }

    handleCountryChange = (countryValue) => {
        this.setState({
            countryValue,
            countryTimezones: lookup.webinar.timezoneOptions[countryValue],
            eventTimezone: ""
        });
    }

    render() {
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { eventTitle, eventDescription, eventTimezone = "America/Los_Angeles", startDate, times = [{startTime: null, endTime: null}], eventPresenter, recordingAssetKey, eventExperience: initialEventExperience="fav", isOnDemand=false, webinarKey, audienceKey, audienceUrl, hostPlatform: initialHostPlatform = lookup.webinar.hostPlatforms[0].value, gcFormId: initialGcFormId, gcEventId: initialGcEventId, country='us' } = this.props.initialValues.webinar || {};
        const { presenters=[], recordingAssets=[], isLoadingRecordingAssets, isWebinarUpdate, isLoadingUpcomingSession } = this.props.additional;
        const startDateObj = startDate ? this.getConvertedDate(startDate, "GMT", eventTimezone) : moment().add("days", 1);
        const 
            [{startTime, endTime},] = times,
            startTimeObj = startTime ? this.getConvertedDate(startTime, "GMT", eventTimezone) : moment.utc("00:00:00", timeFormat),
            endTimeObj = endTime ? this.getConvertedDate(endTime, "GMT", eventTimezone) : moment.utc("00:00:00", timeFormat).add(1, "hours"),
            timeDifference = endTimeObj.diff(startTimeObj, "minutes"),
            initialEventDuration = lookup.webinar.intervals.map(({value}) => (value)).indexOf(timeDifference) === -1 ? "custom" :  timeDifference;

        const { eventDuration=initialEventDuration, eventExperience=initialEventExperience, hostPlatform=initialHostPlatform, gcFormId=initialGcFormId, gcEventId=initialGcEventId } = getFieldsValue();
        const { descriptionLength, countryTimezones } = this.state;

        return (
            <>
                <Form onSubmit={this.handleFormSubmission}>
                    <FormItem {...formItemLayout} label={"Event Title"}>
                        {
                            getFieldDecorator("eventTitle", {
                                rules: [{   
                                    "required": true,
                                    "message": "Provide a title for this event"
                                }],
                                initialValue: eventTitle
                            })(
                                <Input name="eventTitle" placeholder={"Event Title"} onChange={this.handleInputChange} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Event Description"} extra={<div style={{'textAlign': 'right'}}> {descriptionLength > 175 ? <span style={{color: "red"}} >{descriptionLength-175} characters overloaded (Limit 175)</span> : <span>{175-descriptionLength} characters left.</span> }</div>}>
                        {
                            getFieldDecorator("eventDescription", {
                                rules: [{
                                    "required": true,
                                    "message": "Provide some description which will be helpful to elaborate this webinar event"
                                }],
                                initialValue: eventDescription
                            })(
                                <Input.TextArea name="eventDescription" placeholder={"Event description"} autoSize={{minRows: 2, maxRows: 8}} maxLength={175} onChange={this.onDescriptionChange} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Host Platform"}>
                        {
                            getFieldDecorator("hostPlatform", {
                                rules: [{
                                    "required": true,
                                    "message": "Select a platform for this event"
                                }],
                                initialValue: hostPlatform
                            })(
                                <Select disabled={isWebinarUpdate}>
                                    {
                                        lookup.webinar.hostPlatforms.map((timezone, index) => (
                                            <Select.Option key={index} value={timezone.value}>{timezone.label}</Select.Option>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                    {hostPlatform !== 'goldcast' && <FormItem {...formItemLayout} label={"Event Type"}>
                        {
                            getFieldDecorator("eventExperience", {
                                rules: [{   
                                    "required": true,
                                    "message": "Provide a title for this event"
                                }],
                                initialValue: initialEventExperience
                            })(
                                <Radio.Group buttonStyle={"solid"} style={{width: "100%"}} onChange={this.handleEventExperienceChange} disabled={isWebinarUpdate}>
                                    <Radio.Button value="fav" style={{width: "33%", textAlign: "center"}}>Live<Icon type="audio" style={{marginLeft: "3%"}} /></Radio.Button>
                                    <Radio.Button value="simulive" style={{width: "33%", textAlign: "center"}}>Simu-live<Icon type="youtube" style={{marginLeft: "3%"}} /></Radio.Button>
                                    <Radio.Button value="recurring" style={{width: "33%", textAlign: "center"}}>Recurring<Icon type="retweet" style={{marginLeft: "3%"}} /></Radio.Button>
                                </Radio.Group>
                            )
                        }
                    </FormItem>}
                    {hostPlatform === 'goldcast' && (
                        <>
                            <FormItem {...formItemLayout} label={"Form ID"}>
                                {
                                    getFieldDecorator("gcFormId", {
                                        rules: [{   
                                            "required": true,
                                            "message": "Provide Form ID of this event"
                                        }],
                                        initialValue: gcFormId
                                    })(
                                        <Input name="gcFormId" />
                                    )
                                }
                            </FormItem>
                            <FormItem {...formItemLayout} label={"Event ID"}>
                                {
                                    getFieldDecorator("gcEventId", {
                                        rules: [{   
                                            "required": true,
                                            "message": "Provide Event ID of this event"
                                        }],
                                        initialValue: gcEventId
                                    })(
                                        <Input name="gcEventId" />
                                    )
                                }
                            </FormItem>
                        </>
                    )}
                    {/* {
                        eventExperience === "simulive" && (
                            <>
                            <FormItem {...formItemLayout} label={"Event Recording"} extra={isWebinarUpdate ? <small>If you are modifying this recording, then make sure that you have also updated the new recording on <a href={ webinarKey ? `https://dashboard.gotowebinar.com/webinar/${webinarKey}` : "https://dashboard.gotowebinar.com"} target="_blank">G2W Dashboard</a>.</small> : ""}>
                                {
                                    getFieldDecorator("recordingAssetKey", {
                                        rules: [{   
                                            "required": true,
                                            "message": "Provide a title for this event"
                                        }],
                                        initialValue: recordingAssetKey
                                    })(
                                        <Select
                                            placeholder={"Select a recording"}
                                            showSearch
                                            showArrow={false}
                                            onSearch={this.handleRecordingAssestSearch}
                                            loading={isLoadingRecordingAssets}
                                            filterOption={this.handleRecordingAssetsFilter}
                                        >
                                            {
                                                recordingAssets && recordingAssets.map((a, i) => {
                                                    return (
                                                    <Select.Option key={i} value={a.recordingAssetKey}>{`${moment.utc(a.createTime).format("YYYY, MMM DD")} ||     ${a.name}`}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                            <FormItem {...formItemLayout} label={"On Demand"} style={{display: "none"}}>
                                {
                                    getFieldDecorator("isOnDemand", {
                                        rules: [{
                                            "required": false,
                                        }],
                                        valuePropName: "checked",
                                        initialValue: isOnDemand
                                    })(
                                        <Switch checkedChildren={"Yes"} unCheckedChildren={"No"} style={{width: "60px"}} disabled={isWebinarUpdate}/>
                                    )
                                }
                            </FormItem>
                            </>
                        )
                    } */}
                    { (eventExperience === "recurring" || (isWebinarUpdate && eventExperience === "simulive")) && (
                        <>
                            <FormItem {...formItemLayout} label={"Audience URL"} extra={<>Audience URL is present in the 'Event URLs' on On24</>} >
                                {
                                    getFieldDecorator("audienceUrl", {
                                        rules: [{   
                                            "required": true,
                                            "message": "Provide the Audience URL"
                                        }],
                                        initialValue: isWebinarUpdate ?  ("https://event.on24.com/wcc/r/" + webinarKey + "/" + audienceKey) : (audienceUrl || "")
                                    })(
                                        <Input name="audienceUrl" placeholder={"Audience URL"} onChange={this.handleInputChange} disabled={isWebinarUpdate && eventExperience === "recurring"}/>
                                    )
                                }
                            </FormItem>
                        </>
                    )}
                    <FormItem {...formItemLayout} label="Country" required={true}>
                        {
                            getFieldDecorator("country", {
                                rules: [{
                                    "required": true,
                                    "message": "Select a Country according to which this event is supposed to host"
                                }],
                                initialValue: country
                            })(
                                <Select
                                    mode="single"
                                    placeholder="Select country"
                                    required={true}
                                    onChange={this.handleCountryChange}>
                                    {lookup.webinar.countries.map((country) => (
                                        <Option
                                            key={country.value}
                                            value={country.value}>
                                            {country.label}
                                        </Option>
                                    ))}
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Timezone"}>
                        {
                            getFieldDecorator("eventTimezone", {
                                rules: [{
                                    "required": true,
                                    "message": "Select a timezone according to which this event is supposed to host"
                                }],
                                initialValue: this.state.eventTimezone
                            })(
                                <Select showSearch placeholder={"Select a suitable timezone"}>
                                    {
                                        countryTimezones.map((timezone, index) => (
                                            <Select.Option key={`timezone-${index}`} value={timezone.value}>{timezone.label}</Select.Option>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Start Date"}>
                        {
                            this.props.form.getFieldDecorator("startDate", {
                                rules: [{
                                    "required": true,
                                    "message": "Select a start date for this event"
                                }],
                                initialValue: startDateObj
                            })(
                                <DatePicker placeholder={"Choose a start date"} className={"startDate"} format={dateFormat} disabledDate={(current) => (current && current < moment().endOf('day'))}  onChange={this.handleStartDateChange} loading={isLoadingUpcomingSession}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Start Time"}>
                        {
                            this.props.form.getFieldDecorator("startTime", {
                                rules: [{
                                    "required": true,
                                    "message": "Select start time for this event"
                                }, {
                                    validator: (rule, startTime, callback) => {
                                        const {endTime, eventDuration} = this.props.form.getFieldsValue(["endTime", "eventDuration"]);

                                        if (!endTime || !startTime) {
                                            return callback();
                                        }

                                        if(eventDuration === "custom") {
                                            this.props.form.setFields({
                                                startTime: {value: startTime, errors: null},
                                                endTime: {value: endTime, errors: null},
                                            })
        
                                            if (endTime.diff(startTime, "minutes") <= 0) {
                                                return callback(new Error("Start time should less than end time"));
                                            }
                                        }

                                        callback();
                                    }
                                }],
                                initialValue: startTimeObj
                            })(
                                <TimePicker style={{width: "30%"}} placeholder={"HH:MM:SS"} className={"startTime"} loading={isLoadingUpcomingSession}/>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Duration"}>
                        {
                            this.props.form.getFieldDecorator("eventDuration", {
                                rules: [{
                                    "required": true,
                                    "message": "Select duration of this event"
                                }],
                                initialValue: initialEventDuration
                            })(
                                <Select className={"eventDuration"} placeholder="Event duration" loading={isLoadingUpcomingSession} disabled={isWebinarUpdate && eventExperience==='simulive'}>
                                    {
                                        lookup.webinar.intervals.map(({label, value}, index) => (
                                                <Select.Option key={index} value={value}>{label}</Select.Option>
                                            )
                                        )
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                    {
                        (eventDuration === "custom") && (
                            <FormItem {...formItemLayout} label={"End Time"}>
                                {
                                    this.props.form.getFieldDecorator("endTime", {
                                        rules: [{
                                            "required": true,
                                            "message": "Select end time for this event"
                                        }, {
                                            validator: (rule, endTime, callback) => {
                                                const {startTime} = this.props.form.getFieldsValue(['startTime']);

                                                if (!endTime || !startTime) {
                                                    return callback();
                                                }

                                                this.props.form.setFields({
                                                    startTime: {value: startTime, errors: null},
                                                    endTime: {value: endTime, errors: null},
                                                })
            
                                                if (endTime.diff(startTime, "minutes") <= 0) {
                                                    return callback(new Error("End time should greater than start time"));
                                                }

                                                callback();
                                            },
                                            "message": "End time should greater than start time"
                                        }],
                                        initialValue: endTimeObj
                                    })(
                                        <TimePicker style={{width: "30%"}} placeholder={"HH:MM:SS"} className={"endTime"}  loading={isLoadingUpcomingSession}/>
                                    )
                                }
                            </FormItem>
                        )
                    }
                    <FormItem {...formItemLayout} label={"Presenter"}>
                        {
                            getFieldDecorator("eventPresenter", {
                                rules: [{
                                    "required": true,
                                    "message": "Select event presenter(s)"
                                }], 
                                initialValue: eventPresenter && eventPresenter.map(({email}) => (email))
                            })(
                                <Select showSearch mode="multiple" placeholder={"Select event presenter(s)"} filterOption={this.handlePresenterSearch}>
                                    {
                                        presenters && presenters.map(({email, name, designation, photo}, index) => (
                                            <Select.Option key={`presenter-${index}`} name={name} value={email}><span><img style={{height: "15px", width: "15px"}} src={photo}/> <b>{name}</b>, <small>{designation}</small></span></Select.Option>
                                        ))  
                                    }
                                </Select>
                            )
                        }
                        <Button block type="dashed" onClick={this.props.additional.reqWebinarPresenterModalOpen}>Click for Presenter related operations</Button>
                    </FormItem>
                    <FormItem {...formButtonLayout}>
                        <Button type={"primary"} htmlType={"submit"}>
                            Next<Icon type="right" />
                        </Button>
                    </FormItem>
                </Form>
            </>
        );
    }
}

const Webinar = Form.create()(WebinarForm);

Webinar.propTypes = {
    userData: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    additional: PropTypes.object,
    handleStepFormSubmission: PropTypes.func.isRequired,
};

export default Webinar;
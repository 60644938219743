import React, { Component } from "react";
import PropTypes from "prop-types";
import { RedirectWrapper, PageHeaderContainer } from "../../components";
import { getIsPageActionAccessible } from "../../utils";
import { beAPIResource } from "../../utils/apiHelper";
import { Button, Form, Input, Modal, Select } from "antd";
const FormItem = Form.Item;
const InputGroup = Input.Group;

const DOMAINS = [
    { label: 'birdeye.com', value: '' },
    { label: 'getbirdeye.com.au', value: '/au' },
];

export const formItemLayout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 4 }
    },
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 18 }
    }
};

export const formButtonLayout = {
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 18, offset: 4 }
    }
};

class AddWebsiteRedirect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            source: '',
            target: '',
            status: 301,
            country: ''
        };
        this.pageKey = "addWebsiteRedirect";
        this.isReadonly = !getIsPageActionAccessible(this.pageKey, props.userData.privileges);
    }

    async componentDidMount() {
        const id = this.props.match.params.pageId;
        if (id) {
            try {
                const response = await beAPIResource.get(`support/website/content/redirection/${id}`);
                let pageData = response.data; 
                if (pageData.source.includes('/au/')) {
                    pageData.country = '/au';
                    pageData.source = pageData.source.replace('/au', '');
                    }
                else {
                    pageData.country = '';
                }
                this.setState({ ...pageData });
            } catch (error) {
                
            }
        }
    }
    ensureSlashes = (str) => {
        if (!str.startsWith('/') && !str.includes('http')) {
          str = '/' + str;
        }
        if (!str.endsWith('/')) {
          str = str + '/';
        }
        return str;
      }

    handleSubmit = async() => {
        const { history } = this.props;
        const { id, source, target, status, country } = this.state;
        try {
            if (!source || !status) throw {data:{data:{message: 'Please enter missing field(s)'}}};
            if (status.toString()[0] === '3' && !target)  throw {data:{data: {message: 'Please enter a target path for redirection'}}};
            let payload = { 
                id, 
                source: country + this.ensureSlashes(source), 
                target: (target && status.toString()[0] === '3') ? this.ensureSlashes(target) : '', 
                status 
            };
            await beAPIResource.post(`support/website/content/addRedirect`, payload);
            return Modal.success({
                title: `Operation successful`,
                maskClosable: true,
                okText: "Go to redirects list",
                onOk() {
                    history.push({
                        pathname: `/website-redirects`
                    });
                }
            });
        } catch (error) {
            console.error(error);
            return Modal.error({
                title: error.data && error.data.data ? error.data.data.message : `Something went wrong.`,
                okText: "Close"
            });
        }
    }

    render() {
        const { error, userData } = this.props;
        const { source, target, status, country } = this.state;
        const unauthorized = error === "unauthorized" || !userData.name;

        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }

        return (
            <div className="resources-root">
                <PageHeaderContainer pageKey={this.pageKey} />
                <FormItem {...formItemLayout} label={"Source path"} required extra={<p>Enter only the path, ex - /inbox/</p>}>
                    <InputGroup compact style={{display: 'flex'}}>
                        <Select onChange={(e) =>  this.setState({ country: e } )} value={country} style={{minWidth: '160px'}}>
                            {
                                DOMAINS.map((r) => <Select.Option key={r.value} value={r.value}>{r.label}</Select.Option>)
                            }
                        </Select>
                        <Input onChange={(e) => this.setState({ source: e.target.value } )} value={source} />
                    </InputGroup>
                </FormItem>
                <FormItem {...formItemLayout} label={"Target path"} required={status.toString()[0] === '3'} extra={<p>Enter the path, ex - /messaging/ OR complete URL, ex - https://birdeye.com/blog/review-generation/</p>}>
                    <InputGroup compact style={{display: 'flex'}}>
                        {(!target || !target.includes('http')) && <Select onChange={(e) =>  this.setState({ country: e } )} value={country} disabled={status.toString()[0] === '4'} style={{minWidth: '160px'}}>
                            {
                                DOMAINS.map((r) => <Select.Option key={r.value} value={r.value}>{r.label}</Select.Option>)
                            }
                        </Select>}
                        <Input onChange={(e) => this.setState({ target: e.target.value } )} disabled={status.toString()[0] === '4'} value={ status.toString()[0] === '4' ? '' : target} />
                    </InputGroup>
                </FormItem>
                <FormItem {...formItemLayout} required label={"Status code"} >
                    <Input type="number" onChange={(e) => this.setState({ status: e.target.value } )} value={status} />
                </FormItem>
                <FormItem {...formButtonLayout}>
                    <Button type="primary" htmlType="submit" block onClick={this.handleSubmit}>
                        Save
                    </Button>
                </FormItem>
            </div>
        );
    }
}

AddWebsiteRedirect.propTypes = {
    userData: PropTypes.object
};

export default AddWebsiteRedirect;
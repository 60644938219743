import React, { Component } from 'react'
import { Form, Button, Modal, Popconfirm, Icon } from "antd";

import { RedirectWrapper } from "../../components";
import { PageHeaderContainer, TableSkeleton } from "../../components";
import { getIsPageActionAccessible } from "../../utils";
import { isEmpty } from "lodash";
import "./FeaturedPageManagementForm.scss";
import lookup from './lookup.json';

class FeaturedPageManagementForm extends Component {
    constructor(props) {
        super();
        this.pageKey = "websitePages";
        this.isReadonly = !getIsPageActionAccessible(this.pageKey, ((props.userData && props.userData.privileges) || []));

        this.state = {
            featurePagesList: props.featurePagesList
        };
    }
    
    componentDidMount() {
        if(!this.props.featurePagesList.length) {
            this.props.reqFeaturePagesList()
        }
    }

    handleEditOnClick = (e, data) => {
        e.preventDefault();
        this.props.history.push({
            pathname: `/synced-website-pages/${data.id}`
        })
    }

    handleRemoveOnClick = (e, data) => {
        e.preventDefault();
        this.props.reqDeleteFeaturePage({data : { id: data.id }, cb: this.renderCallback});
    };
    
    formatData = (response) => {
        return !isEmpty(response) ? response.filter(record => !['guide', 'webinar', 'podcast', 'video', 'case-study'].includes(record.type)).map((record, index) => {
            return {
                key: index,
                id: record.id,
                pageType: record.type,
                pageName: record.name,
                pageSlug: record.slug,
                action: (
                    <React.Fragment>
                        <Button size="small" icon="edit" type="link" onClick={(e) => {this.handleEditOnClick(e, record)}} />
                        <Popconfirm title="Do you want to remove this?" icon={<Icon type="delete"/>} okText="Yes" cancelText="No" placement="left" onConfirm={(e) => {this.handleRemoveOnClick(e, record)}}>
                            <Button size="small" icon="delete" type="link" />
                        </Popconfirm>
                    </React.Fragment>
                )
            };
        }) : [];
    };
    
    handleDelete = (record) => {
        this.props.reqDeleteFeaturePage({data : { id: record.id }, cb: this.renderCallback});
    }
    
    renderCallback = () => {
        const { titleText, content } = this.props.deleteResponse; 
        const refreshList = () => this.props.reqFeaturePagesList();
        if(titleText === 'Success') {
            return Modal.success({
                title: titleText,
                content: content,
                okText: `Ok & Refresh`,
                onOk() { refreshList() }
            });  
        } else {
            return Modal.error({
                title: titleText,
                content: content,
                okText: `Ok`
            });
        }
    }
    
    render() {
        
        const { featurePagesList, error, userData, isLoading } = this.props;
        const unauthorized = error === "unauthorized" || !userData.name;
        
        if (unauthorized) {
            return <RedirectWrapper location={this.props.location} />;
        }
        
        return (
            <div>
                <PageHeaderContainer pageKey={this.pageKey} />
                <TableSkeleton
                    className="table-list"
                    columns = {lookup.columns.filter(col => (!(this.isReadonly && col.key === "action")))}
                    dataSource = {this.formatData(featurePagesList)}
                    loading={isLoading}
                />
            </div>
        )
    }
}

const FeaturedPageManagement = Form.create()(FeaturedPageManagementForm);

export default FeaturedPageManagement;

import { findIndex } from "lodash";
import { PagesActions } from "./actions";

let Pages = {
    pageData: [],
    curPage: {},
    fallBackCal: [],
    inactiveEmpList:[],
    error: "",
    isLoading: false
};

const pagesReducer = (state = Pages, action) => {
    let index;
    switch (action.type) {
        case PagesActions.FETCH_PAGES_LIST_REQUEST:
        case PagesActions.FETCH_PAGE_REQUEST:
        case PagesActions.ADD_PAGE_REQUEST:
        case PagesActions.UPDATE_PAGE_REQUEST:
        case PagesActions.DELETE_PAGE_REQUEST:
            return {
                ...state,
                error: "",
                isLoading: true
            };
        case PagesActions.FETCH_PAGES_LIST_SUCCESS:
            return {
                ...state,
                pageData: action.payload,
                isLoading: false
            };
        case PagesActions.FETCH_PAGE_SUCCESS:
            return {
                ...state,
                curPage: {...action.payload},
                isLoading: false
            };
        case PagesActions.ADD_PAGE_SUCCESS:
            return {
                ...state,
                pageData: [...state.pageData, action.payload],
                curPage: action.payload,
                isLoading: false
            };
        case PagesActions.UPDATE_PAGE_SUCCESS:
            index = findIndex(state.pageData, ["id", action.payload.id]);
            return {
                ...state,
                pageData: [
                    ...state.pageData.slice(0, index),
                    action.payload,
                    ...state.pageData.slice(index + 1)
                ],
                curPage: action.payload,
                isLoading: false
            };
        case PagesActions.DELETE_PAGE_SUCCESS:
            return {
                ...state,
                pageData: [...state.pageData],
                error: "",
                isLoading: false
            };
        case PagesActions.PAGE_ERROR:
            return {
                ...state,
                pageData: [],
                curPage: {},
                error: action.payload.error || "something went wrong",
                isLoading: false
            };
        case PagesActions.REQ_FALLBACK_CAL:
            return {
                ...state,
                error: "",
                isLoading: true
            }
        case PagesActions.REQ_FALLBACK_CAL_SUCCESS:
            return {
                ...state,
                fallBackCal: [... action.payload],
                error: "",
                isLoading: false
            }
        case PagesActions.REQ_FALLBACK_CAL_ERROR:
            return {
                ...state,
                fallBackCal: [],
                error: action.payload.message || "",
                isLoading: false
            }
        case PagesActions.ADD_INACTIVE_EMP:
        case PagesActions.ADD_INACTIVE_EMP_SUCCESS:
            return {
                ...state,
                error: "",
                isLoading: false
            }
        case PagesActions.ADD_INACTIVE_EMP_ERROR:
            return {
                ...state,
                error: action.payload.message || "",
                isLoading: false
            }
        case PagesActions.REQ_INACTIVE_EMP_LIST:
            return {
                ...state,
                inactiveEmpList: [],
                error: ""
            }
        case PagesActions.REQ_INACTIVE_EMP_LIST_SUCCESS:
            return {
                ...state,
                inactiveEmpList: [... action.payload],
                error: "",
                isLoading: false
            }
        case PagesActions.REQ_INACTIVE_EMP_LIST_ERROR:
            return {
                ...state,
                inactiveEmpList: [],
                error: action.payload.message || "",
                isLoading: false
            }
        default:
            return state;
    }
};

export default pagesReducer;

import { takeLatest, call, put } from "redux-saga/effects";
import EmailServiceActions, * as actionCreator from "./actions";
import emailServiceApi from "./http";
import featureApi from "../FeatureReleases/http";

const emailServiceSaga = [
    takeLatest(EmailServiceActions.SEARCH_DOMAIN_REQUEST, searchDomain),
    takeLatest(EmailServiceActions.FETCH_EMAILS_REQUEST, emailsByDomain),
];


function* searchDomain(action) {
    try {
        const resp = yield call(emailServiceApi.searchDomain, action.payload);
        const successResponse = yield put(actionCreator.successSearchDomainResponse(resp.data));
        yield call(action.payload.cb, successResponse.message);
    } catch (e) {
        const errorResponse =  yield put(actionCreator.errorSearchDomainResponse((e.data && e.data.data) || { error: "Failed to process request. Please retry." }));
        yield call(action.payload.cb, errorResponse.message);
    }
}

function* emailsByDomain(action) {
    try {
        const resp = yield call(emailServiceApi.emailsByDomain, action.payload.domain);
        yield put(actionCreator.successFetchEmailResponse(resp.data.emails));
    } catch (e) {
        const errorResponse =  yield put(actionCreator.errorFetchEmailResponse(e.data.data || { error: "No Email Found" }));
        yield call(action.payload.cb, errorResponse.message);
    }
}

export default emailServiceSaga;

import React from "react";
import PropTypes from "prop-types";
import { find } from "lodash";

import Legend from "./Legend";
import legends from "./legendConfig.json";

const LegendGroup = ({ isRecordLoading, recordCount, targetProfile, targetProfileId }) => {
  if (isRecordLoading) return null;
  let legendsForDisplay = legends.filter((legend) => {
    // no show if count is zero
    if (!recordCount[legend.id]) return false;
    // no show if count is one and is target profile
    if (recordCount[legend.id] === 1 && targetProfile && targetProfile.type === legend.id) return false;
    return true;
  });

  if (targetProfile) {
    let targetLegend = find(legends, { id: "target-" + targetProfile.type });
    if (targetLegend) {
      legendsForDisplay.push({
        ...targetLegend,
        target: true,
        profileId: targetProfileId
      });
    }
  }

  return (
    <div className="legend-group">
      {legendsForDisplay
        .sort((a, b) => a.key - b.key)
        .map((legendData, i) => (
          <Legend key={i} {...legendData} />
        ))}
    </div>
  );
};

LegendGroup.propTypes = {
  isRecordLoading: PropTypes.bool.isRequired,
  recordCount: PropTypes.object.isRequired,
  targetProfile: PropTypes.object.isRequired,
  targetProfileId: PropTypes.string.isRequired
};

export default LegendGroup;

import { takeLatest, call, put } from "redux-saga/effects";
import UserActions, * as actionCreator from "./actions";
import userApi from "./http";
import { notification } from 'antd';

const userSagas = [
    takeLatest(UserActions.LOGIN_REQUEST, login),
    takeLatest(UserActions.BIRDEYE_LOGIN_REQUEST, birdeyeLogin),
    takeLatest(UserActions.LOGOUT_REQUEST, logout),
    takeLatest(UserActions.FETCH_USERS_LIST_REQUEST, fetchUsersList),
    takeLatest(UserActions.FETCH_USER_REQUEST, fetchUser),
    takeLatest(UserActions.ADD_USER_REQUEST, addUser),
    takeLatest(UserActions.UPDATE_USER_REQUEST, updateUser),
    takeLatest(UserActions.FETCH_USER_ROLES, fetchRolesList),
    takeLatest(UserActions.REDIRECT_TO_LOGIN_IF_NO_SESSION, redirectToLoginIfNoSession),
    takeLatest(UserActions.FETCH_PERMISSIONS, fetchPermissions),
    takeLatest(UserActions.ADD_ROLE, addRole),
    takeLatest(UserActions.FETCH_ROLE, fetchRole),
    takeLatest(UserActions.UPDATE_ROLE, updateRole),
    takeLatest(UserActions.FETCH_PERMISSION, fetchPermission),
    takeLatest(UserActions.UPDATE_PERMISSION, updatePermission),
    takeLatest(UserActions.ADD_PERMISSION, addPermission),
    takeLatest(UserActions.DELETE_USER_REQUEST, deleteUser),
    takeLatest(UserActions.DELETE_USER_ROLES_REQUEST, deleteUserRole),
    takeLatest(UserActions.DELETE_PERMISSION_REQUEST, deletePermission),
];

function* login(action) {
    try {
        const resp = yield call(userApi.login, action.payload.id);
        let userData = resp.data;
        userData.privileges = userData.privileges.split(',');
        yield put(actionCreator.loginSucess(userData));
    } catch (e) {
        yield put(
            actionCreator.userError({ error: "cannotLogin" })
        );
    }
}

function* birdeyeLogin(action) {
    try {
        console.log(action);
        const resp = yield call(userApi.birdeyLogin, action.payload.values);
        yield put(actionCreator.birdeyeLoginSucess(resp.data));
        window.location.reload();
    } catch (e) {
        yield put(actionCreator.userError( { error: (e.data.data && e.data.data.message) ? e.data.data.message : "Unable to login" }));
    }
}

function* logout() {
    try {
        yield call(userApi.logout);
        yield put(actionCreator.logoutSuccess());
    } catch (e) {
        yield put(
            actionCreator.userError({ error: "cannotLogout" })
        );
    }
}

function* fetchUsersList() {
    try {
        const resp = yield call(userApi.getAll);
        yield put(actionCreator.fetchUsersListSucess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.userError(e.data && e.data.data || { error: "usersListNotFound" })
        );
    }
}

function* fetchRolesList() {
    try {
        const resp = yield call(userApi.getAllRoles);
        yield put(actionCreator.fetchUserRolesSuccess(resp.data));
    } catch (e) {
        yield put(
            actionCreator.userError(e.data.data || { error: "rolesListNotFound" })
        );
    }
}

function* fetchUser(action) {
    try {
        const resp = yield call(userApi.getById, action.payload.id);
        yield put(actionCreator.fetchUserSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.userError(e.data.data || { error: "userNotFound" }));
    }
}

function* addUser(action) {
    try {
        const resp = yield call(userApi.add, action.payload.data);
        yield put(actionCreator.addUserSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.userError(e.data.data || { error: "cannotAdd" }));
        yield call(action.payload.cb, "cannotAdd");
    }
}

function* updateUser(action) {
    try {
        const resp = yield call(userApi.update, action.payload.data);
        yield put(actionCreator.updateUserSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.userError(e.data.data || { error: "cannotUpdate" }));
        yield call(action.payload.cb, "cannotUpdate");
    }
}

function* redirectToLoginIfNoSession(action) {
    try {
        yield put(actionCreator.logoutSuccess());
    } catch (e) {
        notification.error({message: 'Unexpected error while connecting to server.', description: e.message})
    }
}

function* fetchPermissions() {
    try {
        const resp = yield call(userApi.getAllPermissions);
        yield put(actionCreator.fetchPermissionsSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.userError(e.data.data || { error: "userNotFound" }));
    }
}

function* addRole(action) {
    try {
        const resp = yield call(userApi.createRole, action.payload.data);
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.userError(e.data.data || { error: "cannotAdd" }));
        yield call(action.payload.cb, "cannotAdd");
    }
}

function* fetchRole(action) {
    try {
        const resp = yield call(userApi.getRoleById, action.payload.id);
        yield put(actionCreator.fetchRoleRequestSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.userError(e.data.data || { error: "userNotFound" }));
    }
}


function* updateRole(action) {
    try {
        const resp = yield call(userApi.updateRole, action.payload.data);
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.userError(e.data.data || { error: "cannotUpdate" }));
        yield call(action.payload.cb, "cannotUpdate");
    }
}

function* fetchPermission(action) {
    try {
        const resp = yield call(userApi.getPermissionById, action.payload.id);
        yield put(actionCreator.fetchPermissionSuccess(resp.data));
    } catch (e) {
        yield put(actionCreator.userError(e.data.data || { error: "userNotFound" }));
    }
}

function* addPermission(action) {
    try {
        const resp = yield call(userApi.addPermission, action.payload.data);
        yield put(actionCreator.addPermissionSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.userError(e.data.data || { error: "userNotFound" }));
    }
}

function* updatePermission(action) {
    try {
        const resp = yield call(userApi.updatePermission, action.payload.data);
        yield put(actionCreator.updatePermissionSuccess(resp.data));
        yield call(action.payload.cb);
    } catch (e) {
        yield put(actionCreator.userError(e.data.data || { error: "userNotFound" }));
    }
}

function* deleteUser({payload}) {
    try {
       yield call(userApi.deleteUser, payload.id);
       yield put(actionCreator.deleteUserRequestSuccess(payload));
    } catch (e) {
        yield put(actionCreator.deleteUserRequestError(e.data.data || { error: "cannotDelete" }));
    }
}

function* deleteUserRole({payload}) {
    try {
        yield call(userApi.deleteUserRole, payload.id);
        yield put(actionCreator.deleteUserRolesRequestSuccess(payload));
    } catch (e) {
        yield put(actionCreator.deleteUserRolesRequestError(e.data.data || { error: "cannotDelete" }));
    }
}

function* deletePermission({payload}) {
    try {
        yield call(userApi.deletePermission, payload.data.id);
        yield put(actionCreator.deletePermissionRequestSuccess(payload));
    } catch (e) {
        yield put(actionCreator.deletePermissionRequestError(e.data.data || { error: "cannotDelete" }));
    }
}


export default userSagas;
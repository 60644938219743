import { beAPIResource } from "../../utils/apiHelper";

const salesRepApi = {
    getAll: () => beAPIResource.get(`/support/salesRep`),
    getById: (id) => beAPIResource.get(`/support/salesRep/${id}`),
    add: (data) => beAPIResource.post(`/support/salesRep`, data),
    update: (data) => beAPIResource.put(`/support/salesRep/${data.id}`, data.data),
    delete: (id) => beAPIResource.delete(`/support/salesRep/${id}`),
    getInvitation: (pageId) => beAPIResource.get(`/support/salesRep/invite/${pageId}`),
    getTimezones: () => beAPIResource.get("/support/page/timezones")
};

export default salesRepApi;

import { createActionTypes } from "../../utils";

export const AnalyticsActions = createActionTypes("analytics", [
    "ANALYTICS_REPORT",
    "ANALYTICS_REPORT_SUCCESS",
    "ANALYTICS_REPORT_ERROR"
]);

export const analyticsReport = (obj) => {
    return {
        type: AnalyticsActions.ANALYTICS_REPORT,
        payload: obj
    };
};

export const analyticsReportSuccess = (obj) => {
    return {
        type: AnalyticsActions.ANALYTICS_REPORT_SUCCESS,
        payload: obj
    };
};

export const analyticsReportError = (obj) => {
    return {
        type: AnalyticsActions.ANALYTICS_REPORT_ERROR,
        payload: obj
    };
};

export default AnalyticsActions;

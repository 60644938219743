import React from "react";
import PropTypes from "prop-types";
import { TreeSelect, Form } from "antd";

import SearchSelect from "./SearchSelect";

const FormItem = Form.Item;
const { SHOW_PARENT } = TreeSelect;

class Filter extends React.PureComponent {
  getSelectOption = (option, i) => {
    const name = this.props.config.name;
    let optionDisplay = { title: "", value: "", key: "" };

    if (typeof option === "string") {
      optionDisplay.title = option;
      optionDisplay.value = option;
      optionDisplay.key = option + i;
    } else {
      optionDisplay.value = option.value || option.name;
      optionDisplay.title = option.title || option.label || option.name;
      optionDisplay.key = option.key || option.value || option.name + i;
      if (name === "state") optionDisplay.value = `${option.code}_${option.name}`;
      optionDisplay.value =
        typeof optionDisplay.value === "string" ? optionDisplay.value.toLowerCase() : optionDisplay.value;
    }

    return optionDisplay;
  };

  getOptionsToDisplay = (component, name, options, dynamicOptions) => {
    let optionsToDisplay = [];
    if (component !== "searchSelect") {
      options = dynamicOptions.length ? dynamicOptions : options;
      optionsToDisplay = [
        {
          title: "All selected",
          value: "all-selected",
          key: `all-${name}`,
          children: component === "select" ? options.map(this.getSelectOption) : options
        }
      ];
    }

    return optionsToDisplay;
  };

  getDefaultExpandedKeys = (component, optionsToDisplay) => {
    let defaultExpandedKeys = [];
    if (component === "treeSelect" && optionsToDisplay.length) defaultExpandedKeys = [optionsToDisplay[0].key];

    return defaultExpandedKeys;
  };

  getDropdownWrapper = () => this.element;

  renderSelect = () => {
    const {
      component,
      config: { mode, name, placeholder, maxTagCount, filterOption },
      value,
      options,
      dynamicOptions,
      filterObj,
      handleFieldChange
    } = this.props;

    if (component === "searchSelect")
      return (
        <SearchSelect
          name={name}
          value={value}
          mode={mode || "multiple"}
          placeholder={placeholder || ""}
          filterOption={filterOption || false}
          filterObj={filterObj}
          dynamicOptions={dynamicOptions}
          getPopupContainer={this.getDropdownWrapper}
          handleFieldChange={handleFieldChange}
        />
      );

    const optionsToDisplay = this.getOptionsToDisplay(component, name, options, dynamicOptions);
    const defaultExpandedKeys = this.getDefaultExpandedKeys(component, optionsToDisplay);
    return (
      <TreeSelect
        treeCheckable
        showSearch
        dropdownMatchSelectWidth
        value={value}
        treeData={optionsToDisplay}
        showCheckedStrategy={SHOW_PARENT}
        searchPlaceholder={placeholder || "None selected"}
        maxTagCount={maxTagCount || 1}
        treeDefaultExpandAll={component === "select"}
        getPopupContainer={this.getDropdownWrapper}
        treeDefaultExpandedKeys={defaultExpandedKeys}
        dropdownClassName={`prospector-select-tree-dropdown ${component} ${name}-dropdown`}
        onChange={(nextValue) => handleFieldChange(name, nextValue)}
      />
    );
  };

  render() {
    const { name, label } = this.props.config;

    return (
      <div className={`${name}-wrapper`} ref={(node) => (this.element = node)}>
        <FormItem label={label}>{this.renderSelect()}</FormItem>
      </div>
    );
  }
}

Filter.propTypes = {
  component: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  filterObj: PropTypes.object.isRequired,
  dynamicOptions: PropTypes.array
};

export default Filter;

import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { TableSkeleton } from "../../components";
import MultiResolutionImageForm from "./MultiResolutionImageForm";
import lookup from "./lookup.json";

class ProductMessageImageList extends React.Component {
    constructor(props) {
        super(props);
    }

    handleMessageImageSubmission = (payload) => {
        const { id } = this.props.data;
        this.props.handleOnIndustryImageSubmit({ id, industry_image: payload });
    }

    handleOnRemoveImageClick = (e, industry) => {
        e.preventDefault();
        this.handleMessageImageSubmission({ [industry]: null });
    }

    mapToDataSource = () => {
        const industries = lookup.industry;
        const images = this.props.data.industry_image;

        if (!Object.keys(this.props.data).length) return [];

        return industries.map((industry, i) => {
            return {
                key: i,
                industry: industry.name,
                image: <MultiResolutionImageForm industry={industry.categorySlug} imageUrl={images[industry.categorySlug]} handleSubmission={this.handleMessageImageSubmission} />,
                action: (
                    <>
                        <Button type="link" icon="delete" title="Click to remove" onClick={(e) => this.handleOnRemoveImageClick(e, industry.categorySlug)} />
                    </>
                )
            };
        });
    }

    render() {
        return (
            <div>
                <TableSkeleton loading={this.props.isLoading} columns={lookup.tableColumnOfIndustryImage} dataSource={this.mapToDataSource()}/>
            </div>
        );
    }
};

ProductMessageImageList.propTypes = {
    data: PropTypes.object.isRequired,
    handleOnIndustryImageSubmit: PropTypes.func,
    isLoading: PropTypes.bool
};

export default ProductMessageImageList;
